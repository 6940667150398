import React, { useEffect, useState } from 'react';
import { Heading } from '../../../../styles';
import {
  Box,
  Button,
  Checkbox,
  DialogActions,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material';
import { AdvancedSetting, CheckboxConfig } from './types';
import { ColorSelect } from './ColorSelect';
import { AdvancedSettings } from './AccordionSettings';
import MenuItem from '@mui/material/MenuItem';
import {
  initialMyContractAdvancedSettings,
  initialMyContractCheckedState,
} from './initialState';
import {
  NotificationColor,
  NotificationSetting,
  UpdateNotificationSetting,
} from '../../../../common/api';
import { mapNotificationSettingsToState } from './utils';

type Props = {
  isDisabled: boolean;
  accountId: string;
  notificationSettings?: NotificationSetting[];
  handleSave: (paylod: UpdateNotificationSetting[]) => void;
  onClose: () => void;
};

function MyContractTab({
  isDisabled,
  accountId,
  notificationSettings,
  handleSave,
  onClose,
}: Props) {
  const [checkedState, setCheckedState] = useState<Record<string, boolean>>(
    initialMyContractCheckedState
  );
  const [advancedSettings, setAdvancedSettings] = useState<AdvancedSetting[]>(
    initialMyContractAdvancedSettings
  );

  useEffect(() => {
    if (notificationSettings && notificationSettings.length > 0) {
      const { mappedCheckedState, mappedAdvancedSettings } =
        mapNotificationSettingsToState(notificationSettings);
      setCheckedState(mappedCheckedState);
      setAdvancedSettings(mappedAdvancedSettings);
    }
  }, [notificationSettings]);

  const handleCheckboxChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const { checked } = event.target;
    setCheckedState(prevState => ({
      ...prevState,
      [event.target.name]: checked,
    }));
  };

  const handleAdvancedSettingsChange = (
    event: React.ChangeEvent<
      HTMLInputElement | { name?: string; value: unknown }
    >,
    configName: string
  ) => {
    const { name, value } = event.target;

    setAdvancedSettings(prevSettings =>
      prevSettings.map(setting =>
        setting.name === configName
          ? { ...setting, [name as string]: value ?? '' }
          : setting
      )
    );
  };

  const handleSelectChange = (
    event: SelectChangeEvent<string>,
    index: number
  ) => {
    const { name, value } = event.target;
    setAdvancedSettings(prevSettings => {
      const newSettings = [...prevSettings];
      newSettings[index] = {
        ...newSettings[index],
        [name]: value,
      };
      return newSettings;
    });
  };

  const checkboxConfigs: CheckboxConfig[] = [
    {
      title: 'Claim & Payment Alerts',
      label: 'Contractor Payment Claim received',
      name: 'contractorPaymentClaimReceived',
      alertColor: advancedSettings[0].alertColor,
      emailNotifications: advancedSettings[0].emailNotifications,
      advancedSettings: advancedSettings[0],
      example: 1,
    },
    {
      label: 'My Payment schedule Overdue (SOPA)',
      name: 'myPaymentScheduleOverdue',
      alertColor: advancedSettings[1].alertColor,
      emailNotifications: advancedSettings[1].emailNotifications,
      advancedSettings: advancedSettings[1],
      example: 1,
    },
    {
      label: 'My Payment schedule nearly Due (SOPA)',
      name: 'myPaymentScheduleNearlyDue',
      alertColor: advancedSettings[2].alertColor,
      emailNotifications: advancedSettings[2].emailNotifications,
      advancedSettings: advancedSettings[2],
      example: 2,
    },
    {
      label: 'My Payment Schedule submitted',
      name: 'myPaymentScheduleSubmitted',
      alertColor: advancedSettings[3].alertColor,
      emailNotifications: advancedSettings[3].emailNotifications,
      advancedSettings: advancedSettings[3],
      example: 1,
    },
    {
      label: 'My Payment Schedule accepted',
      name: 'myPaymentScheduleAccepted',
      alertColor: advancedSettings[4].alertColor,
      emailNotifications: advancedSettings[4].emailNotifications,
      advancedSettings: advancedSettings[4],
      example: 1,
    },
    {
      label: 'My Payment Schedule rejected',
      name: 'myPaymentScheduleRejected',
      alertColor: advancedSettings[5].alertColor,
      emailNotifications: advancedSettings[5].emailNotifications,
      advancedSettings: advancedSettings[5],
      example: 1,
    },
    {
      label: 'Contractor Payment Claim withdrawn',
      name: 'contractorPaymentClaimWithdrawn',
      alertColor: advancedSettings[6].alertColor,
      emailNotifications: advancedSettings[6].emailNotifications,
      advancedSettings: advancedSettings[6],
      example: 1,
    },
    {
      label: 'My Payment Made',
      name: 'myPaymentMade',
      alertColor: advancedSettings[7].alertColor,
      emailNotifications: advancedSettings[7].emailNotifications,
      advancedSettings: advancedSettings[7],
      example: 1,
    },
    {
      title: 'Variation Alerts',
      label: 'Contractor Variation received',
      name: 'contractorVariationReceived',
      alertColor: advancedSettings[8].alertColor,
      emailNotifications: advancedSettings[8].emailNotifications,
      advancedSettings: advancedSettings[8],
      example: 1,
    },
    {
      label: 'Contractor Variation withdrawn',
      name: 'contractorVariationWithdrawn',
      alertColor: advancedSettings[9].alertColor,
      emailNotifications: advancedSettings[9].emailNotifications,
      advancedSettings: advancedSettings[9],
      example: 1,
    },
    {
      label: 'Contractor Variation accepted',
      name: 'contractorVariationAccepted',
      alertColor: advancedSettings[10].alertColor,
      emailNotifications: advancedSettings[10].emailNotifications,
      advancedSettings: advancedSettings[10],
      example: 1,
    },
    {
      label: 'Contractor Variation rejected',
      name: 'contractorVariationRejected',
      alertColor: advancedSettings[11].alertColor,
      emailNotifications: advancedSettings[11].emailNotifications,
      advancedSettings: advancedSettings[11],
      example: 1,
    },
    {
      title: 'Other Alerts',
      label: 'Contractor Compliance Document nearing Date expiry',
      name: 'contractorComplianceDocumentNearingExpiry',
      alertColor: advancedSettings[12].alertColor,
      emailNotifications: advancedSettings[12].emailNotifications,
      advancedSettings: advancedSettings[12],
      example: 3,
    },
    {
      label: 'Contractor Compliance Document expired',
      name: 'contractorComplianceDocumentExpired',
      alertColor: advancedSettings[13].alertColor,
      emailNotifications: advancedSettings[13].emailNotifications,
      advancedSettings: advancedSettings[13],
      example: 1,
    },
  ];

  const isColorSelectDisabled = (colorSelectName: string): boolean => {
    if (isDisabled) {
      return true;
    }

    const lockedColorSelects = [
      'myPaymentScheduleOverdue',
      'myPaymentScheduleRejected',
      'contractorComplianceDocumentExpired',
    ];

    return lockedColorSelects.includes(colorSelectName);
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', p: 2 }}>
      <Heading>My Contract</Heading>

      <Box>
        <FormGroup sx={{ pb: 2 }}>
          {checkboxConfigs.map((config, index) => (
            <React.Fragment key={config.name}>
              {config.title && (
                <Typography variant="h5" sx={{ mt: 2, mb: 1, fontWeight: 700 }}>
                  {config.title}
                </Typography>
              )}
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={
                        checkedState[config.name as keyof typeof checkedState]
                      }
                      onChange={event => handleCheckboxChange(event, index)}
                      name={config.name}
                      disabled={isDisabled}
                    />
                  }
                  label={config.label}
                />

                {checkedState[config.name as keyof typeof checkedState] && (
                  <>
                    <ColorSelect
                      value={config.alertColor}
                      onChange={event => handleSelectChange(event, index)}
                      name="alertColor"
                      disabled={isColorSelectDisabled(config.name)}
                    />
                    <Box sx={{ ml: 2, display: 'flex', alignItems: 'center' }}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={config.emailNotifications}
                            onChange={(
                              event: React.ChangeEvent<HTMLInputElement>
                            ) =>
                              setAdvancedSettings(prev => {
                                const newSettings = [...prev];
                                newSettings[index].emailNotifications =
                                  event.target.checked;
                                return newSettings;
                              })
                            }
                            name={`emailNotifications${index + 1}`}
                            disabled={true}
                          />
                        }
                        label="Email Notifications"
                      />
                    </Box>
                  </>
                )}
              </Box>

              {checkedState[config.name as keyof typeof checkedState] && (
                <>
                  {config.example === 1 && (
                    <AdvancedSettings
                      advancedSettings={{
                        minimumValue:
                          advancedSettings?.find(s => s.name === config.name)
                            ?.minimumValue ?? '',
                        timeDelay:
                          advancedSettings?.find(s => s.name === config.name)
                            ?.timeDelay ?? '',
                      }}
                      handleAdvancedSettingsChange={event =>
                        handleAdvancedSettingsChange(event, config.name)
                      }
                      isDisabled={isDisabled}
                    />
                  )}

                  {config.example === 2 && (
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        p: 2,
                        border: '1px solid #E0E0E0',
                        borderRadius: 2,
                      }}
                    >
                      <Typography variant="subtitle1" sx={{ mb: 1 }}>
                        Days Before Deadline
                      </Typography>
                      <FormControl variant="outlined" fullWidth>
                        <Select
                          value={config.advancedSettings.daysBeforeDeadline}
                          onChange={event => handleSelectChange(event, index)}
                          name="daysBeforeDeadline"
                          displayEmpty
                        >
                          <MenuItem value="Off">Off</MenuItem>
                          {[...Array(9)].map((_, i) => (
                            <MenuItem key={i + 1} value={i + 1}>
                              {i + 1} Days
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Box>
                  )}
                </>
              )}

              <Divider sx={{ mt: 1, mb: 3 }} />
            </React.Fragment>
          ))}
        </FormGroup>
        <DialogActions>
          <Button
            onClick={() => {
              const myContractPayload = Object.keys(checkedState).map(
                (key, index) => ({
                  name: key,
                  isOn: checkedState[key],
                  accountId,
                  minimumValue: advancedSettings[index].minimumValue
                    ? parseFloat(advancedSettings[index].minimumValue ?? '')
                    : undefined,
                  timeDelay: advancedSettings[index].timeDelay
                    ? parseInt(advancedSettings[index].timeDelay ?? '', 10)
                    : undefined,
                  emailNotifications:
                    advancedSettings[index].emailNotifications,
                  alertColor: advancedSettings[index]
                    .alertColor as NotificationColor,
                })
              );

              handleSave(myContractPayload);
            }}
            color="primary"
            variant="contained"
            disabled={isDisabled}
          >
            OK
          </Button>
          <Button onClick={onClose} color="secondary" variant="outlined">
            Cancel
          </Button>
        </DialogActions>
      </Box>
    </Box>
  );
}

export { MyContractTab };
