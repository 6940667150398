import React, { Dispatch, SetStateAction } from 'react';
import {
  FormControl,
  Menu,
  MenuItem,
  Typography,
  Box,
  Stack,
  Button,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  brand500,
  grey300,
  grey500,
  red500,
  yellow500,
} from '../../../../styles/colours';

interface Props {
  handleGroupNotification: Dispatch<SetStateAction<Array<string>>>;
}

function PriorityMenu(props: Props) {
  const { handleGroupNotification } = props;

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItem = (value: string) => {
    handleGroupNotification(prev =>
      prev.includes(value) ? prev : [...prev, value]
    );
    handleClose();
  };

  const priorityItems = [
    {
      title: 'Urgent',
      color: red500,
    },
    {
      title: 'Medium',
      color: yellow500,
    },
    {
      title: 'Low',
      color: brand500,
    },
  ];

  return (
    <FormControl sx={{ minWidth: '150px' }}>
      <Button
        id="priority"
        aria-controls={open ? 'priority-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        sx={{
          color: grey500,
          borderRadius: '72px',
          border: `1px solid ${grey300}`,
          width: '7rem',
        }}
      >
        Priority
        <ExpandMoreIcon />
      </Button>
      <Menu
        id="priority-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        sx={{
          top: 5,
          left: 10,
        }}
        PaperProps={{
          style: {
            width: 172,
          },
        }}
        MenuListProps={{
          'aria-labelledby': 'priority',
        }}
      >
        {priorityItems.map(priorityItem => (
          <MenuItem
            key={priorityItem.title}
            onClick={() => handleMenuItem(priorityItem.title)}
            value={priorityItem.title}
          >
            <Stack flexDirection="row" alignItems="center">
              <Box
                style={{
                  ...styles.Dot,
                  backgroundColor: priorityItem.color,
                }}
              />
              <Typography variant="body1">{priorityItem.title}</Typography>
            </Stack>
          </MenuItem>
        ))}
      </Menu>
    </FormControl>
  );
}

const styles = {
  Dot: {
    height: '0.75rem',
    width: '0.75rem',
    borderRadius: '50%',
    marginRight: '0.75rem',
  },
};

export { PriorityMenu };
