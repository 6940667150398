export const dependencyMap: Record<string, [string, string]> = {
  contractorClaimsMade: [
    'subcontractorClaimsMade',
    'claims_replicatedToSubcontractorBelow',
  ],
  subSubmitted: ['submitted', 'submitted_replicatedToSubcontractorBelow'],
  subApproved: ['approved', 'approved_replicatedToSubcontractorBelow'],
  subRejected: ['rejected', 'rejected_replicatedToSubcontractorBelow'],
  subWithdrawn: ['withdrawn', 'withdrawn_replicatedToSubcontractorBelow'],
  subAnticipated: ['anticipated', 'anticipated_replicatedToSubcontractorBelow'],
  paymentScheduleSopaAlertSentToContractor: [
    'sopaComplianceAlert',
    'sopaComplianceAlert_replicatedToSubcontractorBelow',
  ],
  contractorComplianceDocumentsExpire: [
    'complianceDocumentsExpire',
    'compliance_replicatedToSubcontractorBelow',
  ],
};
