import {
  Box,
  Dialog,
  DialogContent,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import React from 'react';
import { DialogClose, Heading } from '../../../styles';
import { ManualPayment } from '../../../common/api';

const PaymentManualFilesCommentView = ({
  manualPayment,
  onClose,
}: {
  manualPayment: ManualPayment;
  onClose: () => void;
}) => {
  return (
    <>
      <Dialog open fullWidth maxWidth="xs" onClose={onClose}>
        <DialogContent>
          <Heading>Comments/Files</Heading>
          <DialogClose onClose={onClose} />
          <Box mb={2}>
            <TextField
              multiline
              rows={4}
              label="Comment"
              fullWidth
              value={manualPayment.comment}
            />
          </Box>

          {manualPayment.manualPaymentFiles.length > 0 && (
            <Box>
              <Typography>
                <strong>Files</strong>
              </Typography>
              {manualPayment.manualPaymentFiles.map(file => (
                <Box
                  key={file.path}
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Typography>
                    <Tooltip title="Download file" placement="top">
                      <a href={file.downloadUrl}>{file.name}</a>
                    </Tooltip>
                  </Typography>
                </Box>
              ))}
            </Box>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default PaymentManualFilesCommentView;
