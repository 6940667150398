import dayjs from 'dayjs';

// API
import {
  Notification,
  NotificationStatus,
  ValueType,
  VariationListResource,
  VariationStatus,
} from '../../../common/api';

// Utils
import { formatDate } from '../../../common/format';

export type VariationRowData = {
  id: string;
  isClosed: number;
  isFinalised: number;
  isSubmitted: number;
  subContractor: string;
  caption: string;
  identifier: string;
  anticipatedId: string;
  variationIdentifier: number;
  description: string;
  closedAt: string;
  createdAt: string;
  submittedAt: string;
  dateSubmitted: number;
  dateResponded: string;
  respondedAt: number;
  status: string;
  statusOrder: number;
  totalValue: number;
  maxVarianceValue: number;
  valueType: ValueType;
  notificationId: string;
};

export function createVariationRow(
  variation: VariationListResource,
  accountId?: string,
  notifications?: Array<Notification>
): VariationRowData {
  const variationNotifications = notifications?.filter(
    notification => notification?.variationId === variation?.id
  );

  const notification = variationNotifications?.find(
    notification =>
      notification.variationId === variation.id &&
      notification.status === NotificationStatus.Active &&
      notification.accountId === accountId
  );

  const notificationId = notification?.id;

  return {
    id: variation.id,
    isClosed: Number(variation.isClosed),
    isFinalised: Number(variation.isFinalised),
    isSubmitted: Number(variation.isSubmitted),
    subContractor: variation.contract.description,
    caption: variation.contract.contractorAccount.name,
    variationIdentifier: Number(variation.identifier || 0),
    identifier: `${variation.identifier}${
      variation.revision ? ` (Rev: ${variation.revision})` : ''
    }`,
    anticipatedId: `${variation.anticipatedId}${
      variation.revision ? ` (Rev: ${variation.revision})` : ''
    }`,
    dateSubmitted: dayjs(variation.submittedAt || 0).unix(),
    respondedAt: dayjs(variation.dateResponded || 0).unix(),
    description: variation.description,
    createdAt: formatDate(variation.createdAt) || '',
    closedAt: formatDate(variation.closedAt) || '',
    submittedAt: formatDate(variation.submittedAt) || '',
    dateResponded: variation.dateResponded
      ? formatDate(variation.dateResponded)
      : '',
    status: '' + variation.status,
    statusOrder: Object.keys(VariationStatus).indexOf(variation.status),
    totalValue: variation.totalValue,
    maxVarianceValue: variation.maxVarianceValue,
    valueType: variation.valueType,
    notificationId: notificationId || '',
  };
}
