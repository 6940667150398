import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Badge from '@mui/material/Badge';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import { IconButton } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import {
  Notification,
  NotificationColor,
  NotificationType,
  updateAllNotification,
} from '../../../../common/api';
import { useAuth } from '../../../../common/auth-provider/AuthProvider';
import {
  formatDaysTillDate,
  projectDate,
} from '../../../../common/format/formatDate';
import { PriorityType } from './types';
import { useCheckbox } from '../../../../pages/contract-page/hooks/useCheckbox';
import _ from 'lodash';
import { useDialogState } from '../../../../components/dialog/dialog.hooks';
import { NotificationDrawer } from './NotificationDrawer';
import { useProject } from '../../../../common/hooks/useProject';
import { useNotifications } from '../../../../common/hooks/useNotifications';

function NotificationList() {
  const [groupNotifications, setGroupNotifications] = useState<Array<string>>(
    []
  );
  const [isShowUnreadNotifications, setIsShowUnreadNotifications] =
    useState(false);
  const [, setNotificationPriorityArr] = useState<
    Array<{ id: string; priority: string }>
  >([]);

  const { user } = useAuth();
  const { project } = useProject();
  const { notifications, refetchNotifications, mutationNotification } =
    useNotifications(user, project, isShowUnreadNotifications);

  const {
    isVisible: isDrawer,
    close: closeDrawer,
    open: openDrawer,
  } = useDialogState();

  const handleShowUnreadNotifications = useCallback(
    (_e: React.SyntheticEvent<Element, Event>, checked: boolean) => {
      setIsShowUnreadNotifications(checked);
    },
    []
  );

  const refetchData = useCallback(async () => {
    await refetchNotifications();
  }, [refetchNotifications]);

  const { mutate: mutationAllNotification } = useMutation(
    () => updateAllNotification(user?.accountId ?? ''),
    {
      onSuccess: () => refetchData(),
    }
  );

  const { isItemSelected, handleCheckboxClick, setSelectAll, countUnSelect } =
    useCheckbox(notifications);

  const handleRemove = useCallback(
    (value: string) => {
      const filteredGroupNotification = groupNotifications.filter(
        groupNotification => groupNotification !== value
      );

      setGroupNotifications(filteredGroupNotification);
    },
    [groupNotifications]
  );

  const handleReadAll = useCallback(() => {
    mutationAllNotification();
  }, [mutationAllNotification]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const setServicesValueDebounced = useCallback(
    _.debounce(mutationNotification, 500),
    []
  );

  const handleCheckbox = useCallback(
    async (notification: Notification) => {
      handleCheckboxClick(notification.id);
      setServicesValueDebounced({
        id: notification.id,
        isRead: !notification.isRead,
      });
    },
    [handleCheckboxClick, setServicesValueDebounced]
  );

  useEffect(() => {
    if (notifications) {
      setSelectAll(
        notifications
          .filter(notification => notification.isRead)
          .map(({ id }) => id)
      );
    }
  }, [notifications, setSelectAll]);

  const notificationRow = useMemo(() => {
    const notificationRow = notifications
      ?.map(notification => {
        const calcPriority = () => {
          if (notification.color === NotificationColor.Red) {
            return PriorityType.URGENT;
          }

          if (notification.color === NotificationColor.Yellow) {
            return PriorityType.MEDIUM;
          }

          if (notification.color === NotificationColor.Green) {
            return PriorityType.LOW;
          }

          if (!notification.dueAt) {
            return PriorityType.LOW;
          }

          const formatDays = formatDaysTillDate(
            notification.dueAt,
            project?.systemDate
              ? projectDate(project.systemDate)
              : projectDate(notification?.project?.systemDate)
          );

          if (notification.type === NotificationType.ComplianceFile) {
            if (formatDays >= -7 && formatDays <= -1) {
              return PriorityType.MEDIUM;
            }
          }

          if (formatDays >= -3 && formatDays <= -1) {
            return PriorityType.MEDIUM;
          }

          if (
            formatDays >= 0 ||
            notification.type === NotificationType.Payment
          ) {
            return PriorityType.URGENT;
          }

          return PriorityType.LOW;
        };

        return {
          ...notification,
          priority: calcPriority(),
        };
      })
      .filter(notification =>
        groupNotifications.length
          ? groupNotifications.includes(notification.priority)
          : true
      )
      .sort(
        (a, b) =>
          groupNotifications.indexOf(b.priority) -
          groupNotifications.indexOf(a.priority)
      );
    if (notificationRow) {
      const priorityArr = notificationRow.map(row => {
        return {
          id: row.id,
          priority: row.priority,
        };
      });

      setNotificationPriorityArr(prev => {
        const priorityChangedArr = prev.filter(
          (el, index) =>
            el?.priority !== priorityArr[index]?.priority &&
            el?.id === priorityArr[index]?.id
        );

        if (priorityChangedArr.length) {
          for (const el of priorityChangedArr) {
            mutationNotification({ id: el.id, isRead: false });
          }
        }

        return priorityArr;
      });
    }
    return notificationRow;
  }, [
    project?.systemDate,
    groupNotifications,
    notifications,
    mutationNotification,
  ]);

  return (
    <>
      <IconButton aria-label="notification" size="large">
        <Badge badgeContent={countUnSelect} color="error">
          <NotificationsNoneIcon onClick={openDrawer} />
        </Badge>
      </IconButton>
      <NotificationDrawer
        isDrawer={isDrawer}
        closeDrawer={closeDrawer}
        isShowUnreadNotifications={isShowUnreadNotifications}
        handleShowUnreadNotifications={handleShowUnreadNotifications}
        groupNotifications={groupNotifications}
        setGroupNotifications={setGroupNotifications}
        handleReadAll={handleReadAll}
        handleRemove={handleRemove}
        isItemSelected={isItemSelected}
        handleCheckbox={handleCheckbox}
        notificationRow={notificationRow}
      />
    </>
  );
}

export { NotificationList };
