import React from 'react';

import { Dialog, DialogContent, Divider } from '@mui/material';
import { MyAccountForm } from './MyAccountForm';
import { DialogClose, Heading } from '../../../../styles';
import { MyAccountPasswordForm } from './MyAccountPasswordForm';

type Props = {
  close: () => void;
};

function MyAccountDialog({ close }: Props) {
  return (
    <Dialog open fullWidth maxWidth="sm" onClose={close}>
      <DialogContent>
        <Heading>My Account</Heading>
        <DialogClose onClose={close} />
        <MyAccountForm close={close} />
        <Divider sx={{ mt: 2, mb: 2 }} />
        <MyAccountPasswordForm />
      </DialogContent>
    </Dialog>
  );
}

export { MyAccountDialog };
