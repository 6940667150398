import { MouseEvent, useState } from 'react';
import { Order } from '../../common/api';

export interface HeadCell {
  disablePadding: boolean;
  id: string;
  label: string;
  numeric: boolean;
  disable?: boolean;
  isHidden?: boolean;
}

export const useSortedTable = (defaultOrder: string = 'name') => {
  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<string>(defaultOrder);

  const handleRequestSort = (event: MouseEvent<unknown>, property: string) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  return {
    order,
    orderBy,
    handleRequestSort,
  };
};
