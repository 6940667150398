import { axios } from './axios';
import { UpdateNotificationSetting } from './types';

export async function updateContractAlertSettings(
  contractIds: string[],
  alertSettings: UpdateNotificationSetting[]
): Promise<void> {
  const updatedAlertSettings = alertSettings.map(setting => ({
    ...setting,
    notificationType: setting.notificationType || 'Payment',
  }));

  await axios.patch(`/contracts/${contractIds[0]}/notification-settings`, {
    contractIds,
    notificationSettings: updatedAlertSettings,
  });
}
