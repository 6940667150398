import React, { useState } from 'react';
import Box from '@mui/material/Box';
import { Alert, CircularProgress, TextField, Typography } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { LoadingButton } from '@mui/lab';
import { Copyright } from '../../components/copyright';
import { Params, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useMutation, useQuery } from '@tanstack/react-query';
import {
  getAccountIdByInvitation,
  updateAccountWithInvitation,
  UpdateAccountWithInvitationPayload,
} from '../../common/api';
import { AxiosError } from 'axios';
import { LoginLayout } from '../../components/login-layout';

const ADDRESS = 'address';
const ABN = 'abn';
const NAME = 'name';

interface Form {
  [NAME]: string;
  [ADDRESS]: string;
  [ABN]: string;
}

function ActivateInvitationAccountPage() {
  const { invitationId } = useParams() as Params;

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<Form>();
  const navigate = useNavigate();
  const location = useLocation();

  // A page/non-field error.
  const [pageError, setPageError] = useState<string | null>();

  // The reason, supplied as a parameter why the user is here.
  const [reason, setReason] = useState<string | undefined>(
    new URLSearchParams(location.search).get('reason') ?? undefined
  );

  // The original intended location, or /
  const from = location.state?.from?.pathname || '/';

  const { data: invitation, isLoading: isInvitationLoading } = useQuery(
    ['invitationAccount', invitationId],
    () => getAccountIdByInvitation(invitationId!)
  );

  const { mutateAsync: mutateUpdateAccount } = useMutation(
    (payload: UpdateAccountWithInvitationPayload) =>
      updateAccountWithInvitation(payload)
  );

  const onChange = () => {
    setPageError(null);
  };

  const onSubmit = async (form: Form) => {
    setReason(undefined);
    if (isValid) {
      try {
        if (!invitation?.email || !invitationId) {
          return;
        }

        await mutateUpdateAccount({
          invitationId: invitationId,
          address: form[ADDRESS],
          name: form[NAME],
          abn: form[ABN],
        });

        navigate(from, { replace: true });
      } catch (err) {
        let message = `An unknown error occurred`;
        if (err instanceof AxiosError) {
          const status = err.response?.status;
          if (status === 404) {
            message = `Invitation not found or already used`;
            setPageError(message);
            return;
          }
          message = `An unknown error occurred (status ${status})`;
          setPageError(message);
        }
      }
    }
  };

  if (isInvitationLoading) {
    return (
      <LoginLayout>
        <>
          <br />
          <CircularProgress />
        </>
      </LoginLayout>
    );
  }

  return (
    <LoginLayout>
      <>
        {!!invitation?.email ? (
          <Box
            component="form"
            noValidate
            sx={{ mt: 1 }}
            onSubmit={handleSubmit(onSubmit)}
            onChange={onChange}
          >
            {reason && (
              <Alert sx={{ my: 3 }} severity="info">
                {reason}
              </Alert>
            )}
            {pageError && (
              <Alert sx={{ my: 3 }} severity="error">
                {pageError}
              </Alert>
            )}
            <TextField
              margin="normal"
              fullWidth
              label="Email"
              value={invitation.email}
              disabled
            />
            <Controller
              name={NAME}
              defaultValue={''}
              control={control}
              rules={{
                required: 'name is required',
              }}
              render={({ field }) => (
                <TextField
                  {...field}
                  margin="normal"
                  required
                  fullWidth
                  label="Name"
                  type="text"
                  error={!!errors[NAME]}
                  helperText={errors[NAME]?.message?.toString()}
                />
              )}
            />
            <Controller
              name={ADDRESS}
              defaultValue={''}
              control={control}
              rules={{
                required: 'address is required',
              }}
              render={({ field }) => (
                <TextField
                  {...field}
                  margin="normal"
                  required
                  fullWidth
                  label="Address"
                  type="text"
                  autoFocus
                  error={!!errors[ADDRESS]}
                  helperText={errors[ADDRESS]?.message?.toString()}
                />
              )}
            />
            <Controller
              name={ABN}
              defaultValue={''}
              control={control}
              rules={{
                required: 'abn is required',
              }}
              render={({ field }) => (
                <TextField
                  {...field}
                  margin="normal"
                  required
                  fullWidth
                  label="ABN"
                  type="text"
                  error={!!errors[ABN]}
                  helperText={errors[ABN]?.message?.toString()}
                />
              )}
            />
            <LoadingButton
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 3 }}
            >
              Submit
            </LoadingButton>
            <Copyright />
          </Box>
        ) : (
          <>
            <br />
            <Typography variant="h5">
              Sorry, it seems that this invitation no longer exists
            </Typography>
          </>
        )}
      </>
    </LoginLayout>
  );
}

export { ActivateInvitationAccountPage };
