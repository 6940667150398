import {
  Avatar,
  Box,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  Typography,
} from '@mui/material';
import dayjs from 'dayjs';
import React, { useMemo, useState } from 'react';
import {
  User,
  VariationMessage,
  VariationMessageStatus,
} from '../../common/api';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { stringAvatar } from './VariationChat';

interface Props {
  message: VariationMessage;
  currentUser: User;
  canEdit: boolean;
  onDeleteMessage: (messageId: string) => void;
  onResolveMessage: (messageId: string) => void;
}

function VariationChatMessage({
  message,
  currentUser,
  canEdit,
  onDeleteMessage,
  onResolveMessage,
}: Props) {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const messageDate = useMemo(() => {
    const date = dayjs(message.createdAt);
    return date.format('HH:mm MMM DD YYYY');
  }, [message]);

  const canResolveMessage = useMemo(
    () =>
      message.status === VariationMessageStatus.Unresolved &&
      currentUser.email !== message.user.email,
    [message.status, message.user, currentUser]
  );

  const canDeleteMessage = currentUser?.id === message.userId;

  return (
    <Box key={message.id}>
      <br />
      <Stack direction="row" spacing={2} style={styles.MessageHeader}>
        <Stack direction="row" spacing={2}>
          <Avatar {...stringAvatar(message.user?.email || '')} />
          <Typography style={styles.MessageAuthor}>
            {message.user?.email}
          </Typography>
          <Typography style={styles.MessageTime}>{messageDate}</Typography>
        </Stack>
        {(canResolveMessage || canDeleteMessage) && (
          <>
            {canEdit && (
              <IconButton onClick={handleClick}>
                <MoreHorizIcon />
              </IconButton>
            )}
            <Menu
              open={Boolean(anchorEl)}
              onClose={handleClose}
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              {canDeleteMessage && (
                <MenuItem
                  sx={{ px: 2 }}
                  disabled={!canDeleteMessage}
                  onClick={() => onDeleteMessage(message.id)}
                >
                  Delete
                </MenuItem>
              )}

              {canResolveMessage && (
                <MenuItem
                  sx={{ px: 2 }}
                  onClick={() => onResolveMessage(message.id)}
                >
                  Mark Resolved
                </MenuItem>
              )}
            </Menu>
          </>
        )}
      </Stack>

      <Typography style={styles.Message}>{message.message}</Typography>
      {currentUser.id !== message.userId && (
        <Typography style={styles.MessageSmall} color="GrayText">
          {message.status}
        </Typography>
      )}
      <br />
    </Box>
  );
}

const styles: { [key: string]: React.CSSProperties } = {
  MessageTime: {
    color: 'grey',
    marginTop: '9px',
  },
  MessageAuthor: {
    marginTop: '9px',
    fontWeight: 'bold',
  },
  Message: {
    marginTop: '10px',
    marginLeft: '56px',
  },
  MessageHeader: {
    width: '520px',
    display: 'flex',
    justifyContent: 'space-between',
  },
  MessageSmall: {
    fontSize: '13px',
    marginLeft: '56px',
  },
};

export { VariationChatMessage };
