import React from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import { Box, Button } from '@mui/material';
import { useQuery } from '@tanstack/react-query';

// API
import { Notification } from '../../../common/api';
import { listVariations } from '../../../common/api/variations';
import { SkeletonTable } from '../../../components/skeleton-table';

// Components
import { useListPagination } from '../../../common/hooks/useListPagination';
import { SubmittedVariationsTable } from './variationsTables/SubmittedVariationsTable';
import { AnticipatedVariationsTable } from './variationsTables/AnticipatedVariationsTable';

// Styles
import { SectionHeading } from '../../../styles';
import { SectionHeadingWithAction } from '../../../styles/text';

// Routes
import {
  VARIATION_NEW,
  VARIATION_NEW_DRAFT,
} from '../../../common/router-util/routes';

type Props = {
  contractId: string;
  handleClick?: (id: string) => void;
  notifications?: Array<Notification>;
  handleCheckbox?: (notification: Notification) => Promise<void>;
};

function VariationsMadeTab({
  contractId,
  handleClick,
  notifications,
  handleCheckbox,
}: Props) {
  const navigate = useNavigate();
  const { rowsPerPage, skip } = useListPagination();

  const { data: submittedData } = useQuery(
    ['submittedVariations', contractId],
    () =>
      listVariations(
        { contractId },
        {
          skip: skip,
          type: 'submitted',
          take: rowsPerPage,
        }
      )
  );

  const { data: anticipatedData } = useQuery(
    ['anticipatedVariations', contractId],
    () =>
      listVariations(
        { contractId },
        {
          skip: skip,
          type: 'anticipated',
          take: rowsPerPage,
        }
      )
  );

  return (
    <Box>
      <section>
        <SectionHeadingWithAction>
          <SectionHeading>Submitted Variation</SectionHeading>
          <Button
            variant="contained"
            sx={{ mb: '16px' }}
            onClick={() =>
              navigate(
                generatePath(VARIATION_NEW, {
                  contractId,
                })
              )
            }
          >
            Create New Variation
          </Button>
        </SectionHeadingWithAction>
        {submittedData ? (
          <SubmittedVariationsTable
            contractId={contractId}
            isMade
            data={submittedData}
            notifications={notifications}
            onClick={handleClick}
            onCheckboxClick={handleCheckbox}
          />
        ) : (
          <SkeletonTable />
        )}
      </section>
      <section>
        <SectionHeadingWithAction>
          <SectionHeading>Anticipated Variation</SectionHeading>
          <Button
            color="warning"
            variant="contained"
            sx={{ mb: '16px' }}
            onClick={() =>
              navigate(
                generatePath(VARIATION_NEW_DRAFT, {
                  contractId,
                })
              )
            }
          >
            Create Anticipated Variation
          </Button>
        </SectionHeadingWithAction>
        {anticipatedData ? (
          <AnticipatedVariationsTable
            contractId={contractId}
            data={anticipatedData}
            isMade
            notifications={notifications}
            onClick={handleClick}
          />
        ) : (
          <SkeletonTable />
        )}
      </section>
    </Box>
  );
}

export { VariationsMadeTab };
