import React, { useEffect, useState } from 'react';
import { Heading } from '../../../../styles';
import {
  Box,
  Button,
  Checkbox,
  DialogActions,
  Divider,
  FormControlLabel,
  FormGroup,
  SelectChangeEvent,
} from '@mui/material';
import { AdvancedSettings } from './AccordionSettings';
import { ColorSelect } from './ColorSelect';
import {
  NotificationColor,
  NotificationSetting,
  UpdateNotificationSetting,
} from '../../../../common/api';
import {
  initialPaymentDefaultsAdvancedSettings,
  initialPaymentDefaultsCheckedState,
} from './initialState';
import { mapNotificationSettingsToState } from './utils';
import { AdvancedSetting, CheckboxConfig } from './types';

type Props = {
  isDisabled: boolean;
  accountId: string;
  notificationSettings?: NotificationSetting[];
  handleSave: (paylod: UpdateNotificationSetting[]) => void;
  onClose: () => void;
};

function MyPaymentDefaultsTab({
  isDisabled,
  accountId,
  notificationSettings,
  handleSave,
  onClose,
}: Props) {
  const [checkedState, setCheckedState] = useState<Record<string, boolean>>(
    initialPaymentDefaultsCheckedState
  );
  const [advancedSettings, setAdvancedSettings] = useState<AdvancedSetting[]>(
    initialPaymentDefaultsAdvancedSettings
  );

  useEffect(() => {
    if (notificationSettings && notificationSettings.length > 0) {
      const { mappedCheckedState, mappedAdvancedSettings } =
        mapNotificationSettingsToState(notificationSettings);
      setCheckedState(mappedCheckedState);
      setAdvancedSettings(mappedAdvancedSettings);
    }
  }, [notificationSettings]);

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target;
    setCheckedState(prevState => ({
      ...prevState,
      [event.target.name]: checked,
    }));
  };

  const handleAdvancedSettingsChange = (
    event: React.ChangeEvent<
      HTMLInputElement | { name?: string; value: unknown }
    >,
    configName: string
  ) => {
    const { name, value } = event.target;

    setAdvancedSettings(prevSettings =>
      prevSettings.map(setting =>
        setting.name === configName
          ? { ...setting, [name as string]: value ?? '' }
          : setting
      )
    );
  };

  const handleSelectChange = (
    event: SelectChangeEvent<string>,
    index: number
  ) => {
    const { name, value } = event.target;
    setAdvancedSettings(prevSettings => {
      const newSettings = [...prevSettings];
      newSettings[index] = {
        ...newSettings[index],
        [name]: value,
      };
      return newSettings;
    });
  };

  const checkboxConfigs: CheckboxConfig[] = [
    {
      label: 'Payment to my contractor Overdue',
      name: 'paymentToContractorOverdue',
      alertColor: advancedSettings[0].alertColor,
      emailNotifications: advancedSettings[0].emailNotifications,
      advancedSettings: advancedSettings[0],
      example: 1,
    },
    {
      label: 'My Contractor Payment to Subcontractor Overdue',
      name: 'contractorPaymentToSubcontractorOverdue',
      alertColor: advancedSettings[1].alertColor,
      emailNotifications: advancedSettings[1].emailNotifications,
      advancedSettings: advancedSettings[1],
      example: 1,
    },
    {
      label: 'Supply Chain Subcontractor Overdue',
      name: 'supplyChainSubcontractorOverdue',
      alertColor: advancedSettings[2].alertColor,
      emailNotifications: advancedSettings[2].emailNotifications,
      advancedSettings: advancedSettings[2],
      example: 1,
    },
  ];

  const isCheckBoxDisabled = (checkboxName: string): boolean => {
    if (isDisabled) {
      return true;
    }

    const lockedCheckboxes = [
      'paymentToContractorOverdue',
      'contractorPaymentToSubcontractorOverdue',
      'supplyChainSubcontractorOverdue',
    ];

    return lockedCheckboxes.includes(checkboxName);
  };

  const isColorSelectDisabled = (colorSelectName: string): boolean => {
    if (isDisabled) {
      return true;
    }

    const lockedColorSelects = [
      'paymentToContractorOverdue',
      'contractorPaymentToSubcontractorOverdue',
      'supplyChainSubcontractorOverdue',
    ];

    return lockedColorSelects.includes(colorSelectName);
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', p: 2 }}>
      <Heading>Payment Default</Heading>

      <Box sx={{ my: 2 }}>
        <FormGroup sx={{ pb: 2 }}>
          {checkboxConfigs.map((config, index) => (
            <React.Fragment key={config.name}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={
                        checkedState[config.name as keyof typeof checkedState]
                      }
                      onChange={event => handleCheckboxChange(event)}
                      name={config.name}
                      disabled={isCheckBoxDisabled(config.name)}
                    />
                  }
                  label={config.label}
                />

                {checkedState[config.name as keyof typeof checkedState] && (
                  <>
                    <ColorSelect
                      value={config.alertColor}
                      onChange={event => handleSelectChange(event, index)}
                      name="alertColor"
                      disabled={isColorSelectDisabled(config.name)}
                    />
                    <Box sx={{ ml: 2, display: 'flex', alignItems: 'center' }}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={config.emailNotifications}
                            onChange={(
                              event: React.ChangeEvent<HTMLInputElement>
                            ) =>
                              setAdvancedSettings(prev => {
                                const newSettings = [...prev];
                                newSettings[index].emailNotifications =
                                  event.target.checked;
                                return newSettings;
                              })
                            }
                            name={`emailNotifications${index + 1}`}
                            disabled={true}
                          />
                        }
                        label="Email Notifications"
                      />
                    </Box>
                  </>
                )}
              </Box>

              {checkedState[config.name as keyof typeof checkedState] && (
                <AdvancedSettings
                  advancedSettings={{
                    minimumValue:
                      advancedSettings?.find(s => s.name === config.name)
                        ?.minimumValue ?? '',
                    timeDelay:
                      advancedSettings?.find(s => s.name === config.name)
                        ?.timeDelay ?? '',
                  }}
                  handleAdvancedSettingsChange={event =>
                    handleAdvancedSettingsChange(event, config.name)
                  }
                  isDisabled={isDisabled}
                />
              )}

              <Divider sx={{ mt: 1, mb: 3 }} />
            </React.Fragment>
          ))}
        </FormGroup>
        <DialogActions>
          <Button
            onClick={() => {
              const myPaymentsPayload = Object.keys(checkedState).map(
                (key, index) => ({
                  name: key,
                  isOn: checkedState[key],
                  accountId,
                  minimumValue: advancedSettings[index].minimumValue
                    ? parseFloat(advancedSettings[index].minimumValue ?? '')
                    : undefined,
                  timeDelay: advancedSettings[index].timeDelay
                    ? parseInt(advancedSettings[index].timeDelay ?? '', 10)
                    : undefined,
                  emailNotifications:
                    advancedSettings[index].emailNotifications,
                  alertColor: advancedSettings[index]
                    .alertColor as NotificationColor,
                })
              );

              handleSave(myPaymentsPayload);
            }}
            color="primary"
            variant="contained"
            disabled={isDisabled}
          >
            OK
          </Button>
          <Button onClick={onClose} color="secondary" variant="outlined">
            Cancel
          </Button>
        </DialogActions>
      </Box>
    </Box>
  );
}

export { MyPaymentDefaultsTab };
