import React, { useCallback, useMemo, useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Drawer,
  FormHelperText,
  InputAdornment,
  InputLabel,
  Stack,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import { StyledTable } from '../../../../../styles';
import { grey900, red700 } from '../../../../../styles/colours';
import { FileUploadDialog } from '../../../../../components/file-upload/FileUploadDropzone';
import { Controller, useForm } from 'react-hook-form';
import { useDialogState } from '../../../../../components/dialog/dialog.hooks';
import { FilesItem } from '../../../../contract-page/components/FilesItem';
import { SkeletonTable } from '../../../../../components/skeleton-table';
import { useMutation, useQuery } from '@tanstack/react-query';
import {
  AddAdjustClaimItemPayload,
  Adjust,
  adjustCreate,
  adjustDelete,
  AdjustmentFile,
  adjustUpdate,
  ClaimType,
} from '../../../../../common/api';
import {
  createAdjustFile,
  deleteAdjustFile,
  getAdjustFile,
  listAdjustFiles,
} from '../../../../../common/api/adjustFiles';
import { ClaimItemRow } from './ClaimTable.utils';
import { LoadingButton } from '@mui/lab';
import { useFileUpload } from '../../../../../components/file-upload/FileUpload';
import { ClaimAdjustmentOnlyView } from './ClaimAdjustmentOnlyView';
import {
  formatAmountByClaimType,
  formatAmountPercentInValue,
} from '../../../../../common/format';
import { useProject } from '../../../../../common/hooks/useProject';

const ADJUST_VALUE_FIELD = 'adjustValue';
const EXPLANATION_FIELD = 'explanation';

type FormData = {
  [ADJUST_VALUE_FIELD]: number | null;
  [EXPLANATION_FIELD]: string;
};

interface Props {
  open: boolean;
  onClose: () => void;
  edit?: boolean;
  claimItem: ClaimItemRow;
  reloadData: () => void;
  adjust?: Adjust;
}
function ClaimAdjust(props: Props) {
  const { open, onClose, edit, claimItem, reloadData, adjust } = props;
  const [adjustmentId, setAdjustmentId] = useState(adjust?.id || '');
  const [resetAdjust, setResetAdjust] = useState(false);
  const [files, setFiles] = useState<
    Array<{ file: File; path: string; contentType: string }>
  >([]);
  const [showDialog, setShowDialog] = useState(false);
  const { project } = useProject();

  const {
    isVisible: isUploadVisible,
    close: closeUpload,
    open: openUpload,
  } = useDialogState();

  const {
    control,
    watch,
    handleSubmit,
    reset,
    setError,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: useMemo(() => {
      return {
        [ADJUST_VALUE_FIELD]: adjust?.value,
        [EXPLANATION_FIELD]: adjust?.explanation,
      };
    }, [adjust?.value, adjust?.explanation]),
  });

  const adjustedValue = watch(ADJUST_VALUE_FIELD);
  const explanationValue = watch(EXPLANATION_FIELD);

  const {
    data: adjustFiles,
    isLoading: isAdjustFilesLoading,
    refetch: refetchAdjustFiles,
  } = useQuery<Array<AdjustmentFile>>(['adjust-files', adjust?.id], () => {
    if (adjust?.id) {
      return listAdjustFiles(adjust.id);
    }
    return [];
  });

  const { mutate: mutateCreateContractFile, isLoading: isCreateFilesLoading } =
    useMutation(
      (payload: { file: File; path: string; contentType: string }) =>
        createAdjustFile(adjustmentId!, {
          fileName: payload.file.name,
          path: payload.path,
          mimetype: payload.contentType,
        }),
      {
        onSuccess: () => refetchAdjustFiles(),
      }
    );

  const { mutate: mutateDeleteAdjustFile, isLoading: isDeleteFileLoading } =
    useMutation((fileId: string) => deleteAdjustFile(adjust?.id!, fileId), {
      onSuccess: () => refetchAdjustFiles(),
    });

  const { mutate: mutateDeleteAdjust, isLoading: isDeleteLoading } =
    useMutation(() => adjustDelete(adjust?.id!, claimItem.id!), {
      onSuccess: () => {
        setAdjustmentId('');
        setFiles([]);
        onClose();
        return reloadData();
      },
    });

  const { mutate: mutateAdjustClaimItem, isLoading: isAdjustClaimItemLoading } =
    useMutation(
      (data: AddAdjustClaimItemPayload) => {
        if (adjust?.id) {
          return adjustUpdate(adjust.id, claimItem.id, data);
        }
        return adjustCreate(claimItem.id, data);
      },
      {
        onSuccess: payload => {
          setAdjustmentId(payload.id);
          for (const file of files) {
            mutateGetUploadPath(file.file);
          }
          onClose();
          setFiles([]);
          return reloadData();
        },
      }
    );

  const { mutate: mutateGetContractFile } = useMutation(
    (fileId: string) => getAdjustFile(claimItem?.id!, fileId),
    {
      onSuccess: data => {
        const link = document.createElement('a');
        link.href = data.url;
        link.click();
        link.remove();
      },
    }
  );

  const { isLoading: isUploadFileLoading, mutateGetUploadPath } = useFileUpload(
    {
      onFileUploadSuccess: (data, variables) => {
        mutateCreateContractFile({
          file: variables.file,
          path: variables.path,
          contentType: variables.contentType,
        });
      },
    }
  );

  const handleDeleteContractFile = useCallback(
    (fileId: string) => {
      const adjustmentFile = adjustFiles?.find(({ id }) => id === fileId);

      if (adjustmentFile) {
        mutateDeleteAdjustFile(fileId);
      } else {
        const localDeleteFile = files.filter(file => file.path !== fileId);
        setFiles(localDeleteFile);
      }
    },
    [adjustFiles, files, mutateDeleteAdjustFile]
  );

  const handleDownloadContractFile = useCallback(
    async (fileId: string) => {
      const adjustmentFile = adjustFiles?.find(({ id }) => id === fileId);

      if (adjustmentFile) {
        mutateGetContractFile(fileId);
      } else {
        const downloadFile = files.find(file => file.path === fileId);
        const url = window.URL.createObjectURL(downloadFile?.file!);
        window.open(url, '_blank');
      }
    },
    [adjustFiles, files, mutateGetContractFile]
  );

  const onSubmit = useCallback(
    async (data: FormData) => {
      if (resetAdjust && data[ADJUST_VALUE_FIELD] === null) {
        await mutateDeleteAdjust();

        isDeleteLoading && setResetAdjust(false);

        return;
      }

      if (files && data[ADJUST_VALUE_FIELD] === null) {
        setError(ADJUST_VALUE_FIELD, { type: 'required' });
      }

      await mutateAdjustClaimItem(data);
      await reset(data);
      setResetAdjust(false);
    },
    [
      mutateAdjustClaimItem,
      reset,
      resetAdjust,
      isDeleteLoading,
      mutateDeleteAdjust,
      files,
      setError,
    ]
  );

  const handleCancel = useCallback(() => {
    reset({
      [ADJUST_VALUE_FIELD]: adjust?.value || null,
      [EXPLANATION_FIELD]: adjust?.explanation,
    });

    if (!resetAdjust) {
      setFiles([]);
    }

    onClose();
    setResetAdjust(false);
  }, [
    onClose,
    reset,
    adjust?.value,
    adjust?.explanation,
    setResetAdjust,
    resetAdjust,
  ]);

  const handleResetAdjust = useCallback(() => {
    setResetAdjust(true);
    reset({
      [ADJUST_VALUE_FIELD]: null,
      [EXPLANATION_FIELD]: '',
    });

    setFiles([]);
  }, [reset]);

  const handleOnCloseDrawer = useCallback(() => {
    if (
      adjustedValue ||
      explanationValue ||
      files.length !== 0 ||
      resetAdjust
    ) {
      setShowDialog(true);

      return;
    }

    onClose();
  }, [onClose, adjustedValue, explanationValue, files, resetAdjust]);

  const isLoading =
    isAdjustFilesLoading ||
    isCreateFilesLoading ||
    isDeleteFileLoading ||
    isUploadFileLoading;

  const variance = useMemo(
    () => (adjustedValue ? adjustedValue - claimItem.value : 0),
    [adjustedValue, claimItem.value]
  );

  const validate = (value: string) => {
    const matches = value.match(
      /^(?:0\.(?:0[0-9]|[0-9]\d?)|[0-9]\d*(?:\.\d{1,2})?)(?:e[+-]?\d+)?$/
    );

    return (matches?.length || 0) > 0 || 'Not a Number';
  };

  if (!edit) {
    return (
      <ClaimAdjustmentOnlyView
        adjustmentFiles={adjustFiles}
        open={open}
        adjust={adjust}
        onClose={onClose}
        claimItem={claimItem}
      />
    );
  }

  return (
    <Drawer
      anchor={'right'}
      PaperProps={{
        sx: { p: 3 },
      }}
      sx={{
        '& .MuiDrawer-paper': {
          marginTop: '0px',
          height: '100%',
        },
      }}
      open={open}
      onClose={() => {
        handleOnCloseDrawer();
      }}
    >
      {showDialog && (
        <Dialog open={open}>
          <DialogTitle id="alert-dialog-title">
            Do you want to save your changes?
          </DialogTitle>
          <DialogActions>
            <Button
              onClick={() => {
                handleCancel();
                setShowDialog(false);
              }}
            >
              No
            </Button>
            <Button
              onClick={() => {
                setShowDialog(false);
                handleSubmit(onSubmit)();
              }}
              autoFocus
            >
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      )}
      <Typography variant="h6" sx={{ fontSize: '20px', mb: 1 }}>
        Adjustment Reason
      </Typography>
      <Typography variant="body2" sx={{ fontSize: '12px', mb: 3 }}>
        {claimItem.identifier}
      </Typography>
      <StyledTable sx={{ borderTop: 'none', mb: 3 }}>
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell>
              <Typography sx={styles.TableCell}>Claimed Value</Typography>
            </TableCell>
            <TableCell>
              <Typography sx={styles.TableCell}>Adjusted Value</Typography>
            </TableCell>
            <TableCell>
              <Typography sx={styles.TableCell}>Variance</Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>
              <Typography sx={styles.TableCell}>
                {claimItem.description}
              </Typography>
            </TableCell>
            <TableCell align="right">
              <Typography variant="body1" sx={{ fontSize: '16px' }}>
                {formatAmountPercentInValue(
                  claimItem.value,
                  claimItem.claimType,
                  claimItem.taskValue
                )}
              </Typography>
            </TableCell>
            <TableCell>
              <Controller
                name={ADJUST_VALUE_FIELD}
                control={control}
                defaultValue={null}
                rules={{
                  required: !resetAdjust && 'Adjust value is required',
                  validate: value => {
                    const newValue = resetAdjust && value === null ? 0 : value;
                    return validate(`${newValue}`);
                  },
                }}
                render={({ field, field: { value } }) => {
                  return (
                    <TextField
                      {...field}
                      value={value == null ? '' : value}
                      placeholder={'0'}
                      error={!!errors[ADJUST_VALUE_FIELD]}
                      helperText={errors[ADJUST_VALUE_FIELD]?.message}
                      size="small"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            {claimItem.claimType === ClaimType.value
                              ? '$'
                              : '%'}
                          </InputAdornment>
                        ),
                      }}
                    />
                  );
                }}
              />
            </TableCell>
            <TableCell align="right">
              <Typography variant="body1" sx={{ fontSize: '16px' }}>
                {formatAmountByClaimType(variance, claimItem.claimType)}
              </Typography>
            </TableCell>
          </TableRow>
        </TableBody>
      </StyledTable>

      <Stack sx={{ mb: 3 }}>
        <Controller
          name={EXPLANATION_FIELD}
          control={control}
          rules={{
            required: !resetAdjust || adjustedValue !== null,
          }}
          render={({ field }) => (
            <>
              <InputLabel
                error={!!errors[EXPLANATION_FIELD]}
                htmlFor="explanation-input"
              >
                <Typography variant="body1" sx={{ fontSize: '16px' }}>
                  * Explanation
                </Typography>
              </InputLabel>
              <TextField
                {...field}
                id="explanation-input"
                placeholder={'Value'}
                fullWidth
                multiline
                rows={6}
                error={!!errors[EXPLANATION_FIELD]}
              />
              <FormHelperText>
                You must include an explanation for your adjustment
              </FormHelperText>
            </>
          )}
        />
      </Stack>

      <Box display="flex" justifyContent="start" marginBottom="16px">
        <Button onClick={openUpload} variant="outlined">
          Upload File
        </Button>
      </Box>
      {!isLoading ? (
        <Stack gap={2}>
          {adjustFiles?.map(
            adjustFile =>
              !resetAdjust && (
                <FilesItem
                  key={adjustFile.id}
                  file={{
                    id: adjustFile.id,
                    name: adjustFile.name,
                    createdAt: adjustFile.createdAt,
                    uploaderEmail: adjustFile.uploader.email,
                  }}
                  editMode={edit}
                  onDeleteFile={handleDeleteContractFile}
                  onDownloadFile={handleDownloadContractFile}
                />
              )
          )}
          {files?.map(file => (
            <FilesItem
              key={file.path}
              file={{
                id: file.path,
                name: file.file.name,
                createdAt: new Date(project?.systemDate || new Date()),
                uploaderEmail: '',
              }}
              editMode={edit}
              onDeleteFile={handleDeleteContractFile}
              onDownloadFile={handleDownloadContractFile}
            />
          ))}
        </Stack>
      ) : (
        <SkeletonTable />
      )}

      <Stack
        sx={{ mt: 3 }}
        justifyContent="space-between"
        alignItems="center"
        direction="row"
      >
        <LoadingButton
          variant="outlined"
          color="error"
          onClick={handleResetAdjust}
          disabled={!adjust}
          sx={{ color: red700, borderColor: red700 }}
        >
          Reset Adjustment
        </LoadingButton>
        <Stack direction="row" gap={2} alignItems="center">
          <Button variant="outlined" onClick={handleCancel}>
            Cancel
          </Button>
          <LoadingButton
            loading={isAdjustClaimItemLoading || isDeleteLoading}
            variant="contained"
            onClick={handleSubmit(onSubmit)}
          >
            Save
          </LoadingButton>
        </Stack>
      </Stack>

      {isUploadVisible && (
        <FileUploadDialog
          showLoading={isCreateFilesLoading}
          isLocal
          onFileUpload={payload => {
            setFiles(prev => [...prev, payload]);
          }}
          close={closeUpload}
        />
      )}
    </Drawer>
  );
}

const styles = {
  TableCell: {
    fontWeight: 500,
    fontSize: '16px',
    color: grey900,
  },
};

export { ClaimAdjust };
