import React from 'react';
import { ClaimStatus } from '../../common/api';
import {
  ChipAlert,
  ChipDanger,
  ChipOk,
  ChipReview,
  ChipYellow,
} from '../../styles/chips';

interface Props {
  status: string;
  isCustomer?: boolean;
  isOverdue?: boolean;
}
function ChipStatus({ status, isCustomer, isOverdue }: Props) {
  if (status === ClaimStatus.Submitted) {
    if (isCustomer) {
      return <ChipYellow label="Received & Under Review" />;
    }
    if (isOverdue) {
      return <ChipDanger label="Submit OVERDUE" />;
    }
    return <ChipAlert label={status} />;
  }

  if (status === ClaimStatus.Rejected) {
    return <ChipDanger label="Payment Schedule Rejected" />;
  }

  if (status === ClaimStatus.Withdrawn) {
    return <ChipDanger label={status} />;
  }

  if (status === ClaimStatus.Draft) {
    return <ChipReview label={status} />;
  }

  if (status === ClaimStatus.Awaiting) {
    if (isCustomer) {
      return <ChipAlert label="Responded & Awaiting Acceptance" />;
    }
    return <ChipYellow label={'Awaiting Acceptance'} />;
  }

  if (status === ClaimStatus.Approved) {
    if (isOverdue) {
      return <ChipDanger label="Complete - Payment OVERDUE" />;
    }

    return <ChipOk label="Complete - Payment Due" />;
  }

  if (status === ClaimStatus.Paid) {
    return <ChipOk label="Paid" />;
  }

  return <ChipOk label={status} />;
}

export { ChipStatus };
