import React, { FC, useMemo } from 'react';

import {
  HeadCell,
  useSortedTable,
} from '../../../../components/sorted-table-head/SortedTable.hooks';
import {
  DOLLAR,
  Notification,
  PERCENT,
  ResponseListBaseType,
  VariationListResource,
  VariationStatus,
} from '../../../../common/api';

import { submittedHeadCells } from './tableHeads';
import { createVariationRow } from '../../utils/variationTable.utils';
import { SkeletonTable } from '../../../../components/skeleton-table';
import {
  StyledCompactTable,
  StyledTableRow,
  StyledTooltip,
} from '../../../../styles';
import { SortedTableHead } from '../../../../components/sorted-table-head';
import { Box, Checkbox, TableBody, TableCell, Typography } from '@mui/material';
import { stableSort } from '../../../../common/sort/stableSort';
import { getComparator } from '../../../../common/sort/getComparator';
import { generatePath, useNavigate } from 'react-router-dom';
import {
  VARIATION_EXISTING,
  VARIATION_RECEIVED,
} from '../../../../common/router-util/routes';
import CircleIcon from '@mui/icons-material/Circle';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import { getShortStatus } from '../../../variations/utils';
import {
  formatAmountByType,
  formatPercentageValue,
} from '../../../../common/format';
import { Pagination } from '../../../../components/pagination';
import { useAuth } from '../../../../common/auth-provider/AuthProvider';
import { brand700 } from '../../../../styles/colours';
import { useListPagination } from '../../../../common/hooks/useListPagination';

interface Props {
  contingencyBudget?: string;
  contractId: string;
  data?: ResponseListBaseType<VariationListResource[]>;
  isMade?: boolean;
  notifications?: Array<Notification>;
  onClick?: (id: string) => void;
  onCheckboxClick?: (notification: Notification) => Promise<void>;
}

const styles = {
  Icon: {
    color: brand700,
  },
};

export const SubmittedVariationsTable: FC<Props> = ({
  contingencyBudget,
  contractId,
  data,
  isMade = false,
  notifications,
  onCheckboxClick,
  onClick,
}) => {
  const navigate = useNavigate();
  const { user } = useAuth();

  const { page, rowsPerPage, handleChangePage } = useListPagination();

  const { order, orderBy, handleRequestSort } = useSortedTable('dateSubmitted');

  const variations =
    data?.data.filter(
      variation => variation.status !== VariationStatus.Draft
    ) || [];
  const variationRows =
    variations.map(d =>
      createVariationRow(d, user?.accountId, notifications)
    ) || [];

  const updatedHeadCells: Array<HeadCell> = useMemo(() => {
    let headCells = submittedHeadCells;
    if (isMade) {
      headCells = submittedHeadCells.filter(
        ({ id }) => id !== 'totalContingency'
      );
    }
    if (notifications?.length) {
      return [
        {
          id: 'notificationIsRead',
          numeric: false,
          disable: true,
          disablePadding: false,
          label: '',
        },
        ...headCells,
      ];
    }
    return headCells;
  }, [isMade, notifications?.length]);

  return (
    <Box>
      {!data ? (
        <SkeletonTable />
      ) : (
        <>
          <StyledCompactTable sx={{ mb: 2 }}>
            <SortedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              headCells={updatedHeadCells}
              data={variations}
            />
            <TableBody>
              {stableSort(variationRows, getComparator(order, orderBy)).map(
                row => {
                  const notification = notifications?.find(
                    el => el.id === row?.notificationId
                  );
                  const isApproved = row.status === VariationStatus.Approved;
                  return (
                    <StyledTableRow
                      key={row.id}
                      hover
                      rowStatus={row.status as VariationStatus}
                      isClosed={row.isClosed}
                      onClick={() => {
                        if (onClick && row.notificationId) {
                          onClick(row.notificationId);
                        }
                        navigate(
                          generatePath(
                            isMade ? VARIATION_EXISTING : VARIATION_RECEIVED,
                            {
                              contractId,
                              variationId: row.id,
                            }
                          )
                        );
                      }}
                    >
                      {notifications?.length ? (
                        <TableCell>
                          {notification && (
                            <StyledTooltip
                              title={
                                notification.isRead
                                  ? 'Mark Unread'
                                  : 'Mark Read'
                              }
                              placement="top"
                            >
                              <Checkbox
                                checked={notification.isRead}
                                onClick={event => {
                                  event.stopPropagation();
                                  if (onCheckboxClick && row.notificationId) {
                                    onCheckboxClick(notification);
                                  }
                                }}
                                icon={<CircleIcon sx={styles.Icon} />}
                                checkedIcon={
                                  <RadioButtonUncheckedIcon sx={styles.Icon} />
                                }
                              />
                            </StyledTooltip>
                          )}
                        </TableCell>
                      ) : null}
                      <TableCell>
                        <Typography variant={'body1'}>
                          {row.subContractor}
                        </Typography>
                        <Typography variant={'caption'}>
                          {row.caption}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant={'body2'}>
                          {row.identifier}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant={'body2'}>
                          {row.description}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant={'body2'}>
                          {row.submittedAt}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant={'body2'}>
                          {row.dateResponded}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        {getShortStatus(row.status as VariationStatus)}
                      </TableCell>
                      <TableCell>
                        <Typography variant={'body2'}>
                          {isApproved
                            ? formatAmountByType(
                                Number(row.maxVarianceValue),
                                row.valueType === DOLLAR ? DOLLAR : PERCENT
                              )
                            : formatAmountByType(
                                Number(row.totalValue),
                                row.valueType === DOLLAR ? DOLLAR : PERCENT
                              )}
                        </Typography>
                      </TableCell>
                      {!isMade && (
                        <TableCell>
                          <Typography variant={'body2'}>
                            {contingencyBudget
                              ? formatPercentageValue(
                                  Number(row.totalValue),
                                  Number(contingencyBudget)
                                )
                              : 0}
                          </Typography>
                        </TableCell>
                      )}
                      <TableCell>
                        <Typography variant={'body2'}>
                          {isApproved &&
                            formatAmountByType(
                              Number(row.totalValue),
                              row.valueType === DOLLAR ? DOLLAR : PERCENT
                            )}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant={'body2'}>
                          {isApproved &&
                            formatAmountByType(
                              Number(row.maxVarianceValue) -
                                Number(row.totalValue),
                              row.valueType === DOLLAR ? DOLLAR : PERCENT
                            )}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant={'body2'}>
                          {row.anticipatedId !== '0'
                            ? `AV# ${row.anticipatedId}`
                            : ''}
                        </Typography>
                      </TableCell>
                    </StyledTableRow>
                  );
                }
              )}
            </TableBody>
          </StyledCompactTable>
          <Pagination
            count={Math.ceil((data?.meta.total || 0) / rowsPerPage)}
            handleChangePage={handleChangePage}
            page={page}
            total={data?.meta?.total || 0}
          />
        </>
      )}
    </Box>
  );
};
