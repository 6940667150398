import React, { useMemo } from 'react';
import { AlertColor } from '@mui/material';
import { createPortal } from 'react-dom';
import { useMutation } from '@tanstack/react-query';
import { generateHTML } from '@tiptap/core';

import { KebabMenu } from '../../../components/menu/KebabMenu';
import { useDialogState } from '../../../components/dialog/dialog.hooks';
import { useConfirmDialog } from '../../../common/hooks/useConfirmDialog';
import {
  deleteReportItem,
  deleteReportItemPayload,
} from '../../../common/api/reports';
import { ReportCellKebab, ReportCellWrapper } from '../ReportCell.style';
import EditTextBoxTile from './EditTextBoxTile';
import { RICHTEXT_EXTENSIONS } from './Richtext.constants';
import { Tile, TileRichtext } from '../BasicTileItem.styles';
import { ReportItem } from '../../../common/api/report-types';

type Props = {
  reportItem: ReportItem;
  isEditingDashboard: boolean;
  reload: () => void;
  showAlert: (title: string, severity: AlertColor) => void;
};

const TextBoxTile = ({
  reportItem,
  isEditingDashboard,
  reload,
  showAlert,
}: Props) => {
  const {
    isVisible: isEditVisible,
    open: openEdit,
    close: closeEdit,
  } = useDialogState();
  const { confirmAction, ConfirmDialog } = useConfirmDialog();

  const { mutateAsync: mutatedeleteReportItem } = useMutation(
    (payload: deleteReportItemPayload) => deleteReportItem(payload),
    {
      onSuccess: () => {
        reload();
        showAlert('Tile removed!', 'success');
      },
      onError: () =>
        showAlert(
          'There was an error deleting the tile. Please try again later.',
          'error'
        ),
    }
  );

  const el = document.getElementById('report-actions');

  const id = reportItem.id;
  const reportId = reportItem.reportId;
  const richtext = reportItem.richtext || null;

  const data = useMemo(
    () =>
      richtext === null ? '' : generateHTML(richtext, RICHTEXT_EXTENSIONS),
    [richtext]
  );

  return (
    <Tile>
      {el &&
        createPortal(
          <>
            {isEditVisible && (
              <EditTextBoxTile
                id={id}
                reportId={reportId}
                richtext={richtext}
                style={reportItem.style}
                close={closeEdit}
                reload={reload}
                showAlert={showAlert}
              />
            )}
          </>,
          el
        )}

      <ConfirmDialog />
      {!isEditingDashboard && (
        <ReportCellKebab>
          <KebabMenu
            options={['Tile Options', 'Remove Tile']}
            onOptionSelect={async option => {
              if (option === 'Remove Tile') {
                await confirmAction(
                  'Are you sure you want to remove this tile?'
                );
                await mutatedeleteReportItem({
                  reportId,
                  id,
                });
              } else if (option === 'Tile Options') {
                openEdit();
              }
            }}
          />
        </ReportCellKebab>
      )}

      <ReportCellWrapper>
        <TileRichtext dangerouslySetInnerHTML={{ __html: data }}></TileRichtext>
      </ReportCellWrapper>
    </Tile>
  );
};

export default TextBoxTile;
