import React, { useState } from 'react';
import { Button, Stack } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';

import '../../../node_modules/react-grid-layout/css/styles.css';
import '../../../node_modules/react-resizable/css/styles.css';

import { AddProjectDialog } from '../project-page/components/AddProjectDialog';
import { useDialogState } from '../../components/dialog/dialog.hooks';
import { Heading } from '../../styles';
import { Report } from '../../layouts/reports/Report';
import { ReportItemStyle } from '../../common/api/report-types';
import { getProjectReport } from '../../common/api/reports';

function ProjectListPage() {
  const queryClient = useQueryClient();

  const [containerRef, setContainerRef] = useState<HTMLDivElement | null>(null);

  // modal state
  const { isVisible: isProjectDialogVisible, open, close } = useDialogState();

  return (
    <>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ marginBottom: '24px' }}
        ref={setContainerRef}
      >
        <Heading sx={{ marginBottom: '0px', fontWeight: '500' }}>
          Project Portfolio
        </Heading>
        <Stack direction="row" spacing={2}>
          <Button variant="contained" onClick={open}>
            Create a Project
          </Button>
        </Stack>
      </Stack>
      <Report
        containerEl={containerRef}
        renderActions={(reportItems, isDefaultReport, refetchReporData) => {
          const projectListItem = reportItems?.find(
            r => r.style === ReportItemStyle.ProjectList
          );
          return (
            <>
              {isProjectDialogVisible && (
                <AddProjectDialog
                  projectListItem={projectListItem}
                  reloadProjects={async () => {
                    const reportItemId = projectListItem?.id;
                    const report = await getProjectReport({
                      reportItemId: reportItemId || '',
                      isDefaultReport: isDefaultReport,
                    });
                    // update project list tile cache
                    queryClient.setQueryData(
                      [
                        isDefaultReport
                          ? 'projectListReportAll'
                          : 'projectListReport',
                        reportItemId,
                      ],
                      report
                    );
                    refetchReporData();
                  }}
                  close={close}
                />
              )}
            </>
          );
        }}
      />
    </>
  );
}

export { ProjectListPage };
