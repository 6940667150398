import { Contract, User } from '../../../common/api';
import { useOutletContext } from 'react-router-dom';
import { DashboardOutletContext } from '../../../layouts/dashboard-layout';
import React, { useCallback, useEffect } from 'react';
import { Box } from '@mui/material';
import { HeaderBreadcrumbs } from '../../../components/header-breadcrumbs';
import { generateBreadcrumb } from '../utils/contractPage.utils';

export const useContractsBreadcrumb = ({
  contract,
  user,
}: {
  contract?: Contract;
  user: User;
}) => {
  const { setTitle } = useOutletContext<DashboardOutletContext>();

  const buildBreadcrumb = useCallback(
    (node: Contract) => generateBreadcrumb(user.accountId)(node),
    [user.accountId]
  );

  useEffect(() => {
    if (contract && user) {
      setTitle(
        <Box sx={{ pv: 2 }}>
          <HeaderBreadcrumbs links={buildBreadcrumb(contract)} />
        </Box>
      );
    }

    return () => {
      setTitle(<></>);
    };
  }, [user, contract, setTitle, buildBreadcrumb]);
};
