import React, { useCallback, useState } from 'react';
import {
  Controller,
  SubmitHandler,
  useFieldArray,
  useForm,
} from 'react-hook-form';
import { useMutation } from '@tanstack/react-query';
import {
  AlertColor,
  Box,
  Button,
  Dialog,
  DialogContent,
  FormControl,
  IconButton,
  InputLabel,
  ListSubheader,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import DeleteIcon from '@mui/icons-material/Delete';

import { Heading, InputActions } from '../../../styles';
import {
  updateReportItem,
  UpdateReportItemPayload,
} from '../../../common/api/reports';
import { TileColour } from '../ReportTile.style';
import { useDialogState } from '../../../components/dialog/dialog.hooks';
import ColourPicker from '../ColourPicker';
import { REPORT_METRIC_COLOURS } from '../Report.constants';
import {
  Denomination,
  ReportMetricGroup,
} from '../../../common/api/report-types';
import { useReportMetrics } from '../Report.hooks';

const METRIC_FIELD = 'metric';
const HEADING_FIELD = 'heading';
const COLOUR_FIELD = 'colours';
const METRIC_GROUP_ID_FIELD = 'id';

type FormData = {
  [HEADING_FIELD]: string;
  metrics: {
    [METRIC_GROUP_ID_FIELD]?: string;
    [METRIC_FIELD]: string;
    [COLOUR_FIELD]: string;
  }[];
};

type Props = {
  id: string;
  metricGroups?: ReportMetricGroup[];
  heading?: string | null;
  reportId: string;
  reload: () => void;
  close: () => void;
  showAlert: (title: string, severity: AlertColor) => void;
};

function EditPieChartTile({
  id,
  metricGroups,
  heading,
  reportId,
  close,
  reload,
  showAlert,
}: Props) {
  const [selectedRow, setSelectedRow] = useState<number>();

  const {
    isVisible: isColourPickerVisible,
    open: openColourPicker,
    close: closeColourPicker,
  } = useDialogState();

  const { isMetricListLoading, groupedMetrics } = useReportMetrics();

  const {
    control,
    formState: { errors },
    watch,
    handleSubmit,
    reset,
  } = useForm<FormData>({
    defaultValues: {
      [HEADING_FIELD]: heading || '',
      metrics:
        metricGroups && metricGroups.length > 0
          ? metricGroups?.map(m => ({
              id: m.id,
              metric: m.metricId,
              colours: m.colour,
            }))
          : [
              {
                metric: '',
                colours: REPORT_METRIC_COLOURS[0],
              },
            ],
    },
  });

  const { fields, append, remove, update } = useFieldArray({
    control,
    name: 'metrics',
  });

  const watchSelectedMetrics = watch('metrics');
  const controlledFields = fields.map((field, index) => {
    return {
      ...field,
      ...watchSelectedMetrics[index],
    };
  });

  const handleCloseDialog = useCallback(() => {
    close();
    reset();
  }, [close, reset]);

  const { mutateAsync: mutateUpdate, isLoading } = useMutation(
    (payload: UpdateReportItemPayload) => updateReportItem(payload),
    {
      onSuccess: () => {
        reload();
        handleCloseDialog();
      },
      onError: () =>
        showAlert(
          'There was an error updating the tile. Please try again later.',
          'error'
        ),
    }
  );

  const onSubmit = useCallback<SubmitHandler<FormData>>(
    (data: FormData) => {
      const existing = data.metrics.filter(m => m[METRIC_GROUP_ID_FIELD]);
      const newItems = data.metrics.filter(m => !m[METRIC_GROUP_ID_FIELD]);
      mutateUpdate({
        reportId,
        id,
        updatedReportItem: {
          id,
          reportId,
          heading: data[HEADING_FIELD],
          subHeading: null,
          imageKey: null,
          richtext: null,
          metricGroups:
            existing.map(m => ({
              id: m[METRIC_GROUP_ID_FIELD] || '',
              colour: m[COLOUR_FIELD],
              decimalPlaces: 0,
              denomination: Denomination.Actual,
              metricId: m[METRIC_FIELD],
              reportItemId: id,
            })) || [],
          newMetricGroups:
            newItems.map(m => ({
              colour: m[COLOUR_FIELD],
              decimalPlaces: 0,
              denomination: Denomination.Actual,
              metricId: m[METRIC_FIELD],
              reportItemId: id,
            })) || [],
        },
      });
    },
    [id, reportId, mutateUpdate]
  );

  return (
    <>
      {isColourPickerVisible && (
        <ColourPicker
          selectedColour={controlledFields[selectedRow || 0][COLOUR_FIELD]}
          onSelect={colour => {
            update(selectedRow || 0, {
              [COLOUR_FIELD]: colour,
              [METRIC_FIELD]: controlledFields[selectedRow || 0][METRIC_FIELD],
            });
          }}
          onClose={closeColourPicker}
        />
      )}

      <Dialog open onClose={handleCloseDialog}>
        <DialogContent sx={{ width: '415px' }}>
          <form onSubmit={handleSubmit(onSubmit)} noValidate>
            <Heading>Pie Chart Tile Options</Heading>

            <FormControl style={styles.FormControl}>
              <Controller
                name={HEADING_FIELD}
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Heading"
                    style={styles.Field}
                    error={!!errors[HEADING_FIELD]}
                  />
                )}
              />
            </FormControl>

            {controlledFields.map((item, index) => (
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                gap="8px"
                marginBottom="8px"
                key={item.id}
              >
                <input
                  type="hidden"
                  name={`metrics.${index}.${METRIC_GROUP_ID_FIELD}`}
                  value={metricGroups?.[index]?.id}
                />
                <div>
                  <Controller
                    name={`metrics.${index}.${COLOUR_FIELD}`}
                    control={control}
                    rules={{
                      required: 'Colour selection is required',
                    }}
                    render={({ field }) => (
                      <input
                        {...field}
                        type="text"
                        style={{
                          visibility: 'hidden',
                          position: 'absolute',
                          left: '-10000px',
                        }}
                      />
                    )}
                  />
                  <TileColour
                    type="button"
                    style={{
                      width: '55px',
                      flexShrink: 0,
                      flexGrow: 0,
                      backgroundColor:
                        controlledFields?.[index][COLOUR_FIELD] ||
                        REPORT_METRIC_COLOURS[0],
                    }}
                    onClick={() => {
                      setSelectedRow(index);
                      openColourPicker();
                    }}
                  />
                </div>

                <FormControl
                  style={{
                    flexGrow: 1,
                  }}
                >
                  <InputLabel id="metric-select-label">Metric</InputLabel>
                  <Controller
                    name={`metrics.${index}.${METRIC_FIELD}`}
                    rules={{
                      required: 'Metric selection is required',
                    }}
                    control={control}
                    render={({ field }) => (
                      <Select
                        {...field}
                        labelId="metric-select-label"
                        label="Metric"
                        required
                        disabled={isMetricListLoading}
                        error={!!errors['metrics']?.[index]?.metric}
                      >
                        {groupedMetrics.map(item => {
                          if (typeof item === 'string') {
                            return (
                              <ListSubheader key={item}>
                                {item === 'null' ? 'Other' : item}
                              </ListSubheader>
                            );
                          }
                          const isNotAvailable =
                            item.group === 'Claim Compliance' ||
                            item.group === 'Payment Compliance';
                          return (
                            <MenuItem
                              key={item.id}
                              value={item.id}
                              disabled={isNotAvailable}
                            >
                              {item.title}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    )}
                  />
                </FormControl>
                <IconButton
                  sx={{ width: '40px', flexShrink: 0 }}
                  onClick={() => {
                    remove(index);
                  }}
                >
                  <DeleteIcon width={20} />
                </IconButton>
              </Box>
            ))}

            <Button
              variant="outlined"
              onClick={() => {
                append({
                  [COLOUR_FIELD]: REPORT_METRIC_COLOURS[0],
                  [METRIC_FIELD]: '',
                });
              }}
            >
              Add another Metric
            </Button>

            <InputActions>
              <Button variant="outlined" onClick={handleCloseDialog}>
                Cancel
              </Button>
              <LoadingButton
                type="submit"
                variant="contained"
                loading={isLoading}
              >
                Save
              </LoadingButton>
            </InputActions>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
}

const styles: { [key: string]: React.CSSProperties } = {
  FormControl: {
    margin: 0,
    width: '100%',
  },
  Field: {
    margin: '0 0 16px 0',
  },
};

export default EditPieChartTile;
