import React, { useCallback } from 'react';
import {
  Box,
  Button,
  Divider,
  Drawer,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import {
  Claim,
  ContractFile,
  createClaimFile,
  deleteClaimFile,
  getClaimFile,
} from '../../../../../common/api';
import { FilesItem } from '../../../components/FilesItem';
import { FileUploadDialog } from '../../../../../components/file-upload/FileUploadDropzone';
import { useDialogState } from '../../../../../components/dialog/dialog.hooks';
import CloseIcon from '@mui/icons-material/Close';
import { FileDrawerComplianceLoader } from './FileDrawerComplianceLoader';

interface Props {
  claim: Claim;
  open: boolean;
  canUpload: boolean;
  canDelete: boolean;
  onClose: () => void;
  reloadClaim?: () => void;
}

function ClaimFileDrawer({
  claim,
  open,
  canUpload = true,
  canDelete = true,
  onClose,
  reloadClaim,
}: Props) {
  const {
    isVisible: isUploadVisible,
    close: closeUpload,
    open: openUpload,
  } = useDialogState();

  const { mutate: mutateGetContractFile } = useMutation(
    (fileId: string) => getClaimFile(claim.id, fileId),
    {
      onSuccess: data => {
        const link = document.createElement('a');
        link.href = data.url;
        link.click();
        link.remove();
      },
    }
  );

  const { mutate: mutateCreateFile, isLoading: isLoadingMutateCreateFile } =
    useMutation(
      (payload: {
        file: File | ContractFile;
        path: string;
        contentType: string;
      }) =>
        createClaimFile(claim.id, {
          fileName: payload.file.name,
          path: payload.path,
          mimetype: payload.contentType,
        }),
      {
        onSuccess: () => {
          reloadClaim?.();
        },
      }
    );

  const { mutate: mutateDeleteFile } = useMutation(
    (fileId: string) => deleteClaimFile(claim.id, fileId),
    {
      onSuccess: () => {
        reloadClaim?.();
      },
    }
  );

  const handleCreateFile = useCallback(
    async (selectedFiles: Array<ContractFile>) => {
      for (const selectedFile of selectedFiles) {
        mutateCreateFile({
          file: selectedFile,
          path: selectedFile.path,
          contentType: selectedFile.mimetype,
        });
      }
    },
    [mutateCreateFile]
  );

  const handleDownloadFile = useCallback(
    async (fileId: string) => {
      mutateGetContractFile(fileId);
    },
    [mutateGetContractFile]
  );

  const handleDeleteFile = useCallback(
    async (fileId: string) => {
      mutateDeleteFile(fileId);
    },
    [mutateDeleteFile]
  );

  return (
    <Drawer
      anchor={'right'}
      sx={{
        '& .MuiDrawer-paper': {
          marginTop: '0px',
          width: '380px',
          height: '100%',
        },
      }}
      open={open}
      onClose={onClose}
    >
      <Box>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            m: 1,
            color: theme => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <Divider />
        <Stack gap={2} sx={{ p: '20px' }}>
          {canUpload && (
            <>
              <FileDrawerComplianceLoader
                handleCreateFile={handleCreateFile}
                isLoadingMutateCreateFile={isLoadingMutateCreateFile}
                files={claim?.claimFiles || []}
              />
              <Button variant="outlined" sx={{ mb: 1 }} onClick={openUpload}>
                Upload file from Computer
              </Button>
            </>
          )}
          {claim.claimFiles.length === 0 && (
            <Typography>No files to show</Typography>
          )}
          <Stack gap={2}>
            {claim.claimFiles?.map(file => (
              <FilesItem
                key={file.id}
                file={{
                  id: file.id,
                  name: file.name,
                  createdAt: file.createdAt,
                  uploaderEmail: file.uploader.email,
                }}
                editMode={canDelete}
                onDownloadFile={handleDownloadFile}
                onDeleteFile={handleDeleteFile}
              />
            ))}
          </Stack>

          {isUploadVisible && (
            <FileUploadDialog
              showLoading={isLoadingMutateCreateFile}
              onFileUpload={payload => {
                mutateCreateFile({
                  file: payload.file,
                  path: payload.path,
                  contentType: payload.contentType,
                });
              }}
              close={closeUpload}
            />
          )}
        </Stack>
      </Box>
    </Drawer>
  );
}

export { ClaimFileDrawer };
