import React, { useCallback, useMemo } from 'react';
import { AxiosError } from 'axios';
import { Box, Button, Divider, Typography } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import pluralize from 'pluralize';

import {
  ClaimType,
  Contract,
  ContractStatus,
  ResponseListBaseType,
  SaveCurrentClaimPayload,
  saveToCurrentClaim,
  Task,
} from '../../../../../common/api';

// Components
import { AddTask } from '../../contract-approve-view/components/AddTask';
import { Pagination } from '../../../../../components/pagination';
import { SkeletonTable } from '../../../../../components/skeleton-table';
import { TaskDetails } from '../../../../task-details/TaskDetails';
import { TaskSummary } from '../../../components/TaskSummary';
import { TasksTable } from './TasksTable';

// Hooks
import { useSnackbar } from '../../../../../common/hooks/useSnackbar';
import { useCheckbox } from '../../../hooks/useCheckbox';
import { useTaskGroupContext } from '../../../../../components/task-group/components/TaskGroup.context';

// Types
import { BasePaginationProps } from '../../../../../common/types';

type Props = BasePaginationProps & {
  contract: Contract;
  tasks?: ResponseListBaseType<Array<Task>>;
  selectedTaskGroup?: string | null;
  reloadData: () => void;
  handleClaimClick: () => void;
  handleMoveTaskDialog: () => void;
  handleSelectedTasks: (selectedTasksIds: Array<string>) => void;
};

function TasksTab(props: Props) {
  const {
    contract,
    tasks,
    reloadData,
    page,
    rowsPerPage,
    handleChangePage,
    handleClaimClick,
    selectedTaskGroup,
    handleMoveTaskDialog,
    handleSelectedTasks,
  } = props;

  const { showAlert, SnackbarComponent } = useSnackbar();
  const {
    selected,
    setSelected,
    isItemSelected,
    handleCheckboxClick,
    handleSelectAll,
    selectAll,
  } = useCheckbox(tasks?.data);

  const { taskGroups } = useTaskGroupContext();
  const { mutate } = useMutation(
    (payload: SaveCurrentClaimPayload) => {
      return saveToCurrentClaim(contract.id, payload);
    },
    {
      onSuccess: () => reloadData(),
      onError: (error: AxiosError<{ message?: string }>) => {
        showAlert(
          error?.response?.data.message || 'An unknown error occurred!',
          'error'
        );
      },
    }
  );

  const handleAddToCurrentClaim = useCallback(
    (task: Task, value: number, claimType: ClaimType) => {
      mutate({
        claimType,
        taskId: task.id,
        value,
      });
    },
    [mutate]
  );

  const handleOpenDialog = useCallback(() => {
    handleMoveTaskDialog();
    handleSelectedTasks(selectAll);
  }, [handleMoveTaskDialog, handleSelectedTasks, selectAll]);

  const selectTaskGroup = useMemo(
    () => taskGroups.find(tg => tg.id === selectedTaskGroup),
    [selectedTaskGroup, taskGroups]
  );

  if (!tasks) {
    return <SkeletonTable />;
  }

  return (
    <>
      <SnackbarComponent />
      <Typography sx={{ mb: 3 }}>
        {selectTaskGroup?.name || 'All Tasks'}
      </Typography>
      <TaskSummary tasks={tasks} contract={contract} />
      <Box sx={{ m: 1, display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          variant="outlined"
          sx={{ m: 1 }}
          onClick={handleOpenDialog}
          disabled={!selectAll.length}
        >
          Add {selectAll.length} {pluralize('Task', selectAll.length)} to Folder
        </Button>
      </Box>
      <TasksTable
        contract={contract}
        tasks={tasks.data}
        selectAll={selectAll}
        handleSelectAll={handleSelectAll}
        selected={selected}
        setSelected={setSelected}
        handleCheckboxClick={handleCheckboxClick}
        handleClaimClick={handleClaimClick}
        isItemSelected={isItemSelected}
        handleAddToCurrentClaim={handleAddToCurrentClaim}
        hasCheckbox={true}
      />
      {(contract.status === ContractStatus.Draft ||
        contract.status === ContractStatus.AwaitingAcceptance) && (
        <Box sx={{ pb: 2 }}>
          <AddTask
            contractId={contract.id}
            selectedTaskGroup={selectedTaskGroup}
            reloadData={reloadData}
          />
        </Box>
      )}
      <Divider sx={{ mb: 3 }} />
      <Pagination
        count={Math.ceil(tasks.meta.total / rowsPerPage)}
        handleChangePage={handleChangePage}
        page={page}
        total={tasks.meta.total}
      />
      <TaskDetails
        contract={contract}
        task={selected}
        isOpen={!!selected}
        selectTaskGroup={selectTaskGroup}
        onClose={() => {
          reloadData();
          setSelected(null);
        }}
      />
    </>
  );
}

export { TasksTab };
