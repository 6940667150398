import React, { useCallback, useState } from 'react';
import Box from '@mui/material/Box';
import { Alert, CircularProgress, TextField } from '@mui/material';
import { Copyright } from '../../components/copyright';
import { Controller, useForm } from 'react-hook-form';
import { useMutation, useQuery } from '@tanstack/react-query';
import { LoadingButton } from '@mui/lab';
import { Params, useLocation, useNavigate, useParams } from 'react-router-dom';
import { AxiosError } from 'axios';
import { LoginLayout } from '../../components/login-layout';
import {
  checkResetPassword,
  updatePassword,
} from '../../common/api/resetPassword';
import { useAuth } from '../../common/auth-provider/AuthProvider';

const NEW_PASSWORD_FIELD = 'newPassword';
const CONFIRM_PASSWORD_FIELD = 'confirmPassword';

interface Form {
  [NEW_PASSWORD_FIELD]: string;
  [CONFIRM_PASSWORD_FIELD]: string;
}

function ResetPasswordPage() {
  const { id } = useParams() as Params;
  const { onLogin } = useAuth();
  const {
    control,
    handleSubmit,
    watch,
    formState: { errors, isValid },
  } = useForm<Form>();
  const navigate = useNavigate();
  const password = watch(NEW_PASSWORD_FIELD);
  const location = useLocation();
  const [pageError, setPageError] = useState<string | undefined>();
  const from = location.state?.from?.pathname || '/';

  const updatePasswordMutation = useMutation((form: Form) => {
    setPageError(undefined);

    return updatePassword(id!, form.confirmPassword);
  });

  const { isLoading: isResetPasswordLoading, isError } = useQuery(
    ['resetPasswordId', id],
    () => checkResetPassword(id!)
  );

  const onSubmit = useCallback(
    async (form: Form) => {
      if (isValid) {
        try {
          const authResponse = await updatePasswordMutation.mutateAsync(form);
          onLogin(authResponse);
          // Navigate to the original location.
          navigate(from, { replace: true });
        } catch (err) {
          let message = `An unknown error occurred`;
          if (err instanceof AxiosError) {
            const status = err.response?.status;
            if (status === 401) {
              message = 'Sign In failed';
            } else {
              message = `An unknown error occurred (status ${status})`;
            }
          }
          setPageError(message);
        }
      }
    },
    [from, isValid, navigate, onLogin, updatePasswordMutation]
  );

  const isLoading = isResetPasswordLoading || updatePasswordMutation.isLoading;

  if (isLoading) {
    return (
      <LoginLayout>
        <>
          <br />
          <CircularProgress />
        </>
      </LoginLayout>
    );
  }

  return (
    <LoginLayout>
      <>
        <Box
          component="form"
          noValidate
          sx={{ mt: 1 }}
          onSubmit={handleSubmit(onSubmit)}
        >
          {pageError && (
            <Alert sx={{ my: 3 }} severity="error">
              {pageError}
            </Alert>
          )}
          {isError && (
            <Alert sx={{ my: 3 }} severity="error">
              Link is expired
            </Alert>
          )}
          <Controller
            control={control}
            name={NEW_PASSWORD_FIELD}
            rules={{ required: 'New Password is required' }}
            defaultValue={''}
            render={({ field }) => (
              <TextField
                {...field}
                margin="normal"
                required
                fullWidth
                label="new password"
                type="password"
                error={!!errors.newPassword}
                helperText={errors.newPassword?.message?.toString()}
              />
            )}
          />
          <Controller
            control={control}
            name={CONFIRM_PASSWORD_FIELD}
            defaultValue={''}
            rules={{
              required: 'Confirm Password is required',
              validate: value => value === password || 'Passwords do not match',
            }}
            render={({ field }) => (
              <TextField
                {...field}
                margin="normal"
                required
                fullWidth
                label="confirm password"
                type="password"
                error={!!errors.confirmPassword}
                helperText={errors.confirmPassword?.message?.toString()}
              />
            )}
          />
          <LoadingButton
            loading={updatePasswordMutation.isLoading}
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 3 }}
          >
            Update Password
          </LoadingButton>
          <Copyright />
        </Box>
      </>
    </LoginLayout>
  );
}

export { ResetPasswordPage };
