import { axios } from './axios';
import { PaymentData, PaymentStatus, ResponseListBaseType } from './types';

type Options = {
  take?: number;
  skip?: number;
};

async function listPayments(
  { accountId }: { accountId: string },
  options: Options = {}
): Promise<ResponseListBaseType<Array<PaymentData>>> {
  const { skip, take } = options;
  const resp = await axios.get(`/accounts/${accountId}/payments`, {
    params: {
      take,
      skip,
    },
  });
  return resp.data;
}

async function listPaidPayments(
  { accountId }: { accountId: string },
  options: Options = {}
): Promise<ResponseListBaseType<Array<PaymentData>>> {
  const { skip, take } = options;
  const resp = await axios.get(`/accounts/${accountId}/payments`, {
    params: {
      status: PaymentStatus.Paid,
      take,
      skip,
    },
  });
  return resp.data;
}

async function listIncome(
  { accountId }: { accountId: string },
  options: Options = {}
): Promise<ResponseListBaseType<Array<PaymentData>>> {
  const { skip, take } = options;
  const resp = await axios.get(`/accounts/${accountId}/income`, {
    params: {
      take,
      skip,
    },
  });
  return resp.data;
}

async function listPaidIncome(
  { accountId }: { accountId: string },
  options: Options = {}
): Promise<ResponseListBaseType<Array<PaymentData>>> {
  const { skip, take } = options;
  const resp = await axios.get(`/accounts/${accountId}/income`, {
    params: {
      status: PaymentStatus.Paid,
      take,
      skip,
    },
  });
  return resp.data;
}

async function makePayment({
  accountId,
  paymentId,
}: {
  accountId: string;
  paymentId: string;
}): Promise<ResponseListBaseType<Array<PaymentData>>> {
  const resp = await axios.post(`/accounts/${accountId}/payment/${paymentId}`);
  return resp.data;
}

async function schedulePayment({
  accountId,
  paymentId,
  payWhenDue,
}: {
  accountId: string;
  paymentId: string;
  payWhenDue: boolean;
}): Promise<boolean> {
  const resp = await axios.post(
    `/accounts/${accountId}/payment/${paymentId}/schedule`,
    {
      payWhenDue,
    }
  );
  return resp.data;
}

export {
  listPayments,
  listPaidPayments,
  listIncome,
  listPaidIncome,
  makePayment,
  schedulePayment,
};
