import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { EditorContent } from '@tiptap/react';
import { grey600, grey800, grey900 } from '../../../styles/colours';

export const baseRichtextCss = css`
  p,
  h1,
  h2,
  ul,
  ol,
  li {
    margin: 0;
  }

  ul,
  ol {
    margin-bottom: 8px;
    padding-left: 32px;
    li p {
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  p {
    font-size: 16px;
    margin-bottom: 8px;
  }

  h1 {
    font-size: 30px;
    line-height: 34px;
    margin-bottom: 8px;
  }

  h2 {
    font-size: 24px;
    line-height: 28px;
    margin-bottom: 8px;
  }
`;

export const RichtextEditor = styled(EditorContent)`
  width: 100%;

  .ProseMirror {
    border: 1px solid ${grey600};
    color: ${grey900};
    border-radius: 8px;
    padding: 8px 16px;

    ${baseRichtextCss}
  }

  .ProseMirror-focused {
    border: 1px solid ${grey800};
    outline: 0;
  }
`;
