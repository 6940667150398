import React, { useCallback, useState } from 'react';
import Box from '@mui/material/Box';
import { Alert, Button, TextField } from '@mui/material';
import { Copyright } from '../../components/copyright';
import { Controller, useForm } from 'react-hook-form';
import { login } from '../../common/api';
import { useMutation } from '@tanstack/react-query';
import { LoadingButton } from '@mui/lab';
import { useAuth } from '../../common/auth-provider/AuthProvider';
import { useLocation, useNavigate } from 'react-router-dom';
import { AxiosError } from 'axios';
import { LoginLayout } from '../../components/login-layout';
import { EMAIL_REGEX } from '../../common/validate';
import { ResetPasswordDialog } from './components/ResetPasswordDialog';

interface Form {
  email: string;
  password: string;
}

function LoginPage() {
  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<Form>();
  const { onLogin } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  // A page/non-field error.
  const [pageError, setPageError] = useState<string | undefined>();

  const [open, setOpen] = useState<boolean>(false);

  // The reason, supplied as a parameter why the user is here.
  const [reason, setReason] = useState<string | undefined>(
    new URLSearchParams(location.search).get('reason') ?? undefined
  );

  // The original intended location, or /
  const from = location.state?.from?.pathname || '/';

  const loginMutation = useMutation((form: Form) => {
    return login(form.email, form.password);
  });

  const onChange = () => {
    setPageError(undefined);
  };

  const onSubmit = async (form: Form) => {
    setReason(undefined);
    if (isValid) {
      try {
        // Call /login
        const authResponse = await loginMutation.mutateAsync(form);

        // Update app state with the AuthResponse.
        onLogin(authResponse);

        // Navigate to the original location.
        navigate(from, { replace: true });
      } catch (err) {
        let message = `An unknown error occurred`;
        if (err instanceof AxiosError) {
          const status = err.response?.status;
          if (status === 401) {
            message = 'Sign In failed';
          } else {
            message = `An unknown error occurred (status ${status})`;
          }
        }
        setPageError(message);
      }
    }
  };

  const handleOpenDialog = useCallback(() => {
    setOpen(true);
  }, []);

  return (
    <LoginLayout>
      <>
        <Box
          component="form"
          noValidate
          sx={{ mt: 1 }}
          onSubmit={handleSubmit(onSubmit)}
          onChange={onChange}
        >
          {reason && (
            <Alert sx={{ my: 3 }} severity="info">
              {reason}
            </Alert>
          )}
          {pageError && (
            <Alert sx={{ my: 3 }} severity="error">
              {pageError}
            </Alert>
          )}
          <Controller
            name={'email'}
            defaultValue={''}
            control={control}
            rules={{
              required: 'Email is required',
              pattern: {
                value: EMAIL_REGEX,
                message: 'Invalid email address',
              },
            }}
            render={({ field }) => (
              <TextField
                {...field}
                margin="normal"
                required
                fullWidth
                label="Email Address"
                autoComplete="email"
                autoFocus
                error={!!errors.email}
                helperText={errors.email?.message?.toString()}
              />
            )}
          />
          <Controller
            name={'password'}
            defaultValue={''}
            control={control}
            rules={{ required: 'Password is required' }}
            render={({ field }) => (
              <TextField
                {...field}
                margin="normal"
                required
                fullWidth
                label="Password"
                type="password"
                autoComplete="current-password"
                error={!!errors.password}
                helperText={errors.password?.message?.toString()}
              />
            )}
          />
          <LoadingButton
            loading={loginMutation.isLoading}
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 3 }}
          >
            Sign In
          </LoadingButton>
          <Button
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 3 }}
            onClick={handleOpenDialog}
          >
            Forgot Password ?
          </Button>
          <Copyright />
        </Box>
        <ResetPasswordDialog open={open} setOpen={setOpen} />
      </>
    </LoginLayout>
  );
}

export { LoginPage };
