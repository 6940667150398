import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Autocomplete,
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Icon,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Theme,
  Typography,
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { useMutation } from '@tanstack/react-query';
import { LoadingButton } from '@mui/lab';
import CloseIcon from '@mui/icons-material/Close';

import {
  Account,
  Contract,
  ContractStatus,
  createAccount,
  CreateAccountPayload,
  updateContract,
  UpdateContractPayload,
} from '../../../common/api';

// Styles

// Utils
import { formatCurrency, formatDate } from '../../../common/format';
import { useAuth } from '../../../common/auth-provider/AuthProvider';
import { isContractor, isCustomer } from '../../../common/access';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import { EMAIL_REGEX } from '../../../common/validate';
import {
  getDefaultFormValues,
  FormData,
  ACCOUNT_NAME,
  ACCOUNT_REP_PHONE,
  ACCOUNT_ID_FILED,
  ACCOUNT_REPRESENTATIVE,
  ACCOUNT_REP_EMAIL,
  CONTRACT_CONTINGENCY_BUDGET,
  CONTRACT_PAYMENT_TERM_FIELD,
  CONTRACT_TITLE,
} from './DetailsDialog.utils';
import { Heading } from '../../../styles';
import MainContractVisibilitySettings from './visibility/main-visibility/MainContractVisibilitySettings';
import VisibilitySettings from './visibility/visibilitySettings/VisibilitySettings';
import {
  getParentContractVisibilitySettings,
  getVisibilitySetting,
} from './visibility/utils/visibilitySettings.utils';
import { VisibilitySetting } from '../../../common/api/visibilitySettings';
import { EditableDataType } from './ContractDetailsEdit';
import RetentionSettings from './retention/RetentionSettings';

interface Props {
  accountList?: Account[];
  contract: Contract;
  isAccountListLoading: boolean;
  isReadOnly?: boolean;
  open: boolean;
  isEdit?: boolean;
  editableData?: EditableDataType;
  handleUpdateAccountList: () => void;
  handleClose: () => void;
  reloadData: () => void;
  onEditSave?: (updatedSettings: VisibilitySetting[]) => void;
}

enum Section {
  details = 'details',
  visibility = 'visibility',
  retention = 'retention',
}

function DetailsDialog({
  accountList,
  contract,
  isAccountListLoading,
  isReadOnly = false,
  open,
  isEdit = false,
  editableData,
  handleUpdateAccountList,
  handleClose,
  reloadData,
  onEditSave,
}: Props) {
  const { user } = useAuth();

  const [selectedCompanyId, setSelectedCompanyId] = useState('');
  const [selectedCompany, setSelectedCompany] = useState<Account>();
  const [isEmailError, setIsEmailError] = useState<boolean>(false);
  const [selectedSection, setSelectedSection] = useState<Section>(
    Section.details
  );

  const [parentVisibilitySettings, setParentVisibilitySettings] = useState<
    VisibilitySetting[] | null
  >(null);

  const isHeadContract = !contract.parentContractId;

  const checkIsDisabledSettings = useCallback(async () => {
    if (!isHeadContract) {
      const visibilitySettings = await getParentContractVisibilitySettings(
        contract.parentContractId
      );
      if (visibilitySettings) {
        setParentVisibilitySettings(visibilitySettings);
      }
    }

    if (
      (contract.status !== ContractStatus.Draft &&
        contract.status !== ContractStatus.AwaitingAcceptance) ||
      (contract.status === ContractStatus.AwaitingAcceptance && !isEdit) ||
      isReadOnly ||
      !isHeadContract
    ) {
      return true;
    } else if (isHeadContract && isContractor(user, contract)) {
      return getVisibilitySetting(
        contract.visibilitySettings,
        'subcontractorSettingsLocked'
      );
    }

    return false;
  }, [contract, isHeadContract, isReadOnly, user, isEdit]);

  useEffect(() => {
    const fetchData = async () => {
      const isDisabled = await checkIsDisabledSettings();
      setIsDisabled(isDisabled);
    };

    fetchData();
  }, [checkIsDisabledSettings]);

  const [isDisabled, setIsDisabled] = useState(false);
  const isChildDisabled = () => {
    if (contract.status === ContractStatus.AwaitingAcceptance && !isEdit) {
      return true;
    }

    return contract.status !== ContractStatus.Draft && !!isReadOnly;
  };

  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors, isValid, isDirty },
    trigger,
  } = useForm<FormData>({
    defaultValues: useMemo(() => getDefaultFormValues(contract), [contract]),
  });

  const { mutateAsync: mutateContract, isLoading } = useMutation(
    (payload: UpdateContractPayload) => updateContract(contract.id, payload),
    {
      onSuccess: () => {
        reloadData();
      },
    }
  );

  const { mutateAsync: mutateAddAccount } = useMutation(
    (payload: CreateAccountPayload) => createAccount(payload),
    {
      onError: ({ response }) => {
        if (response.data.code === 'P2002') setIsEmailError(true);
      },
    }
  );

  const handleCompanyChange = useCallback(
    (event: React.SyntheticEvent, value?: string) => {
      if (!value) {
        return;
      }

      setSelectedCompanyId(value);

      // Pre-populate company info if an existing company is selected
      if (value !== 'new') {
        const selectedCompany = accountList?.find(
          company => company.id === value
        );
        setSelectedCompany(selectedCompany);
        setValue('accountId', selectedCompany?.id || '', {
          shouldDirty: selectedCompany?.id !== contract.contractorAccountId,
        });
        setValue(
          'companyRepresentative',
          selectedCompany?.representative || ''
        );
        setValue('companyName', selectedCompany?.name || '');
        setValue('companyRepEmail', selectedCompany?.email || '');
        setValue('companyRepPhone', selectedCompany?.phone || '');
        trigger();
      } else {
        // Clear company info if "Add a new company" is selected
        setSelectedCompany(undefined);
        setValue('accountId', value, { shouldDirty: true });
        setValue('companyRepresentative', '');
        setValue('companyRepEmail', '');
        setValue('companyRepPhone', '');
        setValue('companyName', '');
        trigger();
      }
    },
    [accountList, setValue, contract.contractorAccountId, trigger]
  );

  const onSubmit = async (data: FormData) => {
    if (isDirty) {
      if (!selectedCompany && selectedCompanyId === 'new') {
        const account = await mutateAddAccount({
          name: data[ACCOUNT_NAME],
          email: data[ACCOUNT_REP_EMAIL],
          phone: data[ACCOUNT_REP_PHONE],
          representative: data[ACCOUNT_REPRESENTATIVE],
          isProvider: true,
          isContract: true,
        });
        await mutateContract({
          description: data[CONTRACT_TITLE],
          contingencyBudget: data[CONTRACT_CONTINGENCY_BUDGET],
          paymentTerm: data[CONTRACT_PAYMENT_TERM_FIELD],
          contractorAccountId: account.id,
        });
        handleUpdateAccountList();
        setSelectedCompany(undefined);
        setSelectedCompanyId('');
      }
      if (selectedCompany) {
        await mutateContract({
          description: data[CONTRACT_TITLE],
          contingencyBudget: data[CONTRACT_CONTINGENCY_BUDGET],
          paymentTerm: data[CONTRACT_PAYMENT_TERM_FIELD],
          contractorAccountId: data[ACCOUNT_ID_FILED],
        });
        setSelectedCompany(undefined);
        setSelectedCompanyId('');
      }
    }

    handleClose();
  };

  useEffect(() => {
    if (contract.contractorAccountId) {
      setSelectedCompany(
        accountList?.find(({ id }) => id === contract.contractorAccountId) ||
          undefined
      );
    }
  }, [accountList, contract.contractorAccountId]);

  const visibilitySettings = () => {
    if (isEdit && editableData) {
      return editableData.visibilitySettings;
    }
    return contract.visibilitySettings || [];
  };

  return (
    <Dialog open={open} maxWidth={'md'} fullWidth>
      <DialogTitle sx={styles.DialogTitle}>
        <Typography fontSize={18}>{'Contract Settings'}</Typography>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={styles.IconButton}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent
        sx={{ position: 'relative', display: 'flex', flexDirection: 'row' }}
      >
        <List sx={styles.DialogSidebar}>
          <ListItem disablePadding>
            <ListItemButton
              sx={{ m: 0 }}
              selected={selectedSection === Section.details}
              onClick={() => setSelectedSection(Section.details)}
            >
              <ListItemText sx={styles.ListButton} primary="Details" />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton
              sx={{ m: 0 }}
              selected={selectedSection === Section.visibility}
              onClick={() => setSelectedSection(Section.visibility)}
            >
              <ListItemText sx={styles.ListButton} primary="Visibility" />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton
              sx={{ m: 0 }}
              selected={selectedSection === Section.retention}
              onClick={() => setSelectedSection(Section.retention)}
            >
              <ListItemText sx={styles.ListButton} primary="Retention" />
            </ListItemButton>
          </ListItem>
        </List>

        {selectedSection === Section.retention && (
          <RetentionSettings
            contract={contract}
            isDisabled={isDisabled}
            reloadData={reloadData}
            handleClose={handleClose}
          />
        )}

        {selectedSection === Section.visibility && (
          <>
            {isHeadContract ? (
              <MainContractVisibilitySettings
                isDisabled={isDisabled}
                contractId={contract.id}
                contractStatus={contract.status}
                visibilitySettings={visibilitySettings()}
                handleClose={handleClose}
                reloadData={reloadData}
                onEditSave={onEditSave}
                isEdit={isEdit}
              />
            ) : (
              <VisibilitySettings
                isDisabled={isChildDisabled()}
                contractId={contract.id}
                contractStatus={contract.status}
                hasRevision={!!contract.parentRevisionContractId}
                parentVisibilitySettings={parentVisibilitySettings || []}
                visibilitySettings={visibilitySettings()}
                handleClose={handleClose}
                onEditSave={onEditSave}
                isEdit={isEdit}
              />
            )}
          </>
        )}

        {selectedSection === 'details' && (
          <Box sx={styles.DialogContent}>
            <Heading fontWeight={500}>Client Details</Heading>
            <TableContainer sx={{ mb: 4 }}>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell>Organisation Name</TableCell>
                    <TableCell>{contract.customerAccount.name}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Address</TableCell>
                    <TableCell>
                      {contract.customerAccount.address || '-'}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>ABN</TableCell>
                    <TableCell>{contract.customerAccount.abn || '-'}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Client Representative</TableCell>
                    <TableCell>
                      {contract.customerAccount.representative || '-'}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Client Rep. email</TableCell>
                    <TableCell>{contract.customerAccount.email}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Client Rep. Phone</TableCell>
                    <TableCell>
                      {contract.customerAccount.phone || '-'}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            <Heading fontWeight={500}>Contract Details</Heading>
            <TableContainer>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell>Contractor</TableCell>
                    {contract.status === ContractStatus.Draft && !isReadOnly ? (
                      <TableCell>
                        <Autocomplete
                          options={
                            [
                              ...(accountList || []),
                              {
                                id: 'new',
                                name: 'Add a new Company',
                              },
                            ] || []
                          }
                          disableClearable
                          defaultValue={{
                            id: contract.contractorAccountId,
                            name: contract.contractorAccount.name,
                          }}
                          getOptionLabel={option => option.name}
                          isOptionEqualToValue={(option, value) =>
                            option.id === value.id
                          }
                          selectOnFocus
                          size="small"
                          onChange={(event, value) =>
                            handleCompanyChange(event, value?.id)
                          }
                          renderInput={params => (
                            <TextField
                              {...params}
                              label="Company Name"
                              fullWidth
                              required
                            />
                          )}
                          renderOption={(props, option) => (
                            <li {...props} key={option.id}>
                              <Stack
                                sx={{ width: '100%' }}
                                flexDirection={'row'}
                                justifyContent={'space-between'}
                                alignItems={'center'}
                              >
                                <Typography>
                                  {option.name || 'Non-active Company'}
                                </Typography>
                                {!option.name && (
                                  <Icon style={{ color: 'red' }}>
                                    <WarningRoundedIcon />
                                  </Icon>
                                )}
                              </Stack>
                            </li>
                          )}
                        />
                      </TableCell>
                    ) : (
                      <TableCell>{contract.contractorAccount.name}</TableCell>
                    )}
                  </TableRow>
                  {selectedCompanyId === 'new' && (
                    <TableRow>
                      <TableCell>Contractor Name</TableCell>
                      <TableCell>
                        <Controller
                          name={ACCOUNT_NAME}
                          defaultValue=""
                          control={control}
                          rules={{
                            required: true,
                          }}
                          render={({ field }) => (
                            <TextField
                              {...field}
                              size="small"
                              fullWidth
                              required
                              error={!!errors.companyName}
                            />
                          )}
                        />
                      </TableCell>
                    </TableRow>
                  )}
                  <TableRow>
                    <TableCell>Contractor Representative</TableCell>
                    {selectedCompanyId === 'new' ? (
                      <TableCell>
                        <Controller
                          name={ACCOUNT_REPRESENTATIVE}
                          defaultValue=""
                          control={control}
                          rules={{
                            required: true,
                          }}
                          render={({ field }) => (
                            <TextField
                              {...field}
                              size="small"
                              fullWidth
                              required
                              error={!!errors.companyRepresentative}
                            />
                          )}
                        />
                      </TableCell>
                    ) : (
                      <TableCell>
                        {selectedCompany
                          ? selectedCompany?.representative
                          : contract.contractorAccount.representative}
                      </TableCell>
                    )}
                  </TableRow>
                  <TableRow>
                    <TableCell>Contractor Rep. email</TableCell>
                    {selectedCompanyId === 'new' ? (
                      <TableCell>
                        <Controller
                          name={ACCOUNT_REP_EMAIL}
                          defaultValue=""
                          control={control}
                          rules={{
                            required: true,
                            pattern: {
                              value: EMAIL_REGEX,
                              message: 'Invalid email address',
                            },
                          }}
                          render={({ field }) => (
                            <TextField
                              {...field}
                              size="small"
                              fullWidth
                              required
                              error={!!errors.companyRepEmail || isEmailError}
                            />
                          )}
                        />
                      </TableCell>
                    ) : (
                      <TableCell>
                        {selectedCompany
                          ? selectedCompany.email
                          : contract.contractorAccount.email}
                      </TableCell>
                    )}
                  </TableRow>
                  <TableRow>
                    <TableCell>Contractor Rep. Phone</TableCell>
                    {selectedCompanyId === 'new' ? (
                      <TableCell>
                        <Controller
                          name={ACCOUNT_REP_PHONE}
                          defaultValue=""
                          control={control}
                          rules={{
                            required: true,
                          }}
                          render={({ field }) => (
                            <TextField
                              {...field}
                              size="small"
                              fullWidth
                              required
                              error={!!errors.companyRepPhone}
                            />
                          )}
                        />
                      </TableCell>
                    ) : (
                      <TableCell>
                        {selectedCompany
                          ? selectedCompany?.phone
                          : contract.contractorAccount.phone}
                      </TableCell>
                    )}
                  </TableRow>
                  <TableRow>
                    <TableCell>Contract Title</TableCell>
                    {contract.status === ContractStatus.Draft && !isReadOnly ? (
                      <TableCell>
                        <Controller
                          name={CONTRACT_TITLE}
                          defaultValue={contract.description}
                          control={control}
                          rules={{
                            required: true,
                          }}
                          render={({ field }) => (
                            <TextField
                              {...field}
                              size="small"
                              fullWidth
                              required
                              error={!!errors.contractTitle}
                            />
                          )}
                        />
                      </TableCell>
                    ) : (
                      <TableCell>{contract.description}</TableCell>
                    )}
                  </TableRow>
                  <TableRow>
                    <TableCell>Reference #</TableCell>
                    <TableCell>{contract.id}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Original Contract Value</TableCell>
                    <TableCell>
                      {formatCurrency(Number(contract.originalValue || 0))}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Approved Variations</TableCell>
                    <TableCell>
                      {formatCurrency(
                        Number(contract.value || 0) -
                          Number(contract.originalValue || 0)
                      )}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Current Contract Value</TableCell>
                    <TableCell>
                      {formatCurrency(Number(contract.value || 0))}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Contract Contingency Budget</TableCell>
                    <TableCell>
                      {isCustomer(user, contract) ? (
                        <Controller
                          name={CONTRACT_CONTINGENCY_BUDGET}
                          control={control}
                          defaultValue={0}
                          render={({ field }) => (
                            <TextField
                              {...field}
                              type="number"
                              inputProps={{
                                style: {
                                  fontSize: 14,
                                  padding: '10px',
                                },
                              }}
                              error={!!errors.contingencyBudget}
                              helperText={
                                errors.contingencyBudget
                                  ? errors.contingencyBudget.message
                                  : ''
                              }
                            />
                          )}
                        />
                      ) : (
                        formatCurrency(Number(contract.contingencyBudget || 0))
                      )}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Contract Awarded</TableCell>
                    <TableCell>{formatDate(contract.award)}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Payment Terms</TableCell>
                    {isReadOnly ? (
                      <TableCell>{contract.paymentTerm}</TableCell>
                    ) : (
                      <TableCell>
                        <Controller
                          name={CONTRACT_PAYMENT_TERM_FIELD}
                          control={control}
                          defaultValue={0}
                          rules={{
                            min: {
                              value: 1,
                              message: 'Payment terms must be at least 1 day',
                            },
                            max: {
                              value: 365,
                              message: 'Payment terms cannot exceed 365 days',
                            },
                          }}
                          render={({ field }) => (
                            <TextField
                              {...field}
                              type="number"
                              inputProps={{
                                style: {
                                  fontSize: 14,
                                  padding: '10px',
                                },
                              }}
                              error={!!errors.paymentTerms}
                              helperText={
                                errors.paymentTerms
                                  ? errors.paymentTerms.message
                                  : ''
                              }
                            />
                          )}
                        />
                      </TableCell>
                    )}
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            <LoadingButton
              loading={isLoading || isAccountListLoading}
              onClick={isReadOnly ? handleClose : handleSubmit(onSubmit)}
              disabled={!isValid}
              sx={{ alignSelf: 'flex-end', m: 2 }}
              variant="contained"
              color="primary"
            >
              {isDirty && !isReadOnly ? 'Save' : 'Close'}
            </LoadingButton>
          </Box>
        )}
      </DialogContent>
    </Dialog>
  );
}

const styles = {
  DialogTitle: {
    p: 2,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottom: '1px solid #e0e0e0',
  },
  ListButton: {
    fontSize: '24px',
    p: 1,
    pl: 0,
  },
  DialogSidebar: {
    position: 'sticky',
    left: 0,
    top: 0,
    flexShrink: 0,
    gap: 1,
    padding: 3,
    paddingLeft: 0,
    width: '200px',
    borderRight: '1px solid #e0e0e0',
  },
  DialogContent: {
    padding: 3,
  },
  IconButton: {
    color: (theme: Theme) => theme.palette.grey[500],
  },
};

export { DetailsDialog };
