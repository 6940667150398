import {
  Avatar,
  Box,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  Typography,
} from '@mui/material';
import dayjs from 'dayjs';
import React, { useMemo, useState } from 'react';
import { ClaimMessage, ClaimMessageStatus, User } from '../../common/api';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { stringAvatar } from './stringAvatar';

interface Props {
  claimMessage: ClaimMessage;
  currentUser: User;
  onDeleteMessage: (messageId: string) => void;
  onResolveMessage: (messageId: string) => void;
}

function Message({
  claimMessage,
  currentUser,
  onDeleteMessage,
  onResolveMessage,
}: Props) {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const messageDate = useMemo(() => {
    const date = dayjs(claimMessage.createdAt);
    return date.format('HH:mm MMM DD YYYY');
  }, [claimMessage]);

  const canResolveMessage = useMemo(
    () =>
      claimMessage.status === ClaimMessageStatus.Unresolved &&
      currentUser.email !== claimMessage.user.email,
    [claimMessage.status, claimMessage.user, currentUser]
  );

  const canDeleteMessage = currentUser?.id === claimMessage.userId;

  return (
    <Box key={claimMessage.id}>
      <br />
      <Stack direction="row" spacing={2} style={styles.MessageHeader}>
        <Stack direction="row" spacing={2}>
          <Avatar {...stringAvatar(claimMessage.user?.email || '')} />
          <Typography style={styles.MessageAuthor}>
            {claimMessage.user?.email}
          </Typography>
          <Typography style={styles.MessageTime}>{messageDate}</Typography>
        </Stack>
        {(canResolveMessage || canDeleteMessage) && (
          <>
            <IconButton onClick={handleClick}>
              <MoreHorizIcon />
            </IconButton>
            <Menu
              open={Boolean(anchorEl)}
              onClose={handleClose}
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              {canDeleteMessage && (
                <MenuItem
                  sx={{ px: 2 }}
                  disabled={!canDeleteMessage}
                  onClick={() => onDeleteMessage(claimMessage.id)}
                >
                  Delete
                </MenuItem>
              )}

              {canResolveMessage && (
                <MenuItem
                  sx={{ px: 2 }}
                  onClick={() => onResolveMessage(claimMessage.id)}
                >
                  Mark Resolved
                </MenuItem>
              )}
            </Menu>
          </>
        )}
      </Stack>

      <Typography style={styles.Message}>{claimMessage.message}</Typography>
      {currentUser.id !== claimMessage.userId && (
        <Typography style={styles.MessageSmall} color="GrayText">
          {claimMessage.status}
        </Typography>
      )}
      <br />
    </Box>
  );
}

const styles: { [key: string]: React.CSSProperties } = {
  MessageTime: {
    color: 'grey',
    marginTop: '9px',
  },
  MessageAuthor: {
    marginTop: '9px',
    fontWeight: 'bold',
  },
  Message: {
    marginTop: '10px',
    marginLeft: '56px',
  },
  MessageHeader: {
    width: '520px',
    display: 'flex',
    justifyContent: 'space-between',
  },
  MessageSmall: {
    fontSize: '13px',
    marginLeft: '56px',
  },
};

export { Message };
