import React, {
  ReactNode,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useQuery } from '@tanstack/react-query';
import { Box, Button, Collapse, Stack } from '@mui/material';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { TouchBackend } from 'react-dnd-touch-backend';
import { TaskGroupTree } from './components/TaskGroupTree';
import { ResponseListBaseType, TaskGroup } from '../../common/api';
import { listTaskGroups } from '../../common/api/taskGroups';
import { TaskGroupCreateDialog } from './components/TaskGroupCreateDialog';
import { useDialogState } from '../dialog/dialog.hooks';
import { DELETE, RENAME } from './components/TaskGroups.constants';
import { TaskGroupDeleteDialog } from './components/TaskGroupDeleteDialog';
import {
  TaskGroupContextProvider,
  useTaskGroupContext,
} from './components/TaskGroup.context';
import { CustomDragLayer } from './components/CustomDragLayer';
import { useSortableScrollManager } from './TaskGroups.hooks';
import { TREE_IDENTIFIER } from './components/TaskGroups.constants';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { grey200 } from '../../styles/colours';

export type BaseTaskGroupProps = {
  selectedTaskGroup?: string | null;
  handleSelectedTaskGroup: (nodeId: string) => void;
};

export type TaskGroupProps = BaseTaskGroupProps & {
  contractId: string;
};

type Props = {
  queryId?: string;
  canEdit?: boolean;
  render: (reloadTree: () => void) => ReactNode;
} & TaskGroupProps;

function TaskGroupsComponent({
  contractId,
  queryId,
  selectedTaskGroup,
  render,
  handleSelectedTaskGroup,
}: Props) {
  const ref = useRef<HTMLDivElement | null>(null);
  const [activeParentId, setActiveParentId] = useState<string | undefined>();
  const [addToTaskGroupId, setAddToTaskGroupId] = useState<string | null>(null);
  const [editTaskGroupId, setEditTaskGroupId] = useState<string | undefined>();
  const [open, setOpen] = useState<boolean>(true);

  const {
    isVisible: isCreateVisible,
    open: openCreate,
    close: closeCreate,
  } = useDialogState();
  const {
    isVisible: isDeleteVisible,
    open: openDelete,
    close: closeDelete,
  } = useDialogState();

  const { data, key, onReload, onRemoveDeleted } = useTaskGroups({
    queryId,
    contractId,
    parentId: activeParentId,
  });
  const taskGroups = useMemo(() => data?.data || [], [data?.data]);

  const targetTaskGroup = useMemo(
    () => taskGroups.find(tg => tg.id === editTaskGroupId),
    [editTaskGroupId, taskGroups]
  );

  const handleCollapse = useCallback(() => setOpen(prev => !prev), []);

  const {
    taskGroups: taskGroupsContext,
    expanded,
    isEdit,
    setExpanded,
  } = useTaskGroupContext();

  const handleClose = useCallback(() => {
    setEditTaskGroupId(undefined);
    setAddToTaskGroupId(null);
  }, [setEditTaskGroupId, setAddToTaskGroupId]);

  useSortableScrollManager(ref, [TREE_IDENTIFIER]);

  return (
    <Stack ref={ref} flex={1}>
      <Stack flexDirection="row" flex={1}>
        <Collapse
          in={open}
          orientation={'horizontal'}
          style={{
            minWidth: 'auto',
          }}
        >
          <Box sx={{ width: '280px', flexShrink: '0', paddingRight: '8px' }}>
            <TaskGroupTree
              key={key}
              taskGroups={taskGroupsContext}
              expanded={expanded}
              setExpanded={setExpanded}
              canEdit={isEdit}
              selectedTaskGroup={selectedTaskGroup}
              handleSelectedTaskGroup={handleSelectedTaskGroup}
              handleSetActiveParent={setActiveParentId}
              onOptionsSelect={(taskGroupId, option) => {
                setEditTaskGroupId(taskGroupId);
                if (option === RENAME) {
                  openCreate();
                }
                if (option === DELETE) {
                  openDelete();
                }
              }}
              onReload={onReload}
              onSelectAdd={(taskGroupId: string | null) => {
                setAddToTaskGroupId(taskGroupId);
                openCreate();
              }}
            />
            {isDeleteVisible && targetTaskGroup && (
              <TaskGroupDeleteDialog
                id={targetTaskGroup.id}
                name={targetTaskGroup.name}
                onReload={() => {
                  onReload();
                  onRemoveDeleted(targetTaskGroup.id);
                  setEditTaskGroupId(undefined);
                }}
                handleClose={() => {
                  handleClose();
                  closeDelete();
                }}
              />
            )}
            {isCreateVisible && (
              <TaskGroupCreateDialog
                taskGroup={targetTaskGroup}
                taskGroups={taskGroups}
                addToTaskGroupId={addToTaskGroupId}
                contractId={contractId}
                onReload={() => onReload()}
                handleClose={() => {
                  handleClose();
                  closeCreate();
                }}
              />
            )}
          </Box>
        </Collapse>
        <Button sx={styles.CollapseButton} onClick={handleCollapse}>
          <ArrowBackIosIcon sx={{ width: '10px' }} />
        </Button>
        <Box sx={{ width: '100%' }}>
          {render(async () => {
            await onReload();
          })}
        </Box>
      </Stack>
    </Stack>
  );
}

export const TaskGroups = (props: Props) => {
  const { canEdit = true } = props;
  const isTouch = 'ontouchstart' in document.documentElement;
  return (
    <DndProvider backend={isTouch ? TouchBackend : HTML5Backend}>
      <CustomDragLayer />
      <TaskGroupContextProvider isEdit={canEdit}>
        <TaskGroupsComponent {...props} />
      </TaskGroupContextProvider>
    </DndProvider>
  );
};

export const useTaskGroups = ({
  queryId,
  contractId,
}: {
  queryId?: string;
  contractId?: string;
  parentId?: string;
}) => {
  const { taskGroups, setTaskGroups, setOriginalTaskGroups } =
    useTaskGroupContext();

  const {
    data,
    isLoading,
    refetch: refetchTaskGroups,
  } = useQuery<ResponseListBaseType<Array<TaskGroup>>>(
    [queryId, 'taskGroup', contractId],
    () => {
      if (contractId) {
        return listTaskGroups(contractId);
      }
      return {
        data: [],
        meta: {
          total: 0,
        },
      };
    },
    {
      onSuccess: response => {
        const newTaskGroups = response.data.map(group => ({
          ...group,
        }));
        setTaskGroups(() => {
          return [...newTaskGroups];
        });
        setOriginalTaskGroups(() =>
          response.data.map(group => ({
            ...group,
          }))
        );
      },
    }
  );

  const taskGroupsData = useMemo(() => data?.data || [], [data?.data]);

  const key = useMemo(() => {
    return taskGroupsData.map(tg => tg.order).join('');
  }, [taskGroupsData]);

  return {
    taskGroups,
    data,
    key,
    isLoading,
    onReload: () => refetchTaskGroups(),
    onRemoveDeleted: (id: string) => {
      for (let i = taskGroups.length - 1; i >= 0; i--) {
        if (taskGroups[i].id === id || taskGroups[i].parentId === id) {
          taskGroups.splice(i, 1);
        }
      }
      setTaskGroups(() => taskGroups);
    },
  };
};

const styles = {
  CollapseButton: {
    backgroundColor: 'rgba(241, 245, 249, 0.25)',
    padding: 0.5,
    minWidth: '24px',
    border: `1px solid ${grey200}`,
    borderWidth: '0px 1px 0 1px',
    borderRadius: 0,
    marginRight: 3,
  },
};
