import React, { FC, ReactNode } from 'react';

interface TabsItemProps {
  children: ReactNode;
  id: string | number;
  isActive?: boolean;
  onActiveToggle?: () => void;
}

export const TabsItem: FC<TabsItemProps> = ({ children }) => (
  <div>{children}</div>
);
