import React from 'react';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Stack,
  Theme,
  Typography,
} from '@mui/material';

interface Props {
  contractName: string;
  isOpen: boolean;
  onClose: () => void;
  onDelete: () => void;
}

export const ContractDeleteDialog = ({
  contractName,
  isOpen,
  onClose,
  onDelete,
}: Props) => {
  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle sx={styles.DialogTitle}>
        <Typography>
          Are you sure you want to delete "{contractName}" contract ?
        </Typography>
      </DialogTitle>
      <DialogContent sx={{ p: 4 }}>
        <Stack alignItems="center" direction="column" spacing={2}>
          <Button
            sx={{ width: '300px' }}
            variant="contained"
            color="error"
            size="large"
            onClick={onDelete}
          >
            Delete Contract
          </Button>
          <Button
            sx={{ width: '300px' }}
            variant="outlined"
            size="large"
            onClick={onClose}
          >
            Cancel
          </Button>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

const styles = {
  DialogTitle: {
    p: 4,
    gap: 2,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  IconButton: {
    color: (theme: Theme) => theme.palette.grey[500],
  },
};
