import { axios as localAxios } from './axios';
import { AdjustmentFile, FileDownloadResponse } from './types';

async function getAdjustFile(
  adjustId: string,
  fileId: string
): Promise<FileDownloadResponse> {
  const resp = await localAxios.get(`/adjust/${adjustId}/files/${fileId}`);
  return resp.data;
}

async function listAdjustFiles(
  adjustId: string
): Promise<Array<AdjustmentFile>> {
  const resp = await localAxios.get(`/adjust/${adjustId}/files`);
  return resp.data;
}

async function createAdjustFile(
  adjustId: string,
  payload: { fileName: string; mimetype: string; path: string }
): Promise<AdjustmentFile> {
  const resp = await localAxios.post(`/adjust/${adjustId}/files`, {
    name: payload.fileName,
    mimetype: payload.mimetype,
    path: payload.path,
  });
  return resp.data;
}

async function deleteAdjustFile(adjustId: string, fileId: string) {
  await localAxios.delete(`/adjust/${adjustId}/files/${fileId}`);
}

export { getAdjustFile, listAdjustFiles, createAdjustFile, deleteAdjustFile };
