import React from 'react';
import { Grid, Typography } from '@mui/material';
import { SummaryCard } from '../../../../project-list-page/components/SummaryCard';
import { SubContractSummary } from '../../../../../common/api';
import { grey500, grey900 } from '../../../../../styles/colours';

function SubContractSummaryCards({
  totalSubContracts,
  totalSubContractors,
  percentageProgress,
}: SubContractSummary) {
  return (
    <Grid sx={styles.InfoGrid}>
      <SummaryCard title="#of Sub Contracts">
        <Typography sx={styles.TypographyContent}>
          {totalSubContracts}
        </Typography>
      </SummaryCard>
      <SummaryCard title="#of Subcontractors">
        <Typography sx={styles.TypographyContent}>
          {totalSubContractors}
        </Typography>
      </SummaryCard>
      <SummaryCard title="Total progress">
        <Typography sx={styles.TypographyContent}>
          {percentageProgress}%
        </Typography>
      </SummaryCard>
    </Grid>
  );
}

const styles = {
  InfoGrid: {
    display: 'grid',
    gridTemplateColumns: 'repeat( auto-fit, minmax(100px, 1fr) )',
    gap: '0px',
    marginBottom: '24px',
  },
  TypographyContent: {
    paddingTop: '6px',
    color: grey900,
    fontSize: '20px',
    fontWeight: 500,
  },
  Span: {
    color: grey500,
    fontWeight: 400,
  },
};

export { SubContractSummaryCards };
