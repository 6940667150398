import React, { ChangeEvent, useCallback, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useMutation } from '@tanstack/react-query';
import { LoadingButton } from '@mui/lab';
import { Avatar, Box, Button, TextField, Typography } from '@mui/material';

import { useSnackbar } from '../../../../common/hooks/useSnackbar';
import { useAuth } from '../../../../common/auth-provider/AuthProvider';
import { updateUser, UpdateUserPayload } from '../../../../common/api';
import { loadAuthResponse } from '../../../../common/auth-provider';
import { EMAIL_REGEX } from '../../../../common/validate';
import { useFileUpload } from '../../../../components/file-upload/FileUpload';

const FIRST_NAME = 'firstName';
const LAST_NAME = 'lastName';
const PHONE = 'phone';
const EMAIL = 'email';
const AVATAR_KEY = 'avatarKey';

type FormData = {
  [EMAIL]: string;
  [FIRST_NAME]: string;
  [LAST_NAME]: string;
  [PHONE]: string;
  [AVATAR_KEY]?: string;
};

type Props = {
  close: () => void;
};

function MyAccountForm({ close }: Props) {
  const { user, onLogin } = useAuth();
  const { showAlert, SnackbarComponent } = useSnackbar();

  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const [avatarFile, setAvatarFile] = useState<File | null>(null);

  const { mutateAsync: mutateUpdate, isLoading } = useMutation(
    (payload: UpdateUserPayload) => updateUser(payload),
    {
      onSuccess: res => {
        const auth = loadAuthResponse();
        if (auth) {
          onLogin({
            token: auth?.token,
            refreshToken: auth?.refreshToken,
            user: {
              ...res.data,
            },
          });
        }
        close();
      },
      onError: () =>
        showAlert(
          'There was an error updating the user. Please try again later.',
          'error'
        ),
    }
  );

  const {
    control,
    formState: { errors },
    setValue,
    handleSubmit,
  } = useForm<FormData>({
    defaultValues: {
      [EMAIL]: user?.email,
      [FIRST_NAME]: user?.firstName,
      [LAST_NAME]: user?.lastName,
      [PHONE]: user?.phone,
      [AVATAR_KEY]: user?.avatarKey,
    },
  });

  const onSubmit = useCallback(
    async (form: FormData) => {
      if (user?.id) {
        await mutateUpdate({
          id: user.id,
          accountId: user.accountId,
          email: form[EMAIL],
          firstName: form[FIRST_NAME],
          lastName: form[LAST_NAME],
          avatarKey: form[AVATAR_KEY],
          phone: form[PHONE],
        });
      }
    },
    [user?.accountId, user?.id, mutateUpdate]
  );

  const { mutateGetUploadPath } = useFileUpload({
    onFileUploadSuccess: (_, variables) => {
      setValue(AVATAR_KEY, variables.path);
    },
  });

  const handleFileChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const file = event?.target?.files?.[0];
      if (file) {
        setAvatarFile(file);
        mutateGetUploadPath(file);
      }
    },
    [setAvatarFile, mutateGetUploadPath]
  );

  return (
    <div>
      <SnackbarComponent />
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <Box mb={4}>
          <Typography marginBottom={'4px'}>Avatar</Typography>
          <Box display="flex" gap={1} alignItems="center">
            <Avatar
              sx={{ width: 56, height: 56 }}
              src={
                avatarFile
                  ? URL.createObjectURL(avatarFile)
                  : user?.avatarKey
                  ? user.avatarUrl || ''
                  : ''
              }
            >{`${user?.firstName?.charAt(0)}${user?.lastName?.charAt(
              0
            )}`}</Avatar>
            <Button
              variant="outlined"
              onClick={() => fileInputRef.current?.click()}
            >
              Upload
            </Button>
          </Box>
          <input
            type="file"
            ref={fileInputRef}
            style={{ display: 'none' }} // Hide the file input element
            onChange={handleFileChange}
          />
        </Box>
        <Box display="flex" gap={3} mb={3}>
          <Controller
            name={FIRST_NAME}
            defaultValue=""
            control={control}
            rules={{
              required: 'First name is required',
            }}
            render={({ field }) => (
              <TextField
                {...field}
                label="First Name"
                fullWidth
                required
                error={!!errors[FIRST_NAME]}
                helperText={errors[FIRST_NAME]?.message}
              />
            )}
          />
          <Controller
            name={LAST_NAME}
            defaultValue=""
            control={control}
            rules={{
              required: 'Last name is required',
            }}
            render={({ field }) => (
              <TextField
                {...field}
                label="Last Name"
                fullWidth
                required
                error={!!errors[LAST_NAME]}
                helperText={errors[LAST_NAME]?.message}
              />
            )}
          />
        </Box>
        <Box display="flex" gap={3} mb={3}>
          <Controller
            name={EMAIL}
            defaultValue=""
            control={control}
            rules={{
              required: 'Email is required',
              pattern: {
                value: EMAIL_REGEX,
                message: 'Invalid email address',
              },
            }}
            render={({ field }) => (
              <TextField
                {...field}
                label="Email"
                fullWidth
                required
                error={!!errors[EMAIL]}
                helperText={errors[EMAIL]?.message}
              />
            )}
          />
          <Controller
            name={PHONE}
            defaultValue=""
            control={control}
            rules={{
              required: 'Mobile is required',
            }}
            render={({ field }) => (
              <TextField
                {...field}
                label="Mobile"
                fullWidth
                required
                error={!!errors[PHONE]}
                helperText={errors[PHONE]?.message}
              />
            )}
          />
        </Box>
        <Box>
          <LoadingButton variant="contained" type="submit" loading={isLoading}>
            Update Details
          </LoadingButton>
        </Box>
      </form>
    </div>
  );
}

export { MyAccountForm };
