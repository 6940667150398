export interface CheckboxStates {
  name: string;
  isOn: boolean;
}

export const mainContractVisibilitySettings: CheckboxStates[] = [
  { name: 'anticipatedVariationsFunction', isOn: true },
  { name: 'viewContractorsAnticipatedVariationItems', isOn: false },
  { name: 'shareMyAnticipatedVariationItemsWithContractor', isOn: false },

  { name: 'subcontractorSettingsLocked', isOn: true },
  { name: 'replicateAllSettings', isOn: true },
  { name: 'subcontractorSettingsBelowLocked', isOn: false },

  { name: 'sub_subcontractorOrgInfo', isOn: true },
  { name: 'subsub_subcontractorOrgInfo', isOn: true },
  { name: 'sub_overallProgress', isOn: false },
  { name: 'subsub_overallProgress', isOn: false },
  { name: 'sub_subcontractorClaimItemsNameOnly', isOn: false },
  { name: 'subsub_subcontractorClaimItemsNameOnly', isOn: false },
  { name: 'sub_progressOfSubcontractorItems', isOn: false },
  { name: 'subsub_progressOfSubcontractorItems', isOn: false },
  { name: 'sub_subcontractorWBSValues', isOn: false },
  { name: 'subsub_subcontractorWBSValues', isOn: false },
  { name: 'sub_lockRow3', isOn: false },
  { name: 'subsub_lockRow3', isOn: false },

  { name: 'parentSubcontractorClaimsMade', isOn: false },
  { name: 'claims_lockedToSubcontractor', isOn: false },
  { name: 'claims_replicatedToSubcontractorBelow', isOn: false },
  { name: 'claims_lockedToSubcontractorBelow', isOn: false },

  { name: 'submitted', isOn: false },
  { name: 'submitted_lockedToSubcontractor', isOn: false },
  { name: 'submitted_replicatedToSubcontractorBelow', isOn: false },
  { name: 'submitted_lockedToSubcontractorBelow', isOn: false },
  { name: 'approved', isOn: false },
  { name: 'approved_lockedToSubcontractor', isOn: false },
  { name: 'approved_replicatedToSubcontractorBelow', isOn: false },
  { name: 'approved_lockedToSubcontractorBelow', isOn: false },
  { name: 'rejected', isOn: false },
  { name: 'rejected_lockedToSubcontractor', isOn: false },
  { name: 'rejected_replicatedToSubcontractorBelow', isOn: false },
  { name: 'rejected_lockedToSubcontractorBelow', isOn: false },
  { name: 'withdrawn', isOn: false },
  { name: 'withdrawn_lockedToSubcontractor', isOn: false },
  { name: 'withdrawn_replicatedToSubcontractorBelow', isOn: false },
  { name: 'withdrawn_lockedToSubcontractorBelow', isOn: false },
  { name: 'anticipated', isOn: false },
  { name: 'anticipated_lockedToSubcontractor', isOn: false },
  { name: 'anticipated_replicatedToSubcontractorBelow', isOn: false },
  { name: 'anticipated_lockedToSubcontractorBelow', isOn: false },

  { name: 'sopaComplianceAlert', isOn: false },
  { name: 'sopaComplianceAlert_lockedToSubcontractor', isOn: false },
  { name: 'sopaComplianceAlert_replicatedToSubcontractorBelow', isOn: false },
  { name: 'sopaComplianceAlert_lockedToSubcontractorBelow', isOn: false },

  { name: 'subcontractorNotPaid', isOn: false },
  { name: 'subsub_subcontractorNotPaid', isOn: false },
  { name: 'contractorWBSValueRange', isOn: true },
  { name: 'subsub_contractorWBSValueRange', isOn: true },
  { name: 'contractorExactWBSValues', isOn: false },
  { name: 'subsub_contractorExactWBSValues', isOn: false },
  { name: 'sub_lockRow7', isOn: false },
  { name: 'subsub_lockRow7', isOn: false },

  { name: 'complianceDocumentsExpire', isOn: false },
  { name: 'compliance_lockedToSubcontractor', isOn: false },
  { name: 'compliance_replicatedToSubcontractorBelow', isOn: false },
  { name: 'compliance_lockedToSubcontractorBelow', isOn: false },
];
