import { Button, Dialog, DialogContent, Typography } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import React, { useCallback } from 'react';
import { Heading } from '../../../styles/text';
import { deleteTaskGroup } from '../../../common/api/taskGroups';
import { useSnackbar } from '../../../common/hooks/useSnackbar';
import { InputActions } from '../../../styles';
import { LoadingButton } from '@mui/lab';

type Props = {
  id: string;
  name: string;
  onReload: () => void;
  handleClose: () => void;
};

function TaskGroupDeleteDialog({ id, name, onReload, handleClose }: Props) {
  const { showAlert, SnackbarComponent } = useSnackbar();

  const { mutateAsync, isLoading } = useMutation(() => deleteTaskGroup(id), {
    onError: () => showAlert('An error occured deleting the folder', 'error'),
  });

  const handleDelete = useCallback(async () => {
    await mutateAsync();
    onReload();
    handleClose();
  }, [handleClose, onReload, mutateAsync]);

  return (
    <>
      <SnackbarComponent />
      <Dialog open onClose={handleClose}>
        <DialogContent sx={{ width: 400 }}>
          <Heading>Delete Folder?</Heading>
          <Typography>Are you sure you want to delete {name}?</Typography>
          <InputActions>
            <Button variant="outlined" type="button" onClick={handleClose}>
              Cancel
            </Button>
            <LoadingButton
              color="error"
              variant="contained"
              loading={isLoading}
              disabled={isLoading}
              onClick={handleDelete}
            >
              Delete
            </LoadingButton>
          </InputActions>
        </DialogContent>
      </Dialog>
    </>
  );
}

export { TaskGroupDeleteDialog };
