import { ResponseListBaseType, TaskGroup } from './types';
import { axios } from './axios';

async function listTaskGroups(
  contractId: string,
  id?: string,
  options: { take?: number; skip?: number } = {}
): Promise<ResponseListBaseType<Array<TaskGroup>>> {
  const { skip, take } = options;
  const resp = await axios.get(`/contract/${contractId}/taskgroup`, {
    params: {
      skip,
      take,
      id,
    },
  });

  return resp.data;
}

async function saveTaskGroup(payload: {
  id?: string;
  name?: string;
  contractId: string;
  parentId: string | null;
  order?: string;
}): Promise<TaskGroup> {
  if (payload.id) {
    const resp = await axios.patch(`/taskgroup/${payload.id}`, payload);
    return resp.data;
  }
  const resp = await axios.post(`/taskgroup`, payload);
  return resp.data;
}

async function deleteTaskGroup(id: string): Promise<Boolean> {
  const resp = await axios.delete(`/taskgroup/${id}`);
  return resp.data;
}

async function moveTaskGroup(payload: {
  id: string;
  parentId: string | null;
  order: string;
}): Promise<TaskGroup> {
  const resp = await axios.patch(`/taskgroup/${payload.id}/move`, payload);
  return resp.data;
}

export { listTaskGroups, saveTaskGroup, deleteTaskGroup, moveTaskGroup };
