import { InputLabel, OutlinedInput, Typography } from '@mui/material';
import { grey600 } from './colours';
import styled from '@emotion/styled';
import { Box } from '@mui/system';

export const InputDescription = styled(Typography)`
  color: ${grey600};
  font-size: 0.75rem;
  margin-top: 0.25rem;
`;

export const InputActions = styled(Box)`
  display: flex;
  justify-content: space-between;
  padding-top: 1.5rem;
`;

export const FilterInput = styled(OutlinedInput)`
  & .MuiOutlinedInput-input {
    padding: 8px 32px 8px 14px;
    font-size: 14px;
  }
`;

export const SmallInputLabel = styled(InputLabel)`
  font-size: 14px;
  transform: translate(14px, 10px) scale(1);
  &.Mui-focused {
    transform: translate(20px, -9px) scale(0.75);
  }
  &.MuiInputLabel-shrink {
    transform: translate(20px, -9px) scale(0.75);
  }
`;
