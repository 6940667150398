import React from 'react';
import { Box, TableCell, TableRow } from '@mui/material';

import { ManualPayment } from '../../../common/api';
import { KebabMenu } from '../../../components/menu/KebabMenu';
import { formatCurrency, formatDate } from '../../../common/format';
import PaymentManualFilesCommentView from './PaymentManualFilesCommentView';
import { useDialogState } from '../../../components/dialog/dialog.hooks';

type Props = {
  manualPayment: ManualPayment;
};

const PaymentIncomeManualPreviousRow = ({ manualPayment }: Props) => {
  const { isVisible, open, close } = useDialogState();

  return (
    <TableRow>
      {isVisible && (
        <PaymentManualFilesCommentView
          manualPayment={manualPayment}
          onClose={close}
        />
      )}
      <TableCell>{formatCurrency(manualPayment.amount)}</TableCell>
      <TableCell>{formatDate(manualPayment.paidAt)}</TableCell>
      <TableCell>{formatDate(manualPayment.confirmedAt)}</TableCell>
      <TableCell>
        <Box display="flex" alignItems="center" gap="8px">
          <KebabMenu
            size="small"
            orientation="vertical"
            options={['View Comments', 'View Files']}
            onOptionSelect={async value => {
              open();
            }}
          />
        </Box>
      </TableCell>
    </TableRow>
  );
};

export default PaymentIncomeManualPreviousRow;
