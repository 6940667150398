import { ContractStatus, Task, ValueType } from '../../../common/api';
import { HeadCell } from '../../../components/sorted-table-head/SortedTable.hooks';
import { getCostToComplete } from '../views/contract-claim-view/components/CurrentClaimTab.utils';

export interface TaskRowData {
  id: string;
  identifier: string | number;
  isNew: string;
  description: string;
  value: number;
  type: string;
  unitOfMeasure: string;
  quantity?: number;
  rate?: number;
  originalValue: string;
  claimed: number;
  approved: string;
  paid: string;
  progress: number;
  taskGroupId: string;
  valueType: ValueType;
  contractId: string;
  parentTaskId: string;
  approvedVariation: string;
  unapprovedVariation: string;
  variationStatus: string;
  revisionTaskId: string;
  children?: TaskRowData[];
  contractorName?: string;
  ref?: string;
}

type TaskStatusType = 'new' | 'updated' | 'deleted';

export interface TaskRowDataWithStatus extends TaskRowData {
  status?: TaskStatusType;
}

export interface TaskRowPropsWithStatus extends Task {
  status?: TaskStatusType;
}

const findLastUnassignedTaskIndex = (tasks: Array<Task>): string => {
  let maxIndex = -1;

  tasks.forEach(task => {
    const id = task.identifier;

    if (id.startsWith('0.')) {
      const index = parseFloat(id.slice(2));
      if (index > maxIndex) {
        maxIndex = index;
      }
    }
  });

  if (maxIndex === -1) {
    return '0';
  }
  return `0.${maxIndex}`;
};

function createTaskRow(task: TaskRowPropsWithStatus) {
  const costToComplete = getCostToComplete(
    task.value,
    task.paid,
    task.approved,
    task.claimed
  );

  const costToCompletePercentsValue = Math.floor(
    (costToComplete / Number(task.value) || 0) * 100
  );

  return {
    id: task.id,
    identifier: task.identifier,
    isNew: `${task.isNew}`,
    description: task.description,
    type: task.type,
    unitOfMeasure: task.unitOfMeasure,
    quantity: Number(task.quantity),
    rate: Number(task.rate),
    value: Number(task.value),
    excludeFromRetention: task.excludeFromRetention,
    originalValue: task.originalValue,
    claimed: Number(task.claimed),
    approved: task.approved,
    paid: task.paid,
    progress: costToCompletePercentsValue,
    taskGroupId: task.taskGroupId,
    valueType: task.valueType,
    contractId: task.contractId,
    parentTaskId: task.parentTaskId || '',
    approvedVariation: task.totalApprovedVariation,
    unapprovedVariation: task.totalUnapprovedVariation,
    variationStatus: task.variationStatus,
    revisionTaskId: task.revisionTaskId,
    children: task.children || [],
    ref: task.ref || '',
    ...(task.status && { status: task.status }),
  };
}

interface HeadCellProps {
  isSubcontract?: boolean;
  status: ContractStatus;
  isEditable?: boolean;
  hasCheckbox?: boolean;
  isUserMainCustomer?: boolean;
}

function headCells({
  isSubcontract,
  status,
  isEditable = false,
  hasCheckbox,
  isUserMainCustomer,
}: HeadCellProps): Array<HeadCell> {
  const isDraft = status === ContractStatus.Draft;
  const isAwaiting = status === ContractStatus.AwaitingAcceptance;

  return [
    {
      id: 'checkbox',
      numeric: false,
      disablePadding: false,
      label: '',
      isHidden: !hasCheckbox,
      disable: true,
    },
    {
      id: 'identifier',
      numeric: false,
      disablePadding: false,
      label: 'ID',
    },
    {
      id: 'description',
      numeric: false,
      disablePadding: false,
      label: 'Task Name',
    },
    {
      id: 'type',
      numeric: false,
      disablePadding: false,
      label: 'Task Type',
    },
    {
      id: 'uom',
      numeric: false,
      disablePadding: false,
      label: 'Units of Measure',
    },
    {
      id: 'qty',
      numeric: true,
      disablePadding: false,
      label: 'Quantity',
    },
    {
      id: 'rate',
      numeric: true,
      disablePadding: false,
      label: 'Rate',
    },
    {
      id: 'qtyRate',
      numeric: false,
      disablePadding: false,
      label: 'QTY/$Rate',
    },
    {
      id: 'value',
      numeric: true,
      disablePadding: false,
      label: 'Value',
      isHidden: isSubcontract,
    },
    {
      id: 'excludeFromRetention',
      numeric: false,
      disablePadding: false,
      label: 'Exclude from Retention',
    },
    {
      id: 'progress',
      numeric: true,
      disablePadding: false,
      label: 'Progress',
      isHidden: isEditable && (isDraft || isAwaiting),
    },
    {
      id: 'claimed',
      numeric: true,
      disablePadding: false,
      label: 'Claim Value',
      isHidden: isEditable && (isSubcontract || isDraft || isAwaiting),
    },
    {
      id: 'actions',
      numeric: true,
      disablePadding: false,
      label: '',
      disable: true,
      isHidden: !isUserMainCustomer && !(isEditable && isDraft),
    },
    {
      id: 'assign',
      numeric: true,
      disablePadding: false,
      label: '',
      disable: true,
      isHidden: !isDraft,
    },
  ];
}

function excludeChildrenField<T extends Record<string, any>>(
  obj: T
): Omit<T, 'children'> {
  const { children, ...rest } = obj;
  return rest;
}

function excludeChildrenFieldFromArray(
  rows: TaskRowData[]
): Omit<TaskRowData, 'children'>[] {
  return rows.map(row => excludeChildrenField(row));
}

export {
  createTaskRow,
  headCells,
  findLastUnassignedTaskIndex,
  excludeChildrenFieldFromArray,
};
