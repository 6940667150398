export enum MetricKey {
  ProjectName = 'ProjectName',
  ContractName = 'ContractName',
  ProgessBar = 'ProgessBar',
  Status = 'Status',
  ActiveContracts = 'ActiveContracts',
  ActiveSubContracts = 'ActiveSubContracts',
  ProjectProgress = 'ProjectProgress',
  OriginalProjectValue = 'OriginalProjectValue',
  AdjustedProjectValue = 'AdjustedProjectValue',
  TotalPaidToDate = 'TotalPaidToDate',
  CurrentlyClaimed = 'CurrentlyClaimed',
  CurrentlyApproved = 'CurrentlyApproved',
  ValueToComplete = 'ValueToComplete',
  SopaClaimIncidents = 'SopaClaimIncidents',
  SopaPaymentIncidents = 'SopaPaymentIncidents',
  SopaClaimIncidentsHistoric = 'SopaClaimIncidentsHistoric',
  SopaPaymentIncidentsHistoric = 'SopaPaymentIncidentsHistoric',
  SopaSupplyChainClaimIncidentsCurrent = 'SopaSupplyChainClaimIncidentsCurrent',
  SopaSupplyChainPaymentIncidentsCurrent = 'SopaSupplyChainPaymentIncidentsCurrent',
  SopaSupplyChainClaimIncidentsHistoric = 'SopaSupplyChainClaimIncidentsHistoric',
  SopaSupplyChainPaymentIncidentsHistoric = 'SopaSupplyChainPaymentIncidentsHistoric',
  TotalNumberApprovedVariations = 'TotalNumberApprovedVariations',
  TotalNumberReceivedVariations = 'TotalNumberReceivedVariations',
  TotalNumberRejectedVariations = 'TotalNumberRejectedVariations',
  TotalNumberClosedVariations = 'TotalNumberClosedVariations',
  TotalApprovedVariations = 'TotalApprovedVariations',
  TotalClaimedUnapprovedVariations = 'TotalClaimedUnapprovedVariations',
}

export enum MetricFormat {
  Percentage = 'Percentage',
  Dollar = 'Dollar',
  Number = 'Number',
  List = 'List',
  Visual = 'Visual',
}

export interface Metric {
  id: string;
  key: MetricKey;
  title: string;
  canRemove: boolean;
  format: MetricFormat;
  group: string;
}
