import React from 'react';
import { LinkTo, StyledTable } from '../../../styles';
import { TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { generatePath } from 'react-router-dom';
import { formatCurrency } from '../../../common/format';

type Props = {
  paymentDetail: {
    payeeName?: string;
    contractId?: string;
    claimId?: string;
    description?: string;
    claimPeriodStart?: string;
    claimPeriodEnd?: string;
    dueAt?: string;
    amount?: number;
    amountOutstanding?: number;
  };
};

const PaymentManualClaimTable = ({ paymentDetail }: Props) => {
  console.log(paymentDetail);
  return (
    <StyledTable sx={{ mb: 2 }}>
      <TableHead>
        <TableRow>
          <TableCell sx={{ width: '300px' }}>Item</TableCell>
          <TableCell sx={{ width: '300px' }}> Label</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell>Payee</TableCell>
          <TableCell>{paymentDetail?.payeeName}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Claim Name</TableCell>
          <TableCell>
            <LinkTo
              to={generatePath('/contracts/:contractId/claims/:claimId', {
                contractId: paymentDetail.contractId || '',
                claimId: paymentDetail.claimId || '',
              })}
            >
              {paymentDetail.description}
            </LinkTo>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Claim Period</TableCell>
          <TableCell>
            {paymentDetail.claimPeriodStart} - {paymentDetail.claimPeriodEnd}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Payment Due Date</TableCell>
          <TableCell>{paymentDetail.dueAt}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Original Amount Due</TableCell>
          {paymentDetail.amount && (
            <TableCell>{formatCurrency(paymentDetail.amount)}</TableCell>
          )}
        </TableRow>
        <TableRow>
          <TableCell>Current Amount Due</TableCell>
          <TableCell>
            {paymentDetail.amountOutstanding && (
              <>{formatCurrency(paymentDetail.amountOutstanding)}</>
            )}
          </TableCell>
        </TableRow>
      </TableBody>
    </StyledTable>
  );
};

export default PaymentManualClaimTable;
