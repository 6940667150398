import React from 'react';
import { Button, TableCell, TableRow, Typography } from '@mui/material';
import { formatAmountByType } from '../../../../../common/format';
import { ProgressBar } from '../../../../../components/progress-bar';
import { Adjust } from '../../../../../common/api';
import { ClaimItemRow } from './ClaimTable.utils';
import { useDialogState } from '../../../../../components/dialog/dialog.hooks';
import { ClaimAdjust } from './ClaimAdjust';
import { grey900 } from '../../../../../styles/colours';

interface Props {
  row: ClaimItemRow;
  reloadData: () => void;
  claimId: string;
  adjust?: Adjust;
}

function ClaimTableRow(props: Props) {
  const { row, reloadData, adjust } = props;
  const {
    isVisible: adjustDrawer,
    open: openAdjustDrawer,
    close: closeAdjustDrawer,
  } = useDialogState();

  if (!row.taskId) {
    return null;
  }

  return (
    <>
      <TableRow key={row.id}>
        <TableCell>{row?.identifier || ''}</TableCell>
        <TableCell>{row?.description}</TableCell>
        <TableCell>
          {formatAmountByType(Number(row.taskValue), row.valueType)}
        </TableCell>
        <TableCell>
          {formatAmountByType(row.previouslyApproved, row.valueType)}
        </TableCell>
        <TableCell>
          <ProgressBar
            total={row.taskValue}
            claimed={adjust?.value || row.value}
            approved={row.approved}
            paid={row.paid}
            valueType={row.valueType}
          />
        </TableCell>
        <TableCell>
          {adjust?.value ? (
            <>
              <Typography>
                {formatAmountByType(adjust.value, row.valueType)}
              </Typography>
              <Typography
                sx={{ textDecoration: 'line-through', fontSize: '12px' }}
              >
                {formatAmountByType(row.value, row.valueType)}
              </Typography>
            </>
          ) : (
            <Typography>
              {formatAmountByType(row.value, row.valueType)}
            </Typography>
          )}
        </TableCell>
        <TableCell>
          {adjust && (
            <Button
              variant="text"
              sx={{ textDecoration: 'underline', color: grey900 }}
              onClick={openAdjustDrawer}
            >
              View Adjust
            </Button>
          )}
        </TableCell>
      </TableRow>
      <ClaimAdjust
        reloadData={reloadData}
        claimItem={row}
        edit={false}
        open={adjustDrawer}
        adjust={adjust}
        onClose={closeAdjustDrawer}
      />
    </>
  );
}

export { ClaimTableRow };
