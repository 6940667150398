import React, { ReactElement } from 'react';
import Grid from '@mui/material/Grid';
import splash from '../../common/assets/splash.jpg';
import Box from '@mui/material/Box';
import { ReactComponent as Logo } from '../../common/assets/logo.svg';

interface Props {
  children: ReactElement;
}

function LoginLayout(props: Props) {
  const { children } = props;
  return (
    <Grid container sx={{ height: '100vh' }}>
      <Grid
        item
        xs={false}
        sm={false}
        md={7}
        sx={{
          backgroundImage: `url(${splash})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      />
      <Grid item xs={12} sm={12} md={5}>
        <Box
          sx={{
            my: 8,
            mx: 4,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Box mb={3} sx={{ display: 'flex', alignItems: 'center' }}>
            <Logo />
          </Box>
          {children}
        </Box>
      </Grid>
    </Grid>
  );
}

export { LoginLayout };
