import {
  Box,
  Button,
  Dialog,
  DialogContent,
  IconButton,
  InputAdornment,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import React, { useMemo, useState } from 'react';
import { ResponseListBaseType, Task, listTasks } from '../../common/api';
import { Heading } from '../../styles/text';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import { DialogClose, StyledTable, StyledTableRow } from '../../styles';
import { grey600 } from '../../styles/colours';
import { VariationReceivedRowItem } from './variationsPage.utils';

type Props = {
  contractId: string;
  variationItems: Array<VariationReceivedRowItem> | Array<Task>;
  variationItemIdToAssignTo: string;
  parentTaskId?: string;
  assignToTask: (taskId: string) => void;
  handleClose: () => void;
};

function VariationAssignParent({
  contractId,
  variationItems,
  variationItemIdToAssignTo,
  assignToTask,
  handleClose,
}: Props) {
  const [term, setTerm] = useState<string>('');

  const { data: taskData } = useQuery<ResponseListBaseType<Array<Task>>>(
    ['tasks', contractId],
    () => listTasks(contractId)
  );

  const filteredTasks = useMemo(() => {
    return taskData?.data.filter(task => task.contractId === contractId) || [];
  }, [taskData, contractId]);

  // @ts-ignore
  const item = variationItems.find(
    (v: VariationReceivedRowItem | Task) => v.id === variationItemIdToAssignTo
  );

  const results = useMemo(() => {
    const tasks = filteredTasks.filter(task => task.id !== item.id) || [];
    return tasks.filter(
      task =>
        task.description.toLowerCase().indexOf(term.toLocaleLowerCase()) !==
          -1 ||
        task.identifier.toLowerCase().indexOf(term.toLocaleLowerCase()) !== -1
    );
  }, [filteredTasks, term, item.id]);

  const parentTask = results.find(r => r.id === item?.parentTaskId);

  return (
    <Dialog open onClose={handleClose}>
      <DialogContent sx={{ width: '600px', height: '700px' }}>
        <DialogClose onClose={handleClose} />
        <Heading>Add New Task to Parent Task</Heading>
        <TextField
          fullWidth
          value={term}
          placeholder="Search by task name or ID"
          sx={{
            borderRadius: '40px',
            '& > div': {
              borderRadius: '40px',
            },
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <IconButton type="button" onClick={() => setTerm('')}>
                  <ClearIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
          onChange={e => setTerm(e.target.value)}
        />
        {parentTask && (
          <Typography
            fontWeight="bold"
            sx={{
              mb: '16px',
            }}
          >
            Current assigned to: {parentTask.identifier}{' '}
            {parentTask.description}
          </Typography>
        )}
        <Box
          sx={{
            position: 'absolute',
            top: '140px',
            bottom: '0',
            left: 0,
            right: 0,
            paddingLeft: '24px',
            paddingRight: '24px',
            paddingBottom: '24px',
            overflow: 'auto',
          }}
        >
          <StyledTable sx={{ mt: '16px', overflow: 'scroll' }}>
            <TableHead
              sx={{
                th: {
                  fontSize: '12px',
                  padding: '10px',
                  color: grey600,
                },
              }}
            >
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Task Name</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {results.map(result => (
                <StyledTableRow key={result.id}>
                  <TableCell>{result.identifier}</TableCell>
                  <TableCell>{result.description}</TableCell>
                  <TableCell sx={{ textAlign: 'right' }}>
                    <Button
                      variant="contained"
                      onClick={() => {
                        assignToTask(result.id);
                        handleClose();
                      }}
                    >
                      Assign
                    </Button>
                  </TableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </StyledTable>
        </Box>
        <Box
          sx={{
            position: 'absolute',
            bottom: 0,
            left: 0,
            right: 0,
            padding: '24px',
            '& > div': {
              padding: 0,
            },
          }}
        ></Box>
      </DialogContent>
    </Dialog>
  );
}

export { VariationAssignParent };
