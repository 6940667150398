import React, { useCallback, useEffect } from 'react';
import { Controller, SubmitHandler, useForm, useWatch } from 'react-hook-form';
import { useMutation } from '@tanstack/react-query';
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';

// Utils
import { useTaskGroupContext } from '../../../components/task-group/components/TaskGroup.context';
import { CreateTaskPayload, createTask } from '../../../common/api';
import { useSnackbar } from '../../../common/hooks/useSnackbar';
import MenuItem from '@mui/material/MenuItem';

const ID_FIELD = 'id';
const NAME_FIELD = 'name';
const TYPE_FIELD = 'taskType';
const UOM_FIELD = 'unitOfMeasure';
const QTY_FIELD = 'quantity';
const RATE_FIELD = 'rate';
const AMOUNT_FIELD = 'amount';
const EXCLUDE_FIELD = 'excludeFromRetention';

type FormData = {
  [ID_FIELD]: string;
  [NAME_FIELD]: string;
  [TYPE_FIELD]: string;
  [UOM_FIELD]: string;
  [QTY_FIELD]: number;
  [RATE_FIELD]: number;
  [AMOUNT_FIELD]: string;
  [EXCLUDE_FIELD]: boolean;
};

type Props = {
  nextTaskId: number;
  selectedTaskGroup?: string | null;
  contractId: string;
  isShowScheduleFields: boolean;
  setIsShowScheduleFields: (value: boolean) => void;
  reloadContract: () => void;
  reloadData: () => void;
};

export function AddTaskForm({
  selectedTaskGroup = '0',
  contractId,
  nextTaskId,
  reloadContract,
  reloadData,
  isShowScheduleFields,
  setIsShowScheduleFields,
}: Props) {
  const { SnackbarComponent, showAlert } = useSnackbar();
  const { taskGroups } = useTaskGroupContext();

  const taskGroup = taskGroups.find(tg => tg.id === selectedTaskGroup);

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { errors, isValid },
  } = useForm<FormData>({
    mode: 'onChange',
  });

  useEffect(() => {
    setValue(ID_FIELD, nextTaskId.toString());
  }, [nextTaskId, setValue]);

  const { mutateAsync: mutateAddTask, isLoading } = useMutation(
    (payload: CreateTaskPayload) => createTask(payload),
    {
      onSuccess: (_, variables) => {
        const nextId =
          Number(variables.identifier) >= nextTaskId
            ? Number(variables.identifier) + 1
            : nextTaskId;
        reloadData();
        reset({
          [ID_FIELD]: nextId.toString(),
          [NAME_FIELD]: '',
          [TYPE_FIELD]: '',
          [UOM_FIELD]: '',
          [QTY_FIELD]: 0,
          [RATE_FIELD]: 0,
          [AMOUNT_FIELD]: '',
        });
      },
      onError: () => {
        showAlert('There was an error saving the task.', 'error');
      },
    }
  );

  const onSubmit = useCallback<SubmitHandler<FormData>>(
    async (data: FormData) => {
      const newTaskData: CreateTaskPayload = {
        identifier: data[ID_FIELD],
        name: data[NAME_FIELD],
        value: Number(data[AMOUNT_FIELD]),
        type: data[TYPE_FIELD],
        unitOfMeasure: data[UOM_FIELD],
        quantity: data[QTY_FIELD],
        rate: data[RATE_FIELD],
        excludeFromRetention: data[EXCLUDE_FIELD],
        tagIds: [],
        taskGroupId:
          !selectedTaskGroup || selectedTaskGroup === '0'
            ? undefined
            : selectedTaskGroup,
        contractId: contractId,
      };
      await mutateAddTask(newTaskData);
      reloadContract();
    },
    [contractId, selectedTaskGroup, mutateAddTask, reloadContract]
  );

  const handleTaskTypeChange = useCallback(
    (value: string) => {
      setValue(UOM_FIELD, '');
      setValue(QTY_FIELD, 0);
      setValue(RATE_FIELD, 0);
      setIsShowScheduleFields(value === 'scheduleOfRates');
    },
    [setValue, setIsShowScheduleFields]
  );

  const qty = useWatch({
    control,
    name: QTY_FIELD,
    defaultValue: 0,
  });

  const rate = useWatch({
    control,
    name: RATE_FIELD,
    defaultValue: 0,
  });

  useEffect(() => {
    if (isShowScheduleFields && qty && rate) {
      const calculatedAmount = qty * rate;
      setValue(AMOUNT_FIELD, calculatedAmount.toFixed(0));
    }
  }, [qty, rate, setValue, isShowScheduleFields]);

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      <SnackbarComponent />
      <Box
        display="flex"
        alignItems="baseline"
        justifyContent="space-between"
        sx={{ p: '16px 0', '.MuiTextField-root': { marginRight: '10px' } }}
      >
        <Box display="flex">
          <Controller
            name={ID_FIELD}
            defaultValue={nextTaskId.toString()}
            control={control}
            rules={{
              required: 'ID is required',
            }}
            render={({ field }) => (
              <TextField
                sx={{ width: '80px' }}
                {...field}
                label="ID"
                size="small"
                required
                error={!!errors[ID_FIELD]}
                helperText={errors[ID_FIELD]?.message}
                inputProps={{
                  inputMode: 'numeric',
                  pattern: '[0-9]*',
                  onInput: e => {
                    const target = e.target as HTMLInputElement;
                    target.value = target.value.replace(/[^0-9]/g, '');
                    field.onChange(target.value);
                  },
                }}
              />
            )}
          />
          <Controller
            name={NAME_FIELD}
            defaultValue=""
            control={control}
            rules={{
              required: 'Name is required',
            }}
            render={({ field }) => (
              <TextField
                sx={{ width: '140px' }}
                {...field}
                label="Task name"
                required
                size="small"
                error={!!errors[NAME_FIELD]}
                helperText={errors[NAME_FIELD]?.message}
              />
            )}
          />
          <Controller
            name={TYPE_FIELD}
            defaultValue=""
            control={control}
            rules={{ required: 'Task Type is required' }}
            render={({ field }) => (
              <FormControl
                size="small"
                sx={{ minWidth: 150, marginRight: '10px' }}
              >
                <InputLabel id="task-type-label">Task Type*</InputLabel>
                <Select
                  {...field}
                  label="Task Type"
                  required
                  error={!!errors[TYPE_FIELD]}
                  onChange={e => {
                    field.onChange(e);
                    handleTaskTypeChange(e.target.value);
                  }}
                >
                  <MenuItem value="progress">Progress (%)</MenuItem>
                  <MenuItem value="milestone">Milestone</MenuItem>
                  <MenuItem value="scheduleOfRates">Schedule of rates</MenuItem>
                </Select>
              </FormControl>
            )}
          />
          {isShowScheduleFields && (
            <>
              <Controller
                name={UOM_FIELD}
                defaultValue=""
                control={control}
                rules={{ required: 'Unit of Measure is required' }}
                render={({ field }) => (
                  <FormControl
                    size="small"
                    sx={{ minWidth: 150, marginRight: '10px' }}
                  >
                    <InputLabel id="task-type-label">
                      Unit of Measure*
                    </InputLabel>
                    <Select
                      {...field}
                      label="Unit of Measure*"
                      required
                      error={!!errors[UOM_FIELD]}
                    >
                      <MenuItem value="m">m</MenuItem>
                      <MenuItem value="m&sup2;">m&sup2;</MenuItem>
                      <MenuItem value="hours">hours</MenuItem>
                      <MenuItem value="kg">kg</MenuItem>
                      <MenuItem value="tonne">tonne</MenuItem>
                      <MenuItem value="others">Others</MenuItem>
                    </Select>
                  </FormControl>
                )}
              />
              <Controller
                name={QTY_FIELD}
                defaultValue={0}
                control={control}
                rules={{
                  required: 'QTY is required',
                  pattern: {
                    value: /^[0-9]*$/,
                    message: 'Value should be a prime number',
                  },
                  min: {
                    value: 1,
                    message: 'QTY must be greater than 0',
                  },
                }}
                render={({ field }) => (
                  <TextField
                    sx={{ width: '80px' }}
                    {...field}
                    label="QTY*"
                    size="small"
                    error={!!errors[QTY_FIELD]}
                    helperText={errors[QTY_FIELD]?.message}
                  />
                )}
              />
              <Controller
                name={RATE_FIELD}
                defaultValue={0}
                control={control}
                rules={{
                  required: 'Rate is required',
                  pattern: {
                    value: /^[0-9]*$/,
                    message: 'Value should be a valid rate',
                  },
                  min: {
                    value: 1,
                    message: 'Rate must be greater than 0',
                  },
                }}
                render={({ field }) => (
                  <TextField
                    sx={{ width: '80px' }}
                    {...field}
                    label="Rate*"
                    size="small"
                    error={!!errors[RATE_FIELD]}
                    helperText={errors[RATE_FIELD]?.message}
                  />
                )}
              />
            </>
          )}
          <Controller
            name={AMOUNT_FIELD}
            defaultValue=""
            control={control}
            rules={{
              required: 'Amount is required',
              pattern: {
                value: /^[0-9]*$/,
                message: 'Value should be a number',
              },
            }}
            render={({ field }) => (
              <TextField
                {...field}
                label="Amount"
                required
                size="small"
                disabled={isShowScheduleFields}
                error={!!errors[AMOUNT_FIELD]}
                helperText={errors[AMOUNT_FIELD]?.message}
              />
            )}
          />
          <Controller
            name="excludeFromRetention"
            defaultValue={false}
            control={control}
            render={({ field }) => (
              <FormControlLabel
                control={<Checkbox {...field} checked={field.value} />}
                label="Exclude from Retention"
                sx={{
                  '.MuiFormControlLabel-label': {
                    fontSize: '0.75rem',
                  },
                }}
              />
            )}
          />
          <Typography display="inline-block" sx={{ lineHeight: '40px' }}>
            {taskGroup?.name || 'All Tasks'}
          </Typography>
        </Box>
        <LoadingButton
          type="submit"
          variant="outlined"
          loading={isLoading}
          disabled={!isValid}
        >
          Add Task
        </LoadingButton>
      </Box>
    </form>
  );
}
