import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import React, { useCallback, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import dayjs from 'dayjs';
import { useMutation } from '@tanstack/react-query';
import { LoadingButton } from '@mui/lab';
import DeleteIcon from '@mui/icons-material/Delete';

import { useDialogState } from '../../../components/dialog/dialog.hooks';
import { FileUploadDialog } from '../../../components/file-upload/FileUploadDropzone';
import {
  UpsertManualPayment,
  upsertManualPayment,
} from '../../../common/api/manual-payment';
import { DEFAULT_DATE_FORMAT } from '../../../common/format';
import { DialogClose, Heading, InputActions } from '../../../styles';
import { useSnackbar } from '../../../common/hooks/useSnackbar';

type LocalFile = {
  id?: string;
  path: string;
  name: string;
  mimetype: string;
  uploaderId: string;
  file?: File;
};

const AMOUNT_FIELD = 'amount';
const DATE_FIELD = 'date';
const COMMENT_FIELD = 'comment';

type FormData = {
  [AMOUNT_FIELD]: number;
  [DATE_FIELD]: string;
  [COMMENT_FIELD]: string;
};

const PaymentMarkAsPaid = ({
  accountId,
  userId,
  paymentId,
  onManualPaymentCreated,
  onClose,
}: {
  accountId: string;
  userId: string;
  paymentId: string;
  onManualPaymentCreated: () => void;
  onClose: () => void;
}) => {
  const { SnackbarComponent } = useSnackbar();
  const [fileList, setFileList] = useState<LocalFile[]>([]);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>();

  const { mutateAsync: mutateManualPayment, isLoading: isMutateLoading } =
    useMutation(
      (payload: UpsertManualPayment) =>
        upsertManualPayment(accountId, paymentId, payload),
      {
        onSuccess: () => onManualPaymentCreated(),
      }
    );

  const {
    isVisible: isUploadVisible,
    close: closeUpload,
    open: openUpload,
  } = useDialogState();

  const onSubmit = useCallback(
    (data: FormData) => {
      mutateManualPayment({
        amount: data[AMOUNT_FIELD],
        comments: data[COMMENT_FIELD],
        paidAt: data[DATE_FIELD],
        files: fileList.map(file => ({
          mimetype: file.mimetype,
          name: file.name,
          path: file.path,
          uploaderId: file.uploaderId,
        })),
      });
    },
    [fileList, mutateManualPayment]
  );

  return (
    <>
      <SnackbarComponent />
      <Dialog open fullWidth maxWidth="xs" onClose={onClose}>
        <DialogContent>
          <Heading>Create Manual Payment</Heading>
          <DialogClose onClose={onClose} />
          <form noValidate onSubmit={handleSubmit(onSubmit)}>
            {isUploadVisible && (
              <FileUploadDialog
                isLocal={false}
                onFileUpload={payload => {
                  setFileList([
                    ...fileList,
                    {
                      file: payload.file,
                      path: payload.path,
                      mimetype: payload.contentType,
                      name: payload.file.name,
                      uploaderId: userId,
                    },
                  ]);
                }}
                close={closeUpload}
              />
            )}
            <Box display="flex" gap={3} mb={3}>
              <Box>
                <Controller
                  name={AMOUNT_FIELD}
                  control={control}
                  rules={{
                    required: 'Amount Paid is required',
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      type="number"
                      label="Amount Paid"
                      fullWidth
                      required
                      error={!!errors[AMOUNT_FIELD]}
                      helperText={errors[AMOUNT_FIELD]?.message}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">$</InputAdornment>
                        ),
                      }}
                    />
                  )}
                />
              </Box>
              <Box>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <Controller
                    name={DATE_FIELD}
                    defaultValue=""
                    control={control}
                    rules={{
                      required: 'Date Paid is required',
                      validate: values =>
                        dayjs(values).isValid() || 'Invalid data format',
                    }}
                    render={({ field, fieldState }) => (
                      <DatePicker
                        {...field}
                        label="Date Paid"
                        inputFormat={DEFAULT_DATE_FORMAT}
                        renderInput={params => (
                          <TextField
                            {...params}
                            fullWidth
                            required
                            error={Boolean(
                              (fieldState.error && fieldState.isTouched) ||
                                !!errors[DATE_FIELD]
                            )}
                            helperText={fieldState.error?.message}
                          />
                        )}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Box>
            </Box>
            <Box mb={2}>
              <Controller
                name={COMMENT_FIELD}
                defaultValue=""
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    multiline
                    rows={4}
                    label="Comment"
                    fullWidth
                    error={!!errors[COMMENT_FIELD]}
                    helperText={errors[COMMENT_FIELD]?.message}
                  />
                )}
              />
            </Box>

            {fileList.length > 0 && (
              <Box>
                <Typography>
                  <strong>Files</strong>
                </Typography>
                {fileList.map(file => (
                  <Box
                    key={file.path}
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Typography>{file.name}</Typography>
                    <IconButton
                      type="button"
                      onClick={() => {
                        setFileList(fileList.filter(f => f.path !== file.path));
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Box>
                ))}
              </Box>
            )}

            <InputActions>
              <Button variant="outlined" onClick={openUpload}>
                Upload File
              </Button>
              <LoadingButton
                type="submit"
                variant="contained"
                loading={isMutateLoading}
                disabled={isMutateLoading}
              >
                Submit for Confirmation
              </LoadingButton>
            </InputActions>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default PaymentMarkAsPaid;
