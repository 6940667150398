import { InfoCard } from '../../../components/info-card';
import {
  Contract,
  DOLLAR,
  PERCENT,
  ResponseListBaseType,
  Task,
} from '../../../common/api';
import {
  Card,
  CardContent,
  CircularProgress,
  Grid,
  Stack,
  Theme,
  Typography,
} from '@mui/material';
import React from 'react';
import { grey500 } from '../../../styles/colours';
import { isContractor, isCustomer } from '../../../common/access';
import { useAuth } from '../../../common/auth-provider/AuthProvider';

interface Props {
  tasks: ResponseListBaseType<Array<Task>>;
  contract: Contract;
}
function TaskSummary(props: Props) {
  const { tasks, contract } = props;
  const { user } = useAuth();

  return (
    <Grid sx={styles.InfoGrid}>
      <InfoCard title={'Total Tasks'} value={tasks.data.length} />
      <InfoCard
        title={'Total Value'}
        value={tasks.meta.totalValue || 0}
        format={tasks.meta?.valueType === DOLLAR ? DOLLAR : PERCENT}
        isShow={
          user && (isCustomer(user, contract) || isContractor(user, contract))
        }
      />
      <InfoCard
        title={'Approved Variations'}
        value={tasks.meta.variationTotalValue || 0}
        format={tasks.meta?.valueType === DOLLAR ? DOLLAR : PERCENT}
        isShow={true}
      />
      <Card variant="outlined" sx={{ mr: 3 }}>
        <CardContent>
          <Typography noWrap variant="caption" sx={styles.TypographyLabel}>
            Complete
          </Typography>
          <Stack direction="row" alignItems={'center'} spacing={2}>
            {/* eslint-disable-next-line react/jsx-no-undef */}
            <CircularProgress
              sx={styles.CircularProgress}
              variant="determinate"
              value={Number(tasks.meta.complete || 0)}
            />
            <Typography variant={'h6'} sx={styles.Percent}>
              {Math.round(tasks.meta.complete || 0)} %
            </Typography>
          </Stack>
        </CardContent>
      </Card>
    </Grid>
  );
}

const styles = {
  InfoGrid: {
    display: 'grid',
    gridTemplateColumns: 'repeat( auto-fit, minmax(100px, 1fr) )',
    gap: '0px',
    marginBottom: '24px',
  },
  TypographyLabel: {
    color: grey500,
    letterSpacing: 1,
    marginBottom: '4px',
  },
  CircularProgress: {
    color: (theme: Theme) => theme.palette.primary.light,
  },
  Percent: {
    fontSize: '20px',
  },
};

export { TaskSummary };
