import React, { useMemo } from 'react';
import { TableBody, Box } from '@mui/material';
import { SortedTableHead } from '../sorted-table-head';
import {
  HeadCell,
  useSortedTable,
} from '../sorted-table-head/SortedTable.hooks';
import { ApprovalsTableRow } from './components/ApprovalsTableRow';
import { Pagination } from '../pagination';
import { StyledTable } from '../../styles';
import { stableSort } from '../../common/sort/stableSort';
import { getComparator } from '../../common/sort/getComparator';
import {
  Claim,
  ClaimStatus,
  Notification,
  NotificationStatus,
} from '../../common/api';
import { formatDate } from '../../common/format';
import { SkeletonTable } from '../skeleton-table';
import { useAuth } from '../../common/auth-provider/AuthProvider';
import dayjs from 'dayjs';

interface Props {
  approvals?: Array<Claim>;
  approvalsTotal: number;
  page: number;
  handleChangePage: (
    event: React.ChangeEvent<unknown>,
    newPage: number
  ) => void;
  isLoading: boolean;
  handleClick?: (id: string) => void;
  notifications?: Array<Notification>;
  handleCheckbox?: (notification: Notification) => Promise<void>;
}

function ClaimsReceivedTab(props: Props) {
  const {
    approvals,
    approvalsTotal,
    page,
    handleChangePage,
    isLoading,
    handleClick,
    notifications,
    handleCheckbox,
  } = props;
  const { user } = useAuth();
  const { order, orderBy, handleRequestSort } = useSortedTable('subContractor');

  const headCells: Array<HeadCell> = [
    {
      id: 'subContractor',
      numeric: false,
      disablePadding: false,
      label: 'Sub-Contract',
    },
    {
      id: 'statusOrder',
      numeric: false,
      disablePadding: false,
      label: 'Status',
    },
    {
      id: 'claimPeriod',
      numeric: false,
      disablePadding: false,
      label: 'Claim Period',
    },
    {
      id: 'submittedAt',
      numeric: false,
      disablePadding: false,
      label: 'Date Submitted',
    },
    {
      id: 'expiredAt',
      numeric: false,
      disablePadding: false,
      label: 'SOPA Expiration',
    },
    {
      id: 'dateResponded',
      numeric: false,
      disablePadding: false,
      label: 'Date Responded',
    },
    {
      id: 'claimValue',
      numeric: false,
      disablePadding: false,
      label: 'Claim Value',
    },
  ];

  const rows = useMemo(
    () =>
      approvals?.map(approval => {
        const claimNotifications = notifications?.filter(
          notification => notification?.claimId === approval.id
        );

        const notification = claimNotifications?.find(
          notification =>
            notification.claimId === approval.id &&
            notification.status === NotificationStatus.Active &&
            notification.accountId === user?.accountId
        );

        const notificationId = notification?.id;

        return {
          id: approval.id,
          ref: approval.id,
          subContractor: approval?.contract.contractorAccount.name || '-',
          caption: approval?.contract.description || '-',
          status: approval.status,
          statusOrder: Object.keys(ClaimStatus).indexOf(approval.status),
          dateSubmitted: formatDate(approval.submittedAt) || '',
          submittedAt: dayjs(approval.submittedAt).unix(),
          dateResponded: dayjs(approval.scheduledAt || 0).unix(),
          claimValue: approval.totalValue,
          sopaExpiration: approval?.expiration,
          expiredAt: dayjs(approval?.expiration || 0).unix(),
          contractId: approval.contractId,
          contractTotalValue: Number(approval.contract.value || 0),
          claimPeriod: dayjs(approval.periodStart).unix(),
          periodStart: approval.periodStart,
          periodEnd: approval.periodEnd,
          scheduledAt: approval.scheduledAt || '',
          taskValue: approval.claimItems[0]?.task?.value || '',
          valueType: approval.valueType,
          paymentDueAt: approval.paymentDueAt || '',
          notificationId: notificationId || '',
        };
      }) || [],
    [approvals, notifications, user?.accountId]
  );

  if (isLoading) {
    return <SkeletonTable />;
  }

  return (
    <Box>
      <StyledTable sx={{ mb: 3 }}>
        <SortedTableHead
          order={order}
          orderBy={orderBy}
          onRequestSort={handleRequestSort}
          headCells={headCells}
          data={rows}
        />
        <TableBody>
          {stableSort(rows, getComparator(order, orderBy)).map(row => {
            const notification = notifications?.find(
              el => el.id === row?.notificationId
            );
            return (
              <ApprovalsTableRow
                key={row.id}
                approve={row}
                notification={notification}
                handleCheckbox={() => {
                  if (notification && handleCheckbox && row.notificationId) {
                    handleCheckbox(notification);
                  }
                }}
                handleClick={() => {
                  if (handleClick && row.notificationId) {
                    handleClick(row.notificationId);
                  }
                }}
              />
            );
          })}
        </TableBody>
      </StyledTable>
      <Pagination
        count={0}
        handleChangePage={handleChangePage}
        page={page}
        total={approvalsTotal}
      />
    </Box>
  );
}

export { ClaimsReceivedTab };
