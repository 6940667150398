import React from 'react';
import { Box, Tooltip, Typography } from '@mui/material';
import { grey900 } from '../../../styles/colours';
import { formatCurrency } from '../../../common/format';

interface Props {
  taskValue: string | number;
  taskOriginalValue: string | number;
}

function ContractSummaryCardModifiedValue({
  taskValue,
  taskOriginalValue,
}: Props) {
  return (
    <Box sx={styles.Container}>
      <Typography pt="6px" sx={styles.TypographyContent}>
        {formatCurrency(Number(taskValue))}
      </Typography>
      <Tooltip arrow title="Original value" placement="bottom">
        <Typography sx={styles.TypographyContent}>
          ({formatCurrency(Number(taskOriginalValue))})
        </Typography>
      </Tooltip>
    </Box>
  );
}

const styles = {
  Container: {
    display: 'flex',
    flexDirection: 'column',
  },
  TypographyContent: {
    color: grey900,
    fontSize: '20px',
    fontWeight: 500,
  },
};

export { ContractSummaryCardModifiedValue };
