import React from 'react';
import { Box, Dialog, DialogContent } from '@mui/material';

import { TileColour } from './ReportTile.style';
import { REPORT_METRIC_COLOURS } from './Report.constants';

type Props = {
  selectedColour: string;
  onClose: () => void;
  onSelect: (colour: string) => void;
};

function ColourPicker({ selectedColour, onClose, onSelect }: Props) {
  return (
    <Dialog open onClose={onClose}>
      <DialogContent>
        <Box display="flex" gap={1}>
          {REPORT_METRIC_COLOURS.map(colour => {
            return (
              <TileColour
                type="button"
                key={colour}
                $isActive={colour === selectedColour}
                style={{
                  backgroundColor: colour,
                }}
                onClick={() => {
                  onSelect(colour);
                  onClose();
                }}
              />
            );
          })}
        </Box>
      </DialogContent>
    </Dialog>
  );
}

export default ColourPicker;
