import React, { useMemo } from 'react';
import { generatePath } from 'react-router-dom';
import { TableBody, TableCell, TableRow } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { useAuth } from '../../../common/auth-provider/AuthProvider';
import { SkeletonTable } from '../../../components/skeleton-table';
import { LinkTo, StyledTable } from '../../../styles';
import { listPaidIncome } from '../../../common/api/payments';
import { formatCurrency } from '../../../common/format';
import { useListPagination } from '../../../common/hooks/useListPagination';
import { Pagination } from '../../../components/pagination';
import { SortedTableHead } from '../../../components/sorted-table-head';
import { getPaymentRows, headCellsIncome } from './PaymentTableHeader';
import { useSortedTable } from '../../../components/sorted-table-head/SortedTable.hooks';
import { stableSort } from '../../../common/sort/stableSort';
import { getComparator } from '../../../common/sort/getComparator';
import { useProject } from '../../../common/hooks/useProject';

function PaymentsReceivedTab() {
  const { user } = useAuth();
  const accountId = user?.accountId;
  const { project } = useProject();

  const { page, rowsPerPage, skip, handleChangePage } = useListPagination();
  const { order, orderBy, handleRequestSort } = useSortedTable('projectName');

  const { data, isLoading } = useQuery(
    ['payments-income', project?.systemDate, accountId, rowsPerPage],
    () =>
      listPaidIncome(
        { accountId: accountId! },
        {
          skip: skip,
          take: rowsPerPage,
        }
      )
  );

  const payments = useMemo(
    () => getPaymentRows(data?.data || []),
    [data?.data]
  );

  return (
    <>
      {isLoading ? (
        <SkeletonTable />
      ) : (
        <>
          <StyledTable sx={{ mb: 2 }}>
            <SortedTableHead
              order={order}
              orderBy={orderBy}
              headCells={headCellsIncome}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {stableSort(payments, getComparator(order, orderBy)).map(
                payment => (
                  <TableRow key={payment.id}>
                    <TableCell>{payment.projectName}</TableCell>
                    <TableCell>{payment.payerName}</TableCell>
                    <TableCell>{payment.description}</TableCell>
                    <TableCell>
                      <LinkTo
                        to={generatePath(
                          '/contracts/:contractId/claims/:claimId',
                          {
                            contractId: payment.contractId,
                            claimId: payment.claimId,
                          }
                        )}
                      >
                        {payment.approvedAt}
                      </LinkTo>
                    </TableCell>
                    <TableCell>{payment.dueAt}</TableCell>
                    <TableCell>{payment.paidAt}</TableCell>
                    <TableCell>
                      <LinkTo
                        to={generatePath(
                          '/contracts/:contractId/claims/:claimId',
                          {
                            contractId: payment.contractId,
                            claimId: payment.claimId,
                          }
                        )}
                      >
                        {formatCurrency(payment.amount)}
                      </LinkTo>
                    </TableCell>
                  </TableRow>
                )
              )}
            </TableBody>
          </StyledTable>
          <Pagination
            count={Math.ceil((data?.meta.total || 0) / rowsPerPage)}
            handleChangePage={handleChangePage}
            page={page}
            total={data?.meta.total || 0}
          />
        </>
      )}
    </>
  );
}

export { PaymentsReceivedTab };
