import { Table } from '@mui/material';
import { styled } from '@mui/material/styles';
import { grey200, grey600 } from './colours';

export const StyledTable = styled(Table)({
  borderTop: `1px solid ${grey200}`,
  borderCollapse: 'collapse',
  borderSpacing: '1px',
  td: {
    padding: '12px',
  },
  th: {
    fontSize: '12px',
    fontWeight: 'normal',
    color: grey600,
    outline: 'none !important',
  },
});

export const StyledCompactTable = styled(StyledTable)({
  td: {
    padding: '8px',
    ':nth-of-type(1)': {
      padding: '8px 0 8px 8x',
    },
  },
});

export const StyledCompactTableWithIdenticalRows = styled(StyledCompactTable)({
  th: {
    width: '20%',
  },
  td: {
    width: '20%',
  },
});
