import React, {
  createContext,
  ReactNode,
  useState,
  useMemo,
  useContext,
} from 'react';
import { Account } from '../../../common/api';

type Props = {
  step: number;
  setStep: (value: number) => void;
  selectedCompany?: Account;
  setSelectedCompany: (company: Account | undefined) => void;
  selectedCompanyId: string;
  setSelectedCompanyId: (id: string) => void;
  isEmailError: boolean;
  setIsEmailError: (value: boolean) => void;
};

export const AddContractContext = createContext<Props>({
  step: 0,
  setStep: () => {},
  selectedCompany: undefined,
  setSelectedCompany: () => {},
  selectedCompanyId: '1',
  setSelectedCompanyId: () => {},
  isEmailError: false,
  setIsEmailError: () => {},
});

export const AddContractContextProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [step, setStep] = useState<number>(0);
  const [selectedCompanyId, setSelectedCompanyId] = useState<string>('');
  const [selectedCompany, setSelectedCompany] = useState<Account>();
  const [isEmailError, setIsEmailError] = useState<boolean>(false);

  const value = useMemo(
    () => ({
      step,
      setStep,
      selectedCompany,
      setSelectedCompany,
      selectedCompanyId,
      setSelectedCompanyId,
      isEmailError,
      setIsEmailError,
    }),
    [
      step,
      setStep,
      selectedCompany,
      setSelectedCompany,
      selectedCompanyId,
      setSelectedCompanyId,
      isEmailError,
      setIsEmailError,
    ]
  );

  return (
    <AddContractContext.Provider value={value}>
      {children}
    </AddContractContext.Provider>
  );
};

export const useAddContract = () => useContext(AddContractContext);
