import React from 'react';
import { Box, Tooltip, Typography } from '@mui/material';
import { formatCurrency } from '../../../../../common/format';

interface Props {
  taskValue: number;
  taskOriginalValue: string;
}

function TaskClaimItemRowModifiedValue({
  taskValue,
  taskOriginalValue,
}: Props) {
  return (
    <Box sx={styles.Container}>
      <div>{formatCurrency(taskValue)}</div>
      <div>
        <Tooltip arrow title="Original value" placement="bottom-end">
          <Typography variant="caption">
            ({formatCurrency(Number(taskOriginalValue))})
          </Typography>
        </Tooltip>
      </div>
    </Box>
  );
}

const styles = {
  Container: {
    display: 'flex',
    flexDirection: 'column',
  },
};

export { TaskClaimItemRowModifiedValue };
