import React, { useCallback } from 'react';
import { useMutation } from '@tanstack/react-query';
import { Box, Button, Dialog, DialogContent, Typography } from '@mui/material';

import { useSnackbar } from '../../common/hooks/useSnackbar';
import { DialogClose, Heading } from '../../styles';
import { grey800 } from '../../styles/colours';
import {
  createReportItem,
  createReportItemPayload,
} from '../../common/api/reports';
import { Tile, TileButton, TileImage } from './AddReportTile.styles';
import { getReportTileStyles } from './Report.utils';
import {
  ReportItemLayout,
  ReportItemStyle,
} from '../../common/api/report-types';

type Props = {
  reportId: string;
  reportLayout: ReportItemLayout[];
  projectId?: string;
  isMainDashboard: boolean;
  reload: () => void;
  close: () => void;
};

function AddReportTile({
  reportId,
  projectId,
  reportLayout,
  isMainDashboard,
  close,
  reload,
}: Props) {
  const { showAlert, SnackbarComponent } = useSnackbar();

  const handleCloseDialog = useCallback(() => {
    close();
  }, [close]);

  const { mutateAsync: mutateAdd } = useMutation(
    (payload: createReportItemPayload) => createReportItem(payload),
    {
      onSuccess: async () => {
        reload();
        handleCloseDialog();
      },
      onError: () =>
        showAlert(
          'There was an error adding the tile. Please try again later.',
          'error'
        ),
    }
  );

  function findItemWithHighestY(metricLayout: ReportItemLayout[]) {
    return metricLayout.reduce((maxItem, currentItem) => {
      return currentItem.y > maxItem.y ? currentItem : maxItem;
    }, metricLayout[0]).y;
  }

  const onAddTile = useCallback(
    (style: ReportItemStyle | undefined) => {
      const highestY =
        reportLayout.length === 0 ? -1 : findItemWithHighestY(reportLayout);

      const richtext =
        style === ReportItemStyle.HeadlineTextBox
          ? {
              type: 'doc',
              content: [
                {
                  type: 'heading',
                  attrs: {
                    level: 1,
                    textAlign: 'left',
                  },
                  content: [
                    {
                      text: 'Project Portfolio',
                      type: 'text',
                    },
                  ],
                },
              ],
            }
          : style === ReportItemStyle.CustomTextBox
          ? {
              type: 'doc',
              content: [
                {
                  type: 'heading',
                  attrs: {
                    level: 1,
                    textAlign: 'left',
                  },
                  content: [
                    {
                      text: 'Tile title',
                      type: 'text',
                    },
                  ],
                },
                {
                  type: 'paragraph',
                  attrs: {
                    textAlign: 'left',
                  },
                  content: [
                    {
                      text: 'Enter text here',
                      type: 'text',
                    },
                  ],
                },
              ],
            }
          : null;

      mutateAdd({
        reportId,
        style: style || ReportItemStyle.Basic,
        richtext,
        projectId,
        reportLayout: {
          x: 0,
          y: highestY + 1,
          w:
            style === ReportItemStyle.ProjectList ||
            style === ReportItemStyle.ContractList
              ? 12
              : 3,
          h: 5,
        },
      });
    },
    [reportId, reportLayout, projectId, mutateAdd]
  );

  return (
    <>
      <SnackbarComponent />
      <Dialog open fullWidth maxWidth="md" onClose={handleCloseDialog}>
        <DialogContent>
          <Heading>Add Tiles</Heading>
          <DialogClose onClose={handleCloseDialog} />

          <Box display="flex" flexWrap="wrap">
            {getReportTileStyles(projectId, isMainDashboard).map(tile => {
              return (
                <Box key={tile.style} flexBasis="33%" padding={1}>
                  <Tile>
                    <TileImage
                      style={{
                        backgroundImage: `url(${tile.image})`,
                      }}
                    />
                    <Box padding={1}>
                      <Typography variant="body1">{tile.label}</Typography>
                      <Typography variant="body2" color={grey800}>
                        {tile.description}
                      </Typography>
                    </Box>
                    <TileButton>
                      <Button
                        type="button"
                        variant="outlined"
                        style={{ backgroundColor: '#fff' }}
                        onClick={() => onAddTile(tile.style)}
                      >
                        + Add Tile
                      </Button>
                    </TileButton>
                  </Tile>
                </Box>
              );
            })}
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default AddReportTile;
