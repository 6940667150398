import React from 'react';
import { AlertColor, Box, Typography } from '@mui/material';
import { createPortal } from 'react-dom';
import { useMutation } from '@tanstack/react-query';

import { KebabMenu } from '../../../components/menu/KebabMenu';
import { useDialogState } from '../../../components/dialog/dialog.hooks';
import { useConfirmDialog } from '../../../common/hooks/useConfirmDialog';
import {
  deleteReportItem,
  deleteReportItemPayload,
} from '../../../common/api/reports';
import { ReportCellKebab } from '../ReportCell.style';
import { Tile } from '../BasicTileItem.styles';
import EditPictureTile from './EditPictureTile';
import { ReportItem } from '../../../common/api/report-types';

import placeholder from '../images/picture.jpg';

type Props = {
  reportItem: ReportItem;
  isEditingDashboard: boolean;
  reload: () => void;
  showAlert: (title: string, severity: AlertColor) => void;
};

const PictureTile = ({
  reportItem,
  isEditingDashboard,
  reload,
  showAlert,
}: Props) => {
  const {
    isVisible: isEditVisible,
    open: openEdit,
    close: closeEdit,
  } = useDialogState();
  const { confirmAction, ConfirmDialog } = useConfirmDialog();

  const { mutateAsync: mutatedeleteReportItem } = useMutation(
    (payload: deleteReportItemPayload) => deleteReportItem(payload),
    {
      onSuccess: () => {
        reload();
        showAlert('Tile removed!', 'success');
      },
      onError: () =>
        showAlert(
          'There was an error deleting the tile. Please try again later.',
          'error'
        ),
    }
  );

  const el = document.getElementById('report-actions');

  const id = reportItem.id;
  const reportId = reportItem.reportId;

  return (
    <Tile>
      {el &&
        createPortal(
          <>
            {isEditVisible && (
              <EditPictureTile
                id={id}
                reportId={reportId}
                close={closeEdit}
                reload={reload}
                showAlert={showAlert}
              />
            )}
          </>,
          el
        )}

      <ConfirmDialog />
      {!isEditingDashboard && (
        <ReportCellKebab>
          <KebabMenu
            options={['Tile Options', 'Remove Tile']}
            onOptionSelect={async option => {
              if (option === 'Remove Tile') {
                await confirmAction(
                  'Are you sure you want to remove this tile?'
                );
                await mutatedeleteReportItem({
                  reportId,
                  id,
                });
              } else if (option === 'Tile Options') {
                openEdit();
              }
            }}
          />
        </ReportCellKebab>
      )}

      {reportItem.imageUrl ? (
        <img src={reportItem.imageUrl} width="100%" alt="" />
      ) : (
        <Box>
          <img src={placeholder} width="100%" alt="" />
          <Box paddingLeft={1}>
            <Typography variant="body2">Upload an image</Typography>
          </Box>
        </Box>
      )}
    </Tile>
  );
};

export default PictureTile;
