import { FilesItem } from '../../../components/FilesItem';
import { Divider } from '@mui/material';
import React, { useMemo } from 'react';
import { ContractFile } from '../../../../../common/api';
import { useCheckbox } from '../../../hooks/useCheckbox';
import { LoadingButton } from '@mui/lab';

interface Props {
  contractFiles: Array<ContractFile>;
  handleCreateFile?: (selectedFiles: Array<ContractFile>) => void;
  isLoadingCreateFile: boolean;
  setSelectedFiles?: (file: Array<ContractFile>) => void;
}

function FileDrawerComplianceFilesList({
  contractFiles,
  handleCreateFile,
  isLoadingCreateFile,
  setSelectedFiles,
}: Props) {
  const { isItemSelected, handleCheckboxClick, selectAll } =
    useCheckbox(contractFiles);
  const selectedFiles = useMemo(
    () => contractFiles.filter(file => selectAll.includes(file.id)),
    [contractFiles, selectAll]
  );

  const handler = handleCreateFile
    ? () => handleCreateFile(selectedFiles)
    : setSelectedFiles
    ? () => setSelectedFiles(selectedFiles)
    : () => {};

  return (
    <>
      {contractFiles.map(contractFile => (
        <FilesItem
          key={contractFile.id}
          file={{
            id: contractFile.id,
            name: contractFile.name,
            createdAt: contractFile.createdAt,
            uploaderEmail: contractFile.uploader.email,
            documentName: contractFile.documentName,
            documentType: contractFile.documentType,
            documentExpiry: contractFile.documentExpiry,
          }}
          withCheckbox={true}
          isChecked={isItemSelected(contractFile.id)}
          handleCheckboxClick={() => {
            handleCheckboxClick(contractFile.id);
          }}
          editMode={false}
        />
      ))}
      <LoadingButton
        variant="outlined"
        loading={isLoadingCreateFile}
        disabled={selectAll.length === 0}
        onClick={handler}
      >
        Attach Files
      </LoadingButton>
      <Divider />
    </>
  );
}

export { FileDrawerComplianceFilesList };
