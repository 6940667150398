import styled from '@emotion/styled';
import { grey200, grey500, white } from '../../../styles/colours';

type TreeDraggableProps = {
  canDrop: boolean;
  isOver: boolean;
  isDragging: boolean;
};
export const TreeDraggable = styled('div')<TreeDraggableProps>`
  background-color: ${props =>
    props.canDrop && props.isOver ? grey200 : white};
  display: inline-flex;
  opacity: ${props => (props.isDragging ? '0.75' : '1')};
  position: relative;
  width: 100%;
  transform: translate(0, 0);
`;

export const TreeDraggableHandle = styled('div')`
  color: ${grey500};
  display: flex;
  flex-shrink: 0;
  align-items: baseline;
  justify-content: center;
  padding-top: 12px;
  width: 32px;
  position: absolute;
  left: 0;
  z-index: 10;
  &:hover {
    cursor: move;
  }
`;

export const TreeDraggableContent = styled('div')`
  width: 100%;
`;

export const TreeRow = styled('div')``;
