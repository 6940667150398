import {
  formatDaysTillDate,
  isSameOrAfterDate,
  isBeforeDate,
  isSameOrBeforeDate,
  projectDate,
} from '../../../../common/format/formatDate';
import {
  Claim,
  ClaimStatus,
  ContractFile,
  Notification,
  NotificationColor,
  NotificationType,
  VariationListResource,
  VariationStatus,
} from '../../../../common/api';
import dayjs from 'dayjs';

function getClaimsMadeGreenActions(
  claims?: Array<Claim>,
  userAccountId?: string,
  systemDate?: string,
  notifications?: Array<Notification>
) {
  return claims?.filter(claim => {
    const sopaDate = formatDaysTillDate(
      claim.expiration,
      projectDate(systemDate)
    );

    const claimNotifications = notifications?.filter(
      notification => notification?.claimId === claim?.id
    );

    const isPaidClaimUnread = claimNotifications?.some(
      ({ message, color, isRead, accountId }) => {
        return (
          message === 'Claim has been paid' &&
          color === NotificationColor.Green &&
          !isRead &&
          userAccountId === accountId
        );
      }
    );
    const submittedClaim =
      claim.status === ClaimStatus.Submitted && sopaDate <= 0;

    const awaitingClaim = claim.status === ClaimStatus.Awaiting;
    const paidClaimUnread =
      claim.status === ClaimStatus.Paid && isPaidClaimUnread;

    return submittedClaim || awaitingClaim || paidClaimUnread;
  }).length;
}

function getClaimsMadeRedActions(
  claims?: Array<Claim>,
  userAccountId?: string,
  systemDate?: string,
  notifications?: Array<Notification>
) {
  return claims?.filter(claim => {
    const sopaDate = formatDaysTillDate(
      claim.expiration,
      projectDate(systemDate)
    );

    const claimNotifications = notifications?.filter(
      notification => notification?.claimId === claim?.id
    );

    const isWithdrawnClaimUnread = claimNotifications?.some(
      ({ message, color, isRead, accountId }) => {
        return (
          message === 'Claim has been withdrawn.' &&
          color === NotificationColor.Yellow &&
          !isRead &&
          userAccountId === accountId
        );
      }
    );

    const isApprovedClaimOverdue =
      dayjs(claim.paymentDueAt) < projectDate(systemDate);

    const submitOverdue =
      claim.status === ClaimStatus.Submitted && sopaDate > 0;

    const withdrawnUnreadClaim =
      claim.status === ClaimStatus.Withdrawn && isWithdrawnClaimUnread;

    const approvedClaimOverdue =
      claim.status === ClaimStatus.Approved && isApprovedClaimOverdue;

    return submitOverdue || withdrawnUnreadClaim || approvedClaimOverdue;
  }).length;
}

function getClaimReceivedGreenActions(
  claims?: Array<Claim>,
  systemDate?: string,
  userAccountId?: string,
  notifications?: Array<Notification>
) {
  return claims?.filter(claim => {
    const sopaDate = formatDaysTillDate(
      claim.expiration,
      projectDate(systemDate)
    );

    const claimNotifications = notifications?.filter(
      notification => notification?.claimId === claim?.id
    );

    const isAcceptedClaimUnread = claimNotifications?.some(
      ({ message, color, isRead, accountId }) => {
        return (
          message === 'Claim has been approved and ready for payment' &&
          color === NotificationColor.Green &&
          !isRead &&
          userAccountId === accountId
        );
      }
    );

    const isPaidClaimUnread = claimNotifications?.some(
      ({ type, color, isRead, accountId }) => {
        return (
          type === NotificationType.Payment &&
          color === NotificationColor.Green &&
          !isRead &&
          userAccountId === accountId
        );
      }
    );

    const submittedClaim =
      claim.status === ClaimStatus.Submitted && sopaDate <= 0;

    const acceptedClaimUnread =
      claim.status === ClaimStatus.Approved && isAcceptedClaimUnread;

    const paidClaimUnread =
      claim.status === ClaimStatus.Paid && isPaidClaimUnread;

    return submittedClaim || acceptedClaimUnread || paidClaimUnread;
  }).length;
}

function getClaimReceivedRedActions(
  claims?: Array<Claim>,
  systemDate?: string,
  userAccountId?: string,
  notifications?: Array<Notification>
) {
  return claims?.filter(claim => {
    const sopaDate = formatDaysTillDate(
      claim.expiration,
      projectDate(systemDate)
    );

    const claimNotifications = notifications?.filter(
      notification => notification?.claimId === claim?.id
    );

    const isApprovedClaimOverdue =
      dayjs(claim.paymentDueAt) < projectDate(systemDate);

    const isWithdrawnClaimUnread = claimNotifications?.some(
      ({ message, color, isRead, accountId }) => {
        return (
          message === 'Claim has been withdrawn.' &&
          color === NotificationColor.Yellow &&
          !isRead &&
          userAccountId === accountId
        );
      }
    );

    const isRejectedClaimUnread = claimNotifications?.some(
      ({ message, color, isRead, accountId }) => {
        return (
          message === 'Claim has been rejected.' &&
          color === NotificationColor.Red &&
          !isRead &&
          userAccountId === accountId
        );
      }
    );

    const withdrawnUnreadClaim =
      claim.status === ClaimStatus.Withdrawn && isWithdrawnClaimUnread;

    const submitOverdue = claim.status !== ClaimStatus.Draft && sopaDate > 0;

    const rejectedClaimUnread =
      claim.status === ClaimStatus.Rejected && isRejectedClaimUnread;

    const approvedClaimOverdue =
      claim.status === ClaimStatus.Approved && isApprovedClaimOverdue;

    return (
      withdrawnUnreadClaim ||
      submitOverdue ||
      rejectedClaimUnread ||
      approvedClaimOverdue
    );
  }).length;
}

function getVariationsMadeGreenActions(
  variations?: Array<VariationListResource>,
  userAccountId?: string,
  notifications?: Array<Notification>
) {
  return variations?.filter(variation => {
    const variationNotifications = notifications?.filter(
      notification => notification?.variationId === variation?.id
    );

    const isWithdrawnVariationUnread = variationNotifications?.some(
      ({ type, color, isRead, accountId }) => {
        return (
          type === NotificationType.Variation &&
          color === NotificationColor.Yellow &&
          !isRead &&
          userAccountId === accountId
        );
      }
    );

    const isApprovedVariationUnread = variationNotifications?.some(
      ({ type, color, isRead, accountId }) => {
        return (
          type === NotificationType.Variation &&
          color === NotificationColor.Green &&
          !isRead &&
          userAccountId === accountId
        );
      }
    );

    const submittedVariation = variation.status === VariationStatus.Submitted;
    const withdrawnVariationUnread =
      variation.status === VariationStatus.Withdrawn &&
      isWithdrawnVariationUnread;
    const approvedVariationUnread =
      variation.status === VariationStatus.Approved &&
      isApprovedVariationUnread;

    return (
      submittedVariation || withdrawnVariationUnread || approvedVariationUnread
    );
  }).length;
}

function getVariationsMadeRedActions(
  variations?: Array<VariationListResource>,
  userAccountId?: string,
  notifications?: Array<Notification>
) {
  return variations?.filter(variation => {
    const variationNotifications = notifications?.filter(
      notification => notification?.variationId === variation?.id
    );

    const isRejectedVariationUnread = variationNotifications?.some(
      ({ type, color, isRead, accountId }) => {
        return (
          type === NotificationType.Variation &&
          color === NotificationColor.Red &&
          !isRead &&
          userAccountId === accountId
        );
      }
    );

    return (
      variation.status === VariationStatus.Rejected && isRejectedVariationUnread
    );
  }).length;
}

function getVariationReceivedGreenActions(
  variations?: Array<VariationListResource>,
  userAccountId?: string,
  notifications?: Array<Notification>
) {
  return variations?.filter(variation => {
    const variationNotifications = notifications?.filter(
      notification => notification?.variationId === variation?.id
    );

    const submittedVariation = variation.status === VariationStatus.Submitted;

    const isApprovedVariationUnread = variationNotifications?.some(
      ({ type, color, isRead, accountId }) => {
        return (
          type === NotificationType.Variation &&
          color === NotificationColor.Green &&
          !isRead &&
          userAccountId === accountId
        );
      }
    );

    return submittedVariation || isApprovedVariationUnread;
  }).length;
}

function getVariationReceivedRedActions(
  variations?: Array<VariationListResource>,
  userAccountId?: string,
  notifications?: Array<Notification>
) {
  return variations?.filter(variation => {
    const variationNotifications = notifications?.filter(
      notification => notification?.variationId === variation?.id
    );

    const isWithdrawnVariationUnread = variationNotifications?.some(
      ({ type, color, isRead, accountId }) => {
        return (
          type === NotificationType.Variation &&
          color === NotificationColor.Yellow &&
          !isRead &&
          userAccountId === accountId
        );
      }
    );

    const isRejectedVariationUnread = variationNotifications?.some(
      ({ type, color, isRead, accountId }) => {
        return (
          type === NotificationType.Variation &&
          color === NotificationColor.Red &&
          !isRead &&
          userAccountId === accountId
        );
      }
    );

    const withdrawnVariationUnread =
      variation.status === VariationStatus.Withdrawn &&
      isWithdrawnVariationUnread;

    const rejectedVariationUnread =
      variation.status === VariationStatus.Rejected &&
      isRejectedVariationUnread;

    return withdrawnVariationUnread || rejectedVariationUnread;
  }).length;
}

function getComplianceGreenActions(
  files?: Array<ContractFile>,
  systemDate?: string
) {
  return files?.filter(file => {
    return (
      !!file?.documentExpiry &&
      isSameOrBeforeDate(
        file.documentExpiry,
        1,
        'month',
        projectDate(systemDate)
      ) &&
      isSameOrAfterDate(file.documentExpiry, projectDate(systemDate))
    );
  }).length;
}

function getComplianceRedActions(
  files?: Array<ContractFile>,
  systemDate?: string
) {
  return files?.filter(
    file =>
      !!file?.documentExpiry &&
      isBeforeDate(file.documentExpiry, projectDate(systemDate))
  ).length;
}

export {
  getClaimsMadeGreenActions,
  getClaimsMadeRedActions,
  getClaimReceivedGreenActions,
  getClaimReceivedRedActions,
  getVariationsMadeGreenActions,
  getVariationsMadeRedActions,
  getVariationReceivedGreenActions,
  getVariationReceivedRedActions,
  getComplianceGreenActions,
  getComplianceRedActions,
};
