import { HeadCell } from '../../../../components/sorted-table-head/SortedTable.hooks';

export const anticipatedHeadCells: Array<HeadCell> = [
  {
    id: 'variationIdentifier',
    numeric: false,
    disablePadding: false,
    label: 'AV#',
  },
  {
    id: 'subContractor',
    numeric: false,
    disablePadding: false,
    label: 'Sub-Contract',
  },
  {
    id: 'description',
    numeric: false,
    disablePadding: false,
    label: 'Variation Name',
  },
  {
    id: 'dateCreated',
    numeric: false,
    disablePadding: false,
    label: 'Date Captured',
  },
  {
    id: 'submittedStatus',
    numeric: false,
    disablePadding: false,
    label: 'Currently Submitted / Finalised',
  },
  {
    id: 'prevSubmittedStatus',
    numeric: false,
    disablePadding: false,
    label: 'Previously Submitted',
  },
  {
    id: 'estimatedValue',
    numeric: true,
    disablePadding: false,
    label: 'Estimated Variation Value',
  },
  {
    id: 'totalContingency',
    numeric: true,
    disablePadding: false,
    label: '% of Total Contingency',
  },
  {
    id: 'svRef',
    numeric: false,
    disablePadding: false,
    label: 'Submitted Variation Ref',
  },
];

export const submittedHeadCells: Array<HeadCell> = [
  {
    id: 'subContractor',
    numeric: false,
    disablePadding: false,
    label: 'Sub-Contract',
  },
  {
    id: 'variationIdentifier',
    numeric: false,
    disablePadding: false,
    label: 'SV#',
  },
  {
    id: 'description',
    numeric: false,
    disablePadding: false,
    label: 'Variation Name',
  },
  {
    id: 'dateSubmitted',
    numeric: false,
    disablePadding: false,
    label: 'Date Submitted',
  },
  {
    id: 'respondedAt',
    numeric: false,
    disablePadding: false,
    label: 'Date Responded',
  },
  {
    id: 'statusOrder',
    numeric: true,
    disablePadding: false,
    label: 'Approval Status',
  },
  {
    id: 'totalValue',
    numeric: true,
    disablePadding: false,
    label: 'Claimed Variation Value',
  },
  {
    id: 'totalContingency',
    numeric: true,
    disablePadding: false,
    label: '% of Total Contingency',
  },
  {
    id: 'approvedValue',
    numeric: true,
    disablePadding: false,
    label: 'Approved Variation Value',
  },
  {
    id: 'varianceValue',
    numeric: true,
    disablePadding: false,
    label: 'Variance Value',
  },
  {
    id: 'avRef',
    numeric: true,
    disablePadding: false,
    label: 'Anticipated Variation Ref',
  },
];
