import React, { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useAuth } from '../../common/auth-provider/AuthProvider';
import { useQuery } from '@tanstack/react-query';
import {
  Contract,
  getContract,
  listTasks,
  ResponseListBaseType,
  summaryTask,
  SummaryTaskData,
  Task,
} from '../../common/api';
import { useContractsBreadcrumb } from './hooks/useContractsBreadcrumb';
import { Typography } from '@mui/material';
import {
  canApproveOrRejectClaimOnNode,
  canClaimNode,
} from '../../common/access';
import { useListPagination } from '../../common/hooks/useListPagination';
import { ContractRevisionView } from './views/contract-revision-view/ContractRevisionView';

type Params = {
  contractId: string;
  subcontractId: string;
};

export const ContractNegotiationPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { contractId, subcontractId } = useParams() as Params;
  const { user } = useAuth();
  const { page, rowsPerPage, handleChangePage, handleChangeRowsPerPage, skip } =
    useListPagination();

  const [selectedTaskGroup, setSelectedTaskGroup] = useState<string | null>(
    null
  );

  if (!user) {
    throw new Error('User is required.');
  }

  const { data: tasks } = useQuery<ResponseListBaseType<Array<Task>>>(
    ['tasks', '?parentTaskId', subcontractId ?? contractId, rowsPerPage, skip],
    () => {
      return listTasks(subcontractId ?? contractId, {
        taskGroupId: selectedTaskGroup === '0' ? null : selectedTaskGroup,
        skip: skip,
        take: rowsPerPage,
      });
    }
  );

  const { data: summaryTaskData } = useQuery<SummaryTaskData>(
    ['summaryTask', subcontractId ?? contractId],
    () => {
      return summaryTask(subcontractId ?? contractId);
    }
  );

  const { data: contract, refetch: refetchContract } = useQuery<Contract>(
    ['contracts', subcontractId ?? contractId],
    () => {
      return getContract(subcontractId ?? contractId);
    }
  );

  const handleSelectedTaskGroup = useCallback((nodeId: string) => {
    setSelectedTaskGroup(nodeId);
  }, []);

  const reloadContract = useCallback(async () => {
    await (async () => {
      await refetchContract();
    })();
  }, [refetchContract]);

  useContractsBreadcrumb({ contract, user });

  useEffect(() => {
    if (!location.state?.isEditable) {
      navigate(`/contracts/${contractId}`);
    }
  }, [contractId, location, navigate]);

  if (!contract) {
    return <Typography>Contract Not Found</Typography>;
  }

  const isCustomer = canApproveOrRejectClaimOnNode(user, contract);
  const isContractor = canClaimNode(user, contract);

  const renderView = () => {
    if (isCustomer || isContractor) {
      return (
        <ContractRevisionView
          contract={contract}
          isCustomer={isCustomer}
          tasks={tasks}
          summary={summaryTaskData}
          page={page}
          rowsPerPage={rowsPerPage}
          reloadContract={reloadContract}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          handleSelectedTaskGroup={handleSelectedTaskGroup}
          selectedTaskGroup={selectedTaskGroup}
        />
      );
    } else {
      return <div>Only client or contractor can edit contracts</div>;
    }
  };

  return renderView();
};
