import { Contract, Project, User } from '../api';

function isUserPartOfProject(
  user: User | undefined,
  project: Project | undefined
): boolean {
  if (!user || !project) return false;
  return project.accountId === user.accountId;
}

function isProjectOwner(user: User, contract: Contract): boolean {
  return contract.project.accountId === user.accountId;
}

function isCustomer(user?: User, contract?: Contract): boolean {
  return contract?.customerAccountId === user?.accountId;
}

function isContractor(user?: User, contract?: Contract): boolean {
  return contract?.contractorAccountId === user?.accountId;
}

function canApproveOrRejectClaimOnNode(
  user: User,
  contract: Contract
): boolean {
  return (
    (isProjectOwner(user, contract) && isCustomer(user, contract)) ||
    isCustomer(user, contract)
  );
}

function canClaimNode(user: User, contract: Contract): boolean {
  return isContractor(user, contract);
}

function canEditNode(user: User, contract: Contract): boolean {
  return isCustomer(user, contract) || isContractor(user, contract);
}

export {
  canClaimNode,
  canEditNode,
  canApproveOrRejectClaimOnNode,
  isContractor,
  isProjectOwner,
  isCustomer,
  isUserPartOfProject,
};
