import React, { SyntheticEvent, useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Alert, Badge, Box, Button, Tab, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { Heading } from '../../styles/text';
import { StyledTabs } from '../../styles';
import { PaymentsDueTab } from './components/PaymentsDueTab';
import { PaymentsMadeTab } from './components/PaymentsMadeTab';
import { PaymentsIncomeTab } from './components/PaymentIncomeTab';
import { useAuth } from '../../common/auth-provider/AuthProvider';
import { getBankAccountsByAccountId } from '../../common/api/bank';
import { PaymentsReceivedTab } from './components/PaymentsReceivedTab';
import { listIncome } from '../../common/api/payments';
import { ManualPaymentStatus } from '../../common/api';

export enum Tabs {
  unpaid,
  paid,
  income,
  income_paid,
}

function TransfersPage() {
  const { user } = useAuth();
  const accountId = user?.accountId;
  const navigate = useNavigate();

  const [tabsValue, setTabsValue] = useState<Tabs>(Tabs.unpaid);

  const { data: incomeData, refetch: refectPaymentsIncome } = useQuery(
    ['payments-income', accountId],
    () => listIncome({ accountId: accountId! })
  );

  const handleChangeTabsValue = useCallback(
    (event: SyntheticEvent<Element, Event>, value: Tabs) => {
      setTabsValue(value);
    },
    []
  );

  const { data: bankAccountData, isLoading: isLoadingBankAccounts } = useQuery(
    ['bank', user?.accountId],
    () => getBankAccountsByAccountId(user?.accountId!)
  );

  const hasBankAccount = bankAccountData && bankAccountData?.length > 0;

  const manualPaymentSubmittedCount = useMemo(() => {
    let cnt = 0;
    incomeData?.data.forEach(data => {
      data.manualPayments.forEach(mp => {
        if (mp.status === ManualPaymentStatus.Submitted) {
          cnt++;
        }
      });
    });
    return cnt;
  }, [incomeData]);

  return (
    <>
      <Heading>Financials</Heading>
      {!isLoadingBankAccounts && !hasBankAccount && (
        <Alert severity="success" sx={{ mb: 2 }} icon={false}>
          <Typography>
            Setup your bank account to enable transfers. Once completed, you can
            enjoy the convenience of easy and reliable transations.
          </Typography>
          <Typography>
            <Button
              variant="contained"
              sx={{ mt: 2 }}
              onClick={() => navigate('/banking')}
            >
              Set up Bank Account
            </Button>
          </Typography>
        </Alert>
      )}
      <Box>
        <StyledTabs
          sx={{ mb: 2 }}
          textColor="inherit"
          value={tabsValue}
          onChange={handleChangeTabsValue}
        >
          <Tab label="Payments Due Out" value={Tabs.unpaid} />
          <Tab label="Payments Made" value={Tabs.paid} />
          <Tab
            sx={{ overflow: 'visible' }}
            label={
              <Box sx={{ display: 'flex', position: 'relative' }}>
                <Typography fontSize="14px" fontWeight={500}>
                  Payments Due In
                </Typography>
                {manualPaymentSubmittedCount > 0 && (
                  <Badge
                    color="error"
                    badgeContent={manualPaymentSubmittedCount}
                    sx={{
                      position: 'absolute',
                      top: 0,
                      right: 0,
                      zIndex: '1',
                    }}
                  />
                )}
              </Box>
            }
            value={Tabs.income}
          />
          <Tab label="Payments Received" value={Tabs.income_paid} />
        </StyledTabs>

        {tabsValue === Tabs.unpaid && (
          <PaymentsDueTab canMakeTransfer={hasBankAccount} />
        )}
        {tabsValue === Tabs.paid && <PaymentsMadeTab />}
        {tabsValue === Tabs.income && (
          <PaymentsIncomeTab refetch={refectPaymentsIncome} />
        )}
        {tabsValue === Tabs.income_paid && <PaymentsReceivedTab />}
      </Box>
    </>
  );
}

export { TransfersPage };
