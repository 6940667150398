import { Contract } from '../../../common/api';
import { PageContractClaimTabs } from '../views/contract-claim-view/ContractClaimView';

export const generateBreadcrumb = (accountId: string) => (node: Contract) => {
  const b = [];
  b.push({
    url: `/projects`,
    label: 'Projects',
    contractorName: '',
  });

  b.push({
    url: `/projects/${node.projectId}`,
    label: node.project.name,
    contractorName: '',
  });

  if (node.path && node.contractorAccountId !== accountId) {
    node.path.forEach(path => {
      b.push({
        url: path.url,
        label: path.label,
        contractorName: path.contractorName,
        state: {
          tab: PageContractClaimTabs.tasks,
        },
      });
    });
  }

  return [
    ...b,
    {
      url: `/contracts/${node.id}`,
      label: node.description,
      contractorName: node.contractorAccount.name,
    },
  ];
};
