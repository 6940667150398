import React, { useCallback, useState } from 'react';
import { IconButton, TableCell, TableRow, Typography } from '@mui/material';
import { formatAmountByType } from '../../../common/format';
import { ProgressBar } from '../../../components/progress-bar';
import { LinkTo } from '../../../styles';
import { SubTaskRow } from './SubTasksTable';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Task, updateTask } from '../../../common/api';
import { useDialogState } from '../../../components/dialog/dialog.hooks';
import { VariationAssignParent } from '../../variations/VariationAssignParent';

interface Props {
  task: SubTaskRow;
  reloadData: () => void;
  closeSubcontractDialog: () => void;
  contractId: string;
  tasks: Task[];
  isMainCustomer: boolean;
}

function SubtaskTableRow(props: Props) {
  const {
    task,
    tasks,
    reloadData,
    contractId,
    closeSubcontractDialog,
    isMainCustomer,
  } = props;

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const isOpenActionButtons = Boolean(anchorEl);
  const [taskIdToAssignTo, setTaskIdToAssignTo] = useState<string>('');
  const {
    isVisible: isSearchVisible,
    close: closeSearch,
    open: openSearch,
  } = useDialogState();

  const handleAssignToTask = useCallback(
    async (currentTask: Task, parentTaskID: string | null) => {
      await updateTask(contractId, currentTask.id, {
        description: currentTask.description,
        identifier: currentTask.identifier,
        value: Number(currentTask.value),
        parentTaskId: parentTaskID,
        type: currentTask.type,
        unitOfMeasure: currentTask.unitOfMeasure,
        quantity: currentTask.quantity,
        rate: currentTask.rate,
        excludeFromRetention: currentTask.excludeFromRetention,
      });

      reloadData();
    },
    [contractId, reloadData]
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  const handleAssign = (
    event: React.MouseEvent<HTMLLIElement>,
    taskId: string
  ) => {
    event.stopPropagation();
    setAnchorEl(null);

    setTaskIdToAssignTo(taskId);
    openSearch();
  };

  const handleUnassign = (
    event: React.MouseEvent<HTMLLIElement>,
    taskId: string
  ) => {
    event.stopPropagation();
    setAnchorEl(null);

    const currentTask = tasks.find(t => t.id === taskId);
    if (currentTask) {
      handleAssignToTask(currentTask, null);
    }
  };

  return (
    <TableRow hover={true}>
      <TableCell>
        <Typography>{task.identifier}</Typography>
      </TableCell>
      <TableCell>
        <LinkTo
          to={`/contracts/${task.contractId}?taskId=${task.parentTaskId}`}
          onClick={closeSubcontractDialog}
        >
          <Typography sx={{ fontSize: '16px' }} variant={'body1'}>
            {task.description}
          </Typography>
          <Typography sx={{ fontSize: '12px' }} variant={'caption'}>
            0 Sub-Sub Contracts
          </Typography>
        </LinkTo>
      </TableCell>
      <TableCell>
        <Typography sx={{ fontSize: '16px' }} variant={'body1'}>
          {task.subContractDescription}
        </Typography>
        <Typography sx={{ fontSize: '12px' }} variant={'caption'}>
          Sub-contract ref{task.subContractRef}
        </Typography>
      </TableCell>
      <TableCell>
        <ProgressBar
          total={task.value}
          claimed={task.claimed}
          approved={task.approved}
          paid={task.paid}
          valueType={task.valueType}
          approvedVariation={Number(task.approvedVariation)}
          unApprovedVariation={Number(task.unApprovedVariation)}
        />
      </TableCell>
      <TableCell>{formatAmountByType(task.value, task.valueType)}</TableCell>
      {isMainCustomer && (
        <>
          <TableCell sx={{ textAlign: 'center' }}>
            <IconButton onClick={handleClick}>
              <MoreVertIcon />
            </IconButton>

            <Menu
              anchorEl={anchorEl}
              open={isOpenActionButtons}
              onClose={handleClose}
              PaperProps={{
                style: {
                  width: '150px',
                },
              }}
            >
              <MenuItem
                key="assign"
                onClick={event => {
                  return handleAssign(event, task.id);
                }}
              >
                {task.parentTaskId ? 'Re-Assign' : 'Assign'}
              </MenuItem>
              <MenuItem
                key="unassign"
                onClick={event => handleUnassign(event, task.id)}
              >
                Un-Assign
              </MenuItem>
            </Menu>
          </TableCell>
          {isSearchVisible && contractId && (
            <VariationAssignParent
              contractId={contractId}
              variationItems={tasks}
              variationItemIdToAssignTo={taskIdToAssignTo}
              handleClose={closeSearch}
              assignToTask={taskId => {
                const currentTask = tasks.find(t => t.id === taskIdToAssignTo);

                if (currentTask && taskId) {
                  handleAssignToTask(currentTask, taskId);
                }
              }}
            />
          )}
        </>
      )}
    </TableRow>
  );
}

export { SubtaskTableRow };
