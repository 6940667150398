import { axios } from './axios';

export interface VisibilitySetting {
  name: string;
  isOn: boolean;
}

export async function updateContractVisibility(
  contractId: string,
  visibilitySettings: VisibilitySetting[]
): Promise<void> {
  await axios.patch(`/contracts/${contractId}/visibility`, {
    contractId,
    visibilitySettings,
  });
}
