import { createTheme } from '@mui/material';
import {
  brand100,
  brand800,
  brand900,
  grey300,
  grey500,
  grey900,
} from './styles/colours';

let theme = createTheme({
  palette: {
    primary: {
      light: brand800,
      main: brand900,
      dark: brand900,
    },
  },
  typography: {
    h1: {
      fontSize: '2.4rem',
      fontWeight: 300,
    },
    h2: {
      fontSize: '2.1rem',
      fontWeight: 300,
    },
    h3: {
      fontSize: '1.8rem',
      fontWeight: 300,
    },
    h4: {
      fontSize: '1.5rem',
      fontWeight: 300,
    },
    h5: {
      fontSize: '1.2rem',
      fontWeight: 400,
    },
    h6: {
      fontSize: '0.9rem',
      fontWeight: 450,
    },
    subtitle1: {
      fontSize: '0.9rem',
      fontWeight: 400,
    },
  },
  shape: {
    borderRadius: 8,
  },
  components: {
    MuiTab: {
      defaultProps: {
        disableRipple: true,
      },
    },
  },
  mixins: {
    toolbar: {
      minHeight: 48,
    },
  },
});

theme = {
  ...theme,
  components: {
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundColor: '#fff',
          height: 'calc(100vh - 64px)',
          marginTop: 64,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
        contained: {
          boxShadow: 'none',
          '&:active': {
            boxShadow: 'none',
          },
        },
        outlined: {
          color: grey900,
          borderColor: grey300,
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          marginLeft: theme.spacing(1),
        },
        indicator: {
          height: 2,
          backgroundColor: brand800,
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          textColorInherit: grey900,
          margin: '0 16px',
          minWidth: 0,
          padding: 0,
          [theme.breakpoints.up('md')]: {
            padding: 0,
            minWidth: 0,
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          padding: theme.spacing(1),
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          borderRadius: 4,
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          backgroundColor: 'rgb(255,255,255,0.15)',
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            color: brand800,
            backgroundColor: brand100,
            '&:hover': {
              backgroundColor: brand100,
            },
          },
          padding: 0,
          margin: '6px 24px 6px 24px',
          borderRadius: 8,
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        primary: {
          fontSize: 14,
          fontWeight: theme.typography.fontWeightMedium,
          paddingLeft: 16,
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          color: 'inherit',
          minWidth: 'auto',
          marginRight: theme.spacing(2),
          marginLeft: 16,
          '& svg': {
            fontSize: 20,
          },
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          width: 32,
          height: 32,
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          backgroundColor: '#fff',
          color: grey500,
          borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
          height: 64,
        },
      },
    },
    MuiTableSortLabel: {
      styleOverrides: {
        root: {
          color: grey500,
          fontSize: 12,
          fontWeight: 400,
          letterSpacing: 1,
        },
      },
    },
  },
};

export { theme };
