import React, { useCallback } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation, useQuery } from '@tanstack/react-query';
import { Button, MobileStepper } from '@mui/material';

import {
  Account,
  createAccount,
  CreateAccountPayload,
  createContract,
  CreateContractPayload,
  listAccounts,
  updateAccount,
  UpdateAccountPayload,
} from '../../../common/api';

import { useAuth } from '../../../common/auth-provider/AuthProvider';
import {
  AddContractContextProvider,
  useAddContract,
} from './AddContract.context';
import { AddContractStep3 } from './AddContractStep3';
import {
  COMPANY_REP_EMAIL_FIELD,
  COMPANY_REPRESENTATIVE_FIELD,
  CONTRACT_JURISDICTION,
  CONTRACT_PAYMENT_TERMS_FIELD,
  CONTRACT_TITLE_FIELD,
  FOR_WORK_COMPLETED_TO,
  FormData,
  CONTRACT_AWARD_FIELD,
  CONTRACT_REF_FIELD,
  COMPANY_REP_PHONE_FIELD,
  CLAIMS_DUE_ON,
  RETENTION_BASIS,
  RETENTION_INITIAL,
  RETENTION_MAX,
  DEFECT_LIABILITY_NUMBER,
  DEFECT_LIABILITY_TERM,
  VARIATION_RETENTION_BASIS,
  VARIATION_RETENTION_MAX,
  VARIATION_RETENTION_INITIAL,
  RETENTION_MINIMAL_APPROVAL,
  RETENTION_INITIAL_RELEASE_AMOUNT,
  CONTRACT_CONTRACTOR_NAME,
} from './AddContract.constants';
import {
  RententionBasis,
  TimePeriod,
} from '../../../common/api/contract-types';
import { AddContractStep1 } from './AddContractStep1';
import { AddContractStep2 } from './AddContractStep2';
import { LoadingButton } from '@mui/lab';
import { mainContractVisibilitySettings } from '../../contract-page/components/visibility/main-visibility/types';
import { getParentContractVisibilitySettings } from '../../contract-page/components/visibility/utils/visibilitySettings.utils';
import { contractVisibilitySettings } from '../../contract-page/components/visibility/visibilitySettings/types';
import { dependencyMap } from '../../contract-page/components/visibility/visibilitySettings/dependencyMap';

interface Props {
  projectId: string;
  parentContractId?: string;
  contractListReportItemId?: string;
  onClose: () => void;
  reloadData?: () => Promise<void>;
}

function AddContractForm({
  projectId,
  parentContractId,
  contractListReportItemId,
  reloadData,
  onClose,
}: Props) {
  const {
    step,
    setStep,
    selectedCompanyId,
    selectedCompany,
    setSelectedCompany,
    setSelectedCompanyId,
    setIsEmailError,
  } = useAddContract();

  const formMethods = useForm<FormData>({
    mode: 'onChange',
    defaultValues: {
      [CONTRACT_JURISDICTION]: '',
      [CONTRACT_PAYMENT_TERMS_FIELD]: 10,
      [CLAIMS_DUE_ON]: '20',
      [FOR_WORK_COMPLETED_TO]: 'last',
      [DEFECT_LIABILITY_NUMBER]: '1',
      [DEFECT_LIABILITY_TERM]: TimePeriod.Days,
      [RETENTION_BASIS]: RententionBasis.None,
      [VARIATION_RETENTION_BASIS]: RententionBasis.None,
      [RETENTION_MINIMAL_APPROVAL]: '100',
      [RETENTION_INITIAL_RELEASE_AMOUNT]: '50',
    },
  });

  const { trigger, getValues } = formMethods;

  const { user } = useAuth();

  const { data: accountList } = useQuery<Array<Account>>(['accounts'], () => {
    return listAccounts();
  });

  const { mutateAsync: mutateAddContract, isLoading: isLoadingAddContract } =
    useMutation((payload: CreateContractPayload) => createContract(payload), {
      onSuccess: async () => {
        onClose();
        await reloadData?.();
      },
    });

  const { mutateAsync: mutateAddAccount, isLoading: isLoadingAddAccount } =
    useMutation((payload: CreateAccountPayload) => createAccount(payload), {
      onError: ({ response }) => {
        if (response.data.code === 'P2002') setIsEmailError(true);
      },
    });

  const {
    mutateAsync: mutateUpdateAccount,
    isLoading: isLoadingUpdateAccount,
  } = useMutation((payload: UpdateAccountPayload) => updateAccount(payload), {
    onError: ({ response }) => {
      if (response.data.code === 'P2002') setIsEmailError(true);
    },
  });

  const onSubmit = useCallback(
    async (form: FormData) => {
      if (!selectedCompany && selectedCompanyId === 'new') {
        const account = await mutateAddAccount({
          email: form[COMPANY_REP_EMAIL_FIELD],
          phone: form[COMPANY_REP_PHONE_FIELD],
          representative: form[COMPANY_REPRESENTATIVE_FIELD],
          isProvider: true,
          isContract: true,
        });

        const newContractData: CreateContractPayload = {
          projectId: projectId,
          customerAccountId: user?.accountId || '',
          description: form[CONTRACT_TITLE_FIELD],
          contractorAccountId: account?.id,
          award: form[CONTRACT_AWARD_FIELD],
          identifier: form[CONTRACT_REF_FIELD],
          paymentTerm: form[CONTRACT_PAYMENT_TERMS_FIELD],
          claimsDueOn: form[CLAIMS_DUE_ON],
          defectLiabilityNum: Number(form[DEFECT_LIABILITY_NUMBER]),
          defectLiabilityTerm: form[DEFECT_LIABILITY_TERM],
          jurisdiction: form[CONTRACT_JURISDICTION],
          retentionBasis: form[RETENTION_BASIS],
          retentionInitial: form[RETENTION_INITIAL]
            ? Number(form[RETENTION_INITIAL])
            : undefined,
          retentionMax: form[RETENTION_MAX]
            ? Number(form[RETENTION_MAX])
            : undefined,
          variationRetentionBasis: form[VARIATION_RETENTION_BASIS],
          variationRetentionInitial: form[VARIATION_RETENTION_INITIAL]
            ? Number(form[VARIATION_RETENTION_INITIAL])
            : undefined,
          variationRetentionMax: form[VARIATION_RETENTION_MAX]
            ? Number(form[VARIATION_RETENTION_MAX])
            : undefined,
          forWorkCompletedTo: form[FOR_WORK_COMPLETED_TO],
          minimalApproval: form[RETENTION_MINIMAL_APPROVAL]
            ? Number(form[RETENTION_MINIMAL_APPROVAL])
            : undefined,
          minimalReleaseAmount: form[RETENTION_INITIAL_RELEASE_AMOUNT]
            ? Number(form[RETENTION_INITIAL_RELEASE_AMOUNT])
            : undefined,
          ...(parentContractId && { parentContractId }),
          contractListReportItemId,
        };

        await mutateAddContract(newContractData);
        setSelectedCompany(undefined);
        setSelectedCompanyId('');
      }

      if (selectedCompany) {
        const newContractData: CreateContractPayload = {
          projectId: projectId,
          customerAccountId: user?.accountId || '',
          description: form[CONTRACT_TITLE_FIELD],
          contractorAccountId: selectedCompany?.id,
          award: form[CONTRACT_AWARD_FIELD],
          identifier: form[CONTRACT_REF_FIELD],
          paymentTerm: form[CONTRACT_PAYMENT_TERMS_FIELD],
          claimsDueOn: form[CLAIMS_DUE_ON],
          defectLiabilityNum: Number(form[DEFECT_LIABILITY_NUMBER]),
          defectLiabilityTerm: form[DEFECT_LIABILITY_TERM],
          jurisdiction: form[CONTRACT_JURISDICTION],
          retentionBasis: form[RETENTION_BASIS],
          retentionInitial: form[RETENTION_INITIAL]
            ? Number(form[RETENTION_INITIAL])
            : undefined,
          retentionMax: form[RETENTION_MAX]
            ? Number(form[RETENTION_MAX])
            : undefined,
          variationRetentionBasis: form[VARIATION_RETENTION_BASIS],
          variationRetentionInitial: form[VARIATION_RETENTION_INITIAL]
            ? Number(form[VARIATION_RETENTION_INITIAL])
            : undefined,
          variationRetentionMax: form[VARIATION_RETENTION_MAX]
            ? Number(form[VARIATION_RETENTION_MAX])
            : undefined,
          forWorkCompletedTo: form[FOR_WORK_COMPLETED_TO],
          minimalApproval: form[RETENTION_MINIMAL_APPROVAL]
            ? Number(form[RETENTION_MINIMAL_APPROVAL])
            : undefined,
          minimalReleaseAmount: form[RETENTION_INITIAL_RELEASE_AMOUNT]
            ? Number(form[RETENTION_INITIAL_RELEASE_AMOUNT])
            : undefined,
          ...(parentContractId && { parentContractId }),
          contractListReportItemId,
        };

        await mutateUpdateAccount({
          id: selectedCompany.id,
          representativeEmail: form[COMPANY_REP_EMAIL_FIELD],
          representative: form[COMPANY_REPRESENTATIVE_FIELD],
          representativePhone: form[COMPANY_REP_PHONE_FIELD],
        });

        if (!parentContractId) {
          newContractData.visibilitySettings =
            mainContractVisibilitySettings.map(checkbox => ({
              name: checkbox.name,
              isOn: checkbox.isOn,
            }));
        } else {
          const parentVisibilitySettings =
            await getParentContractVisibilitySettings(parentContractId);

          newContractData.visibilitySettings = contractVisibilitySettings.map(
            checkbox => {
              const dependencies = dependencyMap[checkbox.name];
              if (dependencies) {
                const [parentCheckboxName, replicatedCheckboxName] =
                  dependencies;

                const parentCheckbox = parentVisibilitySettings?.find(
                  parentCheckbox => parentCheckbox.name === parentCheckboxName
                );
                const replicatedCheckbox = parentVisibilitySettings?.find(
                  parentCheckbox =>
                    parentCheckbox.name === replicatedCheckboxName
                );

                if (parentCheckbox?.isOn && replicatedCheckbox?.isOn) {
                  return {
                    ...checkbox,
                    isOn: true,
                  };
                }
              }

              return checkbox;
            }
          );
        }

        await mutateAddContract(newContractData);

        setSelectedCompany(undefined);
        setSelectedCompanyId('');
      }
    },
    [
      mutateAddAccount,
      mutateAddContract,
      mutateUpdateAccount,
      projectId,
      selectedCompany,
      setSelectedCompany,
      setSelectedCompanyId,
      selectedCompanyId,
      user?.accountId,
      parentContractId,
      contractListReportItemId,
    ]
  );

  return (
    <FormProvider {...formMethods}>
      <form noValidate>
        {step === 0 && <AddContractStep1 accountList={accountList} />}
        {step === 1 && <AddContractStep2 />}
        {step === 2 && <AddContractStep3 />}
        <MobileStepper
          steps={3}
          variant="dots"
          position="static"
          activeStep={step}
          sx={{ flexGrow: 1, pl: 0, pr: 0, mt: 2 }}
          nextButton={
            <LoadingButton
              variant="contained"
              loading={
                isLoadingAddAccount ||
                isLoadingAddContract ||
                isLoadingUpdateAccount
              }
              onClick={async () => {
                if (step === 0) {
                  const isValid = await trigger([
                    CONTRACT_TITLE_FIELD,
                    CONTRACT_AWARD_FIELD,
                    CONTRACT_CONTRACTOR_NAME,
                    CONTRACT_JURISDICTION,
                    COMPANY_REPRESENTATIVE_FIELD,
                    COMPANY_REP_EMAIL_FIELD,
                  ]);
                  if (isValid) {
                    setStep(1);
                  }
                } else if (step === 1) {
                  const isValid = await trigger([
                    CONTRACT_PAYMENT_TERMS_FIELD,
                    CLAIMS_DUE_ON,
                    FOR_WORK_COMPLETED_TO,
                  ]);
                  if (isValid) {
                    setStep(2);
                  }
                } else {
                  const isValid = await trigger([
                    DEFECT_LIABILITY_NUMBER,
                    DEFECT_LIABILITY_TERM,
                    RETENTION_INITIAL,
                    RETENTION_MAX,
                    RETENTION_BASIS,
                    VARIATION_RETENTION_BASIS,
                    VARIATION_RETENTION_MAX,
                    VARIATION_RETENTION_INITIAL,
                    RETENTION_MINIMAL_APPROVAL,
                    RETENTION_INITIAL_RELEASE_AMOUNT,
                  ]);
                  if (isValid) {
                    await onSubmit(getValues());
                  }
                }
              }}
            >
              {step === 2 ? 'Finish' : 'Next'}
            </LoadingButton>
          }
          backButton={
            <Button
              variant="outlined"
              onClick={() => {
                setStep(step - 1);
              }}
              disabled={step === 0}
            >
              Back
            </Button>
          }
        />
      </form>
    </FormProvider>
  );
}

const AddContractFormWrapper = (props: Props) => (
  <AddContractContextProvider>
    <AddContractForm {...props} />
  </AddContractContextProvider>
);

export default AddContractFormWrapper;
