import { axios } from './axios';
import { Invitation, Role } from './types';

async function listInvitations(accountId: string): Promise<Array<Invitation>> {
  const resp = await axios.get(`accounts/${accountId}/invitations`);
  return resp.data;
}

export type UserInvitationPayload = {
  accountId: string;
  email: string;
  firstName: string;
  lastName: string;
  phone: string;
  roles: Array<Role>;
};

async function createInvitation(
  payload: UserInvitationPayload
): Promise<Invitation> {
  const resp = await axios.post(
    `accounts/${payload.accountId}/invitations`,
    payload
  );
  return resp.data;
}

async function deleteInvitation(accountId: string, invitationId: string) {
  await axios.delete(`accounts/${accountId}/invitations/${invitationId}`);
}

export { listInvitations, createInvitation, deleteInvitation };
