import * as React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import { AxiosInterceptor } from './common/auth-provider/AxiosInterceptor';
import {
  VARIATION_EXISTING,
  VARIATION_NEW,
  VARIATION_NEW_DRAFT,
  VARIATION_RECEIVED,
} from './common/router-util/routes';

// Providers
import { AuthProvider, RequireAuth } from './common/auth-provider';

// Layout
import { DashboardLayout } from './layouts/dashboard-layout';

// Pages
import { ActivateInvitationAccountPage } from './pages/activate-invitation-account-page';
import { ActivateInvitationPage } from './pages/activate-invitation-page';
import { ClaimPage } from './pages/claim-page';
import { ContractNegotiationPage } from './pages/contract-page/ContractNegotiationPage';
import { ContractPage } from './pages/contract-page';
import { LoginPage } from './pages/login-page';
import { ProjectListPage } from './pages/project-list-page';
import { ProjectPage } from './pages/project-page';
import { RegisterPage } from './pages/register-page';
import { ResetPasswordPage } from './pages/reset-password-page';
import { TransfersPage } from './pages/transfers';
import { VariationsPage } from './pages/variations/VariationsPage';
import { VariationsReceivedPage } from './pages/variations/VariationsReceivedPage';

const queryClient = new QueryClient({
  defaultOptions: {
    mutations: {
      retry: 0,
    },
    queries: {
      refetchOnWindowFocus: false,
      retry: 0,
    },
  },
});

export default function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <AxiosInterceptor>
          <Routes>
            <Route path="/login" element={<LoginPage />} />
            <Route path="/reset-password/:id" element={<ResetPasswordPage />} />
            <Route path="/register" element={<RegisterPage />} />
            <Route
              path={'/activate-invitation/:invitationId'}
              element={<ActivateInvitationPage />}
            />
            <Route
              path={'/update-account-invitation/:invitationId'}
              element={<ActivateInvitationAccountPage />}
            />
            <Route
              element={
                <RequireAuth>
                  <DashboardLayout />
                </RequireAuth>
              }
            >
              <Route index path={'/projects'} element={<ProjectListPage />} />
              <Route path={'projects/:projectId'} element={<ProjectPage />} />
              <Route
                path="/contracts/:contractId/subcontract/:subcontractId/*"
                element={
                  <Routes>
                    <Route path="/claims/:claimId" element={<ClaimPage />} />
                    <Route index element={<ContractPage />} />
                  </Routes>
                }
              />
              <Route
                path="/contracts/:contractId/*"
                element={
                  <Routes>
                    <Route path="/claims/:claimId" element={<ClaimPage />} />
                    <Route path="/edit" element={<ContractNegotiationPage />} />
                    <Route index element={<ContractPage />} />
                  </Routes>
                }
              ></Route>
              <Route path={'/financials'} element={<TransfersPage />} />
              <Route path={VARIATION_NEW} element={<VariationsPage />} />
              <Route path={VARIATION_EXISTING} element={<VariationsPage />} />
              <Route
                path={VARIATION_NEW_DRAFT}
                element={<VariationsPage isAnticipated />}
              />
              <Route
                path={VARIATION_RECEIVED}
                element={<VariationsReceivedPage />}
              />
            </Route>
            <Route path="*" element={<Navigate to="/projects" />} />
          </Routes>
        </AxiosInterceptor>
      </AuthProvider>
    </QueryClientProvider>
  );
}
