import React from 'react';
import {
  Box,
  Button,
  Drawer,
  FormControlLabel,
  List,
  Stack,
  Typography,
} from '@mui/material';
import { StyledSwitch } from '../../../../styles';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { PriorityMenu } from './PriorityMenu';
import { grey200, grey300, grey900 } from '../../../../styles/colours';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { NotificationItem } from './NotificationItem';
import { Notification } from '../../../../common/api';
import { PriorityType } from './types';

interface Props {
  isDrawer: boolean;
  closeDrawer: () => void;
  isShowUnreadNotifications: boolean;
  handleShowUnreadNotifications: (
    e: React.SyntheticEvent<Element, Event>,
    checked: boolean
  ) => void;
  notificationRow?: Array<
    Notification & {
      priority: typeof PriorityType[keyof typeof PriorityType];
    }
  >;
  groupNotifications: Array<string>;
  setGroupNotifications: React.Dispatch<React.SetStateAction<Array<string>>>;
  handleReadAll: () => void;
  handleRemove: (value: string) => void;
  isItemSelected: (id: string) => boolean;
  handleCheckbox: (notification: Notification) => Promise<void>;
}

function NotificationDrawer(props: Props) {
  const {
    isDrawer,
    closeDrawer,
    isShowUnreadNotifications,
    handleShowUnreadNotifications,
    notificationRow,
    groupNotifications,
    setGroupNotifications,
    handleReadAll,
    handleRemove,
    isItemSelected,
    handleCheckbox,
  } = props;

  return (
    <Drawer
      anchor="right"
      open={isDrawer}
      onClose={closeDrawer}
      sx={styles.Drawer}
    >
      <Box sx={{ p: 3 }}>
        <Stack
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ mb: 2 }}
        >
          <Typography variant={'h4'} sx={{ fontWeight: 500 }}>
            Notifications
          </Typography>
          <Stack gap="8px" flexDirection="row" alignItems="center">
            <FormControlLabel
              control={<StyledSwitch />}
              labelPlacement="start"
              label="Only show unread"
              value={isShowUnreadNotifications}
              onChange={handleShowUnreadNotifications}
            />
            <MoreVertIcon />
          </Stack>
        </Stack>
        <Stack
          flexDirection="row"
          justifyContent="space-between"
          sx={{ pb: 1 }}
        >
          <PriorityMenu handleGroupNotification={setGroupNotifications} />
          <Button
            variant="text"
            onClick={handleReadAll}
            sx={styles.ReadAllButton}
          >
            Mark all as read
          </Button>
        </Stack>
        <Stack
          flexDirection="row"
          gap="16px"
          sx={{
            borderBottom: `1px solid ${grey200}`,
            pb: 4,
          }}
        >
          {groupNotifications.map(groupNotification => (
            <Box key={groupNotification}>
              <Button
                onClick={() => handleRemove(groupNotification)}
                sx={styles.RemoveButton}
              >
                <HighlightOffIcon />
                {groupNotification}
              </Button>
            </Box>
          ))}
        </Stack>
      </Box>
      <List>
        {notificationRow &&
          notificationRow?.map(notification => (
            <NotificationItem
              key={notification.id}
              isItemSelected={isItemSelected}
              handleCheckbox={handleCheckbox}
              notification={notification}
              closeDrawer={closeDrawer}
            />
          ))}
      </List>
    </Drawer>
  );
}

const styles = {
  Drawer: {
    '& .MuiDrawer-paper': {
      width: '500px',
      borderRadius: '16px 0 0 16px',
    },
    '& .MuiBackdrop-root': {
      opacity: '0 !important',
    },
  },
  ReadAllButton: {
    textDecoration: 'underline',
    fontSize: 12,
    color: grey900,
    '&:hover': {
      textDecoration: 'underline',
      background: '#FFF',
    },
  },
  RemoveButton: {
    color: grey900,
    borderRadius: '72px',
    border: `1px solid ${grey300}`,
    display: 'flex',
    gap: '6px',
  },
};

export { NotificationDrawer };
