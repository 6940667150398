import React from 'react';
import { styled } from '@mui/system';
import { Tooltip, tooltipClasses, TooltipProps } from '@mui/material';

export const StyledTooltip = styled(
  ({ className, children, ...props }: TooltipProps) => (
    <Tooltip {...props} arrow classes={{ popper: className }}>
      {children}
    </Tooltip>
  )
)(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
  },
}));
