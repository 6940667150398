import { HeadCell } from '../../../../../components/sorted-table-head/SortedTable.hooks';
import {
  ClaimItem,
  ClaimType,
  DOLLAR,
  Task,
  ValueType,
} from '../../../../../common/api';

const headCells: Array<HeadCell> = [
  {
    id: 'identifier',
    numeric: false,
    disablePadding: false,
    label: 'ID',
  },
  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    label: 'Task name',
  },
  {
    id: 'taskValue',
    numeric: true,
    disablePadding: false,
    label: 'Value',
  },
  {
    id: 'previouslyApproved',
    numeric: true,
    disablePadding: false,
    label: 'Previously Approved',
  },
  {
    id: 'progress',
    numeric: true,
    disablePadding: false,
    label: 'Progress',
  },
  {
    id: 'currentClaim',
    numeric: false,
    disablePadding: false,
    label: 'Current Claim',
  },
];

export interface CurrentClaimRow {
  identifier: number;
  claimed: number;
  taskValue: number;
  claimType: ClaimType;
  approved: number;
  costToComplete: number;
  previouslyApproved: number;
  valueType: ValueType;
  name: string;
  paid: number;
  progress: number;
  id: string;
  currentClaim: number;
  value: number;
  taskId: string;
  approvedVariation: string | number;
  unApprovedVariation: string | number;
}

function createCurrentClaimRow(claimItem: ClaimItem, tasks?: Array<Task>) {
  const task = tasks?.find(t => t.id === claimItem.taskId);
  const approved = Number(task?.approved || 0);
  const taskValue = Number(task?.value || 0);
  const claimed = Number(task?.claimed || 0);
  const paid = Number(task?.paid || 0);
  const totalApprovedVariation = Number(task?.totalApprovedVariation || 0);
  const unApprovedVariation = Number(task?.totalUnapprovedVariation || 0);
  const costToComplete = getCostToComplete(taskValue, paid, approved, claimed);

  const costToCompletePercentsValue = Math.floor(
    (costToComplete / taskValue) * 100
  );

  return {
    id: claimItem.id,
    claimType: claimItem.claimType,
    value: Number(claimItem.value),
    identifier: Number(task?.identifier || 0),
    name: task?.description || '',
    taskValue,
    progress: costToCompletePercentsValue,
    previouslyApproved: approved + paid,
    currentClaim: Number(claimItem.value),
    approved,
    claimed,
    paid,
    approvedVariation: totalApprovedVariation,
    unApprovedVariation: unApprovedVariation,
    valueType: task?.valueType || DOLLAR,
    taskId: task?.id || '',
    costToComplete,
  };
}

function getIsError(
  value: number | null,
  claimType: ClaimType,
  costToComplete: number,
  costToCompletePercentsValue: number
) {
  return value === null
    ? false
    : claimType === ClaimType.value
    ? value > costToComplete
    : value > costToCompletePercentsValue;
}

function getCostToComplete(
  taskValue: number | string,
  paid: number | string,
  approved: number | string,
  claimed: number | string
) {
  return Number(taskValue) - Number(paid) - Number(approved) - Number(claimed);
}

export { headCells, createCurrentClaimRow, getIsError, getCostToComplete };
