import { Button, Dialog, DialogContent, TextField } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import React, { useCallback } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { saveTaskGroup } from '../../../common/api/taskGroups';
import { useSnackbar } from '../../../common/hooks/useSnackbar';
import { TaskGroup } from '../../../common/api';
import { Heading, InputActions } from '../../../styles';
import { LoadingButton } from '@mui/lab';
import { getLastOrderValue } from '../TaskGroup.utils';

const NAME_FIELD = 'name';

type FormData = {
  [NAME_FIELD]: string;
};

type Props = {
  contractId: string;
  addToTaskGroupId: string | null;
  taskGroup?: TaskGroup;
  taskGroups: TaskGroup[];
  onReload: () => void;
  handleClose: () => void;
};

function TaskGroupCreateDialog({
  contractId,
  addToTaskGroupId,
  taskGroup,
  taskGroups,
  onReload,
  handleClose,
}: Props) {
  const { showAlert, SnackbarComponent } = useSnackbar();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: {
      [NAME_FIELD]: taskGroup?.name || '',
    },
  });

  const { mutateAsync, isLoading } = useMutation(
    ({ name, order }: { name: string; order: string }) =>
      saveTaskGroup({
        id: taskGroup?.id,
        name,
        order,
        contractId,
        parentId: taskGroup ? taskGroup.parentId : addToTaskGroupId,
      }),
    {
      onError: () => showAlert('An error occured saving the folder', 'error'),
    }
  );

  const onSubmit = useCallback(
    async (data: FormData) => {
      if (!taskGroup) {
        await mutateAsync({
          ...data,
          order: getLastOrderValue(taskGroups, addToTaskGroupId),
        });
      } else {
        await mutateAsync({ ...data, order: taskGroup.order });
      }
      onReload();
      handleClose();
    },
    [
      addToTaskGroupId,
      taskGroup,
      taskGroups,
      handleClose,
      onReload,
      mutateAsync,
    ]
  );

  return (
    <>
      <SnackbarComponent />
      <Dialog open={true} onClose={handleClose}>
        <DialogContent sx={{ width: 400 }}>
          <Heading>{taskGroup ? 'Update Folder' : 'Add Folder'}</Heading>
          <Controller
            name={NAME_FIELD}
            control={control}
            defaultValue={taskGroup?.name}
            rules={{
              required: 'Name is required',
            }}
            render={({ field }) => (
              <TextField
                {...field}
                value={field.value || ''}
                label="Name"
                required
                fullWidth
                error={!!errors[NAME_FIELD]}
                helperText={errors[NAME_FIELD]?.message}
              />
            )}
          />
          <InputActions>
            <Button variant="outlined" type="button" onClick={handleClose}>
              Cancel
            </Button>
            <LoadingButton
              variant="contained"
              loading={isLoading}
              disabled={isLoading}
              onClick={handleSubmit(onSubmit)}
            >
              Save
            </LoadingButton>
          </InputActions>
        </DialogContent>
      </Dialog>
    </>
  );
}

export { TaskGroupCreateDialog };
