import {
  Box,
  Button,
  Dialog,
  DialogContent,
  IconButton,
  InputAdornment,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import React, { useMemo, useState } from 'react';
import { ResponseListBaseType, Task, listTasks } from '../../common/api';
import { Heading } from '../../styles';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import {
  DialogClose,
  InputActions,
  StyledTable,
  StyledTableRow,
} from '../../styles';
import { grey600 } from '../../styles/colours';
import { VariationRowItem } from './variationsPage.utils';

type Props = {
  contractId: string;
  variationItems: Array<VariationRowItem>;
  addVariations: (items: VariationRowItem[]) => void;
  handleClose: () => void;
};

function VariationsTaskSearch({
  contractId,
  variationItems,
  addVariations,
  handleClose,
}: Props) {
  const [term, setTerm] = useState<string>('');
  const [variations, setVariations] = useState<VariationRowItem[]>([
    ...variationItems,
  ]);

  // get all tasks in the contract?
  const { data: taskData } = useQuery<ResponseListBaseType<Array<Task>>>(
    ['tasks', contractId],
    () => listTasks(contractId)
  );

  const results = useMemo(() => {
    const tasks = taskData?.data || [];
    return tasks.filter(
      task =>
        task.description.toLowerCase().indexOf(term.toLocaleLowerCase()) !==
          -1 ||
        task.identifier.toLowerCase().indexOf(term.toLocaleLowerCase()) !== -1
    );
  }, [taskData, term]);

  return (
    <Dialog open onClose={handleClose}>
      <DialogContent sx={{ width: '600px', height: '700px' }}>
        <DialogClose onClose={handleClose} />
        <Heading>Add Existing Task</Heading>
        <TextField
          fullWidth
          value={term}
          placeholder="Search by task name or ID"
          sx={{
            borderRadius: '40px',
            '& > div': {
              borderRadius: '40px',
            },
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <IconButton type="button" onClick={() => setTerm('')}>
                  <ClearIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
          onChange={e => setTerm(e.target.value)}
        />
        <Box
          sx={{
            position: 'absolute',
            top: '140px',
            bottom: '60px',
            left: 0,
            right: 0,
            paddingLeft: '24px',
            paddingRight: '24px',
            paddingBottom: '24px',
            overflow: 'auto',
          }}
        >
          <StyledTable sx={{ mt: '16px', overflow: 'scroll' }}>
            <TableHead
              sx={{
                th: {
                  fontSize: '12px',
                  padding: '10px',
                  color: grey600,
                },
              }}
            >
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Task Name</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {results.map(result => (
                <StyledTableRow key={result.id}>
                  <TableCell>{result.identifier}</TableCell>
                  <TableCell>{result.description}</TableCell>
                  <TableCell sx={{ textAlign: 'right' }}>
                    <Button
                      variant="outlined"
                      disabled={
                        !!variations.find(
                          variation => variation.taskId === result.id
                        )
                      }
                      onClick={() => {
                        variations.push({
                          id: '',
                          taskId: result.id,
                          identifier: result.identifier,
                          description: result.description,
                          currentTaskValue: Number(result.value),
                          currentTaskApproved: Number(result.approved),
                          variance: 0,
                          newTaskValue: 0,
                          isNewTask: false,
                          maxVarianceValue: 0,
                        });
                        setVariations([...variations]);
                      }}
                    >
                      Add
                    </Button>
                  </TableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </StyledTable>
        </Box>
        <Box
          sx={{
            position: 'absolute',
            bottom: 0,
            left: 0,
            right: 0,
            padding: '24px',
            '& > div': {
              padding: 0,
            },
          }}
        >
          <InputActions>
            <span />
            <Button
              type="button"
              variant="contained"
              onClick={() => {
                addVariations(variations);
                handleClose();
              }}
            >
              Finish
            </Button>
          </InputActions>
        </Box>
      </DialogContent>
    </Dialog>
  );
}

export { VariationsTaskSearch };
