import { axios } from './axios';
import { AuthResponse } from './types';

const login = async (
  email: string,
  password: string
): Promise<AuthResponse> => {
  const resp = await axios.post(`/auth/login`, { email, password });
  return resp.data;
};

const selfRegister = async (
  email: string,
  password: string
): Promise<AuthResponse> => {
  const resp = await axios.post(`/auth/register`, { email, password });
  return resp.data;
};

const refreshToken = async (token: string): Promise<AuthResponse> => {
  const resp = await axios.post(`/auth/refresh`, { token });
  return resp.data;
};

const getEmailByInvitationId = async (
  invitationId: string
): Promise<{
  email: string;
  orgName: string;
  inviterName: string;
  inviterEmail: string;
  deletedAt: Date | null;
  userId: string | null;
  accountId: string;
}> => {
  const resp = await axios.get(`/auth/register/${invitationId}`);
  return resp.data;
};

const register = async (invitationId: string, password: string) => {
  await axios.post(`/auth/register/${invitationId}`, {
    password,
  });
};

export { login, refreshToken, getEmailByInvitationId, register, selfRegister };
