import React, { useMemo } from 'react';
import { TableBody } from '@mui/material';
import { Claim, Task } from '../../../../../common/api';
import { StyledTable } from '../../../../../styles';
import { SortedTableHead } from '../../../../../components/sorted-table-head';
import { useSortedTable } from '../../../../../components/sorted-table-head/SortedTable.hooks';
import { createClaimTableRow, headCells } from './ClaimTable.utils';
import { stableSort } from '../../../../../common/sort/stableSort';
import { getComparator } from '../../../../../common/sort/getComparator';
import { ClaimTableRow } from './ClaimTableRow';

interface Props {
  claim: Claim;
  tasks?: Array<Task>;
  reloadData: () => void;
  isContractor?: boolean;
}

function ClaimTable(props: Props) {
  const { claim, tasks, reloadData, isContractor } = props;
  const { order, orderBy, handleRequestSort } = useSortedTable('identifier');
  const rows = useMemo(
    () =>
      claim.claimItems.map(claimItem =>
        createClaimTableRow({
          claim,
          claimItem,
          tasks,
          previousClaimSnapshot: claim.previousClaimSnapshot,
        })
      ),
    [tasks, claim]
  );

  return (
    <StyledTable>
      <SortedTableHead
        order={order}
        orderBy={orderBy}
        onRequestSort={handleRequestSort}
        headCells={headCells}
      />
      <TableBody>
        {stableSort(rows, getComparator(order, orderBy)).map(row => {
          const claimItem = claim.claimItems.find(
            claimItem => claimItem.id === row.id
          );
          return (
            <ClaimTableRow
              key={row.id}
              row={row}
              reloadData={reloadData}
              claimId={claim.id}
              adjust={claimItem?.adjust}
              isContractor={isContractor}
              claimStatus={claim.status}
            />
          );
        })}
      </TableBody>
    </StyledTable>
  );
}

export { ClaimTable };
