import React, { Fragment, useState } from 'react';
import { Outlet, Params, useParams } from 'react-router-dom';
import { Box, Stack } from '@mui/material';
import { Navigator } from './components/Navigator';
import AppBar from '@mui/material/AppBar';
import * as Styled from './components/DasboardLayout.style';
import { ReactComponent as Logo } from '../../common/assets/logo.svg';
import { NotificationList } from './components/NotificationList';
import { SecondaryMenu } from './components/SecondaryMenu';
import { ServerDate } from './components/ServerDate';

interface DashboardOutletContext {
  title: JSX.Element;
  setTitle: (title: JSX.Element) => void;
}

function DashboardLayout() {
  const [title, setTitle] = useState<JSX.Element | undefined>();
  const { projectId } = useParams() as Params;

  return (
    <Fragment>
      <Box>
        <AppBar
          color="primary"
          position="static"
          elevation={0}
          sx={{ zIndex: 1, height: 64, position: 'sticky', top: 0 }}
        >
          <Styled.DashboardToolbar>
            <Logo width={105} height={40} />
            <Navigator />
            <Stack flexDirection="row" gap={2} alignItems="center">
              {projectId && <ServerDate projectId={projectId} />}
              <NotificationList />
              <SecondaryMenu />
            </Stack>
          </Styled.DashboardToolbar>
        </AppBar>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'stretch',
            px: 10,
            pt: 2,
            minHeight: 'calc(100vh - 64px)',
          }}
        >
          <Box sx={{ pb: 2 }}>{title}</Box>
          <Stack flex={1}>
            <Stack flex={1} component="main">
              <Outlet context={{ title, setTitle }} />
            </Stack>
          </Stack>
        </Box>
      </Box>
    </Fragment>
  );
}

export type { DashboardOutletContext };

export { DashboardLayout };
