import React from 'react';
import { Dialog, DialogContent, Button } from '@mui/material';

import { useDialogState } from '../../../components/dialog/dialog.hooks';
import { DialogClose, Heading } from '../../../styles';
import AddContractForm from './AddContractForm';

interface Props {
  projectId: string;
  buttonTitle?: string;
  parentContractId?: string;
  contractListReportItemId?: string;
  reloadData?: () => Promise<void>;
}

function AddContractDialog({
  projectId,
  buttonTitle,
  parentContractId,
  contractListReportItemId,
  reloadData,
}: Props) {
  const {
    open: handleDialogOpen,
    close: handleDialogClose,
    isVisible,
  } = useDialogState();

  return (
    <div>
      <Button variant="contained" onClick={handleDialogOpen}>
        {buttonTitle || 'Add Contract'}
      </Button>

      {isVisible && (
        <Dialog
          open={isVisible}
          onClose={handleDialogClose}
          PaperProps={{ style: { minWidth: 600 } }}
        >
          <DialogContent>
            <Heading>Create Contract</Heading>
            <DialogClose onClose={handleDialogClose} />
            <AddContractForm
              onClose={handleDialogClose}
              projectId={projectId}
              parentContractId={parentContractId}
              contractListReportItemId={contractListReportItemId}
              reloadData={reloadData}
            />
          </DialogContent>
        </Dialog>
      )}
    </div>
  );
}

export { AddContractDialog };
