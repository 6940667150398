import {
  getSubcontractsSummary,
  Contract,
  SubContractSummary,
  SubContract,
} from '../../../../../common/api';
import { TableBody, TableCell, TableRow, Typography } from '@mui/material';
import React, { useCallback } from 'react';
import { useQuery } from '@tanstack/react-query';
import { ProgressBar } from '../../../../../components/progress-bar';
import { formatAmountByType } from '../../../../../common/format';
import { SkeletonTable } from '../../../../../components/skeleton-table';
import { SortedTableHead } from '../../../../../components/sorted-table-head';
import {
  HeadCell,
  useSortedTable,
} from '../../../../../components/sorted-table-head/SortedTable.hooks';
import { stableSort } from '../../../../../common/sort/stableSort';
import { getComparator } from '../../../../../common/sort/getComparator';
import { getData } from './SubContractTab.utils';
import { LinkTo, StyledTable, TextSmall } from '../../../../../styles';
import { SubContractSummaryCards } from './SubContractSummary';
import { AddContractDialog } from '../../../../project-page/components/AddContractDialog';
import { isContractor } from '../../../../../common/access';
import { useAuth } from '../../../../../common/auth-provider/AuthProvider';
import { ContractReadViewTabs } from '../ContractReadView';
import { Warning } from '@mui/icons-material';

type Props = {
  contract: Contract;
  data?: Array<SubContract>;
  refetchSubContracts: Function;
  isLoading: boolean;
};

function SubContractTab(props: Props) {
  const { contract, data, refetchSubContracts, isLoading } = props;
  const { user } = useAuth();
  const { order, orderBy, handleRequestSort } = useSortedTable();

  const headCells: HeadCell[] = [
    {
      id: 'name',
      numeric: false,
      disablePadding: false,
      label: 'My Sub-Contracts',
    },
    {
      id: 'progress',
      numeric: true,
      disablePadding: false,
      label: 'Progress',
    },
    {
      id: 'claimed',
      numeric: true,
      disablePadding: false,
      label: 'Value Claimed',
    },
  ];

  const {
    data: summaryData,
    isLoading: isLoadingSummary,
    refetch: refetchSummary,
  } = useQuery<SubContractSummary>(['subcontractsSummary', contract.id], () =>
    getSubcontractsSummary({ contractId: contract.id })
  );

  const subcontracts = getData(data || []);

  const reloadData = useCallback(async () => {
    await refetchSubContracts();
    await refetchSummary();
  }, [refetchSubContracts, refetchSummary]);

  return (
    <>
      {!isLoadingSummary && summaryData && (
        <SubContractSummaryCards {...summaryData} />
      )}
      {isLoading ? (
        <SkeletonTable />
      ) : subcontracts.length === 0 ? (
        <>
          <Typography>No Sub-Contracts to display</Typography>
          <br />
          {user && isContractor(user, contract) && contract.projectId && (
            <AddContractDialog
              projectId={contract.projectId}
              reloadData={reloadData}
              buttonTitle={'Add New Subcontract'}
              parentContractId={contract.id}
            />
          )}
        </>
      ) : (
        <>
          <StyledTable>
            <SortedTableHead
              order={order}
              orderBy={orderBy}
              headCells={headCells}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {stableSort(subcontracts, getComparator(order, orderBy)).map(
                subcontract => (
                  <TableRow key={subcontract.id}>
                    <TableCell
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                    >
                      <div>
                        <LinkTo
                          to={`/contracts/${contract.id}/subcontract/${subcontract.id}`}
                          state={{
                            tab: ContractReadViewTabs.tasks,
                          }}
                        >
                          {subcontract.name}
                        </LinkTo>
                        <TextSmall>{subcontract.description}</TextSmall>
                      </div>
                      {subcontract.notificationsLength ? (
                        <Warning color="error" sx={{ mr: 4 }} />
                      ) : null}
                    </TableCell>
                    <TableCell>
                      <ProgressBar
                        total={subcontract.value}
                        claimed={subcontract.claimed}
                        approved={subcontract.approved}
                        paid={subcontract.paid}
                        valueType={subcontract.valueType}
                        unApprovedVariation={
                          subcontract.variationUnapprovedTotalValue
                        }
                        approvedVariation={subcontract.variationTotalValue}
                      />
                    </TableCell>
                    <TableCell>
                      {formatAmountByType(
                        subcontract.claimed,
                        subcontract.valueType
                      )}
                    </TableCell>
                  </TableRow>
                )
              )}
            </TableBody>
          </StyledTable>
          <br />
          {user && isContractor(user, contract) && contract.projectId && (
            <AddContractDialog
              projectId={contract.projectId}
              reloadData={reloadData}
              buttonTitle={'Add New Subcontract'}
              parentContractId={contract.id}
            />
          )}
        </>
      )}
    </>
  );
}

export { SubContractTab };
