import { getContract, VisibilitySetting } from '../../../../../common/api';
import { ICheckbox } from '../main-visibility/checkboxesData';

export const getVisibilitySetting = (
  visibilitySettings: Array<VisibilitySetting> | undefined,
  name: string
): boolean => {
  return !!visibilitySettings?.find(setting => setting.name === name)?.isOn;
};

export const getLevel = (name: string, dataRange: Array<ICheckbox>) => {
  const checkbox = dataRange.find(checkbox => checkbox.name === name);
  return checkbox ? checkbox.level ?? 0 : 0;
};

export const findHighestLevelParent = (
  checkboxStates: Array<VisibilitySetting>,
  dataRange: Array<ICheckbox>
) => {
  const selectedParents = checkboxStates.filter(checkbox =>
    isChecked(checkbox.name, checkboxStates)
  );
  return Math.max(
    ...selectedParents.map(checkbox => getLevel(checkbox.name, dataRange)),
    0
  );
};

export const isChecked = (
  name: string | undefined,
  checkboxStates: Array<VisibilitySetting>
) => {
  const setting = checkboxStates.find(s => s.name === name);
  return setting ? setting.isOn : false;
};

export const calculateMaxSelectedLevel = (
  rows: ICheckbox[],
  checkboxStates: VisibilitySetting[]
) => {
  return rows.reduce((maxLevel, checkbox) => {
    const parentState = checkboxStates.find(
      state => state.name === checkbox.name
    );
    if (parentState?.isOn && checkbox.level) {
      return Math.max(maxLevel, checkbox.level);
    }
    return maxLevel;
  }, 0);
};

export const getParentContractVisibilitySettings = async (
  contractId: string | undefined
) => {
  if (!contractId) {
    return [];
  }

  const parentContract = await getContract(contractId);
  return parentContract.visibilitySettings;
};
