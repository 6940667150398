import { ClaimType, DOLLAR, PERCENT, ValueType } from '../api';

const format = new Intl.NumberFormat('en-EN', {
  currency: 'AUD',
  style: 'currency',
  currencyDisplay: 'narrowSymbol',
  maximumFractionDigits: 0,
});

function formatCurrency(value: number) {
  return format.format(value);
}

function formatPercentageValue(dividend: number, divisor: number) {
  if (!divisor) return '0%';
  return `${Math.round((dividend / divisor) * 10000) / 100}%`;
}

function formatAmountByType(value: number, type: ValueType) {
  switch (type) {
    case PERCENT:
      return `${Math.round(value)}%`;
    case DOLLAR:
      return formatCurrency(Math.round(value));
  }
}

function formatAmountByClaimType(value: number, type: ClaimType) {
  switch (type) {
    case ClaimType.percent:
      return `${value}%`;
    case ClaimType.value:
      return formatCurrency(Math.round(value));
  }
}

function formatAmountPercentInValue(
  value: number,
  type: ClaimType,
  taskValue: number
) {
  switch (type) {
    case ClaimType.percent:
      const newValue = formatCurrency(Math.round((taskValue * value) / 100));
      return `${newValue} (${value}%)`;
    case ClaimType.value:
      const percent = Math.round((value / taskValue) * 100);
      return `${formatCurrency(Math.round(value))} (${percent}%)`;
  }
}

export {
  formatCurrency,
  formatAmountByType,
  formatAmountByClaimType,
  formatAmountPercentInValue,
  formatPercentageValue,
};
