import React from 'react';
import { PriorityType } from './types';
import { ChipAlert, ChipDanger, ChipOk } from '../../../../styles/chips';

interface Props {
  type: typeof PriorityType[keyof typeof PriorityType];
}

function ChipPriority(props: Props) {
  const { type } = props;

  if (type === PriorityType.URGENT) {
    return <ChipDanger label="Urgent" />;
  }

  if (type === PriorityType.MEDIUM) {
    return <ChipAlert label="Medium" />;
  }

  return <ChipOk label="Low" />;
}

export { ChipPriority };
