import React, { useCallback } from 'react';
import { Box } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import { LoadingButton } from '@mui/lab';

import { AddContractStep3 } from '../../../project-page/components/AddContractStep3';
import {
  Contract,
  updateContract,
  UpdateContractPayload,
} from '../../../../common/api';

import {
  DEFECT_LIABILITY_NUMBER,
  DEFECT_LIABILITY_TERM,
  RETENTION_BASIS,
  RETENTION_INITIAL,
  RETENTION_INITIAL_RELEASE_AMOUNT,
  RETENTION_MAX,
  RETENTION_MINIMAL_APPROVAL,
  VARIATION_RETENTION_BASIS,
  VARIATION_RETENTION_INITIAL,
  VARIATION_RETENTION_MAX,
} from '../../../project-page/components/AddContract.constants';
import { RententionBasis } from '../../../../common/api/contract-types';
import { useMutation } from '@tanstack/react-query';

interface FormData {
  [DEFECT_LIABILITY_NUMBER]: string | null;
  [DEFECT_LIABILITY_TERM]: string | null;
  [RETENTION_BASIS]: RententionBasis;
  [RETENTION_MAX]?: string | null;
  [RETENTION_INITIAL]?: string | null;
  [VARIATION_RETENTION_BASIS]: RententionBasis;
  [VARIATION_RETENTION_MAX]?: string | null;
  [VARIATION_RETENTION_INITIAL]?: string | null;
  [RETENTION_MINIMAL_APPROVAL]: string | null;
  [RETENTION_INITIAL_RELEASE_AMOUNT]: string | null;
}

type Props = {
  contract: Contract;
  isDisabled: boolean;
  reloadData: () => void;
  handleClose: () => void;
};

function RetentionSettings({
  contract,
  isDisabled,
  reloadData,
  handleClose,
}: Props) {
  const formMethods = useForm<FormData>({
    defaultValues: {
      [DEFECT_LIABILITY_NUMBER]: contract.defectLiabilityNum?.toString() || '',
      [DEFECT_LIABILITY_TERM]: contract.defectLiabilityTerm || '',
      [RETENTION_BASIS]: contract.retentionBasis || RententionBasis.None,
      [RETENTION_INITIAL]: contract.retentionInitial?.toString() || null,
      [RETENTION_MAX]: contract.retentionMax?.toString() || null,
      [VARIATION_RETENTION_BASIS]:
        contract.variationRetentionBasis || RententionBasis.None,
      [VARIATION_RETENTION_MAX]:
        contract.variationRetentionMax?.toString() || null,
      [VARIATION_RETENTION_INITIAL]:
        contract.variationRetentionInitial?.toString() || null,
      [RETENTION_MINIMAL_APPROVAL]:
        contract.minimalApproval?.toString() || null,
      [RETENTION_INITIAL_RELEASE_AMOUNT]:
        contract.minimalReleaseAmount?.toString() || null,
    },
  });
  const { handleSubmit } = formMethods;

  const { mutateAsync: mutateContract, isLoading } = useMutation(
    (payload: UpdateContractPayload) => updateContract(contract.id, payload),
    {
      onSuccess: () => {
        reloadData();
        handleClose();
      },
      onError: error => {
        console.error('Error updating contract:', error);
      },
    }
  );

  const onSubmit = useCallback(
    (data: FormData) => {
      mutateContract({
        retentionMax: Number(data[RETENTION_MAX]),
        retentionInitial: Number(data[RETENTION_INITIAL]),
        retentionBasis: data[RETENTION_BASIS],
        variationRetentionMax: Number(data[VARIATION_RETENTION_MAX]),
        variationRetentionInitial: Number(data[VARIATION_RETENTION_INITIAL]),
        variationRetentionBasis: data[VARIATION_RETENTION_BASIS],
        defectLiabilityNum: Number(data[DEFECT_LIABILITY_NUMBER]),
        defectLiabilityTerm: data[DEFECT_LIABILITY_TERM] || undefined,
        minimalApproval: Number(data[RETENTION_MINIMAL_APPROVAL]),
        minimalReleaseAmount: Number(data[RETENTION_INITIAL_RELEASE_AMOUNT]),
      });
    },
    [mutateContract]
  );

  return (
    <Box sx={{ width: '100%', p: 3 }}>
      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <AddContractStep3 isDisabled={isDisabled} />
          {!isDisabled && (
            <Box sx={{ display: 'flex' }}>
              <LoadingButton
                type="submit"
                variant="contained"
                loading={isLoading}
              >
                Save
              </LoadingButton>
            </Box>
          )}
        </form>
      </FormProvider>
    </Box>
  );
}

export default RetentionSettings;
