import { Table } from '@mui/material';
import { styled } from '@mui/material/styles';
import { grey200 } from '../../../../styles/colours';

export const VisibilityTable = styled(Table)({
  borderTop: `1px solid ${grey200}`,
  borderLeft: `1px solid ${grey200}`,
  borderRight: `1px solid ${grey200}`,
  borderCollapse: 'collapse',
  borderSpacing: '1px',
  marginBottom: '32px',
  td: {
    padding: '4px',
  },
});

export const VisibilityRow = styled(Table)({
  border: 'none',
  borderCollapse: 'collapse',
  borderSpacing: '1px',

  td: {
    border: 'none',
    padding: '0px 4px',
    ':first-of-type': {
      width: '40px',
    },
  },
});
