import { AddAdjustClaimItemPayload, Adjust } from './types';
import { axios } from './axios';

async function adjustCreate(
  id: string,
  payload: AddAdjustClaimItemPayload
): Promise<Adjust> {
  const resp = await axios.post(`/claim-item/${id}/adjust`, payload);
  return resp.data;
}

async function adjustUpdate(
  adjustId: string,
  id: string,
  payload: AddAdjustClaimItemPayload
): Promise<Adjust> {
  const resp = await axios.patch(
    `/claim-item/${id}/adjust/${adjustId}`,
    payload
  );
  return resp.data;
}

async function adjustDelete(adjustId: string, id: string): Promise<number> {
  const resp = await axios.delete(`/claim-item/${id}/adjust/${adjustId}`);
  return resp.data;
}

export { adjustCreate, adjustDelete, adjustUpdate };
