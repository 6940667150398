import React, { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { SelectChangeEvent } from '@mui/material';

// API
import { Notification } from '../../../common/api';
import {
  listVariationsReceived,
  listVariationsReceivedContracts,
  listVariationsReceivedSummary,
} from '../../../common/api/variations';

// Components
import FilterSelect from './FilterSelect';
import { AnticipatedVariationsTable } from './variationsTables/AnticipatedVariationsTable';
import { SubmittedVariationsTable } from './variationsTables/SubmittedVariationsTable';
import { SummaryTable } from './variationsTables/SummaryTable';
import { useListPagination } from '../../../common/hooks/useListPagination';

// Styles
import { SectionHeading } from '../../../styles';
import { SectionHeadingWithFilter } from '../../../styles/text';

type Props = {
  isShowAVTable?: boolean;
  contractId: string;
  contingencyBudget: string;
  handleClick?: (id: string) => void;
  notifications?: Array<Notification>;
  handleCheckbox?: (notification: Notification) => Promise<void>;
};

function VariationsReceivedTab({
  isShowAVTable,
  contractId,
  contingencyBudget,
  handleClick,
  notifications,
  handleCheckbox,
}: Props) {
  const { rowsPerPage, skip } = useListPagination();

  // State
  const [submittedFilterValues, setSubmittedFilterValues] = useState<string[]>(
    []
  );
  const [anticipatedFilterValues, setAnticipatedFilterValues] = useState<
    string[]
  >([]);

  // Queries
  const { data } = useQuery(['variationsReceivedSummary', contractId], () =>
    listVariationsReceivedSummary({ contractId })
  );

  const { data: submittedFilterData = [] } = useQuery(
    ['submittedReceivedContracts', contractId],
    () => listVariationsReceivedContracts({ contractId }, { type: 'submitted' })
  );

  const { data: anticipatedFilterData = [] } = useQuery(
    ['anticipatedReceivedContracts', contractId],
    () =>
      listVariationsReceivedContracts({ contractId }, { type: 'anticipated' })
  );

  // Get Data
  const { data: submittedData } = useQuery(
    ['submittedVariationsReceived', contractId, submittedFilterValues],
    () =>
      listVariationsReceived(
        { contractId, subContractIds: submittedFilterValues },
        {
          skip: skip,
          take: rowsPerPage,
          type: 'submitted',
        }
      )
  );

  const { data: anticipatedData } = useQuery(
    ['anticipatedVariationsReceived', contractId, anticipatedFilterValues],
    () =>
      listVariationsReceived(
        { contractId, subContractIds: anticipatedFilterValues },
        {
          skip: skip,
          take: rowsPerPage,
          type: 'anticipated',
        }
      )
  );

  // Handlers

  const handleSubmittedFilterChange = (
    event: SelectChangeEvent<typeof submittedFilterValues>
  ) => {
    const {
      target: { value },
    } = event;
    setSubmittedFilterValues(
      typeof value === 'string' ? value.split(',') : value
    );
  };

  const handleAnticipatedFilterChange = (
    event: SelectChangeEvent<typeof submittedFilterValues>
  ) => {
    const {
      target: { value },
    } = event;
    setAnticipatedFilterValues(
      typeof value === 'string' ? value.split(',') : value
    );
  };

  return (
    <>
      <section>
        <SummaryTable data={data} contingencyBudget={contingencyBudget} />
      </section>
      <section>
        <SectionHeadingWithFilter>
          <SectionHeading>Submitted Variation</SectionHeading>
          {submittedFilterData.length > 1 && (
            <FilterSelect
              data={submittedFilterData}
              label="Sub-Contract Filter"
              id="submitted-contract-select"
              value={submittedFilterValues}
              onChange={handleSubmittedFilterChange}
            />
          )}
        </SectionHeadingWithFilter>
        <SubmittedVariationsTable
          contingencyBudget={contingencyBudget}
          contractId={contractId}
          data={submittedData}
          notifications={notifications}
          onClick={handleClick}
          onCheckboxClick={handleCheckbox}
        />
      </section>
      {isShowAVTable && (
        <section>
          <SectionHeadingWithFilter>
            <SectionHeading>Anticipated Variation</SectionHeading>
            {anticipatedFilterData.length > 1 && (
              <FilterSelect
                data={anticipatedFilterData}
                label="Sub-Contract Filter"
                id="submitted-contract-select"
                value={anticipatedFilterValues}
                onChange={handleAnticipatedFilterChange}
              />
            )}
          </SectionHeadingWithFilter>
          <AnticipatedVariationsTable
            contingencyBudget={contingencyBudget}
            contractId={contractId}
            data={anticipatedData}
            notifications={notifications}
            onClick={handleClick}
          />
        </section>
      )}
    </>
  );
}

export { VariationsReceivedTab };
