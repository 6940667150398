import { useMemo } from 'react';
import orderBy from 'lodash/orderBy';
import groupBy from 'lodash/groupBy';

import { useQuery } from '@tanstack/react-query';
import { Metric } from '../../common/api/metric-types';
import { getMetricsList } from '../../common/api/metrics';

export const useReportMetrics = () => {
  const { data, isLoading: isMetricListLoading } = useQuery<Array<Metric>>(
    ['metricList'],
    () => {
      return getMetricsList();
    }
  );

  const metrics = useMemo(() => {
    return orderBy(data?.filter(d => d.group !== 'General') || [], 'group');
  }, [data]);

  const groupedMetrics = useMemo(() => {
    const flatList: Array<string | Metric> = [];
    const groupedMetrics = groupBy(metrics, 'group');
    Object.keys(groupedMetrics).forEach(key => {
      flatList.push(key);
      groupedMetrics[key].forEach(m => {
        flatList.push(m);
      });
    });
    return flatList;
  }, [metrics]);

  return {
    data,
    isMetricListLoading,
    metrics,
    groupedMetrics,
  };
};
