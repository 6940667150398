import { axios } from './axios';
import { Role, UserWithId } from './types';

async function getUsersByAccountId(
  accountId: string
): Promise<Array<UserWithId>> {
  const resp = await axios.get(`accounts/${accountId}/users`);
  return resp.data;
}

async function deleteUser(accountId: string, userId: string) {
  await axios.delete(`accounts/${accountId}/users/${userId}`);
}

export type UpdateUserPayload = {
  id: string;
  email: string;
  accountId: string;
  firstName?: string;
  lastName?: string;
  phone?: string;
  avatarKey?: string;
  passwordExisting?: string;
  passwordNew?: string;
  passwordConfirmNew?: string;
};

async function updateUser(payload: UpdateUserPayload) {
  return await axios.patch(
    `/accounts/${payload.accountId}/users/${payload.id}`,
    payload
  );
}

export type UpdateRolesPayload = {
  accountId: string;
  roles: Array<{
    userId: string;
    roles: Array<Role>;
  }>;
};

async function updateRoles(payload: UpdateRolesPayload) {
  return await axios.patch(`/accounts/${payload.accountId}/users/roles`, {
    userRole: payload.roles,
  });
}

export { getUsersByAccountId, updateUser, updateRoles, deleteUser };
