import React from 'react';
import { TableBody, Typography } from '@mui/material';
import { Task, ValueType } from '../../../common/api';
import { SubtaskTableRow } from './SubtaskTableRow';
import { SortedTableHead } from '../../../components/sorted-table-head';
import {
  HeadCell,
  useSortedTable,
} from '../../../components/sorted-table-head/SortedTable.hooks';
import { StyledTable } from '../../../styles';
import { stableSort } from '../../../common/sort/stableSort';
import { getComparator } from '../../../common/sort/getComparator';
import { getCostToComplete } from '../../contract-page/views/contract-claim-view/components/CurrentClaimTab.utils';

export interface SubTaskRow {
  id: string;
  identifier: string;
  description: string;
  task: string;
  paid: number;
  value: number;
  subContractDescription: string;
  subContractRef: string;
  claimed: number;
  approved: number;
  parentTaskId: string;
  contractId: string;
  valueType: ValueType;
  approvedVariation: string;
  unApprovedVariation: string;
}

const headCells = (shouldHideActions: boolean): Array<HeadCell> => {
  return [
    {
      id: 'identifier',
      numeric: false,
      disablePadding: false,
      label: 'ID',
    },
    {
      id: 'description',
      numeric: false,
      disablePadding: false,
      label: 'Sub-task description',
    },
    {
      id: 'subContractDescription',
      numeric: true,
      disablePadding: false,
      label: 'Sub-contract',
    },
    {
      id: 'progress',
      numeric: true,
      disablePadding: false,
      label: 'Progress',
    },
    {
      id: 'value',
      numeric: true,
      disablePadding: false,
      label: 'Value',
    },
    {
      id: 'actions',
      numeric: false,
      disablePadding: false,
      label: '',
      isHidden: shouldHideActions,
    },
  ];
};
interface Props {
  tasks: Array<Task>;
  reloadData: () => void;
  closeSubcontractDialog: () => void;
  contractId: string;
  isMainCustomer: boolean;
}

function SubTasksTable(props: Props) {
  const {
    tasks,
    contractId,
    reloadData,
    closeSubcontractDialog,
    isMainCustomer,
  } = props;
  const { order, orderBy, handleRequestSort } = useSortedTable('identifier');
  const row = tasks.map(task => {
    const costToComplete = getCostToComplete(
      task.value,
      task.paid,
      task.approved,
      task.claimed
    );
    const costToCompletePercentsValue = Math.floor(
      (costToComplete / Number(task.value) || 0) * 100
    );

    return {
      id: task.id,
      identifier: task.identifier || '',
      description: task.description,
      task: task.contractId,
      paid: Number(task.paid),
      progress: costToCompletePercentsValue,
      value: Number(task.value),
      subContractDescription: task.contract?.description || '',
      subContractRef: task.contract?.id || '',
      claimed: Number(task.claimed),
      contractId: task.contract?.id || '',
      parentTaskId: task.parentTaskId || '',
      approved: Number(task.approved),
      valueType: task.valueType,
      approvedVariation: task.totalApprovedVariation,
      unApprovedVariation: task.totalUnapprovedVariation,
    };
  });

  if (tasks && tasks.length > 0) {
    return (
      <StyledTable>
        <SortedTableHead
          order={order}
          orderBy={orderBy}
          onRequestSort={handleRequestSort}
          headCells={headCells(!isMainCustomer)}
        />
        <TableBody>
          {stableSort(row, getComparator(order, orderBy)).map(row => (
            <SubtaskTableRow
              key={row.id}
              task={row}
              tasks={tasks}
              contractId={contractId}
              isMainCustomer={isMainCustomer}
              reloadData={reloadData}
              closeSubcontractDialog={closeSubcontractDialog}
            />
          ))}
        </TableBody>
      </StyledTable>
    );
  } else {
    return <Typography sx={{ mb: 2 }}>No subtasks</Typography>;
  }
}

export { SubTasksTable };
