import React, {
  MouseEventHandler,
  useState,
  useCallback,
  useEffect,
} from 'react';
import { Controller, useForm, useWatch } from 'react-hook-form';
import {
  Box,
  Checkbox,
  FormControl,
  IconButton,
  Select,
  TableCell,
  TextField,
  Typography,
} from '@mui/material';
import DoneIcon from '@mui/icons-material/DoneOutline';

import {
  ContractStatus,
  Task,
  updateTask,
  VariationStatus,
} from '../../../../common/api';

// Components
import { TaskClaimItemRowModifiedValue } from '../../views/contract-claim-view/components/TaskClaimItemRowModifiedValue';

// Styles
import { StyledTasksTableRow } from '../../../../styles/tableRow';

// Utils
import { formatAmountByType } from '../../../../common/format';
import { TaskRowDataWithStatus } from '../../utils/taskTable.utils';
import { useDialogState } from '../../../../components/dialog/dialog.hooks';
import { VariationAssignParent } from '../../../variations/VariationAssignParent';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';

interface Form {
  id: string;
  description: string;
  value: number;
  type: string;
  unitOfMeasure: string;
  quantity: number;
  rate: number;
  excludeFromRetention: boolean;
}

type ComparedFields =
  | 'identifier'
  | 'description'
  | 'value'
  | 'type'
  | 'unitOfMeasure'
  | 'quantity'
  | 'rate';
const typeLabels: Record<string, string> = {
  progress: 'Progress (%)',
  milestone: 'Milestone',
  scheduleOfRates: 'Schedule of Rates',
};

interface Props {
  status: ContractStatus;
  isItemSelected: (id: string) => boolean;
  isSubcontract?: boolean;
  isCanView?: boolean;
  contractId: string;
  parentContractId: string;
  reloadContract: () => void;
  reloadData: () => void;
  selected: boolean;
  task: TaskRowDataWithStatus;
  tasks: Task[];
  taskHistory: Task | null;
  onCheckboxClick: (id: string) => void;
  onClick: MouseEventHandler;
  handleDeleteTask?: (selectedTasks: Array<string>) => void;
  isCustomer: boolean;
}

function EditableTaskRow({
  task,
  tasks,
  taskHistory,
  selected,
  contractId,
  parentContractId,
  isItemSelected,
  onCheckboxClick,
  isSubcontract,
  isCanView,
  onClick,
  status,
  reloadContract,
  reloadData,
  handleDeleteTask = () => {},
  isCustomer,
}: Props) {
  const isNewTask = Boolean(task.isNew === 'true');
  const isApprovedVariation = task.variationStatus === VariationStatus.Approved;
  const [isEditable, setEditable] = useState(false);
  const [taskIdToAssignTo, setTaskIdToAssignTo] = useState<string>('');
  const [isShowScheduleFields, setIsShowScheduleFields] = useState(
    task.type === 'scheduleOfRates'
  );

  const checkIsEqual = useCallback(
    (attribute: ComparedFields) => {
      if (!taskHistory) return true;
      if (typeof task[attribute] === 'number') {
        return task[attribute] === Number(taskHistory[attribute]);
      }
      return task[attribute] === taskHistory[attribute];
    },
    [taskHistory, task]
  );

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors, isValid },
  } = useForm<Form>({
    mode: 'onChange',
  });

  const isDraft = status === ContractStatus.Draft;
  const isAwaiting = status === ContractStatus.AwaitingAcceptance;

  const onSubmit = useCallback(
    async (form: Form) => {
      const {
        identifier,
        description,
        value,
        type,
        unitOfMeasure,
        quantity,
        rate,
        // @ts-ignore
        excludeFromRetention,
      } = task;
      const {
        id: formId,
        description: formDescription,
        value: formValue,
        type: formType,
        unitOfMeasure: formUnitOfMeasure,
        quantity: formQuantity,
        rate: formRate,
        excludeFromRetention: formExcludeFromRetention,
      } = form;

      if (
        formId === identifier &&
        formDescription === description &&
        Number(formValue) === value &&
        formType === type &&
        formUnitOfMeasure === unitOfMeasure &&
        Number(formQuantity) === quantity &&
        Number(formRate) === rate &&
        formExcludeFromRetention === excludeFromRetention
      ) {
        return;
      }

      await updateTask(contractId, task.id, {
        description: form.description,
        identifier: form.id,
        value: form.value,
        type: form.type,
        unitOfMeasure: isShowScheduleFields ? form.unitOfMeasure : '',
        quantity: isShowScheduleFields ? Number(form.quantity) : 0,
        rate: isShowScheduleFields ? Number(form.rate) : 0,
        excludeFromRetention: form.excludeFromRetention,
      });
      reloadContract();
      reloadData();
    },
    [task, contractId, reloadContract, reloadData, isShowScheduleFields]
  );

  const handleAssignToTask = useCallback(
    async (currentTask: Task, parentTaskID: string | null) => {
      await updateTask(contractId, currentTask.id, {
        description: currentTask.description,
        identifier: currentTask.identifier,
        value: Number(currentTask.value),
        parentTaskId: parentTaskID,
        type: currentTask.type,
        unitOfMeasure: currentTask.unitOfMeasure,
        quantity: currentTask.quantity,
        rate: currentTask.rate,
        excludeFromRetention: currentTask.excludeFromRetention,
      });

      reloadContract();
      reloadData();
    },
    [contractId, reloadContract, reloadData]
  );

  const handleSaveTask = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    if (isEditable && isValid) {
      handleSubmit(onSubmit)();
      setEditable(false);
    }
  };

  const {
    isVisible: isSearchVisible,
    close: closeSearch,
    open: openSearch,
  } = useDialogState();

  const handleTaskTypeChange = useCallback(
    async (value: string) => {
      setIsShowScheduleFields(value === 'scheduleOfRates');
    },
    [setIsShowScheduleFields]
  );

  const quantity = useWatch({
    control,
    name: 'quantity',
    defaultValue: 0,
  });

  const rate = useWatch({
    control,
    name: 'rate',
    defaultValue: 0,
  });

  useEffect(() => {
    if (quantity && rate) {
      const calculatedAmount = quantity * rate;
      setValue('value', Number(calculatedAmount.toFixed(0)));
    }
  }, [quantity, rate, setValue]);

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const isOpenActionButtons = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  const handleEdit = (event: React.MouseEvent<HTMLLIElement>) => {
    event.stopPropagation();
    setAnchorEl(null);

    if (!isEditable) {
      setEditable(true);
    }
  };

  const handleDelete = (event: React.MouseEvent<HTMLLIElement>) => {
    event.stopPropagation();
    setAnchorEl(null);

    handleDeleteTask([task.id]);
  };

  const handleAssign = (
    event: React.MouseEvent<HTMLLIElement>,
    taskId: string
  ) => {
    event.stopPropagation();
    setAnchorEl(null);

    setTaskIdToAssignTo(taskId);
    openSearch();
  };

  const handleUnassign = (
    event: React.MouseEvent<HTMLLIElement>,
    taskId: string
  ) => {
    event.stopPropagation();
    setAnchorEl(null);

    const currentTask = tasks.find(t => t.id === taskId);
    if (currentTask) {
      handleAssignToTask(currentTask, null);
    }
  };

  return (
    <>
      <StyledTasksTableRow
        key={task.id}
        status={task.status}
        isEditable={isEditable}
        onClick={!isEditable ? onClick : () => {}}
        selected={selected}
      >
        {!isAwaiting && (
          <TableCell style={{ width: '10%' }}>
            <Checkbox
              checked={isItemSelected(task.id)}
              onClick={event => {
                event.stopPropagation();
                onCheckboxClick(task.id);
              }}
            />
          </TableCell>
        )}
        <TableCell>
          {isEditable ? (
            <Controller
              name="id"
              control={control}
              defaultValue={String(task.identifier)}
              rules={{
                required: 'ID is required',
              }}
              render={({ field }) => (
                <TextField
                  {...field}
                  size="small"
                  sx={{ width: '80px' }}
                  variant="standard"
                  value={field.value || ''}
                  required
                  fullWidth
                  disabled={true}
                  error={!!errors.id}
                  helperText={errors.id?.message?.toString()}
                />
              )}
            />
          ) : (
            <Box sx={{ position: 'relative' }}>
              <Typography variant={'body2'}>{task.identifier || ''}</Typography>
              {!checkIsEqual('identifier') && (
                <div style={styles.History}>{taskHistory?.identifier}</div>
              )}
            </Box>
          )}
        </TableCell>
        <TableCell>
          {isEditable ? (
            <Controller
              name="description"
              control={control}
              defaultValue={task.description}
              rules={{
                required: 'Task Name is required',
              }}
              render={({ field }) => (
                <TextField
                  {...field}
                  size="small"
                  color="success"
                  value={field.value || ''}
                  variant="standard"
                  required
                  fullWidth
                  autoFocus
                  error={!!errors.description}
                  helperText={errors.description?.message?.toString()}
                />
              )}
            />
          ) : (
            <Box sx={{ position: 'relative' }}>
              <Typography variant={'body2'}>{task.description}</Typography>
              {!checkIsEqual('description') && (
                <div style={styles.History}>{taskHistory?.description}</div>
              )}
            </Box>
          )}
        </TableCell>

        <TableCell>
          {isEditable ? (
            <Controller
              name="type"
              defaultValue={task.type}
              control={control}
              rules={{
                required: 'Task Type is required',
              }}
              render={({ field }) => (
                <FormControl size="small" sx={{ minWidth: 150 }}>
                  <Select
                    {...field}
                    size="small"
                    color="success"
                    variant="standard"
                    fullWidth
                    required
                    error={!!errors.type}
                    onChange={e => {
                      field.onChange(e);
                      handleTaskTypeChange(e.target.value);
                    }}
                  >
                    <MenuItem value="progress">Progress (%)</MenuItem>
                    <MenuItem value="milestone">Milestone</MenuItem>
                    <MenuItem value="scheduleOfRates">
                      Schedule of rates
                    </MenuItem>
                  </Select>
                </FormControl>
              )}
            />
          ) : (
            <Box sx={{ position: 'relative', whiteSpace: 'nowrap' }}>
              <Typography variant={'body2'}>
                {typeLabels[task.type] || task.type}
              </Typography>
              {!checkIsEqual('type') && (
                <div style={styles.History}>
                  {taskHistory?.type
                    ? typeLabels[taskHistory?.type]
                    : taskHistory?.type}
                </div>
              )}
            </Box>
          )}
        </TableCell>

        <TableCell>
          {isShowScheduleFields &&
            (isEditable ? (
              <Controller
                name="unitOfMeasure"
                defaultValue={task.unitOfMeasure}
                control={control}
                rules={{ required: 'Unit of Measure is required' }}
                render={({ field }) => (
                  <FormControl size="small" sx={{ minWidth: 150 }}>
                    <Select
                      {...field}
                      size="small"
                      color="success"
                      variant="standard"
                      fullWidth
                      required
                      error={!!errors.unitOfMeasure}
                    >
                      <MenuItem value="m">m</MenuItem>
                      <MenuItem value="m&sup2;">m&sup2;</MenuItem>
                      <MenuItem value="hours">hours</MenuItem>
                      <MenuItem value="kg">kg</MenuItem>
                      <MenuItem value="tonne">tonne</MenuItem>
                      <MenuItem value="others">Others</MenuItem>
                    </Select>
                  </FormControl>
                )}
              />
            ) : (
              <Box sx={{ position: 'relative' }}>
                <Typography variant={'body2'}>{task.unitOfMeasure}</Typography>
                {!checkIsEqual('unitOfMeasure') && (
                  <div style={styles.History}>{taskHistory?.unitOfMeasure}</div>
                )}
              </Box>
            ))}
        </TableCell>

        <TableCell>
          {isShowScheduleFields &&
            (isEditable ? (
              <Controller
                name="quantity"
                defaultValue={task.quantity ? task.quantity : 0}
                control={control}
                rules={{
                  required: 'QTY is required',
                  pattern: {
                    value: /^[0-9]*$/,
                    message: 'Value should be a prime number',
                  },
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    size="small"
                    color="success"
                    variant="standard"
                    fullWidth
                    error={!!errors.quantity}
                    helperText={errors.quantity?.message}
                  />
                )}
              />
            ) : (
              <Box sx={{ position: 'relative' }}>
                <Typography variant={'body2'}>
                  {task.quantity ? task.quantity : '-'}
                </Typography>
                {!checkIsEqual('quantity') && (
                  <div style={styles.History}>{taskHistory?.quantity}</div>
                )}
              </Box>
            ))}
        </TableCell>

        <TableCell>
          {isShowScheduleFields &&
            (isEditable ? (
              <Controller
                name="rate"
                defaultValue={task.rate ? task.rate : 0}
                control={control}
                rules={{
                  required: 'Rate is required',
                  pattern: {
                    value: /^[0-9]*$/,
                    message: 'Value should be a valid rate',
                  },
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    size="small"
                    color="success"
                    variant="standard"
                    fullWidth
                    error={!!errors.rate}
                    helperText={errors.rate?.message}
                  />
                )}
              />
            ) : (
              <Box sx={{ position: 'relative' }}>
                <Typography variant={'body2'}>
                  {task.rate ? task.rate : '-'}
                </Typography>
                {!checkIsEqual('rate') && (
                  <div style={styles.History}>{taskHistory?.rate}</div>
                )}
              </Box>
            ))}
        </TableCell>

        <TableCell>
          {task.type === 'scheduleOfRates' ? (
            <Box>
              <Typography variant={'body2'}>
                {task.quantity} {task.unitOfMeasure}
              </Typography>
              <Typography variant={'body2'}>
                ${task.rate} per {task.unitOfMeasure}
              </Typography>
            </Box>
          ) : null}
        </TableCell>

        {!isCanView && (
          <TableCell>
            {isEditable ? (
              <Controller
                name="value"
                control={control}
                defaultValue={task.value}
                rules={{
                  required: 'Value is required',
                  pattern: {
                    value: /^[0-9]*$/,
                    message: 'Value should be a number',
                  },
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    disabled={isShowScheduleFields}
                    size="small"
                    color="success"
                    value={
                      isShowScheduleFields
                        ? String(quantity * rate)
                        : field.value || ''
                    }
                    variant="standard"
                    required
                    fullWidth
                    autoFocus
                    error={!!errors.value}
                    helperText={errors.value?.message?.toString()}
                  />
                )}
              />
            ) : isNewTask && !isApprovedVariation ? (
              formatAmountByType(
                Number(task.unapprovedVariation),
                task.valueType
              )
            ) : Number(task.approvedVariation) > 0 ? (
              <TaskClaimItemRowModifiedValue
                taskValue={task.value}
                taskOriginalValue={task.originalValue}
              />
            ) : (
              <Box sx={{ position: 'relative' }}>
                <Typography variant={'body2'}>
                  {formatAmountByType(Number(task.value), task.valueType)}
                </Typography>
                {!checkIsEqual('value') && (
                  <div style={styles.History}>
                    {formatAmountByType(
                      Number(taskHistory?.value),
                      task.valueType
                    )}
                  </div>
                )}
              </Box>
            )}
          </TableCell>
        )}

        <TableCell width={'30%'}>
          <Box
            sx={{
              position: 'relative',
              display: 'inline-flex',
              border:
                taskHistory?.excludeFromRetention &&
                (task as unknown as Task).excludeFromRetention !==
                  taskHistory?.excludeFromRetention
                  ? '1px solid red'
                  : '',
            }}
          >
            <Controller
              name="excludeFromRetention"
              control={control}
              defaultValue={(task as unknown as Task).excludeFromRetention}
              render={({ field }) => (
                <Checkbox
                  {...field}
                  checked={field.value}
                  onChange={e => field.onChange(e.target.checked)}
                  disabled={!isEditable}
                />
              )}
            />
          </Box>
        </TableCell>

        {isDraft && (
          <>
            <TableCell sx={{ textAlign: 'center' }}>
              {isEditable ? (
                <IconButton onClick={handleSaveTask} disabled={!isValid}>
                  <DoneIcon color="success" />
                </IconButton>
              ) : (
                <IconButton onClick={handleClick}>
                  <MoreVertIcon />
                </IconButton>
              )}

              <Menu
                anchorEl={anchorEl}
                open={isOpenActionButtons}
                onClose={handleClose}
                PaperProps={{
                  style: {
                    width: '150px',
                  },
                }}
              >
                <MenuItem onClick={handleEdit}>Edit</MenuItem>
                <MenuItem onClick={handleDelete}>Delete</MenuItem>
                {isSubcontract &&
                  isCustomer && [
                    <MenuItem
                      key="assign"
                      onClick={event => handleAssign(event, task.id)}
                    >
                      {task.parentTaskId ? 'Re-Assign' : 'Assign'}
                    </MenuItem>,
                    task.parentTaskId && (
                      <MenuItem
                        key="unassign"
                        onClick={event => handleUnassign(event, task.id)}
                      >
                        Un-Assign
                      </MenuItem>
                    ),
                  ]}
              </Menu>
            </TableCell>
          </>
        )}
      </StyledTasksTableRow>
      {isSearchVisible && parentContractId && (
        <VariationAssignParent
          contractId={parentContractId}
          variationItems={tasks}
          variationItemIdToAssignTo={taskIdToAssignTo}
          handleClose={closeSearch}
          assignToTask={taskId => {
            const currentTask = tasks.find(t => t.id === taskIdToAssignTo);
            if (currentTask && taskId) {
              handleAssignToTask(currentTask, taskId);
            }
          }}
        />
      )}
    </>
  );
}

const styles: { [key: string]: React.CSSProperties } = {
  History: {
    position: 'absolute',
    top: 18,
    fontSize: '10px',
    color: '#ed6c02',
  },
};

export { EditableTaskRow };
