import React, { useCallback } from 'react';
import { Box, Button, Stack, Typography } from '@mui/material';
import { Claim, ClaimStatus, Contract, Task } from '../../../../common/api';
import { ChipStatus } from '../../../../components/chip-status';
import { ClaimFileDrawer } from '../../../contract-page/views/contract-claim-view/components/ClaimFileDrawer';
import {
  BaseTaskGroupProps,
  TaskGroups,
} from '../../../../components/task-group/TaskGroups';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDialogState } from '../../../../components/dialog/dialog.hooks';
import { ClaimSummary } from '../../components';
import { ClaimChat } from '../../../../components/claim-chat';
import { ClaimTable } from './components/ClaimTable';
import dayjs from 'dayjs';
import { projectDate } from '../../../../common/format/formatDate';
import { useProject } from '../../../../common/hooks/useProject';

type Props = BaseTaskGroupProps & {
  claim: Claim;
  tasks?: Array<Task>;
  isLoadingClaim?: boolean;
  contract: Contract;
  reloadData: () => void;
  reloadContract: () => void;
};

function ClaimReadView(props: Props) {
  const {
    tasks,
    contract,
    reloadData,
    reloadContract,
    selectedTaskGroup,
    handleSelectedTaskGroup,
    claim,
  } = props;

  const location = useLocation();
  const navigate = useNavigate();
  const { project } = useProject();
  const previousPath = location.pathname.split('/claims/')[0];

  const {
    isVisible: isChatVisible,
    close: closeChat,
    open: openChat,
  } = useDialogState();

  const {
    isVisible: isClaimFilesVisible,
    open: openClaimFiles,
    close: closeClaimFiles,
  } = useDialogState();

  const reload = useCallback(() => {
    reloadData();
    reloadContract();
  }, [reloadData, reloadContract]);

  const handleClickBack = useCallback(() => {
    navigate(`${previousPath}`, { state: { tab: 'approvals' } });
  }, [navigate, previousPath]);

  return (
    <>
      <Box>
        <Stack gap={1} sx={{ mb: 3 }}>
          <Stack direction="row" justifyContent="space-between">
            <Stack direction="row" gap={2} alignItems="center">
              <Typography variant="h6" sx={{ fontSize: '20px' }}>
                {claim.description}
              </Typography>
              <ChipStatus
                status={claim.status}
                isCustomer
                isOverdue={
                  dayjs(claim?.paymentDueAt) < projectDate(project?.systemDate)
                }
              />
            </Stack>
            <Stack direction="row" gap={3}>
              <Button variant="outlined" onClick={handleClickBack}>
                Back
              </Button>
              <Button variant="outlined" onClick={openClaimFiles}>
                Attachments
              </Button>
              <Button variant="outlined" onClick={openChat}>
                Comments
              </Button>
              {claim && (
                <ClaimFileDrawer
                  canUpload={false}
                  canDelete={false}
                  claim={claim}
                  open={isClaimFilesVisible}
                  onClose={closeClaimFiles}
                />
              )}
            </Stack>
          </Stack>
          <Typography variant="body2" sx={{ fontSize: '14px' }}>
            {contract.contractorAccount.name}
          </Typography>
          <Typography variant="body2" sx={{ fontSize: '14px' }}>
            {contract.contractorAccount.id}
          </Typography>
        </Stack>
        <ClaimSummary
          claim={claim}
          contractPaymentTerm={contract.paymentTerm || 1}
        />
      </Box>
      <TaskGroups
        canEdit={false}
        contractId={contract.id}
        selectedTaskGroup={selectedTaskGroup}
        handleSelectedTaskGroup={handleSelectedTaskGroup}
        render={() => (
          <ClaimTable reloadData={reloadData} tasks={tasks} claim={claim} />
        )}
      />
      {isChatVisible && (
        <ClaimChat
          claim={claim}
          permissionToSend={
            claim.status !== ClaimStatus.Approved &&
            claim.status !== ClaimStatus.Paid &&
            claim.status !== ClaimStatus.Withdrawn
          }
          reloadData={reload}
          onClose={closeChat}
        />
      )}
    </>
  );
}

export { ClaimReadView };
