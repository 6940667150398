import React, { useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import DoneIcon from '@mui/icons-material/DoneOutline';
import EditIcon from '@mui/icons-material/Edit';
import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Grid,
  IconButton,
  Stack,
  TextField,
  Theme,
  Typography,
} from '@mui/material';

import {
  Account,
  Contract,
  ContractStatus,
  listAccounts,
  SummaryTaskData,
  VisibilitySetting,
  VisibilityStatus,
} from '../../../common/api';

// Components
import { ActionButtons, ActionListType } from './ActionButtons';
import { ContractDetailsInfoCard } from './ContractDetailsInfoCard';
import { ContractStatusChip } from './ContractStatusChip';
import { DetailsDialog } from './DetailsDialog';

// Styles
import { grey500 } from '../../../styles/colours';

// Utils
import { useDialogState } from '../../../components/dialog/dialog.hooks';
import { useQuery } from '@tanstack/react-query';

const CONTRACT_TITLE = 'contractTitle';

export type FormData = {
  [CONTRACT_TITLE]: string;
};

export type EditableDataType = {
  description: string;
  paymentTerm?: number;
  contingencyBudget: string;
  visibilitySettings: VisibilitySetting[];
};

interface Props {
  actionList?: ActionListType[];
  contractHistory?: Contract;
  editableData: EditableDataType;
  contract: Contract;
  summary?: SummaryTaskData;
  onUpdate: (data: EditableDataType) => void;
  reloadContract: () => void;
  onEditSave: (updatedVisibilitySettings: VisibilitySetting[]) => void;
}

function ContractDetailsEdit({
  actionList = [],
  contractHistory,
  contract,
  editableData,
  summary,
  onUpdate,
  reloadContract,
  onEditSave,
}: Props) {
  const {
    open: openDetailsDialog,
    close: closeDetailsDialog,
    isVisible: isVisibleDetailsDialog,
  } = useDialogState();

  const {
    data: accountList,
    isLoading: isAccountListLoading,
    refetch: updateAccountList,
  } = useQuery<Array<Account>>(['accounts'], () => {
    return listAccounts();
  });

  const [isDescriptionEdit, setDescriptionEdit] = useState(false);

  const hasVisibilitySettingsChanged = () => {
    if (
      contract.status === ContractStatus.AwaitingAcceptance &&
      contract.visibilitySettings
    ) {
      return contract.visibilitySettings.some(
        s => s.status === VisibilityStatus.Modified
      );
    }

    return false;
  };

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: useMemo(
      () => ({
        [CONTRACT_TITLE]: editableData.description,
      }),
      [editableData.description]
    ),
  });

  const percent = useMemo(
    () =>
      summary && summary.value
        ? Math.round(
            ((summary.valuePaid + summary.valueApproved) * 100) / summary.value
          )
        : 0,
    [summary]
  );

  const handleTitleEdit = () => {
    setDescriptionEdit(true);
  };
  const handleTitleUpdate = (formData: FormData) => {
    setDescriptionEdit(false);
    onUpdate({ ...editableData, description: formData[CONTRACT_TITLE] });
  };

  useEffect(() => {
    reset({ [CONTRACT_TITLE]: editableData.description });
  }, [reset, editableData.description]);

  return (
    <>
      <Stack
        direction={'row'}
        justifyContent={'space-between'}
        alignContent={'center'}
        sx={{ marginBottom: 3 }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
          <Typography
            variant="h5"
            sx={
              contractHistory &&
              contractHistory.description !== editableData.description
                ? {
                    border: '1px solid red',
                    padding: '0.2rem',
                    borderRadius: '4px',
                  }
                : {}
            }
          >
            {isDescriptionEdit ? (
              <Controller
                name={CONTRACT_TITLE}
                defaultValue=""
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    size="small"
                    variant="standard"
                    fullWidth
                    required
                    error={!!errors.contractTitle}
                  />
                )}
              />
            ) : (
              <>
                {editableData.description}

                {contractHistory &&
                  contractHistory?.description !== editableData.description && (
                    <div
                      style={{
                        color: '#ed6c02',
                        position: 'absolute',
                        top: '30px',
                        fontWeight: 500,
                        fontSize: '12px',
                      }}
                    >
                      {contractHistory.description}
                    </div>
                  )}
              </>
            )}
          </Typography>
          <IconButton
            sx={{ '&:hover': { svg: { color: '#ed6c02' } } }}
            onClick={
              isDescriptionEdit
                ? handleSubmit(handleTitleUpdate)
                : handleTitleEdit
            }
          >
            {isDescriptionEdit ? <DoneIcon color="success" /> : <EditIcon />}
          </IconButton>
          {!!contract.status && (
            <ContractStatusChip size="medium" status={contract.status} />
          )}
        </Box>
        <Stack direction={'row'} spacing={4}>
          {!!actionList.length && (
            <Stack direction={'row'} spacing={2}>
              <ActionButtons actionList={actionList} />
            </Stack>
          )}
          <Button
            onClick={openDetailsDialog}
            variant={'outlined'}
            sx={{
              border: hasVisibilitySettingsChanged() ? '1px solid red' : '',
            }}
          >
            Update Contract Settings
          </Button>
        </Stack>
      </Stack>
      <Grid sx={styles.InfoGrid}>
        <Card variant="outlined" sx={{ mr: 3 }}>
          <CardContent>
            <Typography noWrap variant="caption" sx={styles.TypographyLabel}>
              Complete
            </Typography>
            <Stack direction="row" alignItems={'center'} spacing={2}>
              <CircularProgress
                sx={styles.CircularProgress}
                variant="determinate"
                value={Number(percent || 0)}
              />
              <Typography variant={'h6'} sx={styles.Percent}>
                {percent} %
              </Typography>
            </Stack>
          </CardContent>
        </Card>
        <ContractDetailsInfoCard contract={contract} summary={summary} />
      </Grid>
      {isVisibleDetailsDialog && (
        <DetailsDialog
          isReadOnly={false}
          accountList={accountList}
          isAccountListLoading={isAccountListLoading}
          open={isVisibleDetailsDialog}
          contract={contract}
          editableData={editableData}
          reloadData={reloadContract}
          handleClose={closeDetailsDialog}
          onEditSave={onEditSave}
          handleUpdateAccountList={updateAccountList}
          isEdit={true}
        />
      )}
    </>
  );
}

const styles = {
  InfoGrid: {
    display: 'grid',
    gridTemplateColumns: 'repeat( auto-fit, minmax(100px, 1fr) )',
    gap: '0px',
    marginBottom: '24px',
  },
  TypographyLabel: {
    color: grey500,
    letterSpacing: 1,
    marginBottom: '4px',
  },

  Percent: {
    fontSize: '20px',
  },
  CircularProgress: {
    color: (theme: Theme) => theme.palette.primary.light,
  },
};

export { ContractDetailsEdit };
