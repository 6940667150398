import React, { useMemo, useState } from 'react';
import { generatePath } from 'react-router-dom';
import {
  Box,
  Button,
  Divider,
  Drawer,
  IconButton,
  TableBody,
  TableCell,
  TableRow,
} from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import { useQuery } from '@tanstack/react-query';

import { useAuth } from '../../../common/auth-provider/AuthProvider';
import { SkeletonTable } from '../../../components/skeleton-table';
import { Heading, LinkTo, StyledTable } from '../../../styles';
import { listIncome } from '../../../common/api/payments';
import { formatCurrency } from '../../../common/format';
import { useListPagination } from '../../../common/hooks/useListPagination';
import { Pagination } from '../../../components/pagination';
import { SortedTableHead } from '../../../components/sorted-table-head';
import { getPaymentRows, headCellsIncome } from './PaymentTableHeader';
import { useSortedTable } from '../../../components/sorted-table-head/SortedTable.hooks';
import { stableSort } from '../../../common/sort/stableSort';
import { getComparator } from '../../../common/sort/getComparator';
import { useProject } from '../../../common/hooks/useProject';
import { useDialogState } from '../../../components/dialog/dialog.hooks';
import PaymentManualClaimTable from './PaymentManualClaimTable';
import { brand700, red700 } from '../../../styles/colours';
import { hasPendingManualPaymentAction } from './PaymentManual.utils';
import PaymentIncomeManualPayment from './PaymentIncomeManualPayment';

function PaymentsIncomeTab({
  refetch: refetchIncome,
}: {
  refetch: () => void;
}) {
  const { isVisible, close, open } = useDialogState();
  const [selectedPayment, setSelectedPayment] = useState<string>();

  const { user } = useAuth();
  const accountId = user?.accountId;
  const { project } = useProject();

  const { page, rowsPerPage, skip, handleChangePage } = useListPagination();
  const { order, orderBy, handleRequestSort } = useSortedTable('projectName');

  const { data, isLoading, refetch } = useQuery(
    ['payments-income', project?.systemDate, accountId, rowsPerPage],
    () =>
      listIncome(
        { accountId: accountId! },
        {
          skip: skip,
          take: rowsPerPage,
        }
      )
  );

  const payments = useMemo(
    () => getPaymentRows(data?.data || []),
    [data?.data]
  );

  const selectedPaymentDetails = useMemo(() => {
    const payment = payments.find(p => p.id === selectedPayment);
    const manualPayments = data?.data.find(
      d => d.id === payment?.id
    )?.manualPayments;
    return payment && manualPayments
      ? {
          ...payment,
          manualPayments,
        }
      : undefined;
  }, [selectedPayment, payments, data?.data]);

  return (
    <>
      {isLoading ? (
        <SkeletonTable />
      ) : (
        <>
          <Drawer
            anchor="right"
            sx={{
              '& .MuiDrawer-paper': {
                marginTop: '0px',
                height: '100%',
              },
            }}
            open={isVisible}
            onClose={close}
          >
            <Box display="flex" padding={2}>
              <IconButton
                aria-label="close"
                onClick={close}
                sx={{
                  color: theme => theme.palette.grey[500],
                }}
              >
                <ClearIcon />
              </IconButton>
            </Box>
            <Divider />
            <Box padding={2}>
              <Box marginBottom={6}>
                <Heading>Payment Details</Heading>
                {selectedPaymentDetails && (
                  <PaymentManualClaimTable
                    paymentDetail={selectedPaymentDetails}
                  />
                )}
              </Box>
              {accountId && selectedPaymentDetails && (
                <PaymentIncomeManualPayment
                  accountId={accountId}
                  paymentDetails={selectedPaymentDetails}
                  onPaymentUpdated={() => {
                    refetch();
                    refetchIncome();
                  }}
                />
              )}
            </Box>
          </Drawer>
          <StyledTable sx={{ mb: 2 }}>
            <SortedTableHead
              order={order}
              orderBy={orderBy}
              headCells={headCellsIncome}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {stableSort(payments, getComparator(order, orderBy)).map(
                payment => (
                  <TableRow key={payment.id}>
                    <TableCell>{payment.projectName}</TableCell>
                    <TableCell>{payment.payerName}</TableCell>
                    <TableCell>{payment.description}</TableCell>
                    <TableCell>
                      <LinkTo
                        to={generatePath(
                          '/contracts/:contractId/claims/:claimId',
                          {
                            contractId: payment.contractId,
                            claimId: payment.claimId,
                          }
                        )}
                      >
                        {payment.approvedAt}
                      </LinkTo>
                    </TableCell>
                    <TableCell>{payment.dueAt}</TableCell>
                    <TableCell>{payment.paidAt}</TableCell>
                    <TableCell>
                      <LinkTo
                        to={generatePath(
                          '/contracts/:contractId/claims/:claimId',
                          {
                            contractId: payment.contractId,
                            claimId: payment.claimId,
                          }
                        )}
                      >
                        {formatCurrency(payment.amount)}
                      </LinkTo>
                    </TableCell>
                    <TableCell>
                      {hasPendingManualPaymentAction(
                        data?.data.find(p => p.id === payment.id)
                      ) ? (
                        <Button
                          variant="outlined"
                          sx={{
                            color: red700,
                            borderColor: red700,
                          }}
                          onClick={() => {
                            setSelectedPayment(payment.id);
                            open();
                          }}
                        >
                          Payment Details
                        </Button>
                      ) : (
                        <Button
                          variant="outlined"
                          sx={{
                            color: brand700,
                            borderColor: brand700,
                          }}
                          onClick={() => {
                            setSelectedPayment(payment.id);
                            open();
                          }}
                        >
                          Payment Details
                        </Button>
                      )}
                    </TableCell>
                  </TableRow>
                )
              )}
            </TableBody>
          </StyledTable>
          <Pagination
            count={Math.ceil((data?.meta.total || 0) / rowsPerPage)}
            handleChangePage={handleChangePage}
            page={page}
            total={data?.meta.total || 0}
          />
        </>
      )}
    </>
  );
}

export { PaymentsIncomeTab };
