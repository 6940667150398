import { VariationStatus } from '../../common/api';

export const getShortStatus = (status: VariationStatus) => {
  switch (status) {
    case VariationStatus.Approved:
      return 'A';
    case VariationStatus.Draft:
      return 'D';
    case VariationStatus.Rejected:
      return 'R';
    case VariationStatus.Submitted:
      return 'S';
    case VariationStatus.Withdrawn:
      return 'W';
    case VariationStatus.RejectAndClosed:
      return 'R & Closed';
    case VariationStatus.WithdrawnAndClosed:
      return 'W & Closed';
    case VariationStatus.FurtherInfoRequested:
      return 'F';
    default:
      return '';
  }
};
