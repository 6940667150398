import React from 'react';
import { Box, Card, CardContent, Typography } from '@mui/material';
import { grey200, grey500, grey900 } from '../../../styles/colours';
import { formatCurrency } from '../../../common/format';
import { ContractSummaryCardModifiedValue } from './ContractSummaryCardModifiedValue';

interface Props {
  title: string;
  value: string | number;
  isModifiedValue?: boolean;
  originalValue?: string;
}
function ContractSummaryCard(props: Props) {
  const { value, title, isModifiedValue, originalValue } = props;
  const withOriginalValue = isModifiedValue && originalValue;

  return (
    <Card sx={styles.Card}>
      <CardContent sx={styles.CardContent}>
        <Box sx={styles.TypographyLabelContainer}>
          <Typography variant="caption" sx={styles.TypographyLabel}>
            {title}
          </Typography>
        </Box>
        {withOriginalValue && (
          <ContractSummaryCardModifiedValue
            taskValue={value}
            taskOriginalValue={originalValue}
          />
        )}
        {!withOriginalValue && (
          <Typography sx={styles.TypographyContent}>
            {typeof value === 'number' ? formatCurrency(value) : value}
          </Typography>
        )}
      </CardContent>
    </Card>
  );
}

const styles = {
  Card: {
    border: `1px solid ${grey200}`,
    display: 'flex',
    alignItems: 'scretch',
    borderWidth: '1px 1px 1px 0px',
    boxShadow: 'none',
    borderRadius: '0px',
    '&:last-child': {
      borderWidth: '1px 0px 1px 0px',
    },
  },
  TypographyLabelContainer: {
    display: 'flex',
    alignItems: 'flex-end',
    height: '40px',
  },
  TypographyLabel: {
    color: grey500,
    letterSpacing: 1,
  },
  TypographyContent: {
    paddingTop: '6px',
    color: grey900,
    fontSize: '20px',
    fontWeight: 500,
  },
  Span: {
    color: grey500,
    fontWeight: 400,
  },
  CardContent: {
    flexDirection: 'column',
    px: 1.5,
    '&:last-child': { pb: 1.5 },
  },
};

export { ContractSummaryCard };
