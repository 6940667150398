import { axios } from './axios';
import {
  Account,
  CreateAccountPayload,
  UpdateAccountPayload,
  UpdateAccountWithInvitationPayload,
} from './types';

async function listAccounts(): Promise<Array<Account>> {
  const resp = await axios.get(`/accounts`);
  return resp.data;
}

async function getAccount(id: string): Promise<Account> {
  const resp = await axios.get(`/accounts/${id}`);
  return resp.data;
}

async function createAccount(payload: CreateAccountPayload): Promise<Account> {
  const resp = await axios.post(`/accounts`, payload);
  return resp.data;
}

async function updateAccount(payload: UpdateAccountPayload): Promise<Account> {
  const {
    id,
    representativeEmail,
    representative,
    representativePhone,
    abn,
    address,
    addressLine2,
    businessName,
    country,
    locality,
    logoKey,
    postcode,
    state,
    tradingName,
  } = payload;
  const resp = await axios.post(`/accounts/${id}`, {
    representativeEmail,
    representative,
    representativePhone,
    abn,
    address,
    addressLine2,
    businessName,
    country,
    locality,
    logoKey,
    postcode,
    state,
    tradingName,
  });
  return resp.data;
}

async function updateAccountWithInvitation(
  payload: UpdateAccountWithInvitationPayload
): Promise<Account> {
  const { invitationId, abn, name, address } = payload;
  const resp = await axios.post(`/accounts/invitation/${invitationId}`, {
    abn,
    name,
    address,
  });
  return resp.data;
}

async function getAccountIdByInvitation(
  invitationId: string
): Promise<Account> {
  const resp = await axios.get(`/accounts/invitation/${invitationId}`);
  return resp.data;
}

export {
  listAccounts,
  getAccount,
  createAccount,
  updateAccount,
  updateAccountWithInvitation,
  getAccountIdByInvitation,
};
