import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  createTodo,
  CreateTodoPayload,
  listTodo,
  ResponseListBaseType,
  Todo,
  TodoState,
  updateTodo,
  UpdateTodoPayload,
} from '../../../common/api';
import { SortedTableHead } from '../../../components/sorted-table-head';
import { useSortedTable } from '../../../components/sorted-table-head/SortedTable.hooks';
import { StyledTable } from '../../../styles';
import { useCheckbox } from '../../contract-page/hooks/useCheckbox';
import {
  Box,
  Button,
  LinearProgress,
  Stack,
  TableBody,
  TextField,
  Typography,
} from '@mui/material';
import { stableSort } from '../../../common/sort/stableSort';
import { getComparator } from '../../../common/sort/getComparator';
import { useMutation, useQuery } from '@tanstack/react-query';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { TodoListRow } from './TodoListRow';
import { SkeletonTable } from '../../../components/skeleton-table';

interface Props {
  taskId: string;
}

const DESCRIPTION_FIELD = 'description';

const headCells = [
  {
    id: 'description',
    numeric: false,
    disablePadding: false,
    label: 'Todo Item',
  },
];

type FormData = {
  [DESCRIPTION_FIELD]: string;
};
function TodoList(props: Props) {
  const { taskId } = props;
  const { order, orderBy, handleRequestSort } = useSortedTable('description');

  const { data: todos, refetch } = useQuery<ResponseListBaseType<Array<Todo>>>(
    ['todos', taskId],
    () => {
      return listTodo(taskId);
    }
  );

  const { isItemSelected, handleCheckboxClick, setSelectAll } = useCheckbox(
    todos?.data
  );
  const [isShowTodoInput, setIsShowTodoInput] = useState<boolean>(false);

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<FormData>();

  const { mutate: mutateUpdateTodo } = useMutation(
    (payload: UpdateTodoPayload) => updateTodo(payload),
    {
      onSuccess: async () => {
        return refetch();
      },
    }
  );

  const handleCheckbox = useCallback(
    async (todo: Todo) => {
      handleCheckboxClick(todo.id);
      await mutateUpdateTodo({
        todoId: todo.id,
        state:
          todo.state === TodoState.Complete
            ? TodoState.Incomplete
            : TodoState.Complete,
      });
    },
    [handleCheckboxClick, mutateUpdateTodo]
  );

  const handleEdit = useCallback(
    async (data: { description: string; todoId: string }) => {
      await mutateUpdateTodo({
        todoId: data.todoId,
        description: data.description,
      });
    },
    [mutateUpdateTodo]
  );

  const { mutate: mutateAddTodo } = useMutation(
    (payload: CreateTodoPayload) => createTodo(payload),
    {
      onSuccess: async () => {
        return refetch();
      },
    }
  );

  useEffect(() => {
    if (todos?.data) {
      setSelectAll(
        todos.data
          .filter(todo => todo.state === TodoState.Complete)
          .map(({ id }) => id)
      );
    }
  }, [todos, setSelectAll]);

  const onSubmit: SubmitHandler<FormData> = useCallback(
    async data => {
      await mutateAddTodo({ ...data, taskId: taskId });
      reset();
    },
    [mutateAddTodo, reset, taskId]
  );

  const percentCompleteTodo = useMemo(
    () =>
      ((todos?.data?.filter(todo => todo.state === TodoState.Complete).length ||
        0) *
        100) /
      (todos?.meta?.total || 1),
    [todos]
  );

  if (!todos?.data) {
    return <SkeletonTable rows={1} />;
  }

  return (
    <Box onClick={() => setIsShowTodoInput(false)}>
      <Stack sx={styles.Stack}>
        <Typography sx={styles.TypographyBody2} variant={'body2'}>
          Checklist
        </Typography>
        <Stack alignItems={'center'} direction={'row'}>
          <Typography
            sx={styles.PercentTypography}
            variant="body2"
            color="text.secondary"
          >
            {`${Math.round(percentCompleteTodo)}%`}
          </Typography>
          <LinearProgress
            sx={styles.LinearProgress}
            value={percentCompleteTodo}
            variant="determinate"
          />
        </Stack>
      </Stack>
      <StyledTable sx={styles.Table}>
        <SortedTableHead
          order={order}
          orderBy={orderBy}
          onRequestSort={handleRequestSort}
          headCells={headCells}
        />
        <TableBody>
          {stableSort(todos.data, getComparator(order, orderBy)).map(row => (
            <TodoListRow
              key={row.id}
              todo={row}
              isItemSelected={isItemSelected}
              handleCheckbox={handleCheckbox}
              handleUpdate={handleEdit}
              reloadData={async () => {
                await refetch();
              }}
            />
          ))}
        </TableBody>
      </StyledTable>
      <Button
        variant={'contained'}
        sx={{ mb: 3 }}
        onClick={e => {
          e.stopPropagation();
          setIsShowTodoInput(true);
        }}
      >
        {'Add Todo Item'}
      </Button>
      {isShowTodoInput && (
        <Box sx={styles.TodoInputBox} onClick={e => e.stopPropagation()}>
          <Controller
            name={DESCRIPTION_FIELD}
            control={control}
            rules={{
              required: 'description is required',
            }}
            render={({ field }) => (
              <TextField
                {...field}
                value={field.value || ''}
                label="description"
                required
                fullWidth
                error={!!errors[DESCRIPTION_FIELD]}
                helperText={errors[DESCRIPTION_FIELD]?.message}
              />
            )}
          />
          <Button
            sx={styles.AddButton}
            onClick={handleSubmit(onSubmit)}
            variant={'contained'}
          >
            Add
          </Button>
        </Box>
      )}
    </Box>
  );
}

const styles = {
  Stack: {
    mb: 3,
  },
  TypographyBody2: {
    fontSize: '14px',
    mb: 2,
  },
  PercentTypography: {
    minWidth: 35,
  },
  LinearProgress: {
    width: '100%',
    mr: 1,
    borderRadius: 4,
    height: 8,
  },
  Table: {
    mb: 2,
  },
  Button: {
    mb: 3,
  },
  TodoInputBox: {
    mb: 3,
  },
  AddButton: {
    mt: 2,
  },
};

export { TodoList };
