import styled from '@emotion/styled';

type TileColourProps = {
  $isActive?: boolean;
};
export const TileColour = styled('button')<TileColourProps>`
  border-radius: 8px;
  border: 1px solid white;
  height: 55px;
  width: 55px;
  flex: 1;

  ${props =>
    props.$isActive &&
    `
    border: 1px solid black;
  `}

  &:hover {
    cursor: pointer;
  }

  &:focus {
    outline: 2px solid black;
  }
`;
