import { useMutation, useQuery } from '@tanstack/react-query';
import React, { useCallback, useMemo, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import {
  Claim,
  ClaimStatus,
  ClaimType,
  ResponseListBaseType,
  SaveSubmitClaimPayload,
  Task,
  getDraftClaim,
  saveClaim,
} from '../../../../../common/api';
import { useSnackbar } from '../../../../../common/hooks/useSnackbar';
import {
  Box,
  Button,
  Stack,
  TableBody,
  TextField,
  Typography,
} from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { StyledTable } from '../../../../../styles';
import { useTaskClaimItems } from '../../../hooks/useTaskClaimItems';
import { SkeletonTable } from '../../../../../components/skeleton-table';
import {
  DEFAULT_DATE_FORMAT,
  formatAmountByClaimType,
} from '../../../../../common/format';
import { AxiosError } from 'axios';
import { LoadingButton } from '@mui/lab';
import { useDialogState } from '../../../../../components/dialog/dialog.hooks';
import { ClaimChat } from '../../../../../components/claim-chat';
import { TaskGroups } from '../../../../../components/task-group';
import { SortedTableHead } from '../../../../../components/sorted-table-head';
import { useSortedTable } from '../../../../../components/sorted-table-head/SortedTable.hooks';
import { createCurrentClaimRow, headCells } from './CurrentClaimTab.utils';
import { CurrentClaimTableRow } from './CurrentClaimTableRow';
import { stableSort } from '../../../../../common/sort/stableSort';
import { getComparator } from '../../../../../common/sort/getComparator';
import { ClaimFileDrawer } from './ClaimFileDrawer';
import {
  SummaryCard,
  SummaryGrid,
  SummaryText,
} from '../../../../project-list-page/components/SummaryCard';
import { projectDate } from '../../../../../common/format/formatDate';
import { useProject } from '../../../../../common/hooks/useProject';

type Props = {
  contractId: string;
  projectId: string;
  tasks?: ResponseListBaseType<Array<Task>>;
  reloadData: () => void;
  goToTasksTab: () => void;
  selectedTaskGroup?: string | null;
  handleSelectedTaskGroup: (nodeId: string) => void;
};

function CurrentClaimTab(props: Props) {
  const {
    contractId,
    tasks,
    reloadData,
    goToTasksTab,
    selectedTaskGroup,
    handleSelectedTaskGroup,
    projectId,
  } = props;
  const { showAlert, SnackbarComponent } = useSnackbar();
  const { project } = useProject(projectId);

  const [fromDate, setFromDate] = useState<Dayjs | null>(null);
  const [toDate, setToDate] = useState<Dayjs | null>(null);
  const [fromDateError, setFromDateError] = useState<boolean>(false);
  const [toDateError, setToDateError] = useState<boolean>(false);
  const [claimName, setClaimName] = useState<string>('');

  const {
    isVisible: isChatVisible,
    close: closeChat,
    open: openChat,
  } = useDialogState();

  const {
    isVisible: isClaimFilesVisible,
    open: openClaimFiles,
    close: closeClaimFiles,
  } = useDialogState();

  const { order, orderBy, handleRequestSort } = useSortedTable('identifier');

  const { data: claim, refetch: refetchDraft } = useQuery<Claim>(
    ['draftClaim', contractId],
    () => {
      return getDraftClaim(contractId);
    },
    {
      cacheTime: 0,
      onSuccess: res => {
        setFromDate(dayjs(res.periodStart));
        setClaimName(res.description || 'Claim #1');

        if (res.periodEnd) {
          setToDate(dayjs(res.periodEnd));
        }
      },
    }
  );

  const { mutate: mutateSubmit, isLoading: isLoadingSubmit } = useMutation(
    (payload: SaveSubmitClaimPayload) => {
      return saveClaim(contractId, claim?.id!, payload);
    },
    {
      onSuccess: () => {
        reloadData();
        showAlert('Claim submitted!', 'success');
        goToTasksTab();
      },
      onError: (error: AxiosError<{ message?: string }>) => {
        showAlert(
          error?.response?.data.message || 'An unknown error occurred!',
          'error'
        );
      },
    }
  );

  const { mutate: mutateSave, isLoading: isLoadingSave } = useMutation(
    (payload: SaveSubmitClaimPayload) => {
      return saveClaim(contractId, claim?.id!, payload);
    },
    {
      onSuccess: () => {
        reloadData();
        showAlert('Claim saved!', 'success');
      },
      onError: (error: AxiosError<{ message?: string }>) => {
        showAlert(
          error?.response?.data.message || 'An unknown error occurred!',
          'error'
        );
      },
    }
  );

  const { claimItemIndex, onClaimItemChange, isError } =
    useTaskClaimItems(claim);

  const handleChangeClaimName = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setClaimName(e.target.value);
    },
    []
  );

  const reload = useCallback(async () => {
    reloadData();
    await refetchDraft();
  }, [reloadData, refetchDraft]);

  const validate = useCallback(():
    | Omit<SaveSubmitClaimPayload, 'status'>
    | undefined => {
    if (!fromDate || !toDate) {
      console.log('SNACKBAR: Set a valid period');
      return;
    }

    const claimItems = Object.entries(claimItemIndex)
      .map(([taskId, claimItem]) => {
        return {
          id: claimItem.id,
          taskId,
          claimType: claimItem.claimType,
          value: claimItem.value,
        };
      })
      .filter(
        claimItem => claimItem.id || (!claimItem.id && claimItem.value > 0)
      );

    return {
      periodStart: fromDate.format('YYYY-MM-DD'),
      periodEnd: toDate.format('YYYY-MM-DD'),
      claimItems,
      claimName: claimName || undefined,
    };
  }, [claimName, fromDate, toDate, claimItemIndex]);

  const handleSaveOrSubmitClaim = useCallback(
    (isDraft: boolean = false) => {
      const payload = validate();
      if (!payload) {
        return;
      }
      if (!payload.claimItems.length) {
        showAlert('There is no claim items!', 'error');
        return;
      }

      if (isDraft) {
        mutateSave({
          ...payload,
          status: ClaimStatus.Draft,
        });
      } else {
        mutateSubmit({
          ...payload,
          status: ClaimStatus.Submitted,
        });
      }
    },
    [validate, mutateSave, mutateSubmit, showAlert]
  );

  const rows = useMemo(
    () =>
      claim?.claimItems.map(claimItem =>
        createCurrentClaimRow(claimItem, tasks?.data)
      ) || [],
    [claim?.claimItems, tasks?.data]
  );

  return !claim ? (
    <SkeletonTable />
  ) : (
    <TaskGroups
      contractId={contractId}
      selectedTaskGroup={selectedTaskGroup}
      handleSelectedTaskGroup={handleSelectedTaskGroup}
      render={() => (
        <div>
          <SnackbarComponent />
          {isChatVisible && (
            <ClaimChat
              claim={claim}
              permissionToSend
              reloadData={refetchDraft}
              onClose={closeChat}
            />
          )}
          {claim && (
            <ClaimFileDrawer
              claim={claim}
              open={isClaimFilesVisible}
              canUpload
              canDelete
              onClose={closeClaimFiles}
              reloadClaim={refetchDraft}
            />
          )}

          <Box
            display="flex"
            justifyContent="space-between"
            flexWrap="wrap"
            sx={{
              mb: 3,
            }}
          >
            <Stack direction={'row'} alignItems="baseline" marginTop="8px">
              <TextField
                size="small"
                placeholder="Claim Name"
                value={claimName || ''}
                onChange={handleChangeClaimName}
                sx={{ width: '160px', mr: '16px' }}
              />
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Typography variant="body2" sx={{ fontSize: '14px' }}>
                  Claim period from{' '}
                </Typography>
                <DatePicker
                  value={fromDate}
                  onChange={setFromDate}
                  inputFormat={DEFAULT_DATE_FORMAT}
                  maxDate={toDate || undefined}
                  renderInput={params => {
                    setFromDateError(params.error || !fromDate);
                    return (
                      <TextField
                        {...params}
                        required
                        error={params.error || !fromDate}
                        helperText={
                          !!params.error || !fromDate
                            ? 'Invalid start date'
                            : ''
                        }
                        size="small"
                        sx={{ width: '150px', mr: 1, ml: 1 }}
                      />
                    );
                  }}
                />{' '}
                <Typography variant="body2" sx={{ fontSize: '14px' }}>
                  To{' '}
                </Typography>
                <DatePicker
                  value={toDate}
                  onChange={setToDate}
                  inputFormat={DEFAULT_DATE_FORMAT}
                  minDate={fromDate || projectDate(project?.systemDate)}
                  defaultCalendarMonth={
                    fromDate || projectDate(project?.systemDate)
                  }
                  renderInput={params => {
                    setToDateError(params.error || !toDate);
                    const showError =
                      params.error || (!toDate && toDate !== null);
                    return (
                      <TextField
                        {...params}
                        required
                        error={showError}
                        helperText={showError ? 'Invalid end date' : ''}
                        size="small"
                        sx={{ width: '150px', mr: 1, ml: 1 }}
                      />
                    );
                  }}
                />
              </LocalizationProvider>
            </Stack>
            <Box
              sx={{
                '& > button': {
                  marginLeft: '8px',
                  marginTop: '8px',
                  '&:first-of-type': {
                    marginLeft: 0,
                  },
                },
              }}
            >
              <LoadingButton
                variant="outlined"
                onClick={() => handleSaveOrSubmitClaim(true)}
                loading={isLoadingSave}
                disabled={
                  fromDateError ||
                  toDateError ||
                  isLoadingSubmit ||
                  claim.claimItems.length === 0
                }
              >
                Save
              </LoadingButton>
              <Button variant="outlined" onClick={openClaimFiles}>
                Attachments
              </Button>
              <Button variant="outlined" onClick={openChat}>
                Comments
              </Button>
              <LoadingButton
                variant="contained"
                onClick={() => handleSaveOrSubmitClaim()}
                loading={isLoadingSubmit}
                disabled={
                  fromDateError ||
                  toDateError ||
                  isLoadingSave ||
                  isError ||
                  claim.claimItems.length === 0
                }
              >
                Submit
              </LoadingButton>
            </Box>
          </Box>
          <SummaryGrid>
            <SummaryCard title="Total Value (of claimed tasks)">
              <SummaryText
                value={formatAmountByClaimType(
                  rows.reduce((acc, obj) => {
                    return acc + Number(obj.taskValue);
                  }, 0),
                  ClaimType.value
                )}
              />
            </SummaryCard>
            <SummaryCard title="Total Previously Approved (of claimed tasks)">
              <SummaryText
                value={formatAmountByClaimType(
                  rows.reduce((acc, obj) => {
                    return acc + Number(obj.previouslyApproved);
                  }, 0),
                  ClaimType.value
                )}
              />
            </SummaryCard>
            <SummaryCard title="Progress Approved (of claimed tasks)">
              <SummaryText
                value={
                  rows.length > 0
                    ? parseFloat(
                        (
                          (rows.reduce((acc, obj) => {
                            return (
                              acc + Number(obj.paid) + Number(obj.approved)
                            );
                          }, 0) /
                            rows.reduce((acc, obj) => {
                              return acc + Number(obj.taskValue);
                            }, 0)) *
                          100
                        ).toFixed(1)
                      ) + '%'
                    : '0%'
                }
              />
            </SummaryCard>
            <SummaryCard title="Total Current Claim">
              <SummaryText
                value={formatAmountByClaimType(
                  rows.reduce((acc, obj) => {
                    return acc + Number(obj.value);
                  }, 0),
                  ClaimType.value
                )}
              />
            </SummaryCard>
          </SummaryGrid>
          <StyledTable>
            <SortedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              headCells={headCells}
            />
            <TableBody>
              {stableSort(rows, getComparator(order, orderBy)).map(row =>
                row.taskId ? (
                  <CurrentClaimTableRow
                    key={row.id}
                    row={row}
                    claim={claim}
                    onClaimItemChange={onClaimItemChange}
                    reloadData={reload}
                  />
                ) : (
                  <></>
                )
              )}
            </TableBody>
          </StyledTable>
        </div>
      )}
    />
  );
}

export { CurrentClaimTab };
