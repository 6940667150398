import React, { MouseEvent, useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import Button from '@mui/material/Button';
import Menu, { MenuProps } from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { styled } from '@mui/material/styles';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useAuth } from '../../../common/auth-provider/AuthProvider';
import { Avatar, Box, Typography } from '@mui/material';
import { useDialogState } from '../../../components/dialog/dialog.hooks';
import { grey500 } from '../../../styles/colours';
import { MyAccountDialog } from './account/MyAccountDialog';
import { MyOrganisationDialog } from './organisation/MyOrganisationDialog';
import { MyAlertSettingsDialog } from './alert-settings/MyAlertSettingsDialog';

function SecondaryMenu() {
  const queryClient = useQueryClient();
  const { user, logout } = useAuth();
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const {
    isVisible: isAccountVisible,
    open: openAccount,
    close: closeAccount,
  } = useDialogState();
  const {
    isVisible: isOgranisationVisible,
    open: openOrganisation,
    close: closeOrganisation,
  } = useDialogState();
  const {
    isVisible: isAlertSettingsVisible,
    open: openAlertSettings,
    close: closeAlertSettings,
  } = useDialogState();

  const handleClick = useCallback((event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleLogout = useCallback(() => {
    logout();
    navigate('/');
    queryClient.clear();
  }, [logout, navigate, queryClient]);

  return (
    <div>
      {isAccountVisible && <MyAccountDialog close={closeAccount} />}
      {isOgranisationVisible && (
        <MyOrganisationDialog close={closeOrganisation} />
      )}
      {isAlertSettingsVisible && (
        <MyAlertSettingsDialog close={closeAlertSettings} />
      )}

      <Button
        id="nav-secondary-menu"
        variant="text"
        sx={{ fontWeight: 'normal' }}
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        endIcon={<KeyboardArrowDownIcon />}
        onClick={handleClick}
      >
        {user?.firstName && user.lastName ? (
          <Box display="flex" gap={1} alignItems="center">
            <Avatar src={user.avatarUrl || ''}>{`${user?.firstName?.charAt(
              0
            )}${user?.lastName?.charAt(0)}`}</Avatar>
            <Box sx={{ textAlign: 'left' }}>
              <Typography variant="body1">{`${user?.firstName} ${user?.lastName}`}</Typography>
              <Typography variant="body2" sx={{ color: grey500 }}>
                {user?.email}
              </Typography>
            </Box>
          </Box>
        ) : (
          user?.email
        )}
      </Button>
      <StyledMenu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'nav-secondary-menu',
        }}
      >
        <MenuItem
          onClick={() => {
            openAccount();
            handleClose();
          }}
        >
          My Account
        </MenuItem>
        <MenuItem
          onClick={() => {
            openOrganisation();
            handleClose();
          }}
        >
          My Organisation
        </MenuItem>
        <MenuItem
          onClick={() => {
            openAlertSettings();
            handleClose();
          }}
        >
          My Alert Settings
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClose();
            handleLogout();
          }}
        >
          Log out
        </MenuItem>
      </StyledMenu>
    </div>
  );
}

const StyledMenu = styled((props: MenuProps) => <Menu {...props} />)(() => ({
  '& .MuiPaper-root': {
    minWidth: 180,
  },
}));

export { SecondaryMenu };
