import { useMutation, useQuery } from '@tanstack/react-query';
import {
  listNotification,
  Notification,
  Project,
  updateNotification,
  User,
} from '../api';

function useNotifications(
  user?: User,
  project?: Project,
  isShowUnreadNotifications: boolean = false
) {
  const { data: notifications, refetch: refetchNotifications } = useQuery<
    Array<Notification>
  >(
    [
      'notifications',
      project?.systemDate,
      user?.accountId,
      isShowUnreadNotifications,
    ],
    () => {
      return listNotification(user?.accountId ?? '', isShowUnreadNotifications);
    },
    {
      enabled: !!user?.accountId,
    }
  );

  const { mutate: mutationNotification } = useMutation(
    (payload: { id: string; isRead: boolean }) =>
      updateNotification(payload.id, payload.isRead),
    {
      onSuccess: () => refetchNotifications(),
    }
  );

  return {
    notifications,
    refetchNotifications,
    mutationNotification,
  };
}

export { useNotifications };
