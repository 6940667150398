import React, { ReactNode, useRef } from 'react';
import { useDrop } from 'react-dnd';
import { useTaskGroupUpdate } from '../TaskGroups.hooks';
import { getLastOrderValue } from '../TaskGroup.utils';
import { useTaskGroupContext } from './TaskGroup.context';
import { DragItem } from './types';
import * as Styled from './TreeDraggable.style';

type Props = {
  children: ReactNode;
  onReload?: () => void;
};

export const TreeRootDropable = ({ children, onReload }: Props) => {
  const containerRef = useRef<HTMLDivElement | null>(null);

  const { mutate } = useTaskGroupUpdate();
  const { taskGroups, setTaskGroups } = useTaskGroupContext();

  const [{ isOver }, drop] = useDrop<
    DragItem,
    void,
    { isOver: boolean; canDrop: boolean }
  >({
    accept: 'tree',
    collect: monitor => ({
      isOver: monitor.isOver({ shallow: true }),
      canDrop: monitor.canDrop(),
    }),
    hover: () => {},
    canDrop: (dragItem: DragItem) => {
      if (dragItem.parentId === null) {
        return false;
      }
      return true;
    },
    drop(dragItem: DragItem, monitor) {
      // handle adding to root
      if (dragItem.parentId !== null && !monitor.didDrop()) {
        const order = getLastOrderValue(taskGroups, null);
        const item = taskGroups.find(tg => tg.id === dragItem.id);
        if (item) {
          item.order = order;
          item.parentId = null;
        }
        setTaskGroups(() => [...taskGroups]);
        mutate({
          id: dragItem.id,
          parentId: null,
          order,
        }).then(onReload);
      }
    },
  });

  drop(containerRef);

  return (
    <Styled.TreeRow ref={containerRef}>
      <Styled.TreeDraggable
        tabIndex={0}
        isOver={isOver}
        canDrop
        isDragging={false}
      >
        <Styled.TreeDraggableContent>{children}</Styled.TreeDraggableContent>
      </Styled.TreeDraggable>
    </Styled.TreeRow>
  );
};
