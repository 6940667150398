import { Button, TableCell, Typography } from '@mui/material';
import React from 'react';
import { ClaimItem } from '../../../../../common/api';
import { formatAmountPercentInValue } from '../../../../../common/format';
import { brand800 } from '../../../../../styles/colours';

type Props = {
  claimItem: ClaimItem;
  onClickClaim?: () => void;
  taskValue: number;
};

function TaskClaimItemClaimedCell({
  claimItem,
  onClickClaim,
  taskValue,
}: Props) {
  return (
    <>
      <TableCell>
        <Typography variant="body2" sx={{ mr: '8px' }}>
          {formatAmountPercentInValue(
            claimItem.value,
            claimItem.claimType,
            taskValue
          )}
        </Typography>
      </TableCell>
      <TableCell>
        <Button
          type="button"
          variant="outlined"
          sx={{
            color: brand800,
            borderColor: brand800,
            width: '100%',
            whiteSpace: 'nowrap',
          }}
          onClick={onClickClaim}
        >
          Added to Current Claim
        </Button>
      </TableCell>
    </>
  );
}

export { TaskClaimItemClaimedCell };
