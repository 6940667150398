import React from 'react';
import { generatePath } from 'react-router-dom';
import { Box, Button, TableCell, TableRow, Tooltip } from '@mui/material';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { LoadingButton } from '@mui/lab';
import { useMutation } from '@tanstack/react-query';
import { useAuth } from '../../../common/auth-provider/AuthProvider';
import { LinkTo } from '../../../styles';
import { makePayment, schedulePayment } from '../../../common/api/payments';
import { PaymentRow } from './PaymentTableHeader';
import { formatCurrency } from '../../../common/format';

type Props = {
  canMakeTransfer?: boolean;
  payment: PaymentRow;
  paymentScheduleUpdated: (isAutoPay: boolean) => void;
  onComplete: () => void;
  onError: () => void;
  onManualPayment: () => void;
};

function PaymentsDueRow({
  canMakeTransfer,
  payment,
  paymentScheduleUpdated,
  onComplete,
  onError,
  onManualPayment,
}: Props) {
  const { user } = useAuth();
  const accountId = user?.accountId;

  const { mutate: mutateSchedulePayment, isLoading } = useMutation(
    (payWhenDue: boolean) =>
      schedulePayment({
        accountId: accountId!,
        paymentId: payment.id,
        payWhenDue,
      }),
    {
      onSuccess: (res, variables) => paymentScheduleUpdated(variables),
    }
  );

  return (
    <TableRow>
      <TableCell>{payment.projectName}</TableCell>
      <TableCell>{payment.payeeName}</TableCell>
      <TableCell>{payment.description}</TableCell>
      <TableCell>
        <LinkTo
          to={generatePath('/contracts/:contractId/claims/:claimId', {
            contractId: payment.contractId,
            claimId: payment.claimId,
          })}
        >
          {payment.approvedAt}
        </LinkTo>
      </TableCell>
      <TableCell>{payment.dueAt}</TableCell>
      <TableCell>
        <LinkTo
          to={generatePath('/contracts/:contractId/claims/:claimId', {
            contractId: payment.contractId,
            claimId: payment.claimId,
          })}
        >
          {formatCurrency(payment.amountOutstanding)}
        </LinkTo>
      </TableCell>
      <TableCell>
        <Box display="flex" alignItems="center">
          {payment.bankSetupComplete === 'false' ? (
            <Box display="flex" alignItems="center">
              <Tooltip title="The payee has not completed bank setup. You cannot make this payment yet.">
                <WarningAmberIcon sx={{ color: 'red', marginRight: 1 }} />
              </Tooltip>
              <LoadingButton
                type="button"
                variant="contained"
                disabled
                onClick={() => {}}
              >
                Pay Now
              </LoadingButton>
            </Box>
          ) : accountId ? (
            <PaymentButton
              paymentId={payment.id}
              accountId={accountId}
              canMakeTransfer={!!canMakeTransfer}
              onComplete={onComplete}
              onError={onError}
            />
          ) : null}

          {canMakeTransfer && payment.bankSetupComplete === 'true' && (
            <>
              {payment.payWhenDue === 'true' ? (
                <LoadingButton
                  type="button"
                  variant="outlined"
                  loading={isLoading}
                  sx={{
                    ml: '8px',
                  }}
                  onClick={() => {
                    mutateSchedulePayment(false);
                  }}
                >
                  Cancel Auto Pay
                </LoadingButton>
              ) : (
                <Box display="flex" gap={1}>
                  <LoadingButton
                    type="button"
                    variant="outlined"
                    loading={isLoading}
                    sx={{
                      ml: '8px',
                    }}
                    onClick={() => {
                      mutateSchedulePayment(true);
                    }}
                  >
                    Pay on Due Date
                  </LoadingButton>
                  <Button variant="outlined" onClick={onManualPayment}>
                    Payment Options
                  </Button>
                </Box>
              )}
            </>
          )}
        </Box>
      </TableCell>
    </TableRow>
  );
}

function PaymentButton({
  paymentId,
  accountId,
  canMakeTransfer,
  onComplete,
  onError,
}: {
  paymentId: string;
  accountId: string;
  canMakeTransfer: boolean;
  onComplete: () => void;
  onError: () => void;
}) {
  const { mutate, isLoading: isLoadingMakePayment } = useMutation(
    ['makePayment', accountId],
    () => makePayment({ paymentId, accountId }),
    {
      onSuccess: () => onComplete(),
      onError: () => onError(),
    }
  );

  return (
    <LoadingButton
      type="button"
      variant="contained"
      loading={isLoadingMakePayment}
      disabled={!canMakeTransfer || isLoadingMakePayment}
      onClick={() => mutate()}
    >
      Pay Now
    </LoadingButton>
  );
}
export { PaymentsDueRow };
