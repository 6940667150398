import React, { ReactElement, ReactNode } from 'react';
import { Card, CardContent, Grid, Typography } from '@mui/material';
import { grey200, grey500, grey900 } from '../../../styles/colours';

interface Props {
  children: ReactElement;
  title: string;
  isShow?: boolean;
}
function SummaryCard(props: Props) {
  const { children, title, isShow = true } = props;

  return isShow ? (
    <Card sx={styles.Card}>
      <CardContent>
        <Typography variant={'caption'} sx={styles.TypographyLabel}>
          {title}
        </Typography>
        {children}
      </CardContent>
    </Card>
  ) : null;
}

const styles = {
  Card: {
    backgroundColor: '#fff',
    border: `1px solid ${grey200}`,
    borderWidth: '1px 1px 1px 0px',
    boxShadow: 'none',
    borderRadius: '0px',
    '&:last-child': {
      borderWidth: '1px 0px 1px 0px',
    },
  },
  TypographyLabel: {
    color: grey500,
    letterSpacing: 1,
    marginBottom: '4px',
  },
};

function SummaryGrid({ children }: { children: ReactNode }) {
  return (
    <Grid
      sx={{
        display: 'grid',
        gridTemplateColumns: 'repeat( auto-fit, minmax(100px, 1fr) )',
        gap: '0px',
        marginBottom: '24px',
      }}
    >
      {children}
    </Grid>
  );
}

function SummaryText({ value }: { value: string | number }) {
  return <Typography sx={SummaryTextStyle}>{value}</Typography>;
}

export const SummaryTextStyle = {
  paddingTop: '6px',
  color: grey900,
  fontSize: '20px',
  fontWeight: 500,
};

export { SummaryCard, SummaryGrid, SummaryText };
