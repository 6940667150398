import React, { useEffect } from 'react';
import { Box, Button, TextField, Typography } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import { Controller, useForm } from 'react-hook-form';

import { useTaskGroupContext } from '../../../components/task-group/components/TaskGroup.context';

const ID_FIELD = 'identifier';
const NAME_FIELD = 'description';
const TYPE_FIELD = 'type';
const UOM_FIELD = 'unitOfMeasure';
const QUANTITY_FIELD = 'quantity';
const RATE_FIELD = 'rate';
const EFR_FIELD = 'excludeFromRetention';
const AMOUNT_FIELD = 'value';

type FormData = {
  [ID_FIELD]: string;
  [NAME_FIELD]: string;
  [TYPE_FIELD]: string;
  [UOM_FIELD]: string;
  [QUANTITY_FIELD]: number;
  [RATE_FIELD]: number;
  [EFR_FIELD]: boolean;
  [AMOUNT_FIELD]: string;
};

type Props = {
  nextTaskId: number;
  selectedTaskGroup?: string | null;
  onAddTask: ({
    id,
    identifier,
    description,
    value,
    type,
    unitOfMeasure,
    quantity,
    rate,
    excludeFromRetention,
    action,
  }: FormData & { id: string; action: 'create' | 'delete' | 'update' }) => void;
};

export function AddDraftTaskForm({
  selectedTaskGroup = '0',
  nextTaskId,
  onAddTask,
}: Props) {
  const { taskGroups } = useTaskGroupContext();

  const taskGroup = taskGroups.find(tg => tg.id === selectedTaskGroup);

  const handleTaskCreate = (form: FormData) => {
    onAddTask({ ...form, id: uuidv4(), action: 'create' });
  };

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors, isValid },
  } = useForm<FormData>({
    mode: 'onChange',
  });

  useEffect(() => {
    reset({
      [ID_FIELD]: nextTaskId.toString(),
      [NAME_FIELD]: '',
      [AMOUNT_FIELD]: '',
    });
  }, [nextTaskId, reset]);

  return (
    <form onSubmit={handleSubmit(handleTaskCreate)} noValidate>
      <Box
        display="flex"
        alignItems="baseline"
        justifyContent="space-between"
        sx={{ p: '16px 0', '.MuiTextField-root': { marginRight: '16px' } }}
      >
        <Box display="flex">
          <Controller
            name={ID_FIELD}
            defaultValue={nextTaskId.toString()}
            control={control}
            rules={{
              required: 'ID is required',
            }}
            render={({ field }) => (
              <TextField
                sx={{ width: '80px' }}
                {...field}
                label="ID"
                size="small"
                required
                disabled={!isValid && !errors[ID_FIELD]}
                error={!!errors[ID_FIELD]}
                helperText={errors[ID_FIELD]?.message}
              />
            )}
          />
          <Controller
            name={NAME_FIELD}
            defaultValue=""
            control={control}
            rules={{
              required: 'Name is required',
            }}
            render={({ field }) => (
              <TextField
                {...field}
                label="Task name"
                required
                size="small"
                error={!!errors[NAME_FIELD]}
                helperText={errors[NAME_FIELD]?.message}
              />
            )}
          />
          <Controller
            name={AMOUNT_FIELD}
            defaultValue=""
            control={control}
            rules={{
              required: 'Amount is required',
              pattern: {
                value: /^[0-9]*$/,
                message: 'Value should be a number',
              },
            }}
            render={({ field }) => (
              <TextField
                {...field}
                label="Amount"
                required
                size="small"
                error={!!errors[AMOUNT_FIELD]}
                helperText={errors[AMOUNT_FIELD]?.message}
              />
            )}
          />
          <Typography display="inline-block" sx={{ lineHeight: '40px' }}>
            {taskGroup?.name || 'All Tasks'}
          </Typography>
        </Box>
        <Button type="submit" variant="outlined" disabled={!isValid}>
          Add Task
        </Button>
      </Box>
    </form>
  );
}
