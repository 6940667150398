import {
  Box,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from '@mui/material';
import React, { ChangeEvent, useCallback, useMemo, useState } from 'react';
import { ClaimType } from '../../../../../common/api';
import DeleteIcon from '@mui/icons-material/Delete';
import { useMutation } from '@tanstack/react-query';
import { removeClaimItem } from '../../../../../common/api/claimItem';
import { getIsError } from './CurrentClaimTab.utils';

interface Props {
  id: string;
  claimId: string;
  taskId: string;
  totalValue: number;
  defaultValue: number;
  costToComplete: number;
  defaultClaimType: ClaimType;
  onClaimItemChange: (
    id: string,
    newValue: number,
    newClaimType: ClaimType,
    isError: boolean
  ) => void;
  reloadData: () => void;
}

function TaskClaimItemValueCell({
  id,
  claimId,
  taskId,
  totalValue,
  defaultClaimType,
  defaultValue,
  onClaimItemChange,
  reloadData,
  costToComplete,
}: Props) {
  const [claimType, setClaimType] = useState<ClaimType>(defaultClaimType);
  const [value, setValue] = useState<number>(defaultValue);
  const [isRemoved, setIsRemoved] = useState<boolean>(false);
  const costToCompletePercentsValue = Math.floor(
    (costToComplete / totalValue) * 100
  );
  const isError = getIsError(
    value,
    claimType,
    costToComplete,
    costToCompletePercentsValue
  );

  const { mutate: mutateRemove } = useMutation(
    ['removeClaimItem'],
    (id: string) => removeClaimItem(claimId, id),
    {
      onSuccess: () => reloadData(),
    }
  );

  const hint = useMemo(() => {
    if (totalValue === 0) {
      return '';
    }
    if (claimType === ClaimType.value) {
      return ((value / totalValue) * 100).toFixed(0) + '%';
    } else {
      return '$' + (totalValue * (value / 100)).toFixed(0);
    }
  }, [totalValue, value, claimType]);

  const handleChangeClaimType = useCallback(
    (e: SelectChangeEvent<string>) => {
      const newType = e.target.value as ClaimType;
      const newValue =
        newType === ClaimType.value
          ? Math.round(totalValue * (value / 100))
          : Math.round((value / totalValue) * 100);
      const isError = getIsError(
        newValue,
        newType,
        costToComplete,
        costToCompletePercentsValue
      );

      setValue(newValue);
      setClaimType(newType);
      onClaimItemChange(taskId, newValue, newType, isError);
    },
    [
      value,
      taskId,
      setClaimType,
      onClaimItemChange,
      costToComplete,
      costToCompletePercentsValue,
      totalValue,
    ]
  );

  const handleChangeValue = useCallback(
    (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const input = e.target.value;
      if (!input.match(/^[0-9]*$/)) {
        return;
      }
      const newValue = !!input ? parseInt(input) : 0;
      const isError = getIsError(
        newValue,
        claimType,
        costToComplete,
        costToCompletePercentsValue
      );
      setValue(newValue);
      onClaimItemChange(taskId, newValue, claimType, isError);
    },
    [
      taskId,
      claimType,
      setValue,
      onClaimItemChange,
      costToComplete,
      costToCompletePercentsValue,
    ]
  );

  const handleRemove = useCallback(
    (id: string) => {
      setIsRemoved(true);
      mutateRemove(id);
    },
    [mutateRemove, setIsRemoved]
  );

  return (
    <Box display="flex">
      <Box style={styles.ValueBox}>
        <TextField
          value={value}
          error={isError}
          onChange={handleChangeValue}
          label="Value"
          size={'small'}
          variant={'outlined'}
          disabled={isRemoved}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                {claimType === ClaimType.percent ? '%' : '$'}
              </InputAdornment>
            ),
          }}
        />
        <Typography style={styles.Hint} sx={{ ml: 2, mt: 0, pt: 0 }}>
          {hint}
        </Typography>
      </Box>
      <FormControl>
        <InputLabel
          id="type-select-label"
          style={{ position: 'absolute', top: 0, left: '8px' }}
        >
          Type
        </InputLabel>
        <Select
          labelId="type-select-label"
          id="type-select"
          value={claimType}
          label={'Type'}
          size={'small'}
          disabled={isRemoved}
          onChange={handleChangeClaimType}
          sx={{ ml: '8px', width: '100px' }}
        >
          <MenuItem value={ClaimType.percent}>% Complete</MenuItem>
          <MenuItem value={ClaimType.value}>$ value</MenuItem>
        </Select>
      </FormControl>
      <IconButton
        sx={{ ml: '8px' }}
        disabled={isRemoved}
        onClick={() => handleRemove(id)}
      >
        <DeleteIcon />
      </IconButton>
    </Box>
  );
}

const styles: { [key: string]: React.CSSProperties } = {
  Hint: {
    color: 'rgba(0, 0, 0, 0.3)',
    fontSize: 12,
    position: 'absolute',
    right: 8,
    bottom: 8,
  },
  ValueBox: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    width: 200,
  },
} as const;

export { TaskClaimItemValueCell };
