import {
  Checkbox,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
} from '@mui/material';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import React, { ChangeEvent, useCallback } from 'react';
import { ContractStatus, Order } from '../../common/api';
import { grey600 } from '../../styles/colours';
import { HeadCell } from './SortedTable.hooks';

interface SortedTableHeadProps<T> {
  onRequestSort: (event: React.MouseEvent<unknown>, property: string) => void;
  order: Order;
  isEditable?: boolean;
  orderBy: string;
  headCells: Array<HeadCell>;
  status?: ContractStatus;
  selectAll?: Array<string>;
  handleSelectAll?: (event: ChangeEvent<HTMLInputElement>) => void;
  data?: Array<T>;
}

function SortedTableHead<T>(props: SortedTableHeadProps<T>) {
  const {
    order,
    orderBy,
    headCells,
    isEditable = false,
    status,
    onRequestSort,
    selectAll,
    handleSelectAll,
    data,
  } = props;

  const isDraft = status === ContractStatus.Draft;

  const createSortHandler = useCallback(
    (property: string) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    },
    [onRequestSort]
  );

  return (
    <TableHead
      sx={{
        th: {
          fontSize: '12px',
          padding: '10px',
          color: grey600,
        },
      }}
    >
      <TableRow>
        {isDraft && isEditable && selectAll && handleSelectAll && data && (
          <TableCell>
            <Checkbox
              indeterminate={
                selectAll.length > 0 && selectAll.length < data.length
              }
              checked={selectAll.length === data.length && !!data.length}
              onChange={handleSelectAll}
            />
          </TableCell>
        )}
        {headCells.map(
          headCell =>
            !headCell.isHidden && (
              <TableCell
                key={headCell.id}
                padding={headCell.disablePadding ? 'none' : 'normal'}
                sortDirection={orderBy === headCell.id ? order : false}
              >
                {headCell.disable ? (
                  headCell.label
                ) : (
                  <TableSortLabel
                    sx={{
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'space-between',
                    }}
                    active={orderBy === headCell.id}
                    direction={orderBy === headCell.id ? order : 'asc'}
                    onClick={createSortHandler(headCell.id)}
                    IconComponent={UnfoldMoreIcon}
                  >
                    {headCell.label}
                  </TableSortLabel>
                )}
              </TableCell>
            )
        )}
      </TableRow>
    </TableHead>
  );
}

export { SortedTableHead };
