import React, { useMemo } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Grid,
  Stack,
  Theme,
  Typography,
} from '@mui/material';
import { DetailsDialog } from './DetailsDialog';
import {
  Account,
  Contract,
  ContractStatus,
  listAccounts,
  SummaryTaskData,
  VisibilityStatus,
} from '../../../common/api';
import { useDialogState } from '../../../components/dialog/dialog.hooks';
import { grey500 } from '../../../styles/colours';
import { ActionButtons, ActionListType } from './ActionButtons';
import { ContractDetailsInfoCard } from './ContractDetailsInfoCard';
import { useQuery } from '@tanstack/react-query';
import { ContractStatusChip } from './ContractStatusChip';
import { ViewDetailsDialog } from './ViewDetailsDialog';

interface Props {
  actionList?: ActionListType[];
  contract: Contract;
  contractHistory?: Contract;
  description: string;
  isReadOnly?: boolean;
  summary?: SummaryTaskData;
  reloadData: () => Promise<void>;
}

function ContractDetails({
  actionList = [],
  contract,
  contractHistory,
  description,
  isReadOnly = false,
  summary,
  reloadData,
}: Props) {
  const {
    data: accountList,
    isLoading: isAccountListLoading,
    refetch: updateAccountList,
  } = useQuery<Array<Account>>(['accounts'], () => {
    return listAccounts();
  });

  const {
    open: openDetailsDialog,
    close: closeDetailsDialog,
    isVisible: isVisibleDetailsDialog,
  } = useDialogState();

  const percent = useMemo(
    () =>
      summary && summary.value
        ? Math.round(
            ((summary.valuePaid + summary.valueApproved) * 100) / summary.value
          )
        : 0,
    [summary]
  );

  const hasVisibilitySettingsChanged = () => {
    if (
      contract.status === ContractStatus.AwaitingAcceptance &&
      contract.visibilitySettings
    ) {
      return contract.visibilitySettings.some(
        s => s.status === VisibilityStatus.Modified
      );
    }

    return false;
  };

  return (
    <>
      <Stack
        direction={'row'}
        justifyContent={'space-between'}
        alignContent={'center'}
        sx={{ marginBottom: 3 }}
      >
        <Box
          sx={{
            position: 'relative',
            display: 'flex',
            alignItems: 'center',
            gap: '1rem',
          }}
        >
          <Typography variant="h5">{description}</Typography>
          {contractHistory && contractHistory?.description !== description && (
            <div
              style={{
                color: '#ed6c02',
                position: 'absolute',
                top: '30px',
                fontWeight: 500,
                fontSize: '12px',
              }}
            >
              {contractHistory.description}
            </div>
          )}
          {!!contract.status && <ContractStatusChip status={contract.status} />}
        </Box>
        <Stack direction={'row'} spacing={4}>
          {!!actionList.length && (
            <Stack direction={'row'} spacing={2}>
              <ActionButtons actionList={actionList} />
            </Stack>
          )}
          <Button
            onClick={openDetailsDialog}
            variant={'outlined'}
            sx={{
              border: hasVisibilitySettingsChanged() ? '1px solid red' : '',
            }}
          >
            {contract.status === ContractStatus.Draft && !isReadOnly
              ? 'Update Contract Settings'
              : 'Contract Settings'}
          </Button>
        </Stack>
      </Stack>
      <Grid sx={styles.InfoGrid}>
        <Card variant="outlined" sx={{ mr: 3 }}>
          <CardContent>
            <Typography noWrap variant="caption" sx={styles.TypographyLabel}>
              Complete
            </Typography>
            <Stack direction="row" alignItems={'center'} spacing={2}>
              <CircularProgress
                sx={styles.CircularProgress}
                variant="determinate"
                value={Number(percent || 0)}
              />
              <Typography variant={'h6'} sx={styles.Percent}>
                {percent} %
              </Typography>
            </Stack>
          </CardContent>
        </Card>
        <ContractDetailsInfoCard contract={contract} summary={summary} />
      </Grid>
      {isVisibleDetailsDialog && isReadOnly && (
        <ViewDetailsDialog
          open={isVisibleDetailsDialog}
          contract={contract}
          handleClose={closeDetailsDialog}
        />
      )}
      {isVisibleDetailsDialog && !isReadOnly && (
        <DetailsDialog
          accountList={accountList}
          isAccountListLoading={isAccountListLoading}
          open={isVisibleDetailsDialog}
          contract={contract}
          reloadData={reloadData}
          handleClose={closeDetailsDialog}
          handleUpdateAccountList={updateAccountList}
        />
      )}
    </>
  );
}

const styles = {
  InfoGrid: {
    display: 'grid',
    gridTemplateColumns: 'repeat( auto-fit, minmax(100px, 1fr) )',
    gap: '0px',
    marginBottom: '24px',
  },
  TypographyLabel: {
    color: grey500,
    letterSpacing: 1,
    marginBottom: '4px',
  },

  Percent: {
    fontSize: '20px',
  },
  CircularProgress: {
    color: (theme: Theme) => theme.palette.primary.light,
  },
};

export { ContractDetails };
