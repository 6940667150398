import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import List from '@mui/material/List';
import Box from '@mui/material/Box';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import { Typography } from '@mui/material';
import { brand100, brand800, grey500 } from '../../../styles/colours';

const nav = [
  { id: 'Projects', link: '/projects' },
  { id: 'Financials', link: '/financials' },
];

function Navigator() {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <Box>
      <List
        sx={{
          display: 'flex',
        }}
      >
        {nav.map(({ id, link }) => (
          <ListItem disablePadding key={id}>
            <ListItemButton
              selected={
                location.pathname.startsWith(link) ||
                (location.pathname.startsWith('/contracts') &&
                  id === 'Projects')
              }
              sx={item}
              onClick={() => {
                navigate(link || '/projects');
              }}
            >
              <Typography sx={{ py: 1, px: 2 }} variant="body2">
                {id}
              </Typography>
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );
}

const item = {
  color: grey500,
  borderRadius: '40px',
  margin: '0px 8px',
  '&:hover, &:focus': {
    color: brand800,
    bgcolor: brand100,
  },
};

export { Navigator };
