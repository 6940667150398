import React from 'react';
import {
  Box,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  LinearProgress,
  Tooltip,
  Button,
  Divider,
} from '@mui/material';

import {
  Contract,
  ContractStatus,
  SummaryTaskData,
} from '../../../../../common/api';
import { SectionHeading, StyledTable } from '../../../../../styles';
import { grey100 } from '../../../../../styles/colours';
import { formatCurrency } from '../../../../../common/format';
import { useAuth } from '../../../../../common/auth-provider/AuthProvider';
import { isContractor } from '../../../../../common/access';

type Props = {
  contract: Contract;
  summary?: SummaryTaskData;
};

function RetentionTab({ contract, summary }: Props) {
  const { user } = useAuth();

  const withheldProgress = contract.retention
    ? (contract.retention.withheldPreviouslyApproved /
        contract.retention.withheldWhenComplete) *
      100
    : 0;

  const currentDate = new Date();

  const releasedProgress = contract.retention
    ? (contract.retention.releasedPreviouslyApproved /
        contract.retention.withheldWhenComplete) *
      100
    : 0;

  const dlpStartDate: Date | null = contract.approvedAt
    ? new Date(contract.approvedAt)
    : null;
  const dlpEndDate: Date | null =
    dlpStartDate &&
    contract.defectLiabilityNum !== null &&
    contract.defectLiabilityNum !== undefined
      ? new Date(
          new Date(dlpStartDate).setMonth(
            dlpStartDate.getMonth() + contract.defectLiabilityNum
          )
        )
      : null;

  const dlpProgress: number =
    dlpStartDate && dlpEndDate
      ? ((currentDate.getTime() - dlpStartDate.getTime()) /
          (dlpEndDate.getTime() - dlpStartDate.getTime())) *
        100
      : 0;

  const isPracticalCompletionApproved =
    contract.approvedAt !== null && contract.status === ContractStatus.Active;

  const netRetentionPreviouslyApproved = contract.retention
    ? contract.retention.withheldPreviouslyApproved -
      contract.retention.releasedPreviouslyApproved
    : 0;

  return (
    <Box>
      <Box marginBottom={4}>
        <SectionHeading marginBottom={2}>Retention</SectionHeading>
        <StyledTable>
          <TableHead
            sx={{
              backgroundColor: grey100,
            }}
          >
            <TableRow>
              <TableCell></TableCell>
              <TableCell>When Complete</TableCell>
              <TableCell>Previously Approved</TableCell>
              <TableCell
                sx={{
                  width: '50%',
                }}
              >
                Progress of Retention
              </TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>
                <strong>Withheld</strong>
              </TableCell>
              <TableCell>
                {contract.retention &&
                  formatCurrency(contract.retention.withheldWhenComplete)}
              </TableCell>
              <TableCell>
                {contract.retention &&
                  formatCurrency(contract.retention.withheldPreviouslyApproved)}
              </TableCell>
              <TableCell>
                {contract.retention && (
                  <Box
                    display="flex"
                    alignItems="center"
                    gap={1}
                    position="relative"
                  >
                    <Tooltip
                      title={`${Math.round(withheldProgress) || 0}%`}
                      arrow
                    >
                      <LinearProgress
                        variant="determinate"
                        value={withheldProgress || 0}
                        sx={{
                          flexGrow: 1,
                          height: 10,
                          borderRadius: 5,
                          backgroundColor: 'lightgray',
                          '& .MuiLinearProgress-bar': {
                            backgroundColor:
                              withheldProgress >= 100
                                ? 'darkgreen'
                                : 'lightgreen',
                          },
                        }}
                      />
                    </Tooltip>
                    {contract.minimalApproval && (
                      <Box
                        component="span"
                        position="absolute"
                        left={`${contract.minimalApproval}%`}
                        top="-15px"
                        style={{
                          color: 'red',
                          fontWeight: 'bold',
                          transform: 'translateX(-50%)',
                        }}
                      >
                        &#9660;
                      </Box>
                    )}
                  </Box>
                )}
              </TableCell>
              <TableCell sx={{ width: '150px' }}>
                {isContractor(user, contract) && (
                  <Button variant="contained" disabled={true}>
                    Claim Initial
                  </Button>
                )}
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell>
                <strong>Released</strong>
              </TableCell>
              <TableCell>{''}</TableCell>
              <TableCell>
                {contract.retention &&
                  formatCurrency(contract.retention.releasedPreviouslyApproved)}
              </TableCell>
              <TableCell>
                {contract.retention && (
                  <Box
                    display="flex"
                    alignItems="center"
                    gap={1}
                    position="relative"
                  >
                    <Tooltip
                      title={`${Math.round(releasedProgress) || 0}%`}
                      arrow
                    >
                      <LinearProgress
                        variant="determinate"
                        value={releasedProgress || 0}
                        sx={{
                          flexGrow: 1,
                          height: 10,
                          borderRadius: 5,
                          backgroundColor: 'lightgray',
                          '& .MuiLinearProgress-bar': {
                            backgroundColor: contract.approvedAt
                              ? dlpProgress < 100
                                ? 'lightgreen'
                                : 'darkgreen'
                              : 'darkgreen',
                          },
                        }}
                      />
                    </Tooltip>
                    {isPracticalCompletionApproved && dlpProgress && (
                      <Tooltip
                        title={`${Math.round(dlpProgress)}% DLP complete`}
                        arrow
                      >
                        <Box
                          component="span"
                          position="absolute"
                          left={`${dlpProgress}%`}
                          top="-15px"
                          style={{
                            color: 'red',
                            fontWeight: 'bold',
                            transform: 'translateX(-50%)',
                          }}
                        >
                          &#9660;
                        </Box>
                      </Tooltip>
                    )}
                  </Box>
                )}
              </TableCell>
              <TableCell sx={{ width: '150px' }}></TableCell>
            </TableRow>

            <TableRow>
              <TableCell>
                <strong>Net Retention</strong>
              </TableCell>
              <TableCell>{''}</TableCell>
              <TableCell>
                {contract.retention &&
                  formatCurrency(contract.retention.withheldPreviouslyApproved)}
              </TableCell>
              <TableCell></TableCell>
              <TableCell sx={{ width: '150px' }}>
                {isContractor(user, contract) && (
                  <Button variant="contained" disabled={true}>
                    Claim Initial
                  </Button>
                )}
              </TableCell>
            </TableRow>
          </TableBody>
        </StyledTable>
      </Box>

      <Box>
        <StyledTable>
          <TableHead
            sx={{
              backgroundColor: grey100,
            }}
          >
            <TableRow>
              <TableCell></TableCell>
              <TableCell>Date</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>
                <strong>Practical Completion</strong>
              </TableCell>
              <TableCell>
                {contract.approvedAt
                  ? new Date(contract.approvedAt).toLocaleDateString()
                  : 'DD / MM / YYYY'}
              </TableCell>
              <TableCell>
                {isContractor(user, contract) && (
                  <Button variant="contained" color="primary" disabled={true}>
                    Submit PC
                  </Button>
                )}
              </TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>

            <TableRow>
              <TableCell>
                <strong>End of DLP</strong>
              </TableCell>
              <TableCell>
                {dlpEndDate
                  ? new Date(dlpEndDate).toLocaleDateString()
                  : 'DD / MM / YYYY'}
              </TableCell>
              <TableCell></TableCell>
              <TableCell>
                <strong>DLP Period</strong>
              </TableCell>
              <TableCell>
                {contract.defectLiabilityNum
                  ? `${contract.defectLiabilityNum} Months`
                  : 'N/A'}
              </TableCell>
            </TableRow>
          </TableBody>
        </StyledTable>
      </Box>

      <Divider sx={{ my: 3 }} />

      <Box display="flex" gap={2} mb={2}>
        <Box flex={1}>
          <SectionHeading marginBottom={2}>Calculations</SectionHeading>
          <StyledTable>
            <TableHead
              sx={{
                backgroundColor: grey100,
              }}
            >
              <TableRow>
                <TableCell></TableCell>
                <TableCell>When Complete</TableCell>
                <TableCell>Previously Approved</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>
                  <strong>Original Works</strong>
                </TableCell>
                <TableCell>
                  {formatCurrency(Number(contract.originalValue))}
                </TableCell>
                <TableCell>
                  {formatCurrency(Number(contract.approved))}
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell>
                  <strong>Variations</strong>
                </TableCell>
                <TableCell>
                  {formatCurrency(Number(contract.variationRetentionMax))}
                </TableCell>
                <TableCell>
                  {formatCurrency(Number(contract.variationRetentionInitial))}
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell></TableCell>
                <TableCell>
                  {formatCurrency(
                    Number(contract.originalValue) +
                      Number(contract.variationRetentionMax)
                  )}
                </TableCell>
                <TableCell>
                  {formatCurrency(
                    Number(contract.approved) +
                      Number(contract.variationRetentionInitial)
                  )}
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell>
                  <strong>Total Retention</strong>
                </TableCell>
                <TableCell>
                  {formatCurrency(Number(netRetentionPreviouslyApproved))}
                </TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableBody>
          </StyledTable>
        </Box>

        <Box flex={1}>
          <SectionHeading marginBottom={2}>Contract Settings</SectionHeading>
          <StyledTable>
            <TableHead
              sx={{
                backgroundColor: grey100,
              }}
            >
              <TableRow>
                <TableCell>Basis</TableCell>
                <TableCell>Max Retention</TableCell>
                <TableCell>Initial Rate</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>
                  <strong>{contract.retentionBasis}</strong>
                </TableCell>
                <TableCell>{contract.retentionMax}%</TableCell>
                <TableCell>{contract.retentionInitial}%</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <strong>{contract.variationRetentionBasis}</strong>
                </TableCell>
                <TableCell>{contract.variationRetentionMax}%</TableCell>
                <TableCell>{contract.variationRetentionInitial}%</TableCell>
              </TableRow>
            </TableBody>
            <TableHead
              sx={{
                backgroundColor: grey100,
              }}
            >
              <TableRow>
                <TableCell></TableCell>
                <TableCell>Minimal Approval</TableCell>
                <TableCell>Initial Rate</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>
                  <strong>Release Trigger</strong>
                </TableCell>
                <TableCell>{contract.minimalApproval}%</TableCell>
                <TableCell>{contract.minimalReleaseAmount}%</TableCell>
              </TableRow>
            </TableBody>
          </StyledTable>
        </Box>
      </Box>
    </Box>
  );
}

export { RetentionTab };
