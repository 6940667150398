import React from 'react';
import { JSONContent, useEditor } from '@tiptap/react';

import { RichtextEditor as RichtextEditorStyled } from './Richtext.styles';
import RichtextMenu from './RichtextMenu';
import { RICHTEXT_EXTENSIONS } from './Richtext.constants';

type Props = {
  initialContent: JSONContent | null;
  onChange: (json: JSONContent) => void;
};

const RichtextEditor = ({ initialContent, onChange }: Props) => {
  const editor = useEditor({
    content: initialContent,
    extensions: RICHTEXT_EXTENSIONS,
    onUpdate: ({ editor }) => {
      // prevent empty tags from saving
      onChange(editor.isEmpty ? {} : editor.getJSON());
    },
  });

  return (
    <div>
      {editor && <RichtextMenu editor={editor} />}
      <RichtextEditorStyled editor={editor} />
    </div>
  );
};

export default RichtextEditor;
