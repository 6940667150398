import { CheckboxStates } from '../main-visibility/types';

export const contractVisibilitySettings: CheckboxStates[] = [
  //ROW 1
  { name: 'contractListedInSubcontractorsList', isOn: false },
  { name: 'overallProgressCompleteOfContract', isOn: false },
  { name: 'subcontractorWBSClaimItemsNameOnly', isOn: false },
  { name: 'progressOfSubcontractorWBSClaimItems', isOn: false },
  { name: 'valuesOfSubcontractorWBSClaimItems', isOn: false },

  //ROW 2
  { name: 'contractorClaimsMade', isOn: false },

  //ROW 3
  { name: 'subSubmitted', isOn: false },
  { name: 'subApproved', isOn: false },
  { name: 'subRejected', isOn: false },
  { name: 'subWithdrawn', isOn: false },
  { name: 'subAnticipated', isOn: false },

  //ROW 4
  { name: 'paymentScheduleSopaAlertSentToContractor', isOn: false },

  //ROW 5
  { name: 'child_subcontractorNotPaid', isOn: false },
  { name: 'child_contractorWBSValueRange', isOn: false },
  { name: 'child_contractorExactWBSValues', isOn: false },

  //ROW 6
  { name: 'contractorComplianceDocumentsExpire', isOn: false },

  //ROW 7
  { name: 'subContractListedInSubcontractorsList', isOn: false },
  { name: 'subOverallProgressCompleteOfContract', isOn: false },
  { name: 'subSubcontractorWBSClaimItemsNameOnly', isOn: false },
  { name: 'subProgressOfSubcontractorWBSClaimItems', isOn: false },
  { name: 'subValuesOfSubcontractorWBSClaimItems', isOn: false },

  //ROW 8
  { name: 'subContractorClaimsMade', isOn: false },

  //ROW 9
  { name: 'subSubSubmitted', isOn: false },
  { name: 'subSubApproved', isOn: false },
  { name: 'subSubRejected', isOn: false },
  { name: 'subSubWithdrawn', isOn: false },
  { name: 'subSubAnticipated', isOn: false },

  //ROW 10
  { name: 'subPaymentScheduleSopaAlertSentToContractor', isOn: false },

  //ROW 11
  { name: 'subSubcontractorNotPaid', isOn: false },
  { name: 'subContractorWBSValueRange', isOn: false },
  { name: 'subContractorExactWBSValues', isOn: false },

  //ROW 12
  { name: 'subcontractorComplianceDocumentsExpire', isOn: false },
];
