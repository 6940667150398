import { NotificationSetting } from '../../../../common/api';

const mapNotificationSettingsToState = (
  notificationSettings: NotificationSetting[]
) => {
  const mappedCheckedState: { [key: string]: boolean } = {};
  const mappedAdvancedSettings = notificationSettings.map(setting => {
    if (setting.name) {
      mappedCheckedState[setting.name] = setting.isOn;
    }

    return {
      name: setting.name,
      minimumValue: setting.minimumValue ? String(setting.minimumValue) : '',
      timeDelay: setting.timeDelay ? String(setting.timeDelay) : '',
      emailNotifications: setting.emailNotifications,
      alertColor: setting.alertColor,
    };
  });

  return {
    mappedCheckedState,
    mappedAdvancedSettings,
  };
};

const filterPaymentDefaults = (notificationSettings: NotificationSetting[]) => {
  return notificationSettings.filter(setting =>
    [
      'paymentToMeOverdue',
      'paymentToContractorOverdue',
      'contractorPaymentToSubcontractorOverdue',
      'supplyChainSubcontractorOverdue',
    ].includes(setting.name)
  );
};

const filterMyContract = (notificationSettings: NotificationSetting[]) => {
  return notificationSettings.filter(setting =>
    [
      'contractorPaymentClaimReceived',
      'myPaymentScheduleOverdue',
      'myPaymentScheduleNearlyDue',
      'myPaymentScheduleSubmitted',
      'myPaymentScheduleAccepted',
      'myPaymentScheduleRejected',
      'contractorPaymentClaimWithdrawn',
      'myPaymentMade',
      'contractorVariationReceived',
      'contractorVariationWithdrawn',
      'contractorVariationAccepted',
      'contractorVariationRejected',
      'contractorComplianceDocumentNearingExpiry',
      'contractorComplianceDocumentExpired',
    ].includes(setting.name)
  );
};

const filterMySubContract = (notificationSettings: NotificationSetting[]) => {
  return notificationSettings.filter(setting =>
    [
      'subContractorPaymentClaimReceived',
      'subMyPaymentScheduleOverdue',
      'subMyPaymentScheduleNearlyDue',
      'subMyPaymentScheduleSubmitted',
      'subMyPaymentScheduleAccepted',
      'subMyPaymentScheduleRejected',
      'subContractorPaymentClaimWithdrawn',
      'subMyPaymentMade',
      'subContractorVariationReceived',
      'subContractorVariationWithdrawn',
      'subContractorVariationAccepted',
      'subContractorVariationRejected',
      'subContractorComplianceDocumentNearingExpiry',
      'subContractorComplianceDocumentExpired',
    ].includes(setting.name)
  );
};

export {
  mapNotificationSettingsToState,
  filterMyContract,
  filterPaymentDefaults,
  filterMySubContract,
};
