import React, { useCallback } from 'react';
import { Box, TextField, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTaskGroupContext } from '../../../../../components/task-group/components/TaskGroup.context';
import { useMutation } from '@tanstack/react-query';
import { CreateTaskPayload, createTask } from '../../../../../common/api';
import { useSnackbar } from '../../../../../common/hooks/useSnackbar';

const ID_FIELD = 'id';
const NAME_FIELD = 'name';
const AMOUNT_FIELD = 'amount';

type FormData = {
  [ID_FIELD]: string;
  [NAME_FIELD]: string;
  [AMOUNT_FIELD]: string;
};

type Props = {
  selectedTaskGroup?: string | null;
  contractId: string;
  reloadData: () => void;
};

export function AddTask({
  selectedTaskGroup = '0',
  contractId,
  reloadData,
}: Props) {
  const { SnackbarComponent, showAlert } = useSnackbar();
  const { taskGroups } = useTaskGroupContext();

  const taskGroup = taskGroups.find(tg => tg.id === selectedTaskGroup);

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<FormData>({
    mode: 'onChange',
  });

  const { mutateAsync: mutateAddTask, isLoading } = useMutation(
    (payload: CreateTaskPayload) => createTask(payload),
    {
      onSuccess: () => {
        reloadData();
        reset({
          [ID_FIELD]: '',
          [NAME_FIELD]: '',
          [AMOUNT_FIELD]: '',
        });
      },
      onError: () => {
        showAlert('There was an error saving the task.', 'error');
      },
    }
  );

  const onSubmit = useCallback<SubmitHandler<FormData>>(
    (data: FormData) => {
      const newTaskData: CreateTaskPayload = {
        identifier: data[ID_FIELD],
        name: data[NAME_FIELD],
        value: Number(data[AMOUNT_FIELD]),
        tagIds: [],
        taskGroupId:
          !selectedTaskGroup || selectedTaskGroup === '0'
            ? undefined
            : selectedTaskGroup,
        contractId: contractId,
      };
      mutateAddTask(newTaskData);
    },
    [contractId, selectedTaskGroup, mutateAddTask]
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      <SnackbarComponent />
      <Box
        display="flex"
        alignItems="baseline"
        justifyContent="space-between"
        sx={{ p: '16px 0', '.MuiTextField-root': { marginRight: '16px' } }}
      >
        <Box display="flex">
          <Controller
            name={ID_FIELD}
            defaultValue=""
            control={control}
            rules={{
              required: 'ID is required',
            }}
            render={({ field }) => (
              <TextField
                sx={{ width: '80px' }}
                {...field}
                label="ID"
                size="small"
                required
                error={!!errors[ID_FIELD]}
                helperText={errors[ID_FIELD]?.message}
              />
            )}
          />
          <Controller
            name={NAME_FIELD}
            defaultValue=""
            control={control}
            rules={{
              required: 'Name is required',
            }}
            render={({ field }) => (
              <TextField
                {...field}
                label="Task name"
                required
                size="small"
                error={!!errors[NAME_FIELD]}
                helperText={errors[NAME_FIELD]?.message}
              />
            )}
          />
          <Controller
            name={AMOUNT_FIELD}
            defaultValue=""
            control={control}
            rules={{
              required: 'Amount is required',
              pattern: {
                value: /^[0-9]*$/,
                message: 'Value should be a number',
              },
            }}
            render={({ field }) => (
              <TextField
                {...field}
                label="Amount"
                required
                size="small"
                error={!!errors[AMOUNT_FIELD]}
                helperText={errors[AMOUNT_FIELD]?.message}
              />
            )}
          />
          <Typography display="inline-block" sx={{ lineHeight: '40px' }}>
            {taskGroup?.name || 'All Tasks'}
          </Typography>
        </Box>
        <LoadingButton type="submit" variant="outlined" loading={isLoading}>
          Add Task
        </LoadingButton>
      </Box>
    </form>
  );
}
