import React from 'react';
import {
  FormControl,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import { VariationHistory } from '../../../common/api';

// Styles
import { FilterInput, SmallInputLabel } from '../../../styles/inputs';

type Props = {
  data?: VariationHistory[];
  label: string;
  id: string;
  value: string;
  onChange: (event: SelectChangeEvent) => void;
};

const HistorySelect = ({ data = [], label, id, value, onChange }: Props) => {
  const renderValueString = (value: string) => {
    const { hasRevision = false, revision = null } =
      data.find(item => item.id === value) || {};
    if (hasRevision) {
      return revision ? `Revision: ${revision}` : 'Base Variation';
    }
    return 'Current revision';
  };

  return (
    <FormControl sx={{ mb: 3, width: 300 }}>
      <SmallInputLabel id={`${id}-label`}>{label}</SmallInputLabel>
      <Select
        labelId={`${id}-label`}
        id={id}
        value={value}
        label={label}
        input={<FilterInput label={label} />}
        renderValue={renderValueString}
        MenuProps={{
          disableScrollLock: true,
          PaperProps: {
            style: {
              width: 250,
              maxHeight: 36 * 4.5 + 8,
            },
          },
        }}
        onChange={onChange}
      >
        {data.length ? (
          data.map(variation => (
            <MenuItem key={variation.id} value={variation.id}>
              <ListItemText
                primary={
                  variation.hasRevision
                    ? variation.revision
                      ? `Revision: ${variation.revision}`
                      : 'Base Variation'
                    : 'Current Revision'
                }
              />
            </MenuItem>
          ))
        ) : (
          <MenuItem value="no Revisions"></MenuItem>
        )}
      </Select>
    </FormControl>
  );
};

export default HistorySelect;
