import React, { useCallback, useState } from 'react';
import { Alert, AlertColor, Snackbar } from '@mui/material';

interface SnackbarState {
  isOpen: boolean;
  title: string;
  severity: AlertColor;
}

function useSnackbar() {
  const [snackbarState, setSnackbarState] = useState<SnackbarState>({
    isOpen: false,
    title: '',
    severity: 'success',
  });

  const showAlert = useCallback((title: string, severity: AlertColor) => {
    setSnackbarState({
      isOpen: true,
      title,
      severity: severity || 'success',
    });
  }, []);

  const hideAlert = useCallback(() => {
    setSnackbarState({ ...snackbarState, isOpen: false });
  }, [snackbarState]);

  function SnackbarComponent() {
    return (
      <Snackbar
        open={snackbarState.isOpen}
        autoHideDuration={4000}
        onClose={hideAlert}
      >
        <Alert severity={snackbarState.severity} sx={{ width: '100%' }}>
          {snackbarState.title}
        </Alert>
      </Snackbar>
    );
  }

  return { showAlert, SnackbarComponent };
}

export { useSnackbar };
