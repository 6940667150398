import React, { useState } from 'react';
import { Dialog, DialogContent, Stack } from '@mui/material';
import { Heading } from '../../styles/text';
import { useMutation } from '@tanstack/react-query';
import { withdrawVariation } from '../../common/api/variations';
import { LoadingButton } from '@mui/lab';
import { DialogClose } from '../../styles';
import { generatePath, useNavigate } from 'react-router-dom';
import { VARIATION_EXISTING } from '../../common/router-util/routes';

type Props = {
  contractId: string;
  variationId: string;
  onComplete: () => void;
  handleClose: () => void;
};

function VariationWithdraw({
  contractId,
  variationId,
  onComplete,
  handleClose,
}: Props) {
  const [isWithdrawOnly, setIsWithdrawOnly] = useState<boolean>(false);
  const navigate = useNavigate();
  const { mutate, isLoading } = useMutation(
    (payload: { isNewRevision: boolean }) =>
      withdrawVariation({
        contractId,
        variationId,
        isNewRevision: payload.isNewRevision,
      }),
    {
      onSuccess: variation => {
        onComplete();
        handleClose();
        if (variation.revisedVariationId) {
          navigate(
            generatePath(VARIATION_EXISTING, {
              contractId,
              variationId: variation.revisedVariationId,
            })
          );
        }
      },
    }
  );

  return (
    <Dialog open onClose={handleClose}>
      <DialogContent sx={{ width: '360px' }}>
        <Heading>Withdraw Variation</Heading>
        <DialogClose onClose={handleClose} />
        <Stack display="flex" alignItems="center">
          <LoadingButton
            variant="contained"
            type="button"
            color="error"
            fullWidth
            sx={{ mb: '16px', maxWidth: '200px' }}
            loading={isLoading && isWithdrawOnly}
            disabled={isLoading}
            onClick={() => {
              setIsWithdrawOnly(true);
              mutate({
                isNewRevision: false,
              });
            }}
          >
            Withdraw and Close
          </LoadingButton>
          <LoadingButton
            variant="contained"
            type="button"
            fullWidth
            loading={isLoading && !isWithdrawOnly}
            disabled={isLoading}
            sx={{ maxWidth: '200px' }}
            onClick={() => {
              setIsWithdrawOnly(false);
              mutate({
                isNewRevision: true,
              });
            }}
          >
            Withdraw and Revise
          </LoadingButton>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}

export { VariationWithdraw };
