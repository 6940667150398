import { styled } from '@mui/system';
import { IconButton } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import { brand900 } from '../../styles/colours';

export const KebabMenuIconButton = styled(IconButton)`
  width: ${props => (props.size === 'small' ? `30px` : `40px`)};
  height: ${props => (props.size === 'small' ? `30px` : `40px`)};
`;

export const KebabIcon = styled(MoreVertIcon)`
  color: ${brand900};
  font-size: inherit;
  transform: ${props =>
    props.orientation === 'horizontal' ? `rotate(90deg)` : `rotate(0)`};
`;
