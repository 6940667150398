import { Contract } from '../../../common/api';

const CONTRACT_PAYMENT_TERM_FIELD = 'paymentTerms';
const CONTRACT_TITLE = 'contractTitle';
const CONTRACT_CONTINGENCY_BUDGET = 'contingencyBudget';
const ACCOUNT_ID_FILED = 'accountId';
const ACCOUNT_NAME = 'companyName';
const ACCOUNT_REPRESENTATIVE = 'companyRepresentative';
const ACCOUNT_REP_PHONE = 'companyRepPhone';
const ACCOUNT_REP_EMAIL = 'companyRepEmail';

export type FormData = {
  [ACCOUNT_ID_FILED]: string;
  [ACCOUNT_NAME]: string;
  [ACCOUNT_REPRESENTATIVE]: string;
  [ACCOUNT_REP_PHONE]: string;
  [ACCOUNT_REP_EMAIL]: string;
  [CONTRACT_CONTINGENCY_BUDGET]: number;
  [CONTRACT_PAYMENT_TERM_FIELD]: number;
  [CONTRACT_TITLE]: string;
};

export const getDefaultFormValues = (contract: Contract) => ({
  [ACCOUNT_ID_FILED]: contract.contractorAccountId,
  [ACCOUNT_NAME]: contract.contractorAccount.name,
  [ACCOUNT_REPRESENTATIVE]: contract.contractorAccount.representative,
  [ACCOUNT_REP_PHONE]: contract.contractorAccount.phone,
  [ACCOUNT_REP_EMAIL]: contract.contractorAccount.email,
  [CONTRACT_TITLE]: contract.description,
  [CONTRACT_PAYMENT_TERM_FIELD]: contract.paymentTerm,
  [CONTRACT_CONTINGENCY_BUDGET]: Number(contract.contingencyBudget),
});

export {
  CONTRACT_PAYMENT_TERM_FIELD,
  CONTRACT_TITLE,
  CONTRACT_CONTINGENCY_BUDGET,
  ACCOUNT_ID_FILED,
  ACCOUNT_NAME,
  ACCOUNT_REPRESENTATIVE,
  ACCOUNT_REP_PHONE,
  ACCOUNT_REP_EMAIL,
};
