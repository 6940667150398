import { Order } from '../api';

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  const aValue = a[orderBy];
  const bValue = b[orderBy];

  const aBool = typeof aValue === 'boolean' ? Number(aValue) : null;
  const bBool = typeof bValue === 'boolean' ? Number(bValue) : null;

  if (aBool !== null && bBool !== null) {
    return bBool - aBool;
  }

  const aNum = parseFloat(aValue as unknown as string);
  const bNum = parseFloat(bValue as unknown as string);

  if (!isNaN(aNum) && !isNaN(bNum)) {
    return bNum < aNum ? -1 : bNum > aNum ? 1 : 0;
  }

  if (bValue < aValue) {
    return -1;
  }
  if (bValue > aValue) {
    return 1;
  }

  return 0;
}

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string | boolean },
  b: { [key in Key]: number | string | boolean }
) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

export { getComparator };
