import React, { useEffect, useState } from 'react';
import { useOutletContext, useParams } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import { Box, Stack } from '@mui/material';

import { Project } from '../../common/api';
import { DashboardOutletContext } from '../../layouts/dashboard-layout';
import { useAuth } from '../../common/auth-provider/AuthProvider';
import { isUserPartOfProject } from '../../common/access';
import { HeaderBreadcrumbs } from '../../components/header-breadcrumbs';
import { AddContractDialog } from './components/AddContractDialog';
import { useProject } from '../../common/hooks/useProject';
import { Heading } from '../../styles';
import { Report } from '../../layouts/reports/Report';
import { ReportItemStyle } from '../../common/api/report-types';
import { getContractReport } from '../../common/api/reports';

type Params = {
  projectId: string;
};

function ProjectPage() {
  const queryClient = useQueryClient();

  const [containerRef, setContainerRef] = useState<HTMLDivElement | null>(null);

  const { setTitle } = useOutletContext<DashboardOutletContext>();
  const { projectId } = useParams() as Params;
  const { user } = useAuth();

  const { project } = useProject(projectId);

  const buildBreadcrumb = (project: Project) => {
    const b = [];
    b.push({
      url: `/projects`,
      label: 'Projects',
      contractorName: '',
    });

    return [
      ...b,
      {
        url: `/projects/${project.id}`,
        label: project.name,
        contractorName: '',
      },
    ];
  };

  useEffect(() => {
    if (project) {
      setTitle(
        <Box sx={{ pv: 2 }}>
          <HeaderBreadcrumbs links={buildBreadcrumb(project)} />
        </Box>
      );
    }

    return () => {
      setTitle(<></>);
    };
  }, [project, setTitle]);

  if (!project) {
    return <></>;
  }

  return (
    <>
      <Stack
        direction={'row'}
        justifyContent={'space-between'}
        alignContent={'center'}
        sx={{ marginBottom: '24px' }}
        ref={setContainerRef}
      >
        <Heading sx={{ marginBottom: '0px', fontWeight: '500' }}>
          {project.name}
        </Heading>
      </Stack>

      <Report
        containerEl={containerRef}
        projectId={projectId}
        renderActions={(reportItems, isDefaultReport, refetchReportData) => {
          const contractListItem = reportItems?.find(
            r => r.style === ReportItemStyle.ContractList
          );
          return (
            <>
              {isUserPartOfProject(user, project) && project && (
                <AddContractDialog
                  projectId={project.id}
                  contractListReportItemId={contractListItem?.reportItemList.id}
                  reloadData={async () => {
                    const reportItemId = contractListItem?.id;
                    const report = await getContractReport({
                      reportItemId: reportItemId || '',
                      isDefaultReport: isDefaultReport,
                      projectId,
                    });
                    // update contract list tile cache
                    queryClient.setQueryData(
                      [
                        isDefaultReport
                          ? 'contractListReportAll'
                          : 'contractListReport',
                        reportItemId,
                      ],
                      report
                    );
                    refetchReportData();
                  }}
                />
              )}
            </>
          );
        }}
      />
    </>
  );
}

export { ProjectPage };
