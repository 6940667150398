import { AxiosResponse } from 'axios';
import { axios } from '../api/axios';
import { useEffect, useState } from 'react';
import { useAuth } from './AuthProvider';
import { useNavigate } from 'react-router-dom';
import { refreshToken } from '../api';
import { loadAuthResponse } from './AuthResponseStore';

function AxiosInterceptor({ children }: any) {
  const navigate = useNavigate();
  const { logout, onLogin, authResponse } = useAuth();

  // Ensure the interceptors have been set before rendering children.
  const [isSet, setIsSet] = useState(false);

  useEffect(() => {
    const onRequest = (config: any) => {
      const authResponse = loadAuthResponse();
      if (config.withCredentials === false) {
        return config;
      }
      if (authResponse?.token) {
        config.headers.Authorization = `Bearer ${authResponse?.token}`;
      }
      return config;
    };

    const onRequestError = (error: any) => {
      return Promise.reject(error);
    };

    // Register the interceptor
    const requestInterceptor = axios.interceptors.request.use(
      onRequest,
      onRequestError
    );

    // Flag the interceptors as "set".
    setIsSet(true);

    // The deregister function.
    return () => {
      setIsSet(false);
      axios.interceptors.request.eject(requestInterceptor);
    };
  }, []);

  useEffect(() => {
    const onResponse = (response: AxiosResponse) => {
      return response;
    };

    const onResponseError = async (error: any) => {
      const originalRequest = error.config;

      if (
        // If we get a forbidden...
        error.response?.status === 403 &&
        // And we have a refreshToken
        authResponse?.refreshToken &&
        // And we're not calling /auth/refresh
        !originalRequest.url.endsWith('/auth/refresh') &&
        // And this request isn't already a retry
        !originalRequest._retry
      ) {
        // Mark the call for retry.
        originalRequest._retry = true;

        try {
          const newAuthResponse = await refreshToken(authResponse.refreshToken);

          // Save the new Auth Response.
          onLogin(newAuthResponse);

          // ... Then retry the original request.
          return axios(originalRequest);
        } catch (err) {
          // If we really can't recover, logout and redirect to '/'
          logout();
          navigate('/login?reason=You were logged out due to inactivity');
        }
      }

      throw error;
    };

    // Register the interceptor
    const responseInterceptor = axios.interceptors.response.use(
      onResponse,
      onResponseError
    );

    // The deregister function.
    return () => axios.interceptors.response.eject(responseInterceptor);
    //eslint-disable-next-line
  }, [authResponse]);

  return isSet && children;
}

export { AxiosInterceptor };
