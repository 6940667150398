import React, { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { Box } from '@mui/material';

import {
  Contract,
  Tag,
  ResponseListBaseType,
  Task,
  DeleteTasksPayload,
  deleteTasks,
  SummaryTaskData,
  DeleteContractPayload,
  deleteContract,
  UpdateContractPayload,
  updateContract,
  ContractStatus,
} from '../../../../common/api';

// Components
import {
  BaseTaskGroupProps,
  TaskGroups,
} from '../../../../components/task-group/TaskGroups';
import { ContractDetails } from '../../components/ContractDetails';
import { ContractDeleteDialog } from '../../components/ContractDeleteDialog';
import { Tabs, TabsItem } from '../../../../components/tabs';
import { SkeletonTable } from '../../../../components/skeleton-table';

// Tabs
import { TaskTab } from '../../components/tabs/TaskTab';

// Types
import { ActionListType } from '../../components/ActionButtons';
import { BasePaginationProps } from '../../../../common/types';

// Hooks
import { useConfirmDialog } from '../../../../common/hooks/useConfirmDialog';
import { useProject } from '../../../../common/hooks/useProject';
import { useDialogState } from '../../../../components/dialog/dialog.hooks';
import { useSnackbar } from '../../../../common/hooks/useSnackbar';

export enum PageContractDraftTabsIds {
  tasks = 'tasks',
}

type Props = BasePaginationProps &
  BaseTaskGroupProps & {
    contract: Contract;
    tasks?: ResponseListBaseType<Array<Task>>;
    reloadData: () => Promise<void>;
    reloadContract: () => Promise<void>;
    onChangeTags: (tag: Array<Tag>) => void;
    tags: Array<Tag>;
    summary?: SummaryTaskData;
    isReadOnly?: boolean;
  };

const draftTabs = [
  {
    label: 'Tasks',
    id: PageContractDraftTabsIds.tasks,
  },
];

function ContractDraftView({
  contract,
  page,
  rowsPerPage,
  summary,
  tasks,
  isReadOnly = false,
  handleChangePage,
  handleChangeRowsPerPage,
  handleSelectedTaskGroup,
  reloadContract,
  reloadData,
  selectedTaskGroup,
}: Props) {
  const navigate = useNavigate();

  const {
    open: openDeleteDialog,
    close: closeDeleteDialog,
    isVisible: isVisibleDeleteDialog,
  } = useDialogState();

  const { showAlert, SnackbarComponent } = useSnackbar();

  const { confirmAction, ConfirmDialog } = useConfirmDialog();
  useProject(contract.projectId);

  const { mutateAsync: mutateDeleteTask } = useMutation(
    (payload: DeleteTasksPayload) => deleteTasks(payload)
  );

  const { mutateAsync: mutateDeleteContract } = useMutation(
    (payload: DeleteContractPayload) => deleteContract(payload),
    {
      onError: (error: AxiosError<{ message?: string }>) => {
        showAlert(
          error?.response?.data.message || 'An unknown error occurred!',
          'error'
        );
        closeDeleteDialog();
      },
    }
  );

  const { mutateAsync: mutateContract, isLoading: isContractLoading } =
    useMutation(
      (payload: UpdateContractPayload) => updateContract(contract.id, payload),
      {
        onSuccess: async () => {
          await reloadData();
          return;
        },
      }
    );

  const {
    mutateAsync: mutateInviteContract,
    isLoading: isContractInviteLoading,
  } = useMutation(
    (payload: UpdateContractPayload) => updateContract(contract.id, payload),
    {
      onSuccess: async () => {
        await reloadData();
        return;
      },
    }
  );

  const reloadDataContract = useCallback(async () => {
    await reloadData();
  }, [reloadData]);

  const handleSubmit = useCallback(async () => {
    await mutateContract({
      status: ContractStatus.AwaitingAcceptance,
      isSubmitted: true,
    });
    await reloadContract();
  }, [mutateContract, reloadContract]);

  const handleInvite = useCallback(async () => {
    await mutateInviteContract({
      status: ContractStatus.DraftInviteSent,
      isSubmitted: true,
    });
    await reloadContract();
  }, [mutateInviteContract, reloadContract]);

  const handleDeleteTasks = useCallback(
    async (selectedTasksIds: Array<string>) => {
      await confirmAction('Are you sure you want to delete this tasks');
      await mutateDeleteTask({
        contractId: contract.id,
        taskIds: selectedTasksIds,
      });
      await reloadContract();
      await reloadData();
    },
    [reloadContract, reloadData, confirmAction, contract.id, mutateDeleteTask]
  );

  const handleDeleteContract = useCallback(async () => {
    await mutateDeleteContract({
      contractId: contract.id,
    });
    closeDeleteDialog();
    navigate(`/projects/${contract.projectId}`);
  }, [
    contract.id,
    contract.projectId,
    navigate,
    mutateDeleteContract,
    closeDeleteDialog,
  ]);

  const draftActionList: ActionListType[] = useMemo(() => {
    const backAction = {
      id: 'back',
      label: 'Back',
      action: () => {
        navigate(`/projects/${contract.projectId}`);
      },
    };

    if (isReadOnly) {
      return [backAction];
    }
    return [
      backAction,
      {
        id: 'delete',
        label: 'Delete',
        color: 'error',
        variant: 'contained',
        action: openDeleteDialog,
      },
      {
        id: 'invite',
        isLoading: isContractInviteLoading,
        label: 'Invite submission',
        color: 'success',
        variant: 'outlined',
        textColor: 'green',
        borderColor: 'green',
        action: handleInvite,
      },
      {
        id: 'submit',
        isDisabled: !tasks?.data.length,
        isLoading: isContractLoading,
        label: 'Submit for Acceptance',
        color: 'success',
        variant: 'contained',
        action: handleSubmit,
      },
    ];
  }, [
    contract.projectId,
    tasks?.data.length,
    handleSubmit,
    handleInvite,
    navigate,
    isReadOnly,
    isContractInviteLoading,
    isContractLoading,
    openDeleteDialog,
  ]);

  return (
    <>
      <ContractDetails
        contract={contract}
        actionList={draftActionList}
        description={contract.description}
        isReadOnly={isReadOnly}
        reloadData={reloadContract}
        summary={summary}
      />
      {isVisibleDeleteDialog && (
        <ContractDeleteDialog
          contractName={contract.description}
          isOpen={isVisibleDeleteDialog}
          onClose={closeDeleteDialog}
          onDelete={handleDeleteContract}
        />
      )}
      <Box>
        <Tabs tabsData={draftTabs}>
          <TabsItem id={PageContractDraftTabsIds.tasks}>
            <TaskGroups
              canEdit={false}
              contractId={contract.id}
              queryId="contract-approve"
              selectedTaskGroup={selectedTaskGroup}
              handleSelectedTaskGroup={handleSelectedTaskGroup}
              render={() => (
                <>
                  {tasks ? (
                    <TaskTab
                      contract={contract}
                      isEditable={!isReadOnly}
                      tasks={tasks}
                      selectedTaskGroup={selectedTaskGroup}
                      reloadContract={reloadContract}
                      reloadData={reloadDataContract}
                      page={page}
                      rowsPerPage={rowsPerPage}
                      handleChangePage={handleChangePage}
                      handleChangeRowsPerPage={handleChangeRowsPerPage}
                      handleDeleteTask={handleDeleteTasks}
                    />
                  ) : (
                    <SkeletonTable />
                  )}
                </>
              )}
            />
          </TabsItem>
        </Tabs>
      </Box>
      <ConfirmDialog />
      <SnackbarComponent />
    </>
  );
}

export { ContractDraftView };
