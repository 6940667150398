import {
  Box,
  Button,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TableCell,
  TextField,
  Typography,
} from '@mui/material';
import React, { ChangeEvent, useCallback, useMemo, useState } from 'react';
import { ClaimType } from '../../../../../common/api';
import { getIsError } from './CurrentClaimTab.utils';

interface Props {
  id: string;
  totalValue: number;
  costToComplete: number;
  defaultValue?: number | null;
  defaultClaimType?: ClaimType;
  handleAddToCurrentClaim: (value: number, claimType: ClaimType) => void;
  taskType: string;
}

function TaskClaimItemAddCell({
  totalValue,
  costToComplete,
  defaultValue = null,
  defaultClaimType = ClaimType.value,
  handleAddToCurrentClaim,
  taskType,
}: Props) {
  const [claimType, setClaimType] = useState<ClaimType>(defaultClaimType);
  const [value, setValue] = useState<number | null>(defaultValue);
  const valueClaim = value === null ? 0 : value;
  const costToCompletePercentsValue = Math.floor(
    (costToComplete / totalValue) * 100
  );
  const isError = getIsError(
    value,
    claimType,
    costToComplete,
    costToCompletePercentsValue
  );

  const hint = useMemo(() => {
    if (totalValue === 0) {
      return '';
    }
    if (claimType === ClaimType.value) {
      return ((valueClaim / totalValue) * 100).toFixed(0) + '%';
    } else {
      return '$' + (totalValue * (valueClaim / 100)).toFixed(0);
    }
  }, [totalValue, valueClaim, claimType]);

  const handleChangeClaimType = useCallback(
    (e: SelectChangeEvent<string>) => {
      const newType = e.target.value as ClaimType;
      const newValue =
        newType === ClaimType.value
          ? Math.round(totalValue * (valueClaim / 100))
          : Math.round((valueClaim / totalValue) * 100);
      setValue(newValue);
      setClaimType(newType);
    },
    [setClaimType, setValue, valueClaim, totalValue]
  );

  const handleChangeValue = useCallback(
    (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const input = e.target.value;
      if (!input.match(/^[0-9]*$/)) {
        return;
      }
      const newValue = !!input ? parseInt(input) : null;
      setValue(newValue);
    },
    [setValue]
  );

  const handleMilestoneClick = useCallback(() => {
    handleAddToCurrentClaim(100, ClaimType.percent);
  }, [handleAddToCurrentClaim]);

  return (
    <>
      {taskType === 'milestone' ? (
        <TableCell colSpan={2} align="center">
          <Button
            type="button"
            variant="outlined"
            onClick={event => {
              event.stopPropagation();
              handleMilestoneClick();
            }}
          >
            Add
          </Button>
        </TableCell>
      ) : (
        <>
          <TableCell onClick={e => e.stopPropagation()}>
            <Box style={styles.ValueBox}>
              <TextField
                value={value === null ? '' : value}
                placeholder="0"
                error={isError}
                onChange={handleChangeValue}
                label="Value"
                size={'small'}
                variant={'outlined'}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      {claimType === ClaimType.percent ? '%' : '$'}
                    </InputAdornment>
                  ),
                }}
              />
              <Typography style={styles.Hint} sx={{ ml: 2, mt: 0, pt: 0 }}>
                {hint}
              </Typography>
            </Box>
          </TableCell>
          <TableCell>
            <Box
              display="flex"
              alignItems="baseline"
              justifyContent="end"
              onClick={e => e.stopPropagation()}
            >
              <FormControl
                style={{
                  position: 'relative',
                  justifyContent: 'middle',
                  width: '100%',
                }}
              >
                <InputLabel
                  id="type-select-label"
                  style={{ position: 'absolute', top: 0, left: 0 }}
                >
                  Type
                </InputLabel>
                <Select
                  labelId="type-select-label"
                  id="type-select"
                  value={claimType}
                  label={'Type'}
                  size={'small'}
                  onChange={handleChangeClaimType}
                >
                  <MenuItem value={ClaimType.percent}>% Complete</MenuItem>
                  <MenuItem value={ClaimType.value}>$ value</MenuItem>
                </Select>
              </FormControl>
              <Button
                type="button"
                variant="outlined"
                sx={{ ml: '8px', height: '40px' }}
                disabled={valueClaim <= 0 || isError}
                onClick={() => handleAddToCurrentClaim(valueClaim, claimType)}
              >
                Add
              </Button>
            </Box>
          </TableCell>
        </>
      )}
    </>
  );
}

const styles: { [key: string]: React.CSSProperties } = {
  Hint: {
    color: 'rgba(0, 0, 0, 0.3)',
    fontSize: 12,
    position: 'absolute',
    right: 8,
    bottom: 8,
  },
  ValueBox: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    width: 200,
  },
} as const;

export { TaskClaimItemAddCell };
