import {
  Button,
  Dialog,
  DialogContent,
  InputAdornment,
  TextField,
  Tooltip,
} from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Controller, FieldError, useForm } from 'react-hook-form';
import { useHotkeys } from 'react-hotkeys-hook';
import {
  BaseContract,
  createTask,
  CreateTaskPayload,
  Tag,
} from '../../../common/api';
import { ContractsAutocomplete } from './ContractsAutocomplete';

interface Props {
  onRefetchContracts: () => void;
  isOpen: boolean;
  display: boolean;
  parentTaskId: string;
  lastTaskId?: string;
  onClose: () => void;
  projectId: string;
}

const TASK_ID = 'taskId';
const DESCRIPTION_FIELD = 'description';
const CONTRACT_FIELD = 'contract';
const VALUE_FIELD = 'value';
const TAGS_FIELD = 'tags';

export interface Form {
  [TASK_ID]: string;
  [DESCRIPTION_FIELD]: string;
  [CONTRACT_FIELD]: BaseContract | null;
  [VALUE_FIELD]: number | null;
  [TAGS_FIELD]?: Array<Tag>;
}

function AddSubtaskDialog(props: Props) {
  const [contractorInputValue, setContractorInputValue] = useState<string>('');

  const descriptionInputRef = useRef<HTMLInputElement | null>(null);

  const {
    onRefetchContracts,
    isOpen,
    display,
    parentTaskId,
    lastTaskId,
    onClose,
    projectId,
  } = props;

  const numericValue = lastTaskId ? parseFloat(lastTaskId) : 1;
  const incrementedValue = numericValue + 0.1; // Perform the increment
  const nextTaskId = incrementedValue.toFixed(1);

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    getValues,
    resetField,
    formState: { errors },
  } = useForm<Form>();

  useEffect(() => {
    setValue(TASK_ID, nextTaskId.toString());
  }, [nextTaskId, setValue]);

  const { mutateAsync: mutateAddSubtask, isLoading } = useMutation(
    (payload: CreateTaskPayload) => createTask(payload)
  );

  const handleResetForm = useCallback(
    () =>
      reset({
        taskId: nextTaskId,
        description: '',
        contract: null,
        value: null,
        tags: [],
      }),
    [nextTaskId, reset]
  );

  const handleCloseDialog = useCallback(() => {
    onClose();
    handleResetForm();

    setContractorInputValue('');
    descriptionInputRef.current?.focus();
  }, [handleResetForm, onClose]);

  const onSubmit = useCallback(
    async (form: Form) => {
      if (!form.contract?.id) {
        return;
      }

      const newTaskData: CreateTaskPayload = {
        identifier: form[TASK_ID],
        parentTaskId,
        name: form.description,
        value: form.value,
        tagIds: form.tags?.map(tag => tag.id) || [],
        contractId: form.contract.id,
      };

      await mutateAddSubtask(newTaskData);

      onRefetchContracts();
    },
    [parentTaskId, mutateAddSubtask, onRefetchContracts]
  );

  const handleClickAddAnother = useCallback(
    async (form: Form) => {
      await onSubmit(form);
      handleResetForm();
      setContractorInputValue('');
      descriptionInputRef.current?.focus();
    },
    [handleResetForm, onSubmit]
  );

  const handleClickAdd = useCallback(
    async (form: Form) => {
      await onSubmit(form);
      handleCloseDialog();
    },
    [handleCloseDialog, onSubmit]
  );

  useHotkeys('ctrl+enter', () => handleSubmit(handleClickAddAnother)(), {
    enabled: display,
    enableOnFormTags: true,
  });

  useEffect(() => {
    handleResetForm();
  }, [handleResetForm]);

  return (
    <Dialog open={isOpen} onClose={handleCloseDialog}>
      <DialogContent
        style={{
          ...styles.DialogContent,
          display: display ? 'grid' : 'none',
        }}
      >
        <Controller
          name={DESCRIPTION_FIELD}
          defaultValue=""
          control={control}
          rules={{
            required: 'Description is required',
          }}
          render={({ field }) => (
            <TextField
              {...field}
              label="Task Description"
              required
              fullWidth
              autoFocus
              error={!!errors.description}
              helperText={errors.description?.message?.toString()}
              style={styles.Field}
            />
          )}
        />
        <Controller
          name={CONTRACT_FIELD}
          control={control}
          defaultValue={undefined}
          rules={{
            required: 'Contract is required',
          }}
          render={({ field }) => (
            <ContractsAutocomplete
              field={field}
              error={errors.contract as FieldError}
              contractorInputValue={contractorInputValue}
              setContractorInputValue={setContractorInputValue}
              setValue={setValue}
              getValues={getValues}
              resetField={resetField}
              projectId={projectId}
            />
          )}
        />
        <Controller
          name={VALUE_FIELD}
          control={control}
          defaultValue={null}
          rules={{
            required: 'Value is required',
            pattern: {
              value: /^[0-9]*$/,
              message: 'Value should be a number',
            },
          }}
          render={({ field, field: { value } }) => (
            <TextField
              {...field}
              prefix="$"
              label="Task Value"
              required
              value={value === null ? '' : value}
              placeholder="0"
              fullWidth
              style={styles.Field}
              error={!!errors.value}
              helperText={errors.value?.message?.toString()}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
              }}
            />
          )}
        />
        <div style={styles.ButtonGroup}>
          <Button onClick={handleCloseDialog}>CANCEL</Button>
          <div>
            <Tooltip title="Keyboard hotkey: Ctrl+Enter" placement="left">
              <Button
                variant="outlined"
                disabled={isLoading}
                onClick={handleSubmit(handleClickAddAnother)}
              >
                ADD ANOTHER
              </Button>
            </Tooltip>
            <Button
              variant="contained"
              style={styles.Button}
              type="submit"
              disabled={isLoading}
              onClick={handleSubmit(handleClickAdd)}
            >
              ADD
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}

const styles: { [key: string]: React.CSSProperties } = {
  DialogContent: {
    width: '600px',
  },
  Field: {
    margin: '10px 0',
  },
  InputLabel: {
    top: '10px',
  },
  ButtonGroup: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '10px',
  },
  Button: {
    marginLeft: '10px',
  },
};

export { AddSubtaskDialog };
