import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Contract,
  Tag,
  ResponseListBaseType,
  Task,
  DeleteTasksPayload,
  deleteTasks,
  SummaryTaskData,
  approvalList,
  claimList,
  SubContract,
  listSubContracts,
  ContractFile,
  listContractFiles,
  ClaimStatus,
} from '../../../../common/api';
import { TaskTab } from './components/TaskTab';
import { Box, Tab, Typography } from '@mui/material';
import { useMutation, useQuery } from '@tanstack/react-query';
import { ComplianceTab } from '../../../../components/compliance-tab';
import { Params, useLocation, useParams } from 'react-router-dom';
import { BasePaginationProps } from '../../../../common/types';
import { useListPagination } from '../../../../common/hooks/useListPagination';
import { StyledTabs } from '../../../../styles';
import {
  isContractor,
  isCustomer,
  isProjectOwner,
} from '../../../../common/access';
import { useAuth } from '../../../../common/auth-provider/AuthProvider';
import { SkeletonTable } from '../../../../components/skeleton-table';
import { SubContractTab } from './components/SubContractTab';
import {
  BaseTaskGroupProps,
  TaskGroups,
} from '../../../../components/task-group/TaskGroups';
import { ClaimsReceivedTab } from '../../../../components/approvals-tab';
import { useConfirmDialog } from '../../../../common/hooks/useConfirmDialog';
import { ContractDetails } from '../../components/ContractDetails';
import { ClaimHistory } from '../../components/ClaimHistory';
import { VariationsMadeTab } from '../../components/VariationsMade';
import { VariationsReceivedTab } from '../../components/VariationsReceived';
import { PageContractApproveTabs } from '../contract-approve-view/ContractApproveView';
import { useProject } from '../../../../common/hooks/useProject';
import {
  formatDaysTillDate,
  projectDate,
} from '../../../../common/format/formatDate';
import Badge from '@mui/material/Badge';
import dayjs from 'dayjs';

export enum ContractReadViewTabs {
  tasks = 'tasks',
  subContracts = 'subContracts',
  approvals = 'approvals',
  compliance = 'compliance',
  claims = 'claims',
  variationsMade = 'variationsMade',
  variationsReceived = 'variationsReceived',
}

type Props = BasePaginationProps &
  BaseTaskGroupProps & {
    contract: Contract;
    tasks?: ResponseListBaseType<Array<Task>>;
    reloadData: () => Promise<void>;
    reloadContract: () => Promise<void>;
    onChangeTags: (tag: Array<Tag>) => void;
    tags: Array<Tag>;
    summary?: SummaryTaskData;
  };

function ContractReadView(props: Props) {
  const {
    contract,
    tasks,
    reloadData,
    handleChangePage,
    handleChangeRowsPerPage,
    page,
    rowsPerPage,
    handleSelectedTaskGroup,
    selectedTaskGroup,
    summary,
    reloadContract,
  } = props;

  const location = useLocation();
  const { user } = useAuth();
  const { project } = useProject();
  const { contractId, subcontractId } = useParams<Params>();

  const [tabsValue, setTabsValue] = useState<ContractReadViewTabs>(
    ContractReadViewTabs.tasks
  );

  useEffect(() => {
    if (location.state?.tab) {
      setTabsValue(location.state?.tab);
    }
  }, [location]);

  const handleChangeTabsValue = useCallback(
    (
      event: React.SyntheticEvent<Element, Event>,
      value: ContractReadViewTabs
    ) => {
      setTabsValue(value);
    },
    []
  );

  const { confirmAction, ConfirmDialog } = useConfirmDialog();

  const {
    page: pageApproval,
    rowsPerPage: rowsPerPageApproval,
    handleChangePage: handleChangePageApproval,
    skip,
  } = useListPagination();

  const {
    page: pageClaims,
    rowsPerPage: rowsPerPageClaims,
    handleChangePage: handleChangePageClaims,
  } = useListPagination();

  const { mutateAsync: mutateDeleteTask } = useMutation(
    (payload: DeleteTasksPayload) => deleteTasks(payload)
  );

  const { data: approvalClaims, isFetching: isLoading } = useQuery(
    [contract, rowsPerPageApproval, pageApproval, 'approvals'],
    () => approvalList(contract.id, rowsPerPageApproval, skip)
  );

  const {
    data: contractFiles,
    isLoading: isContractFilesLoading,
    refetch: refetchContractFiles,
  } = useQuery<Array<ContractFile>>(
    ['node-files', subcontractId || contractId],
    () => listContractFiles(subcontractId || contractId!)
  );

  const { data: claims, refetch: refetchClaims } = useQuery(
    [contract, rowsPerPageClaims, pageClaims, 'claims'],
    () =>
      claimList(
        contract.id,
        rowsPerPageClaims,
        rowsPerPageClaims * (pageClaims - 1)
      )
  );

  const {
    data: subcontracts,
    isLoading: isSubContractsLoading,
    refetch: refetchSubContracts,
  } = useQuery<ResponseListBaseType<Array<SubContract>>>(
    ['subcontracts', contract.id],
    () => listSubContracts({ contractId: contract.id, take: 50, skip: 0 })
  );

  const handleDeleteTasks = useCallback(
    async (selectedTasksIds: Array<string>) => {
      await confirmAction('Are you sure you want to delete this tasks');
      await mutateDeleteTask({
        contractId: contract.id,
        taskIds: selectedTasksIds,
      });
      await reloadData();
    },
    [reloadData, confirmAction, contract.id, mutateDeleteTask]
  );

  const reloadDataContract = useCallback(async () => {
    await reloadData();
  }, [reloadData]);

  const reloadContractFiles = useCallback(() => {
    (async () => {
      await refetchContractFiles();
    })();
  }, [refetchContractFiles]);

  const reloadSubContracts = useCallback(() => {
    (async () => {
      await refetchSubContracts();
    })();
  }, [refetchSubContracts]);

  const reloadClaims = useCallback(() => {
    (async () => {
      await refetchClaims();
    })();
  }, [refetchClaims]);

  const tabs = useMemo(() => {
    const isNoProjectOwner = user && !isProjectOwner(user, contract);

    const parentIds = [
      'submitted',
      'approved',
      'rejected',
      'withdrawn',
      'anticipated',
    ];
    const isShowVariationMade = contract.visibilitySettings?.some(
      setting => parentIds.includes(setting.name) && setting.isOn
    );

    const subContractsNotifications = subcontracts?.data?.reduce(
      (acc, subcontract) => {
        return acc + (subcontract.notifications?.length ? 1 : 0);
      },
      0
    );

    const expiredClaims = claims?.data?.filter(claim => {
      const sopaDate = formatDaysTillDate(
        claim.expiration,
        projectDate(project?.systemDate)
      );

      const isOverdue =
        (sopaDate > 0 && claim.status !== ClaimStatus.Draft) ||
        (dayjs(claim.paymentDueAt) < projectDate(project?.systemDate) &&
          claim.status !== ClaimStatus.Paid);

      return isOverdue;
    }).length;

    return [
      {
        label: 'Tasks',
        isShow: true,
        value: PageContractApproveTabs.tasks,
      },
      {
        label: 'Claims Made',
        redDotsLength: expiredClaims,
        value: PageContractApproveTabs.claims,
        isShow: true,
      },
      {
        label: 'Sub-Contracts',
        isShow: true,
        redDotsLength: subContractsNotifications,
        value: PageContractApproveTabs.subContracts,
      },
      {
        label: 'Claims Received',
        isShow: true,
        value: PageContractApproveTabs.claimsReceived,
      },
      {
        label: 'Variations Made',
        isShow: isCustomer(user, contract)
          ? isShowVariationMade
          : isNoProjectOwner,
        value: PageContractApproveTabs.variationsMade,
      },
      {
        label: 'Variations Received',
        isShow: true,
        value: PageContractApproveTabs.variationsReceived,
      },
      {
        label: 'Compliance',
        isShow: true,
        value: PageContractApproveTabs.compliance,
      },
    ];
  }, [user, contract, subcontracts?.data, claims?.data, project?.systemDate]);

  return (
    <>
      <Box>
        <ContractDetails
          description={contract.description}
          summary={summary}
          reloadData={reloadContract}
          contract={contract}
        />
        <StyledTabs
          sx={{ pt: 2, mb: '16px' }}
          textColor="inherit"
          value={tabsValue}
          onChange={handleChangeTabsValue}
        >
          {tabs.map(({ isShow, redDotsLength, label, value }) => {
            if (!isShow) {
              return null;
            }

            if (redDotsLength) {
              return (
                <Tab
                  value={value}
                  sx={{ ml: 0, mr: 4 }}
                  wrapped
                  key={value}
                  label={
                    <Box sx={{ display: 'flex', px: '8px' }}>
                      <Typography fontSize="14px" fontWeight={500}>
                        {label}
                      </Typography>
                      {Boolean(redDotsLength) && (
                        <Badge
                          color="error"
                          badgeContent={redDotsLength}
                          sx={{
                            transform: 'scale(0.7)',
                            ml: '8px',
                          }}
                        />
                      )}
                    </Box>
                  }
                />
              );
            }

            return (
              <Tab
                key={value}
                sx={{ ml: 0, mr: 4 }}
                label={label}
                value={value}
              />
            );
          })}
        </StyledTabs>
      </Box>

      {tabsValue === ContractReadViewTabs.tasks && (
        <TaskGroups
          canEdit={false}
          contractId={contract.id}
          queryId="contract-approve"
          selectedTaskGroup={selectedTaskGroup}
          handleSelectedTaskGroup={handleSelectedTaskGroup}
          render={reloadTree => (
            <>
              {tasks ? (
                <TaskTab
                  contract={contract}
                  tasks={tasks}
                  selectedTaskGroup={selectedTaskGroup}
                  reloadContract={reloadContract}
                  reloadData={reloadDataContract}
                  page={page}
                  rowsPerPage={rowsPerPage}
                  handleChangePage={handleChangePage}
                  handleChangeRowsPerPage={handleChangeRowsPerPage}
                  handleDeleteTask={handleDeleteTasks}
                />
              ) : (
                <SkeletonTable />
              )}
            </>
          )}
        />
      )}
      {tabsValue === ContractReadViewTabs.compliance && user && (
        <ComplianceTab
          contract={contract}
          isContractor={isContractor(user, contract)}
          isContractFilesLoading={isContractFilesLoading}
          refetchContractFiles={reloadContractFiles}
          contractFiles={contractFiles}
        />
      )}

      {tabsValue === ContractReadViewTabs.subContracts && (
        <SubContractTab
          contract={contract}
          data={subcontracts?.data}
          refetchSubContracts={reloadSubContracts}
          isLoading={isSubContractsLoading}
        />
      )}

      {tabsValue === ContractReadViewTabs.approvals && (
        <ClaimsReceivedTab
          approvals={approvalClaims?.data}
          approvalsTotal={approvalClaims?.meta.total || 0}
          handleChangePage={handleChangePageApproval}
          page={pageApproval}
          isLoading={isLoading}
        />
      )}

      {tabsValue === ContractReadViewTabs.claims && user && (
        <>
          <ClaimHistory
            claims={claims?.data || []}
            isContractor={isContractor(user, contract)}
            isCustomer={isCustomer(user, contract)}
            reloadClaims={reloadClaims}
            reloadContract={reloadContract}
            page={pageClaims}
            handleChangePage={handleChangePageClaims}
            rowsPerPage={rowsPerPageClaims}
            total={claims?.meta.total || 0}
          />
        </>
      )}

      {tabsValue === ContractReadViewTabs.variationsMade && (
        <VariationsMadeTab contractId={contract.id} />
      )}

      {tabsValue === ContractReadViewTabs.variationsReceived && (
        <VariationsReceivedTab
          contingencyBudget={contract.contingencyBudget}
          contractId={contract.id}
        />
      )}

      <ConfirmDialog />
    </>
  );
}

export { ContractReadView };
