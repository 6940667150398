import { axios } from './axios';
import { Bank, SaveBankAccount } from './types';

async function getBankAccountsByAccountId(
  accountId: string
): Promise<Array<Bank>> {
  const resp = await axios.get(`/accounts/${accountId}/bank`);
  return resp.data;
}

async function saveBankAccount(
  accountId: string,
  payload: SaveBankAccount
): Promise<Bank> {
  if (payload.id) {
    const resp = await axios.patch(`/accounts/${accountId}/bank`, payload);
    return resp.data;
  }
  const resp = await axios.post(`/accounts/${accountId}/bank/create`, payload);
  return resp.data;
}

async function deleteBankAccount(
  accountId: string,
  bankId: string
): Promise<Boolean> {
  const resp = await axios.delete(`/accounts/${accountId}/bank/${bankId}`);
  return resp.data;
}

export { getBankAccountsByAccountId, saveBankAccount, deleteBankAccount };
