import { RententionBasis } from '../../../common/api/contract-types';

export const CONTRACT_TITLE_FIELD = 'contractTitle';
export const CONTRACT_REF_FIELD = 'contractorRef';
export const CONTRACT_AWARD_FIELD = 'contractAward';
export const CONTRACT_JURISDICTION = 'jurisdiction';
export const CONTRACT_CONTRACTOR_NAME = 'contractorName';
export const CONTRACT_PAYMENT_TERMS_FIELD = 'paymentTerm';
export const COMPANY_REPRESENTATIVE_FIELD = 'companyRepresentative';
export const COMPANY_REP_EMAIL_FIELD = 'companyRepEmail';
export const COMPANY_REP_PHONE_FIELD = 'companyRepPhone';
export const CLAIMS_DUE_ON = 'claimsDueOn';
export const FOR_WORK_COMPLETED_TO = 'forWorkCompletedTo';
export const DEFECT_LIABILITY_NUMBER = 'defectLiabilityNum';
export const DEFECT_LIABILITY_TERM = 'defectLiabilityTerm';
export const RETENTION_BASIS = 'retentionBasis';
export const RETENTION_MAX = 'retentionMax';
export const RETENTION_INITIAL = 'retentionInitial';
export const VARIATION_RETENTION_BASIS = 'variationRetentionBasis';
export const VARIATION_RETENTION_MAX = 'variationRetentionMax';
export const VARIATION_RETENTION_INITIAL = 'variationRetentionInitial';
export const RETENTION_MINIMAL_APPROVAL = 'minimalApproval';
export const RETENTION_INITIAL_RELEASE_AMOUNT = 'minimalReleaseAmount';

export interface FormData {
  [CONTRACT_TITLE_FIELD]: string;
  [CONTRACT_REF_FIELD]: string;
  [CONTRACT_AWARD_FIELD]: string;
  [CONTRACT_PAYMENT_TERMS_FIELD]: number;
  [COMPANY_REPRESENTATIVE_FIELD]: string;
  [COMPANY_REP_EMAIL_FIELD]: string;
  [COMPANY_REP_PHONE_FIELD]: string;
  [CONTRACT_JURISDICTION]: string;
  [CONTRACT_CONTRACTOR_NAME]: string;
  [CLAIMS_DUE_ON]: string;
  [FOR_WORK_COMPLETED_TO]: string;
  [DEFECT_LIABILITY_NUMBER]: string;
  [DEFECT_LIABILITY_TERM]: string;
  [RETENTION_BASIS]: RententionBasis;
  [RETENTION_MAX]?: string;
  [RETENTION_INITIAL]?: string;
  [VARIATION_RETENTION_BASIS]: RententionBasis;
  [VARIATION_RETENTION_MAX]?: string;
  [VARIATION_RETENTION_INITIAL]?: string;
  [RETENTION_MINIMAL_APPROVAL]: string;
  [RETENTION_INITIAL_RELEASE_AMOUNT]: string;
}
