import React, { useCallback, useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { CircularProgress, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';

import {
  Tag,
  getContract,
  ResponseListBaseType,
  Contract,
  Task,
  listTasks,
  SummaryTaskData,
  summaryTask,
  ContractStatus,
} from '../../common/api';

// Layout
import { ContractClaimView } from './views/contract-claim-view/ContractClaimView';
import { ContractReadView } from './views/contract-read-view/ContractReadView';
import { ContractDraftView } from './views/contract-draft-view/ContractDraftView';
import { ContractAwaitingView } from './views/contrat-awaiting-view/ContractAwaitingView';

// Utils
import { useAuth } from '../../common/auth-provider/AuthProvider';
import {
  canApproveOrRejectClaimOnNode,
  canClaimNode,
} from '../../common/access';
import { ContractApproveView } from './views/contract-approve-view/ContractApproveView';
import { useListPagination } from '../../common/hooks/useListPagination';
import { useContractsBreadcrumb } from './hooks/useContractsBreadcrumb';

type Params = {
  contractId: string;
  subcontractId: string;
};

function ContractPage() {
  const { contractId, subcontractId } = useParams() as Params;
  const [searchParams] = useSearchParams();
  const { user } = useAuth();
  const [tags, setTags] = useState<Array<Tag>>([]);
  const [selectedTaskGroup, setSelectedTaskGroup] = useState<string | null>(
    '0'
  );

  const { page, rowsPerPage, handleChangePage, handleChangeRowsPerPage, skip } =
    useListPagination();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  if (!user) {
    throw new Error('User is required.');
  }

  const {
    data: tasks,
    refetch: refetchNodes,
    isFetching: isLoadingTasks,
  } = useQuery<ResponseListBaseType<Array<Task>>>(
    [
      'tasks',
      '?parentTaskId',
      subcontractId ?? contractId,
      rowsPerPage,
      skip,
      tags,
      selectedTaskGroup,
    ],
    () => {
      return listTasks(subcontractId ?? contractId, {
        taskGroupId: selectedTaskGroup === '0' ? null : selectedTaskGroup,
        skip: skip,
        take: rowsPerPage,
      });
    }
  );

  const [updatedTasks, setUpdatedTasks] = useState<
    ResponseListBaseType<Task[]> | undefined
  >(tasks);

  const { data: summaryTaskData, refetch: refetchSummaryTask } =
    useQuery<SummaryTaskData>(
      ['summaryTask', subcontractId ?? contractId],
      () => {
        return summaryTask(subcontractId ?? contractId);
      }
    );

  const {
    data: node,
    refetch: refetchContract,
    // isFetching: isLoadingNode,
  } = useQuery<Contract>(['contracts', subcontractId ?? contractId], () => {
    return getContract(subcontractId ?? contractId, {
      taskId: searchParams.get('taskId'),
    });
  });

  useEffect(() => {
    const updateTasks = () => {
      if (!tasks?.data || !node) {
        return;
      }

      let newParentIndex = 0;
      const updatedTasksWithIdentifiers = tasks.data.map((task, index) => {
        if (task.contractId === node.id) {
          newParentIndex++;
          return { ...task, identifier: newParentIndex.toString() };
        }
        return task;
      });

      const updatedTasksMap = new Map<string, Task>();

      updatedTasksWithIdentifiers.forEach((task: Task) => {
        const contractorName = task.contract?.contractorAccount.name;
        updatedTasksMap.set(task.id, {
          ...task,
          contractorName: contractorName,
        });
      });

      const sortedTasks = updatedTasksWithIdentifiers.sort(
        (a, b) => a.level - b.level
      );

      sortedTasks.forEach((task: Task) => {
        if (task.parentTaskId) {
          const parentTask = updatedTasksMap.get(task.parentTaskId);

          if (parentTask) {
            parentTask.children = parentTask.children || [];

            const updatedTask = updatedTasksMap.get(task.id);
            if (updatedTask) {
              parentTask.children.push(updatedTask);

              updatedTask.identifier = `${parentTask.identifier}.${parentTask.children.length}`;
              updatedTasksMap.set(task.id, updatedTask);
            }

            updatedTasksMap.set(parentTask.id, parentTask);
          }
        }
      });

      const updatedTasksData = Array.from(updatedTasksMap.values()).filter(
        task => task.contractId === node?.id
      );

      setUpdatedTasks({
        ...tasks,
        data: updatedTasksData,
      });
      setIsLoading(false);
    };

    updateTasks();
  }, [tasks, contractId, subcontractId, node, user]);

  const handleSelectedTaskGroup = useCallback((nodeId: string) => {
    setSelectedTaskGroup(nodeId);
  }, []);

  useContractsBreadcrumb({ contract: node, user });

  const reloadData = useCallback(async () => {
    await (async () => {
      await refetchNodes();
      await refetchSummaryTask();
    })();
  }, [refetchNodes, refetchSummaryTask]);

  const reloadContract = useCallback(async () => {
    await (async () => {
      await refetchContract();
    })();
  }, [refetchContract]);

  const renderView = () => {
    if (isLoading) {
      return <CircularProgress />;
    }

    if (user && node) {
      if (canApproveOrRejectClaimOnNode(user, node)) {
        if (node.status === ContractStatus.Draft) {
          return (
            <ContractDraftView
              contract={node}
              tasks={updatedTasks}
              summary={summaryTaskData}
              reloadData={reloadData}
              reloadContract={reloadContract}
              tags={tags}
              onChangeTags={setTags}
              page={page}
              rowsPerPage={rowsPerPage}
              handleChangePage={handleChangePage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              handleSelectedTaskGroup={handleSelectedTaskGroup}
              selectedTaskGroup={selectedTaskGroup}
            />
          );
        }
        if (
          node.status === ContractStatus.AwaitingAcceptance ||
          node.status === ContractStatus.DraftInviteSent
        ) {
          return (
            <ContractAwaitingView
              contract={node}
              tasks={updatedTasks}
              summary={summaryTaskData}
              reloadData={reloadData}
              reloadContract={reloadContract}
              tags={tags}
              onChangeTags={setTags}
              isCustomer
              isReviewed={
                (node.isReviewed && node.isSubmitted && !node.hasRevision) ||
                (!node.isReviewed && !node.isSubmitted)
              }
              page={page}
              rowsPerPage={rowsPerPage}
              handleChangePage={handleChangePage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              handleSelectedTaskGroup={handleSelectedTaskGroup}
              selectedTaskGroup={selectedTaskGroup}
            />
          );
        }
        return (
          <ContractApproveView
            contract={node}
            tasks={updatedTasks}
            summary={summaryTaskData}
            reloadData={reloadData}
            reloadContract={reloadContract}
            tags={tags}
            onChangeTags={setTags}
            page={page}
            rowsPerPage={rowsPerPage}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            handleSelectedTaskGroup={handleSelectedTaskGroup}
            selectedTaskGroup={selectedTaskGroup}
          />
        );
      } else if (canClaimNode(user, node)) {
        if (
          node.status === ContractStatus.AwaitingAcceptance ||
          node.status === ContractStatus.DraftInviteSent
        ) {
          return (
            <ContractAwaitingView
              contract={node}
              tasks={updatedTasks}
              summary={summaryTaskData}
              reloadData={reloadData}
              reloadContract={reloadContract}
              tags={tags}
              onChangeTags={setTags}
              page={page}
              isReviewed={
                (!node.isReviewed && node.isSubmitted && !node.hasRevision) ||
                (node.isReviewed && !node.isSubmitted)
              }
              rowsPerPage={rowsPerPage}
              handleChangePage={handleChangePage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              handleSelectedTaskGroup={handleSelectedTaskGroup}
              selectedTaskGroup={selectedTaskGroup}
            />
          );
        }
        if (node.status === ContractStatus.Draft) {
          return (
            <ContractDraftView
              contract={node}
              tasks={updatedTasks}
              isReadOnly
              summary={summaryTaskData}
              reloadData={reloadData}
              reloadContract={reloadContract}
              tags={tags}
              onChangeTags={setTags}
              page={page}
              rowsPerPage={rowsPerPage}
              handleChangePage={handleChangePage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              handleSelectedTaskGroup={handleSelectedTaskGroup}
              selectedTaskGroup={selectedTaskGroup}
            />
          );
        }
        return (
          <ContractClaimView
            contract={node}
            tasks={updatedTasks}
            summary={summaryTaskData}
            isLoadingTasks={isLoadingTasks}
            reloadData={reloadData}
            reloadContract={reloadContract}
            tags={tags}
            onChangeTags={setTags}
            page={page}
            rowsPerPage={rowsPerPage}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            handleSelectedTaskGroup={setSelectedTaskGroup}
            selectedTaskGroup={selectedTaskGroup}
          />
        );
      } else {
        return (
          <ContractReadView
            contract={node}
            tasks={updatedTasks}
            summary={summaryTaskData}
            reloadData={reloadData}
            reloadContract={reloadContract}
            tags={tags}
            onChangeTags={setTags}
            page={page}
            rowsPerPage={rowsPerPage}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            handleSelectedTaskGroup={handleSelectedTaskGroup}
            selectedTaskGroup={selectedTaskGroup}
          />
        );
      }
    }
    return <Typography>Contract Not Found</Typography>;
  };

  return renderView();
}

export { ContractPage };
