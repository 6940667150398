import { Skeleton } from '@mui/material';
import React, { Fragment } from 'react';

interface Props {
  rows?: number;
}

function SkeletonTable(props: Props) {
  const { rows = 5 } = props;

  return (
    <>
      {[...Array(rows)].map((_, index) => {
        return (
          <Fragment key={index}>
            <Skeleton
              sx={{ my: 3 }}
              variant="rectangular"
              width={'100%'}
              height={'50px'}
            />
          </Fragment>
        );
      })}
    </>
  );
}

export { SkeletonTable };
