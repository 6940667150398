import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  approvalList,
  claimList,
  Contract,
  ContractFile,
  listContractFiles,
  listSubContracts,
  ResponseListBaseType,
  SubContract,
  SummaryTaskData,
  Tag,
  Task,
  Notification,
} from '../../../../common/api';
import { Box, Tab, Typography } from '@mui/material';
import { ClaimHistory } from '../../components/ClaimHistory';
import { ComplianceTab } from '../../../../components/compliance-tab';
import { Params, useLocation, useParams } from 'react-router-dom';
import { BasePaginationProps } from '../../../../common/types';
import { useListPagination } from '../../../../common/hooks/useListPagination';
import { StyledTabs } from '../../../../styles';
import {
  isContractor,
  isCustomer,
  isProjectOwner,
} from '../../../../common/access';
import { useAuth } from '../../../../common/auth-provider/AuthProvider';
import { SubContractTab } from '../contract-approve-view/components/SubContractTab';
import {
  BaseTaskGroupProps,
  TaskGroups,
} from '../../../../components/task-group/TaskGroups';
import { TasksTab } from './components/TasksTab';
import { CurrentClaimTab } from './components/CurrentClaimTab';
import { ClaimsReceivedTab } from '../../../../components/approvals-tab';
import { useQuery } from '@tanstack/react-query';
import { ContractDetails } from '../../components/ContractDetails';
import { MoveTaskDialog } from '../../../../components/move-task-dialog';
import { VariationsMadeTab } from '../../components/VariationsMade';
import { VariationsReceivedTab } from '../../components/VariationsReceived';
import { useProject } from '../../../../common/hooks/useProject';
import {
  listVariations,
  listVariationsReceived,
} from '../../../../common/api/variations';
import Badge from '@mui/material/Badge';
import {
  getClaimReceivedGreenActions,
  getClaimReceivedRedActions,
  getClaimsMadeGreenActions,
  getClaimsMadeRedActions,
  getComplianceGreenActions,
  getComplianceRedActions,
  getVariationReceivedGreenActions,
  getVariationReceivedRedActions,
  getVariationsMadeGreenActions,
  getVariationsMadeRedActions,
} from './ContractTabs.utils';
import { useNotifications } from '../../../../common/hooks/useNotifications';
import { useCheckbox } from '../../hooks/useCheckbox';
import _ from 'lodash';
import { RetentionTab } from '../contract-approve-view/components/RetentionTab';

export enum PageContractClaimTabs {
  tasks = 'tasks',
  currentClaim = 'currentClaim',
  claims = 'claims',
  subContracts = 'subContracts',
  approvals = 'approvals',
  compliance = 'compliance',
  variationsMade = 'variationsMade',
  variationsReceived = 'variationsReceived',
  retention = 'retention',
}

type Props = BasePaginationProps &
  BaseTaskGroupProps & {
    contract: Contract;
    tasks?: ResponseListBaseType<Array<Task>>;
    reloadData: () => Promise<void>;
    reloadContract: () => Promise<void>;
    onChangeTags: (tag: Array<Tag>) => void;
    tags: Array<Tag>;
    isLoadingTasks?: boolean;
    summary?: SummaryTaskData;
  };

function ContractClaimView(props: Props) {
  const {
    contract,
    tasks,
    reloadData,
    reloadContract,
    handleChangePage,
    handleChangeRowsPerPage,
    page,
    rowsPerPage,
    selectedTaskGroup,
    handleSelectedTaskGroup,
    summary,
  } = props;

  const location = useLocation();
  useProject(contract.projectId);

  const [tabsValue, setTabsValue] = useState<PageContractClaimTabs>(
    PageContractClaimTabs.tasks
  );
  const [isOpenMoveTaskDialog, setIsOpenMoveTaskDialog] =
    useState<boolean>(false);
  const [selectedTasks, setSelectedTasks] = useState<Array<string>>([]);
  const { user } = useAuth();
  const { project } = useProject();
  const { contractId, subcontractId } = useParams<Params>();
  const { notifications, mutationNotification } = useNotifications(
    user,
    project
  );
  const { handleCheckboxClick } = useCheckbox(notifications);

  const {
    page: pageClaims,
    rowsPerPage: rowsPerPageClaims,
    handleChangePage: handleChangePageClaims,
  } = useListPagination();

  const {
    page: pageApproval,
    rowsPerPage: rowsPerPageApproval,
    handleChangePage: handleChangePageApproval,
    skip,
  } = useListPagination();

  useEffect(() => {
    if (location.state?.tab) {
      setTabsValue(location.state?.tab);
    }
  }, [location]);

  const handleChangeTabsValue = useCallback(
    (
      event: React.SyntheticEvent<Element, Event>,
      value: PageContractClaimTabs
    ) => {
      setTabsValue(value);
    },
    []
  );

  const { data: variations } = useQuery(['variations', contract.id], () =>
    listVariations({ contractId: contract.id })
  );

  const { data: variationsReceived } = useQuery(
    ['variationsReceived', contract.id],
    () => listVariationsReceived({ contractId: contract.id })
  );

  const {
    data: contractFiles,
    isLoading: isContractFilesLoading,
    refetch: refetchContractFiles,
  } = useQuery<Array<ContractFile>>(
    ['node-files', subcontractId || contractId],
    () => listContractFiles(subcontractId || contractId!)
  );

  const { data: claims, refetch: refetchClaims } = useQuery(
    [contract, rowsPerPageClaims, pageClaims, 'claims'],
    () =>
      claimList(
        contract.id,
        rowsPerPageClaims,
        rowsPerPageClaims * (pageClaims - 1)
      )
  );

  const {
    data: subcontracts,
    isLoading: isSubContractsLoading,
    refetch: refetchSubContracts,
  } = useQuery<ResponseListBaseType<Array<SubContract>>>(
    ['subcontracts', contract.id],
    () => listSubContracts({ contractId: contract.id, take: 50, skip: 0 })
  );

  const { data: approvalClaims, isFetching: isLoading } = useQuery(
    [contract, rowsPerPageApproval, pageApproval, 'approvals'],
    () => approvalList(contract.id, rowsPerPageApproval, skip)
  );

  const reloadClaims = useCallback(() => {
    (async () => {
      await refetchClaims();
    })();
  }, [refetchClaims]);

  const reloadContractFiles = useCallback(() => {
    (async () => {
      await refetchContractFiles();
    })();
  }, [refetchContractFiles]);

  const reloadSubContracts = useCallback(() => {
    (async () => {
      await refetchSubContracts();
    })();
  }, [refetchSubContracts]);

  const reloadDataTasks = useCallback(
    async (reloadTree: () => void) => {
      await reloadData();
      reloadTree();
    },
    [reloadData]
  );

  const handleClick = useCallback(
    async (notificationId: string) => {
      mutationNotification({ id: notificationId, isRead: true });
    },
    [mutationNotification]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const setServicesValueDebounced = useCallback(
    _.debounce(mutationNotification, 500),
    []
  );

  const handleCheckbox = useCallback(
    async (notification: Notification) => {
      handleCheckboxClick(notification.id);
      setServicesValueDebounced({
        id: notification.id,
        isRead: !notification.isRead,
      });
    },
    [handleCheckboxClick, setServicesValueDebounced]
  );

  const reloadDataClaim = useCallback(async () => {
    await refetchClaims();
    await reloadData();
    await reloadContract();
  }, [refetchClaims, reloadData, reloadContract]);

  const handleSelectedTasks = useCallback(
    (selectedTasksIds: Array<string>) => setSelectedTasks(selectedTasksIds),
    []
  );

  const tabs = useMemo(() => {
    const isNoProjectOwner = user && !isProjectOwner(user, contract);

    const subContractsNotifications = subcontracts?.data?.reduce(
      (acc, subcontract) => {
        return acc + (subcontract.notifications?.length ? 1 : 0);
      },
      0
    );

    return [
      {
        label: 'Tasks',
        isShow: true,
        value: PageContractClaimTabs.tasks,
      },
      {
        label: 'Current Claim',
        value: PageContractClaimTabs.currentClaim,
        isShow: true,
      },
      {
        label: 'Claims Made',
        greenDotsLength: getClaimsMadeGreenActions(
          claims?.data,
          user?.accountId,
          project?.systemDate,
          notifications
        ),
        redDotsLength: getClaimsMadeRedActions(
          claims?.data,
          user?.accountId,
          project?.systemDate,
          notifications
        ),
        value: PageContractClaimTabs.claims,
        isShow: true,
      },
      {
        label: 'Variations Made',
        isShow: isNoProjectOwner,
        greenDotsLength: getVariationsMadeGreenActions(
          variations?.data,
          user?.accountId,
          notifications
        ),
        redDotsLength: getVariationsMadeRedActions(
          variations?.data,
          user?.accountId,
          notifications
        ),
        value: PageContractClaimTabs.variationsMade,
      },
      {
        label: 'Retention',
        isShow: true,
        value: PageContractClaimTabs.retention,
      },
      {
        label: 'Sub-Contracts',
        isShow: true,
        redDotsLength: subContractsNotifications,
        value: PageContractClaimTabs.subContracts,
      },
      {
        label: 'Claims Received',
        isShow: true,
        greenDotsLength: getClaimReceivedGreenActions(
          approvalClaims?.data,
          project?.systemDate,
          user?.accountId,
          notifications
        ),
        redDotsLength: getClaimReceivedRedActions(
          approvalClaims?.data,
          project?.systemDate,
          user?.accountId,
          notifications
        ),
        value: PageContractClaimTabs.approvals,
      },

      {
        label: 'Variations Received',
        isShow: true,
        greenDotsLength: getVariationReceivedGreenActions(
          variationsReceived?.data,
          user?.accountId,
          notifications
        ),
        redDotsLength: getVariationReceivedRedActions(
          variationsReceived?.data,
          user?.accountId,
          notifications
        ),
        value: PageContractClaimTabs.variationsReceived,
      },
      {
        label: 'Compliance',
        isShow: true,
        greenDotsLength: getComplianceGreenActions(
          contractFiles,
          project?.systemDate
        ),
        redDotsLength: getComplianceRedActions(
          contractFiles,
          project?.systemDate
        ),
        value: PageContractClaimTabs.compliance,
      },
    ];
  }, [
    user,
    contract,
    variations?.data,
    variationsReceived?.data,
    claims?.data,
    contractFiles,
    approvalClaims?.data,
    subcontracts?.data,
    project?.systemDate,
    notifications,
  ]);

  return (
    <>
      <Box>
        <ContractDetails
          description={contract.description}
          summary={summary}
          reloadData={reloadContract}
          contract={contract}
        />
        <StyledTabs
          sx={{ pt: 2, mb: '16px' }}
          textColor="inherit"
          value={tabsValue}
          onChange={handleChangeTabsValue}
        >
          {tabs.map(
            ({ isShow, greenDotsLength, redDotsLength, label, value }) => {
              if (!isShow) {
                return null;
              }

              if (greenDotsLength || redDotsLength) {
                return (
                  <Tab
                    value={value}
                    sx={{ ml: 0, mr: 4 }}
                    wrapped
                    key={value}
                    label={
                      <Box sx={{ display: 'flex', px: '8px' }}>
                        {Boolean(greenDotsLength) && (
                          <Badge
                            color="success"
                            badgeContent={greenDotsLength}
                            sx={{
                              transform: 'scale(0.7)',
                              mr: '8px',
                            }}
                          />
                        )}
                        <Typography fontSize="14px" fontWeight={500}>
                          {label}
                        </Typography>
                        {Boolean(redDotsLength) && (
                          <Badge
                            color="error"
                            badgeContent={redDotsLength}
                            sx={{
                              transform: 'scale(0.7)',
                              ml: '8px',
                            }}
                          />
                        )}
                      </Box>
                    }
                  />
                );
              }

              return (
                <Tab
                  key={value}
                  sx={{ ml: 0, mr: 4 }}
                  label={label}
                  value={value}
                />
              );
            }
          )}
        </StyledTabs>
      </Box>

      {tabsValue === PageContractClaimTabs.tasks && (
        <TaskGroups
          contractId={contract.id}
          selectedTaskGroup={selectedTaskGroup}
          handleSelectedTaskGroup={handleSelectedTaskGroup}
          render={reloadTree => (
            <>
              <TasksTab
                tasks={tasks}
                selectedTaskGroup={selectedTaskGroup}
                contract={contract}
                reloadData={reloadDataClaim}
                page={page}
                rowsPerPage={rowsPerPage}
                handleChangePage={handleChangePage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                handleClaimClick={() =>
                  setTabsValue(PageContractClaimTabs.currentClaim)
                }
                handleMoveTaskDialog={() => setIsOpenMoveTaskDialog(true)}
                handleSelectedTasks={handleSelectedTasks}
              />
              {isOpenMoveTaskDialog && (
                <MoveTaskDialog
                  projectId={contract.projectId}
                  isOpen={isOpenMoveTaskDialog}
                  selectedTasks={selectedTasks}
                  onRefetchTasks={() => reloadDataTasks(reloadTree)}
                  onClose={() => setIsOpenMoveTaskDialog(false)}
                />
              )}
            </>
          )}
        />
      )}

      {tabsValue === PageContractClaimTabs.currentClaim && (
        <CurrentClaimTab
          projectId={contract.projectId}
          contractId={contract.id}
          tasks={tasks}
          selectedTaskGroup={selectedTaskGroup}
          reloadData={reloadDataClaim}
          goToTasksTab={() => setTabsValue(PageContractClaimTabs.tasks)}
          handleSelectedTaskGroup={handleSelectedTaskGroup}
        />
      )}

      {tabsValue === PageContractClaimTabs.compliance && user && (
        <ComplianceTab
          contract={contract}
          isContractor={isContractor(user, contract)}
          isContractFilesLoading={isContractFilesLoading}
          refetchContractFiles={reloadContractFiles}
          contractFiles={contractFiles}
        />
      )}
      {tabsValue === PageContractClaimTabs.approvals && (
        <ClaimsReceivedTab
          approvals={approvalClaims?.data}
          approvalsTotal={approvalClaims?.meta.total || 0}
          handleChangePage={handleChangePageApproval}
          page={pageApproval}
          handleClick={handleClick}
          handleCheckbox={handleCheckbox}
          notifications={notifications}
          isLoading={isLoading}
        />
      )}
      {tabsValue === PageContractClaimTabs.claims && user && (
        <>
          <ClaimHistory
            claims={claims?.data || []}
            isContractor={isContractor(user, contract)}
            isCustomer={isCustomer(user, contract)}
            reloadClaims={reloadClaims}
            reloadContract={reloadContract}
            page={pageClaims}
            handleClick={handleClick}
            handleCheckbox={handleCheckbox}
            notifications={notifications}
            handleChangePage={handleChangePageClaims}
            rowsPerPage={rowsPerPageClaims}
            total={claims?.meta.total || 0}
          />
        </>
      )}
      {tabsValue === PageContractClaimTabs.subContracts && (
        <SubContractTab
          contract={contract}
          data={subcontracts?.data}
          refetchSubContracts={reloadSubContracts}
          isLoading={isSubContractsLoading}
        />
      )}

      {tabsValue === PageContractClaimTabs.variationsMade && (
        <VariationsMadeTab
          contractId={contract.id}
          handleClick={handleClick}
          handleCheckbox={handleCheckbox}
          notifications={notifications}
        />
      )}

      {tabsValue === PageContractClaimTabs.variationsReceived && (
        <VariationsReceivedTab
          contractId={contract.id}
          contingencyBudget={contract.contingencyBudget}
          handleClick={handleClick}
          handleCheckbox={handleCheckbox}
          notifications={notifications}
        />
      )}

      {tabsValue === PageContractClaimTabs.retention && (
        <RetentionTab contract={contract} summary={summary} />
      )}
    </>
  );
}

export { ContractClaimView };
