import React, { useState } from 'react';
import { AlertColor, TableBody, TableCell, TableRow } from '@mui/material';
import { createPortal } from 'react-dom';
import { useQuery } from '@tanstack/react-query';

import { KebabMenu } from '../../../components/menu/KebabMenu';
import { useDialogState } from '../../../components/dialog/dialog.hooks';
import { useConfirmDialog } from '../../../common/hooks/useConfirmDialog';
import { getProjectReport, ProjectListData } from '../../../common/api/reports';
import { DOLLAR, Order } from '../../../common/api';
import { ReportCellKebab, ReportCellWrapper } from '../ReportCell.style';
import { Tile } from '../BasicTileItem.styles';
import EditProjectListTile from './EditProjectListTile';
import { LinkTo, StyledTable } from '../../../styles';
import { SortedTableHead } from '../../../components/sorted-table-head';
import { HeadCell } from '../../../components/sorted-table-head/SortedTable.hooks';
import { ProgressBar } from '../../../components/progress-bar';
import { MetricKey } from '../../../common/api/metric-types';
import { sortReportRows } from '../Report.utils';
import { ReportItem } from '../../../common/api/report-types';

type Props = {
  reportItem: ReportItem;
  isEditingDashboard: boolean;
  reload: () => void;
  showAlert: (title: string, severity: AlertColor) => void;
};

const ProjectListTileDefault = ({
  reportItem,
  isEditingDashboard,
  reload,
  showAlert,
}: Props) => {
  const [order, setOrder] = useState<Order>('asc');
  const [orderIndex, setOrderIndex] = useState<number>(0);

  const {
    isVisible: isEditVisible,
    open: openEdit,
    close: closeEdit,
  } = useDialogState();
  const { ConfirmDialog } = useConfirmDialog();

  const { data: projectListData, refetch: refetchProjectListData } =
    useQuery<ProjectListData>(['projectListReportAll', reportItem.id], () => {
      return getProjectReport({
        reportItemId: reportItem.id,
        isDefaultReport: true,
      });
    });

  const el = document.getElementById('report-actions');

  const id = reportItem.id;
  const reportId = reportItem.reportId;

  const headCells: Array<HeadCell> =
    projectListData?.data.headers?.map((h, index) => ({
      id: '' + index,
      numeric: true,
      disablePadding: false,
      label: h,
    })) || [];

  return (
    <Tile>
      {el &&
        createPortal(
          <>
            {isEditVisible && (
              <EditProjectListTile
                id={id}
                isDefaultDashboard
                reportId={reportId}
                reportItem={reportItem}
                close={closeEdit}
                reload={() => {
                  refetchProjectListData();
                  reload();
                }}
                showAlert={showAlert}
              />
            )}
          </>,
          el
        )}

      <ConfirmDialog />
      {!isEditingDashboard && (
        <ReportCellKebab>
          <KebabMenu
            options={['Tile Options']}
            onOptionSelect={() => {
              openEdit();
            }}
          />
        </ReportCellKebab>
      )}

      <ReportCellWrapper>
        <StyledTable>
          <SortedTableHead
            order={order}
            orderBy={orderIndex + ''}
            onRequestSort={(e, prop) => {
              setOrder(order === 'asc' ? 'desc' : 'asc');
              setOrderIndex(Number(prop));
            }}
            headCells={headCells}
          />
          <TableBody>
            {sortReportRows(
              projectListData?.data.values || [],
              orderIndex,
              'value',
              order
            ).map((values, index) => (
              <TableRow key={index}>
                {values.map((v, i) => (
                  <TableCell key={i}>
                    {v.metricKey === MetricKey.ProgessBar ? (
                      <ProgressBar
                        total={v.progress?.total || 0}
                        claimed={v.progress?.claimed || 0}
                        approved={v.progress?.approved || 0}
                        paid={v.progress?.paid || 0}
                        approvedVariation={v.progress?.approvedVariation || 0}
                        unApprovedVariation={
                          v.progress?.unapprovedVariation || 0
                        }
                        valueType={DOLLAR}
                      />
                    ) : v.metricKey === MetricKey.ProjectName ? (
                      <LinkTo to={`/projects/${v.id}`}>{v.value}</LinkTo>
                    ) : (
                      v.value
                    )}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </StyledTable>
      </ReportCellWrapper>
    </Tile>
  );
};

export default ProjectListTileDefault;
