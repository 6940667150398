import styled from '@emotion/styled';

import { grey100, grey800 } from '../../styles/colours';
import { Typography } from '@mui/material';

type ReportCellProps = {
  $colour?: string;
  $opacity?: number;
};
export const ReportCell = styled('div')<ReportCellProps>`
  border-radius: 8px;
  border: 1px solid ${props => (props.$colour ? props.$colour : grey100)};
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.2);
  height: 100%;
  overflow: auto;
  background-color: ${props => (props.$colour ? props.$colour : `transparent`)};
  opacity: ${props => (props.$opacity ? props.$opacity : 1)};
`;

export const ReportCellWrapper = styled('div')`
  padding: 16px 40px 16px 16px;
  height: 100%;
  overflow: auto;
`;

export const ReportCellValue = styled(Typography)`
  color: ${grey800};
  font-size: 20px;
  font-weight: 500;
`;

export const ReportCellNumerical = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

export const ReportCellKebab = styled('div')`
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 100;

  button {
    background-color: white;
    &:hover {
      background-color: white;
    }
  }
`;

export const ReportCellMap = styled('div')`
  height: 100%;
`;
