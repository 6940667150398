import React, { useCallback, useState } from 'react';
import { deleteTodo, Todo } from '../../../common/api';
import {
  Button,
  Checkbox,
  IconButton,
  Stack,
  TableCell,
  TableRow,
  TextField,
} from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useMutation } from '@tanstack/react-query';

const DESCRIPTION_FIELD = 'description';

type FormData = {
  [DESCRIPTION_FIELD]: string;
};
interface Props {
  todo: Todo;
  isItemSelected: (id: string) => boolean;
  handleCheckbox: (todo: Todo) => void;
  handleUpdate: (data: { todoId: string; description: string }) => void;
  reloadData: () => void;
}
function TodoListRow(props: Props) {
  const { todo, isItemSelected, handleCheckbox, handleUpdate, reloadData } =
    props;
  const [edit, setEdit] = useState<boolean>(false);
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<FormData>({
    defaultValues: {
      [DESCRIPTION_FIELD]: todo.description,
    },
  });

  const { mutate: mutateDeleteTodo, isLoading: isLoadingDelete } = useMutation(
    (todoId: string) => deleteTodo(todoId),
    {
      onSuccess: () => {
        return reloadData();
      },
    }
  );

  const handleDeleteTodo = useCallback(
    async (id: string) => {
      mutateDeleteTodo(id);
    },
    [mutateDeleteTodo]
  );

  const onSubmit: SubmitHandler<FormData> = useCallback(
    async data => {
      handleUpdate({ ...data, todoId: todo.id });
      setEdit(false);
      reset({
        [DESCRIPTION_FIELD]: data[DESCRIPTION_FIELD],
      });
    },
    [handleUpdate, todo.id, reset]
  );

  const handleEdit = useCallback(() => {
    setEdit(prev => !prev);
  }, []);

  return (
    <TableRow key={todo.id}>
      <TableCell>
        <Stack direction={'row'} justifyContent={'space-between'}>
          <Stack direction={'row'} alignItems={'center'} gap={'12px'}>
            <Checkbox
              checked={isItemSelected(todo.id)}
              onClick={() => handleCheckbox(todo)}
              disabled={isLoadingDelete}
            />
            {!edit ? (
              todo.description
            ) : (
              <Stack direction={'row'} onClick={e => e.stopPropagation()}>
                <Controller
                  name={DESCRIPTION_FIELD}
                  control={control}
                  rules={{
                    required: 'description is required',
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      defaultValue={todo.description}
                      value={field.value || ''}
                      inputProps={{
                        style: { fontSize: 14, padding: 0, paddingTop: '9px' },
                      }}
                      autoFocus={true}
                      sx={{
                        '& fieldset': { border: 'none' },
                      }}
                      required
                      fullWidth
                      error={!!errors[DESCRIPTION_FIELD]}
                      helperText={errors[DESCRIPTION_FIELD]?.message}
                    />
                  )}
                />
                <Button onClick={handleSubmit(onSubmit)} variant={'contained'}>
                  Save
                </Button>
              </Stack>
            )}
          </Stack>
          <Stack direction={'row'} gap={'16px'}>
            <IconButton
              disabled={isLoadingDelete}
              onClick={handleEdit}
              color="primary"
            >
              <Edit />
            </IconButton>
            <IconButton
              disabled={isLoadingDelete}
              color="primary"
              onClick={() => handleDeleteTodo(todo.id)}
            >
              <Delete />
            </IconButton>
          </Stack>
        </Stack>
      </TableCell>
    </TableRow>
  );
}

export { TodoListRow };
