import React from 'react';
import { Card, CardContent, Typography } from '@mui/material';
import { ValueType } from '../../common/api';
import { formatAmountByType } from '../../common/format';
import { grey200, grey500, grey900 } from '../../styles/colours';
import isNumber from 'lodash/isNumber';

interface Props {
  title: string;
  value: string | number;
  format?: ValueType;
  isShow?: boolean;
}
function InfoCard(props: Props) {
  const { value, title, format, isShow = true } = props;

  if (!isShow) {
    return null;
  }

  return (
    <Card sx={styles.Card}>
      <CardContent sx={styles.CardContent}>
        <Typography noWrap variant="caption" sx={styles.TypographyLabel}>
          {title}
        </Typography>
        <Typography sx={styles.TypographyContent}>
          {format && isNumber(value)
            ? formatAmountByType(value, format)
            : value}
        </Typography>
      </CardContent>
    </Card>
  );
}

const styles = {
  Card: {
    border: `1px solid ${grey200}`,
    borderWidth: '1px 1px 1px 0px',
    boxShadow: 'none',
    borderRadius: '0px',
    '&:last-child': {
      borderWidth: '1px 0px 1px 0px',
    },
  },
  TypographyLabel: {
    color: grey500,
    letterSpacing: 1,
    marginBottom: '4px',
  },
  TypographyContent: {
    paddingTop: '6px',
    color: grey900,
    fontSize: '20px',
    fontWeight: 500,
  },
  Span: {
    color: grey500,
    fontWeight: 400,
  },
  CardContent: {
    flexDirection: 'column',
    p: 1.5,
    '&:last-child': { pb: 1.5 },
  },
};

export { InfoCard };
