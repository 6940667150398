import {
  ClaimItem,
  ClaimType,
  Task,
  VariationStatus,
} from '../../../../../common/api';
import { Checkbox, IconButton, TableCell, Typography } from '@mui/material';
import { formatCurrency } from '../../../../../common/format';
import { ProgressBar } from '../../../../../components/progress-bar';
import React, { MouseEventHandler } from 'react';
import { StyledTableRow } from '../../../../../styles';
import { TaskClaimItemClaimedCell } from './TaskClaimItemClaimedCell';
import { TaskClaimItemAddCell } from './TaskClaimItemAddCell';
import { TaskRowData } from '../../../utils/taskTable.utils';
import { getCostToComplete } from './CurrentClaimTab.utils';
import { TaskClaimItemRowModifiedValue } from './TaskClaimItemRowModifiedValue';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box } from '@mui/system';

interface Props {
  task: TaskRowData;
  claimItem?: ClaimItem;
  selected: boolean;
  onClick: MouseEventHandler;
  isItemSelected: (id: string) => boolean;
  handleCheckboxClick: (id: string) => void;
  handleClaimClick?: () => void;
  handleAddToCurrentClaim: (value: number, claimType: ClaimType) => void;
  onToggle?: () => void;
  isOpen?: boolean;
  hasButtonToggle?: boolean;
  offset?: string;
  isChild?: boolean;
}

function TaskClaimItemRow({
  task,
  claimItem,
  selected,
  onClick,
  isItemSelected,
  handleCheckboxClick,
  handleClaimClick,
  handleAddToCurrentClaim,
  onToggle,
  isOpen,
  hasButtonToggle,
  offset,
  isChild,
}: Props) {
  const isNewTask = Boolean(task.isNew === 'true');
  const isApprovedVariation = task.variationStatus === VariationStatus.Approved;
  const costToComplete = getCostToComplete(
    task.value,
    task.paid,
    task.approved,
    task.claimed
  );

  return (
    <StyledTableRow key={task.id} hover onClick={onClick} selected={selected}>
      <TableCell style={{ width: '10%' }}>
        <Checkbox
          checked={isItemSelected(task.id)}
          onClick={event => {
            event.stopPropagation();
            handleCheckboxClick(task.id);
          }}
        />
      </TableCell>
      <TableCell style={{ paddingLeft: offset ? `${offset}px` : '12px' }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
            component="span"
          >
            {task.identifier || ''}
            {hasButtonToggle && (
              <IconButton
                onClick={event => {
                  event.stopPropagation();
                  onToggle && onToggle();
                }}
              >
                {isOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </IconButton>
            )}
          </Box>

          <Box
            sx={{
              display: 'flex',
              gap: '4px',
              color: 'gray',
              fontSize: '0.8rem',
            }}
          >
            <Box component="span">{task.contractorName}</Box>
            <Box component="span">{task.ref}</Box>
          </Box>
        </Box>
      </TableCell>
      <TableCell>
        <Typography variant={'body2'}>{task.description}</Typography>
      </TableCell>
      <TableCell>
        <Typography variant={'body2'}>{task.type}</Typography>
      </TableCell>
      <TableCell>
        <Typography variant={'body2'}>{task.unitOfMeasure || '-'}</Typography>
      </TableCell>
      <TableCell>
        <Typography variant={'body2'}>{task.quantity || '-'}</Typography>
      </TableCell>
      <TableCell>
        <Typography variant={'body2'}>{task.rate || '-'}</Typography>
      </TableCell>
      <TableCell>
        {task.type === 'scheduleOfRates' ? (
          <Box>
            <Typography variant={'body2'}>
              {task.quantity} {task.unitOfMeasure}
            </Typography>
            <Typography variant={'body2'}>
              ${task.rate} per {task.unitOfMeasure}
            </Typography>
          </Box>
        ) : (
          '-'
        )}
      </TableCell>
      <TableCell>
        {isNewTask && !isApprovedVariation ? (
          formatCurrency(Number(task.unapprovedVariation))
        ) : Number(task.approvedVariation) > 0 ? (
          <TaskClaimItemRowModifiedValue
            taskValue={task.value}
            taskOriginalValue={task.originalValue}
          />
        ) : (
          formatCurrency(task.value)
        )}
      </TableCell>
      <TableCell>
        <Typography variant={'body2'}>
          <Checkbox
            checked={(task as unknown as Task).excludeFromRetention}
            disabled={true}
          ></Checkbox>
        </Typography>
      </TableCell>
      <TableCell>
        <ProgressBar
          isNewTask={isNewTask}
          isApprovedVariation={isApprovedVariation}
          total={task.value}
          claimed={task.claimed}
          approved={Number(task.approved)}
          paid={Number(task.paid)}
          valueType={task.valueType}
          approvedVariation={Number(task.approvedVariation)}
          unApprovedVariation={Number(task.unapprovedVariation)}
        />
      </TableCell>
      {(isNewTask && !isApprovedVariation) || isChild ? (
        <>
          <TableCell></TableCell>
          <TableCell></TableCell>
        </>
      ) : claimItem ? (
        <TaskClaimItemClaimedCell
          claimItem={claimItem}
          onClickClaim={handleClaimClick}
          taskValue={task.value}
        />
      ) : (
        <TaskClaimItemAddCell
          id={task.id}
          costToComplete={costToComplete}
          totalValue={task.value}
          handleAddToCurrentClaim={handleAddToCurrentClaim}
          taskType={task.type}
        />
      )}
    </StyledTableRow>
  );
}

export { TaskClaimItemRow };
