import React, { ReactNode } from 'react';
import { Tab } from '@mui/material';
import Box from '@mui/material/Box';

import { KebabMenu } from '../../components/menu/KebabMenu';

const ReportTab = (props: {
  label: ReactNode;
  value: number;
  options: string[];
  onSelect: (option: string) => void;
}) => {
  const { label, options, onSelect, ...other } = props;

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Tab
        component="div"
        label={label}
        sx={{ marginRight: '2px' }}
        {...other}
      />
      <KebabMenu options={options} onOptionSelect={onSelect} />
    </Box>
  );
};

export default ReportTab;
