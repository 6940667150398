import React from 'react';
import {
  Box,
  Divider,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';

import {
  DEFECT_LIABILITY_NUMBER,
  DEFECT_LIABILITY_TERM,
  FormData,
  RETENTION_BASIS,
  RETENTION_INITIAL,
  RETENTION_INITIAL_RELEASE_AMOUNT,
  RETENTION_MAX,
  RETENTION_MINIMAL_APPROVAL,
  VARIATION_RETENTION_BASIS,
  VARIATION_RETENTION_INITIAL,
  VARIATION_RETENTION_MAX,
} from './AddContract.constants';
import {
  RententionBasis,
  TimePeriod,
} from '../../../common/api/contract-types';

export const AddContractStep3 = ({ isDisabled }: { isDisabled?: boolean }) => {
  const formMethods = useFormContext<FormData>();
  const {
    control,
    formState: { errors },
    setValue,
    watch,
    clearErrors,
  } = formMethods;

  const [
    retentionBasis,
    retentionInitial,
    retentionMax,
    variationRetentionBasis,
    variationRetentionMax,
    variationRetentionInitial,
  ] = watch([
    RETENTION_BASIS,
    RETENTION_INITIAL,
    RETENTION_MAX,
    VARIATION_RETENTION_BASIS,
    VARIATION_RETENTION_MAX,
    VARIATION_RETENTION_INITIAL,
  ]);

  const retentionAndVariationIsNoneOrBankGuarantee =
    (retentionBasis === RententionBasis.None &&
      variationRetentionBasis === RententionBasis.None) ||
    (retentionBasis === RententionBasis.BankGuarantee &&
      variationRetentionBasis === RententionBasis.BankGuarantee);

  return (
    <Box>
      <Typography fontWeight="bold" variant="body1" mb={2}>
        Defects Liability Period
      </Typography>

      <Box display="flex" gap={1} mb={3} maxWidth={280}>
        <Controller
          name={DEFECT_LIABILITY_NUMBER}
          control={control}
          rules={{
            required: 'Number is required',
            min: {
              value: 1,
              message: 'Must be a positive number',
            },
          }}
          render={({ field }) => (
            <TextField
              {...field}
              value={field.value ?? ''}
              label="Number"
              type="number"
              sx={{ width: '80px' }}
              required
              disabled={isDisabled}
              error={!!errors[DEFECT_LIABILITY_NUMBER]}
              helperText={errors[DEFECT_LIABILITY_NUMBER]?.message}
            />
          )}
        />
        <FormControl sx={{ flex: 1 }}>
          <InputLabel id="defect-period">Period</InputLabel>
          <Controller
            name={DEFECT_LIABILITY_TERM}
            control={control}
            rules={{
              required: 'Defect period is required',
            }}
            render={({ field }) => (
              <>
                <Select
                  {...field}
                  labelId="defect-period"
                  label="Period"
                  fullWidth
                  disabled={isDisabled}
                  error={!!errors[DEFECT_LIABILITY_TERM]}
                >
                  <MenuItem value={TimePeriod.Days}>Days</MenuItem>
                  <MenuItem value={TimePeriod.Weeks}>Weeks</MenuItem>
                  <MenuItem value={TimePeriod.Months}>Months</MenuItem>
                </Select>
              </>
            )}
          />
        </FormControl>
      </Box>

      <Divider sx={{ mb: 2 }} />

      <Typography fontWeight="bold" variant="body1" mb={2}>
        Retention Withholding
      </Typography>

      <Box display="flex" gap={1} mb={3}>
        <FormControl sx={{ flex: 1 }}>
          <InputLabel id="retention-basis">Retention Basis</InputLabel>
          <Controller
            name={RETENTION_BASIS}
            control={control}
            rules={{
              required: 'Retention Basis is required',
            }}
            render={({ field }) => (
              <>
                <Select
                  {...field}
                  labelId="retention-basis"
                  label="Retention Basis"
                  fullWidth
                  disabled={isDisabled}
                  onChange={e => {
                    const value = e.target.value;
                    if (value === RententionBasis.None) {
                      setValue(RETENTION_MAX, undefined);
                      setValue(RETENTION_INITIAL, undefined);
                      clearErrors([RETENTION_MAX, RETENTION_INITIAL]);
                    }
                    field.onChange(e);
                  }}
                >
                  <MenuItem value={RententionBasis.None}>None</MenuItem>
                  <MenuItem value={RententionBasis.Percent}>Percent</MenuItem>
                  <MenuItem value={RententionBasis.BankGuarantee} disabled>
                    Bank Guarantee
                  </MenuItem>
                </Select>
              </>
            )}
          />
        </FormControl>
        <FormControl sx={{ flex: 1 }}>
          <Controller
            name={RETENTION_MAX}
            control={control}
            rules={{
              validate: value => {
                if (retentionBasis === RententionBasis.Percent) {
                  if (!value || Number(value) <= 0) {
                    return 'Must be a positive number';
                  } else if (Number(value) > 100) {
                    return 'Must be no higher than 100%';
                  }
                }
                return true;
              },
            }}
            render={({ field }) => (
              <TextField
                {...field}
                value={field.value ?? ''}
                label="Max Retention"
                type="number"
                required
                disabled={
                  isDisabled || retentionBasis !== RententionBasis.Percent
                }
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">%</InputAdornment>
                  ),
                }}
                error={!!errors[RETENTION_MAX]}
                helperText={errors[RETENTION_MAX]?.message}
                onChange={e => {
                  const value = e.target.value;
                  if (
                    retentionInitial &&
                    Number(value) > Number(retentionInitial)
                  ) {
                    setValue(RETENTION_INITIAL, value, {
                      shouldValidate: true,
                    });
                  }
                  field.onChange(e);
                }}
              />
            )}
          />
        </FormControl>
        <FormControl sx={{ flex: 1 }}>
          <Controller
            name={RETENTION_INITIAL}
            control={control}
            rules={{
              validate: value => {
                if (retentionBasis === RententionBasis.Percent) {
                  if (!value || Number(value) <= 0) {
                    return 'Must be a positive number';
                  } else if (Number(value) > 100) {
                    return 'Must be no higher than 100%';
                  } else if (Number(value) < Number(retentionMax)) {
                    return 'Must be greater than Retention Basis';
                  }
                }
                return true;
              },
            }}
            render={({ field }) => (
              <TextField
                {...field}
                value={field.value ?? ''}
                label="Initial Rate"
                type="number"
                required
                disabled={
                  isDisabled || retentionBasis !== RententionBasis.Percent
                }
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">%</InputAdornment>
                  ),
                }}
                error={!!errors[RETENTION_INITIAL]}
                helperText={errors[RETENTION_INITIAL]?.message}
              />
            )}
          />
        </FormControl>
      </Box>

      <Box display="flex" gap={1} mb={3}>
        <FormControl sx={{ flex: 1 }}>
          <InputLabel id="variation-retention-basis">
            Variation Retention Basis
          </InputLabel>
          <Controller
            name={VARIATION_RETENTION_BASIS}
            control={control}
            rules={{
              required: 'Variation Retention Basis is required',
            }}
            render={({ field }) => (
              <>
                <Select
                  {...field}
                  labelId="variation-retention-basis"
                  label="Variation Retention Basis"
                  fullWidth
                  disabled={isDisabled}
                  onChange={e => {
                    const value = e.target.value;
                    if (value === RententionBasis.None) {
                      setValue(VARIATION_RETENTION_MAX, '');
                      setValue(VARIATION_RETENTION_INITIAL, '');
                      clearErrors([
                        VARIATION_RETENTION_MAX,
                        VARIATION_RETENTION_INITIAL,
                      ]);
                    }
                    field.onChange(e);
                  }}
                >
                  <MenuItem value={RententionBasis.None}>None</MenuItem>
                  <MenuItem value={RententionBasis.Percent}>Percent</MenuItem>
                  <MenuItem value={RententionBasis.BankGuarantee} disabled>
                    Bank Guarantee
                  </MenuItem>
                </Select>
              </>
            )}
          />
        </FormControl>
        <FormControl sx={{ flex: 1 }}>
          <Controller
            name={VARIATION_RETENTION_MAX}
            control={control}
            rules={{
              validate: value => {
                if (variationRetentionBasis === RententionBasis.Percent) {
                  if (!value || Number(value) <= 0) {
                    return 'Must be a positive number';
                  } else if (Number(value) > 100) {
                    return 'Must be no higher than 100%';
                  }
                }
                return true;
              },
            }}
            render={({ field }) => (
              <TextField
                {...field}
                value={field.value ?? ''}
                label="Variation Max Retention"
                type="number"
                required
                disabled={
                  isDisabled ||
                  variationRetentionBasis !== RententionBasis.Percent
                }
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">%</InputAdornment>
                  ),
                }}
                error={!!errors[VARIATION_RETENTION_MAX]}
                helperText={errors[VARIATION_RETENTION_MAX]?.message}
                onChange={e => {
                  const value = e.target.value;
                  if (
                    variationRetentionInitial &&
                    Number(value) > Number(variationRetentionInitial)
                  ) {
                    setValue(VARIATION_RETENTION_INITIAL, value, {
                      shouldValidate: true,
                    });
                  }
                  field.onChange(e);
                }}
              />
            )}
          />
        </FormControl>
        <FormControl sx={{ flex: 1 }}>
          <Controller
            name={VARIATION_RETENTION_INITIAL}
            control={control}
            rules={{
              validate: value => {
                if (variationRetentionBasis === RententionBasis.Percent) {
                  if (!value || Number(value) <= 0) {
                    return 'Must be a positive number';
                  } else if (Number(value) > 100) {
                    return 'Must be no higher than 100%';
                  } else if (Number(value) < Number(variationRetentionMax)) {
                    return 'Must be greater than Variation Retention Basis';
                  }
                }
                return true;
              },
            }}
            render={({ field }) => (
              <TextField
                {...field}
                value={field.value ?? ''}
                label="Variation Initial Rate"
                type="number"
                required
                disabled={
                  isDisabled ||
                  variationRetentionBasis !== RententionBasis.Percent
                }
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">%</InputAdornment>
                  ),
                }}
                error={!!errors[VARIATION_RETENTION_INITIAL]}
                helperText={errors[VARIATION_RETENTION_INITIAL]?.message}
              />
            )}
          />
        </FormControl>
      </Box>

      <Divider sx={{ mb: 2 }} />

      <Typography fontWeight="bold" variant="body1" mb={2}>
        Retention Release Trigger
      </Typography>

      <Box display="flex" gap={1} mb={3}>
        <FormControl sx={{ flex: 1 }}>
          <Controller
            name={RETENTION_MINIMAL_APPROVAL}
            control={control}
            rules={{
              validate: value => {
                if (!retentionAndVariationIsNoneOrBankGuarantee) {
                  if (!value || Number(value) <= 0) {
                    return 'Must be a positive number';
                  } else if (Number(value) > 100) {
                    return 'Must be no higher than 100%';
                  }
                }
                return true;
              },
            }}
            render={({ field }) => (
              <TextField
                {...field}
                value={field.value ?? ''}
                label="Minimal Approval"
                type="number"
                required
                disabled={
                  isDisabled || retentionAndVariationIsNoneOrBankGuarantee
                }
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">%</InputAdornment>
                  ),
                }}
                error={!!errors[RETENTION_MINIMAL_APPROVAL]}
                helperText={errors[RETENTION_MINIMAL_APPROVAL]?.message}
              />
            )}
          />
        </FormControl>
        <FormControl sx={{ flex: 1 }}>
          <Controller
            name={RETENTION_INITIAL_RELEASE_AMOUNT}
            control={control}
            rules={{
              validate: value => {
                if (!retentionAndVariationIsNoneOrBankGuarantee) {
                  if (!value || Number(value) <= 0) {
                    return 'Must be a positive number';
                  } else if (Number(value) > 100) {
                    return 'Must be no higher than 100%';
                  }
                }
                return true;
              },
            }}
            render={({ field }) => (
              <TextField
                {...field}
                value={field.value ?? ''}
                label="Initial Release Amount"
                type="number"
                required
                disabled={
                  isDisabled || retentionAndVariationIsNoneOrBankGuarantee
                }
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">%</InputAdornment>
                  ),
                }}
                error={!!errors[RETENTION_INITIAL_RELEASE_AMOUNT]}
                helperText={errors[RETENTION_INITIAL_RELEASE_AMOUNT]?.message}
              />
            )}
          />
        </FormControl>
      </Box>
    </Box>
  );
};
