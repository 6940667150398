import { axios } from './axios';

// Types
import {
  ContractFile,
  FileDownloadResponse,
  ResponseBaseType,
  ResponseListBaseType,
  Variation,
  VariationContractsResource,
  VariationFile,
  VariationHistory,
  VariationInfoResource,
  VariationListResource,
  VariationMessage,
  VariationMessageStatus,
  VariationNextIdResource,
  VariationRequestType,
  VariationStatus,
} from './types';
import { LocalVariationFile } from '../../pages/variations/VariationFileDrawer';
import { VariationReceivedRowItem } from '../../pages/variations/variationsPage.utils';

// Utils
import { getUploadPath, uploadToS3 } from './file';

export async function getVariationOrNextId(payload: {
  contractId: string;
  variationId?: string;
}): Promise<Variation | { nextSvId: number; nextAvId: number }> {
  const { variationId, contractId } = payload;

  const { data } = await getVariationsNextId({ contractId });
  const { nextSvId = 1, nextAvId = 1 } = data || {};

  if (variationId) {
    const variation = await getVariation({ contractId, variationId });
    return {
      ...variation,
      anticipatedId:
        variation.anticipatedId !== 0 ? variation.anticipatedId : nextSvId,
      identifier: variation.identifier !== 0 ? variation.identifier : nextSvId,
    };
  }

  return {
    nextSvId: Number(nextSvId),
    nextAvId: Number(nextAvId),
  };
}

export async function getVariation(payload: {
  contractId: string;
  variationId: string;
}): Promise<Variation> {
  const { variationId, contractId } = payload;
  const resp = await axios.get(
    `/contracts/${contractId}/variations/${variationId}`
  );
  return resp.data;
}

export async function getVariationsNextId(payload: {
  contractId: string;
}): Promise<ResponseBaseType<VariationNextIdResource>> {
  const { contractId } = payload;
  const resp = await axios.get(`/contracts/${contractId}/ids/variations`);
  return resp.data;
}

export async function listVariations(
  payload: {
    contractId: string;
  },
  options?: {
    take?: number;
    skip?: number;
    type?: Exclude<VariationRequestType, 'summary'>;
  }
): Promise<ResponseListBaseType<Array<VariationListResource>>> {
  const { contractId } = payload;
  const resp = await axios.get(`/contracts/${contractId}/variations`, {
    params: {
      take: options?.take,
      type: options?.type,
      skip: options?.skip,
    },
  });
  return resp.data;
}

export async function listVariationsReceived(
  payload: {
    contractId: string;
    subContractIds?: string[];
  },
  options?: { take?: number; skip?: number; type?: VariationRequestType }
): Promise<ResponseListBaseType<Array<VariationListResource>>> {
  const { contractId } = payload;
  const resp = await axios.get(`/contracts/${contractId}/variations-received`, {
    params: {
      take: options?.take,
      skip: options?.skip,
      type: options?.type,
      subContractIds: payload.subContractIds,
    },
    paramsSerializer: {
      indexes: null,
    },
  });
  return resp.data;
}

export async function listVariationsSubContractReceived(
  payload: {
    contractId: string;
  },
  options?: { take?: number; skip?: number; type?: VariationRequestType }
): Promise<ResponseListBaseType<Array<VariationListResource>>> {
  const { contractId } = payload;
  const resp = await axios.get(
    `/contracts/${contractId}/variations/sub-contractor`,
    {
      params: {
        take: options?.take,
        skip: options?.skip,
        type: options?.type,
      },
    }
  );
  return resp.data;
}

export async function listVariationsReceivedSummary(payload: {
  contractId: string;
}): Promise<ResponseBaseType<VariationInfoResource>> {
  const { contractId } = payload;
  const resp = await axios.get(
    `/contracts/${contractId}/summary/variations-received`
  );
  return resp.data;
}

export async function listVariationsReceivedContracts(
  payload: {
    contractId: string;
  },
  options?: { type?: Exclude<VariationRequestType, 'all' | 'summary'> }
): Promise<Array<VariationContractsResource>> {
  const { contractId } = payload;
  const resp = await axios.get(
    `/contracts/${contractId}/variations-received/contracts`,
    {
      params: {
        type: options?.type,
      },
    }
  );
  return resp.data;
}

export async function listVariationHistory(payload: {
  variationId: string;
}): Promise<Array<VariationHistory>> {
  const { variationId } = payload;
  const resp = await axios.get(`/variation/${variationId}/history`);
  return resp.data;
}

export type CreateUpdatePayload = {
  name: string;
  status: VariationStatus;
  variationItems: {
    id: string;
    description: string;
    newTaskValue: number;
    variance: number;
    maxVarianceValue: number;
    deleted?: boolean;
  }[];
  variationMessages?: {
    message: string;
  }[];
};

export async function createVariation(
  contractId: string,
  payload: CreateUpdatePayload
): Promise<Variation> {
  const resp = await axios.post(`/contracts/${contractId}/variation`, {
    ...payload,
  });
  return resp.data;
}

export async function updateVariation(
  contractId: string,
  variationId: string,
  payload: CreateUpdatePayload
): Promise<Variation> {
  const resp = await axios.put(
    `/contracts/${contractId}/variation/${variationId}`,
    {
      ...payload,
    }
  );
  return resp.data;
}

export async function closeVariation(
  variationId: string,
  contractId: string
): Promise<Variation> {
  const resp = await axios.delete(
    `/contracts/${contractId}/variation/${variationId}`
  );
  return resp.data;
}

export async function withdrawVariation({
  contractId,
  variationId,
  isNewRevision,
}: {
  contractId: string;
  variationId: string;
  isNewRevision: boolean;
}): Promise<Variation> {
  const resp = await axios.put(
    `/contracts/${contractId}/variation/${variationId}/withdraw`,
    {
      isNewRevision: isNewRevision,
    }
  );
  return resp.data;
}

export async function acceptVariation({
  contractId,
  variationId,
  variationItems,
}: {
  contractId: string;
  variationId: string;
  variationItems: Array<VariationReceivedRowItem>;
}): Promise<Variation> {
  const resp = await axios.put(
    `/contracts/${contractId}/variation/${variationId}/accept`,
    {
      variationItems,
    }
  );
  return resp.data;
}

export async function rejectVariation({
  contractId,
  variationId,
  isNewRevision,
}: {
  contractId: string;
  variationId: string;
  isNewRevision?: boolean;
}): Promise<Variation> {
  const resp = await axios.put(
    `/contracts/${contractId}/variation/${variationId}/reject`,
    {
      isNewRevision: isNewRevision,
    }
  );
  return resp.data;
}

export async function createFiles(
  payload: { variationId: string },
  variationFiles: Array<LocalVariationFile>,
  contractFiles?: Array<ContractFile>
): Promise<boolean> {
  if (contractFiles) {
    for (const contractFile of contractFiles) {
      await createVariationFile(payload.variationId, {
        fileName: contractFile.name,
        id: contractFile.path,
        contentType: contractFile.mimetype,
      });
    }
  }
  for (const variationFile of variationFiles) {
    if (variationFile.file) {
      const resp = await getUploadPath({
        id: variationFile.id,
        contentType: variationFile.contentType,
      });
      await uploadToS3(resp.uploadUrl, variationFile.file, resp.contentType);
      await createVariationFile(payload.variationId, {
        fileName: variationFile.file.name,
        id: variationFile.id,
        contentType: resp.contentType,
      });
    }
  }
  return true;
}

export async function getVariationFile(
  variationId: string,
  fileId: string
): Promise<FileDownloadResponse> {
  const resp = await axios.get(`/variation/${variationId}/file/${fileId}`);
  return resp.data;
}

export async function createVariationFile(
  variationId: string,
  payload: { fileName: string; contentType: string; id: string }
): Promise<VariationFile> {
  const resp = await axios.post(`/variation/${variationId}/file`, {
    name: payload.fileName,
    mimetype: payload.contentType,
    path: payload.id,
  });
  return resp.data;
}

export async function deleteVariationFile(
  variationId: string,
  fileId: string
): Promise<VariationFile> {
  const resp = await axios.delete(`/variation/${variationId}/file/${fileId}`);
  return resp.data;
}

export async function createVariationMessage(
  variationId: string,
  message: string
): Promise<VariationMessage> {
  const resp = await axios.post(`/variation/${variationId}/message`, {
    message,
  });
  return resp.data;
}

export async function updateVariationMessage(
  variationId: string,
  messageId: string,
  payload: {
    status: VariationMessageStatus;
  }
): Promise<VariationMessage> {
  const resp = await axios.put(
    `/variation/${variationId}/message/${messageId}`,
    payload
  );
  return resp.data;
}

export async function deleteVariationMessage(
  variationId: string,
  messageId: string
) {
  await axios.delete(`/variation/${variationId}/message/${messageId}`);
}
