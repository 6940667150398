import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';

function useCheckbox<
  T extends {
    id: string;
  }
>(data?: Array<T>) {
  const [selected, setSelected] = useState<T | null>(null);
  const [selectAll, setSelectAll] = useState<Array<string>>([]);
  const isItemSelected = (id: string) => selectAll.includes(id);

  const handleCheckboxClick = useCallback(
    (id: string) => {
      if (selectAll.includes(id)) {
        setSelectAll(selectAll.filter(item => item !== id));
      } else {
        setSelectAll([...selectAll, id]);
      }
    },
    [selectAll]
  );

  const handleSelectAll = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (event.target.checked && data) {
        const dataIds = data.map(task => task.id);
        setSelectAll(dataIds);
      } else {
        setSelectAll([]);
      }
    },
    [data]
  );

  const countUnSelect =
    useMemo(
      () => data?.filter(item => !selectAll.includes(item.id)),
      [data, selectAll]
    )?.length ?? 0;

  useEffect(() => setSelectAll([]), [data]);

  return {
    selected,
    setSelected,
    isItemSelected,
    handleCheckboxClick,
    handleSelectAll,
    selectAll,
    setSelectAll,
    countUnSelect,
  };
}

export { useCheckbox };
