import { useMutation } from '@tanstack/react-query';
import { RefObject, useEffect, useRef, useState } from 'react';
import { useDragDropManager } from 'react-dnd';
import { Identifier } from 'dnd-core';
import { moveTaskGroup } from '../../common/api/taskGroups';

type UpdatePayload = {
  id: string;
  parentId: string | null;
  order: string;
};

export const useTaskGroupUpdate = () => {
  const { mutateAsync, isLoading } = useMutation((payload: UpdatePayload) =>
    moveTaskGroup(payload)
  );

  return {
    mutate: (payload: UpdatePayload) => mutateAsync(payload),
    isLoading,
  };
};

type ScrollData = {
  position?: number;
  isScrollAllowed: boolean;
};

function getScrollDirection({
  position,
  upperBounds = Infinity,
  lowerBounds = -Infinity,
}: {
  position: number | undefined;
  upperBounds: number | undefined;
  lowerBounds: number | undefined;
}): 'top' | 'bottom' | 'stable' {
  if (position === undefined) {
    return 'stable';
  }
  if (position > lowerBounds - 80) {
    return 'bottom';
  }
  if (position < upperBounds + 80) {
    return 'top';
  }
  return 'stable';
}

export const useScroll = (ref: RefObject<HTMLElement | null>) => {
  const [config, setConfig] = useState<ScrollData>({
    position: 0,
    isScrollAllowed: false,
  });

  const scrollTimer = useRef<null | NodeJS.Timeout>(null);

  const scrollSpeed = 1;
  const { position, isScrollAllowed } = config;

  const bounds = ref.current?.getBoundingClientRect();
  const direction = getScrollDirection({
    position,
    upperBounds: bounds?.top,
    lowerBounds: bounds?.bottom,
  });

  useEffect(() => {
    if (direction !== 'stable' && isScrollAllowed) {
      scrollTimer.current = setInterval(() => {
        ref.current?.scrollBy(0, scrollSpeed * (direction === 'top' ? -1 : 1));
      }, 1);
    }
    return () => {
      if (scrollTimer.current) {
        clearInterval(scrollTimer.current);
      }
    };
  }, [isScrollAllowed, ref, direction, scrollSpeed]);

  return { updatePosition: setConfig };
};

export const useSortableScrollManager = (
  ref: RefObject<HTMLElement | null>,
  acceptedTypes: Identifier[]
) => {
  const { updatePosition } = useScroll(ref);
  const dragDropManager = useDragDropManager();
  const monitor = dragDropManager.getMonitor();

  useEffect(() => {
    const unsubscribe = monitor.subscribeToOffsetChange(() => {
      const type = monitor.getItemType() || '';
      if (acceptedTypes.includes(type)) {
        const offset = monitor.getClientOffset()?.y;
        updatePosition({
          position: offset,
          isScrollAllowed: true,
        });
      }
    });
    return unsubscribe;
  }, [monitor, acceptedTypes, updatePosition]);
};
