import React, { useCallback } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import SearchIcon from '@mui/icons-material/Search';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import {
  Autocomplete,
  Box,
  FormControl,
  Icon,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';

import { Account } from '../../../common/api';
import {
  COMPANY_REP_EMAIL_FIELD,
  COMPANY_REPRESENTATIVE_FIELD,
  CONTRACT_AWARD_FIELD,
  CONTRACT_CONTRACTOR_NAME,
  CONTRACT_JURISDICTION,
  CONTRACT_REF_FIELD,
  CONTRACT_TITLE_FIELD,
  FormData,
} from './AddContract.constants';
import { useAddContract } from './AddContract.context';
import dayjs from 'dayjs';
import { DEFAULT_DATE_FORMAT } from '../../../common/format';
import { EMAIL_REGEX } from '../../../common/validate';

type Props = {
  accountList: Account[] | undefined;
};

export const AddContractStep1 = ({ accountList }: Props) => {
  const formMethods = useFormContext<FormData>();
  const {
    setValue,
    watch,
    control,
    formState: { errors },
  } = formMethods;

  const {
    isEmailError,
    selectedCompanyId,
    selectedCompany,
    setSelectedCompany,
    setSelectedCompanyId,
  } = useAddContract();

  const contractJurisdiction = watch(CONTRACT_JURISDICTION);

  const handleCompanyChange = useCallback(
    (event: React.SyntheticEvent, value?: string) => {
      if (!value) {
        return;
      }

      setSelectedCompanyId(value);

      // Pre-populate company info if an existing company is selected
      if (value !== 'new') {
        const selectedCompany = accountList?.find(
          company => company.id === value
        );
        if (selectedCompany && setSelectedCompany) {
          setSelectedCompany(selectedCompany);
        }
        setValue(
          COMPANY_REPRESENTATIVE_FIELD,
          selectedCompany?.representative || ''
        );
        setValue(COMPANY_REP_EMAIL_FIELD, selectedCompany?.email || '');
      } else {
        // Clear company info if "Add a new company" is selected
        setValue(COMPANY_REPRESENTATIVE_FIELD, '');
        setValue(COMPANY_REP_EMAIL_FIELD, '');
      }
    },
    [accountList, setValue, setSelectedCompany, setSelectedCompanyId]
  );

  return (
    <Box>
      <Typography fontWeight="bold" variant="body1" mb={2}>
        Basic Details
      </Typography>

      <Box display="flex" gap={3} mb={3}>
        <Controller
          name={CONTRACT_TITLE_FIELD}
          defaultValue=""
          control={control}
          rules={{
            required: 'Contract title is required',
          }}
          render={({ field }) => (
            <TextField
              {...field}
              label="Contract Title"
              fullWidth
              required
              error={!!errors[CONTRACT_TITLE_FIELD]}
              helperText={errors[CONTRACT_TITLE_FIELD]?.message}
            />
          )}
        />
        <Controller
          name={CONTRACT_REF_FIELD}
          control={control}
          render={({ field }) => (
            <TextField {...field} label="Contract Reference Number" fullWidth />
          )}
        />
      </Box>

      <Box display="flex" gap={3} mb={3}>
        <FormControl sx={{ flex: 1 }}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Controller
              name={CONTRACT_AWARD_FIELD}
              defaultValue=""
              control={control}
              rules={{
                required: 'Contract award is required',
                validate: values =>
                  dayjs(values).isValid() || 'Invalid data format',
              }}
              render={({ field, fieldState }) => (
                <DatePicker
                  {...field}
                  label="Contract award"
                  inputFormat={DEFAULT_DATE_FORMAT}
                  renderInput={params => (
                    <TextField
                      {...params}
                      fullWidth
                      required
                      error={Boolean(
                        (fieldState.error && fieldState.isTouched) ||
                          !!errors[CONTRACT_AWARD_FIELD]
                      )}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              )}
            />
          </LocalizationProvider>
        </FormControl>
        <FormControl sx={{ flex: 1 }}>
          <InputLabel id="contract-jurisdiction">
            Contract Jurisdiction
          </InputLabel>
          <Controller
            name={CONTRACT_JURISDICTION}
            control={control}
            rules={{
              required: 'Contract jurisdiction is required',
            }}
            render={({ field }) => (
              <Select
                {...field}
                labelId="contract-jurisdiction"
                label="Contract Jurisdiction"
                fullWidth
                required
                value={contractJurisdiction}
                error={!!errors[CONTRACT_JURISDICTION]}
              >
                <MenuItem value="ACT">Australian Capital Territory</MenuItem>
                <MenuItem value="NSW">New South Wales</MenuItem>
                <MenuItem value="NT">Northern Territory</MenuItem>
                <MenuItem value="QLD">Queensland</MenuItem>
                <MenuItem value="SA">South Australia</MenuItem>
                <MenuItem value="TAS">Tasmania</MenuItem>
                <MenuItem value="VIC">Victoria</MenuItem>
                <MenuItem value="WA">Western Australia</MenuItem>
              </Select>
            )}
          />
        </FormControl>
      </Box>

      <Box display="flex" mb={3}>
        <Controller
          name={CONTRACT_CONTRACTOR_NAME}
          control={control}
          rules={{
            validate: value => {
              if (!value) {
                return 'Please select a contractor';
              }
              return true;
            },
          }}
          render={({ field, fieldState: { error } }) => (
            <Autocomplete
              fullWidth
              options={
                [
                  ...(accountList || []),
                  {
                    id: 'new',
                    name: 'Add a new Company',
                  },
                ] || []
              }
              getOptionLabel={option => option.name}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              selectOnFocus
              onChange={(event, value) => {
                field.onChange(value); // Pass the selected value to React Hook Form
                handleCompanyChange(event, value?.id); // Additional custom handler
              }}
              renderInput={params => (
                <TextField
                  {...params}
                  {...field}
                  label="Contractor Name"
                  fullWidth
                  required
                  value={field.value || null}
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                  sx={{ width: '100%' }}
                  error={!!error}
                  helperText={error?.message}
                />
              )}
              renderOption={(props, option) => (
                <li {...props} key={option.id}>
                  <Stack
                    sx={{ width: '100%' }}
                    flexDirection={'row'}
                    justifyContent={'space-between'}
                    alignItems={'center'}
                  >
                    <Typography>
                      {option.name || 'Non-active Company'}
                    </Typography>
                    {!option.name && (
                      <Icon style={{ color: 'red' }}>
                        <WarningRoundedIcon />
                      </Icon>
                    )}
                  </Stack>
                </li>
              )}
            />
          )}
        />
      </Box>

      {selectedCompanyId && (
        <>
          <Box mb={3}>
            <Controller
              name={COMPANY_REPRESENTATIVE_FIELD}
              defaultValue=""
              control={control}
              rules={{
                required: 'Contractor representative is required',
              }}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Contractor representative"
                  required
                  fullWidth
                  autoFocus
                  error={!!errors[COMPANY_REPRESENTATIVE_FIELD]}
                  helperText={errors[COMPANY_REPRESENTATIVE_FIELD]?.message}
                />
              )}
            />
          </Box>
          <Box mb={3}>
            <Controller
              name={COMPANY_REP_EMAIL_FIELD}
              defaultValue=""
              control={control}
              rules={{
                required: 'Contractor representative email is required',
                pattern: {
                  value: EMAIL_REGEX,
                  message: 'Invalid email address',
                },
              }}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Contractor Representative Email"
                  required
                  fullWidth
                  error={!!(errors[COMPANY_REP_EMAIL_FIELD] || isEmailError)}
                  helperText={
                    isEmailError
                      ? 'Email already exist'
                      : errors[COMPANY_REP_EMAIL_FIELD]?.message
                  }
                />
              )}
            />
          </Box>
        </>
      )}

      {selectedCompany && selectedCompanyId !== 'new' && (
        <>
          <Stack flexDirection={'row'} justifyContent={'space-between'}>
            <Typography>ABN</Typography>
            <Typography>{selectedCompany?.abn || '-'}</Typography>
          </Stack>
          <Stack flexDirection={'row'} justifyContent={'space-between'}>
            <Typography>Address</Typography>
            <Typography>{selectedCompany?.address || '-'}</Typography>
          </Stack>
          <Stack flexDirection={'row'} justifyContent={'space-between'}>
            <Typography>Company Name</Typography>
            <Typography>{selectedCompany?.name || '-'}</Typography>
          </Stack>
        </>
      )}
    </Box>
  );
};
