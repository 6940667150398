import React, { MouseEvent, useCallback, useEffect, useState } from 'react';
import { Controller, useForm, useWatch } from 'react-hook-form';
import {
  Box,
  Checkbox,
  FormControl,
  IconButton,
  Select,
  TableCell,
  TextField,
  Typography,
} from '@mui/material';
import DoneIcon from '@mui/icons-material/DoneOutline';

import { DOLLAR, Task } from '../../../../common/api';

// Styles
import { StyledTableRow } from '../../../../styles';

// Utils
import { formatAmountByType } from '../../../../common/format';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';

export type EditableTaskType = {
  id: string;
  identifier: string;
  description: string;
  value: string;
  type: string;
  unitOfMeasure: string;
  quantity: number;
  rate: number;
  excludeFromRetention: boolean;
  action: ActionType;
};

export type ActionType = 'create' | 'delete' | 'update';

interface Form {
  identifier: string;
  description: string;
  value: string;
  type: string;
  unitOfMeasure: string;
  quantity: number;
  rate: number;
  excludeFromRetention: boolean;
}

const typeLabels: Record<string, string> = {
  progress: 'Progress (%)',
  milestone: 'Milestone',
  scheduleOfRates: 'Schedule of Rates',
};

interface Props {
  task: EditableTaskType;
  taskHistory?: Task;
  onTaskUpdate: (data: EditableTaskType) => void;
}

export function DraftTaskRow({ task, onTaskUpdate, taskHistory }: Props) {
  const [isEditable, setEditable] = useState(false);
  const [isShowScheduleFields, setIsShowScheduleFields] = useState(
    task.type === 'scheduleOfRates'
  );
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const isOpenActionButtons = Boolean(anchorEl);

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { errors, isValid, isDirty },
  } = useForm<Form>({
    defaultValues: {
      identifier: task.identifier,
      description: task.description,
      value: task.value,
      type: task.type,
      unitOfMeasure: task.unitOfMeasure,
      quantity: task.quantity,
      rate: task.rate,
      excludeFromRetention: task.excludeFromRetention,
    },
  });

  const handleTaskTypeChange = useCallback(
    async (value: string) => {
      setIsShowScheduleFields(value === 'scheduleOfRates');
    },
    [setIsShowScheduleFields]
  );

  const handleSaveTask = (data: Form) => {
    if (isDirty) {
      onTaskUpdate({ ...data, action: 'update', id: task.id });
    }
    setEditable(false);
  };

  const handleDeleteTask = (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    onTaskUpdate({
      id: task.id,
      identifier: task.identifier,
      description: task.description,
      value: task.value,
      type: task.type,
      unitOfMeasure: task.unitOfMeasure,
      quantity: task.quantity,
      rate: task.rate,
      excludeFromRetention: task.excludeFromRetention,
      action: 'delete',
    });
  };

  const handleActionClick = (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(null);

    if (isEditable && isValid) {
      handleSubmit(handleSaveTask)();
    }
    if (!isEditable) {
      setEditable(true);
    }
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  const quantity = useWatch({
    control,
    name: 'quantity',
    defaultValue: 0,
  });

  const rate = useWatch({
    control,
    name: 'rate',
    defaultValue: 0,
  });

  useEffect(() => {
    if (quantity && rate) {
      const calculatedAmount = quantity * rate;
      setValue('value', calculatedAmount.toFixed(0));
    }
  }, [quantity, rate, setValue]);

  useEffect(() => {
    reset({
      identifier: task.identifier,
      description: task.description,
      type: task.type,
      unitOfMeasure: task.unitOfMeasure,
      quantity: task.quantity,
      rate: task.rate,
      excludeFromRetention: task.excludeFromRetention,
      value: task.value,
    });
  }, [reset, task]);

  return (
    <StyledTableRow key={task.id} hover={true} isEditable={isEditable}>
      <TableCell width={'12%'}>
        {isEditable ? (
          <Controller
            name="identifier"
            control={control}
            rules={{
              required: 'ID is required',
            }}
            render={({ field }) => (
              <TextField
                {...field}
                size="small"
                sx={{ width: '80px' }}
                variant="standard"
                value={field.value || ''}
                disabled={true}
                required
                fullWidth
                autoFocus
                error={!!errors.identifier}
                helperText={errors.identifier?.message?.toString()}
              />
            )}
          />
        ) : (
          <Typography variant={'body2'}>{task.identifier || ''}</Typography>
        )}
      </TableCell>

      <TableCell>
        {isEditable ? (
          <Controller
            name="description"
            control={control}
            rules={{
              required: 'Task Name is required',
            }}
            render={({ field }) => (
              <TextField
                {...field}
                size="small"
                color="success"
                value={field.value || ''}
                variant="standard"
                required
                fullWidth
                autoFocus
                error={!!errors.description}
                helperText={errors.description?.message?.toString()}
              />
            )}
          />
        ) : (
          <Box sx={{ position: 'relative' }}>
            <Typography variant={'body2'}>{task.description} </Typography>
            {taskHistory && taskHistory.description !== task.description && (
              <div style={styles.History}>{taskHistory.description}</div>
            )}
          </Box>
        )}
      </TableCell>

      <TableCell>
        {isEditable ? (
          <Controller
            name="type"
            defaultValue={task.type}
            control={control}
            rules={{
              required: 'Task Type is required',
            }}
            render={({ field }) => (
              <FormControl size="small" sx={{ minWidth: 150 }}>
                <Select
                  {...field}
                  size="small"
                  color="success"
                  variant="standard"
                  fullWidth
                  required
                  error={!!errors.type}
                  onChange={e => {
                    field.onChange(e);
                    handleTaskTypeChange(e.target.value);
                  }}
                >
                  <MenuItem value="progress">Progress (%)</MenuItem>
                  <MenuItem value="milestone">Milestone</MenuItem>
                  <MenuItem value="scheduleOfRates">Schedule of rates</MenuItem>
                </Select>
              </FormControl>
            )}
          />
        ) : (
          <Box sx={{ position: 'relative', whiteSpace: 'nowrap' }}>
            <Typography variant={'body2'}>
              {typeLabels[task.type] || task.type}
            </Typography>
            {task.type !== taskHistory?.type && (
              <div style={styles.History}>
                {taskHistory?.type
                  ? typeLabels[taskHistory?.type]
                  : taskHistory?.type}
              </div>
            )}
          </Box>
        )}
      </TableCell>

      <TableCell>
        {isShowScheduleFields &&
          (isEditable ? (
            <Controller
              name="unitOfMeasure"
              defaultValue={task.unitOfMeasure}
              control={control}
              rules={{ required: 'Unit of Measure is required' }}
              render={({ field }) => (
                <FormControl size="small" sx={{ minWidth: 150 }}>
                  <Select
                    {...field}
                    size="small"
                    color="success"
                    variant="standard"
                    fullWidth
                    required
                    error={!!errors.unitOfMeasure}
                  >
                    <MenuItem value="m">m</MenuItem>
                    <MenuItem value="m&sup2;">m&sup2;</MenuItem>
                    <MenuItem value="hours">hours</MenuItem>
                    <MenuItem value="kg">kg</MenuItem>
                    <MenuItem value="tonne">tonne</MenuItem>
                    <MenuItem value="others">Others</MenuItem>
                  </Select>
                </FormControl>
              )}
            />
          ) : (
            <Box sx={{ position: 'relative' }}>
              <Typography variant={'body2'}>{task.unitOfMeasure} </Typography>
              {task.unitOfMeasure !== taskHistory?.unitOfMeasure && (
                <div style={styles.History}>{taskHistory?.unitOfMeasure}</div>
              )}
            </Box>
          ))}
      </TableCell>

      <TableCell>
        {isShowScheduleFields &&
          (isEditable ? (
            <Controller
              name="quantity"
              defaultValue={task.quantity ? task.quantity : 0}
              control={control}
              rules={{
                required: 'QTY is required',
                pattern: {
                  value: /^[0-9]*$/,
                  message: 'Value should be a prime number',
                },
              }}
              render={({ field }) => (
                <TextField
                  {...field}
                  size="small"
                  color="success"
                  variant="standard"
                  fullWidth
                  error={!!errors.quantity}
                  helperText={errors.quantity?.message}
                />
              )}
            />
          ) : (
            <Box sx={{ position: 'relative' }}>
              <Typography variant={'body2'}>
                {task.quantity ? task.quantity : '-'}
              </Typography>
              {task.quantity !== taskHistory?.quantity && (
                <div style={styles.History}>{taskHistory?.quantity}</div>
              )}
            </Box>
          ))}
      </TableCell>

      <TableCell>
        {isShowScheduleFields &&
          (isEditable ? (
            <Controller
              name="rate"
              defaultValue={task.rate ? task.rate : 0}
              control={control}
              rules={{
                required: 'Rate is required',
                pattern: {
                  value: /^[0-9]*$/,
                  message: 'Value should be a valid rate',
                },
              }}
              render={({ field }) => (
                <TextField
                  {...field}
                  size="small"
                  color="success"
                  variant="standard"
                  fullWidth
                  error={!!errors.rate}
                  helperText={errors.rate?.message}
                />
              )}
            />
          ) : (
            <Box sx={{ position: 'relative' }}>
              <Typography variant={'body2'}>
                {task.rate ? task.rate : '-'}
              </Typography>
              {task.rate !== taskHistory?.rate && (
                <div style={styles.History}>{taskHistory?.rate}</div>
              )}
            </Box>
          ))}
      </TableCell>

      <TableCell>
        {task.type === 'scheduleOfRates' ? (
          <Box>
            <Typography variant={'body2'}>
              {task.quantity} {task.unitOfMeasure}
            </Typography>
            <Typography variant={'body2'}>
              ${task.rate} per {task.unitOfMeasure}
            </Typography>
          </Box>
        ) : null}
      </TableCell>

      <TableCell>
        {isEditable ? (
          <Controller
            name="value"
            control={control}
            rules={{
              required: 'Value is required',
              pattern: {
                value: /^[0-9]*$/,
                message: 'Value should be a number',
              },
            }}
            render={({ field }) => (
              <TextField
                {...field}
                size="small"
                color="success"
                disabled={isShowScheduleFields}
                value={
                  isShowScheduleFields
                    ? String(quantity * rate)
                    : field.value || ''
                }
                variant="standard"
                required
                fullWidth
                autoFocus
                error={!!errors.value}
                helperText={errors.value?.message?.toString()}
              />
            )}
          />
        ) : (
          <Box sx={{ position: 'relative' }}>
            <Typography>
              {formatAmountByType(Number(task.value), DOLLAR)}
              {taskHistory && taskHistory.originalValue !== task.value && (
                <div style={styles.History}>{taskHistory.originalValue}</div>
              )}
            </Typography>
          </Box>
        )}
      </TableCell>

      <TableCell width={'30%'}>
        <Box sx={{ position: 'relative' }}>
          <Controller
            name="excludeFromRetention"
            control={control}
            defaultValue={task.excludeFromRetention}
            render={({ field }) => (
              <Checkbox
                {...field}
                checked={field.value}
                onChange={e => field.onChange(e.target.checked)}
                disabled={!isEditable}
              />
            )}
          />
        </Box>
      </TableCell>

      <TableCell sx={{ textAlign: 'center' }}>
        {isEditable ? (
          <IconButton onClick={handleActionClick} disabled={!isValid}>
            <DoneIcon color="success" />
          </IconButton>
        ) : (
          <IconButton onClick={handleClick}>
            <MoreVertIcon />
          </IconButton>
        )}

        <Menu
          anchorEl={anchorEl}
          open={isOpenActionButtons}
          onClose={handleClose}
          PaperProps={{
            style: {
              width: '150px',
            },
          }}
        >
          <MenuItem onClick={handleActionClick}>Edit</MenuItem>
          <MenuItem onClick={handleDeleteTask}>Delete</MenuItem>
        </Menu>
      </TableCell>
    </StyledTableRow>
  );
}

const styles: { [key: string]: React.CSSProperties } = {
  History: {
    position: 'absolute',
    top: 18,
    fontSize: '10px',
    color: '#ed6c02',
  },
};
