import React, { useCallback, useMemo, useState } from 'react';
import { Box, Button, Divider, Typography } from '@mui/material';

import {
  Contract,
  ContractStatus,
  ResponseListBaseType,
  Task,
} from '../../../../common/api';

// Components
import { AddTaskForm } from '../AddTaskForm';
import { Pagination } from '../../../../components/pagination';
import { TasksTable } from '../taskTables/TaskTable';
import { TaskDetails } from '../../../task-details/TaskDetails';
import { TaskSummary } from '../TaskSummary';

// Types
import { BasePaginationProps } from '../../../../common/types';

// Utils
import { useAuth } from '../../../../common/auth-provider/AuthProvider';
import { isCustomer } from '../../../../common/access';
import { useCheckbox } from '../../hooks/useCheckbox';
import { useTaskGroupContext } from '../../../../components/task-group/components/TaskGroup.context';
import pluralize from 'pluralize';
import { AddSubtaskDialog } from '../../../task-details/components/AddSubtaskDialog';
import { findLastUnassignedTaskIndex } from '../../utils/taskTable.utils';

type Props = BasePaginationProps & {
  contract: Contract;
  tasks: ResponseListBaseType<Array<Task>>;
  tasksHistory?: Array<Task>;
  isEditable?: boolean;
  selectedTaskGroup?: string | null;
  reloadContract: () => void;
  reloadData: () => void;
  handleDeleteTask?: (selectedTasks: Array<string>) => void;
};

function TaskTab({
  contract,
  page,
  isEditable = false,
  reloadContract,
  reloadData,
  tasks,
  tasksHistory,
  handleChangePage,
  handleDeleteTask = () => {},
  rowsPerPage,
  selectedTaskGroup,
}: Props) {
  const { user } = useAuth();

  const [isOpenContractDialog, setIsOpenContractDialog] =
    useState<boolean>(false);
  const [displayContractDialog, setDisplayContractDialog] =
    useState<boolean>(false);
  const [isShowScheduleFields, setIsShowScheduleFields] = useState(false);

  const handleCloseContractDialog = useCallback(() => {
    setIsOpenContractDialog(false);
    setDisplayContractDialog(false);
  }, []);

  const handleRefetchContracts = useCallback(async () => {
    await reloadData();
  }, [reloadData]);

  const isDraftContract = contract.status === ContractStatus.Draft;

  const {
    selected,
    setSelected,
    isItemSelected,
    handleCheckboxClick,
    handleSelectAll,
    selectAll,
  } = useCheckbox(tasks?.data);

  const { taskGroups } = useTaskGroupContext();

  const tasksWithHistory: Array<
    Task & { status: 'new' | 'updated' | 'deleted' }
  > = useMemo(() => {
    const deletedTasks =
      tasksHistory
        ?.filter(({ hasRevision }) => !hasRevision)
        .map(task => ({ ...task, status: 'deleted' as const })) || [];
    const updatedCurrentTasks = tasks.data.map(task => ({
      ...task,
      status: task.revisionTaskId ? ('updated' as const) : ('new' as const),
    }));
    return [...updatedCurrentTasks, ...deletedTasks];
  }, [tasksHistory, tasks.data]);

  const isHaveClaim = tasks?.data
    ?.filter(({ id }) => selectAll.includes(id))
    .some(
      ({ approved, claimed, paid }) =>
        Number(approved) || Number(claimed) || Number(paid)
    );

  const handleTaskDelete = useCallback(() => {
    handleDeleteTask(selectAll);
  }, [handleDeleteTask, selectAll]);

  const selectTaskGroup = useMemo(
    () => taskGroups.find(tg => tg.id === selectedTaskGroup),
    [selectedTaskGroup, taskGroups]
  );

  const lastTaskId = findLastUnassignedTaskIndex(tasks.data);

  const getNextTaskId = () => {
    if (tasks.meta.nextId) {
      if (contract.level === 0 && tasks.meta.total === 0) {
        return 0;
      } else {
        return Math.floor(tasks.meta.nextId);
      }
    } else {
      return 1;
    }
  };

  return (
    <>
      <Typography sx={{ mb: 3 }}>
        {selectTaskGroup?.name || 'All Tasks'}
      </Typography>
      <TaskSummary tasks={tasks} contract={contract} />
      {isDraftContract &&
        user &&
        isCustomer(user, contract) &&
        selectedTaskGroup && (
          <Box sx={{ m: 1, display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              variant="outlined"
              sx={{ m: 1 }}
              onClick={handleTaskDelete}
              disabled={!selectAll.length || isHaveClaim}
            >
              Delete {selectAll.length} {pluralize('Task', selectAll.length)}
            </Button>
          </Box>
        )}
      <TasksTable
        tasks={tasksWithHistory}
        tasksHistory={tasksHistory}
        selectAll={selectAll}
        handleSelectAll={handleSelectAll}
        selected={selected}
        setSelected={setSelected}
        handleCheckboxClick={handleCheckboxClick}
        isEditable={isEditable}
        isItemSelected={isItemSelected}
        contract={contract}
        reloadContract={reloadContract}
        reloadData={reloadData}
        handleDeleteTask={handleDeleteTask}
      />
      {/* direct customer to contract can add a task */}
      {isDraftContract &&
        user &&
        isCustomer(user, contract) &&
        selectedTaskGroup && (
          <AddTaskForm
            contractId={contract.id}
            nextTaskId={getNextTaskId()}
            selectedTaskGroup={selectedTaskGroup}
            reloadData={reloadData}
            reloadContract={reloadContract}
            isShowScheduleFields={isShowScheduleFields}
            setIsShowScheduleFields={setIsShowScheduleFields}
          />
        )}
      <Divider sx={{ mb: 3 }} />
      <Pagination
        count={Math.ceil(tasks.meta.total / rowsPerPage)}
        handleChangePage={handleChangePage}
        page={page}
        total={tasks.meta.total}
      />
      <TaskDetails
        contract={contract}
        task={selected}
        isOpen={!!selected}
        selectTaskGroup={selectTaskGroup}
        onClose={() => {
          reloadData();
          setSelected(null);
        }}
      />
      <AddSubtaskDialog
        onRefetchContracts={handleRefetchContracts}
        isOpen={isOpenContractDialog}
        display={displayContractDialog}
        parentTaskId={''}
        onClose={handleCloseContractDialog}
        projectId={contract.projectId}
        lastTaskId={lastTaskId}
      />
    </>
  );
}

export { TaskTab };
