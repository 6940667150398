import React from 'react';
import { formatCurrency } from '../../../../../common/format';
import { Claim, ClaimType } from '../../../../../common/api';
import { TaskClaimItemValueCell } from './TaskClaimItemValueCell';
import { TableCell, Typography } from '@mui/material';
import { StyledTableRow } from '../../../../../styles';
import { ProgressBar } from '../../../../../components/progress-bar';
import { CurrentClaimRow } from './CurrentClaimTab.utils';

interface Props {
  row: CurrentClaimRow;
  claim: Claim;
  onClaimItemChange: (
    id: string,
    newValue: number,
    newClaimType: ClaimType,
    isError: boolean
  ) => void;
  reloadData: () => void;
}

function CurrentClaimTableRow(props: Props) {
  const { row, claim, onClaimItemChange, reloadData } = props;

  return (
    <StyledTableRow key={row.id}>
      <TableCell>
        <Typography variant={'body2'}>{row.identifier || ''}</Typography>
      </TableCell>
      <TableCell>
        <Typography variant={'body2'}>{row.name}</Typography>
      </TableCell>
      <TableCell>{formatCurrency(row.taskValue)}</TableCell>
      <TableCell>{formatCurrency(row.previouslyApproved)}</TableCell>
      <TableCell>
        <ProgressBar
          total={row.taskValue}
          claimed={row.claimed}
          approved={row.approved}
          paid={row.paid}
          approvedVariation={Number(row.approvedVariation)}
          unApprovedVariation={Number(row.unApprovedVariation)}
          valueType={row.valueType}
        />
      </TableCell>
      <TableCell>
        <TaskClaimItemValueCell
          id={row.id}
          claimId={claim.id}
          taskId={row.taskId}
          totalValue={row.taskValue}
          costToComplete={row.costToComplete}
          defaultClaimType={row.claimType}
          defaultValue={row.value}
          onClaimItemChange={onClaimItemChange}
          reloadData={reloadData}
        />
      </TableCell>
    </StyledTableRow>
  );
}

export { CurrentClaimTableRow };
