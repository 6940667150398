import React from 'react';
import { ChipAlert, ChipDanger, ChipOk, ChipReview } from '../../styles/chips';
import { VariationStatus } from '../../common/api';

interface Props {
  status: VariationStatus;
  isCustomer?: boolean;
  isClosed?: boolean;
}

export function VariationStatusChip({
  status,
  isCustomer,
  isClosed = false,
}: Props) {
  if (status === VariationStatus.Submitted) {
    if (isCustomer) {
      return <ChipAlert label="Received & Under Review" />;
    }
    return <ChipAlert label="Submitted" />;
  }

  if (status === VariationStatus.Rejected) {
    return <ChipAlert label="Rejected" />;
  }

  if (status === VariationStatus.RejectAndClosed) {
    return <ChipDanger label="Rejected And Closed" />;
  }

  if (status === VariationStatus.Withdrawn) {
    return <ChipAlert label="Withdrawn" />;
  }

  if (status === VariationStatus.WithdrawnAndClosed) {
    return <ChipDanger label="Withdrawn and Closed" />;
  }

  if (status === VariationStatus.Draft) {
    if (isClosed) {
      return <ChipDanger label="Closed" />;
    }
    return <ChipReview label="Draft" />;
  }

  if (status === VariationStatus.FurtherInfoRequested) {
    return <ChipAlert label="Further Info Requested" />;
  }

  return <ChipOk label="Approved" />;
}
