import React, { useCallback } from 'react';
import { Box, Checkbox, TableCell, Typography } from '@mui/material';
import { StyledTableRow, StyledTooltip } from '../../../styles';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  ClaimStatus,
  DOLLAR,
  Notification,
  PERCENT,
  ValueType,
} from '../../../common/api';
import { ChipStatus } from '../../chip-status';
import { formatAmountByType, formatDate } from '../../../common/format';
import {
  formatDaysTillDate,
  projectDate,
} from '../../../common/format/formatDate';
import { useProject } from '../../../common/hooks/useProject';
import dayjs from 'dayjs';
import CircleIcon from '@mui/icons-material/Circle';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import { brand700 } from '../../../styles/colours';

interface Props {
  approve: {
    id: string;
    ref: string;
    subContractor: string;
    caption: string;
    status: ClaimStatus;
    statusOrder: number;
    dateSubmitted: string;
    claimValue: number;
    sopaExpiration: string;
    contractId: string;
    paymentDueAt: string;
    contractTotalValue: number;
    periodStart: string;
    periodEnd: string;
    scheduledAt: string;
    valueType: ValueType;
    taskValue: string;
    notificationId: string;
  };
  handleClick: () => void;
  handleCheckbox: () => void;
  notification?: Notification;
}

function ApprovalsTableRow(props: Props) {
  const { approve, handleClick, handleCheckbox, notification } = props;
  const { project } = useProject();
  const { pathname } = useLocation();
  const sopaDate = formatDaysTillDate(
    approve.sopaExpiration,
    projectDate(project?.systemDate)
  );

  const isOverdue =
    dayjs(approve.paymentDueAt) < projectDate(project?.systemDate) &&
    approve.status !== ClaimStatus.Paid;

  const sopaExpiration =
    sopaDate > 0
      ? `${Math.abs(sopaDate)} days OVERDUE`
      : `${Math.abs(sopaDate)} days`;

  const navigate = useNavigate();
  const claimPeriod = `${formatDate(approve.periodStart)} - ${formatDate(
    approve.periodEnd
  )}`;
  const claimValue =
    approve.valueType === PERCENT
      ? (approve.claimValue / 100) * Number(approve.taskValue)
      : approve.claimValue;

  const dateResponded = approve.scheduledAt
    ? formatDate(approve.scheduledAt)
    : '-';

  const handleClickClaim = useCallback(() => {
    handleClick();
    navigate(`${pathname}/claims/${approve.id}`);
  }, [approve.id, navigate, pathname, handleClick]);

  return (
    <StyledTableRow
      hover={!pathname.includes('subcontract')}
      key={approve.id}
      onClick={handleClickClaim}
    >
      <TableCell>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Box>
            <Typography variant={'body1'}>{approve.subContractor}</Typography>
            <Typography variant={'caption'}>{approve.caption}</Typography>
          </Box>
          {notification && (
            <StyledTooltip
              title={notification.isRead ? 'Mark Unread' : 'Mark Read'}
              placement="top"
            >
              <Checkbox
                checked={notification.isRead}
                onClick={event => {
                  event.stopPropagation();
                  handleCheckbox();
                }}
                icon={<CircleIcon sx={styles.Icon} />}
                checkedIcon={<RadioButtonUncheckedIcon sx={styles.Icon} />}
              />
            </StyledTooltip>
          )}
        </Box>
      </TableCell>
      <TableCell>
        <ChipStatus isOverdue={isOverdue} status={approve.status} isCustomer />
      </TableCell>
      <TableCell>
        <Typography variant={'body1'}>{claimPeriod}</Typography>
      </TableCell>
      <TableCell>
        <Typography variant={'body1'}>{approve.dateSubmitted}</Typography>
      </TableCell>
      {approve.sopaExpiration ? (
        <TableCell>
          <Typography variant={'body1'}>{sopaExpiration}</Typography>
          <Typography variant={'caption'}>
            {formatDate(approve.sopaExpiration)}
          </Typography>
        </TableCell>
      ) : (
        <TableCell>
          <Typography variant={'caption'}>-</Typography>
        </TableCell>
      )}
      <TableCell>
        <Typography variant={'body1'}>{dateResponded}</Typography>
      </TableCell>
      <TableCell>
        <Typography variant={'body1'}>
          {approve.valueType === PERCENT
            ? `${Math.round((claimValue * 100) / approve.contractTotalValue)} %`
            : formatAmountByType(approve.claimValue, DOLLAR)}
        </Typography>
      </TableCell>
    </StyledTableRow>
  );
}

const styles = {
  Icon: {
    color: brand700,
  },
};

export { ApprovalsTableRow };
