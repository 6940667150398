import React from 'react';
import {
  SummaryCard,
  SummaryGrid,
  SummaryText,
  SummaryTextStyle,
} from '../../project-list-page/components/SummaryCard';
import { CircularProgress, Typography } from '@mui/material';
import { formatAmountByType, formatDate } from '../../../common/format';
import { Claim, ClaimStatus, DOLLAR, PERCENT } from '../../../common/api';
import { grey900, red700 } from '../../../styles/colours';
import {
  formatDaysTillDate,
  projectDate,
} from '../../../common/format/formatDate';
import { useClaim } from '../../../common/hooks/useClaim';
import { useProject } from '../../../common/hooks/useProject';

interface Props {
  claim: Claim;
  contractPaymentTerm: number;
  isCustomer?: boolean;
  isContractor?: boolean;
}
function ClaimSummary(props: Props) {
  const { isCustomer, isContractor } = props;

  const { project } = useProject();
  const { claim } = useClaim();
  if (!claim) {
    return <CircularProgress />;
  }

  const valueClaimed = (
    <SummaryCard title="Value Claimed">
      <SummaryText
        value={formatAmountByType(
          claim.totalOriginalValue,
          claim.valueType || PERCENT
        )}
      />
    </SummaryCard>
  );

  const valueApproved = (
    <SummaryCard title="Value Approved">
      <SummaryText
        value={formatAmountByType(
          Math.abs(claim.totalValue),
          claim.valueType === DOLLAR ? DOLLAR : PERCENT
        )}
      />
    </SummaryCard>
  );

  const valueProposed = (
    <SummaryCard title="Value Proposed">
      <SummaryText
        value={formatAmountByType(
          Math.abs(claim.totalValue),
          claim.valueType === DOLLAR ? DOLLAR : PERCENT
        )}
      />
    </SummaryCard>
  );

  const variance = (
    <SummaryCard title="Variance">
      <Typography
        sx={{
          ...SummaryTextStyle,
          color: claim.totalVariance < 0 ? red700 : grey900,
        }}
      >
        {formatAmountByType(
          claim.totalVariance,
          claim.valueType === DOLLAR ? DOLLAR : PERCENT
        )}
      </Typography>
    </SummaryCard>
  );

  const dateSubmitted = (
    <SummaryCard title="Date Submitted">
      <SummaryText value={formatDate(claim.submittedAt)} />
    </SummaryCard>
  );

  const claimPeriod = (
    <SummaryCard title="Claim Period">
      <SummaryText
        value={`${formatDate(claim.periodStart)} - ${formatDate(
          claim.periodEnd
        )}`}
      />
    </SummaryCard>
  );

  const dateApproved = (
    <SummaryCard title="Date Approved">
      <SummaryText value={formatDate(claim.approvedAt)} />
    </SummaryCard>
  );

  const dateResponded = (
    <SummaryCard title="Date Responded">
      <SummaryText value={formatDate(claim.scheduledAt)} />
    </SummaryCard>
  );

  const dateRejected = (
    <SummaryCard title="Date Rejected">
      <SummaryText value={formatDate(claim.rejectedAt)} />
    </SummaryCard>
  );

  const dateWithdrawn = (
    <SummaryCard title="Date Withdrawn">
      <SummaryText value={formatDate(claim.withdrawnAt)} />
    </SummaryCard>
  );

  const sopaDate = formatDaysTillDate(
    claim.expiration,
    projectDate(project?.systemDate)
  );

  const sopaExpiration =
    sopaDate > 0
      ? `${Math.abs(sopaDate)} days OVERDUE`
      : `${Math.abs(sopaDate)} days`;

  const sopa = (
    <SummaryCard title="SOPA Expiration">
      {claim.expiration ? (
        <>
          <Typography sx={SummaryTextStyle}>{sopaExpiration}</Typography>
          <Typography variant={'caption'}>
            {formatDate(claim.expiration)}
          </Typography>
        </>
      ) : (
        <Typography sx={SummaryTextStyle}>-</Typography>
      )}
    </SummaryCard>
  );

  // date approved + payment term
  const paymentDue = (
    <SummaryCard title="Payment Due">
      <SummaryText
        value={claim.paymentDueAt ? formatDate(claim.paymentDueAt) : ''}
      />
    </SummaryCard>
  );

  const datePaid = (
    <SummaryCard title="Date Paid">
      <SummaryText value={formatDate(claim.paidAt)} />
    </SummaryCard>
  );

  if (isCustomer) {
    if (claim.status === ClaimStatus.Submitted) {
      return (
        <SummaryGrid>
          {valueClaimed}
          {claimPeriod}
          {valueProposed}
          {variance}
          {dateSubmitted}
          {sopa}
        </SummaryGrid>
      );
    }

    if (claim.status === ClaimStatus.Awaiting) {
      return (
        <SummaryGrid>
          {valueClaimed}
          {claimPeriod}
          {valueProposed}
          {variance}
          {dateSubmitted}
          {dateResponded}
        </SummaryGrid>
      );
    }

    if (claim.status === ClaimStatus.Approved) {
      return (
        <SummaryGrid>
          {valueClaimed}
          {claimPeriod}
          {valueApproved}
          {variance}
          {dateSubmitted}
          {dateApproved}
          {paymentDue}
        </SummaryGrid>
      );
    }

    if (claim.status === ClaimStatus.Paid) {
      return (
        <SummaryGrid>
          {valueClaimed}
          {claimPeriod}
          {valueApproved}
          {variance}
          {dateSubmitted}
          {dateApproved}
          {datePaid}
        </SummaryGrid>
      );
    }

    if (claim.status === ClaimStatus.Rejected) {
      return (
        <SummaryGrid>
          {valueClaimed}
          {claimPeriod}
          {valueProposed}
          {variance}
          {dateSubmitted}
          {dateResponded}
          {dateRejected}
        </SummaryGrid>
      );
    }

    if (claim.status === ClaimStatus.Withdrawn) {
      return (
        <SummaryGrid>
          {valueClaimed}
          {claimPeriod}
          {dateSubmitted}
          {dateWithdrawn}
        </SummaryGrid>
      );
    }
  }

  if (isContractor) {
    if (claim.status === ClaimStatus.Submitted && !isCustomer) {
      return (
        <SummaryGrid>
          {valueClaimed}
          {claimPeriod}
          {dateSubmitted}
          {sopa}
        </SummaryGrid>
      );
    }

    if (claim.status === ClaimStatus.Awaiting && !isCustomer) {
      return (
        <SummaryGrid>
          {valueClaimed}
          {claimPeriod}
          {valueProposed}
          {variance}
          {dateSubmitted}
          {dateResponded}
        </SummaryGrid>
      );
    }

    if (claim.status === ClaimStatus.Approved && !isCustomer) {
      return (
        <SummaryGrid>
          {valueClaimed}
          {claimPeriod}
          {valueApproved}
          {variance}
          {dateSubmitted}
          {dateApproved}
          {paymentDue}
        </SummaryGrid>
      );
    }

    if (claim.status === ClaimStatus.Paid) {
      return (
        <SummaryGrid>
          {valueClaimed}
          {claimPeriod}
          {valueApproved}
          {variance}
          {dateSubmitted}
          {dateApproved}
          {datePaid}
        </SummaryGrid>
      );
    }

    if (claim.status === ClaimStatus.Rejected) {
      return (
        <SummaryGrid>
          {valueClaimed}
          {claimPeriod}
          {valueProposed}
          {variance}
          {dateSubmitted}
          {dateResponded}
          {dateRejected}
        </SummaryGrid>
      );
    }

    if (claim.status === ClaimStatus.Withdrawn) {
      return (
        <SummaryGrid>
          {valueClaimed}
          {claimPeriod}
          {dateSubmitted}
          {dateWithdrawn}
        </SummaryGrid>
      );
    }
  }

  if (claim.status === ClaimStatus.Submitted && !isCustomer) {
    return (
      <SummaryGrid>
        {valueClaimed}
        {claimPeriod}
        {dateSubmitted}
        {sopa}
      </SummaryGrid>
    );
  }

  if (claim.status === ClaimStatus.Awaiting && !isCustomer) {
    return (
      <SummaryGrid>
        {valueClaimed}
        {claimPeriod}
        {valueProposed}
        {variance}
        {dateSubmitted}
        {dateResponded}
      </SummaryGrid>
    );
  }

  if (claim.status === ClaimStatus.Approved && !isCustomer) {
    return (
      <SummaryGrid>
        {valueClaimed}
        {claimPeriod}
        {valueApproved}
        {variance}
        {dateSubmitted}
        {dateApproved}
        {paymentDue}
      </SummaryGrid>
    );
  }

  if (claim.status === ClaimStatus.Paid) {
    return (
      <SummaryGrid>
        {valueClaimed}
        {claimPeriod}
        {valueApproved}
        {variance}
        {dateSubmitted}
        {dateApproved}
        {datePaid}
      </SummaryGrid>
    );
  }

  if (claim.status === ClaimStatus.Rejected) {
    return (
      <SummaryGrid>
        {valueClaimed}
        {claimPeriod}
        {valueProposed}
        {variance}
        {dateSubmitted}
        {dateResponded}
        {dateRejected}
      </SummaryGrid>
    );
  }

  if (claim.status === ClaimStatus.Withdrawn) {
    return (
      <SummaryGrid>
        {valueClaimed}
        {claimPeriod}
        {dateSubmitted}
        {dateWithdrawn}
      </SummaryGrid>
    );
  }

  return null;
}

export { ClaimSummary };
