import styled from '@emotion/styled';

export const TaskGroupItemLabel = styled('div')`
  min-height: 32px;
  min-width: 0;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 32px;
  padding-right: 60px;
`;

export const TaskGroupItemMenuWrapper = styled('div')`
  flex-shrink: 0;
  width: 80px;

  & > button {
    display: none;
    position: absolute;
    right: 30px;
    top: 5px;
    height: 30px;
  }

  .taskgroup-add {
    right: 0;
    width: 30px;
  }
`;
