import { PaymentData } from '../../../common/api';
import { formatDate } from '../../../common/format';
import { HeadCell } from '../../../components/sorted-table-head/SortedTable.hooks';
import dayjs from 'dayjs';

export const headCellsPaymentsDue: Array<HeadCell> = [
  {
    id: 'projectName',
    numeric: false,
    disablePadding: false,
    label: 'Project',
  },
  {
    id: 'payeeName',
    numeric: false,
    disablePadding: false,
    label: 'Payee',
  },
  {
    id: 'description',
    numeric: false,
    disablePadding: false,
    label: 'Claim Name',
  },
  {
    id: 'approvedDate',
    numeric: false,
    disablePadding: false,
    label: 'Payment Approved',
  },
  {
    id: 'dueDate',
    numeric: false,
    disablePadding: false,
    label: 'Due date',
  },
  {
    id: 'amount',
    numeric: true,
    disablePadding: false,
    label: 'Amount',
  },

  {
    id: 'actions',
    numeric: false,
    disablePadding: false,
    label: '',
    disable: true,
  },
];

export const headCellsPaymentsMade: Array<HeadCell> = [
  {
    id: 'projectName',
    numeric: false,
    disablePadding: false,
    label: 'Project',
  },
  {
    id: 'payeeName',
    numeric: false,
    disablePadding: false,
    label: 'Payee',
  },
  {
    id: 'description',
    numeric: false,
    disablePadding: false,
    label: 'Claim Name',
  },
  {
    id: 'approvedDate',
    numeric: false,
    disablePadding: false,
    label: 'Payment Approved',
  },
  {
    id: 'dueDate',
    numeric: false,
    disablePadding: false,
    label: 'Due date',
  },
  {
    id: 'paidDate',
    numeric: false,
    disablePadding: false,
    label: 'Payment Date',
  },
  {
    id: 'amount',
    numeric: true,
    disablePadding: false,
    label: 'Amount',
  },
];

export const headCellsIncome: Array<HeadCell> = [
  {
    id: 'projectName',
    numeric: false,
    disablePadding: false,
    label: 'Project',
  },
  {
    id: 'payerName',
    numeric: false,
    disablePadding: false,
    label: 'Payer',
  },
  {
    id: 'description',
    numeric: false,
    disablePadding: false,
    label: 'Claim Name',
  },
  {
    id: 'approvedDate',
    numeric: false,
    disablePadding: false,
    label: 'Payment Approved',
  },
  {
    id: 'dueDate',
    numeric: false,
    disablePadding: false,
    label: 'Due date',
  },
  {
    id: 'paidDate',
    numeric: false,
    disablePadding: false,
    label: 'Payment Date',
  },
  {
    id: 'amount',
    numeric: true,
    disablePadding: false,
    label: 'Amount',
  },
  {
    id: 'actions',
    numeric: false,
    disablePadding: false,
    label: '',
    disable: true,
  },
];

export type PaymentRow = {
  id: string;
  payeeName: string;
  payerName: string;
  description: string;
  approvedAt: string;
  approvedDate: number;
  dueAt: string;
  dueDate: number;
  paidAt: string;
  paidDate: number;
  amount: number;
  amountOutstanding: number;
  projectName: string;
  claimId: string;
  claimPeriodStart: string;
  claimPeriodEnd: string;
  contractId: string;
  bankSetupComplete: string;
  payWhenDue: string;
};

export function getPaymentRows(
  payments: Array<PaymentData>
): Array<PaymentRow> {
  return payments.map(payment => {
    return {
      id: payment.id,
      payeeName: payment.payee.name,
      payerName: payment.payer.name,
      approvedAt: formatDate(payment.approvedAt),
      approvedDate: dayjs(payment.approvedAt || 0).unix(),
      dueAt: formatDate(payment.dueAt),
      dueDate: dayjs(payment.dueAt || 0).unix(),
      paidAt: formatDate(payment.paidAt),
      paidDate: dayjs(payment.paidAt || 0).unix(),
      payWhenDue: payment.payWhenDue ? 'true' : 'false',
      amount: Number(payment.amount),
      amountOutstanding: Number(payment.amountOutstanding),
      projectName: payment.projectName,
      claimId: payment.claim.id,
      claimPeriodStart: formatDate(payment.claim.periodStart),
      claimPeriodEnd: formatDate(payment.claim.periodEnd),
      contractId: payment.claim.contractId,
      description: payment.claim.description || '',
      bankSetupComplete: payment.payee.bankSetupComplete ? 'true' : 'false',
    };
  });
}
