import styled from '@emotion/styled';
import { Box } from '@mui/material';

import { brand800, grey900 } from '../../styles/colours';
import { baseRichtextCss } from './text/Richtext.styles';

export const Tile = styled('div')`
  border-radius: 8px;
  display: flex;
  height: 100%;
  flex-direction: column;
  overflow: hidden;
  position: relative;
`;

export const TileValue = styled(Box)`
  color: ${brand800};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  font-size: 56px;
  font-weight: 900;
`;

export const TileText = styled(Box)`
  background-color: white;
  padding: 12px;
`;

export const TileRichtext = styled('div')`
  width: 100%;

  color: ${grey900};
  padding: 8px 16px;

  ${baseRichtextCss}
`;
