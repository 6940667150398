import { Skeleton } from '@mui/material';
import { FileDrawerComplianceFilesList } from './FileDrawerComplianceFilesList';
import React, { useMemo } from 'react';
import {
  ClaimFile,
  ContractFile,
  listContractFiles,
} from '../../../../../common/api';
import { Params, useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { LocalVariationFile } from '../../../../variations/VariationFileDrawer';

interface Props {
  files: Array<ClaimFile> | Array<LocalVariationFile>;
  isLoadingMutateCreateFile: boolean;
  handleCreateFile?: (selectedFiles: Array<ContractFile>) => void;
  setSelectedFiles?: (file: Array<ContractFile>) => void;
}

function FileDrawerComplianceLoader({
  files,
  handleCreateFile,
  isLoadingMutateCreateFile,
  setSelectedFiles,
}: Props) {
  const { contractId, subcontractId } = useParams<Params>();

  const { data: contractFiles, isLoading: isContractFilesLoading } = useQuery<
    Array<ContractFile>
  >(['node-files', subcontractId || contractId], () =>
    listContractFiles(subcontractId || contractId!)
  );

  const complianceAttachFiles = useMemo(
    () =>
      contractFiles?.filter(
        file => !files.some(claimFile => claimFile.path === file.path)
      ),
    [files, contractFiles]
  );

  return (
    <>
      {isContractFilesLoading ? (
        <>
          <Skeleton animation="wave" variant="rectangular" />
          <Skeleton animation="wave" variant="rectangular" />
          <Skeleton animation="wave" variant="rectangular" />
        </>
      ) : (
        complianceAttachFiles &&
        complianceAttachFiles.length !== 0 && (
          <FileDrawerComplianceFilesList
            handleCreateFile={handleCreateFile}
            setSelectedFiles={setSelectedFiles}
            isLoadingCreateFile={isLoadingMutateCreateFile}
            contractFiles={complianceAttachFiles}
          />
        )
      )}
    </>
  );
}

export { FileDrawerComplianceLoader };
