import React, {
  Children,
  isValidElement,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useLocation } from 'react-router-dom';
import { Box, Tab, Typography } from '@mui/material';
import Badge from '@mui/material/Badge';

// Styles
import { StyledTabs } from './styles';

export type TabType = {
  greenDotsLength?: number;
  id: string;
  isShow?: boolean;
  label: string;
  redDotsLength?: number;
};

interface TabsProps {
  activeTab?: string;
  children: React.ReactNode;
  tabsData: TabType[];
  onTabChange?: (value: string) => void;
}

export const Tabs = ({
  activeTab,
  children,
  tabsData,
  onTabChange = () => {},
}: TabsProps) => {
  const location = useLocation();
  const tabIds = tabsData.map(({ id }) => id);

  const [currentTab, setCurrentTab] = useState(activeTab || tabIds[0]);

  const handleChangeCurrentTab = useCallback(
    (event: React.SyntheticEvent<Element, Event>, value: string) => {
      setCurrentTab(value);
      onTabChange(value);
    },
    [onTabChange]
  );

  // Effects
  useEffect(() => {
    if (location.state?.tab) {
      setCurrentTab(location.state?.tab);
    }
  }, [location]);

  return (
    <>
      <StyledTabs
        sx={{ pt: 2, mb: '16px' }}
        textColor="inherit"
        value={currentTab}
        onChange={handleChangeCurrentTab}
      >
        {tabsData.map(
          ({ isShow = true, greenDotsLength, redDotsLength, label, id }) => {
            if (!isShow) {
              return null;
            }

            if (greenDotsLength || redDotsLength) {
              return (
                <Tab
                  value={id}
                  sx={{ ml: 0, mr: 4 }}
                  wrapped
                  key={id}
                  label={
                    <Box sx={{ display: 'flex', px: '8px' }}>
                      {Boolean(greenDotsLength) && (
                        <Badge
                          color="success"
                          badgeContent={greenDotsLength}
                          sx={{
                            transform: 'scale(0.7)',
                            mr: '8px',
                          }}
                        />
                      )}
                      <Typography fontSize="14px" fontWeight={500}>
                        {label}
                      </Typography>
                      {Boolean(redDotsLength) && (
                        <Badge
                          color="error"
                          badgeContent={redDotsLength}
                          sx={{
                            transform: 'scale(0.7)',
                            ml: '8px',
                          }}
                        />
                      )}
                    </Box>
                  }
                />
              );
            }

            return (
              <Tab key={id} sx={{ ml: 0, mr: 4 }} label={label} value={id} />
            );
          }
        )}
      </StyledTabs>
      {Children.map(children, child => {
        if (isValidElement(child)) {
          if (child.props.id !== currentTab) {
            return null;
          }
          return <div key={child.props.id}>{child}</div>;
        }
        return null;
      })}
    </>
  );
};
