import {
  Box,
  Button,
  Dialog,
  DialogContent,
  TextField,
  Typography,
} from '@mui/material';
import React, { useCallback, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useDropzone } from 'react-dropzone';
import { LoadingButton } from '@mui/lab';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';

import { useFileUpload } from './FileUpload';
import { FileUploadZone } from './FileUploadDropzone.style';
import { Heading, InputActions } from '../../styles';
import { DEFAULT_DATE_FORMAT } from '../../common/format';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { useProject } from '../../common/hooks/useProject';

const DOCUMENT_NAME_FIELD = 'documentName';
const DOCUMENT_TYPE_FIELD = 'documentType';
const DOCUMENT_EXPIRY_FIELD = 'documentExpiry';

type FormData = {
  [DOCUMENT_NAME_FIELD]: string;
  [DOCUMENT_TYPE_FIELD]?: string;
  [DOCUMENT_EXPIRY_FIELD]?: Date | null;
};

type UploadedFile = {
  id: string;
  documentName?: string;
  documentType?: string;
  documentExpiry?: string;
};

export type FilePayload = {
  file: File;
  path: string;
  contentType: string;
  [DOCUMENT_NAME_FIELD]?: string;
  [DOCUMENT_TYPE_FIELD]?: string;
  [DOCUMENT_EXPIRY_FIELD]?: string;
};

function FileUploadDialog({
  showLoading,
  isLocal,
  hasFormFields,
  uploadedFile,
  close,
  onFileUpload,
  onEditFileInfo,
}: {
  showLoading?: boolean;
  isLocal?: boolean;
  hasFormFields?: boolean;
  uploadedFile?: UploadedFile;
  onFileUpload: (payload: FilePayload) => void;
  onEditFileInfo?: (payload: UploadedFile) => void;
  close: () => void;
}) {
  const [files, setFiles] = useState<Array<File>>([]);
  const { project } = useProject();

  const {
    control,
    formState: { errors },
    setValue,
    handleSubmit,
    getValues,
  } = useForm<FormData>({
    defaultValues: {
      [DOCUMENT_NAME_FIELD]: uploadedFile?.documentName,
      [DOCUMENT_TYPE_FIELD]: uploadedFile?.documentType,
      [DOCUMENT_EXPIRY_FIELD]: uploadedFile?.documentExpiry
        ? dayjs(uploadedFile?.documentExpiry).toDate()
        : new Date(project?.systemDate || new Date()),
    },
  });

  const { isLoading: isUploadFileLoading, mutateGetUploadPath } = useFileUpload(
    {
      onFileUploadSuccess: (data, variables) => {
        close();
        const formValues = getValues();
        onFileUpload({
          ...formValues,
          file: variables.file,
          path: variables.path,
          contentType: variables.contentType,
          documentExpiry: formatExpiryDate(formValues[DOCUMENT_EXPIRY_FIELD]),
        });
      },
    }
  );

  const formatExpiryDate = useCallback((date?: Date | null) => {
    return date ? dayjs(date).toISOString() : undefined;
  }, []);

  const onSubmit = useCallback<SubmitHandler<FormData>>(
    (data: FormData) => {
      if (uploadedFile && onEditFileInfo) {
        onEditFileInfo({
          ...uploadedFile,
          documentName: data[DOCUMENT_NAME_FIELD],
          documentType: data[DOCUMENT_TYPE_FIELD],
          documentExpiry: formatExpiryDate(data[DOCUMENT_EXPIRY_FIELD]),
        });
      } else {
        for (const file of files) {
          if (isLocal) {
            onFileUpload({
              ...data,
              file: file,
              path: file.name,
              contentType: file.type,
              documentExpiry: formatExpiryDate(data[DOCUMENT_EXPIRY_FIELD]),
            });
            close();
          } else {
            mutateGetUploadPath(file);
          }
        }
      }
    },
    [
      files,
      isLocal,
      uploadedFile,
      close,
      mutateGetUploadPath,
      formatExpiryDate,
      onEditFileInfo,
      onFileUpload,
    ]
  );

  return (
    <Dialog open={true}>
      <DialogContent sx={{ width: '450px' }}>
        {!uploadedFile ? (
          <>
            <Heading>Upload file</Heading>
            {files.length > 0 ? (
              <>
                <Box marginBottom="16px">
                  {files.map((file, idx) => (
                    <Typography key={idx}>{file.name}</Typography>
                  ))}
                </Box>
                <Button variant="outlined" onClick={() => setFiles([])}>
                  Remove files
                </Button>
              </>
            ) : (
              <FileUploadDropzone
                onDropFiles={files => {
                  setFiles(files);
                }}
              />
            )}
          </>
        ) : (
          <Heading>Edit File Info</Heading>
        )}

        {hasFormFields ? (
          <Box marginTop={2}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Controller
                  name={DOCUMENT_NAME_FIELD}
                  defaultValue=""
                  control={control}
                  rules={{
                    required: 'Document Name is required',
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Document Name *"
                      size="small"
                      sx={{ mb: 2 }}
                      fullWidth
                      error={!!errors[DOCUMENT_NAME_FIELD]}
                      helperText={errors[DOCUMENT_NAME_FIELD]?.message}
                    />
                  )}
                />
                <Controller
                  name={DOCUMENT_TYPE_FIELD}
                  defaultValue=""
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Document Type"
                      size="small"
                      sx={{ mb: 2 }}
                      fullWidth
                      error={!!errors[DOCUMENT_TYPE_FIELD]}
                      helperText={errors[DOCUMENT_TYPE_FIELD]?.message}
                    />
                  )}
                />
                <Controller
                  name={DOCUMENT_EXPIRY_FIELD}
                  defaultValue={new Date(project?.systemDate || new Date())}
                  control={control}
                  render={({ field }) => (
                    <DatePicker
                      minDate={new Date(project?.systemDate || new Date())}
                      value={field.value}
                      onChange={date => {
                        setValue(DOCUMENT_EXPIRY_FIELD, date);
                      }}
                      inputFormat={DEFAULT_DATE_FORMAT}
                      renderInput={params => {
                        return (
                          <TextField
                            {...params}
                            fullWidth
                            size="small"
                            label="Expiry Date"
                          />
                        );
                      }}
                    />
                  )}
                />
                <InputActions>
                  <Button variant="outlined" onClick={close}>
                    Cancel
                  </Button>
                  <LoadingButton
                    variant="contained"
                    type="submit"
                    disabled={!uploadedFile && files.length === 0}
                    loading={isUploadFileLoading || showLoading}
                  >
                    Save
                  </LoadingButton>
                </InputActions>
              </form>
            </LocalizationProvider>
          </Box>
        ) : (
          <InputActions>
            <Button variant="outlined" onClick={close}>
              Cancel
            </Button>
            <LoadingButton
              variant="contained"
              type="button"
              onClick={() => {
                for (const file of files) {
                  if (isLocal) {
                    onFileUpload({
                      file: file,
                      path: file.name,
                      contentType: file.type,
                    });
                    close();
                  } else {
                    mutateGetUploadPath(file);
                  }
                }
              }}
              disabled={files.length === 0}
              loading={isUploadFileLoading || showLoading}
            >
              Save
            </LoadingButton>
          </InputActions>
        )}
      </DialogContent>
    </Dialog>
  );
}

function FileUploadDropzone({
  onDropFiles,
}: {
  onDropFiles: (files: Array<File>) => void;
}) {
  const onDrop = useCallback(
    (acceptedFiles: Array<File>) => {
      onDropFiles(acceptedFiles);
    },
    [onDropFiles]
  );

  const { getRootProps, getInputProps, isDragActive, inputRef } = useDropzone({
    multiple: false,
    onDrop,
  });

  return (
    <>
      <FileUploadZone {...getRootProps()} isDragActive={isDragActive}>
        <input ref={inputRef} {...getInputProps()} />
        <Typography>
          Drag and drop a file here, or click Browse to select a file
        </Typography>
      </FileUploadZone>
      <Button
        variant="outlined"
        onClick={() => {
          inputRef.current?.click();
        }}
      >
        Browse
      </Button>
    </>
  );
}

export { FileUploadDialog, FileUploadDropzone };
