import React, { forwardRef, ReactNode, Ref, SyntheticEvent } from 'react';
import { styled } from '@mui/system';
import clsx from 'clsx';
import TreeItem, {
  TreeItemContentProps,
  TreeItemProps,
  useTreeItem,
} from '@mui/lab/TreeItem';
import { brand100 } from '../../../styles/colours';
import { TaskGroupItemLabel } from './TaskGroupTree.style';
import { TASK_GROUP_ALL_ID } from './TaskGroups.constants';

declare module '@mui/lab/TreeItem' {
  interface TreeItemContentProps {
    canEdit: boolean;
  }
}

const ItemContainer = styled('div')<{ isEdit?: boolean }>(({ theme }) => ({
  color: theme.palette.text.primary,
  borderRadius: '4px',
  display: 'flex',
  alignItems: 'center',
  minHeight: '40px',
  padding: '4px 2px 4px 16px',
  position: 'relative',
  width: '100%',
  '&.is-edit': {
    paddingLeft: '32px',
  },
  '&.first-node': {
    paddingLeft: '0',
  },
  '&:hover': {
    cursor: 'pointer',
    '.menu-items > button ': {
      display: 'inline-flex',
    },
  },
  '&.Mui-selected': {
    backgroundColor: brand100,
  },
  '& .MuiTreeItem-iconContainer': {
    display: 'flex',
    alignItems: 'center',
    marginRight: '8px',
  },
  '& .MuiTreeItem-label': {
    fontSize: '14px',
    width: '100%',
  },
}));

type Props = TreeItemProps & {
  label: ReactNode;
  editable?: boolean;
};

function TaskGroupItem(props: Props) {
  const { editable, ...rest } = props;
  return (
    <TreeItem
      {...rest}
      ContentComponent={CustomTreeItemContent}
      ContentProps={
        {
          canEdit: editable,
        } as any
      }
    />
  );
}

const CustomTreeItemContent = forwardRef(function CustomContent(
  props: TreeItemContentProps,
  ref
) {
  const {
    classes,
    label,
    nodeId,
    icon: iconProp,
    expansionIcon,
    displayIcon,
    canEdit,
  } = props;

  const {
    disabled,
    expanded,
    selected,
    focused,
    handleExpansion,
    handleSelection,
    preventSelection,
  } = useTreeItem(nodeId);

  const icon = iconProp || expansionIcon || displayIcon;

  const handleMouseDown = (event: SyntheticEvent<Element, Event>) =>
    preventSelection(event);

  const handleExpansionClick = (event: SyntheticEvent<Element, Event>) =>
    handleExpansion(event);

  const handleSelectionClick = (event: SyntheticEvent<Element, Event>) =>
    handleSelection(event);

  return (
    <ItemContainer
      {...props}
      className={clsx({
        'is-edit': canEdit,
        'first-node': nodeId === TASK_GROUP_ALL_ID,
        [classes.expanded]: expanded,
        [classes.selected]: selected,
        [classes.focused]: focused,
        [classes.disabled]: disabled,
      })}
      ref={ref as Ref<HTMLDivElement>}
      onMouseDown={handleMouseDown}
    >
      <div className={classes.iconContainer} onClick={handleExpansionClick}>
        {icon}
      </div>
      <TaskGroupItemLabel onClick={handleSelectionClick}>
        {label}
      </TaskGroupItemLabel>
    </ItemContainer>
  );
});

export { TaskGroupItem };
