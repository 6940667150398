import React, { ChangeEvent, useEffect, useState } from 'react';
import { SortedTableHead } from '../../../../components/sorted-table-head';
import { TableBody } from '@mui/material';
import { StyledTable } from '../../../../styles';
import { useSortedTable } from '../../../../components/sorted-table-head/SortedTable.hooks';
import { Contract, getContract, Task } from '../../../../common/api';
import { stableSort } from '../../../../common/sort/stableSort';
import { getComparator } from '../../../../common/sort/getComparator';
import { EditableTaskRow } from './EditableTaskRow';
import {
  createTaskRow,
  excludeChildrenFieldFromArray,
  headCells,
  TaskRowData,
  TaskRowPropsWithStatus,
} from '../../utils/taskTable.utils';
import {
  isContractor,
  isCustomer,
  isProjectOwner,
} from '../../../../common/access';
import { useAuth } from '../../../../common/auth-provider/AuthProvider';
import { TaskRow } from './TaskRow';
import { getVisibilitySetting } from '../visibility/utils/visibilitySettings.utils';

interface Props {
  handleSelectAll: (event: ChangeEvent<HTMLInputElement>) => void;
  selectAll: Array<string>;
  selected: Task | null;
  tasks: Array<TaskRowPropsWithStatus>;
  tasksHistory?: Array<Task>;
  setSelected: (selected: Task | null) => void;
  handleCheckboxClick: (id: string) => void;
  isItemSelected: (id: string) => boolean;
  isEditable?: boolean;
  contract: Contract;
  reloadContract: () => void;
  reloadData: () => void;
  handleDeleteTask?: (selectedTasks: Array<string>) => void;
}

function TasksTable({
  contract,
  isItemSelected,
  isEditable = false,
  selectAll,
  reloadContract,
  reloadData,
  tasks,
  tasksHistory,
  setSelected,
  handleSelectAll,
  handleCheckboxClick,
  handleDeleteTask = () => {},
}: Props) {
  const [isDisabledTaskDrawer, setIsDisabledTaskDrawer] =
    useState<boolean>(false);
  const [openRows, setOpenRows] = useState<Set<string>>(new Set());
  const { order, orderBy, handleRequestSort } = useSortedTable('identifier');
  const { user } = useAuth();
  const rows = tasks.map(task => createTaskRow(task));
  const isCanView =
    user && (isCustomer(user, contract) || isContractor(user, contract));
  const isUserCustomer = isCustomer(user, contract);
  const isUserMainCustomer = user ? isProjectOwner(user, contract) : false;
  const isSubcontract = contract.level > 0;

  const handleRowToggle = (id: string) => {
    setOpenRows(prevOpenRows => {
      const newOpenRows = new Set(prevOpenRows);
      if (newOpenRows.has(id)) {
        newOpenRows.delete(id);
      } else {
        newOpenRows.add(id);
      }
      return newOpenRows;
    });
  };

  useEffect(() => {
    async function findParentContract() {
      if (contract.parentContractId) {
        const parentContract = await getContract(contract.parentContractId);

        if (parentContract) {
          const isDisabledTaskDrawer =
            isCustomer(user, parentContract) &&
            getVisibilitySetting(
              parentContract.visibilitySettings,
              'sub_subcontractorOrgInfo'
            ) &&
            !getVisibilitySetting(
              parentContract.visibilitySettings,
              'sub_subcontractorClaimItemsNameOnly'
            );

          setIsDisabledTaskDrawer(isDisabledTaskDrawer);
        }
      }
    }

    findParentContract();
  }, [contract.parentContractId, user]);

  function hasChildren(row: TaskRowData | undefined): boolean {
    return row?.children?.length ? row?.children?.length > 0 ?? false : false;
  }

  const renderChildren = (children: TaskRowData[], offset?: any) => (
    <>
      {children.map((child, id) => {
        const childTask = tasks.find(task => task.id === child.id) || null;

        return (
          <React.Fragment key={child.id}>
            <TaskRow
              task={child}
              selected={false}
              reloadData={reloadData}
              isSubcontract={isSubcontract}
              isDisabledTaskDrawer={isDisabledTaskDrawer}
              onClick={() =>
                setSelected(
                  tasks.find(task => task.id === childTask?.id) || null
                )
              }
              onToggle={() => handleRowToggle(child.id.toString())}
              isOpen={openRows.has(child.id.toString())}
              hasButtonToggle={hasChildren(child)}
              offset={`${offset * 10}`}
              isChild={true}
            />
            {openRows.has(child.id.toString()) &&
              child.children &&
              renderChildren(child.children, 6)}
          </React.Fragment>
        );
      })}
    </>
  );

  return (
    <StyledTable sx={{ mb: 3 }}>
      <SortedTableHead
        order={order}
        orderBy={orderBy}
        onRequestSort={handleRequestSort}
        headCells={headCells({
          isSubcontract: !isCanView,
          status: contract.status,
          isEditable,
          isUserMainCustomer,
        })}
        isEditable={isEditable}
        status={contract.status}
        handleSelectAll={handleSelectAll}
        selectAll={selectAll}
        data={tasks}
      />
      <TableBody>
        {stableSort(
          excludeChildrenFieldFromArray(rows),
          getComparator(order, orderBy)
        ).map((row, id) => {
          const fullRow = rows.find(r => r.id === row.id) || row;
          const taskHistory =
            tasksHistory?.find(task => task.id === row.revisionTaskId) || null;
          const isOpen = openRows.has(row.id.toString());

          return (
            <React.Fragment key={row.id}>
              {isEditable ? (
                <EditableTaskRow
                  contractId={contract.id}
                  parentContractId={
                    contract.parentContractId ? contract.parentContractId : ''
                  }
                  selected={false}
                  task={row}
                  tasks={tasks}
                  taskHistory={taskHistory}
                  status={contract.status}
                  isSubcontract={isSubcontract}
                  isCanView={!isCanView}
                  reloadContract={reloadContract}
                  reloadData={reloadData}
                  onClick={() => {
                    setSelected(tasks[id]);
                  }}
                  isItemSelected={isItemSelected}
                  onCheckboxClick={handleCheckboxClick}
                  handleDeleteTask={handleDeleteTask}
                  isCustomer={isUserCustomer}
                />
              ) : (
                <>
                  <TaskRow
                    selected={false}
                    task={row}
                    tasks={tasks}
                    contractId={contract.id}
                    parentContractId={
                      contract.parentContractId ? contract.parentContractId : ''
                    }
                    isSubcontract={isSubcontract}
                    isCanView={!isCanView}
                    reloadData={reloadData}
                    reloadContract={reloadContract}
                    isDisabledTaskDrawer={isDisabledTaskDrawer}
                    onClick={() =>
                      setSelected(
                        tasks.find(task => task.id === row.id) || null
                      )
                    }
                    onToggle={() => handleRowToggle(row.id.toString())}
                    isOpen={isOpen}
                    hasButtonToggle={hasChildren(fullRow as TaskRowData)}
                    isMainCustomer={isUserMainCustomer}
                  />
                  {isOpen &&
                    (fullRow as TaskRowData).children &&
                    renderChildren((fullRow as TaskRowData).children || [], 3)}
                </>
              )}
            </React.Fragment>
          );
        })}
      </TableBody>
    </StyledTable>
  );
}

export { TasksTable };
