import { useCallback, useEffect, useState } from 'react';
import keyBy from 'lodash/keyBy';
import { Claim, ClaimItem, ClaimType } from '../../../common/api';

export const useTaskClaimItems = (claim: Claim | undefined) => {
  const [claimItemIndex, setClaimItemIndex] = useState<
    Record<string, ClaimItem>
  >({});

  const claimItemIndexArr = Object.values(claimItemIndex);
  const isError = claimItemIndexArr.some(value => value.isError);

  useEffect(() => {
    // Create an index of {taskId: ClaimItem}
    setClaimItemIndex(
      keyBy<ClaimItem>(
        claim?.claimItems,
        (claimItem: ClaimItem) => claimItem.taskId
      )
    );
  }, [claim?.claimItems]);

  const onClaimItemChange = useCallback(
    (
      id: string,
      newValue: number,
      newClaimType: ClaimType,
      isError: boolean
    ) => {
      if (isNaN(newValue)) return;

      setClaimItemIndex(prev => ({
        ...prev,
        [id]: {
          ...prev[id],
          value: newValue,
          claimType: newClaimType,
          isError,
        },
      }));
    },
    [setClaimItemIndex]
  );

  return {
    claimItemIndex,
    setClaimItemIndex,
    onClaimItemChange,
    isError,
  };
};
