import { axios } from './axios';
import { CreateProjectPayload, Project, UpdateProjectPayload } from './types';

async function listProjects(): Promise<Project[]> {
  const resp = await axios.get(`/projects`);
  return resp.data;
}

async function listProjectsForUserType(): Promise<Project[]> {
  const resp = await axios.get(`/user-projects`);
  return resp.data;
}

async function getProject(projectId: string): Promise<Project> {
  const resp = await axios.get(`/projects/${projectId}`);
  return resp.data;
}

async function updateProject(payload: UpdateProjectPayload): Promise<Project> {
  const { id, systemDate } = payload;

  const resp = await axios.patch(`/projects/${id}`, {
    systemDate,
  });
  return resp.data;
}
async function createProject(payload: CreateProjectPayload): Promise<Project> {
  const resp = await axios.post(`/projects`, payload);
  return resp.data;
}

export {
  listProjects,
  listProjectsForUserType,
  getProject,
  createProject,
  updateProject,
};
