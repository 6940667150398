import { HeadCell } from '../../../../../components/sorted-table-head/SortedTable.hooks';
import {
  Claim,
  ClaimItem,
  ClaimStatus,
  ClaimType,
  DOLLAR,
  PreviousClaimSnapshotData,
  Task,
  ValueType,
} from '../../../../../common/api';
import { getCostToComplete } from '../../../../contract-page/views/contract-claim-view/components/CurrentClaimTab.utils';

const headCells: Array<HeadCell> = [
  {
    id: 'identifier',
    numeric: false,
    disablePadding: false,
    label: 'ID',
  },
  {
    id: 'description',
    numeric: false,
    disablePadding: false,
    label: 'TaskName',
  },
  {
    id: 'taskValue',
    numeric: true,
    disablePadding: false,
    label: 'Total Task Value',
  },
  {
    id: 'previouslyApproved',
    numeric: true,
    disablePadding: false,
    label: 'Previously Approved',
  },
  {
    id: 'progress',
    numeric: true,
    disablePadding: false,
    label: 'Progress',
  },
  {
    id: 'claimed',
    numeric: true,
    disablePadding: false,
    label: 'Value Claimed',
  },
  {
    id: 'adjust',
    numeric: true,
    disablePadding: false,
    label: '',
    disable: true,
  },
];

export type ClaimItemRow = {
  id: string;
  claimType: ClaimType;
  value: number;
  claimed: number;
  taskId: string;
  taskValue: number;
  approved: number;
  paid: number;
  progress: number;
  valueType: ValueType;
  description: string;
  previouslyApproved: number;
  identifier: number;
  status: ClaimStatus;
};

function createClaimTableRow(payload: {
  claimItem: ClaimItem;
  tasks?: Array<Task>;
  previousClaimSnapshot: PreviousClaimSnapshotData;
  claim: Claim;
}) {
  const { claimItem, tasks, previousClaimSnapshot, claim } = payload;
  const task = tasks?.find(task => task.id === claimItem.taskId);
  const previousSnapshotValues = task ? previousClaimSnapshot[task?.id] : null;
  const approved =
    claimItem.claimType === 'Percentage'
      ? Number(task?.approved) -
        Number(
          claim.status === ClaimStatus.Approved
            ? (claimItem.value * Number(claimItem.task?.value || 0)) / 100
            : 0
        )
      : Number(task?.approved) -
          Number(claim.status === ClaimStatus.Approved ? claimItem.value : 0) ||
        0;

  const approvedCalc = approved > 0 ? approved : 0;

  const claimed =
    claimItem.claimType === ClaimType.value
      ? Number(claimItem?.adjust?.value || claimItem.value)
      : (Number(claimItem?.adjust?.value || claimItem.value) / 100) *
        Number(task?.value || 0);

  const costToComplete = task
    ? getCostToComplete(
        task.value,
        previousSnapshotValues?.paid || 0,
        approvedCalc,
        claimed
      )
    : 0;

  const costToCompletePercentsValue = Math.floor(
    (costToComplete / Number(task?.value) || 0) * 100
  );

  return {
    id: claimItem.id,
    claimType: claimItem.claimType,
    value: claimItem.value,
    claimed,
    taskId: task?.id || '',
    taskValue: Number(task?.value || 0),
    approved: approvedCalc,
    paid: previousSnapshotValues?.paid || 0,
    progress: costToCompletePercentsValue,
    valueType: task?.valueType || DOLLAR,
    description: task?.description || '',
    previouslyApproved: approvedCalc + (previousSnapshotValues?.paid || 0),
    identifier: Number(task?.identifier || 0),
    status: claim.status,
  };
}

export { headCells, createClaimTableRow };
