import React from 'react';
import { ContractStatus } from '../../../common/api';
import { ChipAlert, ChipOk, ChipReview } from '../../../styles/chips';

interface Props {
  status: ContractStatus;
  size?: 'small' | 'medium' | 'large';
}

export function ContractStatusChip({ status, size = 'large' }: Props) {
  switch (status) {
    case ContractStatus.Active:
      return <ChipOk chipSize={size} label="Active" />;
    case ContractStatus.AwaitingAcceptance:
      return <ChipAlert chipSize={size} label="Awaiting Acceptance" />;
    case ContractStatus.DraftInviteSent:
      return <ChipAlert chipSize={size} label="Draft- Invite Sent" />;
    default:
      return <ChipReview chipSize={size} label={status} />;
  }
}
