import { axios } from './axios';
import {
  CreateTaskPayload,
  DeleteTasksPayload,
  ResponseListBaseType,
  SummaryTaskData,
  Task,
  TaskMoveToFolderPayload,
  UpdateTaskPayload,
} from './types';

async function listTasks(
  contractId: string,
  options: {
    parentTaskId?: string;
    taskGroupId?: string | null;
    tags?: Array<string>;
    take?: number;
    skip?: number;
  } = {}
): Promise<ResponseListBaseType<Array<Task>>> {
  const { skip, take, parentTaskId, taskGroupId, tags } = options;
  const resp = await axios.get(`/contracts/${contractId}/tasks`, {
    params: {
      skip,
      take,
      tagsList: tags?.join(','),
      taskGroupId,
      parentTaskId,
    },
  });
  return resp.data;
}

async function createTask(payload: CreateTaskPayload): Promise<Task> {
  const resp = await axios.post(
    `/contract/${payload.contractId}/tasks`,
    payload
  );
  return resp.data;
}

async function updateTask(
  contractId: string,
  taskId: string,
  payload: UpdateTaskPayload
): Promise<Task> {
  const resp = await axios.patch(`/contracts/${contractId}/tasks/${taskId}`, {
    payload,
  });
  return resp.data;
}

async function moveTaskToFolder(
  payload: TaskMoveToFolderPayload
): Promise<{ count: number }> {
  const res = await axios.patch(`/contracts/${payload.contractId}/tasks/move`, {
    taskIds: payload.taskIds,
    groupId: payload.groupId,
  });

  return res.data;
}

async function summaryTask(contractId: string): Promise<SummaryTaskData> {
  const res = await axios.get(`/contracts/${contractId}/tasks/summary`);

  return res.data;
}

async function deleteTasks(
  payload: DeleteTasksPayload
): Promise<{ count: number }> {
  const { taskIds, contractId } = payload;

  const res = await axios.delete(`/contracts/${contractId}/tasks`, {
    params: {
      taskIds: taskIds?.join(','),
    },
  });

  return res.data;
}

export {
  createTask,
  deleteTasks,
  listTasks,
  moveTaskToFolder,
  summaryTask,
  updateTask,
};
