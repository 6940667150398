import { axios } from './axios';
import {
  Claim,
  ClaimFile,
  ClaimMessage,
  ClaimMessageStatus,
  FileDownloadResponse,
  ResponseListBaseType,
  SaveCurrentClaimPayload,
  SaveSubmitClaimPayload,
} from './types';

async function getClaim(claimId: string): Promise<Claim> {
  const resp = await axios.get(`/claims/${claimId}`);
  return resp.data;
}

async function claimList(
  contractId: string,
  take?: number,
  skip?: number
): Promise<ResponseListBaseType<Array<Claim>>> {
  const resp = await axios.get(`/contracts/${contractId}/claims`, {
    params: {
      take,
      skip,
    },
  });
  return resp.data;
}

async function approvalList(
  contractId: string,
  take?: number,
  skip?: number
): Promise<ResponseListBaseType<Array<Claim>>> {
  const resp = await axios.get(`/contracts/${contractId}/claims/approval`, {
    params: {
      take,
      skip,
    },
  });
  return resp.data;
}

async function subContractorClaims(
  contractId: string,
  take?: number,
  skip?: number
): Promise<ResponseListBaseType<Array<Claim>>> {
  const resp = await axios.get(
    `/contracts/${contractId}/claims/sub-contractor`,
    {
      params: {
        take,
        skip,
      },
    }
  );
  return resp.data;
}

async function getDraftClaim(
  contractId: string,
  startDate?: string
): Promise<Claim> {
  const resp = await axios.get(`/contracts/${contractId}/claims/draft`, {
    params: {
      endDate: startDate,
    },
  });
  return resp.data;
}

async function saveClaim(
  contractId: string,
  claimId: string,
  payload: SaveSubmitClaimPayload
): Promise<Claim> {
  if (claimId) {
    if (typeof payload.submitWhenDue === 'boolean') {
      const resp = await axios.patch(
        `/contracts/${contractId}/claims/${claimId}/schedule`,
        payload
      );
      return resp.data;
    }
    const resp = await axios.patch(
      `/contracts/${contractId}/claims/${claimId}`,
      payload
    );
    return resp.data;
  }
  const resp = await axios.post(`/contracts/${contractId}/claims`, payload);
  return resp.data;
}

async function deleteClaim(contractId: string, claimId: string) {
  await axios.delete(`/contracts/${contractId}/claims/${claimId}`);
}

async function saveToCurrentClaim(
  contractId: string,
  payload: SaveCurrentClaimPayload
): Promise<Claim> {
  const resp = await axios.patch(
    `/contracts/${contractId}/currentclaim`,
    payload
  );
  return resp.data;
}

async function createClaimMessage(
  claimId: string,
  message: string
): Promise<ClaimMessage> {
  const resp = await axios.post(`claim/${claimId}/message`, { message });
  return resp.data;
}

async function deleteClaimMessage(claimId: string, messageId: string) {
  await axios.delete(`claim/${claimId}/message/${messageId}`);
}

async function updateClaimMessage(
  claimId: string,
  messageId: string,
  payload: {
    status: ClaimMessageStatus;
  }
) {
  await axios.patch(`claim/${claimId}/message/${messageId}`, payload);
}

async function createClaimFile(
  claimId: string,
  payload: { fileName: string; mimetype: string; path: string }
): Promise<ClaimFile> {
  const resp = await axios.post(`claims/${claimId}/file`, {
    name: payload.fileName,
    mimetype: payload.mimetype,
    path: payload.path,
  });
  return resp.data;
}

async function deleteClaimFile(
  claimId: string,
  fileId: string
): Promise<ClaimFile> {
  const resp = await axios.delete(`claims/${claimId}/file/${fileId}`);
  return resp.data;
}

async function getClaimFile(
  claimId: string,
  fileId: string
): Promise<FileDownloadResponse> {
  const resp = await axios.get(`claims/${claimId}/file/${fileId}`);
  return resp.data;
}

export {
  getClaim,
  approvalList,
  claimList,
  saveClaim,
  getDraftClaim,
  deleteClaim,
  saveToCurrentClaim,
  createClaimMessage,
  deleteClaimMessage,
  updateClaimMessage,
  createClaimFile,
  getClaimFile,
  deleteClaimFile,
  subContractorClaims,
};
