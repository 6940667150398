import React, { useCallback } from 'react';
import {
  Box,
  Button,
  Divider,
  Drawer,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import CloseIcon from '@mui/icons-material/Close';
import { useDialogState } from '../../components/dialog/dialog.hooks';
import { FilesItem } from '../contract-page/components/FilesItem';
import { FileUploadDialog } from '../../components/file-upload/FileUploadDropzone';
import {
  createVariationFile,
  deleteVariationFile,
  getVariationFile,
} from '../../common/api/variations';
import { useAuth } from '../../common/auth-provider/AuthProvider';
import { v4 as uuidv4 } from 'uuid';
import { useProject } from '../../common/hooks/useProject';
import { ContractFile } from '../../common/api';
import { FileDrawerComplianceLoader } from '../contract-page/views/contract-claim-view/components/FileDrawerComplianceLoader';

export type LocalVariationFile = {
  id: string;
  path: string;
  name: string;
  contentType: string;
  uploadedBy?: string;
  file?: File;
};

interface Props {
  variationId?: string;
  variationFiles: Array<LocalVariationFile>;
  open: boolean;
  canUpload: boolean;
  canDelete: boolean;
  onClose: () => void;
  addFile?: (file: LocalVariationFile) => void;
  setFiles?: (file: Array<LocalVariationFile>) => void;
  setSelectedFiles?: (file: Array<ContractFile>) => void;
}

function VariationFileDrawer({
  variationId,
  variationFiles,
  open,
  canUpload = true,
  canDelete = true,
  onClose,
  addFile,
  setFiles,
  setSelectedFiles,
}: Props) {
  const { user } = useAuth();

  const {
    isVisible: isUploadVisible,
    close: closeUpload,
    open: openUpload,
  } = useDialogState();

  const { project } = useProject();

  const { mutate: mutateGetFile } = useMutation(
    (fileId: string) => getVariationFile(variationId!, fileId),
    {
      onSuccess: data => {
        const link = document.createElement('a');
        link.href = data.url;
        link.click();
        link.remove();
      },
    }
  );

  const { mutate: mutateCreateFile, isLoading: isLoadingMutateCreateFile } =
    useMutation(
      (payload: {
        file: File | ContractFile;
        path: string;
        contentType: string;
      }) =>
        createVariationFile(variationId!, {
          fileName: payload.file.name,
          id: payload.path,
          contentType: payload.contentType,
        }),
      {
        onSuccess: res => {
          variationFiles.push({
            id: res.id,
            path: res.path,
            name: res.name,
            contentType: res.mimetype,
            uploadedBy: res.uploader.email,
          });
          setFiles?.([...variationFiles]);
        },
      }
    );

  const { mutate: mutateDeleteFile } = useMutation(
    (fileId: string) => deleteVariationFile(variationId!, fileId),
    {
      onSuccess: (res, fileId) => {
        setFiles?.(variationFiles.filter(f => f.id !== fileId));
      },
    }
  );

  const handleDownloadFile = useCallback(
    async (fileId: string) => {
      if (variationId) {
        mutateGetFile(fileId);
      } else {
        const downloadFile = variationFiles.find(file => file.path === fileId);
        const url = window.URL.createObjectURL(downloadFile?.file!);
        window.open(url, '_blank');
      }
    },
    [variationFiles, variationId, mutateGetFile]
  );

  const handleDeleteFile = useCallback(
    async (fileId: string) => {
      if (variationId) {
        mutateDeleteFile(fileId);
      } else {
        setFiles?.(variationFiles.filter(f => f.path !== fileId));
      }
    },
    [variationFiles, variationId, setFiles, mutateDeleteFile]
  );

  return (
    <Drawer
      anchor={'right'}
      sx={{
        '& .MuiDrawer-paper': {
          marginTop: '0px',
          width: '380px',
          height: '100%',
        },
      }}
      open={open}
      onClose={onClose}
    >
      <Box>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            m: 1,
            color: theme => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <Divider />
        <Stack gap={2} sx={{ p: '20px' }}>
          {canUpload && (
            <>
              <FileDrawerComplianceLoader
                setSelectedFiles={setSelectedFiles}
                isLoadingMutateCreateFile={isLoadingMutateCreateFile}
                files={variationFiles}
              />
              <Button variant="outlined" sx={{ mb: 1 }} onClick={openUpload}>
                Upload file from Computer
              </Button>
            </>
          )}
          {variationFiles.length === 0 && (
            <Typography>No files to show</Typography>
          )}
          <Stack gap={2}>
            {variationFiles.map(file => (
              <FilesItem
                key={file.path}
                file={{
                  id: file.path,
                  name: file.name,
                  createdAt: new Date(project?.systemDate || new Date()),
                  uploaderEmail: file.uploadedBy || '',
                }}
                editMode={canDelete}
                onDownloadFile={handleDownloadFile}
                onDeleteFile={() => handleDeleteFile(file.id)}
              />
            ))}
          </Stack>

          {isUploadVisible && (
            <FileUploadDialog
              isLocal={!variationId}
              showLoading={isLoadingMutateCreateFile}
              onFileUpload={payload => {
                if (!variationId) {
                  addFile?.({
                    id: uuidv4(),
                    file: payload.file,
                    path: payload.path,
                    contentType: payload.contentType,
                    name: payload.file.name,
                    uploadedBy: user?.email,
                  });
                } else {
                  mutateCreateFile({
                    file: payload.file,
                    path: payload.path,
                    contentType: payload.contentType,
                  });
                }
              }}
              close={closeUpload}
            />
          )}
        </Stack>
      </Box>
    </Drawer>
  );
}

export { VariationFileDrawer };
