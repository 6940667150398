import {
  Claim,
  ClaimStatus,
  Notification,
  NotificationStatus,
  PERCENT,
  ValueType,
} from '../../../common/api';
import dayjs from 'dayjs';

export type ClaimHistoryRows = {
  responseDue: number;
  dateSubmitted: number;
  dateResponded: number;
  id: string;
  claimPeriod: number;
  periodStart: string;
  periodEnd: string;
  contractId: string;
  status: ClaimStatus;
  statusOrder: number;
  submittedAt?: string;
  totalValue: number;
  sopaExpiration: string;
  dateExpired: number;
  description: string;
  rejectedAt?: string;
  deletedAt?: string;
  valueType: ValueType;
  notificationId: string;
};

export function createClaimHistoryRows(
  claim: Claim,
  accountId?: string,
  notifications?: Array<Notification>
): ClaimHistoryRows {
  const claimNotifications = notifications?.filter(
    notification => notification?.claimId === claim.id
  );

  const notification = claimNotifications?.find(
    notification =>
      notification.claimId === claim.id &&
      notification.status === NotificationStatus.Active &&
      notification.accountId === accountId
  );

  const notificationId = notification?.id;

  return {
    id: claim.id,
    responseDue: dayjs(claim.periodEnd).unix(),
    dateSubmitted: dayjs(claim.submittedAt || 0).unix(),
    dateResponded: dayjs(claim.scheduledAt || 0).unix(),
    claimPeriod: dayjs(claim.periodStart).unix(),
    periodStart: claim.periodStart,
    periodEnd: claim.periodEnd,
    contractId: claim.contractId,
    totalValue: claim.totalValue,
    description: claim.description,
    submittedAt: claim.submittedAt || undefined,
    status: claim.status,
    statusOrder: Object.keys(ClaimStatus).indexOf(claim.status),
    sopaExpiration: claim.expiration,
    dateExpired: dayjs(claim.expiration || 0).unix(),
    rejectedAt: claim.rejectedAt || undefined,
    deletedAt: claim.deletedAt || undefined,
    valueType: claim.valueType || PERCENT,
    notificationId: notificationId || '',
  };
}
