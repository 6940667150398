import { styled, TableRow, TableRowProps } from '@mui/material';
import { VariationStatus } from '../common/api';
import { brand200, orange50, red50 } from './colours';

interface Props extends TableRowProps {
  isEditable?: boolean;
  isClosed?: number;
  rowStatus?: VariationStatus;
}

const getStatusColor = (status: VariationStatus, isClosed: number) => {
  switch (status) {
    case VariationStatus.Rejected:
    case VariationStatus.Withdrawn:
      return orange50;
    case VariationStatus.RejectAndClosed:
    case VariationStatus.WithdrawnAndClosed:
      return red50;
    case VariationStatus.Draft:
      if (isClosed) return red50;
      break;
    default:
      return 'transparent';
  }
};

const StyledTableRow = styled(TableRow, {
  shouldForwardProp: prop =>
    prop !== 'isClosed' && prop !== 'rowStatus' && prop !== 'isEditable',
})<Props>`
  background-color: ${({ rowStatus, isClosed = 0 }) =>
    rowStatus ? getStatusColor(rowStatus, isClosed) : 'transparent'};
  &:hover {
    cursor: ${({ hover, isEditable }) =>
      hover && !isEditable ? 'pointer' : 'auto'};
  }
  .Mui-error {
    margin-top: 4px;
    font-size: 10px;
  }
`;

interface TasksTableRowProps extends TableRowProps {
  isEditable?: boolean;
  status?: 'new' | 'updated' | 'deleted';
}

const getTasksRowStatus = (
  status: 'new' | 'updated' | 'deleted' | undefined
) => {
  switch (status) {
    case 'deleted':
      return red50;
    case 'new':
      return brand200;
    default:
      return 'transparent';
  }
};

const StyledTasksTableRow = styled(TableRow, {
  shouldForwardProp: prop =>
    prop !== 'status' && prop !== 'isEditable' && prop !== 'isReadOnly',
})<TasksTableRowProps>`
  background-color: ${({ status }) => getTasksRowStatus(status)};
  &:hover {
    cursor: ${({ hover, isEditable, status }) =>
      hover && !isEditable && status !== 'deleted' ? 'pointer' : 'auto'};
  }
  .Mui-error {
    margin-top: 4px;
    font-size: 10px;
  }
  td {
    color: ${({ status }) => (status === 'deleted' ? '#999' : 'inherit')};
  }
`;

export { StyledTableRow, StyledTasksTableRow };
