import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import {
  Button,
  Collapse,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Theme,
  Typography,
} from '@mui/material';
import { TaskGroupTree } from '../task-group/components/TaskGroupTree';
import { useTaskGroups } from '../task-group/TaskGroups';
import { useTaskGroupContext } from '../task-group/components/TaskGroup.context';
import { moveTaskToFolder, TaskMoveToFolderPayload } from '../../common/api';
import { useMutation } from '@tanstack/react-query';
import { InputActions } from '../../styles';
import CloseIcon from '@mui/icons-material/Close';
import { grey200 } from '../../styles/colours';
import { TaskGroupCreateDialog } from '../task-group/components/TaskGroupCreateDialog';
import { useDialogState } from '../dialog/dialog.hooks';

const TASK_GROUP_ID_FIELD = 'taskGroupId';

export interface Form {
  [TASK_GROUP_ID_FIELD]: string;
}

type Params = {
  contractId: string;
};

type Props = {
  projectId: string;
  isOpen: boolean;
  selectedTasks: Array<string>;
  onRefetchTasks: () => void;
  onClose: () => void;
};

function MoveTaskDialog(props: Props) {
  const { isOpen, projectId, onClose, onRefetchTasks, selectedTasks } = props;

  const { contractId } = useParams<Params>() as Params;

  const [activeParentId, setActiveParentId] = useState<string | undefined>();
  const [expanded, setExpanded] = useState<Array<string>>(['0']);
  const [open] = useState<boolean>(true);
  const {
    isVisible: isCreateVisible,
    open: openCreate,
    close: closeCreate,
  } = useDialogState();

  const { taskGroups: taskGroupsContext } = useTaskGroupContext();
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm<Form>({
    mode: 'onChange',
    defaultValues: {
      [TASK_GROUP_ID_FIELD]: '0',
    },
  });

  const selectedTaskGroupId = watch(TASK_GROUP_ID_FIELD);

  const { mutateAsync: mutateMoveToFolder, isLoading } = useMutation(
    (payload: TaskMoveToFolderPayload) => moveTaskToFolder(payload)
  );

  const handleCloseDialog = useCallback(() => {
    onClose();
  }, [onClose]);

  const { data, key, onReload } = useTaskGroups({
    queryId: 'move-to-folder',
    contractId,
    parentId: activeParentId,
  });

  const taskGroups = useMemo(() => data?.data || [], [data?.data]);

  const onSubmit = useCallback(
    async (form: Form) => {
      if (!projectId) {
        return;
      }

      const moveTaskData = {
        contractId,
        taskIds: selectedTasks,
        groupId: form[TASK_GROUP_ID_FIELD],
      };

      await mutateMoveToFolder(moveTaskData);

      onRefetchTasks();
    },
    [projectId, contractId, selectedTasks, mutateMoveToFolder, onRefetchTasks]
  );

  const handleClickAdd = useCallback(
    async (form: Form) => {
      await onSubmit(form);
      handleCloseDialog();
    },
    [handleCloseDialog, onSubmit]
  );

  useEffect(() => {
    register(TASK_GROUP_ID_FIELD, {
      required: true,
      value: '0',
      validate: value => {
        return !value ? 'Please select a group' : true;
      },
      shouldUnregister: true,
    });
  }, [register]);

  return (
    <Dialog open={isOpen} onClose={handleCloseDialog}>
      <DialogTitle sx={styles.DialogTitle}>
        <Typography variant={'body1'}>{'Move Task'}</Typography>
        <IconButton
          aria-label="close"
          onClick={handleCloseDialog}
          sx={styles.IconButton}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={styles.DialogContent}>
        <Collapse
          in={open}
          timeout={500}
          orientation={'horizontal'}
          style={{ minWidth: 'auto' }}
        >
          <TaskGroupTree
            key={key}
            taskGroups={taskGroupsContext}
            expanded={expanded}
            setExpanded={setExpanded}
            canEdit={false}
            selectedTaskGroup={selectedTaskGroupId}
            handleSelectedTaskGroup={id => {
              setValue(TASK_GROUP_ID_FIELD, id, {
                shouldValidate: true,
              });
            }}
            handleSetActiveParent={setActiveParentId}
          />

          {errors[TASK_GROUP_ID_FIELD] && (
            <Typography variant="body2" color={'red'}>
              Please select a group
            </Typography>
          )}
          <InputActions sx={{ gap: '8px' }}>
            <Button
              variant="outlined"
              size="small"
              type="button"
              onClick={openCreate}
            >
              Create Folder
            </Button>
            <Button
              variant="contained"
              type="submit"
              disabled={isLoading}
              onClick={handleSubmit(handleClickAdd)}
            >
              Move here
            </Button>
          </InputActions>
        </Collapse>
      </DialogContent>
      {isCreateVisible && (
        <TaskGroupCreateDialog
          addToTaskGroupId={null}
          taskGroups={taskGroups}
          contractId={contractId}
          onReload={() => onReload()}
          handleClose={() => {
            closeCreate();
          }}
        />
      )}
    </Dialog>
  );
}

const styles = {
  DialogContent: {
    width: 'max-content',
    minWidth: '250px',
    display: 'flex',
    pr: 2,
    mt: 3,
  },
  DialogTitle: {
    p: 2,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottom: `1px solid ${grey200}`,
  },
  CollapseButton: {
    backgroundColor: 'rgba(241, 245, 249, 0.25)',
    padding: 0.5,
    minWidth: '24px',
    border: `1px solid ${grey200}`,
    borderWidth: '0px 1px 0 1px',
    borderRadius: 0,
    ml: 2,
  },
  IconButton: {
    color: (theme: Theme) => theme.palette.grey[500],
  },
};

export { MoveTaskDialog };
