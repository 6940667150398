import React, { useCallback, useMemo } from 'react';
import {
  Claim,
  ClaimStatus,
  deleteClaim,
  Notification,
  saveClaim,
} from '../../../common/api';
import {
  Box,
  Button,
  ButtonGroup,
  Checkbox,
  styled,
  TableCell,
  Typography,
  TypographyProps,
} from '@mui/material';
import dayjs from 'dayjs';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import { StyledTableRow, StyledTooltip } from '../../../styles';
import {
  brand700,
  orange100,
  orange900,
  red700,
  white,
} from '../../../styles/colours';
import { formatAmountByType, formatDate } from '../../../common/format';
import { ClaimHistoryRows } from './ClaimHistory.utils';
import { ChipStatus } from '../../../components/chip-status';
import {
  formatDaysTillDate,
  projectDate,
} from '../../../common/format/formatDate';
import { useProject } from '../../../common/hooks/useProject';
import { Warning } from '@mui/icons-material';
import CircleIcon from '@mui/icons-material/Circle';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';

type Params = {
  contractId: string;
};

interface Props {
  claim: Claim;
  row: ClaimHistoryRows;
  isContractor: boolean;
  isCustomer: boolean;
  reloadClaims?: () => void;
  reloadContract?: () => void;
  handleClick: () => void;
  handleCheckbox: () => void;
  notification?: Notification;
}

function ClaimHistoryRow(props: Props) {
  const {
    claim,
    isContractor,
    row,
    reloadClaims,
    reloadContract,
    isCustomer,
    handleClick,
    handleCheckbox,
    notification,
  } = props;

  const { project } = useProject();
  const { contractId } = useParams() as Params;
  const { pathname } = useLocation();

  const sopaDate = formatDaysTillDate(
    row.sopaExpiration,
    projectDate(project?.systemDate)
  );

  const isOverdue =
    (sopaDate > 0 && claim.status !== ClaimStatus.Draft) ||
    (dayjs(claim.paymentDueAt) < projectDate(project?.systemDate) &&
      claim.status !== ClaimStatus.Paid);

  const sopaExpiration = isOverdue
    ? `${Math.abs(sopaDate)} days OVERDUE`
    : `${Math.abs(sopaDate)} days`;

  const { mutate: mutateUpdateClaim, isLoading: isLoadingUpdateClaim } =
    useMutation(
      (status: ClaimStatus) => {
        const periodStart = dayjs(claim.periodStart).format('YYYY-MM-DD');
        const periodEnd = dayjs(claim.periodEnd).format('YYYY-MM-DD');

        return saveClaim(contractId, claim.id, {
          ...claim,
          status,
          periodStart,
          periodEnd,
          claimItems: claim.claimItems.map(claimItem => ({
            ...claimItem,
          })),
        });
      },
      {
        onSuccess: () => {
          if (reloadClaims) {
            reloadClaims();
          }
        },
      }
    );

  const { mutate: mutateDeleteClaim, isLoading: isLoadingDeleteClaim } =
    useMutation(() => deleteClaim(contractId, claim.id), {
      onSuccess: () => {
        if (reloadClaims) {
          reloadClaims();
        }

        if (reloadContract) {
          reloadContract();
        }
      },
    });

  const modifiedDate = useMemo(
    () =>
      claim.status === ClaimStatus.Rejected
        ? dayjs(claim.rejectedAt).format('DD/MM/YYYY')
        : !!claim.submittedAt
        ? dayjs(claim.submittedAt).format('DD/MM/YYYY')
        : '',
    [claim.status, claim.rejectedAt, claim.submittedAt]
  );

  const modifiedDateResponded = useMemo(
    () =>
      claim.scheduledAt ? dayjs(claim.scheduledAt).format('DD/MM/YYYY') : '-',
    [claim]
  );

  const claimPeriod = `${formatDate(claim.periodStart)} - ${formatDate(
    claim.periodEnd
  )}`;

  const navigate = useNavigate();

  const handleClickClaim = useCallback(() => {
    if (claim.status === ClaimStatus.Draft) {
      return;
    }
    handleClick();
    navigate(`${pathname}/claims/${claim.id}`);
  }, [claim.status, claim.id, navigate, pathname, handleClick]);

  const handleResubmitClaim = useCallback(() => {
    mutateUpdateClaim(ClaimStatus.Submitted);
  }, [mutateUpdateClaim]);

  const handleRemoveClaim = useCallback(() => {
    mutateDeleteClaim();
  }, [mutateDeleteClaim]);

  return (
    <StyledTableRow
      hover={row.status !== ClaimStatus.Draft}
      key={row.id}
      onClick={handleClickClaim}
    >
      <TableCell sx={{ position: 'relative' }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          {row.status === ClaimStatus.Submitted ||
          (row.status === ClaimStatus.Awaiting &&
            (isContractor || isCustomer)) ? (
            <TypographyNotificationIndicator
              sx={{ width: 'max-content' }}
              noWrap
              variant={'body1'}
            >
              {row.description || '-'}
            </TypographyNotificationIndicator>
          ) : (
            <Typography noWrap variant={'body1'}>
              {row.description || '-'}
            </Typography>
          )}
          {isOverdue ? <Warning color="error" sx={{ mr: 3 }} /> : null}
          {notification && (
            <StyledTooltip
              title={notification.isRead ? 'Mark Unread' : 'Mark Read'}
              placement="top"
            >
              <Checkbox
                checked={notification.isRead}
                onClick={event => {
                  event.stopPropagation();
                  handleCheckbox();
                }}
                icon={<CircleIcon sx={styles.Icon} />}
                checkedIcon={<RadioButtonUncheckedIcon sx={styles.Icon} />}
              />
            </StyledTooltip>
          )}
        </Box>
      </TableCell>
      <TableCell>
        <Box>
          <Typography variant={'caption'}>
            <ChipStatus status={row.status} isOverdue={isOverdue} />
          </Typography>
          {row.status === ClaimStatus.Rejected &&
            isContractor &&
            !row.deletedAt && (
              <ButtonGroup style={styles.ResubmitRemoveButtons}>
                <Button
                  onClick={handleResubmitClaim}
                  disabled={isLoadingUpdateClaim || isLoadingDeleteClaim}
                >
                  Re-submit
                </Button>
                <Button
                  onClick={handleRemoveClaim}
                  disabled={isLoadingUpdateClaim || isLoadingDeleteClaim}
                >
                  Remove
                </Button>
              </ButtonGroup>
            )}
        </Box>
      </TableCell>
      <TableCell>
        <Typography variant={'body1'}>{claimPeriod}</Typography>
      </TableCell>
      <TableCell>
        <Typography variant={'body1'}>{modifiedDate}</Typography>
      </TableCell>

      {row.sopaExpiration ? (
        <TableCell>
          <Typography variant={'body1'}>{sopaExpiration}</Typography>
          <Typography variant={'caption'}>
            {formatDate(row.sopaExpiration)}
          </Typography>
        </TableCell>
      ) : (
        <TableCell>
          <Typography variant={'caption'}>-</Typography>
        </TableCell>
      )}

      <TableCell>
        <Typography variant={'body1'}>{modifiedDateResponded}</Typography>
      </TableCell>
      <TableCell>{formatAmountByType(row.totalValue, row.valueType)}</TableCell>
    </StyledTableRow>
  );
}

export { ClaimHistoryRow };

const styles: { [key: string]: React.CSSProperties } = {
  Submitted: {
    color: orange900,
    backgroundColor: orange100,
    padding: '0px 5px 0px 5px',
    borderRadius: '4px',
  },
  ResubmitRemoveButtons: {
    marginRight: '20px',
    marginLeft: '20px',
  },
  Icon: {
    color: brand700,
  },
};

const TypographyNotificationIndicator = styled((props: TypographyProps) => (
  <Typography {...props} />
))(() => ({
  width: '65px',
  paddingRight: '10px',
  '&:before': {
    content: '" "',
    backgroundColor: red700,
    height: '14px',
    width: '14px',
    borderRadius: '50%',
    position: 'absolute',
    left: '-8px',
    top: '16px',
    fontSize: '12px',
    color: white,
    padding: '0px 4px',
  },
}));
