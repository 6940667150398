import { ICheckbox } from '../main-visibility/checkboxesData';

const row1: ICheckbox[] = [
  {
    id: 1,
    label:
      'Contract listed in Subcontractors list of contract above with organisational info & description of services',
    name: 'contractListedInSubcontractorsList',
    level: 1,
  },
  {
    id: 2,
    label: 'Overall % progress complete of contract',
    name: 'overallProgressCompleteOfContract',
    level: 2,
  },
  {
    id: 3,
    label: 'Subcontractor WBS/Claim items (name only)',
    name: 'subcontractorWBSClaimItemsNameOnly',
    level: 3,
  },
  {
    id: 4,
    label: '% progress of Subcontractor WBS/Claim items',
    name: 'progressOfSubcontractorWBSClaimItems',
    level: 4,
  },
  {
    id: 5,
    label: '$Values of Subcontractor WBS/Claim items',
    name: 'valuesOfSubcontractorWBSClaimItems',
    level: 5,
  },
];

const row2: ICheckbox[] = [
  {
    id: 6,
    label: 'Contractor Claims made',
    name: 'contractorClaimsMade',
  },
];

const row3: ICheckbox[] = [
  {
    id: 7,
    label: 'Submitted',
    name: 'subSubmitted',
  },
  {
    id: 8,
    label: 'Approved',
    name: 'subApproved',
  },
  {
    id: 9,
    label: 'Rejected',
    name: 'subRejected',
  },
  {
    id: 10,
    label: 'Withdrawn',
    name: 'subWithdrawn',
  },
  {
    id: 11,
    label: 'Anticipated',
    name: 'subAnticipated',
  },
];

const row4: ICheckbox[] = [
  {
    id: 12,
    label: 'Payment schedules sent to Contractor - SOPA non-compliance Alert',
    name: 'paymentScheduleSopaAlertSentToContractor',
  },
];

const row5: ICheckbox[] = [
  {
    id: 13,
    label:
      '[Name of subcontractor +details] has not been paid for [period] by [Name of Contractor]',
    name: 'child_subcontractorNotPaid',
    level: 1,
  },
  {
    id: 14,
    label: '$value [RANGE] of Contractor`s WBS/Claim items',
    name: 'child_contractorWBSValueRange',
    level: 2,
  },
  {
    id: 15,
    label: 'Exact $Values of Contractor`s WBS/Claim items',
    name: 'child_contractorExactWBSValues',
    level: 3,
  },
];

const row6: ICheckbox[] = [
  {
    id: 16,
    label: 'Contractor compliance documents expire',
    name: 'contractorComplianceDocumentsExpire',
  },
];

/////////////////////////////////////////////////////////////////////////////////////

const row7: ICheckbox[] = [
  {
    id: 17,
    label:
      'Contract listed in Subcontractors list of contract above with organisational info & description of services',
    name: 'subContractListedInSubcontractorsList',
    level: 1,
  },
  {
    id: 18,
    label: 'Overall % progress complete of contract',
    name: 'subOverallProgressCompleteOfContract',
    level: 2,
  },
  {
    id: 19,
    label: 'Subcontractor WBS/Claim items (name only)',
    name: 'subSubcontractorWBSClaimItemsNameOnly',
    level: 3,
  },
  {
    id: 20,
    label: '% progress of Subcontractor WBS/Claim items',
    name: 'subProgressOfSubcontractorWBSClaimItems',
    level: 4,
  },
  {
    id: 21,
    label: '$Values of Subcontractor WBS/Claim items',
    name: 'subValuesOfSubcontractorWBSClaimItems',
    level: 5,
  },
];

const row8: ICheckbox[] = [
  {
    id: 22,
    label: 'Subcontractor Claims made',
    name: 'subContractorClaimsMade',
  },
];

const row9: ICheckbox[] = [
  {
    id: 23,
    label: 'Submitted',
    name: 'subSubSubmitted',
  },
  {
    id: 24,
    label: 'Approved',
    name: 'subSubApproved',
  },
  {
    id: 25,
    label: 'Rejected',
    name: 'subSubRejected',
  },
  {
    id: 26,
    label: 'Withdrawn',
    name: 'subSubWithdrawn',
  },
  {
    id: 27,
    label: 'Anticipated',
    name: 'subSubAnticipated',
  },
];

const row10: ICheckbox[] = [
  {
    id: 28,
    label:
      'Payments schedules sent to Subcontractor - SOPA non-compliance Alert',
    name: 'subPaymentScheduleSopaAlertSentToContractor',
  },
];

const row11: ICheckbox[] = [
  {
    id: 29,
    label:
      '[Name of subcontractor +details] has not been paid for [period] by [Name of Contractor]',
    name: 'subSubcontractorNotPaid',
    level: 1,
  },
  {
    id: 30,
    label: '$value [RANGE] of Contractor`s WBS/Claim items',
    name: 'subContractorWBSValueRange',
    level: 2,
  },
  {
    id: 31,
    label: 'Exact $Values of Contractor`s WBS/Claim items',
    name: 'subContractorExactWBSValues',
    level: 3,
  },
];

const row12: ICheckbox[] = [
  {
    id: 32,
    label: 'Subcontractor compliance documents expire',
    name: 'subcontractorComplianceDocumentsExpire',
  },
];

export {
  row1,
  row2,
  row3,
  row4,
  row5,
  row6,
  row7,
  row8,
  row9,
  row10,
  row11,
  row12,
};
