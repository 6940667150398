import React, { useCallback, useMemo, useState } from 'react';

import { useAuth } from '../../../../common/auth-provider/AuthProvider';
import { StyledTable } from '../../../../styles';
import {
  Avatar,
  Box,
  Button,
  Checkbox,
  IconButton,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import { SkeletonTable } from '../../../../components/skeleton-table';
import { useMutation, useQuery } from '@tanstack/react-query';
import {
  deleteUser,
  getUsersByAccountId,
  Role,
  updateRoles,
  UpdateRolesPayload,
  UserStatus,
} from '../../../../common/api';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import { useDialogState } from '../../../../components/dialog/dialog.hooks';
import { AddUserForm } from './AddUserForm';
import { ChipAlert, ChipOk } from '../../../../styles/chips';
import { useConfirmDialog } from '../../../../common/hooks/useConfirmDialog';
import { useSnackbar } from '../../../../common/hooks/useSnackbar';
import { LoadingButton } from '@mui/lab';
import { grey100 } from '../../../../styles/colours';

function MyUsersTab() {
  const { user } = useAuth();
  const { isVisible, open, close } = useDialogState();
  const { confirmAction, ConfirmDialog } = useConfirmDialog();
  const { showAlert, SnackbarComponent } = useSnackbar();

  const currentUserId = user?.id;

  const [userData, setUserData] = useState<
    Array<{
      id: string;
      firstName?: string | null;
      lastName?: string | null;
      avatarUrl?: string | null;
      email: string;
      status: UserStatus;
      roles: Array<Role>;
    }>
  >();

  const { isLoading: isAccountUsersLoading, refetch } = useQuery(
    ['account-users', user?.accountId],
    () => getUsersByAccountId(user?.accountId!),
    {
      onSuccess: res => {
        setUserData(
          res.map(user => ({
            id: user.id,
            firstName: user.firstName,
            lastName: user.lastName,
            avatarUrl: user.avatarUrl,
            email: user.email,
            status: user.status,
            roles: user.roles.map(r => r.role),
          }))
        );
      },
    }
  );

  const { mutate: mutateDeleteUser } = useMutation(
    (userId: string) => deleteUser(user?.accountId!, userId),
    {
      onSuccess: () => refetch(),
    }
  );

  const { mutate: mutateUpdateRoles, isLoading: savingUpdateRoles } =
    useMutation((payload: UpdateRolesPayload) => updateRoles(payload), {
      onSuccess: () => {
        showAlert('User roles updated', 'success');
      },
      onError: () => {
        showAlert('There was an error updating the user roles', 'error');
      },
    });

  const canEdit = useMemo(() => {
    return user?.roles.find(r => r.role === Role.OrganisationAdmin);
  }, [user]);

  const handleDelete = useCallback(
    (userId: string) => {
      mutateDeleteUser(userId);
    },
    [mutateDeleteUser]
  );

  const canUncheckRole = useCallback(
    (userId: string, role: Role) => {
      const user = userData?.find(u => u.id === userId);
      if (user) {
        if (user.roles.includes(role)) {
          const users = userData?.filter(u => u.roles.includes(role));
          return users && users?.length >= 2;
        }
      }
      return true;
    },
    [userData]
  );

  const handleRoleCheckbox = useCallback(
    (userId: string, role: Role) => {
      if (canUncheckRole(userId, role)) {
        setUserData(
          userData?.map(ud => {
            if (ud.id === userId) {
              return {
                ...ud,
                roles: ud.roles.includes(role)
                  ? ud.roles.filter(r => r !== role)
                  : [...ud.roles, role],
              };
            }
            return ud;
          })
        );
      }
    },
    [userData, setUserData, canUncheckRole]
  );

  const canDeleteUser = useCallback(
    (userId: string) => {
      const requiredRoles = [
        Role.OrganisationAdmin,
        Role.FinancialManager,
        Role.ProjectManager,
        Role.ContractsUser,
      ];

      const rolesAssigned = {
        [Role.BasicUser]: true,
        [Role.OrganisationAdmin]: false,
        [Role.FinancialManager]: false,
        [Role.ProjectManager]: false,
        [Role.ContractsUser]: false,
      };

      userData?.forEach(user => {
        if (user.id !== userId) {
          user.roles.forEach(role => {
            if (requiredRoles.includes(role)) {
              rolesAssigned[role] = true; // Mark role as assigned
            }
          });
        }
      });

      // Check if all required roles still have at least one user assigned
      return Object.values(rolesAssigned).every(isAssigned => isAssigned);
    },
    [userData]
  );

  const handleSave = useCallback(() => {
    if (user?.accountId) {
      mutateUpdateRoles({
        accountId: user?.accountId,
        roles:
          userData?.map(user => {
            return {
              userId: user.id,
              roles: user.roles,
            };
          }) || [],
      });
    }
  }, [userData, user?.accountId, mutateUpdateRoles]);

  return (
    <div>
      <SnackbarComponent />
      {isVisible && (
        <AddUserForm
          close={(refetchData: boolean) => {
            close();
            if (refetchData) {
              refetch();
            }
          }}
        />
      )}

      {!isAccountUsersLoading ? (
        <>
          <ConfirmDialog />

          <StyledTable>
            <TableHead>
              <TableRow sx={{ backgroundColor: grey100 }}>
                <TableCell>Name</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Status</TableCell>
                <TableCell sx={{ width: '80px' }}>Basic User</TableCell>
                <TableCell sx={{ width: '80px' }}>Contracts Admin</TableCell>
                <TableCell sx={{ width: '80px' }}>Project Manager</TableCell>
                <TableCell sx={{ width: '80px' }}>Financial Manager</TableCell>
                <TableCell sx={{ width: '80px' }}>
                  Organisation Manager
                </TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {userData?.map(user => (
                <TableRow key={user.id}>
                  <TableCell>
                    <Box display="flex" gap="8px" alignItems="center">
                      <Avatar
                        sx={{ width: 32, height: 32 }}
                        src={user.avatarUrl || ''}
                      >{`${user?.firstName?.charAt(0)}${user?.lastName?.charAt(
                        0
                      )}`}</Avatar>
                      <span>
                        {user.firstName} {user.lastName}
                      </span>
                    </Box>
                  </TableCell>
                  <TableCell>{user.email}</TableCell>
                  <TableCell>
                    {user.status === UserStatus.Active ? (
                      <ChipOk label="Active" />
                    ) : (
                      <ChipAlert label="Invited" />
                    )}
                  </TableCell>
                  <TableCell>
                    <Checkbox
                      sx={{
                        color: '#92b2ac',
                        '&.Mui-checked': {
                          color: '#92b2ac',
                        },
                      }}
                      checked={user.roles.includes(Role.BasicUser)}
                    />
                  </TableCell>
                  <TableCell>
                    <Checkbox
                      disabled={!canEdit}
                      checked={user.roles.includes(Role.ContractsUser)}
                      onClick={() => {
                        handleRoleCheckbox(user.id, Role.ContractsUser);
                      }}
                    />
                  </TableCell>
                  <TableCell>
                    <Checkbox
                      disabled={!canEdit}
                      checked={user.roles.includes(Role.ProjectManager)}
                      onClick={() => {
                        handleRoleCheckbox(user.id, Role.ProjectManager);
                      }}
                    />
                  </TableCell>
                  <TableCell>
                    <Checkbox
                      disabled={!canEdit}
                      checked={user.roles.includes(Role.FinancialManager)}
                      onClick={() => {
                        handleRoleCheckbox(user.id, Role.FinancialManager);
                      }}
                    />
                  </TableCell>
                  <TableCell>
                    <Checkbox
                      disabled={!canEdit}
                      checked={user.roles.includes(Role.OrganisationAdmin)}
                      onClick={() => {
                        handleRoleCheckbox(user.id, Role.OrganisationAdmin);
                      }}
                    />
                  </TableCell>

                  <TableCell style={{ width: '60px' }}>
                    <IconButton
                      disabled={
                        user.id === currentUserId ||
                        userData.length === 1 ||
                        !canEdit ||
                        !canDeleteUser(user.id)
                      }
                      onClick={async () => {
                        await confirmAction(
                          'Are you sure you want to remove this user?'
                        );
                        handleDelete(user.id);
                      }}
                    >
                      <DeleteRoundedIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </StyledTable>
          <Box display="flex" justifyContent="space-between" marginTop={2}>
            <Button variant="outlined" disabled={!canEdit} onClick={open}>
              Add User
            </Button>
            <LoadingButton
              variant="contained"
              loading={savingUpdateRoles}
              disabled={!canEdit || savingUpdateRoles}
              onClick={handleSave}
            >
              Save
            </LoadingButton>
          </Box>
        </>
      ) : (
        <SkeletonTable />
      )}
    </div>
  );
}

export { MyUsersTab };
