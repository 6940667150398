import { AuthResponse } from './types';
import { axios } from './axios';

const sendResetPassword = async (email: string): Promise<AuthResponse> => {
  const resp = await axios.post(`/reset-password`, { email });
  return resp.data;
};

const checkResetPassword = async (id: string): Promise<AuthResponse> => {
  const resp = await axios.get(`/reset-password/${id}`);

  return resp.data;
};

const updatePassword = async (
  id: string,
  password: string
): Promise<AuthResponse> => {
  const resp = await axios.post(`/reset-password/${id}`, { password });
  return resp.data;
};

export { sendResetPassword, checkResetPassword, updatePassword };
