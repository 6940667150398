import React, { useEffect, useMemo, useState } from 'react';
import debounce from 'lodash/debounce';
import { Box, Grid, TextField, Typography } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { mapsLoader } from './LocationLibraryLoader';

type PlaceType = google.maps.places.QueryAutocompletePrediction;

export type LocationPlace = {
  address: string;
  placeId: string;
};

type Props = {
  label?: string;
  placeholder?: string;
  onLocationSelected: (data: LocationPlace) => void;
};

let autocompleteService: google.maps.places.AutocompleteService | undefined =
  undefined;

function LocationSearch({ label, placeholder, onLocationSelected }: Props) {
  const [value, setValue] = useState<PlaceType | null>(null);
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState<
    google.maps.places.QueryAutocompletePrediction[]
  >([]);

  const fetch = useMemo(
    () =>
      debounce(
        (
          request: {
            input: string;
            componentRestrictions: { country: string };
          },
          callback: (results?: readonly PlaceType[]) => void
        ) => {
          autocompleteService?.getPlacePredictions(
            request,
            (predictions, status) => {
              if (status === window.google.maps.places.PlacesServiceStatus.OK) {
                callback(predictions || []);
              } else {
                console.error('AutocompleteService failed:', status);
                callback([]);
              }
            }
          );
        },
        400
      ),
    []
  );

  useEffect(() => {
    let active = true;

    async function init() {
      if (!autocompleteService) {
        const { AutocompleteService } = (await mapsLoader.importLibrary(
          'places'
        )) as google.maps.PlacesLibrary;
        autocompleteService = new AutocompleteService();
      }
    }

    init();

    if (inputValue === '') {
      setOptions([]);
      return undefined;
    }

    fetch(
      { input: inputValue, componentRestrictions: { country: 'au' } },
      (results?: readonly PlaceType[]) => {
        if (active) {
          let newOptions: PlaceType[] = [];

          if (results) {
            newOptions = [...newOptions, ...results];
          }

          setOptions(newOptions);
        }
      }
    );

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch]);

  return (
    <Box position="relative">
      <Autocomplete
        id="google-map-demo"
        sx={{ width: '100%' }}
        getOptionLabel={option =>
          typeof option === 'string' ? option : option.description
        }
        filterOptions={x => x}
        options={options}
        autoComplete
        includeInputInList
        filterSelectedOptions
        placeholder={placeholder}
        value={value}
        noOptionsText="No locations"
        onChange={(event: any, newValue: PlaceType | null) => {
          setOptions(newValue ? [newValue, ...options] : options);
          setValue(newValue);

          onLocationSelected({
            address: newValue?.description || '',
            placeId: newValue?.place_id || '',
          });
        }}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        renderInput={params => (
          <TextField {...params} label={label || 'Add a location'} fullWidth />
        )}
        renderOption={(props, option) => {
          const { ...optionProps } = props;

          const mainText = option.description;
          return (
            <li key={option.place_id} {...optionProps}>
              <Grid container sx={{ alignItems: 'center' }}>
                <Grid
                  item
                  sx={{
                    width: 'calc(100% - 44px)',
                    wordWrap: 'break-word',
                  }}
                >
                  <Typography>{mainText}</Typography>
                </Grid>
              </Grid>
            </li>
          );
        }}
      />
    </Box>
  );
}

export default LocationSearch;
