import React, { useCallback, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useMutation } from '@tanstack/react-query';
import { LoadingButton } from '@mui/lab';
import { Box, Button, TextField, Typography } from '@mui/material';

import { useSnackbar } from '../../../../common/hooks/useSnackbar';
import { useAuth } from '../../../../common/auth-provider/AuthProvider';
import { updateUser, UpdateUserPayload } from '../../../../common/api';
import { validatePassword } from '../../../../common/validate';

const PASSWORD = 'password';
const NEW_PASSWORD = 'newPassword';
const CONFIRM_PASSWORD = 'confirmPassword';

type FormData = {
  [PASSWORD]: string;
  [NEW_PASSWORD]?: string;
  [CONFIRM_PASSWORD]?: string;
};

function MyAccountPasswordForm() {
  const { user } = useAuth();
  const { showAlert, SnackbarComponent } = useSnackbar();

  const [isPasswordEdit, setIsPasswordEdit] = useState<boolean>(false);

  const { mutateAsync: mutateUpdate, isLoading } = useMutation(
    (payload: UpdateUserPayload) => updateUser(payload),
    {
      onSuccess: () => {
        reset();
        setIsPasswordEdit(false);
      },
      onError: () =>
        showAlert(
          'There was an error updating the password. Please try again later.',
          'error'
        ),
    }
  );

  const {
    control,
    formState: { errors },
    clearErrors,
    watch,
    handleSubmit,
    reset,
  } = useForm<FormData>({
    mode: 'onChange',
  });

  const onSubmit = useCallback(
    async (form: FormData) => {
      if (user?.id) {
        await mutateUpdate({
          id: user.id,
          accountId: user.accountId,
          email: user.email,
          firstName: user.firstName,
          lastName: user.lastName,
          avatarKey: user.avatarKey,
          phone: user.phone,
          passwordExisting: form[PASSWORD],
          passwordNew: form[NEW_PASSWORD],
          passwordConfirmNew: form[CONFIRM_PASSWORD],
        });
      }
    },
    [user, mutateUpdate]
  );

  return (
    <div>
      <SnackbarComponent />
      <Typography variant="body1" marginBottom={1}>
        Password
      </Typography>
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <Box>
          <Box gap={2} mb={2} sx={{ width: '100%', maxWidth: '264px' }}>
            <Controller
              name={PASSWORD}
              defaultValue=""
              control={control}
              rules={{
                required: 'Password is required',
              }}
              render={({ field }) => (
                <TextField
                  {...field}
                  disabled={!isPasswordEdit}
                  type="password"
                  label={isPasswordEdit ? 'Password' : '********'}
                  fullWidth
                  required={isPasswordEdit}
                  error={!!errors[PASSWORD]}
                  helperText={errors[PASSWORD]?.message}
                />
              )}
            />
          </Box>
          {!isPasswordEdit && (
            <Button
              variant="outlined"
              onClick={() => {
                setIsPasswordEdit(true);
              }}
            >
              Change
            </Button>
          )}
          {isPasswordEdit && (
            <Box mb={2}>
              <Box display="flex" mb={1} gap={2} sx={{ width: '100%' }}>
                <Controller
                  name={NEW_PASSWORD}
                  defaultValue=""
                  control={control}
                  rules={{
                    required: 'New Password is required',
                    validate: value => validatePassword(value),
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      type="password"
                      label="New Password"
                      fullWidth
                      required
                      error={!!errors[NEW_PASSWORD]}
                      helperText={errors[NEW_PASSWORD]?.message}
                    />
                  )}
                />
                <Controller
                  name={CONFIRM_PASSWORD}
                  defaultValue=""
                  control={control}
                  rules={{
                    required: 'Confirm Password is required',
                    validate: value =>
                      value === watch(NEW_PASSWORD) || "Passwords don't match",
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      type="password"
                      label="Confirm Password"
                      fullWidth
                      required
                      error={!!errors[CONFIRM_PASSWORD]}
                      helperText={errors[CONFIRM_PASSWORD]?.message}
                    />
                  )}
                />
              </Box>
              {isPasswordEdit && (
                <Box display="flex" gap={1} sx={{ width: '100%' }}>
                  <LoadingButton
                    type="submit"
                    variant="contained"
                    loading={isLoading}
                    sx={{ mt: 1 }}
                  >
                    Update Password
                  </LoadingButton>
                  <Button
                    variant="outlined"
                    sx={{ mt: 1 }}
                    onClick={() => {
                      setIsPasswordEdit(false);
                      clearErrors();
                    }}
                  >
                    Cancel
                  </Button>
                </Box>
              )}
            </Box>
          )}
        </Box>
      </form>
    </div>
  );
}

export { MyAccountPasswordForm };
