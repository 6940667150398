import { useMutation } from '@tanstack/react-query';
import { v4 as uuidv4 } from 'uuid';
import { useRef } from 'react';
import { FileUploadResponse } from '../../common/api';
import { getUploadPath, uploadToS3 } from '../../common/api/file';

type UploadFilePayload = {
  url: string;
  file: File;
  path: string;
  contentType: string;
};

export const useFileUpload = ({
  onFileUploadSuccess,
}: {
  onFileUploadSuccess: (
    data: FileUploadResponse,
    variables: UploadFilePayload
  ) => void;
}) => {
  const fileInputRef = useRef<HTMLInputElement>(null);

  const { mutate: mutateUploadFile, isLoading: isLoadingMutateUploadFile } =
    useMutation(
      (payload: {
        url: string;
        file: File;
        path: string;
        contentType: string;
      }) => uploadToS3(payload.url, payload.file, payload.contentType),
      {
        onSuccess: (data, variables) => {
          onFileUploadSuccess(data, variables);
        },
      }
    );

  const { mutate: mutateGetUploadPath, isLoading: isLoadingGetUploadPath } =
    useMutation(
      (file: File) =>
        getUploadPath({
          id: uuidv4(),
          contentType: file.type,
        }),
      {
        onSuccess: (data, variables) => {
          mutateUploadFile({
            url: data.uploadUrl,
            contentType: data.contentType,
            path: data.path,
            file: variables,
          });
        },
      }
    );

  return {
    fileInputRef,
    isLoading: isLoadingMutateUploadFile || isLoadingGetUploadPath,
    mutateGetUploadPath,
  };
};
