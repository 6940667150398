import { axios as localAxios } from './axios';
import { ContractFile, FileDownloadResponse } from './types';

async function getContractFile(
  contractId: string,
  fileId: string
): Promise<FileDownloadResponse> {
  const resp = await localAxios.get(`contracts/${contractId}/files/${fileId}`);
  return resp.data;
}

async function listContractFiles(
  contractId: string
): Promise<Array<ContractFile>> {
  const resp = await localAxios.get(`contracts/${contractId}/files`);
  return resp.data;
}

async function createContractFile(
  contractId: string,
  payload: {
    fileName: string;
    mimetype: string;
    path: string;
    documentName?: string;
    documentType?: string;
    documentExpiry?: string;
  }
): Promise<ContractFile> {
  const resp = await localAxios.post(`contracts/${contractId}/files`, {
    name: payload.fileName,
    mimetype: payload.mimetype,
    path: payload.path,
    documentName: payload.documentName,
    documentType: payload.documentType,
    documentExpiry: payload.documentExpiry,
  });
  return resp.data;
}

async function updateContractFile(
  contractId: string,
  payload: {
    id: string;
    documentName?: string;
    documentType: string | null;
    documentExpiry: string | null;
  }
): Promise<ContractFile> {
  const resp = await localAxios.put(
    `contracts/${contractId}/files/${payload.id}`,
    payload
  );
  return resp.data;
}

async function deleteContractFile(contractId: string, fileId: string) {
  await localAxios.delete(`contracts/${contractId}/files/${fileId}`);
}

export {
  getContractFile,
  updateContractFile,
  listContractFiles,
  createContractFile,
  deleteContractFile,
};
