import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { LoadingButton } from '@mui/lab';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { useAuth } from '../../../../common/auth-provider/AuthProvider';
import { useSnackbar } from '../../../../common/hooks/useSnackbar';
import { useFileUpload } from '../../../../components/file-upload/FileUpload';
import { allCountries } from '../../../../components/country-select/CountrySelect';
import { grey300, red700 } from '../../../../styles/colours';
import {
  Account,
  getAccount,
  Role,
  updateAccount,
  UpdateAccountPayload,
} from '../../../../common/api';
import { SkeletonTable } from '../../../../components/skeleton-table';

const BUSINESS_NAME = 'businessName';
const TRADING_NAME = 'tradingName';
const ORG_LOGO = 'orgLogo';
const ADDRESS_LINE_1 = 'addressLine1';
const ADDRESS_LINE_2 = 'addressLine2';
const POSTCODE = 'postcode';
const COUNTRY = 'country';
const STATE = 'state';
const LOCALITY = 'locality';
const PRINCIPLE_REPRESENTATIVE = 'principleRepresentative';
const PRINCIPLE_REPRESENTATIVE_EMAIL = 'principleRepresentativeEmail';
const PRINCIPLE_REPRESENTATIVE_PHONE = 'principleRepresentativePhone';
const ABN = 'abn';

type FormData = {
  [BUSINESS_NAME]: string;
  [TRADING_NAME]?: string;
  [ORG_LOGO]?: string;
  [ADDRESS_LINE_1]: string;
  [ADDRESS_LINE_2]?: string;
  [COUNTRY]: string;
  [STATE]: string;
  [LOCALITY]: string;
  [POSTCODE]: string;
  [PRINCIPLE_REPRESENTATIVE]: string;
  [PRINCIPLE_REPRESENTATIVE_EMAIL]: string;
  [PRINCIPLE_REPRESENTATIVE_PHONE]: string;
  [ABN]: string;
};

function MyDetailsTab() {
  const queryClient = useQueryClient();

  const { user } = useAuth();
  const { showAlert, SnackbarComponent } = useSnackbar();

  const canEdit = useMemo(
    () => user?.roles.find(r => r.role === Role.OrganisationAdmin),
    [user]
  );

  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const [logoFile, setLogoFile] = useState<File | null>(null);

  const accountId = user?.accountId;

  const { data, isLoading: isLoadingAccount } = useQuery<Account>(
    [accountId, 'currentAccount'],
    () => getAccount(accountId!)
  );

  const { mutateAsync: mutateUpdate, isLoading } = useMutation(
    (payload: UpdateAccountPayload) => updateAccount(payload),
    {
      onSuccess: res => {
        showAlert('Details updated.', 'success');
        queryClient.setQueryData([accountId, 'currentAccount'], () => {
          return res;
        });
      },
      onError: () =>
        showAlert(
          'There was an error updating the account details. Please try again later.',
          'error'
        ),
    }
  );

  const {
    control,
    formState: { errors },
    setValue,
    setError,
    clearErrors,
    handleSubmit,
    watch,
    reset,
  } = useForm<FormData>({
    defaultValues: {
      [BUSINESS_NAME]: data?.name,
      [STATE]: data?.state || 'NSW',
      [COUNTRY]: data?.country || 'Australia',
    },
  });

  const stateValue = watch(STATE);
  const countryValue = watch(COUNTRY);

  const onSubmit = useCallback(
    async (form: FormData) => {
      if (accountId) {
        await mutateUpdate({
          id: accountId,
          abn: form[ABN],
          address: form[ADDRESS_LINE_1],
          addressLine2: form[ADDRESS_LINE_2],
          country: form[COUNTRY],
          locality: form[LOCALITY],
          logoKey: form[ORG_LOGO],
          businessName: form[BUSINESS_NAME],
          tradingName: form[TRADING_NAME],
          postcode: form[POSTCODE],
          state: form[STATE],
          representativePhone: form[PRINCIPLE_REPRESENTATIVE_PHONE],
          representativeEmail: form[PRINCIPLE_REPRESENTATIVE_EMAIL],
          representative: form[PRINCIPLE_REPRESENTATIVE],
        });
      }
    },
    [accountId, mutateUpdate]
  );

  const { mutateGetUploadPath } = useFileUpload({
    onFileUploadSuccess: (_, variables) => {
      setValue(ORG_LOGO, variables.path);
    },
  });

  const handleFileChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const file = event?.target?.files?.[0];
      const maxSizeInBytes = 2 * 1024 * 1024; // 2MB in bytes

      if (file && file.size > maxSizeInBytes) {
        setError(ORG_LOGO, { message: 'File size cannot be larger than 2mb' });
      } else {
        if (file) {
          setLogoFile(file);
          clearErrors(ORG_LOGO);
          mutateGetUploadPath(file);
        }
      }
    },
    [setLogoFile, setError, clearErrors, mutateGetUploadPath]
  );

  useEffect(() => {
    if (data) {
      reset({
        [ABN]: data.abn || '',
        [ADDRESS_LINE_1]: data.address || '',
        [ADDRESS_LINE_2]: data.addressLine2 || '',
        [BUSINESS_NAME]: data.name,
        [COUNTRY]: data.country || '',
        [ORG_LOGO]: data.logoKey || '',
        [STATE]: data.state || '',
        [LOCALITY]: data.locality || '',
        [POSTCODE]: data.postcode || '',
        [PRINCIPLE_REPRESENTATIVE]: data.representative,
        [PRINCIPLE_REPRESENTATIVE_EMAIL]: data.email,
        [PRINCIPLE_REPRESENTATIVE_PHONE]: data.phone || '',
        [TRADING_NAME]: data.tradingName || '',
      });
    }
  }, [data, reset]);

  return isLoadingAccount ? (
    <SkeletonTable />
  ) : (
    <div>
      <SnackbarComponent />
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <Box display="flex" gap={3} mb={3}>
          <Controller
            name={BUSINESS_NAME}
            defaultValue=""
            control={control}
            rules={{
              required: 'Business name is required',
            }}
            render={({ field }) => (
              <TextField
                {...field}
                label="Business name"
                fullWidth
                required
                disabled={!canEdit}
                error={!!errors[BUSINESS_NAME]}
                helperText={errors[BUSINESS_NAME]?.message}
              />
            )}
          />
          <Controller
            name={TRADING_NAME}
            defaultValue=""
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label="Trading Name (if different to business name)"
                fullWidth
                required
                disabled={!canEdit}
                error={!!errors[TRADING_NAME]}
                helperText={errors[TRADING_NAME]?.message}
              />
            )}
          />
        </Box>

        <Box display="flex" gap={3} mb={3}>
          <Controller
            name={ADDRESS_LINE_1}
            defaultValue=""
            control={control}
            rules={{
              required: 'Address Line 1 is required',
            }}
            render={({ field }) => (
              <TextField
                {...field}
                label="Address Line 1"
                fullWidth
                required
                disabled={!canEdit}
                error={!!errors[ADDRESS_LINE_1]}
                helperText={errors[ADDRESS_LINE_1]?.message}
              />
            )}
          />
          <Controller
            name={ADDRESS_LINE_2}
            defaultValue=""
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label="Address Line 2"
                fullWidth
                required
                disabled={!canEdit}
                error={!!errors[ADDRESS_LINE_2]}
                helperText={errors[ADDRESS_LINE_2]?.message}
              />
            )}
          />
        </Box>

        <Box display="flex" gap={3} mb={3}>
          <Controller
            name={LOCALITY}
            defaultValue=""
            control={control}
            rules={{
              required: 'City is required',
            }}
            render={({ field }) => (
              <TextField
                {...field}
                label="City"
                fullWidth
                required
                disabled={!canEdit}
                error={!!errors[LOCALITY]}
                helperText={errors[LOCALITY]?.message}
              />
            )}
          />
          <Controller
            name={POSTCODE}
            defaultValue=""
            control={control}
            rules={{
              required: 'Postcode is required',
            }}
            render={({ field }) => (
              <TextField
                {...field}
                label="Postcode"
                fullWidth
                required
                disabled={!canEdit}
                error={!!errors[POSTCODE]}
                helperText={errors[POSTCODE]?.message}
              />
            )}
          />

          <FormControl sx={{ flexBasis: '100%' }}>
            <InputLabel id="state">State</InputLabel>
            <Controller
              name={STATE}
              control={control}
              rules={{
                required: 'State is required',
              }}
              render={({ field }) => (
                <Select
                  {...field}
                  labelId="state"
                  label="State"
                  fullWidth
                  required
                  disabled={!canEdit}
                  value={stateValue}
                  error={!!errors[STATE]}
                >
                  <MenuItem value="ACT">Australian Capital Territory</MenuItem>
                  <MenuItem value="NSW">New South Wales</MenuItem>
                  <MenuItem value="NT">Northern Territory</MenuItem>
                  <MenuItem value="QLD">Queensland</MenuItem>
                  <MenuItem value="SA">South Australia</MenuItem>
                  <MenuItem value="TAS">Tasmania</MenuItem>
                  <MenuItem value="VIC">Victoria</MenuItem>
                  <MenuItem value="WA">Western Australia</MenuItem>
                </Select>
              )}
            />
          </FormControl>

          <FormControl sx={{ flexBasis: '100%' }}>
            <InputLabel id="country">Country</InputLabel>
            <Controller
              name={COUNTRY}
              control={control}
              rules={{
                required: 'Country is required',
              }}
              render={({ field }) => (
                <Select
                  {...field}
                  labelId="country"
                  label="Country"
                  fullWidth
                  required
                  disabled={!canEdit}
                  value={countryValue}
                  error={!!errors[COUNTRY]}
                >
                  {allCountries.map(c => (
                    <MenuItem key={c.name} value={c.name}>
                      {c.name}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
          </FormControl>
        </Box>

        <Box display="flex" gap={3} mb={3}>
          <Controller
            name={PRINCIPLE_REPRESENTATIVE}
            defaultValue=""
            control={control}
            rules={{
              required: 'Principle Representative is required',
            }}
            render={({ field }) => (
              <TextField
                {...field}
                label="Principle Representative "
                fullWidth
                required
                disabled={!canEdit}
                error={!!errors[PRINCIPLE_REPRESENTATIVE]}
                helperText={errors[PRINCIPLE_REPRESENTATIVE]?.message}
              />
            )}
          />
          <Controller
            name={PRINCIPLE_REPRESENTATIVE_EMAIL}
            defaultValue=""
            control={control}
            rules={{
              required: 'Principle Representative Email is required',
            }}
            render={({ field }) => (
              <TextField
                {...field}
                label="Principle Representative Email"
                fullWidth
                required
                disabled={!canEdit}
                error={!!errors[PRINCIPLE_REPRESENTATIVE_EMAIL]}
                helperText={errors[PRINCIPLE_REPRESENTATIVE_EMAIL]?.message}
              />
            )}
          />
          <Controller
            name={PRINCIPLE_REPRESENTATIVE_PHONE}
            defaultValue=""
            control={control}
            rules={{
              required: 'Principle Representative Phone is required',
            }}
            render={({ field }) => (
              <TextField
                {...field}
                label="Principle Representative Phone"
                fullWidth
                required
                disabled={!canEdit}
                error={!!errors[PRINCIPLE_REPRESENTATIVE_PHONE]}
                helperText={errors[PRINCIPLE_REPRESENTATIVE_PHONE]?.message}
              />
            )}
          />
          <Controller
            name={ABN}
            defaultValue=""
            control={control}
            rules={{
              required: 'ABN is required',
            }}
            render={({ field }) => (
              <TextField
                {...field}
                label="ABN"
                fullWidth
                required
                disabled={!canEdit}
                error={!!errors[ABN]}
                helperText={errors[ABN]?.message}
              />
            )}
          />
        </Box>
        <Box mb={4}>
          <Typography marginBottom={'4px'}>Organisation Logo</Typography>
          <Box display="flex" gap={1} alignItems="flex-start">
            <Box
              sx={{
                width: '100px',
                height: '100px',
                border: `1px solid ${grey300}`,
                objectFit: 'cover',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              {(logoFile || data?.logoUrl) && (
                <img
                  width={100}
                  alt=""
                  src={
                    logoFile
                      ? URL.createObjectURL(logoFile)
                      : data?.logoUrl
                      ? data.logoUrl || ''
                      : ''
                  }
                />
              )}
            </Box>
            {canEdit && (
              <Button
                variant="outlined"
                onClick={() => fileInputRef.current?.click()}
              >
                Upload
              </Button>
            )}
          </Box>
          <input
            type="file"
            ref={fileInputRef}
            style={{ display: 'none' }} // Hide the file input element
            onChange={handleFileChange}
          />
          {errors[ORG_LOGO]?.message && (
            <Typography variant="body2" color={red700}>
              {errors[ORG_LOGO]?.message}
            </Typography>
          )}
        </Box>

        <Box>
          <LoadingButton
            variant="contained"
            type="submit"
            loading={isLoading}
            disabled={!canEdit}
          >
            Save
          </LoadingButton>
        </Box>
      </form>
    </div>
  );
}

export { MyDetailsTab };
