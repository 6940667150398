import React, { ReactNode, useEffect, useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { XYCoord, Identifier } from 'dnd-core';
import { getEmptyImage } from 'react-dnd-html5-backend';
import { useTaskGroupUpdate } from '../TaskGroups.hooks';
import { getLastOrderValue } from '../TaskGroup.utils';
import { ReactComponent as IconDrag } from '../../../common/assets/icon_drag.svg';
import { useTaskGroupContext } from './TaskGroup.context';
import { DragItem } from './types';
import * as Styled from './TreeDraggable.style';
import { getReorderValue } from '../TaskGroup.utils';
import { TaskGroup } from '../../../common/api';
import { sortBy } from 'lodash';
import { TREE_IDENTIFIER } from './TaskGroups.constants';

type Props = {
  id: string;
  parentId: string | null;
  taskGroup: TaskGroup;
  children: ReactNode;
  index: number;
  onReload?: () => void;
};

export const TreeDraggable = ({
  children,
  id,
  parentId,
  index,
  taskGroup,
  onReload,
}: Props) => {
  const containerRef = useRef<HTMLDivElement | null>(null);

  const { mutate } = useTaskGroupUpdate();
  const { taskGroups, originalTaskGroups, setTaskGroups } =
    useTaskGroupContext();

  const [{ isOver, canDrop }, drop] = useDrop<
    DragItem,
    void,
    { handlerId: Identifier | null; isOver: boolean; canDrop: boolean }
  >({
    accept: TREE_IDENTIFIER,
    collect: monitor => ({
      handlerId: monitor.getHandlerId(),
      isOver: monitor.isOver({ shallow: true }),
      canDrop: monitor.canDrop(),
    }),
    hover: (dragItem: DragItem, monitor) => {
      if (
        dragItem.el?.contains(containerRef.current) ||
        dragItem.parentId !== parentId
      ) {
        // prevent reordering items not in same parentId
        return false;
      }

      const dragIndex = dragItem.index;
      const hoverIndex = index;

      if (dragIndex === hoverIndex) {
        return;
      }

      const hoverBoundingRect = containerRef.current?.getBoundingClientRect();
      if (hoverBoundingRect) {
        const hoverMiddleY =
          (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
        const clientOffset = monitor.getClientOffset();
        const hoverClientY =
          (clientOffset as XYCoord).y - hoverBoundingRect.top;

        if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
          return;
        }

        if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
          return;
        }

        const items = taskGroups.filter(
          tg => tg.parentId === dragItem.parentId
        );
        const sortedItems = sortBy(items, 'order');

        const moving = sortedItems[dragIndex];
        const hover = sortedItems[hoverIndex];

        const isMovingDown = dragIndex < hoverIndex;
        if (isMovingDown && moving.order > hover.order) {
          return;
        }

        const isMovingUp = dragIndex > hoverIndex;
        if (isMovingUp && hover.order > moving.order) {
          return;
        }

        if (isMovingDown) {
          const newOrder = getReorderValue(
            taskGroups,
            hover.id,
            hover.parentId
          );
          moving.order = newOrder;
          dragItem.order = newOrder;
          dragItem.index = hoverIndex;
          setTaskGroups(() => taskGroups.map(tg => tg));
        } else {
          const newOrder = getReorderValue(
            taskGroups,
            hover.id,
            hover.parentId,
            'up'
          );
          moving.order = newOrder;
          dragItem.order = newOrder;
          dragItem.index = hoverIndex;
          setTaskGroups(() => taskGroups.map(tg => tg));
        }
      }
    },
    canDrop: (item: DragItem) => {
      if (
        (item.el !== containerRef.current &&
          item.el?.contains(containerRef.current)) ||
        item.parentId === id
      ) {
        return false;
      }
      return true;
    },
    drop(dragItem: DragItem) {
      if (dragItem.id === id) {
        const newOrder = taskGroups.find(tg => tg.id === id)?.order || 'V';
        mutate({
          id: dragItem.id,
          parentId: dragItem.parentId,
          order: newOrder,
        }).then(onReload);
      } else {
        const order = getLastOrderValue(taskGroups, id);
        const item = taskGroups.find(tg => tg.id === dragItem.id);
        if (item) {
          item.order = order;
          item.parentId = id;
        }
        setTaskGroups(() => [...taskGroups]);
        mutate({
          id: dragItem.id,
          parentId: id,
          order,
        }).then(onReload);
      }
    },
  });

  const [{ isDragging }, drag, preview] = useDrag(() => ({
    type: 'tree',
    item: () => {
      return {
        el: containerRef.current,
        id,
        parentId,
        order: taskGroup.order,
        index,
      };
    },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
    end: (item, monitor) => {
      const didDrop = monitor.didDrop();
      if (!didDrop) {
        const taskGroupItem = taskGroups.find(tg => tg.id === item.id);
        const originalGroupItem = originalTaskGroups.find(
          tg => tg.id === item.id
        );
        if (taskGroupItem && originalGroupItem) {
          item.order = originalGroupItem.order;
          taskGroupItem.order = originalGroupItem.order;
        }
        setTaskGroups(() => [...taskGroups]);
      }
    },
  }));

  useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: true });
    //eslint-disable-next-line
  }, []);

  drop(containerRef);

  return (
    <Styled.TreeRow ref={containerRef} id={id}>
      <Styled.TreeDraggable
        id={`tree-draggable-${id}`}
        tabIndex={0}
        isOver={isOver}
        canDrop={canDrop}
        isDragging={isDragging}
      >
        <Styled.TreeDraggableHandle ref={drag}>
          <IconDrag />
        </Styled.TreeDraggableHandle>
        <Styled.TreeDraggableContent>{children}</Styled.TreeDraggableContent>
      </Styled.TreeDraggable>
    </Styled.TreeRow>
  );
};
