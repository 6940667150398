import React, { MouseEvent, useCallback, useState } from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { KebabIcon, KebabMenuIconButton } from './KebabMenu.style';
import { Button } from '@mui/material';

type Props = {
  options: Array<string>;
  size?: 'small' | 'medium' | 'large';
  orientation?: 'horizontal' | 'vertical';
  prefixLabel?: string;
  onOptionSelect: (option: string) => void;
};

function KebabMenu({
  options,
  size = 'small',
  orientation = 'horizontal',
  prefixLabel,
  onOptionSelect,
}: Props) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = useCallback(
    (event: MouseEvent<HTMLElement>) => {
      event.preventDefault();
      event.stopPropagation();
      setAnchorEl(event.currentTarget);
    },
    [setAnchorEl]
  );

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, [setAnchorEl]);

  const handleMenuItemClick = useCallback(
    (event: MouseEvent<HTMLElement>, index: number) => {
      event.preventDefault();
      event.stopPropagation();
      onOptionSelect(options[index]);
      handleClose();
    },
    [options, handleClose, onOptionSelect]
  );

  return (
    <>
      {prefixLabel ? (
        <Button
          variant="outlined"
          aria-haspopup="true"
          className="kebab-menu"
          onClick={handleClick}
        >
          <span>{prefixLabel}</span>
          <KebabIcon orientation={orientation} sx={{ fontSize: '1.15rem' }} />
        </Button>
      ) : (
        <KebabMenuIconButton
          size={size}
          aria-label="more"
          aria-haspopup="true"
          className="kebab-menu"
          onClick={handleClick}
        >
          <KebabIcon orientation={orientation} />
        </KebabMenuIconButton>
      )}

      <Menu
        id="long-menu"
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {options.map((option, index) => (
          <MenuItem
            key={option}
            onClick={event => handleMenuItemClick(event, index)}
          >
            {option}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}

export { KebabMenu };
