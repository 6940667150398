import React, { useState } from 'react';
import { AlertColor, TableBody, TableCell, TableRow } from '@mui/material';
import { createPortal } from 'react-dom';
import { useMutation, useQuery } from '@tanstack/react-query';

import { KebabMenu } from '../../../components/menu/KebabMenu';
import { useDialogState } from '../../../components/dialog/dialog.hooks';
import { useConfirmDialog } from '../../../common/hooks/useConfirmDialog';
import {
  ContractReportListData,
  deleteReportItem,
  deleteReportItemPayload,
  getContractReport,
} from '../../../common/api/reports';
import { ContractStatus, DOLLAR, Order } from '../../../common/api';
import { ReportCellKebab, ReportCellWrapper } from '../ReportCell.style';
import { Tile } from '../BasicTileItem.styles';
import EditContractListTile from './EditContractListTile';
import { LinkTo, StyledTable, TextSmall } from '../../../styles';
import { SortedTableHead } from '../../../components/sorted-table-head';
import { HeadCell } from '../../../components/sorted-table-head/SortedTable.hooks';
import { ProgressBar } from '../../../components/progress-bar';
import { MetricKey } from '../../../common/api/metric-types';
import { ContractStatusChip } from '../../../pages/contract-page/components/ContractStatusChip';
import { sortReportRows } from '../Report.utils';
import { ReportItem } from '../../../common/api/report-types';

type Props = {
  reportItem: ReportItem;
  canRemove: boolean;
  isEditingDashboard: boolean;
  projectId: string;
  reload: () => void;
  showAlert: (title: string, severity: AlertColor) => void;
};

const ContractListTile = ({
  reportItem,
  canRemove,
  projectId,
  isEditingDashboard,
  reload,
  showAlert,
}: Props) => {
  const {
    isVisible: isEditVisible,
    open: openEdit,
    close: closeEdit,
  } = useDialogState();
  const { confirmAction, ConfirmDialog } = useConfirmDialog();

  const [order, setOrder] = useState<Order>('asc');
  const [orderIndex, setOrderIndex] = useState<number>(0);

  const { data: contractListData, refetch: refetchContractListData } =
    useQuery<ContractReportListData>(
      ['contractListReport', reportItem.id],
      () => {
        return getContractReport({
          reportItemId: reportItem.id,
          isDefaultReport: false,
          projectId,
        });
      }
    );

  const { mutateAsync: mutatedeleteReportItem } = useMutation(
    (payload: deleteReportItemPayload) => deleteReportItem(payload),
    {
      onSuccess: () => {
        reload();
        showAlert('Tile removed!', 'success');
      },
      onError: () =>
        showAlert(
          'There was an error deleting the tile. Please try again later.',
          'error'
        ),
    }
  );

  const el = document.getElementById('report-actions');

  const id = reportItem.id;
  const reportId = reportItem.reportId;

  const headCells: Array<HeadCell> =
    contractListData?.data.headers?.map((h, index) => ({
      id: '' + index,
      numeric: true,
      disablePadding: false,
      label: h,
    })) || [];

  const rows = contractListData?.data.values;

  return (
    <Tile>
      {el &&
        createPortal(
          <>
            {isEditVisible && (
              <EditContractListTile
                id={id}
                isDefaultDashboard={false}
                reportId={reportId}
                reportItem={reportItem}
                projectId={projectId}
                close={closeEdit}
                reload={() => {
                  reload();
                  refetchContractListData();
                }}
                showAlert={showAlert}
              />
            )}
          </>,
          el
        )}

      <ConfirmDialog />
      {!isEditingDashboard && (
        <ReportCellKebab>
          <KebabMenu
            options={
              canRemove ? ['Tile Options', 'Remove Tile'] : ['Tile Options']
            }
            onOptionSelect={async option => {
              if (option === 'Remove Tile') {
                await confirmAction(
                  'Are you sure you want to remove this tile?'
                );
                await mutatedeleteReportItem({
                  reportId,
                  id,
                });
              } else if (option === 'Tile Options') {
                openEdit();
              }
            }}
          />
        </ReportCellKebab>
      )}

      <ReportCellWrapper>
        <StyledTable>
          <SortedTableHead
            order={order}
            orderBy={orderIndex + ''}
            onRequestSort={(e, prop) => {
              setOrder(order === 'asc' ? 'desc' : 'asc');
              setOrderIndex(Number(prop));
            }}
            headCells={headCells}
          />
          {rows && contractListData?.data.values.length > 0 && (
            <TableBody>
              {sortReportRows(
                contractListData.data.values,
                orderIndex,
                'value',
                order
              ).map((values, index) => (
                <TableRow key={index}>
                  {values.map((v, i) => (
                    <TableCell key={i}>
                      {v.metricKey === MetricKey.ContractName ? (
                        <>
                          <LinkTo to={`/contracts/${v.id}`}>{v.value}</LinkTo>
                          <TextSmall>{v.accountName}</TextSmall>
                        </>
                      ) : v.metricKey === MetricKey.Status ? (
                        <ContractStatusChip
                          size="medium"
                          status={v.value as ContractStatus}
                        />
                      ) : v.metricKey === MetricKey.ProgessBar ? (
                        <ProgressBar
                          total={v.progress?.total || 0}
                          claimed={v.progress?.claimed || 0}
                          approved={v.progress?.approved || 0}
                          paid={v.progress?.paid || 0}
                          approvedVariation={v.progress?.approvedVariation || 0}
                          unApprovedVariation={
                            v.progress?.unapprovedVariation || 0
                          }
                          valueType={DOLLAR}
                        />
                      ) : (
                        v.value
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          )}
        </StyledTable>
      </ReportCellWrapper>
    </Tile>
  );
};

export default ContractListTile;
