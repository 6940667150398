import styled from '@emotion/styled';
import { Box } from '@mui/material';

import { grey300 } from '../../styles/colours';

export const TileButton = styled(Box)`
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  opacity: 0;
  z-index: 1;
  transition: opacity 200ms ease;
  &:hover {
    opacity: 1;
  }
`;

export const Tile = styled('div')`
  border: 1px solid ${grey300};
  border-radius: 8px;
  overflow: hidden;
  position: relative;
`;

export const TileImage = styled(Box)`
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  padding-top: 65%;
`;
