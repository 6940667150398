import React, { ReactNode } from 'react';
import { Stack, SxProps, Theme } from '@mui/material';

export function PageHeader({
  actions,
  title,
  sx = { mt: 2, mb: 3 },
}: {
  title: ReactNode;
  actions: ReactNode;
  sx?: SxProps<Theme>;
}) {
  return (
    <Stack gap={1} sx={sx}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Stack direction="row" gap={2} alignItems="center">
          {title}
        </Stack>
        <Stack direction="row" gap={'16px'}>
          {actions}
        </Stack>
      </Stack>
    </Stack>
  );
}
