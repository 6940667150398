import React, { useCallback, useState } from 'react';

import { useMutation } from '@tanstack/react-query';
import { AlertColor, Box, Button, Dialog, DialogContent } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Divider from '@mui/material/Divider';

import { Heading, InputActions } from '../../../styles';
import {
  updateReportItem,
  UpdateReportItemPayload,
} from '../../../common/api/reports';
import { FileUploadDropzone } from '../../../components/file-upload/FileUploadDropzone';
import { useFileUpload } from '../../../components/file-upload/FileUpload';

type Props = {
  id: string;
  reportId: string;
  reload: () => void;
  close: () => void;
  showAlert: (title: string, severity: AlertColor) => void;
};

function EditPictureTile({ id, reportId, close, reload, showAlert }: Props) {
  const [files, setFiles] = useState<Array<File>>([]);

  const { mutateAsync: mutateUpdate, isLoading } = useMutation(
    (payload: UpdateReportItemPayload) => updateReportItem(payload),
    {
      onSuccess: () => {
        reload();
        close();
      },
      onError: () =>
        showAlert(
          'There was an error updating the tile. Please try again later.',
          'error'
        ),
    }
  );

  const { isLoading: isUploadFileLoading, mutateGetUploadPath } = useFileUpload(
    {
      onFileUploadSuccess: (data, variables) => {
        close();
        mutateUpdate({
          reportId,
          id,
          updatedReportItem: {
            id,
            reportId,
            heading: null,
            subHeading: null,
            imageKey: variables.path,
            richtext: null,
            metricGroups: [],
            newMetricGroups: [],
          },
        });
      },
    }
  );

  const onSubmit = useCallback(() => {
    if (files[0]) {
      mutateGetUploadPath(files[0]);
    }
  }, [files, mutateGetUploadPath]);

  return (
    <Dialog open fullWidth maxWidth="xs" onClose={close}>
      <DialogContent>
        <Heading style={{ marginBottom: '8px' }}>Picture Tile</Heading>

        <Divider sx={{ mb: 2 }} />

        {files.length > 0 ? (
          <Box
            sx={{ height: '300px', display: 'flex', justifyContent: 'center' }}
          >
            <img src={URL.createObjectURL(files[0])} height="300px" alt="" />
          </Box>
        ) : (
          <FileUploadDropzone
            onDropFiles={files => {
              setFiles(files);
            }}
          />
        )}

        <InputActions>
          <Button variant="outlined" onClick={close}>
            Cancel
          </Button>
          <LoadingButton
            type="submit"
            variant="contained"
            loading={isUploadFileLoading || isLoading}
            onClick={() => onSubmit()}
          >
            Save
          </LoadingButton>
        </InputActions>
      </DialogContent>
    </Dialog>
  );
}

export default EditPictureTile;
