import {
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  FormControl,
  FormControlLabel,
  InputLabel,
  Select,
  TextField,
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import React, { useCallback, useState } from 'react';
import { Heading } from '../../styles';
import { DialogClose, InputActions } from '../../styles';
import { VariationRowItem } from './variationsPage.utils';
import { validateNumericalField } from '../bank/BankCreateDialog.util';
import MenuItem from '@mui/material/MenuItem';

type Props = {
  addVariation: (items: VariationRowItem) => void;
  handleClose: () => void;
};

const ID_FIELD = 'id';
const NAME_FIELD = 'nameField';
const TYPE_FIELD = 'type';
const UOM_FIELD = 'unitOfMeasure';
const QTY_FIELD = 'quantity';
const RATE_FIELD = 'rate';
const VALUE_FIELD = 'valueField';
const EXCLUDE_FIELD = 'excludeFromRetention';

type FormData = {
  [ID_FIELD]: string;
  [NAME_FIELD]: string;
  [TYPE_FIELD]: string;
  [UOM_FIELD]: string;
  [QTY_FIELD]: number;
  [RATE_FIELD]: number;
  [VALUE_FIELD]: string;
  [EXCLUDE_FIELD]: boolean;
};

function VariationNewTask({ addVariation, handleClose }: Props) {
  const [isShowScheduleFields, setIsShowScheduleFields] = useState(false);

  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm<FormData>();

  const onSubmit = useCallback(
    (data: FormData) => {
      addVariation({
        currentTaskApproved: 0,
        currentTaskValue: Number(data[VALUE_FIELD]),
        description: data[NAME_FIELD],
        id: '',
        taskId: '',
        isNewTask: true,
        identifier: '',
        newTaskValue: Number(data[VALUE_FIELD]),
        variance: 0,
        maxVarianceValue: 0,
        type: data[TYPE_FIELD],
        unitOfMeasure: data[UOM_FIELD],
        quantity: data[QTY_FIELD],
        rate: data[RATE_FIELD],
        excludeFromRetention: data[EXCLUDE_FIELD],
      });
      handleClose();
    },
    [handleClose, addVariation]
  );

  const handleTaskTypeChange = useCallback(
    (value: string) => {
      setValue(UOM_FIELD, '');
      setValue(QTY_FIELD, 0);
      setValue(RATE_FIELD, 0);
      setIsShowScheduleFields(value === 'scheduleOfRates');
    },
    [setValue, setIsShowScheduleFields]
  );

  return (
    <Dialog open onClose={handleClose}>
      <DialogContent sx={{ width: '440px' }}>
        <form noValidate onSubmit={handleSubmit(onSubmit)}>
          <DialogClose onClose={handleClose} />
          <Heading>Create New Task</Heading>
          <Controller
            name={ID_FIELD}
            control={control}
            defaultValue={''}
            rules={{
              required: 'Enter a Task ID',
            }}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                type="text"
                label="ID"
                sx={{ mb: '20px' }}
                error={!!errors[ID_FIELD]}
                helperText={errors[ID_FIELD]?.message?.toString()}
              />
            )}
          />
          <Controller
            name={NAME_FIELD}
            control={control}
            defaultValue={''}
            rules={{
              required: 'Enter a Task Name',
            }}
            render={({ field }) => (
              <TextField
                {...field}
                required
                fullWidth
                type="text"
                label="Task Name"
                sx={{ mb: '20px' }}
                error={!!errors[NAME_FIELD]}
                helperText={errors[NAME_FIELD]?.message?.toString()}
              />
            )}
          />
          <Controller
            name={TYPE_FIELD}
            defaultValue=""
            control={control}
            rules={{ required: 'Task Type is required' }}
            render={({ field }) => (
              <FormControl size="small" sx={{ minWidth: '100%', mb: '20px' }}>
                <InputLabel id="task-type-label">Task Type*</InputLabel>
                <Select
                  sx={{ height: '55px' }}
                  {...field}
                  label="Task Type"
                  required
                  error={!!errors[TYPE_FIELD]}
                  onChange={e => {
                    field.onChange(e);
                    handleTaskTypeChange(e.target.value);
                  }}
                >
                  <MenuItem value="progress">Progress (%)</MenuItem>
                  <MenuItem value="milestone">Milestone</MenuItem>
                  <MenuItem value="scheduleOfRates">Schedule of rates</MenuItem>
                </Select>
              </FormControl>
            )}
          />
          {isShowScheduleFields && (
            <>
              <Controller
                name={UOM_FIELD}
                defaultValue=""
                control={control}
                rules={{ required: 'Unit of Measure is required' }}
                render={({ field }) => (
                  <FormControl
                    size="small"
                    sx={{ minWidth: '100%', mb: '20px' }}
                  >
                    <InputLabel id="task-type-label">
                      Unit of Measure*
                    </InputLabel>
                    <Select
                      sx={{ height: '50px' }}
                      {...field}
                      label="Unit of Measure*"
                      required
                      error={!!errors[UOM_FIELD]}
                    >
                      <MenuItem value="m">m</MenuItem>
                      <MenuItem value="m&sup2;">m&sup2;</MenuItem>
                      <MenuItem value="hours">hours</MenuItem>
                      <MenuItem value="kg">kg</MenuItem>
                      <MenuItem value="tonne">tonne</MenuItem>
                      <MenuItem value="others">Others</MenuItem>
                    </Select>
                  </FormControl>
                )}
              />
              <Controller
                name={QTY_FIELD}
                defaultValue={0}
                control={control}
                rules={{
                  required: 'QTY is required',
                  pattern: {
                    value: /^[0-9]*$/,
                    message: 'Value should be a prime number',
                  },
                  min: {
                    value: 1,
                    message: 'QTY must be greater than 0',
                  },
                }}
                render={({ field }) => (
                  <TextField
                    sx={{ width: '100%', height: '55px', mb: '15px' }}
                    {...field}
                    label="QTY*"
                    size="small"
                    error={!!errors[QTY_FIELD]}
                    helperText={errors[QTY_FIELD]?.message}
                  />
                )}
              />
              <Controller
                name={RATE_FIELD}
                defaultValue={0}
                control={control}
                rules={{
                  required: 'Rate is required',
                  pattern: {
                    value: /^[0-9]*$/,
                    message: 'Value should be a valid rate',
                  },
                  min: {
                    value: 1,
                    message: 'Rate must be greater than 0',
                  },
                }}
                render={({ field }) => (
                  <TextField
                    sx={{ width: '100%', height: '55px', mb: '15px' }}
                    {...field}
                    label="Rate*"
                    size="small"
                    error={!!errors[RATE_FIELD]}
                    helperText={errors[RATE_FIELD]?.message}
                  />
                )}
              />
            </>
          )}
          <Controller
            name={VALUE_FIELD}
            control={control}
            defaultValue={''}
            rules={{
              required: 'Enter a Task Value',
              validate: {
                numerical: value => validateNumericalField(value),
              },
            }}
            render={({ field }) => (
              <TextField
                sx={{ mb: '20px' }}
                {...field}
                required
                fullWidth
                type="text"
                label="Task Value"
                inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                error={!!errors[VALUE_FIELD]}
                helperText={errors[VALUE_FIELD]?.message?.toString()}
              />
            )}
          />
          <Controller
            name="excludeFromRetention"
            defaultValue={false}
            control={control}
            render={({ field }) => (
              <FormControlLabel
                control={<Checkbox {...field} checked={field.value} />}
                label="Exclude from Retention"
              />
            )}
          />
          <InputActions>
            <Button variant="outlined" type="button" onClick={handleClose}>
              Cancel
            </Button>
            <Button type="submit" variant="contained">
              Create
            </Button>
          </InputActions>
        </form>
      </DialogContent>
    </Dialog>
  );
}

export { VariationNewTask };
