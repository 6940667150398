import React from 'react';
import { Tooltip } from '@mui/material';
import { PERCENT, ValueType } from '../../common/api';
import {
  brand300,
  brand600,
  grey200,
  orange100,
  orange500,
  red100,
  red700,
  yellow200,
} from '../../styles/colours';
import { formatAmountByType, formatCurrency } from '../../common/format';

interface Props {
  total: number;
  claimed: number;
  approved: number;
  paid: number;
  valueType: ValueType;
  approvedVariation?: number;
  unApprovedVariation?: number;
  isNewTask?: boolean;
  isApprovedVariation?: boolean;
}

function ProgressBar(props: Props) {
  const {
    total,
    claimed,
    approved,
    paid,
    approvedVariation,
    unApprovedVariation,
    isNewTask,
    isApprovedVariation,
  } = props;

  const totalWithVariations =
    Number(total) + Math.abs(Number(approvedVariation) || 0);
  const costToComplete =
    !isApprovedVariation && isNewTask ? 0 : total - paid - approved - claimed;

  const block = (amount: number, title: string, color: string) => {
    return (
      <Tooltip
        title={
          <React.Fragment>
            <b>
              {props.valueType === PERCENT
                ? formatAmountByType(Math.round(amount), PERCENT)
                : formatCurrency(Math.round(amount))}
            </b>{' '}
            {title}
          </React.Fragment>
        }
        arrow
      >
        <div
          style={{
            ...styles.Bar,
            maxWidth: '100%',
            backgroundColor: color,
            width:
              props.valueType === PERCENT
                ? `${Math.round(Math.abs(Math.round(amount)))}%`
                : `${
                    (Math.abs(Math.round(amount)) / totalWithVariations) * 100
                  }%`,
          }}
        />
      </Tooltip>
    );
  };

  const backgroundColor =
    isNewTask && isApprovedVariation ? orange100 : isNewTask ? red100 : grey200;

  return (
    <div style={{ ...styles.Container, backgroundColor: backgroundColor }}>
      {block(paid, 'paid', brand600)}
      {block(approved, 'approved', brand300)}
      {block(claimed, 'claimed', yellow200)}
      {block(costToComplete, 'Cost to Complete', backgroundColor)}
      {unApprovedVariation
        ? block(unApprovedVariation, 'unapproved variation', red700)
        : null}
      {approvedVariation
        ? block(approvedVariation, 'approved variation', orange500)
        : null}
    </div>
  );
}

const styles: { [key: string]: React.CSSProperties } = {
  Bar: {
    display: 'flex',
    height: '100%',
  },
  Container: {
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: grey200,
    height: '16px',
    borderRadius: '4px',
    overflow: 'hidden',
    width: '100%',
    minWidth: '128px',
  },
};

export { ProgressBar };
