import React from 'react';
import { Breadcrumbs, Tooltip, Typography } from '@mui/material';
import { LinkTo } from '../../styles';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { grey500 } from '../../styles/colours';
import { v4 as uuidv4 } from 'uuid';
import { Path } from '../../common/api';

interface Props {
  links: Array<Path>;
}

function HeaderBreadcrumbs(props: Props) {
  const { links } = props;

  return (
    <Breadcrumbs
      separator={<NavigateNextIcon fontSize="small" sx={{ color: grey500 }} />}
    >
      {links.map((link, index) => {
        if (index === links.length - 1) {
          return (
            <Tooltip
              key={uuidv4()}
              title={link.contractorName}
              placement="bottom"
            >
              <Typography color={grey500} variant="body1">
                {link.label}
              </Typography>
            </Tooltip>
          );
        } else {
          return (
            <Tooltip
              key={uuidv4()}
              title={link.contractorName}
              placement="bottom"
            >
              <LinkTo color={grey500} to={link.url} state={link.state}>
                {link.label}
              </LinkTo>
            </Tooltip>
          );
        }
      })}
    </Breadcrumbs>
  );
}

export { HeaderBreadcrumbs };
