import React from 'react';
import { AlertColor } from '@mui/material';

import BasicTileItem from './BasicTileItem';
import DonutTileItem from './DonutTileItem';
import PieChartTileItem from './pie/PieChartTileItem';
import TextBoxTile from './text/TextBoxTile';
import MapTile from './map/MapTile';
import PictureTile from './picture/PictureTile';
import ProjectListTile from './list/ProjectListTile';
import ContractListTile from './list/ContractListTile';
import {
  ReportItemResource,
  ReportItemStyle,
} from '../../common/api/report-types';
import ProjectListTileDefault from './list/ProjectListTileDefault';
import ContractListTileDefault from './list/ContractListTileDefault';

type Props = {
  reportItem: ReportItemResource;
  projectId: string | null;
  isDefaultDashboard: boolean;
  isEditingDashboard: boolean;
  reload: () => void;
  showAlert: (title: string, severity: AlertColor) => void;
};

const ReportTile = ({
  reportItem,
  projectId,
  isDefaultDashboard,
  isEditingDashboard,
  reload,
  showAlert,
}: Props) => {
  return (
    <>
      {reportItem.style === ReportItemStyle.Basic && (
        <BasicTileItem
          reportItem={reportItem}
          isEditingDashboard={isEditingDashboard}
          reload={reload}
          showAlert={showAlert}
        />
      )}

      {reportItem.style === ReportItemStyle.Donut && (
        <DonutTileItem
          reportItem={reportItem}
          isEditingDashboard={isEditingDashboard}
          reload={reload}
          showAlert={showAlert}
        />
      )}

      {reportItem.style === ReportItemStyle.Pie && (
        <PieChartTileItem
          reportItem={reportItem}
          isEditingDashboard={isEditingDashboard}
          reload={reload}
          showAlert={showAlert}
        />
      )}

      {reportItem.style === ReportItemStyle.HeadlineTextBox && (
        <TextBoxTile
          reportItem={reportItem}
          isEditingDashboard={isEditingDashboard}
          reload={reload}
          showAlert={showAlert}
        />
      )}

      {reportItem.style === ReportItemStyle.CustomTextBox && (
        <TextBoxTile
          reportItem={reportItem}
          isEditingDashboard={isEditingDashboard}
          reload={reload}
          showAlert={showAlert}
        />
      )}

      {reportItem.style === ReportItemStyle.Map && (
        <MapTile
          reportItem={reportItem}
          isEditingDashboard={isEditingDashboard}
          reload={reload}
          showAlert={showAlert}
        />
      )}

      {(reportItem.style === ReportItemStyle.Picture ||
        reportItem.style === ReportItemStyle.Logo) && (
        <PictureTile
          reportItem={reportItem}
          isEditingDashboard={isEditingDashboard}
          reload={reload}
          showAlert={showAlert}
        />
      )}

      {reportItem.style === ReportItemStyle.ProjectList && (
        <>
          {isDefaultDashboard ? (
            <ProjectListTileDefault
              reportItem={reportItem}
              isEditingDashboard={isEditingDashboard}
              reload={reload}
              showAlert={showAlert}
            />
          ) : (
            <ProjectListTile
              reportItem={reportItem}
              isEditingDashboard={isEditingDashboard}
              reload={reload}
              showAlert={showAlert}
            />
          )}
        </>
      )}

      {reportItem.style === ReportItemStyle.ContractList && projectId && (
        <>
          {isDefaultDashboard ? (
            <ContractListTileDefault
              reportItem={reportItem}
              isEditingDashboard={isEditingDashboard}
              projectId={projectId}
              reload={reload}
              showAlert={showAlert}
            />
          ) : (
            <ContractListTile
              reportItem={reportItem}
              isEditingDashboard={isEditingDashboard}
              projectId={projectId}
              canRemove={isDefaultDashboard}
              reload={reload}
              showAlert={showAlert}
            />
          )}
        </>
      )}
    </>
  );
};

export default ReportTile;
