import React, { useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import Chart from 'chart.js/auto';
import { AlertColor, Box, Typography } from '@mui/material';
import { useMutation } from '@tanstack/react-query';

import { ReportItemResource } from '../../../common/api/report-types';
import { grey900 } from '../../../styles/colours';
import { useConfirmDialog } from '../../../common/hooks/useConfirmDialog';
import { ReportCellKebab, ReportCellWrapper } from '../ReportCell.style';
import { KebabMenu } from '../../../components/menu/KebabMenu';
import {
  deleteReportItem,
  deleteReportItemPayload,
} from '../../../common/api/reports';
import { useDialogState } from '../../../components/dialog/dialog.hooks';
import EditPieChartTile from './EditPieChartTile';
import { generateGreyShades, generateRandomNumbers } from './PieChart.utils';

type Props = {
  reportItem: ReportItemResource;
  isEditingDashboard: boolean;
  reload: () => void;
  showAlert: (title: string, severity: AlertColor) => void;
};

const PieChartTileItem = ({
  reportItem,
  isEditingDashboard,
  reload,
  showAlert,
}: Props) => {
  const chartRef = useRef<HTMLCanvasElement | null>(null);
  const [container, setContainer] = useState<HTMLDivElement | null>(null);

  const id = reportItem.id;
  const reportId = reportItem.reportId;
  const heading = reportItem.heading;
  const metricGroups = reportItem.metricGroups;

  const {
    isVisible: isEditVisible,
    open: openEdit,
    close: closeEdit,
  } = useDialogState();
  const { confirmAction, ConfirmDialog } = useConfirmDialog();

  const { mutateAsync: mutatedeleteReportItem } = useMutation(
    (payload: deleteReportItemPayload) => deleteReportItem(payload),
    {
      onSuccess: () => {
        reload();
        showAlert('Tile removed!', 'success');
      },
      onError: () =>
        showAlert(
          'There was an error deleting the tile. Please try again later.',
          'error'
        ),
    }
  );

  useEffect(() => {
    let chart: Chart<'pie'>;

    async function init() {
      if (chartRef.current) {
        const ctx = chartRef.current.getContext('2d');

        if (ctx) {
          const hasData = metricGroups.some(mg => mg.computedMetric > 0);
          const metricCount = metricGroups.length;
          chart = new Chart(ctx, {
            type: 'pie',
            options: {
              maintainAspectRatio: false,
              plugins: {
                legend: {
                  position: 'bottom',
                },
              },
            },
            data: {
              labels:
                metricGroups.length > 0
                  ? metricGroups.map(mg => mg.metric.title)
                  : ['Metric A', 'Metric B', 'Metric C'],
              datasets: [
                {
                  data: hasData
                    ? metricGroups.map(mg => mg.computedMetric)
                    : generateRandomNumbers(metricCount),
                  backgroundColor: hasData
                    ? metricGroups.map(mg => mg.colour)
                    : generateGreyShades(metricCount),
                  hoverOffset: 4,
                },
              ],
            },
          });
        }
      }
    }

    init();

    return () => {
      if (chart) {
        chart.destroy();
      }
    };
  }, [metricGroups]);

  const el = document.getElementById('report-actions');

  return (
    <ReportCellWrapper ref={setContainer}>
      {el &&
        createPortal(
          <>
            {isEditVisible && (
              <EditPieChartTile
                id={id}
                metricGroups={reportItem.metricGroups}
                reportId={reportId}
                heading={heading}
                close={closeEdit}
                reload={reload}
                showAlert={showAlert}
              />
            )}
          </>,
          el
        )}

      <ConfirmDialog />
      {!isEditingDashboard && (
        <ReportCellKebab>
          <KebabMenu
            options={['Tile Options', 'Remove Tile']}
            onOptionSelect={async option => {
              if (option === 'Remove Tile') {
                await confirmAction(
                  'Are you sure you want to remove this tile?'
                );
                await mutatedeleteReportItem({
                  reportId,
                  id,
                });
              } else if (option === 'Tile Options') {
                openEdit();
              }
            }}
          />
        </ReportCellKebab>
      )}
      <Box padding={1} height={`${(container?.offsetHeight || 300) - 60}px`}>
        {reportItem.heading && (
          <Typography
            fontSize="22px"
            fontWeight={500}
            color={grey900}
            marginBottom={'16px'}
          >
            {reportItem.heading}
          </Typography>
        )}

        <canvas
          style={{
            width: '100%',
            height: '100%',
          }}
          ref={chartRef}
        ></canvas>
      </Box>
    </ReportCellWrapper>
  );
};

export default PieChartTileItem;
