import { Button, Dialog, DialogContent, TextField } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import React, { useCallback } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Bank, SaveBankAccount } from '../../common/api';
import { saveBankAccount } from '../../common/api/bank';
import { Heading } from '../../styles/text';
import {
  validateBSBField,
  validateNumericalField,
} from './BankCreateDialog.util';
import { LoadingButton } from '@mui/lab';

const BSB_FIELD = 'bsb';
const ACCOUNT_NUMBER_FIELD = 'accountNumber';
const ACCOUNT_NAME = 'accountName';
const ACCOUNT_NICKNAME = 'accountNickname';

type FormData = {
  [BSB_FIELD]: string;
  [ACCOUNT_NUMBER_FIELD]: string;
  [ACCOUNT_NAME]: string;
  [ACCOUNT_NICKNAME]?: string;
};

type Props = {
  isOpen: boolean;
  accountId: string;
  bank: Bank | null;
  handleClose: () => void;
  refetch: () => void;
  onError: () => void;
};

function BankCreateDialog({
  isOpen,
  accountId,
  bank,
  handleClose,
  refetch,
  onError,
}: Props) {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>();

  const { mutateAsync, isLoading } = useMutation(
    (payload: SaveBankAccount) => saveBankAccount(accountId, payload),
    {
      onError,
    }
  );

  const onSubmit = useCallback(
    async (data: FormData) => {
      await mutateAsync({ ...data, id: bank?.id });
      refetch();
      handleClose();
    },
    [bank?.id, handleClose, mutateAsync, refetch]
  );

  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <DialogContent>
        <Heading>Add Bank Account</Heading>
        <Controller
          name={ACCOUNT_NAME}
          defaultValue={bank?.accountName}
          control={control}
          rules={{
            required: 'Account Name is required',
          }}
          render={({ field }) => (
            <TextField
              {...field}
              value={field.value || ''}
              label="Account Name"
              required
              fullWidth
              error={!!errors[ACCOUNT_NAME]}
              helperText={errors[ACCOUNT_NAME]?.message}
              style={styles.Field}
            />
          )}
        />
        <Controller
          name={ACCOUNT_NICKNAME}
          defaultValue={bank?.accountNickname}
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              value={field.value || ''}
              label="Account Nickname"
              fullWidth
              error={!!errors[ACCOUNT_NICKNAME]}
              helperText={errors[ACCOUNT_NICKNAME]?.message}
              style={styles.Field}
            />
          )}
        />
        <Controller
          name={BSB_FIELD}
          defaultValue={bank?.bsb}
          control={control}
          rules={{
            required: 'BSB is required',
            maxLength: 6,
            validate: {
              numerical: value => validateBSBField(value),
            },
          }}
          render={({ field }) => (
            <TextField
              {...field}
              value={field.value || ''}
              label="BSB"
              required
              fullWidth
              inputProps={{ maxLength: 6 }}
              error={!!errors[BSB_FIELD]}
              helperText={errors[BSB_FIELD]?.message}
              style={styles.Field}
            />
          )}
        />
        <Controller
          name={ACCOUNT_NUMBER_FIELD}
          control={control}
          defaultValue={bank?.accountNumber}
          rules={{
            required: 'Account Number is required',
            validate: {
              numerical: value => validateNumericalField(value),
            },
          }}
          render={({ field }) => (
            <TextField
              {...field}
              value={field.value || ''}
              label="Account Number"
              required
              fullWidth
              inputProps={{ maxLength: 9 }}
              error={!!errors[ACCOUNT_NUMBER_FIELD]}
              helperText={errors[ACCOUNT_NUMBER_FIELD]?.message}
              style={styles.Field}
            />
          )}
        />
        <div style={styles.ButtonGroup}>
          <Button variant="outlined" type="button" onClick={handleClose}>
            Cancel
          </Button>
          <LoadingButton
            variant="contained"
            loading={isLoading}
            onClick={handleSubmit(onSubmit)}
          >
            Save
          </LoadingButton>
        </div>
      </DialogContent>
    </Dialog>
  );
}

const styles: { [key: string]: React.CSSProperties } = {
  DialogContent: {
    width: '600px',
  },
  Field: {
    margin: '10px 0',
  },
  ButtonGroup: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '10px',
  },
};

export { BankCreateDialog };
