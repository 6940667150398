import React from 'react';
import { useQuery } from '@tanstack/react-query';

// API
import { listVariationsSubContractReceived } from '../../../../../common/api/variations';

// Components

// import { SummaryTable } from './variationsTables/SummaryTable';
import { useListPagination } from '../../../../../common/hooks/useListPagination';

// Styles
import { SectionHeading } from '../../../../../styles';
import { SectionHeadingWithFilter } from '../../../../../styles/text';
import { SubmittedVariationsTable } from '../../../components/variationsTables/SubmittedVariationsTable';
import { AnticipatedVariationsTable } from '../../../components/variationsTables/AnticipatedVariationsTable';

type Props = {
  contractId: string;
};

function SubContractorVariationsReceivedTab({
  contractId,
}: //   notifications,
//   handleCheckbox,
Props) {
  const { rowsPerPage, skip } = useListPagination();

  // Get Data
  const { data: submittedData } = useQuery(
    ['subContractSubmittedVariationsReceived', contractId],
    () =>
      listVariationsSubContractReceived(
        { contractId },
        {
          skip: skip,
          take: rowsPerPage,
          type: 'submitted',
        }
      )
  );

  const { data: anticipatedData } = useQuery(
    ['subContractAnticipatedVariationsReceived', contractId],
    () =>
      listVariationsSubContractReceived(
        { contractId },
        {
          skip: skip,
          take: rowsPerPage,
          type: 'anticipated',
        }
      )
  );

  return (
    <>
      {/* <section>
        <SummaryTable data={data} contingencyBudget={contingencyBudget} />
      </section> */}
      <section>
        <SectionHeadingWithFilter>
          <SectionHeading>Submitted Variation</SectionHeading>
        </SectionHeadingWithFilter>
        <SubmittedVariationsTable
          contractId={contractId}
          data={submittedData}
        />
      </section>
      <section>
        <SectionHeadingWithFilter>
          <SectionHeading>Anticipated Variation</SectionHeading>
        </SectionHeadingWithFilter>
        <AnticipatedVariationsTable
          contractId={contractId}
          data={anticipatedData}
        />
      </section>
    </>
  );
}

export { SubContractorVariationsReceivedTab };
