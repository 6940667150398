import React from 'react';
import { IconButton } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';

const DialogClose = ({ onClose }: { onClose: () => void }) => {
  return (
    <IconButton
      aria-label="close"
      onClick={onClose}
      sx={{
        position: 'absolute',
        right: '20px',
        top: '16px',
        color: theme => theme.palette.grey[500],
      }}
    >
      <ClearIcon />
    </IconButton>
  );
};

export { DialogClose };
