import React, { useCallback, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { EMAIL_REGEX } from '../../../common/validate';
import { sendResetPassword } from '../../../common/api/resetPassword';

const EMAIL_FIELD = 'email';
interface ResetPasswordForm {
  [EMAIL_FIELD]: string;
}

interface Props {
  open: boolean;
  setOpen: (isOpen: boolean) => void;
}

function ResetPasswordDialog(props: Props) {
  const { open, setOpen } = props;

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<ResetPasswordForm>();
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState('');

  const handleClose = useCallback(() => {
    setOpen(false);
    setSuccess(false);
    setError('');
  }, [setOpen]);

  const onSubmit = useCallback(async (data: ResetPasswordForm) => {
    setLoading(true);
    setSuccess(false);
    setError('');

    try {
      await sendResetPassword(data.email);
      setSuccess(true);
    } catch (error) {
      setError('Failed to send forgot password email. Please try again later.');
    } finally {
      setLoading(false);
    }
  }, []);

  return (
    <>
      <Dialog open={open} onClose={handleClose}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogTitle>Forgot Password</DialogTitle>
          <DialogContent style={styles.DialogContent}>
            {success ? (
              <p>link sent to email</p>
            ) : (
              <Controller
                name={EMAIL_FIELD}
                defaultValue={''}
                control={control}
                rules={{
                  required: 'Email is required',
                  pattern: {
                    value: EMAIL_REGEX,
                    message: 'Invalid email address',
                  },
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    margin="normal"
                    required
                    fullWidth
                    label="Email Address"
                    autoComplete="email"
                    autoFocus
                    error={!!errors.email}
                    helperText={errors.email?.message?.toString()}
                  />
                )}
              />
            )}
            {error && <p style={{ color: 'red' }}>{error}</p>}
          </DialogContent>
          <DialogActions>
            {success ? (
              <Button onClick={handleClose} color="primary">
                Close
              </Button>
            ) : (
              <>
                <Button onClick={handleClose} color="primary">
                  Cancel
                </Button>
                <Button type="submit" color="primary" disabled={loading}>
                  {loading ? 'Sending...' : 'Send'}
                </Button>
              </>
            )}
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
}

const styles: { [key: string]: React.CSSProperties } = {
  DialogContent: {
    width: '600px',
  },
};

export { ResetPasswordDialog };
