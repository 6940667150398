import React from 'react';
import { Select, MenuItem, SelectChangeEvent } from '@mui/material';

export enum AlertColor {
  Green = 'Green',
  Yellow = 'Yellow',
  Red = 'Red',
}

interface Props {
  value: string;
  onChange: (event: SelectChangeEvent<string>) => void;
  name: string;
  disabled?: boolean;
}

const ColorSelect = ({ value, onChange, name, disabled = false }: Props) => {
  return (
    <Select
      value={value}
      onChange={onChange}
      name={name}
      disabled={disabled}
      displayEmpty
      sx={{
        width: '100px',
        height: '40px',
        backgroundColor:
          value === 'Green'
            ? 'rgba(210, 245, 225, 0.8)'
            : value === 'Yellow'
            ? 'rgba(255, 255, 200, 0.8)'
            : value === 'Red'
            ? 'rgba(255, 200, 200, 0.8)'
            : 'rgba(210, 245, 225, 0.8)',
        borderRadius: '30px',
        padding: '0',
        color: '#1d5d46',
        '& .MuiSelect-icon': {
          color: '#1d5d46',
        },
        '&:hover': {
          backgroundColor:
            value === 'Green'
              ? 'rgba(210, 245, 225, 1)'
              : value === 'Yellow'
              ? 'rgba(255, 255, 200, 1)'
              : value === 'Red'
              ? 'rgba(255, 200, 200, 1)'
              : 'rgba(210, 245, 225, 1)',
        },
      }}
      MenuProps={{
        PaperProps: {
          sx: {
            borderRadius: '15px',
            backgroundColor: 'white',
          },
        },
      }}
    >
      <MenuItem value={AlertColor.Green}>{AlertColor.Green}</MenuItem>
      <MenuItem value={AlertColor.Yellow}>Amber</MenuItem>
      <MenuItem value={AlertColor.Red}>{AlertColor.Red}</MenuItem>
    </Select>
  );
};

export { ColorSelect };
