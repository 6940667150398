import React, { useCallback, useState } from 'react';
import { Box, Button, Grid, Typography } from '@mui/material';
import {
  createContractFile,
  deleteContractFile,
  getContractFile,
  Contract,
  ContractFile,
  updateContractFile,
} from '../../common/api';
import { useMutation } from '@tanstack/react-query';
import { Params, useParams } from 'react-router-dom';
import { FilesItem } from '../../pages/contract-page/components/FilesItem';
import { SkeletonTable } from '../skeleton-table';
import { useDialogState } from '../dialog/dialog.hooks';
import {
  FilePayload,
  FileUploadDialog,
} from '../file-upload/FileUploadDropzone';

interface Props {
  contract: Contract | null;
  isContractor: boolean;
  contractFiles?: Array<ContractFile>;
  isContractFilesLoading: boolean;
  refetchContractFiles: () => void;
}

function ComplianceTab(props: Props) {
  const {
    contract,
    isContractor,
    contractFiles,
    isContractFilesLoading,
    refetchContractFiles,
  } = props;
  const { contractId } = useParams<Params>();
  const [selectedFile, setSelectedFile] = useState<string | undefined>();

  const {
    isVisible: isUploadVisible,
    close: closeUpload,
    open: openUpload,
  } = useDialogState();

  const { mutate: mutateCreateContractFile, isLoading: isCreateFilesLoading } =
    useMutation(
      (payload: FilePayload) =>
        createContractFile(contractId!, {
          fileName: payload.file.name,
          path: payload.path,
          mimetype: payload.contentType,
          documentName: payload.documentName,
          documentType: payload.documentType,
          documentExpiry: payload.documentExpiry,
        }),
      {
        onSuccess: () => refetchContractFiles(),
      }
    );

  const { mutate: mutateUpdateFile, isLoading: isUpdateFilesLoading } =
    useMutation(
      (payload: {
        id: string;
        documentName: string;
        documentType: string | null;
        documentExpiry: string | null;
      }) => updateContractFile(contractId!, payload),
      {
        onSuccess: () => {
          refetchContractFiles();
          closeUpload();
          setSelectedFile(undefined);
        },
      }
    );

  const { mutate: mutateDeleteContractFile, isLoading: isDeleteFileLoading } =
    useMutation((fileId: string) => deleteContractFile(contractId!, fileId), {
      onSuccess: () => refetchContractFiles(),
    });

  const { mutate: mutateGetContractFile } = useMutation(
    (fileId: string) => getContractFile(contract?.id!, fileId),
    {
      onSuccess: data => {
        const link = document.createElement('a');
        link.href = data.url;
        link.click();
        link.remove();
      },
    }
  );

  const handleDeleteContractFile = useCallback(
    (fileId: string) => {
      mutateDeleteContractFile(fileId);
    },
    [mutateDeleteContractFile]
  );

  const handleDownloadContractFile = useCallback(
    async (fileId: string) => {
      mutateGetContractFile(fileId);
    },
    [mutateGetContractFile]
  );

  const handleEditFileInfo = useCallback(
    (fileId: string) => {
      setSelectedFile(fileId);
      openUpload();
    },
    [openUpload, setSelectedFile]
  );

  const isLoading =
    isContractFilesLoading || isCreateFilesLoading || isDeleteFileLoading;

  return (
    <>
      {isUploadVisible && (
        <FileUploadDialog
          hasFormFields
          showLoading={isCreateFilesLoading || isUpdateFilesLoading}
          uploadedFile={contractFiles?.find(f => f.id === selectedFile)}
          onFileUpload={payload => {
            mutateCreateContractFile(payload);
          }}
          onEditFileInfo={payload => {
            mutateUpdateFile({
              id: payload.id,
              documentName: payload.documentName || '',
              documentType: payload.documentType || null,
              documentExpiry: payload.documentExpiry || null,
            });
          }}
          close={closeUpload}
        />
      )}

      {isContractor && (
        <Box display="flex" justifyContent="end" marginBottom="16px">
          <Button
            onClick={() => {
              setSelectedFile(undefined);
              openUpload();
            }}
            variant="contained"
          >
            Upload File
          </Button>
        </Box>
      )}

      {!isLoading ? (
        <Grid container spacing={2}>
          {contractFiles?.length === 0 && (
            <Grid item xs={12} sm={12} md={4}>
              <Typography sx={{ mt: 2 }}>No files to display</Typography>
            </Grid>
          )}
          {contractFiles?.map(contractFile => (
            <Grid item xs={12} sm={12} md={4} key={contractFile.id}>
              <FilesItem
                file={{
                  id: contractFile.id,
                  name: contractFile.name,
                  createdAt: contractFile?.uploadedAt || contractFile.createdAt,
                  uploaderEmail: contractFile.uploader.email,
                  documentName: contractFile.documentName,
                  documentType: contractFile.documentType,
                  documentExpiry: contractFile.documentExpiry,
                }}
                editMode={isContractor}
                canEditFileInfo={isContractor}
                onDeleteFile={handleDeleteContractFile}
                onDownloadFile={handleDownloadContractFile}
                onEditFile={handleEditFileInfo}
              />
            </Grid>
          ))}
        </Grid>
      ) : (
        <SkeletonTable />
      )}
    </>
  );
}

export { ComplianceTab };
