import { Color } from '@tiptap/extension-color';
import Paragraph from '@tiptap/extension-paragraph';
import TextStyle from '@tiptap/extension-text-style';
import Text from '@tiptap/extension-text';
import Document from '@tiptap/extension-document';
import Bold from '@tiptap/extension-bold';
import Italic from '@tiptap/extension-italic';
import BulletList from '@tiptap/extension-bullet-list';
import OrderedList from '@tiptap/extension-ordered-list';
import ListItem from '@tiptap/extension-list-item';
import Heading from '@tiptap/extension-heading';
import Placeholder from '@tiptap/extension-placeholder';
import Underline from '@tiptap/extension-underline';
import Link from '@tiptap/extension-link';
import TextAlign from '@tiptap/extension-text-align';
import Highlight from '@tiptap/extension-highlight';
import Strike from '@tiptap/extension-strike';
import History from '@tiptap/extension-history';

export const RICHTEXT_EXTENSIONS = [
  Bold,
  Italic,
  Underline,
  Document,
  Text,
  Placeholder,
  Link,
  Paragraph,
  TextAlign.configure({
    alignments: ['left', 'right', 'center'],
    types: ['paragraph', 'heading'],
  }),
  TextStyle.configure(),
  Heading,
  ListItem,
  BulletList,
  OrderedList,
  Highlight.configure({
    multicolor: true,
  }),
  Color.configure({ types: [TextStyle.name, ListItem.name] }),
  Strike,
  History,
];
