import React from 'react';
import {
  SummaryCard,
  SummaryGrid,
  SummaryText,
} from '../project-list-page/components/SummaryCard';
import { PERCENT, Variation } from '../../common/api';
import {
  formatAmountByType,
  formatCurrency,
  formatDate,
} from '../../common/format';

type Props = {
  variationData: Variation | null;
};

function VariationSummary({ variationData }: Props) {
  return (
    <SummaryGrid>
      <SummaryCard title="Total Value of Variation">
        <SummaryText
          value={
            variationData
              ? variationData?.valueType === PERCENT
                ? formatAmountByType(Number(variationData.totalValue), PERCENT)
                : formatCurrency(Number(variationData.totalValue))
              : 0
          }
        />
      </SummaryCard>
      <SummaryCard title="Date Submitted">
        <SummaryText
          value={
            variationData?.submittedAt
              ? formatDate(variationData.submittedAt)
              : ''
          }
        />
      </SummaryCard>
      <SummaryCard title="Date Responded">
        <SummaryText
          value={
            variationData?.approvedAt
              ? formatDate(variationData.approvedAt)
              : variationData?.rejectedAt
              ? formatDate(variationData.rejectedAt)
              : ''
          }
        />
      </SummaryCard>
    </SummaryGrid>
  );
}

export { VariationSummary };
