import React, { useState } from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  TextField,
  Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

interface Props {
  advancedSettings: {
    minimumValue: string;
    timeDelay: string;
  };
  isDisabled: boolean;
  handleAdvancedSettingsChange: (
    event: React.ChangeEvent<HTMLInputElement>
  ) => void;
}

const AdvancedSettings = ({
  advancedSettings,
  isDisabled,
  handleAdvancedSettingsChange,
}: Props) => {
  const [expanded, setExpanded] = useState<boolean>(true);

  const handleAccordionChange = () => {
    setExpanded(!expanded);
  };

  return (
    <Accordion
      expanded={expanded}
      onChange={handleAccordionChange}
      elevation={0}
      sx={{
        border: '1px solid #D9D9D9',
        boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.1)',
        borderRadius: '4px',
        mt: 2,
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="advanced-settings-content"
        id="advanced-settings-header"
      >
        <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
          Advanced Settings
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Box sx={{ ml: 4 }}>
          <TextField
            label="Minimum Value"
            name="minimumValue"
            type="number"
            value={advancedSettings.minimumValue}
            onChange={handleAdvancedSettingsChange}
            disabled={isDisabled}
            fullWidth
            InputProps={{
              startAdornment: <Typography sx={{ color: 'gray' }}>$</Typography>,
            }}
            inputProps={{ min: 0 }}
            sx={{ mt: 2 }}
          />
          <TextField
            label="Time Delay (Days)"
            name="timeDelay"
            type="number"
            value={advancedSettings.timeDelay}
            onChange={handleAdvancedSettingsChange}
            disabled={isDisabled}
            fullWidth
            InputProps={{
              startAdornment: !advancedSettings.timeDelay ? (
                <Typography sx={{ color: 'gray' }}>00</Typography>
              ) : null,
            }}
            inputProps={{ min: 0 }}
            sx={{ mt: 2 }}
          />
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

export { AdvancedSettings };
