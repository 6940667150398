import { axios } from './axios';
import {
  Contract,
  ContractHistoryData,
  CreateContractPayload,
  ResponseListBaseType,
  ReviewContractPayload,
  SubContract,
  SubContractSummary,
  UpdateContractPayload,
} from './types';

async function listContracts(options: {
  projectId: string;
  parentTaskId?: string;
  take?: number;
  skip?: number;
  tags?: Array<string>;
  isAutocomplete?: boolean;
}): Promise<ResponseListBaseType<Array<Contract>>> {
  const { projectId, parentTaskId, take, skip, tags, isAutocomplete } = options;

  const resp = await axios.get(`/contracts`, {
    params: {
      projectId,
      parentTaskId,
      tagsList: tags?.join(','),
      take,
      skip,
      isAutocomplete,
    },
  });

  return resp.data;
}

async function getContract(
  contractId: string,
  options: {
    taskId?: string | null;
  } = {}
): Promise<Contract> {
  const { taskId } = options;

  const resp = await axios.get(`/contracts/${contractId}`, {
    params: {
      taskId,
    },
  });

  return resp.data;
}

async function createContract(
  payload: CreateContractPayload
): Promise<Contract> {
  const resp = await axios.post(`/contracts`, payload);
  return resp.data;
}

async function updateContract(
  contractId: string,
  data: UpdateContractPayload
): Promise<Contract> {
  const resp = await axios.patch(`/contracts/${contractId}`, data);
  return resp.data;
}

async function deleteContract({
  contractId,
}: {
  contractId: string;
}): Promise<Contract> {
  const resp = await axios.delete(`/contracts/${contractId}`);
  return resp.data;
}

async function reviewContract(
  contractId: string,
  data: ReviewContractPayload
): Promise<Contract> {
  const resp = await axios.patch(`/contracts/${contractId}/review`, data);
  return resp.data;
}

async function getContractHistory(
  contractId: string
): Promise<ContractHistoryData> {
  const resp = await axios.get(`/contracts/${contractId}/history`);

  return resp.data;
}

async function listSubContracts(options: {
  contractId: string;
  take?: number;
  skip?: number;
}): Promise<ResponseListBaseType<Array<SubContract>>> {
  const { contractId, take, skip } = options;

  const resp = await axios.get(`/contracts/${contractId}/subcontracts`, {
    params: {
      take,
      skip,
    },
  });

  return resp.data;
}

async function getSubcontractsSummary(options: {
  contractId: string;
}): Promise<SubContractSummary> {
  const { contractId } = options;

  const resp = await axios.get(`/contracts/${contractId}/subcontracts/summary`);

  return resp.data;
}

export {
  listContracts,
  getContract,
  getContractHistory,
  createContract,
  deleteContract,
  reviewContract,
  updateContract,
  listSubContracts,
  getSubcontractsSummary,
};
