import React, { useState } from 'react';
import Box from '@mui/material/Box';
import { Alert, CircularProgress, TextField, Typography } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { LoadingButton } from '@mui/lab';
import { Params, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useMutation, useQuery } from '@tanstack/react-query';

import { Copyright } from '../../components/copyright';
import { useAuth } from '../../common/auth-provider/AuthProvider';
import { getEmailByInvitationId, register, login } from '../../common/api';
import { AxiosError } from 'axios';
import { LoginLayout } from '../../components/login-layout';
import { validatePassword } from '../../common/validate';

interface Form {
  password: string;
  confirmPassword: string;
}

function ActivateInvitationPage() {
  const { invitationId } = useParams() as Params;

  const {
    control,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm<Form>({
    mode: 'onChange',
  });
  const { onLogin } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  // A page/non-field error.
  const [pageError, setPageError] = useState<string | null>();

  // The reason, supplied as a parameter why the user is here.
  const [reason, setReason] = useState<string | undefined>(
    new URLSearchParams(location.search).get('reason') ?? undefined
  );

  // The original intended location, or /
  const from = location.state?.from?.pathname || '/';

  const { data: invitation, isLoading: isInvitationLoading } = useQuery(
    ['invitation', invitationId],
    () => getEmailByInvitationId(invitationId!)
  );

  const { mutateAsync: mutateRegister, isLoading: isRegisterLoading } =
    useMutation((password: string) => register(invitationId!, password));

  const { mutateAsync: mutateLogin, isLoading: isLoginLoading } = useMutation(
    (password: string) => {
      return login(invitation?.email || '', password);
    }
  );

  const onChange = () => {
    setPageError(null);
  };

  const onSubmit = async (form: Form) => {
    setReason(undefined);
    try {
      // Call /login
      if (!invitation?.email) {
        return;
      }
      await mutateRegister(form.password);

      const authResponse = await mutateLogin(form.password);

      // Update app state with the AuthResponse.
      onLogin(authResponse);

      // Navigate to the original location.
      navigate(from, { replace: true });
    } catch (err) {
      let message = `An unknown error occurred`;
      if (err instanceof AxiosError) {
        const status = err.response?.status;
        if (status === 401) {
          message = 'Login failed';
          setPageError(message);
          return;
        }
        if (status === 404) {
          message = `Invitation not found or already used`;
          setPageError(message);
          return;
        }
        message = `An unknown error occurred (status ${status})`;
        setPageError(message);
      }
    }
  };

  const isLoading = isInvitationLoading || isLoginLoading || isRegisterLoading;

  if (isLoading) {
    return (
      <LoginLayout>
        <CircularProgress />
      </LoginLayout>
    );
  }

  return (
    <LoginLayout>
      <>
        {!!invitation?.email ? (
          <Box
            component="form"
            noValidate
            sx={{ mt: 1 }}
            onSubmit={handleSubmit(onSubmit)}
            onChange={onChange}
          >
            {reason && (
              <Alert sx={{ my: 3 }} severity="info">
                {reason}
              </Alert>
            )}
            {pageError && (
              <Alert sx={{ my: 3 }} severity="error">
                {pageError}
              </Alert>
            )}
            <Typography mb={2}>
              You have been invited to <strong>{invitation.orgName}</strong> by:
              <br />
              {invitation.inviterName}
              <br />
              {invitation.inviterEmail}
              <br />
              <br />
              Please enter a password and reconfirm to accept this invitation.
            </Typography>

            <TextField
              margin="normal"
              fullWidth
              label="Email"
              value={invitation.email}
              disabled
            />
            <Controller
              name={'password'}
              defaultValue={''}
              control={control}
              rules={{
                validate: value => validatePassword(value),
              }}
              render={({ field }) => (
                <TextField
                  {...field}
                  margin="normal"
                  required
                  fullWidth
                  label="Password"
                  type="password"
                  autoFocus
                  error={!!errors.password}
                  helperText={errors.password?.message?.toString()}
                />
              )}
            />
            <Controller
              name={'confirmPassword'}
              defaultValue={''}
              control={control}
              rules={{
                required: 'Confirm Password!',
                validate: value => {
                  const { password } = getValues();
                  return password === value || 'Passwords should match!';
                },
              }}
              render={({ field }) => (
                <TextField
                  {...field}
                  margin="normal"
                  required
                  fullWidth
                  label="Confirm Password"
                  type="password"
                  error={!!errors.confirmPassword}
                  helperText={errors.confirmPassword?.message?.toString()}
                />
              )}
            />
            <LoadingButton
              loading={isRegisterLoading || isLoginLoading}
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 3 }}
            >
              Register
            </LoadingButton>
            <Copyright />
          </Box>
        ) : (
          <>
            <br />
            <Typography variant="h5">
              Sorry, it seems that this invitation no longer exists
            </Typography>
          </>
        )}
      </>
    </LoginLayout>
  );
}

export { ActivateInvitationPage };
