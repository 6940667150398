export interface ICheckbox {
  id: number;
  label?: string;
  name: string;
  level?: number;
  levelLabel?: string;
  child?: ICheckbox;
  children?: ICheckbox[];
}

const row1: ICheckbox[] = [
  {
    id: 1,
    label: 'Anticipated Variations function',
    name: 'anticipatedVariationsFunction',
  },
  {
    id: 2,
    label: "View Contractor's Anticipated Variation items",
    name: 'viewContractorsAnticipatedVariationItems',
  },
  {
    id: 3,
    label: 'Share my Anticipated Variation items with contractor',
    name: 'shareMyAnticipatedVariationItemsWithContractor',
  },
];

const row2: ICheckbox[] = [
  {
    id: 4,
    label: 'All Subcontractor Settings Locked?',
    name: 'subcontractorSettingsLocked',
  },
  {
    id: 5,
    label: 'Replicate all subcontractor settings below?',
    name: 'replicateAllSettings',
  },
  {
    id: 6,
    label: 'All Subcontractor Settings (below) locked',
    name: 'subcontractorSettingsBelowLocked',
  },
];

const row3: ICheckbox[] = [
  {
    id: 7,
    label:
      'Subcontractors listed with organisational info & description of services',
    name: 'sub_subcontractorOrgInfo',
    level: 1,
    levelLabel: '1',
    child: {
      id: 71,
      name: 'subsub_subcontractorOrgInfo',
      level: 1,
    },
  },
  {
    id: 8,
    label: 'Overall % progress complete of contract',
    name: 'sub_overallProgress',
    level: 2,
    levelLabel: '2',
    child: {
      id: 81,
      name: 'subsub_overallProgress',
      level: 2,
    },
  },
  {
    id: 9,
    label: 'Subcontractor WBS/Claim items (name only)',
    name: 'sub_subcontractorClaimItemsNameOnly',
    level: 3,
    levelLabel: '3',
    child: {
      id: 91,
      name: 'subsub_subcontractorClaimItemsNameOnly',
      level: 3,
    },
  },
  {
    id: 10,
    label: '% progress of Subcontractor WBS/Claim items',
    name: 'sub_progressOfSubcontractorItems',
    level: 4,
    levelLabel: '4',
    child: {
      id: 101,
      name: 'subsub_progressOfSubcontractorItems',
      level: 4,
    },
  },
  {
    id: 11,
    label: '$Values of Subcontractor WBS/Claim items',
    name: 'sub_subcontractorWBSValues',
    level: 5,
    levelLabel: '5',
    child: {
      id: 111,
      name: 'subsub_subcontractorWBSValues',
      level: 5,
    },
  },
  {
    id: 110,
    label: '',
    name: 'sub_lockRow3',
    level: 0,
    levelLabel: 'Lock',
    child: {
      id: 1110,
      name: 'subsub_lockRow3',
      level: 0,
      levelLabel: 'Lock',
    },
  },
];

const row4: ICheckbox[] = [
  {
    id: 12,
    label: 'Subcontractor Claims made',
    name: 'parentSubcontractorClaimsMade',
    children: [
      {
        id: 121,
        label: 'Locked to subcontractor',
        name: 'claims_lockedToSubcontractor',
      },
      {
        id: 122,
        label: 'Replicated to subcontractor below',
        name: 'claims_replicatedToSubcontractorBelow',
      },
      {
        id: 123,
        label: 'Locked to subcontractor below',
        name: 'claims_lockedToSubcontractorBelow',
      },
    ],
  },
];

const row5: ICheckbox[] = [
  {
    id: 13,
    label: 'Submitted',
    name: 'submitted',
    children: [
      {
        id: 131,
        label: 'Locked to subcontractor',
        name: 'submitted_lockedToSubcontractor',
      },
      {
        id: 132,
        label: 'Replicated to subcontractor below',
        name: 'submitted_replicatedToSubcontractorBelow',
      },
      {
        id: 133,
        label: 'Locked to subcontractor below',
        name: 'submitted_lockedToSubcontractorBelow',
      },
    ],
  },
  {
    id: 14,
    label: 'Approved',
    name: 'approved',
    children: [
      {
        id: 141,
        label: 'Locked to subcontractor',
        name: 'approved_lockedToSubcontractor',
      },
      {
        id: 142,
        label: 'Replicated to subcontractor below',
        name: 'approved_replicatedToSubcontractorBelow',
      },
      {
        id: 143,
        label: 'Locked to subcontractor below',
        name: 'approved_lockedToSubcontractorBelow',
      },
    ],
  },
  {
    id: 15,
    label: 'Rejected',
    name: 'rejected',
    children: [
      {
        id: 151,
        label: 'Locked to subcontractor',
        name: 'rejected_lockedToSubcontractor',
      },
      {
        id: 152,
        label: 'Replicated to subcontractor below',
        name: 'rejected_replicatedToSubcontractorBelow',
      },
      {
        id: 153,
        label: 'Locked to subcontractor below',
        name: 'rejected_lockedToSubcontractorBelow',
      },
    ],
  },
  {
    id: 16,
    label: 'Withdrawn',
    name: 'withdrawn',
    children: [
      {
        id: 161,
        label: 'Locked to subcontractor',
        name: 'withdrawn_lockedToSubcontractor',
      },
      {
        id: 162,
        label: 'Replicated to subcontractor below',
        name: 'withdrawn_replicatedToSubcontractorBelow',
      },
      {
        id: 163,
        label: 'Locked to subcontractor below',
        name: 'withdrawn_lockedToSubcontractorBelow',
      },
    ],
  },
  {
    id: 17,
    label: 'Anticipated',
    name: 'anticipated',
    children: [
      {
        id: 171,
        label: 'Locked to subcontractor',
        name: 'anticipated_lockedToSubcontractor',
      },
      {
        id: 172,
        label: 'Replicated to subcontractor below',
        name: 'anticipated_replicatedToSubcontractorBelow',
      },
      {
        id: 173,
        label: 'Locked to subcontractor below',
        name: 'anticipated_lockedToSubcontractorBelow',
      },
    ],
  },
];

const row6: ICheckbox[] = [
  {
    id: 18,
    label: 'Payments schedules - SOPA compliance Alert',
    name: 'sopaComplianceAlert',
    children: [
      {
        id: 181,
        label: 'Locked to subcontractor',
        name: 'sopaComplianceAlert_lockedToSubcontractor',
      },
      {
        id: 182,
        label: 'Replicated to subcontractor below',
        name: 'sopaComplianceAlert_replicatedToSubcontractorBelow',
      },
      {
        id: 183,
        label: 'Locked to subcontractor below',
        name: 'sopaComplianceAlert_lockedToSubcontractorBelow',
      },
    ],
  },
];

const row7: ICheckbox[] = [
  {
    id: 19,
    label:
      '[Name of subcontractor +details] has not been paid for [period] by [Name of Contractor]',
    name: 'subcontractorNotPaid',
    level: 1,
    child: {
      id: 191,
      name: 'subsub_subcontractorNotPaid',
      level: 1,
    },
  },
  {
    id: 20,
    label: '$value [RANGE] of Contractor`s WBS/Claim items',
    name: 'contractorWBSValueRange',
    level: 2,
    child: {
      id: 201,
      name: 'subsub_contractorWBSValueRange',
      level: 2,
    },
  },
  {
    id: 21,
    label: 'Exact $Values of Contractor`s WBS/Claim items',
    name: 'contractorExactWBSValues',
    level: 3,
    child: {
      id: 211,
      name: 'subsub_contractorExactWBSValues',
      level: 3,
    },
  },
  {
    id: 210,
    label: '',
    name: 'sub_lockRow7',
    level: 0,
    levelLabel: 'Lock',
    child: {
      id: 2110,
      name: 'subsub_lockRow7',
      level: 0,
      levelLabel: 'Lock',
    },
  },
];

const row8: ICheckbox[] = [
  {
    id: 22,
    label: 'Subcontractor compliance documents expire',
    name: 'complianceDocumentsExpire',
    children: [
      {
        id: 221,
        label: 'Locked to subcontractor',
        name: 'compliance_lockedToSubcontractor',
      },
      {
        id: 222,
        label: 'Replicated to subcontractor below',
        name: 'compliance_replicatedToSubcontractorBelow',
      },
      {
        id: 223,
        label: 'Locked to subcontractor below',
        name: 'compliance_lockedToSubcontractorBelow',
      },
    ],
  },
];

export { row1, row2, row3, row4, row5, row6, row7, row8 };
