export enum RententionBasis {
  None = 'None',
  Percent = 'Percent',
  BankGuarantee = 'BankGuarantee',
}

export enum TimePeriod {
  Days = 'Days',
  Weeks = 'Weeks',
  Months = 'Months',
}
