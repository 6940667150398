import React from 'react';
import { LoadingButton } from '@mui/lab';

export type ActionListType = {
  id: string;
  isDisabled?: boolean;
  isLoading?: boolean;
  label: string;
  color?: 'success' | 'error' | 'info' | 'warning';
  textColor?: string;
  borderColor?: string;
  variant?: 'contained' | 'outlined';
  action: () => void;
};

interface ActionButtonsProps {
  actionList?: ActionListType[];
}

export const ActionButtons = ({ actionList = [] }: ActionButtonsProps) => (
  <>
    {actionList.map(
      ({
        id,
        isDisabled = false,
        isLoading = false,
        color,
        label,
        variant = 'outlined',
        textColor,
        borderColor,
        action,
      }) => (
        <LoadingButton
          key={id}
          loading={isLoading}
          onClick={action}
          variant={variant}
          color={color}
          disabled={isDisabled}
          sx={{
            color: textColor,
            borderColor: borderColor,
          }}
        >
          {label}
        </LoadingButton>
      )
    )}
  </>
);
