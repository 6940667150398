import React, { useCallback, useMemo, useState } from 'react';

function useListPagination() {
  const [page, setPage] = useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = useState<number>(50);

  const handleChangeRowsPerPage = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    },
    [setPage, setRowsPerPage]
  );

  const handleChangePage = useCallback(
    (event: React.ChangeEvent<unknown>, newPage: number) => {
      setPage(newPage);
    },
    [setPage]
  );

  const skip = useMemo(() => (page - 1) * rowsPerPage, [page, rowsPerPage]);

  return {
    page,
    rowsPerPage,
    handleChangeRowsPerPage,
    handleChangePage,
    skip,
  };
}

export { useListPagination };
