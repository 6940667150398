import React, { useCallback } from 'react';
import { Box, Button, Stack, Typography } from '@mui/material';
import { useDialogState } from '../../../../components/dialog/dialog.hooks';
import { ClaimChat } from '../../../../components/claim-chat';
import {
  BaseTaskGroupProps,
  TaskGroups,
} from '../../../../components/task-group/TaskGroups';
import { ClaimTable } from '../claim-approve-view/components/ClaimTable';
import { Claim, ClaimStatus, Contract, Task } from '../../../../common/api';
import { ClaimSummary } from '../../components';
import { LoadingButton } from '@mui/lab';
import { red700 } from '../../../../styles/colours';
import { useUpdateClaim } from '../claim-approve-view/components/ClaimTab.hooks';
import { ClaimFileDrawer } from '../../../contract-page/views/contract-claim-view/components/ClaimFileDrawer';
import { ChipStatus } from '../../../../components/chip-status';
import { useLocation, useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import { projectDate } from '../../../../common/format/formatDate';
import { useProject } from '../../../../common/hooks/useProject';

type Props = BaseTaskGroupProps & {
  claim: Claim;
  tasks?: Array<Task>;
  isLoadingClaim?: boolean;
  contract: Contract;
  reloadData: () => void;
  reloadContract: () => void;
  isContractor: boolean;
};

function ClaimContractorView(props: Props) {
  const {
    claim,
    tasks,
    contract,
    reloadData,
    reloadContract,
    selectedTaskGroup,
    handleSelectedTaskGroup,
    isContractor,
  } = props;

  const location = useLocation();
  const navigate = useNavigate();
  const previousPath = location.pathname.split('/claims/')[0];
  const { project } = useProject();

  const {
    isVisible: isChatVisible,
    close: closeChat,
    open: openChat,
  } = useDialogState();

  const {
    isVisible: isClaimFilesVisible,
    open: openClaimFiles,
    close: closeClaimFiles,
  } = useDialogState();

  const reload = useCallback(() => {
    reloadData();
    reloadContract();
  }, [reloadData, reloadContract]);

  const { mutate: mutateUpdateClaim, isLoading: isLoadingUpdateClaim } =
    useUpdateClaim({
      claim,
      contract,
      onSuccess: () => {
        reload();
      },
    });

  const handleApproveClaim = useCallback(() => {
    mutateUpdateClaim({ status: ClaimStatus.Approved });
  }, [mutateUpdateClaim]);

  const handleRejectClaim = useCallback(() => {
    mutateUpdateClaim({ status: ClaimStatus.Rejected });
  }, [mutateUpdateClaim]);

  const handleWithdrawClaim = useCallback(() => {
    mutateUpdateClaim({ status: ClaimStatus.Withdrawn });
  }, [mutateUpdateClaim]);

  const handleClickBack = useCallback(() => {
    navigate(`${previousPath}`, { state: { tab: 'claims' } });
  }, [navigate, previousPath]);

  const canEdit =
    claim.status !== ClaimStatus.Approved &&
    claim.status !== ClaimStatus.Withdrawn &&
    claim.status !== ClaimStatus.Paid;

  return (
    <>
      <Box>
        <Stack gap={1} sx={{ mb: 3 }}>
          <Stack direction="row" justifyContent="space-between">
            <Stack direction="row" gap={2} alignItems="center">
              <Typography variant="h6" sx={{ fontSize: '20px' }}>
                {claim.description}
              </Typography>
              <ChipStatus
                status={claim.status}
                isOverdue={
                  dayjs(claim?.paymentDueAt) < projectDate(project?.systemDate)
                }
                isCustomer={false}
              />
            </Stack>
            <Stack direction="row" gap={3}>
              <Button variant="outlined" onClick={handleClickBack}>
                Back
              </Button>
              {claim.status === ClaimStatus.Submitted && (
                <LoadingButton
                  variant="outlined"
                  color="error"
                  sx={{ color: red700, borderColor: red700 }}
                  onClick={handleWithdrawClaim}
                >
                  Withdraw Submission
                </LoadingButton>
              )}
              <Button variant="outlined" onClick={openChat}>
                Comments
              </Button>
              <Button variant="outlined" onClick={openClaimFiles}>
                Attachments
              </Button>
              {claim.status === ClaimStatus.Awaiting && (
                <>
                  <LoadingButton
                    variant="outlined"
                    color="error"
                    sx={{ color: red700, borderColor: red700 }}
                    onClick={handleRejectClaim}
                    loading={isLoadingUpdateClaim}
                  >
                    Reject Schedule
                  </LoadingButton>
                  <LoadingButton
                    variant="contained"
                    loading={isLoadingUpdateClaim}
                    onClick={handleApproveClaim}
                  >
                    Accept Schedule
                  </LoadingButton>
                </>
              )}
            </Stack>
          </Stack>
          <Typography variant="body2" sx={{ fontSize: '14px' }}>
            {contract.contractorAccount.name}
          </Typography>
          <Typography variant="body2" sx={{ fontSize: '14px' }}>
            {contract.contractorAccount.id}
          </Typography>
        </Stack>
        <ClaimSummary
          claim={claim}
          contractPaymentTerm={contract.paymentTerm || 1}
          isCustomer={false}
          isContractor={true}
        />
      </Box>
      <TaskGroups
        canEdit={canEdit}
        contractId={contract.id}
        selectedTaskGroup={selectedTaskGroup}
        handleSelectedTaskGroup={handleSelectedTaskGroup}
        render={() => (
          <ClaimTable
            reloadData={reload}
            tasks={tasks}
            claim={claim}
            isContractor={isContractor}
          />
        )}
      />
      {isChatVisible && (
        <ClaimChat
          claim={claim}
          permissionToSend={canEdit}
          reloadData={reload}
          onClose={closeChat}
        />
      )}
      {isClaimFilesVisible && (
        <ClaimFileDrawer
          canUpload={canEdit}
          canDelete={false}
          reloadClaim={reload}
          claim={claim}
          open
          onClose={closeClaimFiles}
        />
      )}
    </>
  );
}

export { ClaimContractorView };
