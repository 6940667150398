import { AdvancedSetting } from './types';
import { AlertColor } from './ColorSelect';

const initialPaymentDefaultsCheckedState = {
  paymentToMeOverdue: true,
  paymentToContractorOverdue: true,
  contractorPaymentToSubcontractorOverdue: true,
  supplyChainSubcontractorOverdue: true,
};

const initialPaymentDefaultsAdvancedSettings: AdvancedSetting[] = [
  {
    name: 'paymentToMeOverdue',
    minimumValue: '',
    timeDelay: '',
    emailNotifications: true,
    alertColor: AlertColor.Red,
  },
  {
    name: 'paymentToContractorOverdue',
    minimumValue: '',
    timeDelay: '',
    emailNotifications: true,
    alertColor: AlertColor.Red,
  },
  {
    name: 'contractorPaymentToSubcontractorOverdue',
    minimumValue: '',
    timeDelay: '',
    emailNotifications: true,
    alertColor: AlertColor.Red,
  },
  {
    name: 'supplyChainSubcontractorOverdue',
    minimumValue: '',
    timeDelay: '',
    emailNotifications: true,
    alertColor: AlertColor.Red,
  },
];

const initialMyContractCheckedState = {
  contractorPaymentClaimReceived: true,
  myPaymentScheduleOverdue: true,
  myPaymentScheduleNearlyDue: false,
  myPaymentScheduleSubmitted: true,
  myPaymentScheduleAccepted: true,
  myPaymentScheduleRejected: true,
  contractorPaymentClaimWithdrawn: true,
  myPaymentMade: true,
  contractorVariationReceived: true,
  contractorVariationWithdrawn: true,
  contractorVariationAccepted: true,
  contractorVariationRejected: true,
  contractorComplianceDocumentNearingExpiry: false,
  contractorComplianceDocumentExpired: true,
};

const initialMyContractAdvancedSettings: AdvancedSetting[] = [
  {
    name: 'contractorPaymentClaimReceived',
    minimumValue: '',
    timeDelay: '',
    emailNotifications: false,
    alertColor: AlertColor.Green,
  },
  {
    name: 'myPaymentScheduleOverdue',
    minimumValue: '',
    timeDelay: '',
    emailNotifications: true,
    alertColor: AlertColor.Red,
  },
  {
    name: 'myPaymentScheduleNearlyDue',
    daysBeforeDeadline: 'Off',
    emailNotifications: false,
    alertColor: AlertColor.Yellow,
  },
  {
    name: 'myPaymentScheduleSubmitted',
    minimumValue: '',
    timeDelay: '',
    emailNotifications: false,
    alertColor: AlertColor.Green,
  },
  {
    name: 'myPaymentScheduleAccepted',
    minimumValue: '',
    timeDelay: '',
    emailNotifications: false,
    alertColor: AlertColor.Green,
  },
  {
    name: 'myPaymentScheduleRejected',
    minimumValue: '',
    timeDelay: '',
    emailNotifications: true,
    alertColor: AlertColor.Red,
  },
  {
    name: 'contractorPaymentClaimWithdrawn',
    minimumValue: '',
    timeDelay: '',
    emailNotifications: false,
    alertColor: AlertColor.Yellow,
  },
  {
    name: 'myPaymentMade',
    minimumValue: '',
    timeDelay: '',
    emailNotifications: false,
    alertColor: AlertColor.Green,
  },
  {
    name: 'contractorVariationReceived',
    minimumValue: '',
    timeDelay: '',
    emailNotifications: false,
    alertColor: AlertColor.Green,
  },
  {
    name: 'contractorVariationWithdrawn',
    minimumValue: '',
    timeDelay: '',
    emailNotifications: false,
    alertColor: AlertColor.Yellow,
  },
  {
    name: 'contractorVariationAccepted',
    minimumValue: '',
    timeDelay: '',
    emailNotifications: false,
    alertColor: AlertColor.Green,
  },
  {
    name: 'contractorVariationRejected',
    minimumValue: '',
    timeDelay: '',
    emailNotifications: false,
    alertColor: AlertColor.Red,
  },
  {
    name: 'contractorComplianceDocumentNearingExpiry',
    minimumValue: '',
    timeDelay: '',
    emailNotifications: true,
    alertColor: AlertColor.Yellow,
  },
  {
    name: 'contractorComplianceDocumentExpired',
    minimumValue: '',
    timeDelay: '',
    emailNotifications: true,
    alertColor: AlertColor.Red,
  },
];

const initialMySubContractCheckedState = {
  subContractorPaymentClaimReceived: true,
  subMyPaymentScheduleOverdue: true,
  subMyPaymentScheduleNearlyDue: false,
  subMyPaymentScheduleSubmitted: true,
  subMyPaymentScheduleAccepted: true,
  subMyPaymentScheduleRejected: true,
  subContractorPaymentClaimWithdrawn: true,
  subMyPaymentMade: true,
  subContractorVariationReceived: true,
  subContractorVariationWithdrawn: true,
  subContractorVariationAccepted: true,
  subContractorVariationRejected: true,
  subContractorComplianceDocumentNearingExpiry: false,
  subContractorComplianceDocumentExpired: true,
};

const initialMySubContractAdvancedSettings: AdvancedSetting[] = [
  {
    name: 'subContractorPaymentClaimReceived',
    minimumValue: '',
    timeDelay: '',
    emailNotifications: false,
    alertColor: AlertColor.Green,
  },
  {
    name: 'subMyPaymentScheduleOverdue',
    minimumValue: '',
    timeDelay: '',
    emailNotifications: true,
    alertColor: AlertColor.Red,
  },
  {
    name: 'subMyPaymentScheduleNearlyDue',
    daysBeforeDeadline: 'Off',
    emailNotifications: false,
    alertColor: AlertColor.Yellow,
  },
  {
    name: 'subMyPaymentScheduleSubmitted',
    minimumValue: '',
    timeDelay: '',
    emailNotifications: false,
    alertColor: AlertColor.Green,
  },
  {
    name: 'subMyPaymentScheduleAccepted',
    minimumValue: '',
    timeDelay: '',
    emailNotifications: false,
    alertColor: AlertColor.Green,
  },
  {
    name: 'subMyPaymentScheduleRejected',
    minimumValue: '',
    timeDelay: '',
    emailNotifications: true,
    alertColor: AlertColor.Red,
  },
  {
    name: 'subContractorPaymentClaimWithdrawn',
    minimumValue: '',
    timeDelay: '',
    emailNotifications: false,
    alertColor: AlertColor.Yellow,
  },
  {
    name: 'subMyPaymentMade',
    minimumValue: '',
    timeDelay: '',
    emailNotifications: false,
    alertColor: AlertColor.Green,
  },
  {
    name: 'subContractorVariationReceived',
    minimumValue: '',
    timeDelay: '',
    emailNotifications: false,
    alertColor: AlertColor.Green,
  },
  {
    name: 'subContractorVariationWithdrawn',
    minimumValue: '',
    timeDelay: '',
    emailNotifications: false,
    alertColor: AlertColor.Yellow,
  },
  {
    name: 'subContractorVariationAccepted',
    minimumValue: '',
    timeDelay: '',
    emailNotifications: false,
    alertColor: AlertColor.Green,
  },
  {
    name: 'subContractorVariationRejected',
    minimumValue: '',
    timeDelay: '',
    emailNotifications: false,
    alertColor: AlertColor.Red,
  },
  {
    name: 'subContractorComplianceDocumentNearingExpiry',
    minimumValue: '',
    timeDelay: '',
    emailNotifications: true,
    alertColor: AlertColor.Yellow,
  },
  {
    name: 'subContractorComplianceDocumentExpired',
    minimumValue: '',
    timeDelay: '',
    emailNotifications: true,
    alertColor: AlertColor.Red,
  },
];

export {
  initialPaymentDefaultsCheckedState,
  initialPaymentDefaultsAdvancedSettings,
  initialMyContractCheckedState,
  initialMyContractAdvancedSettings,
  initialMySubContractCheckedState,
  initialMySubContractAdvancedSettings,
};
