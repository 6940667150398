import React, { useState, useCallback, useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import {
  Box,
  CircularProgress,
  Divider,
  Drawer,
  Grid,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import {
  ClaimType,
  Contract,
  DOLLAR,
  getContract,
  listTasks,
  PERCENT,
  ResponseListBaseType,
  Task,
  TaskGroup,
} from '../../common/api';
import { useAuth } from '../../common/auth-provider/AuthProvider';
import { isContractor, isCustomer, isProjectOwner } from '../../common/access';
import { AddSubtaskDialog } from './components/AddSubtaskDialog';
import { SkeletonTable } from '../../components/skeleton-table';
import { SubTasksTable } from './components/SubTasksTable';
import { TodoList } from './components/TodoList';
import { ProgressBar } from '../../components/progress-bar';
import { ContractSummaryCard } from '../project-page/components/ContractSummaryCard';
import {
  formatAmountByType,
  formatAmountPercentInValue,
} from '../../common/format';
import { getVisibilitySetting } from '../contract-page/components/visibility/utils/visibilitySettings.utils';

interface Props {
  onClose: () => void;
  contract: Contract;
  isOpen: boolean;
  task: Task | null;
  selectTaskGroup?: TaskGroup;
}

function TaskDetails(props: Props) {
  const { onClose, isOpen, contract, task } = props;
  const { user } = useAuth();

  const [isOpenContractDialog, setIsOpenContractDialog] =
    useState<boolean>(false);
  const [displayContractDialog, setDisplayContractDialog] =
    useState<boolean>(false);
  const [
    isSub_subcontractorClaimItemsNameOnly,
    setIsSub_subcontractorClaimItemsNameOnly,
  ] = useState<boolean | undefined>(false);
  const [updatedTasks, setUpdatedTasks] = useState<Array<Task>>([]);

  const handleCloseContractDialog = useCallback(() => {
    setIsOpenContractDialog(false);
    setDisplayContractDialog(false);
  }, []);

  const {
    data: tasks,
    isLoading: isLoadingTasks,
    refetch: refetchTasks,
  } = useQuery<ResponseListBaseType<Array<Task>>>(
    ['tasks', '?parentTaskId', task?.id],
    () => {
      return listTasks(contract.id, { parentTaskId: task!.id });
    },
    {
      enabled: !!task?.id,
    }
  );

  const { data: mainContract } = useQuery<Contract>(
    ['contract', contract?.parentContractId],
    () => {
      return getContract(contract.parentContractId!);
    },
    {
      enabled: !!contract?.parentContractId,
    }
  );

  const meta = tasks && tasks.meta;

  const handleRefetchContracts = useCallback(async () => {
    await refetchTasks();
  }, [refetchTasks]);

  useEffect(() => {
    if (tasks?.data) {
      const updatedTasks = tasks.data.map((t, index) => ({
        ...t,
        identifier: `${task?.identifier}.${index + 1}`,
      }));

      setUpdatedTasks(updatedTasks);
    }
  }, [tasks, task?.identifier]);

  const lastTaskId = updatedTasks[updatedTasks.length - 1]?.identifier;

  const valuePreviouslyApproved = task
    ? Number(task.approved) + Number(task.paid)
    : 0;
  const isCanViewAmount =
    user && (isContractor(user, contract) || isCustomer(user, contract));

  useEffect(() => {
    if (mainContract) {
      const sub_subcontractorClaimItemsNameOnly = getVisibilitySetting(
        mainContract.visibilitySettings,
        'sub_subcontractorClaimItemsNameOnly'
      );

      if (isCustomer(user, mainContract)) {
        setIsSub_subcontractorClaimItemsNameOnly(
          !sub_subcontractorClaimItemsNameOnly
        );
      } else if (isContractor(user, contract)) {
        setIsSub_subcontractorClaimItemsNameOnly(true);
      }
    }
  }, [mainContract, user, contract]);

  return (
    <Drawer
      anchor={'right'}
      sx={{
        '& .MuiDrawer-paper': {
          marginTop: '0px',
          height: '100%',
          width: '30%',
          minWidth: '600px',
        },
      }}
      open={isOpen}
      onClose={onClose}
    >
      {task ? (
        <Box>
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              m: 1,
              color: theme => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <Divider />
          <Stack sx={{ p: 2, pt: 3 }}>
            <Typography sx={{ fontSize: '20px' }} variant={'h6'}>
              {task.identifier} {task.description}
            </Typography>
            <ProgressBar
              total={Number(task.value)}
              claimed={Number(task.claimed)}
              approved={Number(task.approved)}
              paid={Number(task.paid)}
              valueType={task.valueType}
              approvedVariation={Number(task.totalApprovedVariation)}
              unApprovedVariation={Number(task.totalUnapprovedVariation)}
            />
            {isSub_subcontractorClaimItemsNameOnly && (
              <Grid sx={styles.InfoGrid}>
                {isCanViewAmount ? (
                  <>
                    <ContractSummaryCard
                      title={'Task value'}
                      value={Number(task.value)}
                      isModifiedValue={Number(task.totalApprovedVariation) > 0}
                      originalValue={task.originalValue}
                    />
                    <ContractSummaryCard
                      title={'Approved Variations'}
                      value={Number(task.totalApprovedVariation)}
                    />
                    <ContractSummaryCard
                      title={'Previously approved'}
                      value={formatAmountPercentInValue(
                        valuePreviouslyApproved,
                        ClaimType.value,
                        Number(task.value)
                      )}
                    />
                    <ContractSummaryCard
                      title={'Currently claimed'}
                      value={formatAmountPercentInValue(
                        Number(task.claimed),
                        ClaimType.value,
                        Number(task.value)
                      )}
                    />
                  </>
                ) : (
                  <>
                    <ContractSummaryCard
                      title={'Value to Complete'}
                      value={formatAmountByType(Number(task.value), PERCENT)}
                    />
                    <ContractSummaryCard
                      title={'Previously approved'}
                      value={formatAmountByType(
                        Math.round(
                          (valuePreviouslyApproved / Number(task.value)) * 100
                        ),
                        PERCENT
                      )}
                    />
                    <ContractSummaryCard
                      title={'Currently claimed'}
                      value={formatAmountByType(Number(task.claimed), PERCENT)}
                    />
                  </>
                )}
              </Grid>
            )}
          </Stack>
          <Box sx={{ p: 2 }}>
            <TodoList taskId={task?.id || ''} />
            <Divider sx={{ mb: 2 }} />
            <Box sx={{ mb: 4 }}>
              <Stack
                sx={{ mb: 2 }}
                direction={'row'}
                justifyContent={'space-between'}
                alignItems={'center'}
              >
                <Typography variant={'body2'}>Sub-contracted tasks</Typography>
              </Stack>

              <Stack>
                {isSub_subcontractorClaimItemsNameOnly && (
                  <Grid sx={styles.InfoGrid}>
                    {isCanViewAmount && meta?.valueType === DOLLAR ? (
                      <ContractSummaryCard
                        title={'Sub Contracted Task Total'}
                        value={Number(meta?.totalValue || 0)}
                      />
                    ) : (
                      <ContractSummaryCard
                        title={'Sub Contracted Task Total'}
                        value={formatAmountByType(
                          Number(meta?.totalValue || 0),
                          PERCENT
                        )}
                      />
                    )}
                    {isCanViewAmount && meta?.valueType === DOLLAR ? (
                      <ContractSummaryCard
                        title={'Approved Variations'}
                        value={Number(meta?.variationTotalValue || 0)}
                      />
                    ) : (
                      <ContractSummaryCard
                        title={'Approved Variations'}
                        value={formatAmountByType(
                          Number(meta?.variationTotalValue || 0),
                          PERCENT
                        )}
                      />
                    )}
                    <ContractSummaryCard
                      title={'Approved Progress Total '}
                      value={`${Math.round(Number(meta?.complete) || 0)}%`}
                    />
                    {isCanViewAmount && meta?.valueType === DOLLAR ? (
                      <ContractSummaryCard
                        title={'Currently Claimed'}
                        value={Number(meta?.totalValueClaimed || 0)}
                      />
                    ) : (
                      <ContractSummaryCard
                        title={'Currently Claimed'}
                        value={formatAmountByType(
                          Number(meta?.totalValueClaimed || 0),
                          PERCENT
                        )}
                      />
                    )}
                  </Grid>
                )}
              </Stack>

              <Box sx={{ mb: 2 }}>
                {isLoadingTasks || !tasks ? (
                  <SkeletonTable rows={1} />
                ) : (
                  <SubTasksTable
                    tasks={updatedTasks}
                    reloadData={handleRefetchContracts}
                    closeSubcontractDialog={onClose}
                    contractId={contract.id}
                    isMainCustomer={
                      user ? isProjectOwner(user, contract) : false
                    }
                  />
                )}
              </Box>
            </Box>
          </Box>
        </Box>
      ) : (
        <CircularProgress />
      )}
      <AddSubtaskDialog
        onRefetchContracts={handleRefetchContracts}
        isOpen={isOpenContractDialog}
        display={displayContractDialog}
        parentTaskId={task?.id || ''}
        onClose={handleCloseContractDialog}
        projectId={contract.projectId}
        lastTaskId={lastTaskId}
      />
    </Drawer>
  );
}

const styles = {
  InfoGrid: {
    display: 'grid',
    mt: 2,
    gridTemplateColumns: 'repeat( auto-fit, minmax(100px, 1fr) )',
    gap: '0px',
    marginBottom: '24px',
  },
};

export { TaskDetails };
