export const white = '#FFFFFF';
export const black = '#000';

export const grey50 = '#F8FAFC';

export const grey100 = '#F1F5F9';
export const grey200 = '#E2E8F0';
export const grey300 = '#CBD5E1';
export const grey500 = '#64748B';
export const grey600 = '#475569';
export const grey800 = '#1E293B';
export const grey900 = '#0F172A';

export const brand100 = '#E8F7F4';
export const brand200 = '#f2fff2';
export const brand300 = '#A5E1D4';
export const brand400 = '#77D1BF';
export const brand500 = '#22C55E';
export const brand600 = '#1DB394';
export const brand700 = '#178F76';
export const brand800 = '#00725C';
export const brand900 = '#0C483B';
export const brand1000 = '#06241E';

export const orange50 = '#FEFAF2';
export const orange100 = '#FFEDD5';
export const orange200 = '#FED7AA';
export const orange400 = '#fb923c';
export const orange500 = '#f97316';
export const orange900 = '#7C2D12';

export const blue100 = '#DBEAFE';
export const blue200 = '#BFDBFE';

export const red50 = '#FEF2F2';
export const red700 = '#B91C1C';
export const red900 = '#7F1D1D';
export const red100 = '#FEE2E2';
export const red500 = '#EF4444';

export const yellow200 = '#FCE96A';
export const yellow400 = '#E3A008';
export const yellow600 = '#9F580A';
export const yellow500 = '#EAB308';
