import React, { FC, useMemo } from 'react';

import {
  HeadCell,
  useSortedTable,
} from '../../../../components/sorted-table-head/SortedTable.hooks';
import {
  DOLLAR,
  Notification,
  PERCENT,
  ResponseListBaseType,
  VariationListResource,
  VariationStatus,
} from '../../../../common/api';

import { anticipatedHeadCells } from './tableHeads';
import { createVariationRow } from '../../utils/variationTable.utils';
import { SkeletonTable } from '../../../../components/skeleton-table';
import { StyledCompactTable, StyledTableRow } from '../../../../styles';
import { SortedTableHead } from '../../../../components/sorted-table-head';
import { Box, TableBody, TableCell, Typography } from '@mui/material';
import { stableSort } from '../../../../common/sort/stableSort';
import { getComparator } from '../../../../common/sort/getComparator';
import { generatePath, useNavigate } from 'react-router-dom';
import {
  VARIATION_EXISTING,
  VARIATION_RECEIVED,
} from '../../../../common/router-util/routes';
import {
  formatAmountByType,
  formatPercentageValue,
} from '../../../../common/format';
import { Pagination } from '../../../../components/pagination';
import { useAuth } from '../../../../common/auth-provider/AuthProvider';
import { useListPagination } from '../../../../common/hooks/useListPagination';

interface Props {
  contingencyBudget?: string;
  contractId: string;
  data?: ResponseListBaseType<VariationListResource[]>;
  isMade?: boolean;
  notifications?: Array<Notification>;
  onClick?: (id: string) => void;
}

export const AnticipatedVariationsTable: FC<Props> = ({
  contingencyBudget = '0',
  contractId,
  data,
  isMade = false,
  notifications,
  onClick,
}) => {
  const navigate = useNavigate();
  const { user } = useAuth();

  const { page, rowsPerPage, handleChangePage } = useListPagination();

  const { order, orderBy, handleRequestSort } = useSortedTable(
    'variationIdentifier'
  );

  const variations = data?.data || [];
  // data?.data.filter(
  //   variation => variation.status !== VariationStatus.Approved
  // ) || [];

  const variationRows =
    variations.map(d =>
      createVariationRow(d, user?.accountId, notifications)
    ) || [];

  const updatedHeadCells: Array<HeadCell> = useMemo(() => {
    if (isMade) {
      return anticipatedHeadCells.filter(({ id }) => id !== 'totalContingency');
    }
    return anticipatedHeadCells;
  }, [isMade]);

  return (
    <Box>
      {!data ? (
        <SkeletonTable />
      ) : (
        <>
          <StyledCompactTable sx={{ mb: 2 }}>
            <SortedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              headCells={updatedHeadCells}
              data={variations}
            />
            <TableBody>
              {stableSort(variationRows, getComparator(order, orderBy)).map(
                row => (
                  <StyledTableRow
                    key={row.id}
                    hover
                    isClosed={row.isClosed}
                    rowStatus={row.status as VariationStatus}
                    onClick={() => {
                      if (onClick && row.notificationId) {
                        onClick(row.notificationId);
                      }
                      navigate(
                        generatePath(
                          isMade ? VARIATION_EXISTING : VARIATION_RECEIVED,
                          {
                            contractId,
                            variationId: row.id,
                          }
                        )
                      );
                    }}
                  >
                    <TableCell>
                      <Typography variant={'body2'}>
                        {row.anticipatedId}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant={'body1'}>
                        {row.subContractor}
                      </Typography>
                      <Typography variant={'caption'}>{row.caption}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant={'body2'}>
                        {row.description}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant={'body2'}>{row.createdAt}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant={'body2'}>
                        {row.isFinalised ? 'Y' : 'N'}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant={'body2'}>
                        {row.isFinalised ? '' : row.isSubmitted ? 'Y' : 'N'}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant={'body2'}>
                        {row.isFinalised
                          ? ''
                          : formatAmountByType(
                              Number(row.totalValue),
                              row.valueType === DOLLAR ? DOLLAR : PERCENT
                            )}
                      </Typography>
                    </TableCell>
                    {!isMade && (
                      <TableCell>
                        <Typography variant={'body2'}>
                          {row.isFinalised
                            ? ''
                            : formatPercentageValue(
                                Number(row.totalValue),
                                Number(contingencyBudget)
                              )}
                        </Typography>
                      </TableCell>
                    )}
                    <TableCell>
                      <Typography variant={'body2'}>
                        {row.identifier !== '0' ? `SV# ${row.identifier}` : ''}
                      </Typography>
                    </TableCell>
                  </StyledTableRow>
                )
              )}
            </TableBody>
          </StyledCompactTable>
          <Pagination
            count={Math.ceil((data?.meta.total || 0) / rowsPerPage)}
            handleChangePage={handleChangePage}
            page={page}
            total={data?.meta?.total || 0}
          />
        </>
      )}
    </Box>
  );
};
