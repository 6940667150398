import React, { useState } from 'react';
import { AuthResponse, User } from '../api';
import {
  clearAuthResponse,
  loadAuthResponse,
  saveAuthResponse,
} from './AuthResponseStore';

interface AuthContextType {
  isLoggedIn: () => boolean;
  getUser: () => User | undefined;
  onLogin: (authResponse: AuthResponse) => void;
  logout: () => void;
  authResponse: AuthResponse | undefined;
  user: User | undefined;
}

let AuthContext = React.createContext<AuthContextType>(null!);

function AuthProvider({ children }: { children: React.ReactNode }) {
  let [authResponse, setAuthResponse] = useState<AuthResponse | undefined>(
    loadAuthResponse()
  );

  let [user, setUser] = useState<User | undefined>(authResponse?.user);

  const onLogin = (authResponse: AuthResponse) => {
    setAuthResponse(authResponse);
    setUser(authResponse.user);
    saveAuthResponse(authResponse);
  };

  const logout = () => {
    setAuthResponse(undefined);
    setUser(undefined);
    clearAuthResponse();
  };

  const getUser = (): User | undefined => {
    return authResponse?.user;
  };

  const isLoggedIn = (): boolean => {
    return !!authResponse;
  };

  let value = { onLogin, logout, getUser, isLoggedIn, authResponse, user };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

function useAuth() {
  return React.useContext(AuthContext);
}

export { useAuth, AuthProvider };
