import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  Divider,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { VisibilityRow, VisibilityTable } from '../VisibilitySettings.style';
import { updateContractVisibility } from '../../../../../common/api/visibilitySettings';
import {
  ContractStatus,
  VisibilitySetting,
  VisibilityStatus,
} from '../../../../../common/api';
import {
  ICheckbox,
  row1,
  row2,
  row3,
  row4,
  row5,
  row6,
  row7,
  row8,
} from './checkboxesData';
import {
  calculateMaxSelectedLevel,
  findHighestLevelParent,
  isChecked,
} from '../utils/visibilitySettings.utils';

interface Props {
  isDisabled: boolean;
  contractId: string;
  visibilitySettings: VisibilitySetting[];
  isEdit: boolean;
  contractStatus: ContractStatus;
  handleClose: () => void;
  reloadData: () => void;
  onEditSave?: (updatedSettings: VisibilitySetting[]) => void;
}

function MainContractVisibilitySettings({
  isDisabled,
  contractId,
  visibilitySettings,
  isEdit,
  contractStatus,
  handleClose,
  reloadData,
  onEditSave,
}: Props) {
  const [checkboxStates, setCheckboxStates] =
    useState<VisibilitySetting[]>(visibilitySettings);
  const [maxSelectedLevelRow3, setMaxSelectedLevelRow3] = useState<
    number | null
  >(null);
  const [maxSelectedLevelRow7, setMaxSelectedLevelRow7] = useState<
    number | null
  >(null);

  useEffect(() => {
    const initialSettings = visibilitySettings.map(setting => ({ ...setting }));
    setCheckboxStates(initialSettings);
  }, [visibilitySettings]);

  const handleCheckboxClick = (
    name: string | undefined,
    dataRange?: Array<ICheckbox>
  ) => {
    setCheckboxStates(prevState => {
      let newState = prevState.map(setting => {
        if (setting.name === name) {
          const newIsOn = !setting.isOn;
          return { ...setting, isOn: newIsOn };
        }
        return setting;
      });

      if (dataRange) {
        const parentSetting = dataRange.find(parent => parent.name === name);

        if (parentSetting && parentSetting.children) {
          parentSetting.children.forEach((child: any) => {
            const childIndex = newState.findIndex(s => s.name === child.name);
            if (childIndex !== -1) {
              newState[childIndex] = { ...newState[childIndex], isOn: false };
            }
          });
        }
      }

      return newState;
    });
  };

  const handleTableCheckboxClick = (
    name: string | undefined,
    dataRange: Array<ICheckbox>
  ) => {
    setCheckboxStates(prevState => {
      return prevState.map((setting: any) => {
        if (setting.name === name) {
          const newIsOn = !setting.isOn;
          let updatedSetting = { ...setting, isOn: newIsOn };

          const parentSetting = dataRange.find(parent => parent.name === name);

          if (isReplicateAllSettings && parentSetting?.child) {
            const child = prevState.find(
              s => s.name === parentSetting.child?.name
            );
            if (child) {
              child.isOn = newIsOn;
              updatedSetting = { ...updatedSetting, child };
            }
          }

          return updatedSetting;
        }

        return setting;
      });
    });
  };

  const handleLock = (
    name: string | undefined,
    dataRange: Array<ICheckbox>
  ) => {
    setCheckboxStates(prevState => {
      return prevState.map(setting => {
        if (setting.name === name) {
          const newIsOn = !setting.isOn;
          let updatedSetting = { ...setting, isOn: newIsOn };

          const parentSetting = dataRange.find(parent => parent.name === name);

          if (parentSetting?.child && (isReplicateAllSettings || !newIsOn)) {
            const child = prevState.find(
              s => s.name === parentSetting.child?.name
            );
            if (child) {
              child.isOn = newIsOn;
              // @ts-ignore
              updatedSetting = { ...updatedSetting, child };
            }
          }

          return updatedSetting;
        }

        return setting;
      });
    });
  };

  const handleSave = async () => {
    handleClose();
    if (isEdit && onEditSave) {
      const updatedCheckboxStates = checkboxStates.map(item => {
        const correspondingItem = visibilitySettings.find(
          vItem => vItem.id === item.id
        );

        if (correspondingItem && item.isOn !== correspondingItem.isOn) {
          return { ...item, status: VisibilityStatus.Modified };
        }

        return { ...item, status: VisibilityStatus.Unchanged };
      });

      setCheckboxStates([...updatedCheckboxStates]);
      onEditSave(updatedCheckboxStates);
      return;
    }

    await updateContractVisibility(contractId, checkboxStates);
    reloadData();
  };

  const handleReset = () => {
    const initialSettings = visibilitySettings.map(setting => ({ ...setting }));
    setCheckboxStates(initialSettings);
  };

  const isShowAnticipatedVariation = isChecked(
    'viewContractorsAnticipatedVariationItems',
    checkboxStates
  );
  const isReplicateAllSettings = isChecked(
    'replicateAllSettings',
    checkboxStates
  );

  const isDisabledChild = (childLevel: number, dataRange: Array<ICheckbox>) => {
    if (isDisabled) {
      return true;
    }
    const parentLevel = findHighestLevelParent(checkboxStates, dataRange);
    return childLevel > parentLevel;
  };

  const isDisabledChildLock = (
    name: string | undefined,
    dataRange: Array<ICheckbox>
  ) => {
    if (isDisabled) {
      return true;
    }

    const firstItemName = dataRange[0].name;
    const firstItemIsOn = !!checkboxStates.find(c => c.name === firstItemName)
      ?.isOn;
    if (firstItemIsOn) {
      return true;
    }

    const checkbox = dataRange.find(checkbox => checkbox.name === name);
    return !isChecked(checkbox?.name, checkboxStates);
  };

  const isDisabledLock = (dataRange: Array<ICheckbox>) => {
    if (isDisabled) {
      return true;
    }
    const firstItemName = dataRange[0].name;
    return !!checkboxStates.find(c => c.name === firstItemName)?.isOn;
  };

  const isDisabledRow7 = (name: string, dataRange: Array<ICheckbox>) => {
    if (isDisabled) {
      return true;
    }

    const names = dataRange
      .filter(
        checkbox =>
          checkbox.level !== undefined &&
          checkbox.level > 0 &&
          checkbox.level < 3
      )
      .map(checkbox => checkbox.name);

    return (
      isChecked('sub_subcontractorWBSValues', checkboxStates) &&
      names.includes(name)
    );
  };

  const updateCheckboxStates = (
    rows: ICheckbox[],
    maxLevel: number,
    checkboxStates: VisibilitySetting[]
  ) => {
    return checkboxStates.map(state => {
      const parentCheckbox = rows.find(
        checkbox => checkbox.child && checkbox.child.name === state.name
      );
      if (
        parentCheckbox &&
        parentCheckbox.child?.level &&
        parentCheckbox.child.level > maxLevel
      ) {
        return { ...state, isOn: false };
      }
      return state;
    });
  };

  useEffect(() => {
    const newMaxSelectedLevelRow3 = calculateMaxSelectedLevel(
      row3,
      checkboxStates
    );
    if (newMaxSelectedLevelRow3 !== maxSelectedLevelRow3) {
      setMaxSelectedLevelRow3(newMaxSelectedLevelRow3);
    }
  }, [checkboxStates, maxSelectedLevelRow3]);

  useEffect(() => {
    const newMaxSelectedLevelRow7 = calculateMaxSelectedLevel(
      row7,
      checkboxStates
    );
    if (newMaxSelectedLevelRow7 !== maxSelectedLevelRow7) {
      setMaxSelectedLevelRow7(newMaxSelectedLevelRow7);
    }
  }, [checkboxStates, maxSelectedLevelRow7]);

  useEffect(() => {
    let updatedStates = [...checkboxStates];

    if (typeof maxSelectedLevelRow3 === 'number') {
      updatedStates = updateCheckboxStates(
        row3,
        maxSelectedLevelRow3,
        updatedStates
      );
    }

    if (typeof maxSelectedLevelRow7 === 'number') {
      updatedStates = updateCheckboxStates(
        row7,
        maxSelectedLevelRow7,
        updatedStates
      );
    }

    const hasChanged = updatedStates.some(
      (state, index) => state.isOn !== checkboxStates[index].isOn
    );

    if (hasChanged) {
      setCheckboxStates(updatedStates);
    }
  }, [maxSelectedLevelRow3, maxSelectedLevelRow7, checkboxStates]);

  useEffect(() => {
    const isLevel1InRow3Selected = isChecked(
      'sub_subcontractorOrgInfo',
      checkboxStates
    );
    const isLevel1InRow7Selected = isChecked(
      'subcontractorNotPaid',
      checkboxStates
    );

    if (isLevel1InRow3Selected) {
      const needsUpdate = checkboxStates.some(
        state =>
          (state.name === 'sub_lockRow3' || state.name === 'subsub_lockRow3') &&
          !state.isOn
      );

      if (needsUpdate) {
        const updatedCheckboxStates = checkboxStates.map(state => {
          if (
            state.name === 'sub_lockRow3' ||
            state.name === 'subsub_lockRow3'
          ) {
            return { ...state, isOn: true };
          }

          return state;
        });

        setCheckboxStates(updatedCheckboxStates);
      }
    }

    if (isLevel1InRow7Selected) {
      const needsUpdate = checkboxStates.some(
        state =>
          (state.name === 'sub_lockRow7' || state.name === 'subsub_lockRow7') &&
          !state.isOn
      );

      if (needsUpdate) {
        const updatedCheckboxStates = checkboxStates.map(state => {
          if (
            state.name === 'sub_lockRow7' ||
            state.name === 'subsub_lockRow7'
          ) {
            return { ...state, isOn: true };
          }

          return state;
        });

        setCheckboxStates(updatedCheckboxStates);
      }
    }
  }, [checkboxStates]);

  useEffect(() => {
    const level5InRow3 = row3.find(c => c.level === 5);
    const row3State = checkboxStates.find(
      state => state.name === level5InRow3?.name
    );

    if (row3State?.isOn) {
      const updatedCheckboxStates = checkboxStates.map(state => {
        if (
          state.name === 'contractorExactWBSValues' ||
          state.name === 'subsub_contractorExactWBSValues'
        ) {
          return { ...state, isOn: true };
        }

        return state;
      });

      const hasChanged = updatedCheckboxStates.some(
        (state, index) => state.isOn !== checkboxStates[index].isOn
      );

      if (hasChanged) {
        setCheckboxStates(updatedCheckboxStates);
      }
    }
  }, [checkboxStates]);

  const allRows = useMemo(
    () => [
      ...row1,
      ...row2,
      ...row3,
      ...row4,
      ...row5,
      ...row6,
      ...row7,
      ...row8,
    ],
    []
  );

  const updateChildrenCheckboxStates = useCallback(
    (
      conditions: { condition: boolean; childIndex: number }[],
      checkboxStates: VisibilitySetting[],
      setCheckboxStates: React.Dispatch<
        React.SetStateAction<VisibilitySetting[]>
      >
    ) => {
      const names = conditions.flatMap(({ condition, childIndex }) =>
        condition
          ? allRows
              .filter(
                checkbox =>
                  checkbox.children && checkbox.children.length > childIndex
              )
              .map(parent => parent.children![childIndex].name)
          : []
      );

      if (names.length > 0) {
        const updatedCheckboxStates = checkboxStates.map(state => {
          if (names.includes(state.name)) {
            return { ...state, isOn: true };
          }
          return state;
        });

        const hasChanged = updatedCheckboxStates.some(
          (state, index) => state.isOn !== checkboxStates[index].isOn
        );

        if (hasChanged) {
          setCheckboxStates(updatedCheckboxStates);
        }
      }
    },
    [allRows]
  );

  useEffect(() => {
    const conditions = [
      {
        condition: isChecked('subcontractorSettingsLocked', checkboxStates),
        childIndex: 0,
      },
      {
        condition: isChecked('replicateAllSettings', checkboxStates),
        childIndex: 1,
      },
      {
        condition: isChecked(
          'subcontractorSettingsBelowLocked',
          checkboxStates
        ),
        childIndex: 2,
      },
    ];

    updateChildrenCheckboxStates(conditions, checkboxStates, setCheckboxStates);
  }, [checkboxStates, setCheckboxStates, updateChildrenCheckboxStates]);

  const isCheckboxModified = (checkboxName?: string) => {
    if (contractStatus !== ContractStatus.AwaitingAcceptance) {
      return false;
    }
    return (
      checkboxStates.find(c => c.name === checkboxName)?.status ===
      VisibilityStatus.Modified
    );
  };

  return (
    <Box sx={{ width: '100%', p: 3 }}>
      <Typography fontSize={26} fontWeight={500}>
        Current Contract
      </Typography>

      {/*ROW 1*/}
      <Divider sx={{ mb: 2 }} />
      <Typography marginBottom={'4px'}>
        This contract information visibility
      </Typography>
      <VisibilityRow>
        <TableBody>
          {row1.map(checkbox => (
            <TableRow
              key={checkbox.id}
              sx={{
                border: isCheckboxModified(checkbox.name)
                  ? '1px solid red'
                  : '',
              }}
            >
              <TableCell sx={{ width: '40px' }}>
                <Checkbox
                  checked={isChecked(checkbox.name, checkboxStates)}
                  disabled={isDisabled}
                  onChange={() => handleCheckboxClick(checkbox.name)}
                />
              </TableCell>
              <TableCell>{checkbox.label}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </VisibilityRow>

      <Typography fontSize={26} fontWeight={500} mt={2}>
        Subcontract Settings to be Prescribed
      </Typography>

      {/*ROW 2*/}
      <Divider sx={{ mb: 2 }} />
      <VisibilityRow>
        <TableBody>
          {row2.map(checkbox => (
            <TableRow
              key={checkbox.id}
              sx={{
                border: isCheckboxModified(checkbox.name)
                  ? '1px solid red'
                  : '',
              }}
            >
              <TableCell>
                <Checkbox
                  checked={isChecked(checkbox.name, checkboxStates)}
                  disabled={isDisabled}
                  onChange={() => handleCheckboxClick(checkbox.name)}
                />
              </TableCell>
              <TableCell>{checkbox.label}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </VisibilityRow>

      {/*ROW 3*/}
      <Divider sx={{ mb: 2, mt: 2 }} />
      <Typography marginBottom={'4px'}>
        Subcontractor Information Visibility
      </Typography>
      <VisibilityTable>
        <TableHead>
          <TableRow>
            <TableCell>Description</TableCell>
            <TableCell>Level</TableCell>
            <TableCell>Sub</TableCell>
            <TableCell>Subsub</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {row3.slice(0, -1).map((checkbox, index) => {
            const childLevel: number = checkbox.child
              ? checkbox.child.level ?? 0
              : 0;
            return (
              <TableRow key={checkbox.id}>
                <TableCell style={{ paddingLeft: '16px' }}>
                  {checkbox.label}
                </TableCell>
                <TableCell align="center">{checkbox.levelLabel}</TableCell>
                <TableCell
                  sx={{
                    border: isCheckboxModified(checkbox.name)
                      ? '1.5px solid red'
                      : '',
                  }}
                >
                  <Checkbox
                    checked={isChecked(checkbox.name, checkboxStates)}
                    disabled={isDisabled}
                    onChange={() =>
                      handleTableCheckboxClick(checkbox.name, row3)
                    }
                  />
                </TableCell>
                <TableCell
                  sx={{
                    border: isCheckboxModified(checkbox.child?.name)
                      ? '1.5px solid red'
                      : '',
                  }}
                >
                  <Checkbox
                    checked={isChecked(checkbox.child?.name, checkboxStates)}
                    disabled={isDisabledChild(childLevel, row3)}
                    onChange={() => handleCheckboxClick(checkbox.child?.name)}
                  />
                </TableCell>
              </TableRow>
            );
          })}
          {row3.slice(-1).map(checkbox => {
            return (
              <TableRow key={checkbox.id}>
                <TableCell></TableCell>
                <TableCell align="center">{checkbox.levelLabel}</TableCell>
                <TableCell
                  sx={{
                    border: isCheckboxModified(checkbox.name)
                      ? '1.5px solid red'
                      : '',
                  }}
                >
                  <Checkbox
                    checked={isChecked(checkbox.name, checkboxStates)}
                    disabled={isDisabledLock(row3)}
                    onChange={() => handleLock(checkbox.name, row3)}
                  />
                </TableCell>
                <TableCell
                  sx={{
                    border: isCheckboxModified(checkbox.child?.name)
                      ? '1.5px solid red'
                      : '',
                  }}
                >
                  <Checkbox
                    checked={isChecked(checkbox.child?.name, checkboxStates)}
                    disabled={isDisabledChildLock(checkbox.name, row3)}
                    onChange={() => handleCheckboxClick(checkbox.child?.name)}
                  />
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </VisibilityTable>

      {/*ROW 4*/}
      <Divider sx={{ mb: 3 }} />
      <VisibilityRow>
        <TableBody>
          <TableRow
            sx={{
              border: isCheckboxModified('parentSubcontractorClaimsMade')
                ? '1px solid red'
                : '',
            }}
          >
            <TableCell>
              <Checkbox
                checked={isChecked(
                  'parentSubcontractorClaimsMade',
                  checkboxStates
                )}
                disabled={isDisabled}
                onChange={() =>
                  handleCheckboxClick('parentSubcontractorClaimsMade', row4)
                }
              />
            </TableCell>
            <TableCell>Subcontractor Claims made</TableCell>
          </TableRow>
          {isChecked('parentSubcontractorClaimsMade', checkboxStates) && (
            <>
              {row4
                .find(
                  checkbox => checkbox.name === 'parentSubcontractorClaimsMade'
                )
                ?.children?.slice(0, 2)
                .map(child => (
                  <TableRow
                    key={child.id}
                    sx={{
                      border: isCheckboxModified(child.name)
                        ? '1px solid red'
                        : '',
                    }}
                  >
                    <TableCell>
                      <Checkbox
                        checked={isChecked(child.name, checkboxStates)}
                        disabled={isDisabled}
                        onChange={() => handleCheckboxClick(child.name)}
                      />
                    </TableCell>
                    <TableCell sx={{ fontSize: '0.85rem' }}>
                      {child.label}
                    </TableCell>
                  </TableRow>
                ))}
              {isChecked('claims_lockedToSubcontractor', checkboxStates) &&
                isChecked(
                  'claims_replicatedToSubcontractorBelow',
                  checkboxStates
                ) &&
                row4[0]?.children && (
                  <TableRow
                    key={row4[0].children[2].id}
                    sx={{
                      border: isCheckboxModified(row4[0].children[2].name)
                        ? '1px solid red'
                        : '',
                    }}
                  >
                    <TableCell>
                      <Checkbox
                        checked={isChecked(
                          'claims_lockedToSubcontractorBelow',
                          checkboxStates
                        )}
                        disabled={isDisabled}
                        onChange={() =>
                          handleCheckboxClick(
                            'claims_lockedToSubcontractorBelow'
                          )
                        }
                      />
                    </TableCell>
                    <TableCell sx={{ fontSize: '0.85rem' }}>
                      {row4[0].children[2].label}
                    </TableCell>
                  </TableRow>
                )}
            </>
          )}
        </TableBody>
      </VisibilityRow>

      {/*ROW 5*/}
      <Divider sx={{ mb: 2, mt: 2 }} />
      <Typography marginBottom={'4px'}>Variation Made Information</Typography>
      <VisibilityRow>
        <TableBody>
          {row5.slice(0, -1).map(parent => (
            <React.Fragment key={parent.id}>
              <TableRow
                sx={{
                  border: isCheckboxModified(parent.name)
                    ? '1px solid red'
                    : '',
                }}
              >
                <TableCell>
                  <Checkbox
                    checked={isChecked(parent.name, checkboxStates)}
                    disabled={isDisabled}
                    onChange={() => handleCheckboxClick(parent.name, row5)}
                  />
                </TableCell>
                <TableCell>{parent.label}</TableCell>
              </TableRow>
              {isChecked(parent.name, checkboxStates) &&
                parent.children?.length && (
                  <>
                    {parent.children.slice(0, 2).map(child => (
                      <TableRow
                        key={child.id}
                        sx={{
                          border: isCheckboxModified(child.name)
                            ? '1px solid red'
                            : '',
                        }}
                      >
                        <TableCell>
                          <Checkbox
                            checked={isChecked(child.name, checkboxStates)}
                            disabled={isDisabled}
                            onChange={() => handleCheckboxClick(child.name)}
                          />
                        </TableCell>
                        <TableCell sx={{ fontSize: '0.85rem' }}>
                          {child.label}
                        </TableCell>
                      </TableRow>
                    ))}
                    {isChecked(parent.children[0]?.name, checkboxStates) &&
                      isChecked(parent.children[1]?.name, checkboxStates) && (
                        <TableRow
                          key={parent.children[2]?.id}
                          sx={{
                            border: isCheckboxModified(parent.children[2].name)
                              ? '1px solid red'
                              : '',
                          }}
                        >
                          <TableCell>
                            <Checkbox
                              checked={isChecked(
                                parent.children[2]?.name,
                                checkboxStates
                              )}
                              disabled={isDisabled}
                              onChange={() =>
                                handleCheckboxClick(parent.children?.[2]?.name)
                              }
                            />
                          </TableCell>
                          <TableCell sx={{ fontSize: '0.85rem' }}>
                            {parent.children[2]?.label}
                          </TableCell>
                        </TableRow>
                      )}
                  </>
                )}
            </React.Fragment>
          ))}
          {isShowAnticipatedVariation &&
            row5
              .filter(data => [17].includes(data.id))
              .map(parent => (
                <React.Fragment key={parent.id}>
                  <TableRow
                    sx={{
                      border: isCheckboxModified(parent.name)
                        ? '1px solid red'
                        : '',
                    }}
                  >
                    <TableCell>
                      <Checkbox
                        checked={isChecked(parent.name, checkboxStates)}
                        disabled={isDisabled}
                        onChange={() => handleCheckboxClick(parent.name, row5)}
                      />
                    </TableCell>
                    <TableCell>{parent.label}</TableCell>
                  </TableRow>
                  {isChecked(parent.name, checkboxStates) &&
                    parent.children?.length && (
                      <>
                        {parent.children.slice(0, 2).map(child => (
                          <TableRow
                            key={child.id}
                            sx={{
                              border: isCheckboxModified(child.name)
                                ? '1px solid red'
                                : '',
                            }}
                          >
                            <TableCell>
                              <Checkbox
                                checked={isChecked(child.name, checkboxStates)}
                                disabled={isDisabled}
                                onChange={() => handleCheckboxClick(child.name)}
                              />
                            </TableCell>
                            <TableCell sx={{ fontSize: '0.85rem' }}>
                              {child.label}
                            </TableCell>
                          </TableRow>
                        ))}
                        {isChecked(parent.children[0]?.name, checkboxStates) &&
                          isChecked(
                            parent.children[1]?.name,
                            checkboxStates
                          ) && (
                            <TableRow
                              key={parent.children[2]?.id}
                              sx={{
                                border: isCheckboxModified(
                                  parent.children[2].name
                                )
                                  ? '1px solid red'
                                  : '',
                              }}
                            >
                              <TableCell>
                                <Checkbox
                                  checked={isChecked(
                                    parent.children[2]?.name,
                                    checkboxStates
                                  )}
                                  disabled={isDisabled}
                                  onChange={() =>
                                    handleCheckboxClick(
                                      parent.children?.[2]?.name
                                    )
                                  }
                                />
                              </TableCell>
                              <TableCell sx={{ fontSize: '0.85rem' }}>
                                {parent.children[2]?.label}
                              </TableCell>
                            </TableRow>
                          )}
                      </>
                    )}
                </React.Fragment>
              ))}
        </TableBody>
      </VisibilityRow>

      {/*ROW 6*/}
      <Divider sx={{ mb: 2, mt: 2 }} />
      <VisibilityRow>
        <TableBody>
          {row6.map(parent => (
            <React.Fragment key={parent.id}>
              <TableRow
                sx={{
                  border: isCheckboxModified(parent.name)
                    ? '1px solid red'
                    : '',
                }}
              >
                <TableCell>
                  <Checkbox
                    checked={isChecked(parent.name, checkboxStates)}
                    disabled={isDisabled}
                    onChange={() => handleCheckboxClick(parent.name, row6)}
                  />
                </TableCell>
                <TableCell>{parent.label}</TableCell>
              </TableRow>
              {isChecked(parent.name, checkboxStates) && parent.children && (
                <>
                  {parent.children.slice(0, 2).map(child => (
                    <TableRow
                      key={child.id}
                      sx={{
                        border: isCheckboxModified(child.name)
                          ? '1px solid red'
                          : '',
                      }}
                    >
                      <TableCell>
                        <Checkbox
                          checked={isChecked(child.name, checkboxStates)}
                          disabled={isDisabled}
                          onChange={() => handleCheckboxClick(child.name)}
                        />
                      </TableCell>
                      <TableCell sx={{ fontSize: '0.85rem' }}>
                        {child.label}
                      </TableCell>
                    </TableRow>
                  ))}
                  {isChecked(parent.children[0].name, checkboxStates) &&
                    isChecked(parent.children[1].name, checkboxStates) &&
                    parent.children[2] && (
                      <TableRow
                        key={parent.children[2].id}
                        sx={{
                          border: isCheckboxModified(parent.children[2].name)
                            ? '1px solid red'
                            : '',
                        }}
                      >
                        <TableCell>
                          <Checkbox
                            checked={isChecked(
                              parent.children[2].name,
                              checkboxStates
                            )}
                            disabled={isDisabled}
                            onChange={() =>
                              handleCheckboxClick(parent.children?.[2].name)
                            }
                          />
                        </TableCell>
                        <TableCell sx={{ fontSize: '0.85rem' }}>
                          {parent.children[2].label}
                        </TableCell>
                      </TableRow>
                    )}
                </>
              )}
            </React.Fragment>
          ))}
        </TableBody>
      </VisibilityRow>

      {/*ROW 7*/}
      <Divider sx={{ mb: 2, mt: 2 }} />
      <Typography marginBottom={'4px'}>
        Payment Defaults to subcontractors - Alert info
      </Typography>
      <VisibilityTable>
        <TableHead>
          <TableRow>
            <TableCell>Description</TableCell>
            <TableCell align="center">Level</TableCell>
            <TableCell align="center">Sub</TableCell>
            <TableCell align="center">Subsub</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {row7.slice(0, -1).map(checkbox => {
            const childLevel: number = checkbox.child
              ? checkbox.child.level ?? 0
              : 0;
            return (
              <TableRow key={checkbox.id}>
                <TableCell style={{ paddingLeft: '16px' }}>
                  {checkbox.label}
                </TableCell>
                <TableCell align="center">{checkbox.level}</TableCell>
                <TableCell
                  align="center"
                  sx={{
                    border: isCheckboxModified(checkbox.name)
                      ? '1.5px solid red'
                      : '',
                  }}
                >
                  <Checkbox
                    checked={isChecked(checkbox.name, checkboxStates)}
                    disabled={isDisabledRow7(checkbox.name, row7)}
                    onChange={() =>
                      handleTableCheckboxClick(checkbox.name, row7)
                    }
                  />
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    border: isCheckboxModified(checkbox.child?.name)
                      ? '1.5px solid red'
                      : '',
                  }}
                >
                  <Checkbox
                    checked={isChecked(checkbox.child?.name, checkboxStates)}
                    disabled={
                      isDisabledChild(childLevel, row7) ||
                      isDisabledRow7(checkbox.name, row7)
                    }
                    onChange={() => handleCheckboxClick(checkbox.child?.name)}
                  />
                </TableCell>
              </TableRow>
            );
          })}
          {row7.slice(-1).map(checkbox => {
            return (
              <TableRow key={checkbox.id}>
                <TableCell></TableCell>
                <TableCell align={'center'}>{checkbox.levelLabel}</TableCell>
                <TableCell
                  sx={{
                    border: isCheckboxModified(checkbox.name)
                      ? '1.5px solid red'
                      : '',
                  }}
                  align={'center'}
                >
                  <Checkbox
                    checked={isChecked(checkbox.name, checkboxStates)}
                    disabled={isDisabledLock(row7)}
                    onChange={() => handleLock(checkbox.name, row7)}
                  />
                </TableCell>
                <TableCell
                  sx={{
                    border: isCheckboxModified(checkbox.child?.name)
                      ? '1.5px solid red'
                      : '',
                  }}
                  align={'center'}
                >
                  <Checkbox
                    checked={isChecked(checkbox.child?.name, checkboxStates)}
                    disabled={isDisabledChildLock(checkbox.name, row7)}
                    onChange={() => handleCheckboxClick(checkbox.child?.name)}
                  />
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </VisibilityTable>

      {/*ROW 8*/}
      <Divider sx={{ mb: 3 }} />
      <VisibilityRow>
        <TableBody>
          {row8.map(parent => (
            <React.Fragment key={parent.id}>
              <TableRow
                sx={{
                  border: isCheckboxModified(parent.name)
                    ? '1px solid red'
                    : '',
                }}
              >
                <TableCell>
                  <Checkbox
                    checked={isChecked(parent.name, checkboxStates)}
                    disabled={isDisabled}
                    onChange={() => handleCheckboxClick(parent.name, row8)}
                  />
                </TableCell>
                <TableCell>{parent.label}</TableCell>
              </TableRow>
              {isChecked(parent.name, checkboxStates) && parent.children && (
                <>
                  {parent.children.slice(0, 2).map(child => (
                    <TableRow
                      key={child.id}
                      sx={{
                        border: isCheckboxModified(child.name)
                          ? '1px solid red'
                          : '',
                      }}
                    >
                      <TableCell>
                        <Checkbox
                          checked={isChecked(child.name, checkboxStates)}
                          disabled={isDisabled}
                          onChange={() => handleCheckboxClick(child.name)}
                        />
                      </TableCell>
                      <TableCell sx={{ fontSize: '0.85rem' }}>
                        {child.label}
                      </TableCell>
                    </TableRow>
                  ))}
                  {isChecked(parent.children[0].name, checkboxStates) &&
                    isChecked(parent.children[1].name, checkboxStates) &&
                    parent.children[2] && (
                      <TableRow
                        key={parent.children[2].id}
                        sx={{
                          border: isCheckboxModified(parent.children[2].name)
                            ? '1px solid red'
                            : '',
                        }}
                      >
                        <TableCell>
                          <Checkbox
                            checked={isChecked(
                              parent.children[2].name,
                              checkboxStates
                            )}
                            disabled={isDisabled}
                            onChange={() =>
                              handleCheckboxClick(parent.children?.[2]?.name)
                            }
                          />
                        </TableCell>
                        <TableCell sx={{ fontSize: '0.85rem' }}>
                          {parent.children[2].label}
                        </TableCell>
                      </TableRow>
                    )}
                </>
              )}
            </React.Fragment>
          ))}
        </TableBody>
      </VisibilityRow>

      {/*ROW 9*/}
      <Divider sx={{ mt: 3, mb: 3 }} />
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Button
          onClick={handleSave}
          sx={{
            m: 2,
            borderColor: 'success.main',
            color: 'success.main',
            '&:hover': {
              borderColor: 'success.dark',
              color: 'success.dark',
            },
          }}
          variant="outlined"
        >
          Save
        </Button>
        <Button
          onClick={handleReset}
          sx={{
            m: 2,
            borderColor: 'error.main',
            color: 'error.main',
            '&:hover': {
              borderColor: 'error.dark',
              color: 'error.dark',
            },
          }}
          variant="outlined"
        >
          Reset
        </Button>
      </Box>
    </Box>
  );
}

export default MainContractVisibilitySettings;
