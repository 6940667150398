import React, { useState } from 'react';
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Box } from '@mui/material';
import { grey300 } from '../../../styles/colours';
import { ReactComponent as IconDrag } from '../../../common/assets/icon_drag.svg';

function SortableItem({ id, name }: { id: string; name: string }) {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <Box
      ref={setNodeRef}
      style={{
        ...style,
        border: `1px solid ${grey300}`,
        borderRadius: '3px',
        backgroundColor: 'white',
        boxShadow: '1px 1px 4px rgba(0,0,0,0.3)',
        padding: '8px',
        marginBottom: '8px',
        display: 'flex',
        alignItems: 'center',
      }}
      {...attributes}
      {...listeners}
    >
      <Box marginRight={1}>
        <IconDrag />
      </Box>
      {name}
    </Box>
  );
}

type ListItem = {
  id: string;
  name: string;
};

type Props = {
  items: Array<ListItem>;
  onItemOrderChange: (items: Array<ListItem>) => void;
};

function SortableList({ items, onItemOrderChange }: Props) {
  const [list, setList] = useState<Array<ListItem>>(items.map(i => i));

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  function handleDragEnd(event: any) {
    const { active, over } = event;

    if (active.id !== over.id) {
      setList(items => {
        const oldIndex = items.findIndex(i => i.id === active.id);
        const newIndex = items.findIndex(i => i.id === over.id);

        const newArray = arrayMove(items, oldIndex, newIndex);
        onItemOrderChange(newArray);
        return newArray;
      });
    }
  }

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
    >
      <SortableContext items={list} strategy={verticalListSortingStrategy}>
        {list.map(item => (
          <SortableItem key={item.id} id={item.id} name={item.name} />
        ))}
      </SortableContext>
    </DndContext>
  );
}

export default SortableList;
