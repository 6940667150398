import React, { useEffect } from 'react';
import { AlertColor } from '@mui/material';
import { createPortal } from 'react-dom';
import { useMutation } from '@tanstack/react-query';

import { KebabMenu } from '../../../components/menu/KebabMenu';
import { useDialogState } from '../../../components/dialog/dialog.hooks';
import { useConfirmDialog } from '../../../common/hooks/useConfirmDialog';
import {
  deleteReportItem,
  deleteReportItemPayload,
} from '../../../common/api/reports';
import { ReportCellKebab, ReportCellMap } from '../ReportCell.style';
import { Tile } from '../BasicTileItem.styles';
import EditMapTile from './EditMapTile';
import { ReportItem } from '../../../common/api/report-types';
import { mapsLoader } from '../../../components/location-search/LocationLibraryLoader';

type Props = {
  reportItem: ReportItem;
  isEditingDashboard: boolean;
  reload: () => void;
  showAlert: (title: string, severity: AlertColor) => void;
};

const MapTile = ({
  reportItem,
  isEditingDashboard,
  reload,
  showAlert,
}: Props) => {
  const {
    isVisible: isEditVisible,
    open: openEdit,
    close: closeEdit,
  } = useDialogState();
  const { confirmAction, ConfirmDialog } = useConfirmDialog();

  const { mutateAsync: mutatedeleteReportItem } = useMutation(
    (payload: deleteReportItemPayload) => deleteReportItem(payload),
    {
      onSuccess: () => {
        reload();
        showAlert('Tile removed!', 'success');
      },
      onError: () =>
        showAlert(
          'There was an error deleting the tile. Please try again later.',
          'error'
        ),
    }
  );

  const el = document.getElementById('report-actions');

  const id = reportItem.id;
  const reportId = reportItem.reportId;
  const locations = reportItem.locations;

  useEffect(() => {
    async function setup() {
      const { Map } = (await mapsLoader.importLibrary(
        'maps'
      )) as google.maps.MapsLibrary;
      const { AdvancedMarkerElement } = (await mapsLoader.importLibrary(
        'marker'
      )) as google.maps.MarkerLibrary;

      const el = document.getElementById(`map-${id}`);
      if (el) {
        const map = new Map(el, {
          center:
            locations.length > 0
              ? {
                  lat: parseFloat(locations[0].latitude),
                  lng: parseFloat(locations[0].longitude),
                }
              : {
                  lat: -33.8419535,
                  lng: 149.6836996,
                },
          zoom: 6,
          mapId: `map-${id}`,
          fullscreenControl: false,
        });

        const bounds = new google.maps.LatLngBounds();

        for (let i = 0; i < locations.length; i++) {
          new AdvancedMarkerElement({
            map: map,
            position: {
              lat: parseFloat(locations[i].latitude),
              lng: parseFloat(locations[i].longitude),
            },
            title: locations[i].address,
          });
          bounds.extend({
            lat: parseFloat(locations[i].latitude),
            lng: parseFloat(locations[i].longitude),
          });

          map.fitBounds(bounds);
        }
      }
    }

    setup();
  }, [id, locations]);

  return (
    <Tile>
      {el &&
        createPortal(
          <>
            {isEditVisible && (
              <EditMapTile
                id={id}
                reportId={reportId}
                locations={locations}
                style={reportItem.style}
                close={closeEdit}
                reload={reload}
                showAlert={showAlert}
              />
            )}
          </>,
          el
        )}

      <ConfirmDialog />
      {!isEditingDashboard && (
        <ReportCellKebab>
          <KebabMenu
            options={['Tile Options', 'Remove Tile']}
            onOptionSelect={async option => {
              if (option === 'Remove Tile') {
                await confirmAction(
                  'Are you sure you want to remove this tile?'
                );
                await mutatedeleteReportItem({
                  reportId,
                  id,
                });
              } else if (option === 'Tile Options') {
                openEdit();
              }
            }}
          />
        </ReportCellKebab>
      )}

      <ReportCellMap id={`map-${id}`}></ReportCellMap>
    </Tile>
  );
};

export default MapTile;
