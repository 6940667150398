import dayjs, { ManipulateType } from 'dayjs';
import dayjsBusinessTime from 'dayjs-business-time';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(relativeTime);
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);
dayjs.extend(dayjsBusinessTime);

export const DEFAULT_DATE_FORMAT = 'DD/MM/YYYY';

export function formatDate(value: Date | string | null) {
  if (!value) return '';
  return dayjs(value).format(DEFAULT_DATE_FORMAT);
}

export function projectDate(accountDate?: string) {
  return accountDate ? dayjs(accountDate) : dayjs();
}

export function formatDaysTillDate(
  startDate: Date | string,
  systemDate?: dayjs.Dayjs
) {
  if (!(startDate instanceof Date) && typeof startDate !== 'string') {
    return NaN;
  }

  const start = dayjs(new Date(startDate).toISOString());
  const currentDate = dayjs();
  const time = (systemDate || currentDate).isBusinessDay()
    ? systemDate || currentDate
    : (systemDate || currentDate).lastBusinessDay();

  if (
    start.format(DEFAULT_DATE_FORMAT) ===
    (systemDate || currentDate).format(DEFAULT_DATE_FORMAT)
  ) {
    return 0;
  }

  return start.set('hours', 9).businessDaysDiff(time);
}

export function formatDateFromNow(
  value: Date | string | null,
  systemDate?: dayjs.Dayjs
) {
  if (systemDate) {
    return dayjs(value).from(systemDate);
  }

  return dayjs(value).fromNow();
}

export function isBeforeDate(
  value: Date | string | null,
  systemDate?: dayjs.Dayjs
) {
  const currentDate = dayjs();
  return dayjs(dayjs(value)).isBefore(systemDate || currentDate, 'days');
}

export function isSameOrBeforeDate(
  value: Date | string | null,
  unitAmount: number,
  unit: ManipulateType,
  systemDate?: dayjs.Dayjs
) {
  const currentDate = dayjs();
  return dayjs(dayjs(value))
    .subtract(unitAmount, unit)
    .isSameOrBefore(systemDate || currentDate, 'days');
}

export function isSameOrAfterDate(
  value: Date | string | null,
  systemDate?: dayjs.Dayjs
) {
  const currentDate = dayjs();

  return dayjs(dayjs(value)).isSameOrAfter(systemDate || currentDate, 'days');
}
