import React, { ChangeEvent, useState } from 'react';
import { SortedTableHead } from '../../../../../components/sorted-table-head';
import { TableBody } from '@mui/material';
import { TaskClaimItemRow } from './TaskClaimItemRow';
import { StyledTable } from '../../../../../styles';
import { useSortedTable } from '../../../../../components/sorted-table-head/SortedTable.hooks';
import { ClaimType, Contract, Task } from '../../../../../common/api';
import { stableSort } from '../../../../../common/sort/stableSort';
import { getComparator } from '../../../../../common/sort/getComparator';
import {
  createTaskRow,
  excludeChildrenFieldFromArray,
  headCells,
  TaskRowData,
} from '../../../utils/taskTable.utils';
import { isContractor, isCustomer } from '../../../../../common/access';
import { useAuth } from '../../../../../common/auth-provider/AuthProvider';

interface Props {
  tasks: Array<Task>;
  selectAll: Array<string>;
  handleSelectAll: (event: ChangeEvent<HTMLInputElement>) => void;
  selected: Task | null;
  setSelected: (selected: Task | null) => void;
  handleCheckboxClick: (id: string) => void;
  handleClaimClick: () => void;
  isItemSelected: (id: string) => boolean;
  handleAddToCurrentClaim: (
    task: Task,
    value: number,
    claimType: ClaimType
  ) => void;
  contract: Contract;
  hasCheckbox: boolean;
}

function TasksTable(props: Props) {
  const {
    tasks,
    selectAll,
    handleSelectAll,
    setSelected,
    handleCheckboxClick,
    handleClaimClick,
    handleAddToCurrentClaim,
    isItemSelected,
    contract,
    hasCheckbox,
  } = props;

  const { order, orderBy, handleRequestSort } = useSortedTable('identifier');
  const rows = tasks.map(task => createTaskRow(task));
  const [openRows, setOpenRows] = useState<Set<string>>(new Set());
  const { user } = useAuth();
  const isCanView =
    user && (isCustomer(user, contract) || isContractor(user, contract));

  const handleRowToggle = (id: string) => {
    setOpenRows(prevOpenRows => {
      const newOpenRows = new Set(prevOpenRows);
      if (newOpenRows.has(id)) {
        newOpenRows.delete(id);
      } else {
        newOpenRows.add(id);
      }
      return newOpenRows;
    });
  };

  function hasChildren(row: any | undefined): boolean {
    return row?.children?.length ? row?.children?.length > 0 ?? false : false;
  }

  const renderChildren = (children: any[], offset?: any) => (
    <>
      {children.map((child, id) => {
        const childTask = tasks.find(task => task.id === child.id) || null;

        return (
          <React.Fragment key={child.id}>
            <TaskClaimItemRow
              key={child.id}
              task={child}
              claimItem={
                child.claimItem?.length > 0
                  ? childTask?.claimItem[0]
                  : undefined
              }
              selected={false}
              onClick={() => setSelected(child)}
              isItemSelected={isItemSelected}
              handleCheckboxClick={handleCheckboxClick}
              handleClaimClick={handleClaimClick}
              handleAddToCurrentClaim={(value, claimType) =>
                handleAddToCurrentClaim(child, value, claimType)
              }
              onToggle={() => handleRowToggle(child.id.toString())}
              isOpen={openRows.has(child.id.toString())}
              hasButtonToggle={hasChildren(child)}
              offset={`${offset * 10}`}
              isChild={true}
            />
            {openRows.has(child.id.toString()) &&
              child.children &&
              renderChildren(child.children, 6)}
          </React.Fragment>
        );
      })}
    </>
  );

  return (
    <StyledTable>
      <SortedTableHead
        order={order}
        orderBy={orderBy || 'identifier'}
        onRequestSort={handleRequestSort}
        headCells={headCells({
          isSubcontract: !isCanView,
          status: contract.status,
          hasCheckbox,
        })}
        handleSelectAll={handleSelectAll}
        selectAll={selectAll}
        data={tasks}
      />
      <TableBody>
        {stableSort(
          excludeChildrenFieldFromArray(rows),
          getComparator(order, orderBy || 'identifier')
        ).map(row => {
          const task = tasks.find(task => task.id === row.id);
          const isOpen = openRows.has(row.id.toString());
          const fullRow = rows.find(r => r.id === row.id) || row;

          if (task) {
            return (
              <React.Fragment key={row.id}>
                <TaskClaimItemRow
                  key={row.id}
                  task={row}
                  claimItem={
                    task.claimItem?.length > 0 ? task.claimItem[0] : undefined
                  }
                  selected={false}
                  onClick={() => setSelected(task)}
                  isItemSelected={isItemSelected}
                  handleCheckboxClick={handleCheckboxClick}
                  handleClaimClick={handleClaimClick}
                  handleAddToCurrentClaim={(value, claimType) =>
                    handleAddToCurrentClaim(task, value, claimType)
                  }
                  onToggle={() => handleRowToggle(row.id.toString())}
                  isOpen={isOpen}
                  hasButtonToggle={hasChildren(fullRow as TaskRowData)}
                />
                {isOpen &&
                  (fullRow as TaskRowData).children &&
                  renderChildren((fullRow as TaskRowData).children || [], 3)}
              </React.Fragment>
            );
          }

          return <></>;
        })}
      </TableBody>
    </StyledTable>
  );
}

export { TasksTable };
