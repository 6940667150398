import * as React from 'react';
import usePagination from '@mui/material/usePagination';
import { Button, Stack, Typography } from '@mui/material';

interface Props {
  count: number;
  handleChangePage: (
    event: React.ChangeEvent<unknown>,
    newPage: number
  ) => void;
  page: number;
  total: number;
}

function Pagination(props: Props) {
  const { count, handleChangePage, page, total } = props;

  const { items } = usePagination({
    count: count,
    onChange: handleChangePage,
    page: page,
  });

  let children = null;

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      sx={{ marginBottom: '30px' }}
    >
      <Typography variant={'caption'}>{total} Results</Typography>
      <Stack direction={'row'} spacing={2}>
        {items.map(({ page, type, selected, ...item }, index) => {
          if (
            type === 'start-ellipsis' ||
            type === 'end-ellipsis' ||
            type === 'page'
          ) {
            children = null;
          } else {
            children = (
              <Button variant={'outlined'} {...item}>
                {type === 'next' ? 'Next' : 'Previous'}
              </Button>
            );
          }

          if (children) {
            return <div key={index}>{children}</div>;
          }

          return null;
        })}
      </Stack>
    </Stack>
  );
}

export { Pagination };
