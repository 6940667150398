import React, { useCallback, useMemo } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import { useMutation, useQuery } from '@tanstack/react-query';
import { Box } from '@mui/material';

import {
  Contract,
  ContractStatus,
  getContractHistory,
  ResponseListBaseType,
  SummaryTaskData,
  Tag,
  Task,
  updateContract,
  UpdateContractPayload,
} from '../../../../common/api';

// Components
import {
  BaseTaskGroupProps,
  TaskGroups,
} from '../../../../components/task-group/TaskGroups';
import { ContractDetails } from '../../components/ContractDetails';
import { Tabs, TabsItem } from '../../../../components/tabs';
import { SkeletonTable } from '../../../../components/skeleton-table';

// Tabs
import { TaskTab } from '../../components/tabs/TaskTab';

// Types
import { ActionListType } from '../../components/ActionButtons';
import { BasePaginationProps } from '../../../../common/types';
import { CONTRACT_EDIT } from '../../../../common/router-util/routes';

export enum PageContractDraftTabsIds {
  tasks = 'tasks',
}

type Props = BasePaginationProps &
  BaseTaskGroupProps & {
    contract: Contract;
    tasks?: ResponseListBaseType<Array<Task>>;
    reloadData: () => Promise<void>;
    reloadContract: () => Promise<void>;
    onChangeTags: (tag: Array<Tag>) => void;
    tags: Array<Tag>;
    summary?: SummaryTaskData;
    isCustomer?: boolean;
    isReviewed?: boolean;
  };

const draftTabs = [
  {
    label: 'Tasks',
    id: PageContractDraftTabsIds.tasks,
  },
];

function ContractAwaitingView({
  contract,
  page,
  isReviewed,
  isCustomer = false,
  rowsPerPage,
  summary,
  tasks,
  handleChangePage,
  handleChangeRowsPerPage,
  handleSelectedTaskGroup,
  reloadContract,
  reloadData,
  selectedTaskGroup,
}: Props) {
  const navigate = useNavigate();

  const { data: historyData } = useQuery(['getHistory', contract.id], () =>
    getContractHistory(contract.id)
  );

  const { mutateAsync: mutateContract, isLoading: isContractLoading } =
    useMutation(
      (payload: UpdateContractPayload) => updateContract(contract.id, payload),
      {
        onSuccess: async () => {
          await reloadData();
          return;
        },
      }
    );

  const reloadDataContract = useCallback(async () => {
    await reloadData();
  }, [reloadData]);

  const handleUpdate = useCallback(
    (status: ContractStatus) => async () => {
      await mutateContract({
        status: status,
      });
      if (isReviewed || (!isCustomer && status === ContractStatus.Draft)) {
        navigate(`/projects/${contract.projectId}`);
      } else {
        await reloadContract();
      }
    },
    [
      isCustomer,
      contract.projectId,
      navigate,
      mutateContract,
      reloadContract,
      isReviewed,
    ]
  );

  const actionList: ActionListType[] = useMemo((): ActionListType[] => {
    const backAction = {
      id: 'back',
      label: 'Back',
      action: () => {
        navigate(`/projects/${contract.projectId}`);
      },
    };

    const acceptAction = {
      id: 'accept',
      isLoading: isContractLoading,
      label: 'Accept',
      color: 'success' as const,
      variant: 'contained' as const,
      action: handleUpdate(ContractStatus.Active),
    };

    const rejectOrWithdraw = isCustomer
      ? {
          id: 'withdraw',
          isLoading: isContractLoading,
          label: 'Withdraw',
          color: 'warning',
          variant: 'contained',
          action: handleUpdate(ContractStatus.Draft),
        }
      : {
          id: 'reject',
          isLoading: isContractLoading,
          label: 'Reject',
          color: 'error',
          variant: 'contained',
          action: handleUpdate(ContractStatus.Draft),
        };

    if (isReviewed) {
      const actionList: ActionListType[] = [
        backAction,
        rejectOrWithdraw,
        {
          id: 'edit',
          label: 'Edit',
          variant: 'outlined',
          action: () => {
            navigate(
              generatePath(CONTRACT_EDIT, {
                contractId: contract.id,
              }),
              {
                state: {
                  isEditable: true,
                },
              }
            );
          },
        },
      ];

      if (
        contract.isSubmitted &&
        contract.status !== ContractStatus.DraftInviteSent
      ) {
        actionList.push(acceptAction);
      }

      return actionList;
    }
    return [backAction, rejectOrWithdraw];
  }, [
    isCustomer,
    handleUpdate,
    navigate,
    contract.projectId,
    contract.isSubmitted,
    contract.id,
    contract.status,
    isReviewed,
    isContractLoading,
  ]);

  return (
    <>
      <ContractDetails
        contract={contract}
        contractHistory={historyData?.contractHistory}
        actionList={actionList}
        description={contract.description}
        reloadData={reloadContract}
        summary={summary}
      />
      <Box>
        <Tabs tabsData={draftTabs}>
          <TabsItem id={PageContractDraftTabsIds.tasks}>
            <TaskGroups
              canEdit={false}
              contractId={contract.id}
              queryId="contract-approve"
              selectedTaskGroup={selectedTaskGroup}
              handleSelectedTaskGroup={handleSelectedTaskGroup}
              render={() => (
                <>
                  {tasks ? (
                    <TaskTab
                      contract={contract}
                      tasks={tasks}
                      tasksHistory={historyData?.tasksHistory}
                      selectedTaskGroup={selectedTaskGroup}
                      reloadContract={reloadContract}
                      reloadData={reloadDataContract}
                      page={page}
                      isEditable
                      rowsPerPage={rowsPerPage}
                      handleChangePage={handleChangePage}
                      handleChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                  ) : (
                    <SkeletonTable />
                  )}
                </>
              )}
            />
          </TabsItem>
        </Tabs>
      </Box>
    </>
  );
}

export { ContractAwaitingView };
