import { axios } from './axios';
import { Notification } from './types';

async function listNotification(
  accountId: string,
  isRead?: boolean
): Promise<Array<Notification>> {
  const resp = await axios.get(`accounts/${accountId}/notifications`, {
    params: {
      isRead: isRead ? !isRead : undefined,
    },
  });
  return resp.data;
}

async function updateNotification(
  id: string,
  isRead: boolean
): Promise<Notification> {
  const resp = await axios.post(`notification/${id}`, {
    isRead,
  });

  return resp.data;
}

async function updateAllNotification(accountId: string): Promise<any> {
  const resp = await axios.post(`accounts/${accountId}/notifications`);

  return resp.data;
}

export { listNotification, updateNotification, updateAllNotification };
