import React, { useCallback } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useMutation } from '@tanstack/react-query';
import {
  AlertColor,
  Box,
  Button,
  Dialog,
  DialogContent,
  IconButton,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Divider from '@mui/material/Divider';
import DeleteIcon from '@mui/icons-material/Delete';

import { Heading, InputActions } from '../../../styles';
import {
  updateReportItemLocations,
  UpdateReportItemLocationsPayload,
} from '../../../common/api/reports';
import {
  ReportItemLocation,
  ReportItemStyle,
} from '../../../common/api/report-types';
import LocationSearch from '../../../components/location-search/LocationSearch';

const ADDRESS_FIELD = 'address';
const PLACE_ID = 'placeId';

type FormData = {
  locations: {
    [ADDRESS_FIELD]: string;
    [PLACE_ID]: string;
  }[];
};

type Props = {
  id: string;
  reportId: string;
  style: ReportItemStyle;
  locations: Array<ReportItemLocation>;
  reload: () => void;
  close: () => void;
  showAlert: (title: string, severity: AlertColor) => void;
};

function EditMapTile({
  id,
  reportId,
  locations,
  close,
  reload,
  showAlert,
}: Props) {
  const { control, handleSubmit } = useForm<FormData>({
    defaultValues: {
      locations: locations.length
        ? locations.map(l => ({
            [ADDRESS_FIELD]: l.address,
            [PLACE_ID]: l.placeId,
          }))
        : [],
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'locations',
  });

  const { mutateAsync: mutateUpdate, isLoading } = useMutation(
    (payload: UpdateReportItemLocationsPayload) =>
      updateReportItemLocations(payload),
    {
      onSuccess: () => {
        reload();
        close();
      },
      onError: () =>
        showAlert(
          'There was an error updating the tile. Please try again later.',
          'error'
        ),
    }
  );

  const onSubmit = useCallback(
    async (data: FormData) => {
      mutateUpdate({
        reportId,
        id,
        locations: data.locations.map((d, index) => {
          return {
            id: locations?.find(l => l.placeId === d[PLACE_ID])?.id || null,
            address: d[ADDRESS_FIELD],
            placeId: d[PLACE_ID],
            reportItemId: id,
          };
        }),
      });
    },
    [id, reportId, locations, mutateUpdate]
  );

  return (
    <Dialog open fullWidth maxWidth="sm" onClose={close}>
      <DialogContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Heading style={{ marginBottom: '8px' }}>Map Tile Options</Heading>

          <Divider sx={{ mb: 2 }} />

          <LocationSearch
            onLocationSelected={data => {
              if (data) {
                append({
                  [ADDRESS_FIELD]: data.address,
                  [PLACE_ID]: data.placeId,
                });
              }
            }}
          />

          {fields.map((item, index) => (
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              gap="8px"
              padding="2px"
              key={item.id}
            >
              <input
                type="hidden"
                name={`locations.${index}.${PLACE_ID}`}
                value={locations?.[index]?.latitude}
              />

              <Box display="flex" alignItems={'center'} flex={1}>
                {item[ADDRESS_FIELD]}
              </Box>
              <IconButton
                sx={{ width: '40px', flexShrink: 0 }}
                onClick={() => {
                  remove(index);
                }}
              >
                <DeleteIcon width={20} />
              </IconButton>
            </Box>
          ))}

          <InputActions>
            <Button variant="outlined" onClick={close}>
              Cancel
            </Button>
            <LoadingButton
              type="submit"
              variant="contained"
              loading={isLoading}
            >
              Save
            </LoadingButton>
          </InputActions>
        </form>
      </DialogContent>
    </Dialog>
  );
}

export default EditMapTile;
