import React from 'react';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import { Contract } from '../../../common/api';

// Styles
import { blue200 } from '../../../styles/colours';

// Utils
import { formatCurrency, formatDate } from '../../../common/format';
import { Heading } from '../../../styles';

interface Props {
  contract: Contract;
  open: boolean;
  handleClose: () => void;
}

const ViewDetailsDialog = ({ contract, open, handleClose }: Props) => (
  <Dialog open={open} maxWidth={'md'} fullWidth>
    <DialogTitle sx={styles.DialogTitle}>
      <Heading>Contract Settings</Heading>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={styles.IconButton}
      >
        <CloseIcon />
      </IconButton>
    </DialogTitle>
    <DialogContent sx={{ display: 'flex', flexDirection: 'column' }}>
      <TableContainer>
        <Table>
          <TableHead sx={{ backgroundColor: blue200 }}>
            <TableRow>
              <TableCell />
              <TableCell>Client Details</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>Organisation Name</TableCell>
              <TableCell>{contract.customerAccount.name}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Address</TableCell>
              <TableCell>{contract.customerAccount.address || '-'}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>ABN</TableCell>
              <TableCell>{contract.customerAccount.abn || '-'}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Client Representative</TableCell>
              <TableCell>
                {contract.customerAccount.representative || '-'}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Client Rep. email</TableCell>
              <TableCell>{contract.customerAccount.email}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Client Rep. Phone</TableCell>
              <TableCell>{contract.customerAccount.phone || '-'}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell />
              <TableCell />
            </TableRow>
          </TableBody>
          <TableHead sx={{ backgroundColor: blue200 }}>
            <TableRow>
              <TableCell />
              <TableCell>Contract Details</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>Contractor</TableCell>
              <TableCell>{contract.contractorAccount.name}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Contractor Representative</TableCell>
              <TableCell>{contract.contractorAccount.representative}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Contractor Rep. email</TableCell>
              <TableCell>{contract.contractorAccount.email}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Contractor Rep. Phone</TableCell>
              <TableCell>{contract.contractorAccount.phone}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Contract Title</TableCell>
              <TableCell>{contract.description}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Reference #</TableCell>
              <TableCell>{contract.id}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Contract Contingency Budget</TableCell>
              <TableCell>
                {formatCurrency(Number(contract.contingencyBudget || 0))}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Contract Awarded</TableCell>
              <TableCell>{formatDate(contract.award)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Payment Terms</TableCell>
              <TableCell>{contract.paymentTerm}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <Button
        onClick={handleClose}
        sx={{ alignSelf: 'flex-end', m: 2 }}
        variant="contained"
        color="primary"
      >
        Close
      </Button>
    </DialogContent>
  </Dialog>
);

const styles = {
  DialogTitle: {
    p: 4,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  IconButton: {
    color: (theme: Theme) => theme.palette.grey[500],
  },
};

export { ViewDetailsDialog };
