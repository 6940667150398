import React, { useCallback, useEffect } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useMutation } from '@tanstack/react-query';
import {
  AlertColor,
  Box,
  Button,
  Dialog,
  DialogContent,
  FormControl,
  InputLabel,
  ListSubheader,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';

import { Heading, InputActions } from '../../styles';
import {
  updateReportItem,
  UpdateReportItemPayload,
} from '../../common/api/reports';
import { TileColour } from './ReportTile.style';
import { REPORT_METRIC_COLOURS } from './Report.constants';
import { MetricFormat } from '../../common/api/metric-types';
import { Denomination } from '../../common/api/report-types';
import { useReportMetrics } from './Report.hooks';

const METRIC_FIELD = 'metric';
const HEADING_FIELD = 'heading';
const SUB_HEADING_FIELD = 'subHeading';
const COLOUR_FIELD = 'colour';
const DENOMINATION_FIELD = 'denomination';
const DECIMAL_FIELD = 'decimal';

type FormData = {
  [METRIC_FIELD]: string;
  [HEADING_FIELD]: string;
  [SUB_HEADING_FIELD]: string;
  [COLOUR_FIELD]: string;
  [DENOMINATION_FIELD]: string;
  [DECIMAL_FIELD]: string;
};

type Props = {
  id: string;
  metricGroupId?: string;
  heading?: string | null;
  subHeading?: string | null;
  colour: string;
  metricId?: string;
  reportId: string;
  denomination?: string;
  decimalPlaces?: number;
  reload: () => void;
  close: () => void;
  showAlert: (title: string, severity: AlertColor) => void;
};

function EditBasicTile({
  id,
  metricGroupId,
  heading,
  subHeading,
  colour,
  metricId,
  reportId,
  denomination,
  decimalPlaces,
  close,
  reload,
  showAlert,
}: Props) {
  const { data, isMetricListLoading, groupedMetrics } = useReportMetrics();

  const {
    control,
    formState: { errors },
    watch,
    register,
    setValue,
    handleSubmit,
    reset,
  } = useForm<FormData>({
    defaultValues: {
      [HEADING_FIELD]: heading || '',
      [SUB_HEADING_FIELD]: subHeading || '',
      [METRIC_FIELD]: metricId,
      [COLOUR_FIELD]: colour,
      [DENOMINATION_FIELD]: denomination || Denomination.Million,
      [DECIMAL_FIELD]: decimalPlaces ? decimalPlaces.toString() : '0',
    },
  });

  const selectedColour = watch(COLOUR_FIELD);

  const handleCloseDialog = useCallback(() => {
    close();
    reset();
  }, [close, reset]);

  const { mutateAsync: mutateUpdate, isLoading } = useMutation(
    (payload: UpdateReportItemPayload) => updateReportItem(payload),
    {
      onSuccess: () => {
        reload();
        handleCloseDialog();
      },
      onError: () =>
        showAlert(
          'There was an error updating the tile. Please try again later.',
          'error'
        ),
    }
  );

  const onSubmit = useCallback<SubmitHandler<FormData>>(
    (data: FormData) => {
      mutateUpdate({
        reportId,
        id,
        updatedReportItem: {
          id,
          reportId,
          heading: data[HEADING_FIELD],
          subHeading: data[SUB_HEADING_FIELD],
          imageKey: null,
          richtext: null,
          metricGroups: metricGroupId
            ? [
                {
                  id: metricGroupId,
                  colour: data[COLOUR_FIELD],
                  decimalPlaces: Number(data[DECIMAL_FIELD]),
                  denomination: data[DENOMINATION_FIELD],
                  metricId: data[METRIC_FIELD],
                  reportItemId: id,
                },
              ]
            : [],
          newMetricGroups: !metricGroupId
            ? [
                {
                  colour: data[COLOUR_FIELD],
                  metricId: data[METRIC_FIELD],
                  reportItemId: id,
                  decimalPlaces: Number(data[DECIMAL_FIELD]),
                  denomination: data[DENOMINATION_FIELD],
                },
              ]
            : [],
        },
      });
    },
    [id, reportId, metricGroupId, mutateUpdate]
  );

  useEffect(() => {
    register(COLOUR_FIELD);
  }, [register]);

  return (
    <Dialog open onClose={handleCloseDialog}>
      <DialogContent sx={{ width: '415px' }}>
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <Heading>Basic Metric Tile</Heading>

          <FormControl style={styles.FormControl}>
            <InputLabel id="metric-select-label">Metric</InputLabel>
            <Controller
              name={METRIC_FIELD}
              control={control}
              defaultValue=""
              rules={{
                required: 'Metric is required',
              }}
              render={({ field }) => (
                <Select
                  {...field}
                  labelId="metric-select-label"
                  label="Metric"
                  required
                  disabled={isMetricListLoading}
                  style={styles.Field}
                  error={!!errors[METRIC_FIELD]}
                  onChange={e => {
                    setValue(METRIC_FIELD, e.target.value);
                    setValue(
                      HEADING_FIELD,
                      data?.find(m => m.id === e.target.value)?.title || '',
                      {
                        shouldValidate: true,
                      }
                    );
                  }}
                >
                  {groupedMetrics.map(item => {
                    if (typeof item === 'string') {
                      return (
                        <ListSubheader key={item}>
                          {item === 'null' ? 'Other' : item}
                        </ListSubheader>
                      );
                    }
                    const isNotAvailable =
                      item.group === 'Claim Compliance' ||
                      item.group === 'Payment Compliance';
                    return (
                      <MenuItem
                        key={item.id}
                        value={item.id}
                        disabled={isNotAvailable}
                      >
                        {item.title}
                        {item.format === MetricFormat.Percentage
                          ? ' (%)'
                          : item.format === MetricFormat.Dollar
                          ? ' ($)'
                          : ''}
                      </MenuItem>
                    );
                  })}
                </Select>
              )}
            />
          </FormControl>

          <FormControl style={styles.FormControl}>
            <Controller
              name={HEADING_FIELD}
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Heading"
                  style={styles.Field}
                  error={!!errors[HEADING_FIELD]}
                />
              )}
            />
          </FormControl>

          <FormControl style={styles.FormControl}>
            <Controller
              name={SUB_HEADING_FIELD}
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Sub text"
                  style={styles.Field}
                  error={!!errors[SUB_HEADING_FIELD]}
                />
              )}
            />
          </FormControl>

          <FormControl style={styles.FormControl}>
            <InputLabel id="denomination-select-label">Denomination</InputLabel>
            <Controller
              name={DENOMINATION_FIELD}
              control={control}
              defaultValue=""
              render={({ field }) => (
                <Select
                  {...field}
                  labelId="denomination-select-label"
                  label="Denomination"
                  style={styles.Field}
                  error={!!errors[DENOMINATION_FIELD]}
                  onChange={e => {
                    setValue(DENOMINATION_FIELD, e.target.value);
                    setValue(DECIMAL_FIELD, '0');
                  }}
                >
                  <MenuItem value={Denomination.Actual}>Actual</MenuItem>
                  <MenuItem value={Denomination.Thousands}>Thousands</MenuItem>
                  <MenuItem value={Denomination.Million}>Millions</MenuItem>
                  <MenuItem value={Denomination.Billion}>Billions</MenuItem>
                </Select>
              )}
            />
          </FormControl>

          <FormControl style={styles.FormControl}>
            <InputLabel id="decimals-select-label">Decimal Places</InputLabel>
            <Controller
              name={DECIMAL_FIELD}
              control={control}
              defaultValue=""
              render={({ field }) => (
                <Select
                  {...field}
                  labelId="decimals-select-label"
                  label="Decimal Places"
                  style={styles.Field}
                  error={!!errors[DECIMAL_FIELD]}
                  onChange={e => {
                    setValue(DECIMAL_FIELD, e.target.value);
                  }}
                >
                  <MenuItem value={'0'}>0</MenuItem>
                  <MenuItem value={'1'}>1</MenuItem>
                  <MenuItem value={'2'}>2</MenuItem>
                  <MenuItem value={'3'}>3</MenuItem>
                </Select>
              )}
            />
          </FormControl>

          <FormControl style={styles.FormControl}>
            <Box display="flex" gap={1}>
              {REPORT_METRIC_COLOURS.map(colour => {
                return (
                  <TileColour
                    type="button"
                    key={colour}
                    $isActive={colour === selectedColour}
                    style={{
                      ...styles.ColourButton,
                      backgroundColor: colour,
                    }}
                    onClick={() => setValue(COLOUR_FIELD, colour)}
                  />
                );
              })}
            </Box>
          </FormControl>

          <InputActions>
            <Button variant="outlined" onClick={handleCloseDialog}>
              Cancel
            </Button>
            <LoadingButton
              type="submit"
              variant="contained"
              loading={isLoading}
            >
              Save
            </LoadingButton>
          </InputActions>
        </form>
      </DialogContent>
    </Dialog>
  );
}

const styles: { [key: string]: React.CSSProperties } = {
  FormControl: {
    margin: 0,
    width: '100%',
  },
  Field: {
    margin: '0 0 16px 0',
  },
};

export default EditBasicTile;
