export const PROJECTS = '/projects';
export const PROJECT = `${PROJECTS}/:projectId`;

export const CONTRACTS = '/contracts';
export const CONTRACT = `${CONTRACTS}/:contractId`;
export const CONTRACT_EDIT = `${CONTRACTS}/:contractId/edit`;

export const VARIATION = 'variation';
export const VARIATION_NEW_DRAFT = `${CONTRACT}/variation/draft`;
export const VARIATION_NEW = `${CONTRACT}/${VARIATION}`;
export const VARIATION_EXISTING = `${CONTRACT}/${VARIATION}/:variationId`;
export const VARIATION_RECEIVED = `${CONTRACT}/${VARIATION}/:variationId/received`;

export const CLAIM = `${CONTRACT}/claims/:claimId`;
export const SUBCONTRACT_CLAIM = `${CONTRACT}/subcontract/${CONTRACT}/claims/:claimId`;
