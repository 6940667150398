import React from 'react';
import { SortedTableHead } from '../../../../components/sorted-table-head';
import { TableBody } from '@mui/material';
import { StyledTable } from '../../../../styles';
import { useSortedTable } from '../../../../components/sorted-table-head/SortedTable.hooks';
import { Contract, ContractStatus, Task } from '../../../../common/api';
import { stableSort } from '../../../../common/sort/stableSort';
import { getComparator } from '../../../../common/sort/getComparator';
import { headCells } from '../../utils/taskTable.utils';
import { isContractor, isCustomer } from '../../../../common/access';
import { useAuth } from '../../../../common/auth-provider/AuthProvider';
import { DraftTaskRow } from './DraftTaskRow';
import { useConfirmDialog } from '../../../../common/hooks/useConfirmDialog';
import { AddDraftTaskForm } from '../AddDraftTaskForm';

export type EditableTaskType = {
  id: string;
  identifier: string;
  description: string;
  value: string;
  type: string;
  unitOfMeasure: string;
  quantity: number;
  rate: number;
  excludeFromRetention: boolean;
  action: 'create' | 'delete' | 'update';
};

interface Props {
  tasks: Array<EditableTaskType>;
  isEditable?: boolean;
  tasksHistory?: Array<Task>;
  contract: Contract;
  selectedTaskGroup?: string | null;
  onUpdate: (task: EditableTaskType) => void;
}

function TasksTableEdit({
  contract,
  tasks,
  tasksHistory,
  selectedTaskGroup,
  onUpdate,
}: Props) {
  const { order, orderBy, handleRequestSort } = useSortedTable('identifier');
  const { user } = useAuth();

  const { confirmAction, ConfirmDialog } = useConfirmDialog();

  const isCanView =
    user && (isCustomer(user, contract) || isContractor(user, contract));

  const handleTaskUpdate = async (data: EditableTaskType) => {
    const { action } = data;
    if (action === 'delete') {
      await confirmAction('Are you sure you want to delete this task?');
    }
    onUpdate({ ...data, action });
  };

  const getNextId = () =>
    tasks.reduce((acc, task) => {
      return Math.max(acc, Number(task.identifier) + 1);
    }, 0);

  return (
    <>
      <StyledTable sx={{ mb: 3 }}>
        <SortedTableHead
          order={order}
          orderBy={orderBy}
          onRequestSort={handleRequestSort}
          headCells={headCells({
            isSubcontract: !isCanView,
            status: ContractStatus.Draft,
            isEditable: true,
          })}
          isEditable
          status={contract.status}
          data={tasks}
        />
        <TableBody>
          {stableSort(tasks, getComparator(order, orderBy)).map(row => {
            if (row.action === 'delete') {
              return null;
            }
            return (
              <DraftTaskRow
                key={row.id}
                task={row}
                taskHistory={tasksHistory?.find(
                  taskHistory => taskHistory.identifier === row.identifier
                )}
                onTaskUpdate={handleTaskUpdate}
              />
            );
          })}
        </TableBody>
      </StyledTable>
      <ConfirmDialog />
      <AddDraftTaskForm
        selectedTaskGroup={selectedTaskGroup}
        nextTaskId={getNextId()}
        onAddTask={handleTaskUpdate}
      />
    </>
  );
}

export { TasksTableEdit };
