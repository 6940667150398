import sortBy from 'lodash/sortBy';
import { TaskGroup } from '../../common/api';
import { between, highest, lowest } from '../../common/sort/between';

export function orderTaskGroups(taskGroups: TaskGroup[]) {
  return sortBy(taskGroups, 'order');
}

export function getLastOrderValue(
  taskGroups: TaskGroup[],
  parentId: string | null
) {
  const items = taskGroups.filter(tg => tg.parentId === parentId);
  const orderedItems = sortBy(items, 'order');
  return orderedItems.length === 0
    ? between(lowest, highest)
    : between(orderedItems[orderedItems.length - 1].order, highest);
}

export function getReorderValue(
  taskGroups: TaskGroup[],
  targetId: string,
  parentId: string | null,
  direction?: 'up' | 'down'
) {
  const items = taskGroups.filter(tg => tg.parentId === parentId);
  const orderedItems = sortBy(items, 'order');
  const index = orderedItems.findIndex(tg => tg.id === targetId);

  if (targetId === '0') {
    return between(lowest, orderedItems[0].order);
  }

  if (index === orderedItems.length - 1) {
    return between(orderedItems[orderedItems.length - 1].order, highest);
  }

  if (direction === 'up') {
    const leftSort = orderedItems[index - 1]?.order || lowest;
    const rightSort = orderedItems[index].order;
    return between(leftSort, rightSort);
  } else {
    const leftSort = orderedItems[index].order;
    const rightSort = orderedItems[index + 1].order;
    return between(leftSort, rightSort);
  }
}
