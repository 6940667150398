import { styled } from '@mui/system';
import { Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { brand1000, grey600, grey900 } from './colours';

export const Heading = styled(Typography)({
  color: grey900,
  fontSize: '24px',
  fontWeight: '500',
  marginBottom: '24px',
});

export const LinkTo = styled(Link)(props => ({
  color: props.color || grey900,
}));

export const SectionHeading = styled(Typography)({
  fontSize: '16px',
  fontWeight: '500',
  lineHeight: '24px',
  color: brand1000,
});

export const SectionHeadingWithFilter = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: '10px',
  marginBottom: '12px',
});

export const SectionHeadingWithAction = styled('div')({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
});

export const TextSmall = styled(Typography)({
  fontSize: 12,
  color: grey600,
});
