import React, { useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
import Chart from 'chart.js/auto';
import { AlertColor, Box, Typography } from '@mui/material';
import { useMutation } from '@tanstack/react-query';

import { ReportItemResource } from '../../common/api/report-types';
import { grey900 } from '../../styles/colours';
import { useConfirmDialog } from '../../common/hooks/useConfirmDialog';
import { ReportCellKebab, ReportCellWrapper } from './ReportCell.style';
import { KebabMenu } from '../../components/menu/KebabMenu';
import {
  deleteReportItem,
  deleteReportItemPayload,
} from '../../common/api/reports';
import { useDialogState } from '../../components/dialog/dialog.hooks';
import EditDonutTile from './EditDonutTile';

type Props = {
  reportItem: ReportItemResource;
  isEditingDashboard: boolean;
  reload: () => void;
  showAlert: (title: string, severity: AlertColor) => void;
};

const DonutTileItem = ({
  reportItem,
  isEditingDashboard,
  reload,
  showAlert,
}: Props) => {
  const chartRef = useRef<HTMLCanvasElement | null>(null);

  const id = reportItem.id;
  const reportId = reportItem.reportId;
  const heading = reportItem.heading;
  const metricGroups = reportItem.metricGroups;

  const {
    isVisible: isEditVisible,
    open: openEdit,
    close: closeEdit,
  } = useDialogState();
  const { confirmAction, ConfirmDialog } = useConfirmDialog();

  const { mutateAsync: mutatedeleteReportItem } = useMutation(
    (payload: deleteReportItemPayload) => deleteReportItem(payload),
    {
      onSuccess: () => {
        reload();
        showAlert('Tile removed!', 'success');
      },
      onError: () =>
        showAlert(
          'There was an error deleting the tile. Please try again later.',
          'error'
        ),
    }
  );

  useEffect(() => {
    let chart: Chart<'doughnut'>;

    async function init() {
      if (chartRef.current) {
        const ctx = chartRef.current.getContext('2d');

        if (ctx) {
          chart = new Chart(ctx, {
            type: 'doughnut',
            options: {
              plugins: {
                legend: {
                  position: 'bottom',
                },
              },
            },
            data: {
              labels:
                metricGroups.length > 0
                  ? metricGroups.map(mg => mg.metric.title)
                  : ['Metric A', 'Metric B'],
              datasets: [
                {
                  data:
                    metricGroups.length > 0
                      ? metricGroups.map(mg => mg.computedMetric)
                      : [6, 4],
                  backgroundColor:
                    metricGroups.length > 0
                      ? metricGroups.map(mg => mg.colour)
                      : ['rgb(255, 99, 132)', 'rgb(54, 162, 235)'],
                  hoverOffset: 4,
                },
              ],
            },
          });
        }
      }
    }

    init();

    return () => {
      if (chart) {
        chart.destroy();
      }
    };
  }, [metricGroups]);

  const el = document.getElementById('report-actions');

  return (
    <ReportCellWrapper>
      {el &&
        createPortal(
          <>
            {isEditVisible && (
              <EditDonutTile
                id={id}
                metricGroups={reportItem.metricGroups}
                reportId={reportId}
                heading={heading}
                close={closeEdit}
                reload={reload}
                showAlert={showAlert}
              />
            )}
          </>,
          el
        )}

      <ConfirmDialog />
      {!isEditingDashboard && (
        <ReportCellKebab>
          <KebabMenu
            options={['Tile Options', 'Remove Tile']}
            onOptionSelect={async option => {
              if (option === 'Remove Tile') {
                await confirmAction(
                  'Are you sure you want to remove this tile?'
                );
                await mutatedeleteReportItem({
                  reportId,
                  id,
                });
              } else if (option === 'Tile Options') {
                openEdit();
              }
            }}
          />
        </ReportCellKebab>
      )}

      <Typography
        fontSize="22px"
        fontWeight={500}
        color={grey900}
        marginBottom={'16px'}
      >
        {reportItem.heading}
      </Typography>
      <Box padding={1}>
        <canvas
          style={{
            width: '100%',
            height: '100%',
          }}
          ref={chartRef}
        ></canvas>
      </Box>
    </ReportCellWrapper>
  );
};

export default DonutTileItem;
