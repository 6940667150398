import {
  Contract,
  ResponseListBaseType,
  Task,
} from '../../../../../common/api';
import { Box, Button, Divider, Typography } from '@mui/material';
import React, { useCallback, useMemo } from 'react';
import { useAuth } from '../../../../../common/auth-provider/AuthProvider';
import { isCustomer } from '../../../../../common/access';
import { BasePaginationProps } from '../../../../../common/types';
import pluralize from 'pluralize';
import { useCheckbox } from '../../../hooks/useCheckbox';
import { AddTask } from './AddTask';
import { Pagination } from '../../../../../components/pagination';
import { TasksTable } from '../../../components/taskTables/TaskTable';
import { useTaskGroupContext } from '../../../../../components/task-group/components/TaskGroup.context';
import { TaskDetails } from '../../../../task-details/TaskDetails';
import { TaskSummary } from '../../../components/TaskSummary';

type Props = BasePaginationProps & {
  contract: Contract;
  isSubContract?: boolean;
  tasks: ResponseListBaseType<Array<Task>>;
  selectedTaskGroup?: string | null;
  reloadContract: () => void;
  reloadData: () => void;
  handleDeleteTask: (selectedTasks: Array<string>) => void;
};

function TaskTab(props: Props) {
  const { user } = useAuth();
  const {
    contract,
    tasks,
    selectedTaskGroup,
    reloadContract,
    reloadData,
    handleChangePage,
    page,
    rowsPerPage,
    handleDeleteTask,
  } = props;

  const {
    selected,
    setSelected,
    isItemSelected,
    handleCheckboxClick,
    handleSelectAll,
    selectAll,
  } = useCheckbox(tasks?.data);

  const { taskGroups } = useTaskGroupContext();

  const isHaveClaim = tasks?.data
    ?.filter(({ id }) => selectAll.includes(id))
    .some(
      ({ approved, claimed, paid }) =>
        Number(approved) || Number(claimed) || Number(paid)
    );

  const handleTaskDelete = useCallback(() => {
    handleDeleteTask(selectAll);
  }, [handleDeleteTask, selectAll]);

  const selectTaskGroup = useMemo(
    () => taskGroups.find(tg => tg.id === selectedTaskGroup),
    [selectedTaskGroup, taskGroups]
  );

  return (
    <>
      <Typography sx={{ mb: 3 }}>
        {selectTaskGroup?.name || 'All Tasks'}
      </Typography>
      <TaskSummary tasks={tasks} contract={contract} />
      <Box sx={{ m: 1, display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          variant="outlined"
          sx={{ m: 1 }}
          onClick={handleTaskDelete}
          disabled={!selectAll.length || isHaveClaim}
        >
          Delete {selectAll.length} {pluralize('Task', selectAll.length)}
        </Button>
      </Box>
      <TasksTable
        tasks={tasks.data}
        selectAll={selectAll}
        handleSelectAll={handleSelectAll}
        selected={selected}
        setSelected={setSelected}
        handleCheckboxClick={handleCheckboxClick}
        isItemSelected={isItemSelected}
        contract={contract}
        reloadContract={reloadContract}
        reloadData={reloadData}
      />
      {/* direct customer to contract can add a task */}
      {user && isCustomer(user, contract) && selectedTaskGroup && (
        <AddTask
          contractId={contract.id}
          selectedTaskGroup={selectedTaskGroup}
          reloadData={reloadData}
        />
      )}
      <Divider sx={{ mb: 3 }} />
      <Pagination
        count={Math.ceil(tasks.meta.total / rowsPerPage)}
        handleChangePage={handleChangePage}
        page={page}
        total={tasks.meta.total}
      />
      <TaskDetails
        contract={contract}
        task={selected}
        isOpen={!!selected}
        selectTaskGroup={selectTaskGroup}
        onClose={() => {
          reloadData();
          setSelected(null);
        }}
      />
    </>
  );
}

export { TaskTab };
