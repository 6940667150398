import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  SelectChangeEvent,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import { useMutation, useQuery } from '@tanstack/react-query';
import React, { useCallback, useEffect, useState } from 'react';
import { generatePath, Params, useNavigate, useParams } from 'react-router-dom';
import {
  Contract,
  ContractFile,
  getContract,
  Variation,
  VariationHistory,
  VariationMessage,
  VariationStatus,
} from '../../common/api';
import { CONTRACT, VARIATION_EXISTING } from '../../common/router-util/routes';
import { PageHeader } from '../../components/layout/PageHeader';
import { ChipOk } from '../../styles/chips';
import {
  closeVariation,
  createFiles,
  CreateUpdatePayload,
  createVariation,
  getVariationOrNextId,
  listVariationHistory,
  rejectVariation,
  updateVariation,
} from '../../common/api/variations';
import {
  Controller,
  FormProvider,
  SubmitHandler,
  useForm,
  useFormContext,
} from 'react-hook-form';
import { validateNumericalField } from '../bank/BankCreateDialog.util';
import { LoadingButton } from '@mui/lab';
import { formatCurrency } from '../../common/format';
import { VariationsTaskSearch } from './VariationsTaskSearch';
import { useDialogState } from '../../components/dialog/dialog.hooks';
import {
  canSubmit,
  getNextIdentifier,
  getVariationDetails,
  VariationRowItem,
} from './variationsPage.utils';
import { StyledTable, StyledTableRow } from '../../styles';
import { grey600, red700 } from '../../styles/colours';
import DeleteIcon from '@mui/icons-material/Delete';
import { SkeletonTable } from '../../components/skeleton-table';
import { useSnackbar } from '../../common/hooks/useSnackbar';
import { VariationNewTask } from './VariationNewTask';
import { VariationStatusChip } from './VariationStatusChip';
import { VariationWithdraw } from './VariationWithdraw';
import { VariationSummary } from './VariationSummary';
import { useVariationBreadCrumb } from './variation.hook';
import { LocalVariationFile, VariationFileDrawer } from './VariationFileDrawer';
import { VariationsChat } from './VariationChat';
import RevisionSelect from '../contract-page/components/RevisionSelect';

const NAME_FIELD = 'nameField';
const IDENTIFIER_FIELD = 'numberField';
const AV_ID_FIELD = 'avNumberField';
const REVISION_FIELD = 'revisionField';

type FormData = {
  [NAME_FIELD]: string;
  [IDENTIFIER_FIELD]: string;
  [AV_ID_FIELD]: string;
  [REVISION_FIELD]?: string;
};

type VariationsPageProps = {
  isAnticipated?: boolean;
};

export function VariationsPage({ isAnticipated = false }: VariationsPageProps) {
  const navigate = useNavigate();
  const { contractId, variationId } = useParams() as Params;
  const { showAlert, SnackbarComponent } = useSnackbar();
  const {
    isVisible: isSearchVisible,
    close: closeSearch,
    open: openSearch,
  } = useDialogState();
  const {
    isVisible: isAddTaskVisible,
    close: closeAddTask,
    open: openAddTask,
  } = useDialogState();
  const {
    isVisible: isWithdrawVisible,
    close: closeWithdraw,
    open: openWithdraw,
  } = useDialogState();
  const {
    isVisible: isFileDrawerVisible,
    close: closeFileDrawer,
    open: openFileDrawer,
  } = useDialogState();
  const {
    isVisible: isMessagesVisible,
    close: closeMessage,
    open: openMessages,
  } = useDialogState();

  const formMethods = useForm<FormData>();
  const { setValue, handleSubmit, watch } = formMethods;

  const [id, setId] = useState<string | undefined>(variationId);
  const [isSaveDraft, setIsSaveDraft] = useState<boolean>(false);
  const [loadError, setLoadError] = useState<boolean>(false);
  const [variations, setVariations] = useState<Array<VariationRowItem>>([]);
  const [variationFiles, setVariationFiles] = useState<
    Array<LocalVariationFile>
  >([]);
  const [messages, setMessages] = useState<Array<VariationMessage>>([]);
  const [selectedFiles, setSelectedFiles] = useState<Array<ContractFile>>([]);
  const [isRejectedOnly, setIsRejectedOnly] = useState<boolean>(false);
  const [variationIdentifier, setVariationIdentifier] = useState<string>('');

  const nameField = watch(NAME_FIELD);

  const { data: contract } = useQuery<Contract>(['contract', contractId], () =>
    getContract(contractId || '')
  );

  const { mutate: mutateReject, isLoading } = useMutation(
    (payload: { isNewRevision: boolean }) => {
      return rejectVariation({
        contractId: contractId || '',
        variationId: variationId || '',
        isNewRevision: payload.isNewRevision,
      });
    },
    {
      onSuccess: variation => {
        refetchVariation();
        if (variation.revisedVariationId) {
          navigate(
            generatePath(VARIATION_EXISTING, {
              contractId,
              variationId: variation.revisedVariationId,
            })
          );
        }
      },
    }
  );

  const {
    data: variation,
    isLoading: isLoadingVariation,
    isFetching: isFetchingVariation,
    refetch: refetchVariation,
  } = useQuery<Variation | { nextSvId: number; nextAvId: number }>(
    ['getVariation', id],
    () =>
      getVariationOrNextId({
        contractId: contractId || '',
        variationId: id,
      }),
    {
      onSuccess: variation => {
        const details = getVariationDetails(variation);
        setValue(NAME_FIELD, details.name);
        setValue(IDENTIFIER_FIELD, details.identifier);
        setValue(AV_ID_FIELD, details.anticipatedId);
        !isAnticipated && details.identifier
          ? setVariationIdentifier(details.identifier)
          : setVariationIdentifier(details.anticipatedId);
        if ('variationItems' in variation) {
          setVariations(variation.variationItems);
        }
        if ('revision' in variation) {
          setValue(REVISION_FIELD, variation.revision?.toString() || '');
        }
        if ('variationFiles' in variation) {
          setVariationFiles(() =>
            variation.variationFiles.map(f => ({
              id: f.id,
              name: f.name,
              createdAt: f.createdAt,
              path: f.path,
              contentType: f.mimetype,
              uploadedBy: f.uploader.email,
            }))
          );
        }
        if ('variationMessages' in variation) {
          setMessages([...variation.variationMessages]);
        }
      },
      onError: () => {
        setLoadError(true);
      },
    }
  );

  const { data: historyData = [] } = useQuery<VariationHistory[]>({
    queryKey: ['variationHistory', contractId, id],
    queryFn: () =>
      listVariationHistory({
        variationId: id || '',
      }),
    enabled: !!id,
  });

  const { mutate: mutateCreate, isLoading: isLoadingMutateCreate } =
    useMutation(
      (payload: CreateUpdatePayload) => createVariation(contractId!, payload),
      {
        onSuccess: async variation => {
          await createFiles(
            { variationId: variation.id },
            variationFiles,
            selectedFiles
          );
          navigate(
            generatePath(VARIATION_EXISTING, {
              contractId,
              variationId: variation.id,
            })
          );
          showAlert('Variation saved.', 'success');
          setId(variation.id);
          setIsSaveDraft(false);
          setVariations([...variation.variationItems]);
          setMessages([...variation.variationMessages]);
        },
        onError: () => {
          showAlert('There was an error saving the Variation.', 'error');
        },
      }
    );

  const { mutate: mutateUpdate, isLoading: isLoadingMutateUpdate } =
    useMutation(
      (payload: CreateUpdatePayload) =>
        updateVariation(contractId!, variationId!, payload),
      {
        onSuccess: variation => {
          showAlert('Variation update.', 'success');
          setIsSaveDraft(false);
          setVariations([...variation.variationItems]);
          if (variation.status !== VariationStatus.Draft) {
            refetchVariation();
          }
        },
        onError: () => {
          showAlert('There was an error updating the Variation.', 'error');
        },
      }
    );

  const { mutate: mutateDelete, isLoading: isLoadingDelete } = useMutation(
    () => closeVariation(variationId!, contractId!),
    {
      onSuccess: () => {
        navigate(
          generatePath(CONTRACT, {
            contractId: contractId,
          })
        );
      },
      onError: () => {
        showAlert('There was an error deleting the Variation.', 'error');
      },
    }
  );

  useEffect(() => {
    setId(variationId);
  }, [variationId]);

  const variationData = variation && 'id' in variation ? variation : null;
  const isDraft = !id || variationData?.status === VariationStatus.Draft;
  const isClosed = variationData?.isClosed;
  const isSubmitted = variationData?.status === VariationStatus.Submitted;
  const isDraftRevision =
    variationData && isDraft && variationData.revision !== null;
  const isFurtherInfoRequested =
    variationData?.status === VariationStatus.FurtherInfoRequested;

  const variationHeading = variationData
    ? `${variationData.name}${
        variationData.revision ? ` (Rev: ${variationData.revision})` : ''
      } SV# ${variationData.identifier}`
    : '';

  const variationRevision = variationData?.revision
    ? variationData?.revision
    : null;

  const onRevisionChange = useCallback(
    (event: SelectChangeEvent) => {
      const {
        target: { value },
      } = event;
      navigate(
        generatePath(VARIATION_EXISTING, {
          contractId,
          variationId: value,
        })
      );
    },
    [contractId, navigate]
  );

  const onSubmit: SubmitHandler<FormData> = useCallback(
    async data => {
      if (variationId) {
        mutateUpdate({
          name: data[NAME_FIELD],
          variationItems: variations,
          status: isSaveDraft
            ? VariationStatus.Draft
            : VariationStatus.Submitted,
        });
      } else {
        mutateCreate({
          name: data[NAME_FIELD],
          variationItems: variations,
          variationMessages: messages.map(m => ({
            message: m.message,
          })),
          status: isSaveDraft
            ? VariationStatus.Draft
            : VariationStatus.Submitted,
        });
      }
    },
    [isSaveDraft, variationId, variations, messages, mutateCreate, mutateUpdate]
  );

  useVariationBreadCrumb({ contract, name: nameField || '' });

  if (loadError) {
    return (
      <Box display="flex" alignItems="center">
        <Typography>There was an error loading the variation</Typography>
      </Box>
    );
  }

  return (
    <Box>
      <SnackbarComponent />
      {isLoadingVariation ? (
        <SkeletonTable />
      ) : (
        <FormProvider {...formMethods}>
          <form noValidate onSubmit={handleSubmit(onSubmit)}>
            <PageHeader
              title={
                isDraftRevision ? (
                  <DraftHeaderRevisedField isClosed={isClosed} />
                ) : isDraft || isFurtherInfoRequested ? (
                  <DraftHeaderField
                    isClosed={isClosed}
                    isDraft={variationData?.status === VariationStatus.Draft}
                    isFurtherInfoRequested={
                      variationData?.status ===
                      VariationStatus.FurtherInfoRequested
                    }
                    isAnticipated={isAnticipated}
                  />
                ) : variationData ? (
                  <>
                    <Typography variant="h6" sx={{ fontSize: '20px' }}>
                      {variationHeading}
                    </Typography>
                    <VariationStatusChip
                      isCustomer={false}
                      isClosed={isClosed}
                      status={variationData?.status || VariationStatus.Draft}
                    />
                  </>
                ) : null
              }
              actions={
                <>
                  {variationData?.status === VariationStatus.Rejected && (
                    <Box display="flex" alignItems="center" gap={2}>
                      <LoadingButton
                        variant="contained"
                        color="warning"
                        type="button"
                        fullWidth
                        loading={isLoading && !isRejectedOnly}
                        disabled={isLoading}
                        onClick={() => {
                          setIsRejectedOnly(false);
                          mutateReject({
                            isNewRevision: true,
                          });
                        }}
                      >
                        Keep variation
                      </LoadingButton>
                      <LoadingButton
                        variant="contained"
                        type="button"
                        color="error"
                        fullWidth
                        loading={isLoading && isRejectedOnly}
                        disabled={isLoading}
                        sx={{ whiteSpace: 'nowrap' }}
                        onClick={() => {
                          setIsRejectedOnly(true);
                          mutateReject({
                            isNewRevision: false,
                          });
                        }}
                      >
                        Close variation
                      </LoadingButton>
                    </Box>
                  )}
                  {(isDraft || isFurtherInfoRequested) && !isClosed && (
                    <>
                      <Button
                        variant="outlined"
                        onClick={() =>
                          navigate(
                            generatePath(CONTRACT, {
                              contractId,
                            })
                          )
                        }
                      >
                        Cancel
                      </Button>
                      {id && (
                        <LoadingButton
                          variant="outlined"
                          color="error"
                          sx={{ color: red700, borderColor: red700 }}
                          loading={isLoadingDelete}
                          onClick={() => mutateDelete()}
                        >
                          Close Variation
                        </LoadingButton>
                      )}

                      <LoadingButton
                        color="warning"
                        type="submit"
                        variant="contained"
                        loading={
                          (isLoadingMutateCreate || isLoadingMutateUpdate) &&
                          isSaveDraft
                        }
                        disabled={isLoadingMutateCreate && !isSaveDraft}
                        onClick={() => setIsSaveDraft(true)}
                      >
                        Save Anticipation Variation
                      </LoadingButton>
                      {!isAnticipated && (
                        <LoadingButton
                          type="submit"
                          variant="contained"
                          loading={
                            (isLoadingMutateCreate || isLoadingMutateUpdate) &&
                            !isSaveDraft
                          }
                          disabled={
                            (isLoadingMutateCreate && isSaveDraft) ||
                            !canSubmit(variations)
                          }
                          onClick={() => setIsSaveDraft(false)}
                        >
                          Submit Variation
                        </LoadingButton>
                      )}
                    </>
                  )}
                  {isSubmitted && (
                    <LoadingButton
                      variant="outlined"
                      color="error"
                      sx={{ color: red700, borderColor: red700 }}
                      loading={false}
                      disabled={false}
                      onClick={openWithdraw}
                    >
                      Withdraw Variation
                    </LoadingButton>
                  )}
                </>
              }
              sx={historyData.length > 1 ? { mt: 2, mb: 2 } : undefined}
            />
            {historyData.length > 1 && (
              <RevisionSelect
                data={historyData}
                label="Revision"
                id="variance-revision"
                value={id || ''}
                onChange={onRevisionChange}
              />
            )}
          </form>

          {isFetchingVariation ? (
            <SkeletonTable rows={1} />
          ) : (
            <VariationSummary variationData={variationData} />
          )}

          <Box display="flex" justifyContent="space-between">
            <Box display="flex" gap="16px">
              {(isDraft || isFurtherInfoRequested) && (
                <>
                  <Button variant="outlined" onClick={openAddTask}>
                    Create New Task
                  </Button>
                  <Button variant="outlined" onClick={openSearch}>
                    Add Existing Task
                  </Button>
                </>
              )}
            </Box>
            <Box display="flex" gap="16px">
              <Button variant="outlined" onClick={openMessages}>
                Comments
              </Button>
              <Button variant="outlined" onClick={openFileDrawer}>
                Attachments
              </Button>
            </Box>
          </Box>

          {isMessagesVisible && (
            <VariationsChat
              permissionToSend={
                variationData?.status !== VariationStatus.Rejected &&
                variationData?.status !== VariationStatus.Approved
              }
              variationId={variationId!}
              messages={messages}
              onClose={closeMessage}
              onSend={message => {
                setMessages([...messages, message]);
              }}
              onDelete={messageId => {
                setMessages(
                  messages.filter(message => message.id !== messageId)
                );
              }}
              onUpdate={message => {
                setMessages(
                  messages.map(m => (m.id === message.id ? message : m))
                );
              }}
            />
          )}

          <VariationFileDrawer
            canDelete={isDraft}
            canUpload={isDraft}
            variationId={
              variation && 'id' in variation ? variation.id : undefined
            }
            variationFiles={variationFiles}
            open={isFileDrawerVisible}
            onClose={closeFileDrawer}
            addFile={file => {
              setVariationFiles(prev => [...prev, file]);
            }}
            setFiles={files => {
              setVariationFiles(() => files);
            }}
            setSelectedFiles={files => setSelectedFiles(() => files)}
          />

          {isSearchVisible && contractId && (
            <VariationsTaskSearch
              contractId={contractId}
              variationItems={variations}
              addVariations={variations => setVariations([...variations])}
              handleClose={closeSearch}
            />
          )}
          {isAddTaskVisible && (
            <VariationNewTask
              addVariation={variation => {
                variation.identifier = getNextIdentifier(
                  variations,
                  variationIdentifier,
                  variationRevision
                );
                variations.push(variation);
                setVariations([...variations]);
              }}
              handleClose={closeAddTask}
            />
          )}
          {isWithdrawVisible && contractId && variationId && (
            <VariationWithdraw
              contractId={contractId}
              variationId={variationId}
              onComplete={refetchVariation}
              handleClose={closeWithdraw}
            />
          )}
          {isDraft ? (
            <DraftTableBody
              isLoading={isFetchingVariation}
              variations={variations}
              setVariations={setVariations}
            />
          ) : (
            <SubmittedTableBody
              isLoading={isFetchingVariation}
              variations={variations}
            />
          )}
        </FormProvider>
      )}
    </Box>
  );
}

type DraftHeaderFieldProps = {
  isClosed?: boolean;
  isDraft?: boolean;
  isFurtherInfoRequested?: boolean;
  isAnticipated?: boolean;
};

const DraftHeaderField = ({
  isClosed = false,
  isAnticipated = false,
  isDraft = false,
  isFurtherInfoRequested = false,
}: DraftHeaderFieldProps) => {
  const {
    control,
    formState: { errors },
  } = useFormContext<FormData>();

  return (
    <>
      <Controller
        name={
          isAnticipated || isDraft || isFurtherInfoRequested
            ? AV_ID_FIELD
            : IDENTIFIER_FIELD
        }
        control={control}
        defaultValue={''}
        rules={{
          required: 'Required',
          validate: {
            numerical: value => validateNumericalField(value),
          },
        }}
        render={({ field }) => (
          <TextField
            {...field}
            disabled
            required
            InputLabelProps={{ shrink: true }}
            type="text"
            size="small"
            label={
              isAnticipated || isDraft || isFurtherInfoRequested
                ? 'AV #'
                : 'SV #'
            }
            inputProps={{
              inputMode: 'numeric',
              pattern: '[0-9]*',
            }}
            sx={{ width: '100px' }}
            error={!!errors[AV_ID_FIELD]}
            helperText={errors[AV_ID_FIELD]?.message?.toString()}
          />
        )}
      />
      <Controller
        name={NAME_FIELD}
        control={control}
        defaultValue={''}
        rules={{
          required: 'Name is required',
        }}
        render={({ field }) => (
          <TextField
            {...field}
            required
            size="small"
            label="Variation Name"
            placeholder="Enter Variation Name"
            error={!!errors[NAME_FIELD]}
            helperText={errors[NAME_FIELD]?.message?.toString()}
          />
        )}
      />
      <VariationStatusChip
        isCustomer={false}
        isClosed={isClosed}
        status={
          isFurtherInfoRequested
            ? VariationStatus.FurtherInfoRequested
            : VariationStatus.Draft
        }
      />
    </>
  );
};

const DraftHeaderRevisedField = ({
  isClosed = false,
}: {
  isClosed?: boolean;
}) => {
  const {
    control,
    formState: { errors },
  } = useFormContext<FormData>();

  return (
    <>
      <Controller
        name={IDENTIFIER_FIELD}
        control={control}
        defaultValue={''}
        rules={{
          required: 'Required',
          validate: {
            numerical: value => validateNumericalField(value),
          },
        }}
        render={({ field }) => (
          <TextField
            {...field}
            required
            disabled
            InputLabelProps={{ shrink: true }}
            type="text"
            size="small"
            label="Variation #"
            inputProps={{
              inputMode: 'numeric',
              pattern: '[0-9]*',
            }}
            sx={{ width: '100px' }}
            error={!!errors[IDENTIFIER_FIELD]}
            helperText={errors[IDENTIFIER_FIELD]?.message?.toString()}
          />
        )}
      />
      <Controller
        name={REVISION_FIELD}
        control={control}
        defaultValue={''}
        render={({ field }) => (
          <TextField
            {...field}
            disabled
            InputLabelProps={{ shrink: true }}
            type="text"
            size="small"
            label="Revision"
            sx={{ width: '100px' }}
            error={!!errors[REVISION_FIELD]}
            helperText={errors[REVISION_FIELD]?.message?.toString()}
          />
        )}
      />
      <Controller
        name={NAME_FIELD}
        control={control}
        defaultValue={''}
        rules={{
          required: 'Name is required',
        }}
        render={({ field }) => (
          <TextField
            {...field}
            required
            size="small"
            label="Variation Name"
            placeholder="Enter Variation Name"
            error={!!errors[NAME_FIELD]}
            helperText={errors[NAME_FIELD]?.message?.toString()}
          />
        )}
      />
      <VariationStatusChip
        isCustomer={false}
        isClosed={isClosed}
        status={VariationStatus.Draft}
      />
    </>
  );
};

const DraftTableBody = ({
  isLoading = false,
  variations,
  setVariations,
}: {
  isLoading?: boolean;
  variations: VariationRowItem[];
  setVariations: (variations: VariationRowItem[]) => void;
}) => {
  const handleUpdateVariance = useCallback(
    (variation: VariationRowItem, value: string) => {
      const variance = Number(value);
      if (!isNaN(variance)) {
        variation.variance = variance;
        variation.newTaskValue = variation.currentTaskValue + variance;
        setVariations([...variations]);
      } else {
        variation.variance = 0;
        variation.newTaskValue = variation.currentTaskValue;
        setVariations([...variations]);
      }
    },
    [variations, setVariations]
  );

  const handleUpdateTaskValue = useCallback(
    (variation: VariationRowItem, value: string) => {
      const newTaskValue = Number(value);
      if (isNaN(newTaskValue)) return;

      if (variation.isNewTask) {
        variation.newTaskValue = Number(value);
        setVariations([...variations]);
      } else {
        variation.newTaskValue = Number(value);
        variation.variance = newTaskValue - variation.currentTaskValue;
        setVariations([...variations]);
      }
    },
    [variations, setVariations]
  );

  const handleRemoveVariation = useCallback(
    (index: number) => {
      if (variations[index].id) {
        variations[index].deleted = true;
      } else {
        variations.splice(index, 1);
      }
      setVariations([...variations]);
    },
    [variations, setVariations]
  );

  if (isLoading) {
    return <SkeletonTable rows={3} />;
  }

  return (
    <StyledTable sx={{ mt: '16px', overflow: 'scroll' }}>
      <TableHead
        sx={{
          th: {
            fontSize: '12px',
            padding: '10px',
            color: grey600,
          },
        }}
      >
        <TableRow>
          <TableCell>ID</TableCell>
          <TableCell>Task Name</TableCell>
          <TableCell>Current Task Value</TableCell>
          <TableCell>Currently Approved</TableCell>
          <TableCell>Variance</TableCell>
          <TableCell>New Task Value</TableCell>
          <TableCell></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {variations.map((variation, index) => {
          const isNewTask = variation.isNewTask;
          return variation.deleted ? null : (
            <StyledTableRow key={index}>
              <TableCell>{variation.identifier}</TableCell>
              <TableCell>
                {variation.description} {isNewTask && <ChipOk label="New" />}
              </TableCell>
              <TableCell>
                {!isNewTask && formatCurrency(variation.currentTaskValue)}
              </TableCell>
              <TableCell>
                {!isNewTask && formatCurrency(variation.currentTaskApproved)}
              </TableCell>
              <TableCell>
                {!isNewTask && (
                  <TextField
                    type="text"
                    size="small"
                    inputProps={{
                      inputMode: 'numeric',
                      pattern: '[0-9]*',
                    }}
                    sx={{ width: '180px' }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                    }}
                    value={variation.variance}
                    onChange={e =>
                      handleUpdateVariance(variation, e.target.value)
                    }
                  />
                )}
              </TableCell>
              <TableCell>
                <TextField
                  type="text"
                  size="small"
                  inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                  sx={{ width: '180px' }}
                  value={variation.newTaskValue}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                  }}
                  onChange={e =>
                    handleUpdateTaskValue(variation, e.target.value)
                  }
                />
              </TableCell>
              <TableCell sx={{ textAlign: 'right' }}>
                <IconButton onClick={() => handleRemoveVariation(index)}>
                  <DeleteIcon />
                </IconButton>
              </TableCell>
            </StyledTableRow>
          );
        })}
      </TableBody>
    </StyledTable>
  );
};

const SubmittedTableBody = ({
  isLoading = false,
  variations,
}: {
  isLoading?: boolean;
  variations: VariationRowItem[];
}) => {
  if (isLoading) {
    return <SkeletonTable rows={3} />;
  }
  return (
    <StyledTable sx={{ mt: '16px', overflow: 'scroll' }}>
      <TableHead
        sx={{
          th: {
            fontSize: '12px',
            padding: '10px',
            color: grey600,
          },
        }}
      >
        <TableRow>
          <TableCell>ID</TableCell>
          <TableCell>Task Name</TableCell>
          <TableCell>Current Task Value</TableCell>
          <TableCell>Currently Approved</TableCell>
          <TableCell>Variance</TableCell>
          <TableCell>New Task Value</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {variations.map((variation, index) => {
          const isNewTask = variation.isNewTask;
          return (
            <StyledTableRow key={index}>
              <TableCell>{variation.identifier}</TableCell>
              <TableCell>
                {variation.description} {isNewTask && <ChipOk label="New" />}
              </TableCell>
              <TableCell>
                {!isNewTask && formatCurrency(variation.currentTaskValue)}
              </TableCell>
              <TableCell>
                {!isNewTask && formatCurrency(variation.currentTaskApproved)}
              </TableCell>
              <TableCell>
                {!isNewTask && formatCurrency(variation.variance)}
              </TableCell>
              <TableCell>{formatCurrency(variation.newTaskValue)}</TableCell>
            </StyledTableRow>
          );
        })}
      </TableBody>
    </StyledTable>
  );
};
