import React, {
  ReactNode,
  createContext,
  useState,
  useMemo,
  useContext,
} from 'react';
import { TaskGroup } from '../../../common/api';

type TaskGroupType = {
  isEdit: boolean;
  expanded: Array<string>;
  setExpanded: (ids: Array<string>) => void;
  taskGroups: Array<TaskGroup>;
  setTaskGroups: React.Dispatch<React.SetStateAction<TaskGroup[]>>;
  originalTaskGroups: Array<TaskGroup>;
  setOriginalTaskGroups: React.Dispatch<React.SetStateAction<TaskGroup[]>>;
};

const noop = () => {};

export const TaskGroupContext = createContext<TaskGroupType>({
  isEdit: false,
  expanded: [],
  setExpanded: noop,
  taskGroups: [],
  setTaskGroups: noop,
  originalTaskGroups: [],
  setOriginalTaskGroups: noop,
});

export const TaskGroupContextProvider = ({
  isEdit,
  children,
}: {
  isEdit: boolean;
  children: ReactNode;
}) => {
  const [expanded, setExpanded] = useState<Array<string>>(['0']);
  const [taskGroups, setTaskGroups] = useState<Array<TaskGroup>>([]);
  const [originalTaskGroups, setOriginalTaskGroups] = useState<
    Array<TaskGroup>
  >([]);

  const value = useMemo(
    () => ({
      isEdit,
      expanded,
      setExpanded,
      taskGroups,
      setTaskGroups,
      originalTaskGroups,
      setOriginalTaskGroups,
    }),
    [
      expanded,
      setExpanded,
      taskGroups,
      setTaskGroups,
      isEdit,
      originalTaskGroups,
      setOriginalTaskGroups,
    ]
  );

  return (
    <TaskGroupContext.Provider value={value}>
      {children}
    </TaskGroupContext.Provider>
  );
};

export const useTaskGroupContext = () => useContext(TaskGroupContext);
