import React, { useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import {
  Box,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';

import {
  CLAIMS_DUE_ON,
  CONTRACT_PAYMENT_TERMS_FIELD,
  FOR_WORK_COMPLETED_TO,
  FormData,
} from './AddContract.constants';

export const AddContractStep2 = () => {
  const formMethods = useFormContext<FormData>();
  const {
    control,
    formState: { errors },
    watch,
  } = formMethods;

  const [claimsDue, lastDayOf] = watch([CLAIMS_DUE_ON, FOR_WORK_COMPLETED_TO]);

  const dayOptions = useMemo(
    () => Array.from({ length: 30 }, (_, index) => index + 1),
    []
  );

  return (
    <Box>
      <Typography fontWeight="bold" variant="body1" mb={2}>
        Payment and Claim Details
      </Typography>

      <Box display="flex" gap={3} mb={3}>
        <Controller
          name={CONTRACT_PAYMENT_TERMS_FIELD}
          control={control}
          rules={{
            required: 'Payment terms is required',
            min: {
              value: 1,
              message: 'Payment terms must be a positive number',
            },
          }}
          render={({ field }) => (
            <TextField
              {...field}
              label="Payment Terms (Business Days)"
              type="number"
              fullWidth
              required
              error={!!errors[CONTRACT_PAYMENT_TERMS_FIELD]}
              helperText={errors[CONTRACT_PAYMENT_TERMS_FIELD]?.message}
            />
          )}
        />
      </Box>

      <Box display="flex" gap={3} mb={3}>
        <FormControl sx={{ flex: 1 }}>
          <InputLabel id="contract-claims-due-on">Claims are Due on</InputLabel>
          <Controller
            name={CLAIMS_DUE_ON}
            control={control}
            rules={{
              required: 'Claims are Due on is required',
            }}
            render={({ field }) => (
              <>
                <Select
                  {...field}
                  labelId="contract-claims-due-on"
                  label="Claims are Due on"
                  fullWidth
                  required
                  value={claimsDue}
                  error={!!errors[CLAIMS_DUE_ON]}
                >
                  {dayOptions.map(d => (
                    <MenuItem key={d} value={d}>
                      {d}
                    </MenuItem>
                  ))}
                  <MenuItem key={'last'} value={'last'}>
                    last
                  </MenuItem>
                </Select>
                <FormHelperText>day of the month</FormHelperText>
              </>
            )}
          />
        </FormControl>
        <FormControl sx={{ flex: 1 }}>
          <InputLabel id="contract-work-completed">
            For work completed up to
          </InputLabel>
          <Controller
            name={FOR_WORK_COMPLETED_TO}
            control={control}
            rules={{
              required: 'For work completed up to is required',
            }}
            render={({ field }) => (
              <>
                <Select
                  {...field}
                  labelId="contract-worked-completed"
                  label="For work completed up to"
                  required
                  value={lastDayOf}
                  error={!!errors[FOR_WORK_COMPLETED_TO]}
                >
                  {dayOptions.map(d => (
                    <MenuItem key={d} value={d}>
                      {d}
                    </MenuItem>
                  ))}
                  <MenuItem key={'last'} value={'last'}>
                    last
                  </MenuItem>
                </Select>
                <FormHelperText>day of the month</FormHelperText>
              </>
            )}
          />
        </FormControl>
      </Box>
    </Box>
  );
};
