import React, { FC } from 'react';

import {
  DOLLAR,
  ResponseBaseType,
  VariationInfoResource,
} from '../../../../common/api';
import { SkeletonTable } from '../../../../components/skeleton-table';
import { Box, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import {
  formatAmountByType,
  formatPercentageValue,
} from '../../../../common/format';
import { grey600 } from '../../../../styles/colours';
import { StyledCompactTableWithIdenticalRows } from '../../../../styles/table';
// import { useAuth } from '../../../../common/auth-provider/AuthProvider';

interface Props {
  data?: ResponseBaseType<VariationInfoResource>;
  contingencyBudget?: string;
}

export const SummaryTable: FC<Props> = ({ contingencyBudget, data }) => {
  // const { user } = useAuth();

  const {
    totalApproved = 0,
    totalAnticipated = 0,
    totalClaimed = 0,
  } = data?.data || {};

  const totalValue =
    Number(totalApproved) + Number(totalClaimed) + Number(totalAnticipated);

  return (
    <Box>
      {!data ? (
        <SkeletonTable />
      ) : (
        <>
          <StyledCompactTableWithIdenticalRows>
            <TableHead
              sx={{
                th: {
                  fontSize: '12px',
                  padding: '10px',
                  color: grey600,
                },
              }}
            >
              <TableRow>
                <TableCell>Total Available Contingency</TableCell>
                <TableCell>Actual Contingency Remaining</TableCell>
                <TableCell>Actual Contingency Used</TableCell>
                <TableCell>
                  Forecast Contingency Remaining (After Approved, Claimed &
                  Anticipated)
                </TableCell>
                <TableCell>
                  Forecast Contingency Used (After Approved, Claimed &
                  Anticipated)
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>
                  {formatAmountByType(Number(contingencyBudget), DOLLAR)}
                </TableCell>
                <TableCell>
                  {formatAmountByType(
                    Number(contingencyBudget) - Number(totalApproved),
                    DOLLAR
                  )}
                </TableCell>
                <TableCell>
                  {formatPercentageValue(
                    Number(totalApproved),
                    Number(contingencyBudget)
                  )}
                </TableCell>
                <TableCell>
                  {formatAmountByType(
                    Number(contingencyBudget) - totalValue,
                    DOLLAR
                  )}
                </TableCell>
                <TableCell>
                  {formatPercentageValue(totalValue, Number(contingencyBudget))}
                </TableCell>
              </TableRow>
            </TableBody>
          </StyledCompactTableWithIdenticalRows>
          <StyledCompactTableWithIdenticalRows
            sx={{ mb: '30px', borderTop: 'none' }}
          >
            <TableHead
              sx={{
                th: {
                  fontSize: '12px',
                  padding: '10px',
                  color: grey600,
                },
              }}
            >
              <TableRow>
                <TableCell>Total Approved Variations</TableCell>
                <TableCell>Total Claimed (Unapproved) Variations</TableCell>
                <TableCell>Total Anticipated Variations</TableCell>
                <TableCell>Total Approved, Claimed & Anticipated</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>
                  {formatAmountByType(Number(totalApproved), DOLLAR)}
                </TableCell>
                <TableCell>
                  {formatAmountByType(Number(totalClaimed), DOLLAR)}
                </TableCell>
                <TableCell>
                  {formatAmountByType(Number(totalAnticipated), DOLLAR)}
                </TableCell>
                <TableCell>{formatAmountByType(totalValue, DOLLAR)}</TableCell>
                <TableCell />
              </TableRow>
            </TableBody>
          </StyledCompactTableWithIdenticalRows>
        </>
      )}
    </Box>
  );
};
