import {
  Box,
  Button,
  SelectChangeEvent,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useMutation, useQuery } from '@tanstack/react-query';
import React, { useCallback, useState } from 'react';
import { generatePath, Params, useNavigate, useParams } from 'react-router-dom';
import {
  PERCENT,
  Variation,
  VariationHistory,
  VariationMessage,
  VariationStatus,
} from '../../common/api';
import { PageHeader } from '../../components/layout/PageHeader';
import { ChipOk } from '../../styles/chips';
import {
  acceptVariation,
  getVariation,
  listVariationHistory,
  rejectVariation,
} from '../../common/api/variations';
import { LoadingButton } from '@mui/lab';
import { formatAmountByType, formatCurrency } from '../../common/format';
import { useDialogState } from '../../components/dialog/dialog.hooks';
import { StyledTable, StyledTableRow } from '../../styles';
import { grey600, orange400, orange500 } from '../../styles/colours';
import { SkeletonTable } from '../../components/skeleton-table';
import { useSnackbar } from '../../common/hooks/useSnackbar';
import { VariationStatusChip } from './VariationStatusChip';
import { VariationSummary } from './VariationSummary';
import { useVariationBreadCrumb } from './variation.hook';
import { VariationReceivedRowItem } from './variationsPage.utils';
import { VariationAssignParent } from './VariationAssignParent';
import { VariationFileDrawer } from './VariationFileDrawer';
import { VariationsChat } from './VariationChat';
import { isContractor, isCustomer } from '../../common/access';
import { useAuth } from '../../common/auth-provider/AuthProvider';
import RevisionSelect from '../contract-page/components/RevisionSelect';
import { VARIATION_RECEIVED } from '../../common/router-util/routes';
import { VariationRequestInfo } from './VariationRequestInfo';

export function VariationsReceivedPage() {
  const { contractId, variationId } = useParams() as Params;
  const { showAlert, SnackbarComponent } = useSnackbar();
  const navigate = useNavigate();
  const { user } = useAuth();

  const {
    isVisible: isSearchVisible,
    close: closeSearch,
    open: openSearch,
  } = useDialogState();
  const {
    isVisible: isFileDrawerVisible,
    close: closeFileDrawer,
    open: openFileDrawer,
  } = useDialogState();
  const {
    isVisible: isMessagesVisible,
    close: closeMessage,
    open: openMessages,
  } = useDialogState();
  const {
    isVisible: isRequestInfoVisible,
    close: closeRequestInfo,
    open: openRequestInfo,
  } = useDialogState();

  const [loadError, setLoadError] = useState<boolean>(false);
  const [variations, setVariations] = useState<VariationReceivedRowItem[]>([]);
  const [messages, setMessages] = useState<Array<VariationMessage>>([]);
  const [variationItemIdToAssignTo, setVariationItemIdToAssignTo] =
    useState<string>('');

  const {
    data: variation,
    isLoading: isLoadingVariation,
    isFetching: isFetchingVariation,
    refetch,
  } = useQuery<Variation>(
    ['getVariation', variationId],
    () =>
      getVariation({
        contractId: contractId!,
        variationId: variationId!,
      }),
    {
      onSuccess: variation => {
        setVariations(variation.variationItems);
        setMessages(variation.variationMessages);
      },
      onError: () => setLoadError(true),
    }
  );

  const { data: historyData = [] } = useQuery<VariationHistory[]>({
    queryKey: ['variationHistory', contractId, variationId],
    queryFn: () =>
      listVariationHistory({
        variationId: variationId || '',
      }),
    enabled: !!variationId,
  });

  const { mutate: mutateAccept, isLoading: isLoadingAccept } = useMutation(
    () =>
      acceptVariation({
        contractId: contractId!,
        variationId: variationId!,
        variationItems: variations,
      }),
    {
      onSuccess: () => {
        showAlert('Variation accepted.', 'success');
        refetch();
      },
      onError: () => {
        showAlert('There was an error accepting the Variation.', 'error');
      },
    }
  );

  const { mutate: mutateReject, isLoading: isLoadingReject } = useMutation(
    () =>
      rejectVariation({ contractId: contractId!, variationId: variationId! }),
    {
      onSuccess: () => {
        showAlert('Variation accepted.', 'success');
        refetch();
      },
      onError: () => {
        showAlert('There was an error accepting the Variation.', 'error');
      },
    }
  );

  const variationData = variation;
  const isTopLevelContract = variationData?.contract.parentContractId === null;

  const handleAccept = useCallback(() => {
    mutateAccept();
  }, [mutateAccept]);

  const handleReject = useCallback(() => {
    mutateReject();
  }, [mutateReject]);

  const handleRevisionChange = useCallback(
    (event: SelectChangeEvent) => {
      const {
        target: { value },
      } = event;
      navigate(
        generatePath(VARIATION_RECEIVED, {
          contractId,
          variationId: value,
        })
      );
    },
    [contractId, navigate]
  );

  useVariationBreadCrumb({
    contract: variationData?.contract,
    name: variationData?.name || '',
  });

  if (loadError) {
    return (
      <Box display="flex" alignItems="center">
        <Typography>There was an error loading the variation</Typography>
      </Box>
    );
  }

  return (
    <Box>
      <SnackbarComponent />
      {isLoadingVariation ? (
        <SkeletonTable />
      ) : variationData ? (
        <>
          <PageHeader
            title={
              <>
                <Typography variant="h6" sx={{ fontSize: '20px' }}>
                  {variationData.name} #{variationData.identifier}
                </Typography>
                <VariationStatusChip
                  isCustomer
                  status={variationData?.status}
                />
              </>
            }
            actions={
              variationData.status === VariationStatus.Submitted &&
              (isCustomer(user, variationData?.contract) ||
                isContractor(user, variationData?.contract)) && (
                <>
                  <LoadingButton
                    color="error"
                    variant="contained"
                    loading={isLoadingReject}
                    disabled={isLoadingAccept || isLoadingReject}
                    onClick={handleReject}
                  >
                    Reject Variation
                  </LoadingButton>
                  <LoadingButton
                    color="warning"
                    variant="contained"
                    loading={false}
                    disabled={false}
                    onClick={openRequestInfo}
                  >
                    Request Further Info
                  </LoadingButton>
                  <LoadingButton
                    variant="contained"
                    loading={isLoadingAccept}
                    disabled={isLoadingAccept || isLoadingReject}
                    onClick={handleAccept}
                  >
                    Accept Variation
                  </LoadingButton>
                </>
              )
            }
            sx={historyData.length > 1 ? { mt: 2, mb: 2 } : undefined}
          />
          {historyData.length > 1 && (
            <RevisionSelect
              data={historyData}
              label="Revision"
              id="variance-revision"
              value={variationId || ''}
              onChange={handleRevisionChange}
            />
          )}
          {isSearchVisible && contractId && (
            <VariationAssignParent
              contractId={contractId}
              variationItems={variations}
              variationItemIdToAssignTo={variationItemIdToAssignTo}
              handleClose={closeSearch}
              assignToTask={taskId => {
                const variation = variations.find(
                  v => v.id === variationItemIdToAssignTo
                );
                if (variation) {
                  variation.parentTaskId = taskId;
                  setVariations([...variations]);
                }
              }}
            />
          )}

          {isRequestInfoVisible && contractId && variationId && (
            <VariationRequestInfo
              variationId={variationId}
              contractId={contractId}
              close={closeRequestInfo}
              refetch={refetch}
            />
          )}

          {isMessagesVisible && (
            <VariationsChat
              permissionToSend={
                variationData.status === VariationStatus.Submitted
              }
              variationId={variationId!}
              messages={messages}
              onClose={closeMessage}
              onSend={message => {
                setMessages([...messages, message]);
              }}
              onDelete={messageId => {
                setMessages(
                  messages.filter(message => message.id !== messageId)
                );
              }}
              onUpdate={message => {
                setMessages(
                  messages.map(m => (m.id === message.id ? message : m))
                );
              }}
            />
          )}

          <VariationFileDrawer
            canDelete={false}
            canUpload={false}
            variationId={variation.id}
            variationFiles={variation.variationFiles.map(v => ({
              id: v.id,
              contentType: v.mimetype,
              name: v.name,
              path: v.path,
              uploadedBy: v.uploader.email,
            }))}
            open={isFileDrawerVisible}
            onClose={closeFileDrawer}
          />

          {isFetchingVariation ? (
            <SkeletonTable rows={1} />
          ) : (
            <VariationSummary variationData={variationData} />
          )}

          <Box display="flex" justifyContent="space-between">
            <Box display="flex" gap="16px"></Box>
            <Box display="flex" gap="16px">
              <Button variant="outlined" onClick={openMessages}>
                Comments
              </Button>
              <Button variant="outlined" onClick={openFileDrawer}>
                Attachments
              </Button>
            </Box>
          </Box>

          {isFetchingVariation ? (
            <SkeletonTable rows={3} />
          ) : (
            <StyledTable sx={{ mt: '16px', overflow: 'scroll' }}>
              <TableHead
                sx={{
                  th: {
                    fontSize: '12px',
                    padding: '10px',
                    color: grey600,
                  },
                }}
              >
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>Task Name</TableCell>
                  <TableCell>Current Task Value</TableCell>
                  <TableCell>Currently Approved</TableCell>
                  <TableCell>Variance</TableCell>
                  <TableCell>New Task Value</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {variations.map((variation, index) => {
                  const isNewTask = variation.isNewTask;
                  return (
                    <StyledTableRow key={index}>
                      <TableCell>{variation.identifier}</TableCell>
                      <TableCell>
                        {variation.description}{' '}
                        {isNewTask && (
                          <ChipOk
                            label={
                              variation.parentTaskId ? 'New - Assigned' : 'New'
                            }
                          />
                        )}
                      </TableCell>
                      <TableCell>
                        {!isNewTask
                          ? variationData?.valueType === PERCENT
                            ? formatAmountByType(
                                Number(variation.currentTaskValue),
                                PERCENT
                              )
                            : formatCurrency(Number(variation.currentTaskValue))
                          : null}
                      </TableCell>
                      <TableCell>
                        {!isNewTask
                          ? variationData?.valueType === PERCENT
                            ? formatAmountByType(
                                Number(variation.currentTaskApproved),
                                PERCENT
                              )
                            : formatCurrency(
                                Number(variation.currentTaskApproved)
                              )
                          : null}
                      </TableCell>
                      <TableCell>
                        {!isNewTask
                          ? variationData?.valueType === PERCENT
                            ? formatAmountByType(
                                Number(variation.variance),
                                PERCENT
                              )
                            : formatCurrency(Number(variation.variance))
                          : null}
                      </TableCell>
                      <TableCell>
                        {isNewTask
                          ? variationData?.valueType === PERCENT
                            ? formatAmountByType(
                                Number(variation.newTaskValue),
                                PERCENT
                              )
                            : formatCurrency(Number(variation.newTaskValue))
                          : variationData?.valueType === PERCENT
                          ? formatAmountByType(
                              Number(
                                variation.currentTaskValue + variation.variance
                              ),
                              PERCENT
                            )
                          : formatCurrency(
                              Number(
                                variation.currentTaskValue + variation.variance
                              )
                            )}
                      </TableCell>
                      <TableCell>
                        {isNewTask &&
                          !isTopLevelContract &&
                          variationData.status ===
                            VariationStatus.Submitted && (
                            <Button
                              variant="contained"
                              sx={
                                variation.parentTaskId
                                  ? {
                                      backgroundColor: orange400,
                                      '&:hover': {
                                        backgroundColor: orange500,
                                      },
                                    }
                                  : {}
                              }
                              onClick={() => {
                                setVariationItemIdToAssignTo(variation.id);
                                openSearch();
                              }}
                            >
                              {variation.parentTaskId ? 'Re-assign' : 'Assign'}
                            </Button>
                          )}
                      </TableCell>
                    </StyledTableRow>
                  );
                })}
              </TableBody>
            </StyledTable>
          )}
        </>
      ) : null}
    </Box>
  );
}
