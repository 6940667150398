import React, { SyntheticEvent, useCallback, useState } from 'react';

import { Box, Dialog, DialogContent, Tab } from '@mui/material';
import { DialogClose, Heading, StyledTabs } from '../../../../styles';
import { MyBankingTab } from './MyBankingTab';
import { MyDetailsTab } from './MyDetailsTab';
import { useAuth } from '../../../../common/auth-provider/AuthProvider';
import { MyUsersTab } from './MyUsersTab';

type Props = {
  close: () => void;
};

export enum Tabs {
  Details,
  Users,
  PaymentDetails,
}

function MyOrganisationDialog({ close }: Props) {
  const { user } = useAuth();

  const [tabsValue, setTabsValue] = useState<Tabs>(Tabs.Details);

  const handleChangeTabsValue = useCallback(
    (event: SyntheticEvent<Element, Event>, value: Tabs) => {
      setTabsValue(value);
    },
    []
  );

  return (
    <Dialog open fullWidth maxWidth="lg" onClose={close}>
      <DialogContent>
        <Heading>My Organisation</Heading>
        <DialogClose onClose={close} />

        <Box>
          <StyledTabs
            sx={{ mb: 2 }}
            textColor="inherit"
            value={tabsValue}
            onChange={handleChangeTabsValue}
          >
            <Tab label="Details" value={Tabs.Details} />
            <Tab label="Users" value={Tabs.Users} />
            <Tab label="Payment Details" value={Tabs.PaymentDetails} />
          </StyledTabs>

          {tabsValue === Tabs.Details && user?.accountId && <MyDetailsTab />}
          {tabsValue === Tabs.Users && <MyUsersTab />}
          {tabsValue === Tabs.PaymentDetails && <MyBankingTab />}
        </Box>
      </DialogContent>
    </Dialog>
  );
}

export { MyOrganisationDialog };
