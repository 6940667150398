import React, { useCallback, useEffect } from 'react';
import { Contract } from '../../common/api';
import { CONTRACT, PROJECT, PROJECTS } from '../../common/router-util/routes';
import { generatePath, useOutletContext } from 'react-router-dom';
import { DashboardOutletContext } from '../../layouts/dashboard-layout';
import { HeaderBreadcrumbs } from '../../components/header-breadcrumbs';

export const useVariationBreadCrumb = ({
  contract,
  name,
}: {
  contract?: Contract;
  name: string;
}) => {
  const { setTitle } = useOutletContext<DashboardOutletContext>();

  const buildBreadcrumb = useCallback(
    (contract: Contract) => {
      const b = [];
      b.push({
        url: PROJECTS,
        label: 'Projects',
        contractorName: '',
      });

      b.push({
        url: generatePath(PROJECT, { projectId: contract.projectId }),
        label: contract?.project.name,
        contractorName: '',
      });

      return [
        ...b,
        {
          url: generatePath(CONTRACT, {
            contractId: contract.id,
          }),
          label: contract?.description,
          contractorName: contract?.contractorAccount.name,
        },
        {
          url: '',
          label: name,
          contractorName: '',
        },
      ];
    },
    [name]
  );

  useEffect(() => {
    if (contract) {
      setTitle(<HeaderBreadcrumbs links={buildBreadcrumb(contract)} />);
    }

    return () => {
      setTitle(<></>);
    };
  }, [contract, name, setTitle, buildBreadcrumb]);
};
