import { HeadCell } from '../../../../../components/sorted-table-head/SortedTable.hooks';
import {
  ClaimItem,
  ClaimType,
  DOLLAR,
  PreviousClaimSnapshotData,
  Task,
  ValueType,
} from '../../../../../common/api';
import { getCostToComplete } from '../../../../contract-page/views/contract-claim-view/components/CurrentClaimTab.utils';

const headCells: Array<HeadCell> = [
  {
    id: 'identifier',
    numeric: false,
    disablePadding: false,
    label: 'ID',
  },
  {
    id: 'description',
    numeric: false,
    disablePadding: false,
    label: 'TaskName',
  },
  {
    id: 'taskValue',
    numeric: true,
    disablePadding: false,
    label: 'Total Task Value',
  },
  {
    id: 'previouslyApproved',
    numeric: true,
    disablePadding: false,
    label: 'Previously Approved',
  },
  {
    id: 'progress',
    numeric: true,
    disablePadding: false,
    label: 'Progress',
  },
  {
    id: 'claimed',
    numeric: true,
    disablePadding: false,
    label: 'Value Claimed',
  },
  {
    id: 'adjust',
    numeric: true,
    disablePadding: false,
    label: '',
    disable: true,
  },
];

export type ClaimItemRow = {
  id: string;
  claimType: ClaimType;
  value: number;
  claimed: number;
  taskId: string;
  taskValue: number;
  approved: number;
  paid: number;
  progress: number;
  valueType: ValueType;
  description: string;
  previouslyApproved: number;
  identifier: number;
};

function createClaimTableRow(payload: {
  claimItem: ClaimItem;
  tasks?: Array<Task>;
  previousClaimSnapshot: PreviousClaimSnapshotData;
}) {
  const { claimItem, tasks, previousClaimSnapshot } = payload;
  const task = tasks?.find(task => task.id === claimItem.taskId);
  const previousSnapshotValues = task ? previousClaimSnapshot[task?.id] : null;
  const claimed = claimItem?.adjust?.value || claimItem.value;

  const costToComplete = task
    ? getCostToComplete(
        Number(task?.value || 0),
        previousSnapshotValues?.paid || 0,
        ((previousSnapshotValues?.approved || 0) * 100) /
          (previousSnapshotValues?.taskValue || 1) || 0,
        claimed
      )
    : 0;

  return {
    id: claimItem.id,
    claimType: claimItem.claimType,
    value: claimItem.value,
    claimed,
    taskId: task?.id || '',
    taskValue: Number(task?.value || 0),
    approved:
      ((previousSnapshotValues?.approved || 0) * 100) /
        (previousSnapshotValues?.taskValue || 1) || 0,
    paid: previousSnapshotValues?.paid || 0,
    progress: costToComplete,
    valueType: task?.valueType || DOLLAR,
    description: task?.description || '',
    previouslyApproved:
      (((previousSnapshotValues?.approved || 0) +
        (previousSnapshotValues?.paid || 0)) *
        100) /
      (previousSnapshotValues?.taskValue || 1),
    identifier: Number(task?.identifier || 0),
  };
}

export { headCells, createClaimTableRow };
