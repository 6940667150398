import React, { useCallback, useEffect, useMemo } from 'react';
import { Stack, TextField } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { LoadingButton } from '@mui/lab';
import { Controller, useForm } from 'react-hook-form';
import { DEFAULT_DATE_FORMAT } from '../../../common/format';
import { useProject } from '../../../common/hooks/useProject';

const SERVER_DATE = 'serverDate';

interface Form {
  [SERVER_DATE]: string;
}

interface Props {
  projectId: string;
}
function ServerDate(props: Props) {
  const { projectId } = props;
  const { mutateUpdateAccount, isMutateLoading, project } =
    useProject(projectId);

  const {
    control,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm<Form>({
    mode: 'onChange',
    defaultValues: useMemo(() => {
      return {
        [SERVER_DATE]: project?.systemDate,
      };
    }, [project?.systemDate]),
  });

  const serverDate = watch();

  const onSubmit = useCallback(
    async (form: Form) => {
      await mutateUpdateAccount({
        id: project?.id!,
        systemDate: new Date(
          new Date(form[SERVER_DATE]).setHours(13)
        ).toISOString(),
      });
    },
    [project?.id, mutateUpdateAccount]
  );

  const handleResetAccountDate = useCallback(async () => {
    await mutateUpdateAccount({
      id: project?.id!,
      systemDate: null,
    });
  }, [project?.id, mutateUpdateAccount]);

  useEffect(() => {
    reset({
      [SERVER_DATE]: project?.systemDate,
    });
  }, [project?.systemDate, reset]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack flexDirection="row" gap={2} alignItems="center">
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Controller
            name={SERVER_DATE}
            defaultValue=""
            control={control}
            rules={{
              required: 'date is required',
              validate: values =>
                dayjs(values).isValid() || 'Invalid data format',
            }}
            render={({ field, fieldState }) => (
              <DatePicker
                {...field}
                label="Server Date"
                inputFormat={DEFAULT_DATE_FORMAT}
                renderInput={params => {
                  return (
                    <TextField
                      {...params}
                      required
                      size="small"
                      style={{ height: '40px' }}
                      error={Boolean(
                        (fieldState.error && fieldState.isTouched) ||
                          !!errors[SERVER_DATE]
                      )}
                      helperText={fieldState.error?.message}
                    />
                  );
                }}
              />
            )}
          />
        </LocalizationProvider>
        <LoadingButton
          loading={isMutateLoading}
          type="submit"
          variant="contained"
          color="primary"
          disabled={
            !!project?.systemDate &&
            dayjs(serverDate[SERVER_DATE]).format() ===
              dayjs(project?.systemDate).format()
          }
        >
          Set date
        </LoadingButton>
        {project?.systemDate && (
          <LoadingButton
            variant="contained"
            color="error"
            disabled={!project?.systemDate}
            onClick={handleResetAccountDate}
            loading={isMutateLoading}
          >
            Reset date
          </LoadingButton>
        )}
      </Stack>
    </form>
  );
}

export { ServerDate };
