import React, {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Box, Button, IconButton } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import sortBy from 'lodash/sortBy';
import GridLayout from 'react-grid-layout';

import '../../../node_modules/react-grid-layout/css/styles.css';
import '../../../node_modules/react-resizable/css/styles.css';

import { useDialogState } from '../../components/dialog/dialog.hooks';
import { StyledTabs } from '../../styles';
import { useAuth } from '../../common/auth-provider/AuthProvider';
import AddReportDialog from '../../layouts/reports/AddReportDialog';
import {
  deleteReport,
  DeleteReportPayload,
  getReportList,
  updateAllReportMetrics,
  UpdateAllReportItemsPayload,
  getReport,
  ReportResource,
} from '../../common/api/reports';
import { grey200 } from '../../styles/colours';
import EditReportDialog from '../../layouts/reports/EditReportDialog';
import { useConfirmDialog } from '../../common/hooks/useConfirmDialog';
import { useSnackbar } from '../../common/hooks/useSnackbar';
import { ReportCell } from '../../layouts/reports/ReportCell.style';
import ReportTab from './ReportTab';
import AddReportTile from './AddReportTile';
import ReportTile from './ReportTile';
import {
  ReportItem,
  ReportItemLayout,
  ReportItemStyle,
  ReportListData,
  ReportMetricGroup,
} from '../../common/api/report-types';

function Report({
  containerEl,
  projectId,
  extraActions,
  renderActions,
}: {
  containerEl: HTMLDivElement | null;
  projectId?: string;
  extraActions?: ReactNode;
  renderActions?: (
    reportItems: ReportItem[],
    isDefaultReport: boolean,
    refecthData: () => void
  ) => ReactNode;
}) {
  const [tab, setTab] = useState<number>(0);
  const [isEditingDashboard, setIsEditDashboard] = useState<boolean>(false);
  const [reportId, setReportId] = useState<string>('');
  const [dashboadWidth, setDashboardWidth] = useState<number>();

  const queryClient = useQueryClient();

  // modal state
  const {
    isVisible: isCreateReportVisible,
    open: openCreateReport,
    close: closeCreateReport,
  } = useDialogState();
  const {
    isVisible: isEditReportVisible,
    open: openEditReport,
    close: closeEditReport,
  } = useDialogState();
  const {
    isVisible: isAddReportTileVisible,
    open: openAddReportTile,
    close: closeAddReportTile,
  } = useDialogState();

  const { confirmAction, ConfirmDialog } = useConfirmDialog();
  const { showAlert, SnackbarComponent } = useSnackbar();

  const { user } = useAuth();

  // get list of reports for the user
  const {
    data: reportsData,
    isLoading: isReportsLoading,
    refetch: refetchReports,
  } = useQuery<Array<ReportListData>>(
    ['reportsList', user?.id, projectId],
    () => {
      return getReportList(projectId || null);
    }
  );

  const { mutateAsync: mutateDeleteReport } = useMutation(
    (payload: DeleteReportPayload) => deleteReport(payload),
    {
      onSuccess: () => {
        setTab(0);
        refetchReports();
      },
      onError: () =>
        showAlert(
          'There was an error deleting the Dashboard. Please try again later.',
          'error'
        ),
    }
  );

  const { mutateAsync: mutateupdateReportItem } = useMutation(
    (payload: UpdateAllReportItemsPayload) => updateAllReportMetrics(payload),
    {
      onSuccess: () => refetchReports(),
      onError: () =>
        showAlert(
          'There was an error updating the Dashboard tile. Please try again later.',
          'error'
        ),
    }
  );

  const sortedReports = useMemo(
    () => sortBy(reportsData, 'order'),
    [reportsData]
  );

  const currentReport = useMemo(() => sortedReports[tab], [tab, sortedReports]);

  const isDefaultReport = currentReport?.id === sortedReports?.[0]?.id;

  const { data: reportData, refetch: refetchReportData } =
    useQuery<ReportResource>(
      ['reportData', currentReport?.id],
      () => {
        return getReport({
          reportId: currentReport.id,
          projectId,
        });
      },
      {
        enabled: !!currentReport?.id,
      }
    );

  const overviewReportData = useMemo(() => {
    const overviewReportId = sortedReports?.[0]?.id;
    const cachedData = queryClient.getQueryData([
      'reportData',
      overviewReportId,
    ]);
    return cachedData as ReportResource;
    //eslint-disable-next-line
  }, [queryClient, sortedReports?.length, reportData]);

  const reportMetrics = useMemo(
    () => reportData?.reportItems || [],
    [reportData?.reportItems]
  );

  const layout = useMemo(() => {
    return (
      reportData?.reportItems.map(item => {
        const metricGroups = item.metricGroups;
        const isSingle = item.style === ReportItemStyle.Basic;
        return {
          ...item.reportLayout,
          i: item.id,
          id: item.id,
          heading: item.heading,
          key: metricGroups?.[0]?.metric?.key,
          colour: isSingle ? metricGroups?.[0]?.colour : 'white',
          canRemove: metricGroups?.[0]?.metric?.canRemove,
        };
      }) || []
    );
  }, [reportData]);

  const handleLayoutUpdate = useCallback(
    (updatedLayout: ReportItemLayout[]) => {
      if (!reportData) return;

      mutateupdateReportItem({
        reportId: currentReport.id,
        reportItems: updatedLayout.map((l, index) => {
          const item = reportData.reportItems[index];
          return {
            id: item.id,
            heading: item.heading || null,
            style: item.style,
            reportId: item.reportId,
            reportLayout: {
              h: l.h,
              w: l.w,
              x: l.x,
              y: l.y,
            },
          };
        }),
      });
    },
    [reportData, currentReport?.id, mutateupdateReportItem]
  );

  useEffect(() => {
    function handleResize() {
      // Set window width/height to state
      setDashboardWidth(containerEl?.offsetWidth);
    }

    window.addEventListener('resize', handleResize);

    if (containerEl) {
      handleResize();
    }
    return () => window.removeEventListener('resize', handleResize);
  }, [containerEl]);

  return (
    <>
      <SnackbarComponent />

      <div id="report-actions"></div>

      {!isReportsLoading && (
        <>
          <Box
            display="flex"
            alignItems="center"
            mb={2}
            borderBottom={`1px solid ${grey200}`}
          >
            <StyledTabs value={tab} onChange={(e, value) => setTab(value)}>
              {sortedReports?.map((report, index) => (
                <ReportTab
                  key={report.id}
                  label={<span>{report.name}</span>}
                  value={index}
                  options={index > 0 ? ['Edit', 'Delete'] : ['Edit']}
                  onSelect={async option => {
                    setReportId(report.id);
                    if (option === 'Edit') {
                      openEditReport();
                    } else {
                      await confirmAction(
                        'Are you sure you want to delete this dashboard?'
                      );
                      await mutateDeleteReport({
                        id: report.id,
                      });
                    }
                  }}
                />
              ))}
            </StyledTabs>
            <IconButton
              size="small"
              sx={{
                border: '1px solid #ddd',
                borderRadius: '4px',
                width: '32px',
                height: '32px',
              }}
              onClick={openCreateReport}
            >
              <AddIcon />
            </IconButton>
          </Box>

          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            gap={1}
            mb={2}
          >
            <Button variant="outlined" onClick={openAddReportTile}>
              Add Tiles
            </Button>

            <Box display="flex" gap="8px">
              {extraActions}
              {renderActions?.(
                overviewReportData?.reportItems || [],
                isDefaultReport,
                refetchReportData
              )}
              <Button
                variant="outlined"
                onClick={() => setIsEditDashboard(!isEditingDashboard)}
              >
                {isEditingDashboard ? 'Lock Dashboard' : 'Drag and Resize'}
              </Button>
            </Box>
          </Box>

          {containerEl && (
            <GridLayout
              className="layout"
              layout={layout}
              style={{ marginBottom: '40px' }}
              cols={12}
              containerPadding={[0, 0]}
              rowHeight={40}
              width={dashboadWidth}
              isDraggable={isEditingDashboard}
              isDroppable={isEditingDashboard}
              isResizable={isEditingDashboard}
              onResizeStop={updatedLayout => {
                if (!currentReport) return;
                handleLayoutUpdate(updatedLayout);
              }}
              onDragStop={updatedLayout => {
                if (!currentReport) return;
                handleLayoutUpdate(updatedLayout);
              }}
            >
              {reportMetrics.map(rm => {
                const isBasic = rm.style === ReportItemStyle.Basic;
                const reportMetric: ReportMetricGroup | undefined =
                  rm.metricGroups?.[0];
                return (
                  <ReportCell
                    key={rm.id}
                    $colour={
                      reportMetric && isBasic ? reportMetric.colour : '#fff'
                    }
                  >
                    <ReportTile
                      reportItem={rm}
                      projectId={currentReport.projectId}
                      isDefaultDashboard={isDefaultReport}
                      isEditingDashboard={isEditingDashboard}
                      reload={() => {
                        refetchReportData();
                        refetchReports();
                      }}
                      showAlert={showAlert}
                    />
                  </ReportCell>
                );
              })}
            </GridLayout>
          )}
        </>
      )}

      {isCreateReportVisible && (
        <AddReportDialog
          projectId={projectId}
          reports={reportsData || []}
          reload={refetchReports}
          close={closeCreateReport}
        />
      )}

      {isEditReportVisible && (
        <EditReportDialog
          report={sortedReports.find(r => r.id === reportId)!}
          reload={refetchReports}
          close={closeEditReport}
        />
      )}

      {isAddReportTileVisible && (
        <AddReportTile
          reportId={currentReport.id}
          projectId={projectId}
          reportLayout={layout}
          isMainDashboard={sortedReports[0].id === currentReport.id}
          reload={async () => {
            await refetchReportData();
            showAlert('New Tile added!', 'success');
          }}
          close={closeAddReportTile}
        />
      )}

      <ConfirmDialog />
    </>
  );
}

export { Report };
