import React, { useCallback, useState } from 'react';
import { Box, Button, Dialog, DialogContent, Typography } from '@mui/material';

interface ConfirmDialogState {
  isOpen: boolean;
  title: string;
  confirmButtonTitle: string;
  rejectButtonTitle: string;
  confirmFunction: Function;
}

const initialConfirmDialogState = {
  isOpen: false,
  title: 'Do you confirm this action?',
  confirmButtonTitle: 'YES',
  rejectButtonTitle: 'NO',
  confirmFunction: () => {},
};

function useConfirmDialog() {
  const [confirmDialogState, setConfirmDialogState] =
    useState<ConfirmDialogState>(initialConfirmDialogState);

  const confirmAction = useCallback(
    (
      title?: string,
      confirmButtonTitle?: string,
      rejectButtonTitle?: string
    ) => {
      return new Promise(resolve => {
        setConfirmDialogState(prevState => ({
          ...prevState,
          ...(title ? { title } : null),
          ...(confirmButtonTitle ? { confirmButtonTitle } : null),
          ...(rejectButtonTitle ? { rejectButtonTitle } : null),
          confirmFunction: resolve,
          isOpen: true,
        }));
      });
    },
    []
  );

  const closeConfirmDialog = useCallback(() => {
    setConfirmDialogState({ ...confirmDialogState, isOpen: false });
  }, [confirmDialogState]);

  const handleConfirm = useCallback(() => {
    confirmDialogState.confirmFunction();
    closeConfirmDialog();
  }, [confirmDialogState, closeConfirmDialog]);
  function ConfirmDialog() {
    return (
      <Dialog open={confirmDialogState.isOpen} onClose={closeConfirmDialog}>
        <DialogContent style={styles.DialogContent}>
          <Typography variant="h6" style={styles.Typography}>
            {confirmDialogState.title}
          </Typography>
          <Box style={styles.ButtonGroup}>
            <Button
              onClick={closeConfirmDialog}
              variant="outlined"
              style={styles.Button}
            >
              {confirmDialogState.rejectButtonTitle}
            </Button>
            <Button
              onClick={handleConfirm}
              variant="outlined"
              color="error"
              style={styles.Button}
            >
              {confirmDialogState.confirmButtonTitle}
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    );
  }

  return { confirmAction, ConfirmDialog };
}

export { useConfirmDialog };

const styles: { [key: string]: React.CSSProperties } = {
  DialogContent: {
    width: '360px',
    minHeight: '150px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '25px 24px',
  },
  Typography: {
    margin: '5px auto 20px auto',
    textAlign: 'center',
  },
  ButtonGroup: {
    display: 'flex',
    gap: '8px',
    justifyContent: 'flex-end',
  },
};
