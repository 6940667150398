import { JSONContent } from '@tiptap/core';

import { axios } from './axios';
import { CreateReportPayload, UpdateReportPayload } from './types';
import { MetricFormat } from './metric-types';
import {
  ComputedMetricGroup,
  Report,
  ReportItem,
  ReportItemLayout,
  ReportItemStyle,
  ReportListData,
} from './report-types';

export async function createReport(
  payload: CreateReportPayload
): Promise<Report> {
  const resp = await axios.post('/report', payload);
  return resp.data;
}

export async function updateReport(
  payload: UpdateReportPayload
): Promise<Report> {
  const resp = await axios.patch(`/report/${payload.id}`, payload);
  return resp.data;
}

export interface DeleteReportPayload {
  id: string;
}

export async function deleteReport(
  payload: DeleteReportPayload
): Promise<Report> {
  const resp = await axios.delete(`/report/${payload.id}`);
  return resp.data;
}

export async function getReportList(
  projectId: string | null
): Promise<Array<ReportListData>> {
  const resp = await axios.get('/reports', {
    params: {
      projectId,
    },
  });
  return resp.data;
}

export interface createReportItemPayload {
  reportId: string;
  projectId?: string;
  reportLayout: ReportItemLayout;
  style: ReportItemStyle;
  richtext?: JSONContent | null;
}

export async function createReportItem(
  payload: createReportItemPayload
): Promise<Report> {
  const resp = await axios.post('/report/item', payload);
  return resp.data;
}

export interface deleteReportItemPayload {
  reportId: string;
  id: string;
}

export async function deleteReportItem({
  reportId,
  id,
}: deleteReportItemPayload): Promise<Report> {
  const resp = await axios.delete(`/report/${reportId}/item/${id}`);
  return resp.data;
}

export interface UpdateReportMetricGroup {
  id: string;
  colour: string;
  denomination: string;
  decimalPlaces: number;
  reportItemId: string;
  metricId: string;
}

export interface UpdateReportItemPayload {
  reportId: string;
  id: string;
  updatedReportItem: {
    id: string;
    reportId: string;
    heading: string | null;
    subHeading: string | null;
    imageKey: string | null;
    richtext: JSONContent | null;
    metricGroups: Array<UpdateReportMetricGroup>;
    newMetricGroups: Array<{
      colour: string;
      denomination: string;
      decimalPlaces: number;
      reportItemId: string;
      metricId: string;
    }>;
  };
}

export async function updateReportItem({
  reportId,
  id,
  updatedReportItem,
}: UpdateReportItemPayload): Promise<Report> {
  const resp = await axios.patch(`/report/${reportId}/item/${id}`, {
    updatedReportItem,
  });
  return resp.data;
}

export interface UpdateReportItemLocationsPayload {
  reportId: string;
  id: string;
  locations: Array<{
    id: string | null;
    address: string;
    placeId: string;
    reportItemId: string;
  }>;
}

export async function updateReportItemLocations({
  reportId,
  id,
  locations,
}: UpdateReportItemLocationsPayload): Promise<Report> {
  const resp = await axios.patch(`/report/${reportId}/item/${id}/locations`, {
    locations,
  });
  return resp.data;
}

export interface UpdateAllReportItemsPayload {
  reportId: string;
  reportItems: Array<{
    id: string;
    heading: string | null;
    style: ReportItemStyle;
    reportLayout: ReportItemLayout;
    reportId: string;
  }>;
}

export async function updateAllReportMetrics({
  reportId,
  reportItems,
}: UpdateAllReportItemsPayload): Promise<Report> {
  const resp = await axios.patch(`/report/${reportId}/item/all`, {
    reportItems,
  });
  return resp.data;
}

export type MetricValues = {
  metricKey: string;
  value: number;
  locations: Array<{
    lat: number;
    lng: number;
    title: string;
  }>;
  format: MetricFormat;
};

export type GetProjectReportPayload = {
  reportItemId: string | null;
};

export interface ProjectListData {
  data: {
    headers: Array<string>;
    values: Array<
      Array<{
        id: string;
        metricKey: string;
        value: string | number;
        progress?: {
          total: number;
          claimed: number;
          approved: number;
          paid: number;
          approvedVariation: number;
          unapprovedVariation: number;
        };
      }>
    >;
  };
}

export async function getProjectReport({
  reportItemId,
  isDefaultReport,
}: {
  reportItemId: string;
  isDefaultReport: boolean;
}): Promise<ProjectListData> {
  const resp = await axios.get(
    `/report/${reportItemId}/project?isDefaultReport=${isDefaultReport}`
  );
  return resp.data;
}

export type ReportResource = Report & {
  reportItems: Array<
    ReportItem & {
      locations: Array<Location>;
      metricGroups: Array<ComputedMetricGroup>;
    }
  >;
};

export async function getReport({
  reportId,
  projectId,
}: {
  reportId: string;
  projectId?: string;
}): Promise<ReportResource> {
  const resp = await axios.get(`/report/${reportId}`, {
    params: {
      projectId,
    },
  });
  return resp.data;
}

export interface ContractReportListData {
  data: {
    headers: Array<string>;
    values: Array<
      Array<{
        id: string;
        accountName: string;
        metricKey: string;
        value: string | number;
        progress?: {
          total: number;
          claimed: number;
          approved: number;
          paid: number;
          approvedVariation: number;
          unapprovedVariation: number;
        };
      }>
    >;
  };
}

export async function getContractReport({
  reportItemId,
  isDefaultReport,
  projectId,
}: {
  reportItemId: string;
  projectId: string;
  isDefaultReport: boolean;
}): Promise<ContractReportListData> {
  const resp = await axios.get(
    `/report/${reportItemId}/contract?projectId=${projectId}&isDefaultReport=${isDefaultReport}`
  );
  return resp.data;
}

export interface UpdateProjectListDataPayload {
  id: string;
  reportId: string;
  fields: Array<{
    id: string | null;
    metricId: string;
    order: string;
  }>;
  projectIds: Array<string>;
}

export async function updateProjectListReport(
  payload: UpdateProjectListDataPayload
) {
  const resp = await axios.patch(`/report/project-list`, {
    ...payload,
  });
  return resp.data;
}

export interface UpdateContractListDataPayload {
  id: string;
  reportId: string;
  fields: Array<{
    id: string | null;
    metricId: string;
    order: string;
  }>;
  contractIds: Array<string>;
}

export async function updateContractListReport(
  payload: UpdateContractListDataPayload
) {
  const resp = await axios.patch(`/report/contract-list`, {
    ...payload,
  });
  return resp.data;
}
