import React from 'react';
import { BaseContract } from '../../../common/api';

interface Props {
  option: BaseContract;
  liProps: React.HTMLAttributes<HTMLLIElement>;
}

function ContractRenderOption(props: Props) {
  const { option, liProps } = props;

  return <li {...liProps}>{option.description}</li>;
}

export { ContractRenderOption };
