import {
  Contract,
  subContractorClaims,
  ClaimStatus,
  PERCENT,
  DOLLAR,
} from '../../../../../common/api';
import { Box, TableBody, TableCell, TableRow, Typography } from '@mui/material';
import React, { useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import { formatAmountByType, formatDate } from '../../../../../common/format';
import { SkeletonTable } from '../../../../../components/skeleton-table';
import { SortedTableHead } from '../../../../../components/sorted-table-head';
import {
  HeadCell,
  useSortedTable,
} from '../../../../../components/sorted-table-head/SortedTable.hooks';
import { stableSort } from '../../../../../common/sort/stableSort';
import { getComparator } from '../../../../../common/sort/getComparator';
import { StyledTable } from '../../../../../styles';
import dayjs from 'dayjs';
import { ChipStatus } from '../../../../../components/chip-status';
import {
  formatDaysTillDate,
  projectDate,
} from '../../../../../common/format/formatDate';
import { useProject } from '../../../../../common/hooks/useProject';

type Props = {
  contract: Contract;
};

function SubContractorClaimsReceivedTab({ contract }: Props) {
  const { data, isLoading } = useQuery([contract, 'subContractClaims'], () =>
    subContractorClaims(contract.id)
  );

  const { project } = useProject();

  const { order, orderBy, handleRequestSort } = useSortedTable();

  const headCells: HeadCell[] = [
    {
      id: 'subContractor',
      numeric: false,
      disablePadding: false,
      label: 'Sub-Contract',
    },
    {
      id: 'statusOrder',
      numeric: false,
      disablePadding: false,
      label: 'Contract Status',
    },
    {
      id: 'claimPeriod',
      numeric: false,
      disablePadding: false,
      label: 'Claim Period',
    },
    {
      id: 'submittedAt',
      numeric: false,
      disablePadding: false,
      label: 'Date Submitted',
    },
    {
      id: 'expiredAt',
      numeric: false,
      disablePadding: false,
      label: 'SOPA Expiration',
    },
    {
      id: 'dateResponded',
      numeric: false,
      disablePadding: false,
      label: 'Date Responded',
    },
    {
      id: 'claimValue',
      numeric: false,
      disablePadding: false,
      label: 'Claim Value',
    },
  ];

  const rows = useMemo(() => {
    const list = data?.data || [];

    return list?.map(claim => {
      return {
        id: claim.id,
        ref: claim.id,
        subContractor: claim?.contract.contractorAccount.name || '-',
        description: claim?.contract.description || '-',
        status: claim.status,
        statusOrder: Object.keys(ClaimStatus).indexOf(claim.status),
        dateSubmitted: formatDate(claim.submittedAt) || '',
        submittedAt: dayjs(claim.submittedAt).unix(),
        dateResponded: dayjs(claim.scheduledAt || 0).unix(),
        claimValue: claim.totalValue,
        sopaExpiration: claim?.expiration,
        expiredAt: dayjs(claim?.expiration || 0).unix(),
        contractId: claim.contractId,
        contractTotalValue: Number(claim.contract.value || 0),
        claimPeriod: dayjs(claim.periodStart).unix(),
        periodStart: claim.periodStart,
        periodEnd: claim.periodEnd,
        scheduledAt: claim.scheduledAt || '',
        taskValue: claim.claimItems[0]?.task?.value || '',
        valueType: claim.valueType,
        paymentDueAt: claim.paymentDueAt || '',
      };
    });
  }, [data]);

  return (
    <>
      {isLoading ? (
        <SkeletonTable />
      ) : data?.data.length === 0 ? (
        <Typography>No Sub-Contract claims to display</Typography>
      ) : (
        <>
          <StyledTable>
            <SortedTableHead
              order={order}
              orderBy={orderBy}
              headCells={headCells}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {stableSort(rows, getComparator(order, orderBy)).map(item => {
                const claimPeriod = `${formatDate(
                  item.periodStart
                )} - ${formatDate(item.periodEnd)}`;

                const claimValue =
                  item.valueType === PERCENT
                    ? (item.claimValue / 100) * Number(item.taskValue)
                    : item.claimValue;

                const dateResponded = item.scheduledAt
                  ? formatDate(item.scheduledAt)
                  : '-';

                const sopaDate = formatDaysTillDate(
                  item.sopaExpiration,
                  projectDate(project?.systemDate)
                );
                const sopaExpiration =
                  sopaDate > 0
                    ? `${Math.abs(sopaDate)} days OVERDUE`
                    : `${Math.abs(sopaDate)} days`;
                return (
                  <TableRow key={item.id}>
                    <TableCell
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                        }}
                      >
                        <Box>
                          <Typography variant={'body1'}>
                            {item.subContractor}
                          </Typography>
                          <Typography variant={'caption'}>
                            {item.description}
                          </Typography>
                        </Box>
                      </Box>
                    </TableCell>
                    <TableCell>
                      <ChipStatus
                        isOverdue={
                          dayjs(item.paymentDueAt) <
                            projectDate(project?.systemDate) &&
                          item.status !== ClaimStatus.Paid
                        }
                        status={item.status}
                        isCustomer
                      />
                    </TableCell>
                    <TableCell>
                      <Typography variant={'body1'}>{claimPeriod}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant={'body1'}>
                        {item.dateSubmitted}
                      </Typography>
                    </TableCell>
                    {item.sopaExpiration ? (
                      <TableCell>
                        <Typography variant={'body1'}>
                          {sopaExpiration}
                        </Typography>
                        <Typography variant={'caption'}>
                          {formatDate(item.sopaExpiration)}
                        </Typography>
                      </TableCell>
                    ) : (
                      <TableCell>
                        <Typography variant={'caption'}>-</Typography>
                      </TableCell>
                    )}
                    <TableCell>
                      <Typography variant={'body1'}>{dateResponded}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant={'body1'}>
                        {item.valueType === PERCENT
                          ? `${Math.round(
                              (claimValue * 100) / item.contractTotalValue
                            )} %`
                          : formatAmountByType(item.claimValue, DOLLAR)}
                      </Typography>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </StyledTable>
        </>
      )}
    </>
  );
}

export { SubContractorClaimsReceivedTab };
