import React, { useCallback } from 'react';

import {
  Box,
  Button,
  Dialog,
  DialogContent,
  FormControl,
  TextField,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { InputActions, InputDescription } from '../../../styles/inputs';
import { useMutation } from '@tanstack/react-query';

import { Heading } from '../../../styles';
import { createProject, CreateProjectPayload } from '../../../common/api';
import { useAuth } from '../../../common/auth-provider/AuthProvider';
import { useSnackbar } from '../../../common/hooks/useSnackbar';
import LocationSearch from '../../../components/location-search/LocationSearch';
import { mapsLoader } from '../../../components/location-search/LocationLibraryLoader';
import { ReportItem } from '../../../common/api/report-types';

const NAME_FIELD = 'name';
const ADDRESS_LINE_1 = 'addressLine1';
const ADDRESS_LINE_2 = 'addressLine2';
const COUNTRY_FIELD = 'country';
const CITY_FIELD = 'city';
const STATE_FIELD = 'state';
const POST_CODE = 'postcode';
const PLACE_ID = 'placeId';

interface FormData {
  [NAME_FIELD]: string;
  [ADDRESS_LINE_1]: string;
  [ADDRESS_LINE_2]?: string;
  [COUNTRY_FIELD]: string;
  [CITY_FIELD]: string;
  [STATE_FIELD]: string;
  [POST_CODE]: string;
  [PLACE_ID]?: string;
}

interface Props {
  projectListItem?: ReportItem;
  reloadProjects: () => void;
  close: () => void;
}

function AddProjectDialog({ projectListItem, reloadProjects, close }: Props) {
  const { user } = useAuth();
  const { showAlert, SnackbarComponent } = useSnackbar();

  const {
    control,
    formState: { errors },
    setValue,
    handleSubmit,
    reset,
  } = useForm<FormData>();

  const handleCloseDialog = useCallback(() => {
    close();
    reset();
  }, [close, reset]);

  const { mutateAsync: mutateAddProject, isLoading } = useMutation(
    (payload: CreateProjectPayload) => createProject(payload),
    {
      onSuccess: () => {
        reloadProjects();
        handleCloseDialog();
      },
      onError: () => {
        showAlert(
          'There was an error creating the project. Please try again later.',
          'error'
        );
      },
    }
  );

  const getAddressDetails = useCallback(
    async (placeId: string) => {
      const { PlacesService } = (await mapsLoader.importLibrary(
        'places'
      )) as google.maps.PlacesLibrary;
      const placesService = new PlacesService(document.createElement('div'));

      const request = {
        placeId: placeId,
        fields: [
          'name',
          'formatted_address',
          'address_component',
          'place_id',
          'geometry',
        ],
      };

      placesService.getDetails(request, (place, status) => {
        if (status === google.maps.places.PlacesServiceStatus.OK && place) {
          const addressComponents = place.address_components || [];

          // Helper function to get a component by type
          const getAddressComponent = (type: string) => {
            return (
              addressComponents.find(component =>
                component.types.includes(type)
              )?.long_name || ''
            );
          };

          // Extracting specific parts of the address
          const addressLine1 = `${getAddressComponent(
            'street_number'
          )} ${getAddressComponent('route')}`.trim();
          const addressLine2 = getAddressComponent('subpremise'); // If applicable
          const city = getAddressComponent('locality');
          const state = getAddressComponent('administrative_area_level_1');
          const postcode = getAddressComponent('postal_code');
          const country = getAddressComponent('country');

          setValue(ADDRESS_LINE_1, addressLine1, {
            shouldValidate: true,
          });
          setValue(ADDRESS_LINE_2, addressLine2, {
            shouldValidate: true,
          });
          setValue(STATE_FIELD, state, {
            shouldValidate: true,
          });
          setValue(STATE_FIELD, state, {
            shouldValidate: true,
          });
          setValue(POST_CODE, postcode, {
            shouldValidate: true,
          });
          setValue(CITY_FIELD, city, {
            shouldValidate: true,
          });
          setValue(COUNTRY_FIELD, country, {
            shouldValidate: true,
          });
          setValue(PLACE_ID, placeId, {
            shouldValidate: true,
          });
        } else {
          console.error('Place details request failed due to:', status);
        }
      });
    },
    [setValue]
  );

  const onSubmit = useCallback<SubmitHandler<FormData>>(
    async (data: FormData) => {
      if (!user || !projectListItem?.reportItemList.id) return;

      await mutateAddProject({
        name: data[NAME_FIELD],
        accountId: user?.accountId,
        addressLine1: data[ADDRESS_LINE_1],
        addressLine2: data[ADDRESS_LINE_2],
        city: data[CITY_FIELD],
        country: data[COUNTRY_FIELD],
        postcode: data[POST_CODE],
        locality: data[STATE_FIELD],
        placeId: data[PLACE_ID],
        projectReportListItemId: projectListItem.reportItemList.id,
      });
    },
    [user, projectListItem, mutateAddProject]
  );

  return (
    <>
      <SnackbarComponent />
      <Dialog open fullWidth maxWidth="xs" onClose={handleCloseDialog}>
        <DialogContent>
          <form onSubmit={handleSubmit(onSubmit)} noValidate>
            <Heading>Create Project</Heading>
            <FormControl sx={{ mb: 3, width: '100%' }}>
              <Controller
                name={NAME_FIELD}
                defaultValue=""
                control={control}
                rules={{
                  required: 'Name is required',
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Project Name"
                    required
                    fullWidth
                    autoFocus
                    error={!!errors[NAME_FIELD]}
                    helperText={errors[NAME_FIELD]?.message}
                  />
                )}
              />
              <InputDescription>
                A name that helps everyone recognise the project works best
              </InputDescription>
            </FormControl>

            <FormControl sx={{ mb: 2, width: '100%' }}>
              <LocationSearch
                label="Search Address"
                placeholder="Search address"
                onLocationSelected={data => {
                  getAddressDetails(data.placeId);
                }}
              />
            </FormControl>

            <FormControl sx={{ mb: 3, width: '100%' }}>
              <Controller
                name={ADDRESS_LINE_1}
                defaultValue=""
                control={control}
                rules={{
                  required: 'Address is required',
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Address"
                    required
                    fullWidth
                    error={!!errors[ADDRESS_LINE_1]}
                    helperText={errors[ADDRESS_LINE_1]?.message}
                  />
                )}
              />
            </FormControl>

            <FormControl sx={{ mb: 3, width: '100%' }}>
              <Controller
                name={COUNTRY_FIELD}
                defaultValue=""
                control={control}
                rules={{
                  required: 'Country is required',
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Country"
                    required
                    fullWidth
                    error={!!errors[COUNTRY_FIELD]}
                    helperText={errors[COUNTRY_FIELD]?.message}
                  />
                )}
              />
            </FormControl>

            <FormControl sx={{ mb: 3, width: '100%' }}>
              <Controller
                name={CITY_FIELD}
                defaultValue=""
                control={control}
                rules={{
                  required: 'City is required',
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="City"
                    required
                    fullWidth
                    error={!!errors[CITY_FIELD]}
                    helperText={errors[CITY_FIELD]?.message}
                  />
                )}
              />
            </FormControl>

            <Box gap="16px" display="flex">
              <FormControl sx={{ mb: 3 }}>
                <Controller
                  name={STATE_FIELD}
                  defaultValue=""
                  control={control}
                  rules={{
                    required: 'State is required',
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="State"
                      required
                      fullWidth
                      error={!!errors[STATE_FIELD]}
                      helperText={errors[STATE_FIELD]?.message}
                    />
                  )}
                />
              </FormControl>
              <FormControl sx={{ mb: 3 }}>
                <Controller
                  name={POST_CODE}
                  defaultValue=""
                  control={control}
                  rules={{
                    required: 'Postcode is required',
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Postcode"
                      required
                      fullWidth
                      error={!!errors[POST_CODE]}
                      helperText={errors[POST_CODE]?.message}
                    />
                  )}
                />
              </FormControl>
            </Box>

            <InputActions>
              <Button variant="outlined" onClick={handleCloseDialog}>
                Cancel
              </Button>
              <LoadingButton
                variant="contained"
                type="submit"
                loading={isLoading}
              >
                Create
              </LoadingButton>
            </InputActions>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
}

export { AddProjectDialog };
