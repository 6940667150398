import React, { useMemo } from 'react';
import { Card, CardContent, Typography } from '@mui/material';

import {
  Contract,
  DOLLAR,
  PERCENT,
  SummaryTaskData,
  ValueType,
} from '../../../common/api';

// Utils
import { useAuth } from '../../../common/auth-provider/AuthProvider';
import { formatAmountByType } from '../../../common/format';
import {
  isContractor,
  isCustomer,
  isProjectOwner,
} from '../../../common/access';

// Styles
import { grey200, grey500, grey900 } from '../../../styles/colours';

interface ContractDetailsInfoCardProps {
  contract: Contract;
  summary?: SummaryTaskData;
}

export const ContractDetailsInfoCard = ({
  contract,
  summary,
}: ContractDetailsInfoCardProps) => {
  const { user } = useAuth();
  const checkIsContractor = isContractor(user, contract);
  const checkIsCustomer = isCustomer(user, contract);

  const isCanView = user ? checkIsCustomer || checkIsContractor : false;

  const pendingClaimsValue = useMemo(() => {
    if (!isCanView) {
      return Math.round(
        ((summary?.pendingClaims || summary?.valueClaimed || 0) /
          (summary?.value || 1)) *
          100
      );
    }

    if (user && isProjectOwner(user, contract)) {
      return 0;
    }

    return summary?.pendingClaims || summary?.valueClaimed || 0;
  }, [contract, isCanView, user, summary]);

  const totalClaimsApproveValue = useMemo(() => {
    if (!isCanView) {
      return Math.round(
        ((summary?.valuePaid || summary?.valueApproved || 0) /
          (summary?.value || 1)) *
          100
      );
    }

    if (user && isProjectOwner(user, contract)) {
      return 0;
    }

    return summary?.valuePaid || summary?.valueApproved || 0;
  }, [contract, isCanView, summary, user]);

  const valueToComplete = useMemo(() => {
    if (!isCanView) {
      return Math.round(
        ((summary?.valueToComplete || 0) / (summary?.value || 1)) * 100
      );
    }

    return summary?.valueToComplete || 0;
  }, [isCanView, summary]);

  const contractInfoCardsData: Array<{
    id: string;
    title: string;
    value: number;
    format?: ValueType;
    showCard: boolean;
  }> = useMemo(
    () => [
      {
        id: 'total_value',
        title: 'Total Value',
        value: summary?.value ?? 0,
        format: DOLLAR,
        showCard:
          (checkIsCustomer || checkIsContractor) && !contract.parentContractId,
      },
      {
        id: 'pending_claims',
        title: 'Pending Claims',
        value: pendingClaimsValue,
        format: isCanView ? DOLLAR : PERCENT,
        showCard:
          !isCanView ||
          (checkIsCustomer && !!contract.parentContractId) ||
          (checkIsContractor && !!contract.parentContractId),
      },
      {
        id: 'total claims approved',
        title: 'Total Claims Approved',
        value: totalClaimsApproveValue,
        format: isCanView ? DOLLAR : PERCENT,
        showCard:
          !isCanView ||
          (checkIsCustomer && !!contract.parentContractId) ||
          (checkIsContractor && !!contract.parentContractId),
      },
      {
        id: 'pending_approvals',
        title: 'Pending Approvals',
        value:
          user && isProjectOwner(user, contract)
            ? summary?.valueClaimed || 0
            : summary?.pendingApprovals || 0,
        format: DOLLAR,
        showCard:
          (checkIsCustomer || checkIsContractor) && !contract.parentContractId,
      },
      {
        id: 'value_to_complete',
        title: 'Value to Complete',
        value: valueToComplete,
        format: isCanView ? DOLLAR : PERCENT,
        showCard: true,
      },
    ],
    [
      summary,
      pendingClaimsValue,
      isCanView,
      checkIsContractor,
      checkIsCustomer,
      totalClaimsApproveValue,
      user,
      contract,
      valueToComplete,
    ]
  );

  return (
    <>
      {contractInfoCardsData.map(cardInfo => {
        return (
          cardInfo.showCard && (
            <Card key={cardInfo.id} sx={styles.Card}>
              <CardContent sx={styles.CardContent}>
                <Typography
                  noWrap
                  variant="caption"
                  sx={styles.TypographyLabel}
                >
                  {cardInfo.title}
                </Typography>
                <Typography sx={styles.TypographyContent}>
                  {cardInfo?.format
                    ? formatAmountByType(cardInfo.value, cardInfo.format)
                    : cardInfo.value}
                </Typography>
              </CardContent>
            </Card>
          )
        );
      })}
    </>
  );
};

const styles = {
  Card: {
    borderRight: `1px solid ${grey200}`,
    boxShadow: 'none',
    borderRadius: '0px',
  },
  TypographyLabel: {
    color: grey500,
    letterSpacing: 1,
    marginBottom: '4px',
  },
  TypographyContent: {
    paddingTop: '6px',
    color: grey900,
    fontSize: '20px',
    fontWeight: 500,
  },
  CardContent: {
    p: 2,
    '&:last-child': { pb: 1.5 },
  },
};
