import { AuthResponse } from '../api';

const AUTH_RESPONSE_STORAGE_KEY = 'AUTH_RESPONSE';

function loadAuthResponse(): AuthResponse | undefined {
  const s = localStorage.getItem(AUTH_RESPONSE_STORAGE_KEY);
  if (s) {
    return JSON.parse(s) as AuthResponse;
  }
}

function saveAuthResponse(authResponse: AuthResponse) {
  if (authResponse) {
    localStorage.setItem(
      AUTH_RESPONSE_STORAGE_KEY,
      JSON.stringify(authResponse)
    );
  } else {
    clearAuthResponse();
  }
}

function clearAuthResponse() {
  localStorage.removeItem(AUTH_RESPONSE_STORAGE_KEY);
}

export { loadAuthResponse, saveAuthResponse, clearAuthResponse };
