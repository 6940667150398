import React, { useCallback, useMemo } from 'react';
import { Params, useNavigate, useParams } from 'react-router-dom';
import _ from 'lodash';
import { Box } from '@mui/material';
import { useMutation, useQuery } from '@tanstack/react-query';

import {
  approvalList,
  claimList,
  Contract,
  ContractFile,
  deleteTasks,
  DeleteTasksPayload,
  listContractFiles,
  listSubContracts,
  Notification,
  ResponseListBaseType,
  SubContract,
  SummaryTaskData,
  Tag,
  Task,
} from '../../../../common/api';
import {
  listVariations,
  listVariationsReceived,
} from '../../../../common/api/variations';

// Components
import { Tabs, TabsItem, TabType } from '../../../../components/tabs';
import { ActionListType } from '../../components/ActionButtons';
import { ContractDetails } from '../../components/ContractDetails';
import { ClaimHistory } from '../../components/ClaimHistory';
import { SkeletonTable } from '../../../../components/skeleton-table';
import {
  BaseTaskGroupProps,
  TaskGroups,
} from '../../../../components/task-group/TaskGroups';

// Tabs
import { TaskTab } from './components/TaskTab';
import { ClaimsReceivedTab } from '../../../../components/approvals-tab';
import { ComplianceTab } from '../../../../components/compliance-tab';
import { SubContractTab } from './components/SubContractTab';
import { VariationsMadeTab } from '../../components/VariationsMade';
import { VariationsReceivedTab } from '../../components/VariationsReceived';
import { PageContractClaimTabs } from '../contract-claim-view/ContractClaimView';

// Utils
import { useAuth } from '../../../../common/auth-provider/AuthProvider';
import { BasePaginationProps } from '../../../../common/types';
import { useConfirmDialog } from '../../../../common/hooks/useConfirmDialog';
import { useListPagination } from '../../../../common/hooks/useListPagination';
import {
  isContractor,
  isCustomer,
  isProjectOwner,
} from '../../../../common/access';
import { useProject } from '../../../../common/hooks/useProject';
import {
  getClaimReceivedGreenActions,
  getClaimReceivedRedActions,
  getClaimsMadeGreenActions,
  getClaimsMadeRedActions,
  getComplianceGreenActions,
  getComplianceRedActions,
  getVariationReceivedGreenActions,
  getVariationReceivedRedActions,
  getVariationsMadeGreenActions,
  getVariationsMadeRedActions,
} from '../contract-claim-view/ContractTabs.utils';
import { useNotifications } from '../../../../common/hooks/useNotifications';
import { useCheckbox } from '../../hooks/useCheckbox';
import { SubContractorClaimsReceivedTab } from './components/SubContractorClaimsReceivedTab';
import { SubContractorVariationsReceivedTab } from './components/SubContractorVariationsReceivedTab';
import { getVisibilitySetting } from '../../components/visibility/utils/visibilitySettings.utils';
import { RetentionTab } from './components/RetentionTab';

export enum PageContractApproveTabs {
  tasks = 'tasks',
  subContracts = 'subContracts',
  claimsReceived = 'claimsReceived',
  compliance = 'compliance',
  claims = 'claims',
  variationsMade = 'variationsMade',
  retention = 'retention',
  variationsReceived = 'variationsReceived',
  subContractClaims = 'subContractClaims',
  subContractVariations = 'subContractVariations',
}

type Props = BasePaginationProps &
  BaseTaskGroupProps & {
    contract: Contract;
    tasks?: ResponseListBaseType<Array<Task>>;
    reloadData: () => Promise<void>;
    reloadContract: () => Promise<void>;
    onChangeTags: (tag: Array<Tag>) => void;
    tags: Array<Tag>;
    summary?: SummaryTaskData;
  };

function ContractApproveView(props: Props) {
  const {
    contract,
    tasks,
    reloadData,
    handleChangePage,
    handleChangeRowsPerPage,
    page,
    rowsPerPage,
    handleSelectedTaskGroup,
    selectedTaskGroup,
    summary,
    reloadContract,
  } = props;

  const navigate = useNavigate();
  const { user } = useAuth();
  const { project } = useProject();
  const { contractId, subcontractId } = useParams<Params>();
  const { notifications, mutationNotification } = useNotifications(
    user,
    project
  );
  const { handleCheckboxClick } = useCheckbox(notifications);

  const { confirmAction, ConfirmDialog } = useConfirmDialog();
  useProject(contract.projectId);

  const {
    page: pageApproval,
    rowsPerPage: rowsPerPageApproval,
    handleChangePage: handleChangePageApproval,
    skip,
  } = useListPagination();

  const {
    page: pageClaims,
    rowsPerPage: rowsPerPageClaims,
    handleChangePage: handleChangePageClaims,
  } = useListPagination();

  const { mutateAsync: mutateDeleteTask } = useMutation(
    (payload: DeleteTasksPayload) => deleteTasks(payload)
  );

  const { data: approvalClaims, isFetching: isLoading } = useQuery(
    [contract, rowsPerPageApproval, pageApproval, 'approvals'],
    () => approvalList(contract.id, rowsPerPageApproval, skip)
  );

  const { data: variations } = useQuery(['variations', contract.id], () =>
    listVariations({ contractId: contract.id })
  );

  const { data: variationsReceived } = useQuery(
    ['variationsReceived', contract.id],
    () => listVariationsReceived({ contractId: contract.id })
  );

  const {
    data: contractFiles,
    isLoading: isContractFilesLoading,
    refetch: refetchContractFiles,
  } = useQuery<Array<ContractFile>>(
    ['node-files', subcontractId || contractId],
    () => listContractFiles(subcontractId || contractId!)
  );

  const { data: claims, refetch: refetchClaims } = useQuery(
    [contract, rowsPerPageClaims, pageClaims, 'claims'],
    () =>
      claimList(
        contract.id,
        rowsPerPageClaims,
        rowsPerPageClaims * (pageClaims - 1)
      )
  );

  const {
    data: subcontracts,
    isLoading: isSubContractsLoading,
    refetch: refetchSubContracts,
  } = useQuery<ResponseListBaseType<Array<SubContract>>>(
    ['subcontracts', contract.id],
    () => listSubContracts({ contractId: contract.id, take: 50, skip: 0 })
  );

  const handleDeleteTasks = useCallback(
    async (selectedTasksIds: Array<string>) => {
      await confirmAction('Are you sure you want to delete this tasks');
      await mutateDeleteTask({
        contractId: contract.id,
        taskIds: selectedTasksIds,
      });
      await reloadData();
    },
    [reloadData, confirmAction, contract.id, mutateDeleteTask]
  );

  const handleClick = useCallback(
    async (notificationId: string) => {
      mutationNotification({ id: notificationId, isRead: true });
    },
    [mutationNotification]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const setServicesValueDebounced = useCallback(
    _.debounce(mutationNotification, 500),
    []
  );

  const handleCheckbox = useCallback(
    async (notification: Notification) => {
      handleCheckboxClick(notification.id);
      setServicesValueDebounced({
        id: notification.id,
        isRead: !notification.isRead,
      });
    },
    [handleCheckboxClick, setServicesValueDebounced]
  );

  const reloadDataContract = useCallback(async () => {
    await reloadData();
  }, [reloadData]);

  const reloadContractFiles = useCallback(() => {
    (async () => {
      await refetchContractFiles();
    })();
  }, [refetchContractFiles]);

  const reloadSubContracts = useCallback(() => {
    (async () => {
      await refetchSubContracts();
    })();
  }, [refetchSubContracts]);

  const reloadClaims = useCallback(() => {
    (async () => {
      await refetchClaims();
    })();
  }, [refetchClaims]);

  const isHeadContract = !contract.parentContractId;

  const isAnticipatedVariationsFunction = getVisibilitySetting(
    contract.visibilitySettings,
    'anticipatedVariationsFunction'
  );

  const tabs = useMemo((): TabType[] => {
    const isNoProjectOwner = user && !isProjectOwner(user, contract);

    const isContractorClaimsMadeVisible = getVisibilitySetting(
      contract.visibilitySettings,
      'subcontractorClaimsMade'
    );

    const parentIds = [
      'submitted',
      'approved',
      'rejected',
      'withdrawn',
      'anticipated',
    ];
    const isSubcontractVariationsReceived = contract.visibilitySettings?.some(
      setting => parentIds.includes(setting.name) && setting.isOn
    );

    const subContractsNotifications = subcontracts?.data?.reduce(
      (acc, subcontract) => {
        return acc + (subcontract.notifications?.length ? 1 : 0);
      },
      0
    );

    return [
      {
        label: 'Tasks',
        id: PageContractApproveTabs.tasks,
      },
      {
        label: 'Claims Received',
        greenDotsLength: getClaimReceivedGreenActions(
          approvalClaims?.data,
          project?.systemDate,
          user?.accountId,
          notifications
        ),
        redDotsLength: getClaimReceivedRedActions(
          approvalClaims?.data,
          project?.systemDate,
          user?.accountId,
          notifications
        ),
        id: PageContractApproveTabs.claimsReceived,
      },
      {
        label: 'Variations Received',
        greenDotsLength: getVariationReceivedGreenActions(
          variationsReceived?.data,
          user?.accountId,
          notifications
        ),
        redDotsLength: getVariationReceivedRedActions(
          variationsReceived?.data,
          user?.accountId,
          notifications
        ),
        id: PageContractApproveTabs.variationsReceived,
      },
      {
        label: 'Retention',
        isShow: true,
        id: PageContractApproveTabs.retention,
      },
      {
        label: 'Sub-Contracts',
        redDotsLength: subContractsNotifications,
        id: PageContractApproveTabs.subContracts,
      },
      {
        label: 'Claims Made',
        greenDotsLength: getClaimsMadeGreenActions(
          claims?.data,
          user?.accountId,
          project?.systemDate,
          notifications
        ),
        redDotsLength: getClaimsMadeRedActions(
          claims?.data,
          user?.accountId,
          project?.systemDate,
          notifications
        ),
        isShow: isNoProjectOwner,
        id: PageContractApproveTabs.claims,
      },

      {
        label: 'Variations Made',
        isShow: isNoProjectOwner,
        greenDotsLength: getVariationsMadeGreenActions(
          variations?.data,
          user?.accountId,
          notifications
        ),
        redDotsLength: getVariationsMadeRedActions(
          variations?.data,
          user?.accountId,
          notifications
        ),
        id: PageContractApproveTabs.variationsMade,
      },
      {
        label: 'Subcontract Claim Received',
        id: PageContractApproveTabs.subContractClaims,
        isShow: isContractorClaimsMadeVisible,
      },
      {
        label: 'Subcontract Variations Received',
        id: PageContractApproveTabs.subContractVariations,
        isShow: isSubcontractVariationsReceived,
      },
      {
        label: 'Compliance',
        greenDotsLength: getComplianceGreenActions(
          contractFiles,
          project?.systemDate
        ),
        redDotsLength: getComplianceRedActions(
          contractFiles,
          project?.systemDate
        ),
        id: PageContractClaimTabs.compliance,
      },
    ];
  }, [
    user,
    contract,
    claims?.data,
    contractFiles,
    variations?.data,
    variationsReceived?.data,
    approvalClaims?.data,
    subcontracts?.data,
    project?.systemDate,
    notifications,
  ]);

  const actionList: ActionListType[] = useMemo(() => {
    return [
      {
        id: 'back',
        label: 'Back',
        action: () => {
          navigate(`/projects/${contract.projectId}`);
        },
      },
    ];
  }, [navigate, contract.projectId]);

  return (
    <>
      <Box>
        <ContractDetails
          actionList={actionList}
          description={contract.description}
          summary={summary}
          reloadData={reloadContract}
          contract={contract}
        />
        <Tabs tabsData={tabs}>
          <TabsItem id={PageContractApproveTabs.tasks}>
            <TaskGroups
              canEdit={false}
              contractId={contract.id}
              queryId="contract-approve"
              selectedTaskGroup={selectedTaskGroup}
              handleSelectedTaskGroup={handleSelectedTaskGroup}
              render={reloadTree => (
                <>
                  {tasks ? (
                    <TaskTab
                      contract={contract}
                      tasks={tasks}
                      selectedTaskGroup={selectedTaskGroup}
                      reloadData={reloadDataContract}
                      reloadContract={reloadContract}
                      page={page}
                      rowsPerPage={rowsPerPage}
                      handleChangePage={handleChangePage}
                      handleChangeRowsPerPage={handleChangeRowsPerPage}
                      handleDeleteTask={handleDeleteTasks}
                    />
                  ) : (
                    <SkeletonTable />
                  )}
                </>
              )}
            />
          </TabsItem>
          <TabsItem id={PageContractApproveTabs.subContracts}>
            <SubContractTab
              contract={contract}
              data={subcontracts?.data}
              refetchSubContracts={reloadSubContracts}
              isLoading={isSubContractsLoading}
            />
          </TabsItem>
          <TabsItem id={PageContractApproveTabs.compliance}>
            <ComplianceTab
              contract={contract}
              isContractor={isContractor(user, contract)}
              isContractFilesLoading={isContractFilesLoading}
              refetchContractFiles={reloadContractFiles}
              contractFiles={contractFiles}
            />
          </TabsItem>
          <TabsItem id={PageContractApproveTabs.claimsReceived}>
            <ClaimsReceivedTab
              approvals={approvalClaims?.data}
              approvalsTotal={approvalClaims?.meta.total || 0}
              handleChangePage={handleChangePageApproval}
              page={pageApproval}
              handleClick={handleClick}
              handleCheckbox={handleCheckbox}
              notifications={notifications}
              isLoading={isLoading}
            />
          </TabsItem>
          <TabsItem id={PageContractApproveTabs.subContractClaims}>
            <SubContractorClaimsReceivedTab contract={contract} />
          </TabsItem>
          <TabsItem id={PageContractApproveTabs.subContractVariations}>
            <SubContractorVariationsReceivedTab contractId={contract.id} />
          </TabsItem>
          <TabsItem id={PageContractApproveTabs.variationsMade}>
            <ClaimHistory
              claims={claims?.data || []}
              isContractor={isContractor(user, contract)}
              isCustomer={isCustomer(user, contract)}
              reloadClaims={reloadClaims}
              reloadContract={reloadContract}
              page={pageClaims}
              handleClick={handleClick}
              handleCheckbox={handleCheckbox}
              notifications={notifications}
              handleChangePage={handleChangePageClaims}
              rowsPerPage={rowsPerPageClaims}
              total={claims?.meta.total || 0}
            />
          </TabsItem>
          <TabsItem id={PageContractApproveTabs.variationsReceived}>
            <VariationsReceivedTab
              isShowAVTable={
                isHeadContract ? isAnticipatedVariationsFunction : true
              }
              contractId={contract.id}
              contingencyBudget={contract.contingencyBudget}
              handleClick={handleClick}
              handleCheckbox={handleCheckbox}
              notifications={notifications}
            />
          </TabsItem>
          <TabsItem id={PageContractApproveTabs.variationsMade}>
            <VariationsMadeTab
              contractId={contract.id}
              handleClick={handleClick}
              handleCheckbox={handleCheckbox}
              notifications={notifications}
            />
          </TabsItem>
          <TabsItem id={PageContractApproveTabs.retention}>
            <RetentionTab contract={contract} summary={summary} />
          </TabsItem>
        </Tabs>
      </Box>
      <ConfirmDialog />
    </>
  );
}

export { ContractApproveView };
