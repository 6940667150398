import { useState } from 'react';

export const useDialogState = () => {
  const [isVisible, setIsVisible] = useState<boolean>(false);

  const open = () => {
    setIsVisible(true);
  };

  const close = () => {
    setIsVisible(false);
  };

  return { isVisible, open, close };
};
