import { axios } from './axios';
import {
  CreateTodoPayload,
  ResponseListBaseType,
  Todo,
  UpdateTodoPayload,
} from './types';

async function createTodo(payload: CreateTodoPayload): Promise<Todo> {
  const resp = await axios.post(`/todos`, payload);

  return resp.data;
}

async function listTodo(
  taskId: string
): Promise<ResponseListBaseType<Array<Todo>>> {
  const resp = await axios.get(`/todos`, {
    params: {
      taskId,
    },
  });

  return resp.data;
}

async function deleteTodo(todoId: string): Promise<string> {
  const resp = await axios.delete(`/todos/${todoId}`);

  return resp.data;
}

async function updateTodo(payload: UpdateTodoPayload): Promise<Todo> {
  const resp = await axios.patch(`/todos/${payload.todoId}`, {
    description: payload.description,
    state: payload.state,
  });
  return resp.data;
}

export { createTodo, updateTodo, deleteTodo, listTodo };
