import { JSONContent } from '@tiptap/core';
import { Metric } from './metric-types';

export enum ReportItemStyle {
  Basic = 'Basic',
  Donut = 'Donut',
  Pie = 'Pie',
  HeadlineTextBox = 'HeadlineTextBox',
  CustomTextBox = 'CustomTextBox',
  ContractList = 'ContractList',
  ProjectList = 'ProjectList',
  Map = 'Map',
  Picture = 'Picture',
  Logo = 'Logo',
}

export enum Denomination {
  Million = 'Million',
  Billion = 'Billion',
  Thousands = 'Thousands',
  Actual = 'Actual',
}

export interface Report {
  id: string;
  name: string;
  order: string;
  projectId: string;
  userId: string;
  createdAt: Date;
}

export type ReportItemLayout = {
  x: number;
  y: number;
  w: number;
  h: number;
};

export interface ReportItem {
  id: string;
  heading?: string | null;
  subHeading?: string | null;
  style: ReportItemStyle;
  richtext: JSONContent | null;
  reportLayout: ReportItemLayout;
  reportId: string;
  imageKey: string | null;
  imageUrl: string | null;
  metricGroups: Array<ReportMetricGroup>;
  locations: Array<ReportItemLocation>;
  reportItemList: ReportItemList;
}

export interface ReportItemListFields {
  id: string;
  order: string;
  metric: Metric;
  reportItemListId: string;
}

export interface ReportItemList {
  id: string;
  reportItem: ReportItem;
  reportItemId: string;
  itemListfields: Array<ReportItemListFields>;
  contracts: Array<{
    id: string;
    contractId: string;
  }>;
  projects: Array<{
    id: string;
    projectId: string;
  }>;
}

export interface ReportMetricGroup {
  id: string;
  colour: string;
  denomination: string;
  decimalPlaces: number;
  reportMetricId: string;
  metricId: string;
  metric: Metric;
}

export interface ReportListData {
  id: string;
  name: string;
  order: string;
  projectId: string;
  userId: string;
  createdAt: Date;
  reportItems: Array<ReportItem>;
}

export interface ReportMetricGroupResource {
  id: string;
  colour: string;
  denomination: string;
  decimalPlaces: number;
  reportMetricId: string;
  metricId: string;
  metric: Metric;
  computedMetric: number;
  formattedMetric: string;
}

export interface ReportItemResource {
  id: string;
  heading?: string | null;
  subHeading?: string | null;
  style: ReportItemStyle;
  richtext: JSONContent | null;
  reportLayout: ReportItemLayout;
  reportId: string;
  imageKey: string | null;
  imageUrl: string | null;
  metricGroups: Array<ReportMetricGroupResource>;
  locations: Array<ReportItemLocation>;
  reportItemList: ReportItemList;
}

export interface ReportItemLocation {
  id: string;
  placeId: string;
  latitude: string;
  longitude: string;
  address: string;
  title?: string;
}

export type ComputedMetricGroup = ReportMetricGroup & {
  computedMetric: number;
  formattedMetric: string;
};
