export const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const MINIMUM_PASSWORD_LENGTH = 8;

export default function validatePasswordComplexity(value: string | undefined) {
  if (value) {
    const hasUpperCase = /[A-Z]/.test(value);
    const hasNumber = /[0-9]/.test(value);
    const hasSpecialCharacter = /[^A-Za-z0-9\s]/.test(value);
    const hasMinimumLength = value.length >= MINIMUM_PASSWORD_LENGTH;

    return {
      hasUpperCase,
      hasNumber,
      hasSpecialCharacter,
      hasMinimumLength,
      isValid:
        hasUpperCase && hasNumber && hasSpecialCharacter && hasMinimumLength,
    };
  }
  return undefined;
}

export function validatePassword(value: string | undefined) {
  const result = validatePasswordComplexity(value);
  if (!value) {
    return 'Password is required.';
  }
  if (!result?.hasMinimumLength) {
    return 'Password must be at least 8 characters.';
  }
  if (!result?.hasNumber) {
    return 'Password must 1 number';
  }
  if (!result?.hasSpecialCharacter) {
    return 'Password must 1 special character';
  }
  if (!result?.hasUpperCase) {
    return 'Password must 1 uppercase character';
  }

  return true;
}
