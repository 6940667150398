import React, { useState } from 'react';
import {
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
  Theme,
} from '@mui/material';
import { DialogClose, Heading } from '../../../../styles';
import { MyPaymentDefaultsTab } from './MyPaymentDefaultsTab';
import { MyContractTab } from './MyContractTab';
import { useAuth } from '../../../../common/auth-provider/AuthProvider';
import { useQueries, useQuery } from '@tanstack/react-query';
import {
  Contract,
  listContracts,
  listProjects,
  listSubContracts,
  NotificationSetting,
  Project,
  UpdateNotificationSetting,
} from '../../../../common/api';
import { Box } from '@mui/material';
import { updateContractAlertSettings } from '../../../../common/api/alertSettings';
import { MySubContractsTab } from './MySubContractsTab';
import {
  filterMyContract,
  filterMySubContract,
  filterPaymentDefaults,
} from './utils';

type Props = {
  close: () => void;
};

enum Tabs {
  PaymentDefaults,
  MyContract,
  MySubContract,
}

const ALL = 'ALL';

function MyAlertSettingsDialog({ close }: Props) {
  const [tabValue, setTabValue] = useState<Tabs>(Tabs.PaymentDefaults);
  const { user } = useAuth();
  const [selectedContractIds, setSelectedContractIds] = useState<string[]>([]);

  const [myPaymentsNotificationSettings, setMyPaymentsNotificationSettings] =
    useState<NotificationSetting[]>([]);
  const [myContractNotificationSettings, setMyContractNotificationSettings] =
    useState<NotificationSetting[]>([]);
  const [
    mySubContractNotificationSettings,
    setMySubContractNotificationSettings,
  ] = useState<NotificationSetting[]>([]);

  const { data: projectList } = useQuery<Project[]>(['projects'], () =>
    listProjects()
  );

  const contractsQueries = useQueries({
    queries:
      projectList?.map(project => ({
        queryKey: ['contracts', project.id],
        queryFn: () => listContracts({ projectId: project.id }),
        enabled: !!project.id,
      })) || [],
  });

  const isContractsLoading = contractsQueries.some(query => query.isLoading);

  const contracts = contractsQueries
    .map(query => query.data?.data || [])
    .flat();

  const filteredContracts = contracts.filter(
    (contract: Contract) =>
      contract.customerAccountId === user?.accountId ||
      contract.contractorAccountId === user?.accountId
  );

  const subcontractsQueries = useQueries({
    queries: contracts.map(contract => ({
      queryKey: ['subcontracts', contract.id],
      queryFn: async () => {
        const result = await listSubContracts({ contractId: contract.id });
        return result;
      },
      enabled: true,
    })),
  });

  const subcontracts = subcontractsQueries
    .map(query => query.data?.data || [])
    .flat()
    .filter(contract => contract.customerAccountId === user?.accountId);

  const projectsWithContracts =
    projectList
      ?.map(project => {
        const projectContracts = filteredContracts.filter(
          contract => contract.projectId === project.id
        );

        return projectContracts.length > 0
          ? {
              projectName: project.name,
              contracts: projectContracts,
            }
          : null;
      })
      .filter(project => project !== null) || [];

  const projectsWithSubContracts =
    projectList
      ?.map(project => {
        const projectContracts = subcontracts.filter(
          contract => contract.projectId === project.id
        );

        return projectContracts.length > 0
          ? {
              projectName: project.name,
              contracts: projectContracts,
            }
          : null;
      })
      .filter(project => project !== null) || [];

  const handleChange = (
    event: SelectChangeEvent<typeof selectedContractIds>
  ) => {
    const { value } = event.target;

    if ((typeof value === 'string' && value === ALL) || value.includes(ALL)) {
      const allContracts = projectsWithContracts.flatMap(
        project => project!.contracts
      );
      setSelectedContractIds(
        selectedContractIds.length === allContracts.length
          ? []
          : allContracts.map(contract => contract.id)
      );
    } else {
      const selectedIds = typeof value === 'string' ? value.split(',') : value;
      setSelectedContractIds(selectedIds);

      selectedIds.forEach(id => {
        const contract = contracts.find(contract => contract.id === id);

        if (contract && contract.notificationSettings?.length) {
          const myPaymentsSettings = filterPaymentDefaults(
            contract.notificationSettings
          );
          const myContractSettings = filterMyContract(
            contract.notificationSettings
          );
          const mySubContractSettings = filterMySubContract(
            contract.notificationSettings
          );

          if (myPaymentsSettings.length > 0) {
            setMyPaymentsNotificationSettings(myPaymentsSettings);
          }
          if (myContractSettings.length > 0) {
            setMyContractNotificationSettings(myContractSettings);
          }
          if (mySubContractSettings.length > 0) {
            setMySubContractNotificationSettings(myContractSettings);
          }
        }
      });
    }
  };

  const handleSave = async (payload: UpdateNotificationSetting[]) => {
    close();
    await updateContractAlertSettings(selectedContractIds, payload);
  };

  const isMainCustomer = filteredContracts.some(
    contract =>
      !contract.parentContractId &&
      contract.customerAccountId === user?.accountId
  );

  return (
    <Dialog open fullWidth maxWidth="lg" onClose={close}>
      <DialogTitle sx={styles.DialogTitle}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Heading>Alerts</Heading>
          {!isContractsLoading && (
            <>
              <FormControl sx={{ mb: 2, ml: 2, width: 300 }}>
                <InputLabel id="multiple-checkbox-label">
                  Select Contract
                </InputLabel>{' '}
                <Select
                  labelId="multiple-checkbox-label"
                  label="Select Contract"
                  id="contracts"
                  multiple
                  value={selectedContractIds}
                  onChange={handleChange}
                  renderValue={selected => {
                    const contractsOrSubcontracts =
                      tabValue === Tabs.MySubContract
                        ? projectsWithSubContracts
                        : projectsWithContracts;

                    return selected
                      .map(id => {
                        const contract = contractsOrSubcontracts
                          .flatMap(project => project!.contracts)
                          .find(contract => contract.id === id);
                        return contract ? contract.description : '';
                      })
                      .join(', ');
                  }}
                >
                  <MenuItem key="select-all" value={ALL}>
                    <Checkbox
                      checked={
                        selectedContractIds.length ===
                        (tabValue === Tabs.MySubContract
                          ? projectsWithSubContracts
                          : projectsWithContracts
                        ).flatMap(project => project!.contracts).length
                      }
                    />
                    <ListItemText primary="Select All Contracts" />
                  </MenuItem>

                  {(tabValue === Tabs.MySubContract
                    ? projectsWithSubContracts
                    : projectsWithContracts
                  ).map(project => [
                    <ListItemText
                      primary={project!.projectName}
                      sx={{ fontWeight: 'bold' }}
                    />,
                    ...project!.contracts.map(contract => (
                      <MenuItem key={contract.id} value={contract.id}>
                        <Checkbox
                          checked={
                            selectedContractIds.indexOf(contract.id) > -1
                          }
                        />
                        <ListItemText primary={contract.description} />
                      </MenuItem>
                    )),
                  ])}
                </Select>
              </FormControl>
            </>
          )}
        </Box>
        <DialogClose onClose={close} />
      </DialogTitle>
      <DialogContent
        sx={{ position: 'relative', display: 'flex', flexDirection: 'row' }}
      >
        <List sx={styles.DialogSidebar}>
          <ListItem disablePadding>
            <ListItemButton
              sx={{ m: 0 }}
              selected={tabValue === Tabs.PaymentDefaults}
              onClick={() => setTabValue(Tabs.PaymentDefaults)}
            >
              <ListItemText sx={styles.ListButton} primary="Payment Defaults" />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton
              sx={{ m: 0 }}
              selected={tabValue === Tabs.MyContract}
              onClick={() => setTabValue(Tabs.MyContract)}
            >
              <ListItemText sx={styles.ListButton} primary="My Contract" />
            </ListItemButton>
          </ListItem>
          {!isMainCustomer && (
            <ListItem disablePadding>
              <ListItemButton
                sx={{ m: 0 }}
                selected={tabValue === Tabs.MySubContract}
                onClick={() => setTabValue(Tabs.MySubContract)}
                disabled={!subcontracts.length}
              >
                <ListItemText
                  sx={styles.ListButton}
                  primary="My Sub-Contracts"
                />
              </ListItemButton>
            </ListItem>
          )}
        </List>

        {tabValue === Tabs.PaymentDefaults && (
          <MyPaymentDefaultsTab
            isDisabled={!selectedContractIds.length}
            accountId={user ? user.accountId : ''}
            notificationSettings={myPaymentsNotificationSettings}
            handleSave={handleSave}
            onClose={close}
          />
        )}

        {tabValue === Tabs.MyContract && (
          <MyContractTab
            isDisabled={!selectedContractIds.length}
            accountId={user ? user.accountId : ''}
            notificationSettings={myContractNotificationSettings}
            handleSave={handleSave}
            onClose={close}
          />
        )}

        {tabValue === Tabs.MySubContract && (
          <MySubContractsTab
            isDisabled={!selectedContractIds.length}
            accountId={user ? user.accountId : ''}
            notificationSettings={mySubContractNotificationSettings}
            handleSave={handleSave}
            onClose={close}
          />
        )}
      </DialogContent>
    </Dialog>
  );
}

export { MyAlertSettingsDialog };

const styles = {
  DialogTitle: {
    p: 2,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottom: '1px solid #e0e0e0',
  },
  ListButton: {
    fontSize: '24px',
    p: 1,
    pl: 0,
  },
  DialogSidebar: {
    position: 'sticky',
    left: 0,
    top: 0,
    flexShrink: 0,
    gap: 1,
    padding: 3,
    paddingLeft: 0,
    width: '200px',
    borderRight: '1px solid #e0e0e0',
  },
  DialogContent: {
    padding: 3,
  },
  IconButton: {
    color: (theme: Theme) => theme.palette.grey[500],
  },
};
