import {
  Avatar,
  Box,
  Dialog,
  DialogContent,
  IconButton,
  InputBase,
  Paper,
  Skeleton,
  Stack,
} from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import SendRoundedIcon from '@mui/icons-material/SendRounded';
import React, { ChangeEvent, useCallback, useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { VariationMessage, VariationMessageStatus } from '../../common/api';
import { useHotkeys } from 'react-hotkeys-hook';
import { useAuth } from '../../common/auth-provider/AuthProvider';
import { VariationChatMessage } from './VariationChatMessage';
import { stringAvatar } from '../../components/claim-chat/stringAvatar';
import {
  createVariationMessage,
  deleteVariationMessage,
  updateVariationMessage,
} from '../../common/api/variations';
import { DialogClose } from '../../styles';
import { useProject } from '../../common/hooks/useProject';

interface Props {
  variationId: string;
  messages: Array<VariationMessage>;
  permissionToSend: boolean;
  onClose: () => void;
  onSend: (message: VariationMessage) => void;
  onDelete: (messageId: string) => void;
  onUpdate?: (message: VariationMessage) => void;
}

function VariationsChat({
  variationId,
  messages,
  permissionToSend,
  onClose,
  onSend,
  onDelete,
  onUpdate,
}: Props) {
  const { user } = useAuth();

  const [messageInput, setMessageInput] = useState<string>('');

  const { project } = useProject();

  const { mutate: mutateCreateMessage, isLoading: isLoadingSend } = useMutation(
    ({ variationId, message }: { variationId: string; message: string }) =>
      createVariationMessage(variationId, message),
    {
      onSuccess: res => {
        onSend({
          ...res,
        });
      },
    }
  );

  const { mutate: mutateDeleteMessage, isLoading: isLoadingDelete } =
    useMutation(
      (messageId: string) => deleteVariationMessage(variationId, messageId),
      {
        onSuccess: (_res, messageId) => onDelete(messageId),
      }
    );

  const { mutate: mutateResolveMessage } = useMutation(
    (messageId: string) =>
      updateVariationMessage(variationId, messageId, {
        status: VariationMessageStatus.Resolved,
      }),
    {
      onSuccess: res => onUpdate?.(res),
    }
  );

  const handleResolveMessage = useCallback(
    (messageId: string) => {
      mutateResolveMessage(messageId);
    },
    [mutateResolveMessage]
  );

  const handleChangeMessageInput = useCallback(
    ({ target }: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setMessageInput(target.value);
    },
    []
  );

  const handleSendMessage = useCallback(() => {
    if (messageInput.length === 0) {
      return;
    }
    if (variationId) {
      mutateCreateMessage({
        variationId: variationId,
        message: messageInput,
      });
    } else {
      if (user) {
        onSend({
          id: uuidv4(),
          createdAt: new Date(project?.systemDate || new Date()),
          message: messageInput,
          status: VariationMessageStatus.Unresolved,
          userId: user?.id || '',
          user: user,
          variationId: '-1',
        });
      }
    }
    setMessageInput('');
  }, [
    messageInput,
    variationId,
    user,
    mutateCreateMessage,
    onSend,
    project?.systemDate,
  ]);

  const handleDeleteMessage = useCallback(
    (messageId: string) => {
      if (variationId) {
        mutateDeleteMessage(messageId);
      } else {
        onDelete(messageId);
      }
    },
    [variationId, mutateDeleteMessage, onDelete]
  );

  useHotkeys(
    'ctrl+enter',
    () => handleSendMessage(),
    {
      enableOnFormTags: true,
    },
    [messageInput]
  );

  const isLoading = isLoadingSend || isLoadingDelete;

  return (
    <Dialog open={true} onClose={onClose} onClick={e => e.stopPropagation()}>
      <DialogContent style={styles.DialogContent}>
        <DialogClose onClose={onClose} />
        <Box>
          {isLoading ? (
            <>
              <Skeleton variant="circular" width={'40px'} height={'40px'} />
              <br />
              <Skeleton variant="rectangular" width={'540px'} height={'30px'} />
              <br />
              <Skeleton variant="circular" width={'40px'} height={'40px'} />
              <br />
              <Skeleton variant="rectangular" width={'540px'} height={'30px'} />
              <br />
              <Skeleton variant="circular" width={'40px'} height={'40px'} />
              <br />
              <Skeleton variant="rectangular" width={'540px'} height={'30px'} />
              <br />
            </>
          ) : messages && messages.length > 0 ? (
            <Box style={styles.MessageList} id="message-list">
              <Box>
                {user &&
                  messages.map(message => (
                    <VariationChatMessage
                      key={message.id}
                      currentUser={user}
                      message={message}
                      canEdit={permissionToSend}
                      onDeleteMessage={handleDeleteMessage}
                      onResolveMessage={handleResolveMessage}
                    />
                  ))}
              </Box>
            </Box>
          ) : (
            <div>No chat messages to display</div>
          )}
          <br />
          {permissionToSend && user && (
            <Stack direction="row" spacing={2}>
              <Avatar
                {...stringAvatar(user.email)}
                style={{ marginTop: '5px' }}
              />
              <Paper style={styles.Paper}>
                <InputBase
                  multiline
                  maxRows={3}
                  style={styles.InputBase}
                  value={messageInput}
                  onChange={handleChangeMessageInput}
                  placeholder="Reply"
                />
                <IconButton onClick={handleSendMessage}>
                  <SendRoundedIcon />
                </IconButton>
              </Paper>
            </Stack>
          )}
        </Box>
      </DialogContent>
    </Dialog>
  );
}

const styles: { [key: string]: React.CSSProperties } = {
  DialogContent: {
    width: '600px',
    height: '500px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
  },
  MessageTime: {
    color: 'grey',
    marginTop: '9px',
  },
  MessageAuthor: {
    marginTop: '9px',
    fontWeight: 'bold',
  },
  Message: {
    marginTop: '10px',
    marginLeft: '56px',
  },
  MessageList: {
    maxHeight: '340px',
    overflowY: 'auto',
    display: 'flex',
    flexDirection: 'column-reverse',
  },
  Paper: {
    borderColor: 'none',
    boxShadow: 'none',
    backgroundColor: '#E7E7E7',
    paddingRight: '10px',
  },
  InputBase: {
    width: '420px',
    margin: '10px',
  },
};

export { VariationsChat, stringAvatar };
