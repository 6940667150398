import React, { ChangeEvent, Fragment, SyntheticEvent } from 'react';
import { TreeView } from '@mui/lab';
import AddIcon from '@mui/icons-material/Add';
import { TaskGroupItem } from './TaskGroupItem';
import { ReactComponent as FolderIcon } from '../../../common/assets/folder.svg';
import { ReactComponent as FolderOpenIcon } from '../../../common/assets/folder-open.svg';
import { grey900 } from '../../../styles/colours';
import { KebabMenu } from '../../menu/KebabMenu';
import { TreeDraggable } from './TreeDraggable';
import { orderTaskGroups } from '../TaskGroup.utils';
import { TreeRootDropable } from './TreeRootDroppable';
import { TaskGroup } from '../../../common/api';
import { IconButton } from '@mui/material';
import { TaskGroupItemMenuWrapper } from './TaskGroupTree.style';
import { TASK_GROUP_ALL_ID } from './TaskGroups.constants';

const TASK_GROUP_OPTIONS = ['Rename', 'Delete'];

type onSelectAddFolder = {
  onSelectAdd?: (taskGroupId: string | null) => void;
};

function TaskGroupTree({
  taskGroups,
  expanded,
  selectedTaskGroup,
  canEdit,
  handleSelectedTaskGroup,
  handleSetActiveParent,
  onOptionsSelect,
  onReload,
  setExpanded,
  onSelectAdd,
}: {
  taskGroups: Array<TaskGroup>;
  selectedTaskGroup?: string | null;
  canEdit?: boolean;
  expanded: Array<string>;
  handleSelectedTaskGroup?: (nodeId: string) => void;
  handleSetActiveParent: (node: string) => void;
  onOptionsSelect?: (taskGroupId: string, option: string) => void;
  onReload?: () => void;
  setExpanded: (ids: Array<string>) => void;
} & onSelectAddFolder) {
  const handleTreeItemSelect = (
    event: ChangeEvent<unknown>,
    nodeId: string
  ) => {
    handleSelectedTaskGroup?.(nodeId);
  };

  const handleOnNodeToggle = (event: SyntheticEvent, nodes: Array<string>) => {
    const expandingNodes = nodes.filter(
      (nodeId: string) => !expanded.includes(nodeId)
    );
    setExpanded(nodes);

    if (expandingNodes[0]) {
      const childId = expandingNodes[0];
      handleSetActiveParent(childId);
    }
  };

  const renderTree = (parentId: string | null) => {
    const items = orderTaskGroups(
      taskGroups.filter(taskGroup => taskGroup.parentId === parentId)
    );
    return items.map((taskGroup, index) => {
      const childrenTaskGroup = renderTree(taskGroup.id);

      const item = (
        <TaskGroupItem
          editable={!!canEdit}
          sx={{ width: '100%' }}
          nodeId={taskGroup.id}
          label={
            <>
              <span>{taskGroup.name}</span>
              {canEdit && (
                <TaskGroupItemMenuWrapper className="menu-items">
                  <KebabMenu
                    options={TASK_GROUP_OPTIONS}
                    onOptionSelect={option =>
                      onOptionsSelect?.(taskGroup.id, option)
                    }
                  />
                  <AddFolderButton
                    nodeId={taskGroup.id}
                    onSelectAdd={onSelectAdd}
                  />
                </TaskGroupItemMenuWrapper>
              )}
            </>
          }
        >
          {childrenTaskGroup.length ? childrenTaskGroup : null}
        </TaskGroupItem>
      );
      return (
        <Fragment key={`${taskGroup.id}`}>
          {canEdit ? (
            <TreeDraggable
              key={taskGroup.id}
              id={taskGroup.id}
              index={index}
              parentId={taskGroup.parentId}
              taskGroup={taskGroup}
              onReload={onReload}
            >
              {item}
            </TreeDraggable>
          ) : (
            item
          )}
        </Fragment>
      );
    });
  };

  return (
    <TreeView
      className={canEdit ? 'editable' : 'readable'}
      aria-label="task groups"
      sx={{
        mb: 3,
        width: '100%',
        '.MuiTreeItem-root': {
          width: '100%',
        },

        '.MuiTreeItem-group': {
          marginLeft: '12px',
          borderLeft: `1px solid #CBD5E1`,
          maxWidth: '100%',
        },
      }}
      onNodeSelect={handleTreeItemSelect}
      defaultEndIcon={<FolderOpenIcon />}
      selected={selectedTaskGroup || ''}
      defaultCollapseIcon={<FolderIcon />}
      defaultExpandIcon={<FolderOpenIcon />}
      expanded={expanded}
      onNodeToggle={handleOnNodeToggle}
    >
      <TreeRootDropable>
        <TaskGroupItem
          nodeId={TASK_GROUP_ALL_ID}
          label={
            <>
              <span>All Tasks</span>
              {canEdit && (
                <TaskGroupItemMenuWrapper className="menu-items">
                  <AddFolderButton nodeId={null} onSelectAdd={onSelectAdd} />
                </TaskGroupItemMenuWrapper>
              )}
            </>
          }
          editable={!!canEdit}
        >
          {renderTree(null)}
        </TaskGroupItem>
      </TreeRootDropable>
    </TreeView>
  );
}

export { TaskGroupTree };

function AddFolderButton({
  nodeId,
  onSelectAdd,
}: { nodeId: string | null } & onSelectAddFolder) {
  return (
    <IconButton
      className="taskgroup-add"
      onClick={e => {
        e.preventDefault();
        e.stopPropagation();
        onSelectAdd?.(nodeId);
      }}
    >
      <AddIcon sx={{ color: grey900, width: '16px' }} />
    </IconButton>
  );
}
