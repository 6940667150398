import React, { useCallback, useState } from 'react';

import { useMutation } from '@tanstack/react-query';
import { AlertColor, Box, Button, Dialog, DialogContent } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Divider from '@mui/material/Divider';

import { Heading, InputActions } from '../../../styles';
import {
  updateReportItem,
  UpdateReportItemPayload,
} from '../../../common/api/reports';
import RichtextEditor from './RichtextEditor';
import { JSONContent } from '@tiptap/core';
import { ReportItemStyle } from '../../../common/api/report-types';

type Props = {
  id: string;
  richtext?: JSONContent | null;
  reportId: string;
  style: ReportItemStyle;
  reload: () => void;
  close: () => void;
  showAlert: (title: string, severity: AlertColor) => void;
};

function EditTextBoxTile({
  id,
  richtext,
  reportId,
  style,
  close,
  reload,
  showAlert,
}: Props) {
  const [richState, setRichState] = useState<JSONContent>();

  const { mutateAsync: mutateUpdate, isLoading } = useMutation(
    (payload: UpdateReportItemPayload) => updateReportItem(payload),
    {
      onSuccess: () => {
        reload();
        close();
      },
      onError: () =>
        showAlert(
          'There was an error updating the tile. Please try again later.',
          'error'
        ),
    }
  );

  const onSubmit = useCallback(() => {
    mutateUpdate({
      reportId,
      id,
      updatedReportItem: {
        id,
        reportId,
        heading: null,
        subHeading: null,
        imageKey: null,
        richtext: richState || null,
        metricGroups: [],
        newMetricGroups: [],
      },
    });
  }, [id, reportId, richState, mutateUpdate]);

  return (
    <Dialog open fullWidth maxWidth="md" onClose={close}>
      <DialogContent>
        <Heading style={{ marginBottom: '8px' }}>
          {style === ReportItemStyle.CustomTextBox
            ? 'Custom Text Box'
            : 'Headline Text Box'}
        </Heading>

        <Divider sx={{ mb: 2 }} />

        <Box>
          <RichtextEditor
            initialContent={richtext || null}
            onChange={json => setRichState(json)}
          />
        </Box>
        <InputActions>
          <Button variant="outlined" onClick={close}>
            Cancel
          </Button>
          <LoadingButton
            type="submit"
            variant="contained"
            loading={isLoading}
            onClick={() => onSubmit()}
          >
            Save
          </LoadingButton>
        </InputActions>
      </DialogContent>
    </Dialog>
  );
}

export default EditTextBoxTile;
