import React from 'react';
import { AlertColor, Typography } from '@mui/material';
import { createPortal } from 'react-dom';
import { useMutation } from '@tanstack/react-query';

import { Tile, TileText, TileValue } from './BasicTileItem.styles';
import { REPORT_METRIC_COLOURS } from './Report.constants';
import { grey500 } from '../../styles/colours';
import { ReportCellKebab } from './ReportCell.style';
import { KebabMenu } from '../../components/menu/KebabMenu';
import { useDialogState } from '../../components/dialog/dialog.hooks';
import EditBasicTile from './EditBasicTile';
import { useConfirmDialog } from '../../common/hooks/useConfirmDialog';
import {
  deleteReportItem,
  deleteReportItemPayload,
} from '../../common/api/reports';
import { ReportItemResource } from '../../common/api/report-types';

type Props = {
  reportItem: ReportItemResource;
  isEditingDashboard: boolean;
  reload: () => void;
  showAlert: (title: string, severity: AlertColor) => void;
};

const BasicTileItem = ({
  reportItem,
  isEditingDashboard,
  reload,
  showAlert,
}: Props) => {
  const {
    isVisible: isEditVisible,
    open: openEdit,
    close: closeEdit,
  } = useDialogState();
  const { confirmAction, ConfirmDialog } = useConfirmDialog();

  const { mutateAsync: mutatedeleteReportItem } = useMutation(
    (payload: deleteReportItemPayload) => deleteReportItem(payload),
    {
      onSuccess: () => {
        reload();
        showAlert('Tile removed!', 'success');
      },
      onError: () =>
        showAlert(
          'There was an error deleting the tile. Please try again later.',
          'error'
        ),
    }
  );

  const el = document.getElementById('report-actions');

  const id = reportItem.id;
  const reportId = reportItem.reportId;
  const metricGroups = reportItem.metricGroups;
  const metricGroupId = metricGroups?.[0]?.id;
  const metricId = metricGroups?.[0]?.metricId;
  const colour = metricGroups?.[0]?.colour;
  const denomination = metricGroups?.[0]?.denomination;
  const decimalPlaces = metricGroups?.[0]?.decimalPlaces;
  const formattedMetric = metricGroups?.[0]?.formattedMetric;
  const heading = !metricId ? 'Heading' : reportItem.heading;
  const subHeading = !metricId ? 'Optional text' : reportItem.subHeading;

  return (
    <Tile>
      {el &&
        createPortal(
          <>
            {isEditVisible && (
              <EditBasicTile
                id={id}
                metricGroupId={metricGroupId}
                reportId={reportId}
                metricId={metricId}
                colour={colour || REPORT_METRIC_COLOURS[0]}
                heading={metricId ? heading : null}
                subHeading={metricId ? subHeading : null}
                denomination={denomination}
                decimalPlaces={decimalPlaces}
                close={closeEdit}
                reload={reload}
                showAlert={showAlert}
              />
            )}
          </>,
          el
        )}

      <ConfirmDialog />
      {!isEditingDashboard && (
        <ReportCellKebab>
          <KebabMenu
            options={['Tile Options', 'Remove Tile']}
            onOptionSelect={async option => {
              if (option === 'Remove Tile') {
                await confirmAction(
                  'Are you sure you want to remove this tile?'
                );
                await mutatedeleteReportItem({
                  reportId,
                  id,
                });
              } else if (option === 'Tile Options') {
                openEdit();
              }
            }}
          />
        </ReportCellKebab>
      )}

      <TileValue
        style={{
          backgroundColor: colour || REPORT_METRIC_COLOURS[0],
        }}
      >
        {formattedMetric}
      </TileValue>
      <TileText>
        {heading && (
          <Typography variant="body1" marginBottom={'4px'}>
            {heading}
          </Typography>
        )}
        {subHeading && (
          <Typography variant="body2" color={grey500}>
            {subHeading}
          </Typography>
        )}
      </TileText>
    </Tile>
  );
};

export default BasicTileItem;
