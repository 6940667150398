import React, { useCallback, useMemo, useState } from 'react';
import sortBy from 'lodash/sortBy';
import { useMutation, useQuery } from '@tanstack/react-query';
import {
  AlertColor,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Divider from '@mui/material/Divider';
import groupBy from 'lodash/groupBy';
import orderBy from 'lodash/orderBy';

import { Heading, InputActions } from '../../../styles';
import {
  UpdateProjectListDataPayload,
  updateProjectListReport,
} from '../../../common/api/reports';
import { listProjectsForUserType, Project } from '../../../common/api';
import { getMetricsList } from '../../../common/api/metrics';
import SortableList from './SortableList';
import { SkeletonTable } from '../../../components/skeleton-table';
import { Metric, MetricKey } from '../../../common/api/metric-types';
import { ReportItem } from '../../../common/api/report-types';

const ALL = 'all';

type Props = {
  id: string;
  isDefaultDashboard: boolean;
  reportId: string;
  reportItem: ReportItem;
  reload: () => void;
  close: () => void;
  showAlert: (title: string, severity: AlertColor) => void;
};

function EditProjectListTile({
  id,
  isDefaultDashboard,
  reportId,
  reportItem,
  close,
  reload,
  showAlert,
}: Props) {
  const existingProjects = reportItem.reportItemList.projects || [];
  const existingFields = reportItem.reportItemList.itemListfields || [];

  const [selectedProjectIds, setSelectedProjectIds] = useState<Array<string>>(
    existingProjects.map(ex => ex.projectId)
  );
  const [selectedMetrics, setSelectedMetrics] = useState<
    Array<{ metricId: string; order: string }>
  >(
    sortBy(existingFields, 'order').map(ef => ({
      metricId: ef.metric.id,
      order: ef.order,
    }))
  );

  // get list of projects
  const { data: projectList, isLoading: isProjectListLoading } = useQuery<
    Array<Project>
  >(
    ['projects'],
    () => {
      return listProjectsForUserType();
    },
    {
      onSuccess: res => {
        if (isDefaultDashboard) {
          setSelectedProjectIds(res.map(p => p.id));
        }
      },
    }
  );

  const { data } = useQuery<Array<Metric>>(['metricList'], () => {
    return getMetricsList();
  });

  const metrics = useMemo(() => {
    const projectMetrics = [
      MetricKey.ProjectName,
      MetricKey.ProgessBar,
      MetricKey.ActiveContracts,
      MetricKey.OriginalProjectValue,
      MetricKey.AdjustedProjectValue,
      MetricKey.TotalPaidToDate,
      MetricKey.CurrentlyClaimed,
      MetricKey.CurrentlyApproved,
      MetricKey.ValueToComplete,
      MetricKey.TotalApprovedVariations,
      MetricKey.TotalClaimedUnapprovedVariations,
    ];
    return orderBy(
      data?.filter(d => projectMetrics.includes(d.key)) || [],
      'group'
    );
  }, [data]);

  const groupedMetrics = useMemo(() => {
    return groupBy(metrics, 'group');
  }, [metrics]);

  const { mutateAsync: mutateUpdate, isLoading } = useMutation(
    (payload: UpdateProjectListDataPayload) => updateProjectListReport(payload),
    {
      onSuccess: () => {
        reload();
        close();
      },
      onError: () =>
        showAlert(
          'There was an error updating the tile. Please try again later.',
          'error'
        ),
    }
  );

  const onSubmit = useCallback(() => {
    // on save set the order
    const order = 'abcdefghijklmnop';
    const fields = selectedMetrics.map((s, index) => {
      const item = metrics.find(m => m.id === s.metricId);
      return {
        id: null,
        metricId: item?.id || '',
        order: order.charAt(index),
      };
    });

    mutateUpdate({
      id,
      reportId,
      fields,
      projectIds: selectedProjectIds,
    });
  }, [
    id,
    reportId,
    selectedProjectIds,
    selectedMetrics,
    metrics,
    mutateUpdate,
  ]);

  const handleChange = (
    event: SelectChangeEvent<typeof selectedProjectIds>
  ) => {
    const {
      target: { value },
    } = event;

    // On autofill we get a stringified value.
    if ((typeof value === 'string' && value === ALL) || value.includes(ALL)) {
      setSelectedProjectIds(
        selectedProjectIds.length === projectList?.length
          ? []
          : projectList?.map(p => p.id) || []
      );
    } else {
      setSelectedProjectIds(
        typeof value === 'string' ? value.split(',') : value
      );
    }
  };

  return (
    <Dialog open fullWidth maxWidth="md" onClose={close}>
      <DialogContent>
        <Heading style={{ marginBottom: '8px' }}>Project List Tile</Heading>

        <Divider sx={{ mb: 2 }} />

        {isProjectListLoading || metrics.length === 0 ? (
          <SkeletonTable />
        ) : (
          <>
            <InputLabel id="multiple-checkbox-label">
              Select Projects
            </InputLabel>
            <FormControl sx={{ mb: 2, width: 300 }}>
              <Select
                labelId="multiple-checkbox-label"
                id="projects"
                multiple
                disabled={isDefaultDashboard}
                value={selectedProjectIds}
                onChange={handleChange}
                renderValue={selected => {
                  if (isDefaultDashboard) {
                    return 'All Projects';
                  }
                  return projectList
                    ?.filter(p => selected.includes(p.id))
                    .map(p => p.name)
                    .join(', ');
                }}
              >
                <MenuItem key="select-all" value={ALL}>
                  <Checkbox
                    checked={selectedProjectIds.length === projectList?.length}
                  />
                  <ListItemText primary="Select All Projects" />
                </MenuItem>
                {projectList?.map(project => (
                  <MenuItem key={project.id} value={project.id}>
                    <Checkbox
                      checked={selectedProjectIds.indexOf(project.id) > -1}
                    />
                    <ListItemText primary={project.name} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <Box display="flex">
              <Box width={'50%'}>
                {selectedProjectIds.length > 0 && (
                  <FormControl>
                    <ListItemText>
                      <strong>General</strong>
                    </ListItemText>
                    <ListItems
                      metrics={groupedMetrics['General']}
                      selectedMetricIds={selectedMetrics.map(s => s.metricId)}
                      onSelect={id => {
                        const metric = metrics.find(m => m.id === id);
                        if (metric?.key === MetricKey.ProjectName) {
                          return false;
                        }
                        if (selectedMetrics.find(s => s.metricId === id)) {
                          setSelectedMetrics(
                            selectedMetrics.filter(s => s.metricId !== id)
                          );
                        } else if (selectedMetrics.length < 7) {
                          setSelectedMetrics([
                            ...selectedMetrics,
                            { metricId: id, order: 'z' },
                          ]);
                        }
                      }}
                    />
                    <ListItemText>
                      <strong>Commercial</strong>
                    </ListItemText>
                    <ListItems
                      metrics={groupedMetrics['Commercial Metrics']}
                      selectedMetricIds={selectedMetrics.map(s => s.metricId)}
                      onSelect={id => {
                        if (selectedMetrics.find(s => s.metricId === id)) {
                          setSelectedMetrics(
                            selectedMetrics.filter(s => s.metricId !== id)
                          );
                        } else if (selectedMetrics.length < 7) {
                          setSelectedMetrics([
                            ...selectedMetrics,
                            { metricId: id, order: 'z' },
                          ]);
                        }
                      }}
                    />
                    <ListItemText>
                      <strong>Variation</strong>
                    </ListItemText>
                    <ListItems
                      metrics={groupedMetrics['Variation Metrics']}
                      selectedMetricIds={selectedMetrics.map(s => s.metricId)}
                      onSelect={id => {
                        if (selectedMetrics.find(s => s.metricId === id)) {
                          setSelectedMetrics(
                            selectedMetrics.filter(s => s.metricId !== id)
                          );
                        } else if (selectedMetrics.length < 7) {
                          setSelectedMetrics([
                            ...selectedMetrics,
                            { metricId: id, order: 'z' },
                          ]);
                        }
                      }}
                    />
                  </FormControl>
                )}
              </Box>

              <Box width={'50%'}>
                {selectedProjectIds.length > 0 && (
                  <>
                    <Box display="flex" justifyContent="space-between">
                      <Typography>
                        <strong>Selected fields (max 7)</strong>
                      </Typography>
                      <Button
                        variant="text"
                        onClick={() => {
                          const projectName = metrics.find(
                            m => m.key === MetricKey.ProjectName
                          );
                          if (projectName) {
                            setSelectedMetrics([
                              {
                                metricId: projectName.id,
                                order: '',
                              },
                            ]);
                          }
                        }}
                      >
                        Clear All
                      </Button>
                    </Box>
                    <Box>
                      <SortableList
                        key={selectedMetrics.map(i => i.metricId).join(',')}
                        items={selectedMetrics.map(sm => {
                          const m = metrics.find(m => m.id === sm.metricId);
                          return {
                            id: sm.metricId,
                            name: m?.title || '',
                          };
                        })}
                        onItemOrderChange={items => {
                          setSelectedMetrics(
                            items.map(i => ({
                              metricId: i.id,
                              order: '', // order doesnt matter till saving
                            }))
                          );
                        }}
                      />
                    </Box>
                  </>
                )}
              </Box>
            </Box>

            <Box>
              <InputActions>
                <Button variant="outlined" onClick={close}>
                  Cancel
                </Button>
                <LoadingButton
                  type="submit"
                  variant="contained"
                  disabled={selectedProjectIds.length === 0}
                  loading={isLoading}
                  onClick={() => onSubmit()}
                >
                  Save
                </LoadingButton>
              </InputActions>
            </Box>
          </>
        )}
      </DialogContent>
    </Dialog>
  );
}

const ListItems = ({
  metrics,
  selectedMetricIds,
  onSelect,
}: {
  metrics: Array<Metric>;
  selectedMetricIds: Array<string>;
  onSelect: (id: string) => void;
}) => {
  return (
    <>
      {metrics.map(item => {
        return (
          <MenuItem
            key={item.id}
            value={item.id}
            sx={{ paddingTop: '0', paddingBottom: '0' }}
            onClick={() => {
              onSelect(item.id);
            }}
          >
            <Checkbox checked={selectedMetricIds.includes(item.id)} />
            <ListItemText primary={item.title} />
          </MenuItem>
        );
      })}
    </>
  );
};

export default EditProjectListTile;
