import React, { useMemo, useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Divider,
  Drawer,
  IconButton,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import ClearIcon from '@mui/icons-material/Clear';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { useAuth } from '../../../common/auth-provider/AuthProvider';
import { SkeletonTable } from '../../../components/skeleton-table';
import { Heading, StyledTable } from '../../../styles';
import { listPayments } from '../../../common/api/payments';
import { useListPagination } from '../../../common/hooks/useListPagination';
import { PaymentsDueRow } from './PaymentDueRow';
import { useSnackbar } from '../../../common/hooks/useSnackbar';
import { Pagination } from '../../../components/pagination';
import { useSortedTable } from '../../../components/sorted-table-head/SortedTable.hooks';
import { SortedTableHead } from '../../../components/sorted-table-head';
import { getPaymentRows, headCellsPaymentsDue } from './PaymentTableHeader';
import { stableSort } from '../../../common/sort/stableSort';
import { getComparator } from '../../../common/sort/getComparator';
import { useProject } from '../../../common/hooks/useProject';
import { useDialogState } from '../../../components/dialog/dialog.hooks';
import { grey300 } from '../../../styles/colours';
import PaymentManualRow from './PaymentManualOptions';
import PaymentManualClaimTable from './PaymentManualClaimTable';
import PaymentMarkAsPaid from './PaymentMarkAsPaid';
import PaymentEditMarkAsPaid from './PaymentEditMarkAsPaid';
import { ManualPayment } from '../../../common/api';

type Props = {
  canMakeTransfer?: boolean;
};
function PaymentsDueTab({ canMakeTransfer }: Props) {
  const { isVisible, close, open } = useDialogState();
  const {
    isVisible: isManualPaymentFormVisible,
    close: closeManualPaymentForm,
    open: openManualPaymentForm,
  } = useDialogState();
  const { showAlert, SnackbarComponent } = useSnackbar();

  const [selectedPayment, setSelectedPayment] = useState<string>();
  const [editManualPayment, setEditManualPayment] =
    useState<ManualPayment | null>();

  const { user } = useAuth();
  const accountId = user?.accountId;

  const { project } = useProject();

  const { page, rowsPerPage, skip, handleChangePage } = useListPagination();
  const { order, orderBy, handleRequestSort } = useSortedTable();

  const { data, isLoading, refetch } = useQuery(
    ['payments-due', accountId, project?.systemDate, page, rowsPerPage],
    () =>
      listPayments(
        {
          accountId: accountId!,
        },
        {
          skip: skip,
          take: rowsPerPage,
        }
      )
  );

  const payments = getPaymentRows(data?.data || []);

  const selectedPaymentDetails = useMemo(() => {
    const payment = payments.find(p => p.id === selectedPayment);
    return {
      ...payment,
      manualPayments: data?.data.find(d => d.id === payment?.id)
        ?.manualPayments,
    };
  }, [selectedPayment, payments, data?.data]);

  const hasManualPayments = useMemo(() => {
    return (
      selectedPaymentDetails.manualPayments &&
      selectedPaymentDetails.manualPayments.length > 0
    );
  }, [selectedPaymentDetails]);

  return (
    <>
      {isLoading ? (
        <SkeletonTable />
      ) : (
        <>
          <SnackbarComponent />
          <Drawer
            anchor="right"
            sx={{
              '& .MuiDrawer-paper': {
                marginTop: '0px',
                height: '100%',
              },
            }}
            open={isVisible}
            onClose={close}
          >
            <Box display="flex" padding={2}>
              <IconButton
                aria-label="close"
                onClick={close}
                sx={{
                  color: theme => theme.palette.grey[500],
                }}
              >
                <ClearIcon />
              </IconButton>
            </Box>
            <Divider />
            <Box padding={2}>
              <Heading>Payment Options</Heading>
              {selectedPaymentDetails && (
                <PaymentManualClaimTable
                  paymentDetail={selectedPaymentDetails}
                />
              )}

              <Accordion
                elevation={0}
                sx={{
                  border: `1px solid ${grey300}`,
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                >
                  <strong>Mark as Paid</strong>
                </AccordionSummary>
                <AccordionDetails>
                  {hasManualPayments && (
                    <>
                      <Typography variant="body1" marginBottom={2}>
                        <strong>Current payments</strong>
                      </Typography>
                      <StyledTable sx={{ mb: 6 }}>
                        <TableHead>
                          <TableRow>
                            <TableCell>Amount Paid</TableCell>
                            <TableCell>Date Paid</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell>Date Submitted</TableCell>
                            <TableCell></TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {selectedPaymentDetails.manualPayments?.map(
                            manualPayment =>
                              user?.accountId && selectedPaymentDetails.id ? (
                                <PaymentManualRow
                                  key={manualPayment.id}
                                  accountId={user.accountId}
                                  paymentId={selectedPaymentDetails.id}
                                  manualPayment={manualPayment}
                                  onPaymentUpdated={() => {
                                    refetch();
                                  }}
                                  onEditPayment={mp => setEditManualPayment(mp)}
                                />
                              ) : null
                          )}
                        </TableBody>
                      </StyledTable>
                    </>
                  )}

                  <Button variant="outlined" onClick={openManualPaymentForm}>
                    {hasManualPayments ? 'Add Another Payment' : 'Add Payment'}
                  </Button>
                  {isManualPaymentFormVisible &&
                    selectedPaymentDetails.id &&
                    user?.accountId && (
                      <PaymentMarkAsPaid
                        accountId={user.accountId}
                        userId={user.id}
                        paymentId={selectedPaymentDetails.id}
                        onManualPaymentCreated={() => {
                          showAlert('Manual Payment created', 'success');
                          refetch();
                          closeManualPaymentForm();
                        }}
                        onClose={closeManualPaymentForm}
                      />
                    )}
                  {editManualPayment && user?.accountId && (
                    <PaymentEditMarkAsPaid
                      accountId={user.accountId}
                      userId={user.id}
                      manualPayment={editManualPayment}
                      onManualPaymentUpdated={() => {
                        showAlert('Manual Payment updated', 'success');
                        refetch();
                      }}
                      onClose={() => setEditManualPayment(null)}
                    />
                  )}
                </AccordionDetails>
              </Accordion>
            </Box>
          </Drawer>
          <StyledTable sx={{ mb: 2 }}>
            <SortedTableHead
              order={order}
              orderBy={orderBy}
              headCells={headCellsPaymentsDue}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {stableSort(payments, getComparator(order, orderBy)).map(
                payment => (
                  <PaymentsDueRow
                    key={payment.id}
                    payment={payment}
                    canMakeTransfer={canMakeTransfer}
                    paymentScheduleUpdated={isAutoPay => {
                      if (isAutoPay) {
                        showAlert('Auto pay setup', 'success');
                      } else {
                        showAlert('Auto pay cancelled', 'success');
                      }
                      refetch();
                    }}
                    onComplete={() => {
                      showAlert(
                        `Payment to ${payment.payeeName} was successful`,
                        'success'
                      );
                      refetch();
                    }}
                    onError={() =>
                      showAlert(
                        'There was an error making the payment. Please try again later.',
                        'error'
                      )
                    }
                    onManualPayment={() => {
                      setSelectedPayment(payment.id);
                      open();
                    }}
                  />
                )
              )}
            </TableBody>
          </StyledTable>
          <Pagination
            count={Math.ceil((data?.meta.total || 0) / rowsPerPage)}
            handleChangePage={handleChangePage}
            page={page}
            total={data?.meta?.total || 0}
          />
        </>
      )}
    </>
  );
}

export { PaymentsDueTab };
