import React, { useEffect, useMemo, useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  Select,
  SelectChangeEvent,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import { updateContractVisibility } from '../../../../../common/api/visibilitySettings';
import { VisibilityRow } from '../VisibilitySettings.style';
import {
  calculateMaxSelectedLevel,
  isChecked,
} from '../utils/visibilitySettings.utils';

import {
  row1,
  row10,
  row11,
  row12,
  row2,
  row3,
  row4,
  row5,
  row6,
  row7,
  row8,
  row9,
} from './checkboxesData';
import {
  ICheckbox,
  row3 as parentRow3,
  row7 as parentRow7,
} from '../main-visibility/checkboxesData';
import {
  ContractStatus,
  VisibilitySetting,
  VisibilityStatus,
} from '../../../../../common/api';
import MenuItem from '@mui/material/MenuItem';

interface Props {
  isDisabled: boolean;
  contractId: string;
  isEdit: boolean;
  contractStatus: string;
  hasRevision: boolean;
  visibilitySettings: VisibilitySetting[];
  parentVisibilitySettings: VisibilitySetting[];
  handleClose: () => void;
  onEditSave?: (updatedSettings: VisibilitySetting[]) => void;
}

function VisibilitySettings({
  isDisabled,
  contractId,
  isEdit,
  contractStatus,
  onEditSave,
  hasRevision,
  visibilitySettings,
  handleClose,
  parentVisibilitySettings,
}: Props) {
  const [checkboxStates, setCheckboxStates] =
    useState<VisibilitySetting[]>(visibilitySettings);
  const [initialSettings, setInitialSettings] =
    useState<VisibilitySetting[]>(checkboxStates);

  const dependencyMap: Record<
    string,
    { parent: string[]; replicated: string; locked: string }
  > = useMemo(
    () => ({
      contractListedInSubcontractorsList: {
        parent: ['sub_subcontractorOrgInfo'],
        replicated: 'sub_subcontractorOrgInfo',
        locked: '',
      },
      overallProgressCompleteOfContract: {
        parent: ['sub_overallProgress'],
        replicated: 'sub_overallProgress',
        locked: '',
      },
      subcontractorWBSClaimItemsNameOnly: {
        parent: ['sub_subcontractorClaimItemsNameOnly'],
        replicated: 'sub_subcontractorClaimItemsNameOnly',
        locked: '',
      },
      progressOfSubcontractorWBSClaimItems: {
        parent: ['sub_progressOfSubcontractorItems'],
        replicated: 'sub_progressOfSubcontractorItems',
        locked: '',
      },
      valuesOfSubcontractorWBSClaimItems: {
        parent: ['sub_subcontractorWBSValues'],
        replicated: 'sub_subcontractorWBSValues',
        locked: '',
      },
      contractorClaimsMade: {
        parent: ['parentSubcontractorClaimsMade'],
        replicated: 'claims_replicatedToSubcontractorBelow',
        locked: 'claims_lockedToSubcontractorBelow',
      },
      subSubmitted: {
        parent: ['submitted'],
        replicated: 'submitted_replicatedToSubcontractorBelow',
        locked: 'submitted_lockedToSubcontractorBelow',
      },
      subApproved: {
        parent: ['approved'],
        replicated: 'approved_replicatedToSubcontractorBelow',
        locked: 'approved_lockedToSubcontractorBelow',
      },
      subRejected: {
        parent: ['rejected'],
        replicated: 'rejected_replicatedToSubcontractorBelow',
        locked: 'rejected_lockedToSubcontractorBelow',
      },
      subWithdrawn: {
        parent: ['withdrawn'],
        replicated: 'withdrawn_replicatedToSubcontractorBelow',
        locked: 'withdrawn_lockedToSubcontractorBelow',
      },
      subAnticipated: {
        parent: ['anticipated'],
        replicated: 'anticipated_replicatedToSubcontractorBelow',
        locked: 'anticipated_lockedToSubcontractorBelow',
      },
      paymentScheduleSopaAlertSentToContractor: {
        parent: ['sopaComplianceAlert'],
        replicated: 'sopaComplianceAlert_replicatedToSubcontractorBelow',
        locked: 'sopaComplianceAlert_lockedToSubcontractorBelow',
      },
      child_subcontractorNotPaid: {
        parent: ['subcontractorNotPaid'],
        replicated: 'subcontractorNotPaid',
        locked: '',
      },
      child_contractorWBSValueRange: {
        parent: ['contractorWBSValueRange'],
        replicated: 'contractorWBSValueRange',
        locked: '',
      },
      child_contractorExactWBSValues: {
        parent: ['contractorExactWBSValues'],
        replicated: 'contractorExactWBSValues',
        locked: '',
      },
      contractorComplianceDocumentsExpire: {
        parent: ['complianceDocumentsExpire'],
        replicated: 'compliance_replicatedToSubcontractorBelow',
        locked: 'compliance_lockedToSubcontractorBelow',
      },
      subContractListedInSubcontractorsList: {
        parent: ['subsub_subcontractorOrgInfo'],
        replicated: 'subsub_subcontractorOrgInfo',
        locked: '',
      },
      subOverallProgressCompleteOfContract: {
        parent: ['subsub_overallProgress'],
        replicated: 'subsub_overallProgress',
        locked: '',
      },
      subSubcontractorWBSClaimItemsNameOnly: {
        parent: ['subsub_subcontractorClaimItemsNameOnly'],
        replicated: 'subsub_subcontractorClaimItemsNameOnly',
        locked: '',
      },
      subProgressOfSubcontractorWBSClaimItems: {
        parent: ['subsub_progressOfSubcontractorItems'],
        replicated: 'subsub_progressOfSubcontractorItems',
        locked: '',
      },
      subValuesOfSubcontractorWBSClaimItems: {
        parent: ['subsub_subcontractorWBSValues'],
        replicated: 'subsub_subcontractorWBSValues',
        locked: '',
      },
      subSubcontractorNotPaid: {
        parent: ['subsub_subcontractorNotPaid'],
        replicated: 'subsub_subcontractorNotPaid',
        locked: '',
      },
      subContractorWBSValueRange: {
        parent: ['subsub_contractorWBSValueRange'],
        replicated: 'subsub_contractorWBSValueRange',
        locked: '',
      },
      subContractorExactWBSValues: {
        parent: ['subsub_contractorExactWBSValues'],
        replicated: 'subsub_contractorExactWBSValues',
        locked: '',
      },
    }),
    []
  );

  const childDependencyMap: Record<
    string,
    { parent: string[]; replicated: string; locked: string }
  > = useMemo(
    () => ({
      subContractorClaimsMade: {
        parent: ['contractorClaimsMade, subcontractorClaimsMade'],
        replicated: 'claims_replicatedToSubcontractorBelow',
        locked: '',
      },
      subSubSubmitted: {
        parent: ['subSubmitted, submitted'],
        replicated: 'submitted_replicatedToSubcontractorBelow',
        locked: '',
      },
      subSubApproved: {
        parent: ['subApproved, approved'],
        replicated: 'approved_replicatedToSubcontractorBelow',
        locked: '',
      },
      subSubRejected: {
        parent: ['subRejected, rejected'],
        replicated: 'rejected_replicatedToSubcontractorBelow',
        locked: '',
      },
      subSubWithdrawn: {
        parent: ['subWithdrawn, withdrawn'],
        replicated: 'withdrawn_replicatedToSubcontractorBelow',
        locked: 'withdrawn_lockedToSubcontractorBelow',
      },
      subSubAnticipated: {
        parent: ['subAnticipated', 'anticipated'],
        replicated: 'anticipated_replicatedToSubcontractorBelow',
        locked: 'anticipated_lockedToSubcontractorBelow',
      },
      subPaymentScheduleSopaAlertSentToContractor: {
        parent: ['paymentScheduleSopaAlertSentToContractor'],
        replicated: '',
        locked: '',
      },
      subcontractorComplianceDocumentsExpire: {
        parent: ['contractorComplianceDocumentsExpire'],
        replicated: '',
        locked: '',
      },
    }),
    []
  );

  const handleSave = async () => {
    handleClose();
    if (isEdit && onEditSave) {
      const updatedCheckboxStates = checkboxStates.map(item => {
        const correspondingItem = initialSettings.find(
          vItem => vItem.id === item.id
        );

        if (correspondingItem && item.isOn !== correspondingItem.isOn) {
          return { ...item, status: VisibilityStatus.Modified };
        }

        return { ...item, status: VisibilityStatus.Unchanged };
      });

      setCheckboxStates([...updatedCheckboxStates]);
      onEditSave(updatedCheckboxStates);
      return;
    }

    await updateContractVisibility(contractId, checkboxStates);
  };

  const handleReset = () => {
    const initialSettings = visibilitySettings.map(setting => ({ ...setting }));
    setCheckboxStates(initialSettings);
  };

  const handleCheckboxClick = (name: string | undefined) => {
    setCheckboxStates(prevState => {
      return prevState.map(setting => {
        if (setting.name === name) {
          const newIsOn = !setting.isOn;
          return { ...setting, isOn: newIsOn };
        }
        return setting;
      });
    });
  };

  const isCheckboxDisabled = (checkboxName: string) => {
    if (isDisabled) {
      return true;
    }

    const dependency = dependencyMap[checkboxName];

    if (!dependency) {
      return false;
    }

    const parentCheckboxes = dependency.parent.map(parentName =>
      parentVisibilitySettings.find(setting => setting.name === parentName)
    );

    const isParentEnabled = parentCheckboxes.some(
      parentCheckbox => parentCheckbox?.isOn
    );

    if (!isParentEnabled) {
      return true;
    }

    return !!parentVisibilitySettings.find(
      setting => setting.name === dependency.locked
    )?.isOn;
  };

  const isChildCheckboxDisabled = (checkboxName: string) => {
    if (isDisabled) {
      return true;
    }

    const dependency = childDependencyMap[checkboxName];

    if (dependency) {
      const parentCheckboxes = dependency.parent.map(parentName =>
        checkboxStates.find(setting => setting.name === parentName)
      );

      const isParentEnabled = parentCheckboxes.some(
        parentCheckbox => parentCheckbox?.isOn
      );
      const isLocked = checkboxStates.find(
        setting => setting.name === dependency.locked
      )?.isOn;

      if (isParentEnabled) {
        return !!isLocked;
      }

      return true;
    }

    return false;
  };

  const isSelectDisabled = (
    checkboxName: string,
    dataRange: Array<ICheckbox>,
    parentDataRange: Array<ICheckbox>
  ) => {
    if (isDisabled) {
      return true;
    }

    const newMaxSelectedLevel = calculateMaxSelectedLevel(
      parentDataRange,
      parentVisibilitySettings
    );

    const isLockedLabel = parentDataRange.find(
      checkbox => checkbox.levelLabel === 'Lock'
    )?.name;

    if (isLockedLabel) {
      const isLocked = parentVisibilitySettings.find(
        checkbox => checkbox.name === isLockedLabel
      )?.isOn;
      if (isLocked) {
        return true;
      }
    }

    const checkbox = dataRange.find(checkbox => checkbox.name === checkboxName);

    if (checkbox && checkbox.level) {
      return checkbox.level > newMaxSelectedLevel;
    }

    return false;
  };

  const isChildSelectDisabled = (
    checkboxName: string,
    dataRange: Array<ICheckbox>,
    parentDataRange: Array<ICheckbox>
  ) => {
    if (isDisabled) {
      return true;
    }

    const newMaxSelectedLevel = calculateMaxSelectedLevel(
      parentDataRange,
      checkboxStates
    );

    const checkbox = dataRange.find(checkbox => checkbox.name === checkboxName);

    if (checkbox && checkbox.level) {
      return checkbox.level > newMaxSelectedLevel;
    }

    return false;
  };

  const childRows = useMemo(
    () => [...row7, ...row8, ...row9, ...row10, ...row11, ...row12],
    []
  );

  const [hasInitialized, setHasInitialized] = useState(false);
  const [checkboxStatesUpdated, setCheckboxStatesUpdated] = useState(false);
  const [childCheckboxStatesUpdated, setChildCheckboxStatesUpdated] =
    useState(false);

  useEffect(() => {
    setCheckboxStates(prevState => {
      let newState = [...prevState];
      let hasChanges = false;

      newState.forEach((checkbox, index) => {
        const dependency = dependencyMap[checkbox.name];

        if (dependency) {
          const parentCheckboxes = dependency.parent.map(parentName =>
            parentVisibilitySettings.find(
              setting => setting.name === parentName
            )
          );

          const isParentEnabled = parentCheckboxes.some(
            parentCheckbox => parentCheckbox?.isOn
          );
          const isReplicated = parentVisibilitySettings.find(
            setting => setting.name === dependency.replicated
          )?.isOn;

          if (isParentEnabled && isReplicated && !hasRevision) {
            if (!newState[index].isOn) {
              newState[index] = {
                ...newState[index],
                isOn: true,
              };
              hasChanges = true;
            }
          }
        }
      });

      setCheckboxStatesUpdated(true);
      return hasChanges ? newState : prevState;
    });
  }, [dependencyMap, parentVisibilitySettings, contractStatus, hasRevision]);

  useEffect(() => {
    setCheckboxStates(prevState => {
      let newState = [...prevState];
      let hasChanges = false;

      childRows.forEach(childRow => {
        const dependency = childDependencyMap[childRow.name];

        if (dependency) {
          const parentCheckboxes = dependency.parent.map(parentName =>
            prevState.find(setting => setting.name === parentName)
          );

          const isParentEnabled = parentCheckboxes.some(
            parentCheckbox => parentCheckbox?.isOn
          );
          const isReplicated = prevState.find(
            setting => setting.name === dependency.replicated
          )?.isOn;

          const childCheckboxIndex = newState.findIndex(
            setting => setting.name === childRow.name
          );

          if (childCheckboxIndex !== -1 && !hasRevision) {
            if (isParentEnabled && isReplicated) {
              if (!newState[childCheckboxIndex].isOn) {
                newState[childCheckboxIndex] = {
                  ...newState[childCheckboxIndex],
                  isOn: true,
                };
                hasChanges = true;
              }
            } else {
              if (newState[childCheckboxIndex].isOn) {
                newState[childCheckboxIndex] = {
                  ...newState[childCheckboxIndex],
                  isOn: false,
                };
                hasChanges = true;
              }
            }
          }
        }
      });

      setChildCheckboxStatesUpdated(true);

      return hasChanges ? newState : prevState;
    });
  }, [childRows, childDependencyMap, contractStatus, hasRevision]);

  useEffect(() => {
    if (
      checkboxStatesUpdated &&
      childCheckboxStatesUpdated &&
      !hasInitialized
    ) {
      setInitialSettings(checkboxStates);
      setHasInitialized(true);
    }
  }, [
    checkboxStates,
    checkboxStatesUpdated,
    childCheckboxStatesUpdated,
    hasInitialized,
  ]);

  const getSelectElem = (row: ICheckbox[]): string => {
    const selectedCheckbox = checkboxStates.find(setting =>
      row.some(checkbox => checkbox.name === setting.name && setting.isOn)
    );

    return selectedCheckbox ? selectedCheckbox.name : '';
  };

  const handleSelectChange = (
    event: SelectChangeEvent<string>,
    dataRange: Array<ICheckbox>
  ) => {
    const selectedValue = event.target.value;

    setCheckboxStates(prevState => {
      return prevState.map(setting => {
        const isInRow = dataRange.some(
          checkbox => checkbox.name === setting.name
        );

        if (isInRow) {
          if (setting.name === selectedValue) {
            return {
              ...setting,
              isOn: true,
            };
          }
          return {
            ...setting,
            isOn: false,
          };
        }

        return setting;
      });
    });
  };

  const isCheckboxModified = (checkboxName?: string) => {
    if (contractStatus !== ContractStatus.AwaitingAcceptance) {
      return false;
    }
    return (
      checkboxStates.find(c => c.name === checkboxName)?.status ===
      VisibilityStatus.Modified
    );
  };

  const isSelectModified = (row: ICheckbox[]) => {
    if (contractStatus !== ContractStatus.AwaitingAcceptance) {
      return false;
    }

    const result = checkboxStates.filter(c => {
      return row.some(r => r.name === c.name);
    });

    return result.some(s => s.status === VisibilityStatus.Modified);
  };

  return (
    <Box sx={{ width: '100%', p: 3 }}>
      <Typography fontSize={26} fontWeight={500}>
        Current Contract
      </Typography>

      {/*ROW 1*/}
      <Divider sx={{ mb: 2 }} />
      <Typography marginBottom={'4px'}>
        This contract information visibility
      </Typography>
      <FormControl fullWidth sx={{ mb: 2 }}>
        <Select
          value={getSelectElem(row1)}
          onChange={event => handleSelectChange(event, row1)}
          displayEmpty
          variant="outlined"
          sx={{
            borderRadius: '20px',
            border: isSelectModified(row1)
              ? '1px solid red'
              : '1px solid #E0E0E0',
            maxWidth: 600,
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderColor: isSelectModified(row1) ? 'red' : '#388E3C',
              borderWidth: '1px',
            },
            '&:hover .MuiOutlinedInput-notchedOutline': {
              borderColor: isSelectModified(row1) ? 'red' : '#388E3C',
            },
            '& .MuiSelect-icon': {
              color: '#6b7280',
              marginRight: '5px',
            },
          }}
          MenuProps={{
            PaperProps: {
              style: {
                borderRadius: '8px',
                boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
                marginTop: '5px',
              },
            },
          }}
        >
          {row1.map(checkbox => (
            <MenuItem
              key={checkbox.id}
              value={checkbox.name}
              disabled={isSelectDisabled(checkbox.name, row1, parentRow3)}
            >
              {checkbox.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {/*ROW 2*/}
      <Divider sx={{ mb: 2 }} />
      <VisibilityRow>
        <TableBody>
          {row2.map(checkbox => (
            <TableRow
              key={checkbox.id}
              sx={{
                border: isCheckboxModified(checkbox.name)
                  ? '1px solid red'
                  : '',
              }}
            >
              <TableCell sx={{ width: '40px' }}>
                <Checkbox
                  checked={isChecked(checkbox.name, checkboxStates)}
                  disabled={isCheckboxDisabled(checkbox.name)}
                  onChange={() => handleCheckboxClick(checkbox.name)}
                />
              </TableCell>
              <TableCell>{checkbox.label}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </VisibilityRow>

      {/*ROW 3*/}
      <Divider sx={{ mb: 2, mt: 2 }} />
      <Typography marginBottom={'4px'}>
        Contractor Variation Made Information
      </Typography>
      <VisibilityRow>
        <TableBody>
          {row3.map(checkbox => (
            <TableRow
              key={checkbox.id}
              sx={{
                border: isCheckboxModified(checkbox.name)
                  ? '1px solid red'
                  : '',
              }}
            >
              <TableCell sx={{ width: '40px' }}>
                <Checkbox
                  checked={isChecked(checkbox.name, checkboxStates)}
                  disabled={isCheckboxDisabled(checkbox.name)}
                  onChange={() => handleCheckboxClick(checkbox.name)}
                />
              </TableCell>
              <TableCell>{checkbox.label}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </VisibilityRow>

      {/*ROW 4*/}
      <Divider sx={{ mb: 2 }} />
      <VisibilityRow>
        <TableBody>
          {row4.map(checkbox => (
            <TableRow
              key={checkbox.id}
              sx={{
                border: isCheckboxModified(checkbox.name)
                  ? '1px solid red'
                  : '',
              }}
            >
              <TableCell sx={{ width: '40px' }}>
                <Checkbox
                  checked={isChecked(checkbox.name, checkboxStates)}
                  disabled={isCheckboxDisabled(checkbox.name)}
                  onChange={() => handleCheckboxClick(checkbox.name)}
                />
              </TableCell>
              <TableCell>{checkbox.label}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </VisibilityRow>

      {/*ROW 5*/}
      <Divider sx={{ mb: 2, mt: 2 }} />
      <Typography marginBottom={'4px'}>
        Payment Defaults to Contractor -Alert info
      </Typography>
      <FormControl fullWidth sx={{ mb: 2 }}>
        <Select
          value={getSelectElem(row5)}
          onChange={event => handleSelectChange(event, row5)}
          displayEmpty
          variant="outlined"
          sx={{
            borderRadius: '20px',
            border: isSelectModified(row5)
              ? '1px solid red'
              : '1px solid #E0E0E0',
            maxWidth: 600,
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderColor: isSelectModified(row5) ? 'red' : '#388E3C',
              borderWidth: '1px',
            },
            '&:hover .MuiOutlinedInput-notchedOutline': {
              borderColor: isSelectModified(row5) ? 'red' : '#388E3C',
            },
            '& .MuiSelect-icon': {
              color: '#6b7280',
              marginRight: '5px',
            },
          }}
          MenuProps={{
            PaperProps: {
              style: {
                borderRadius: '8px',
                boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
                marginTop: '5px',
              },
            },
          }}
        >
          {row5.map(checkbox => (
            <MenuItem
              key={checkbox.id}
              value={checkbox.name}
              disabled={isSelectDisabled(checkbox.name, row5, parentRow7)}
            >
              {checkbox.label}
              {checkbox.level}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {/*ROW 6*/}
      <Divider sx={{ mb: 2 }} />
      <VisibilityRow>
        <TableBody>
          {row6.map(checkbox => (
            <TableRow
              key={checkbox.id}
              sx={{
                border: isCheckboxModified(checkbox.name)
                  ? '1px solid red'
                  : '',
              }}
            >
              <TableCell sx={{ width: '40px' }}>
                <Checkbox
                  checked={isChecked(checkbox.name, checkboxStates)}
                  disabled={isCheckboxDisabled(checkbox.name)}
                  onChange={() => handleCheckboxClick(checkbox.name)}
                />
              </TableCell>
              <TableCell>{checkbox.label}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </VisibilityRow>

      {/*///////////////////////////////////////////////////////////////////////////////*/}

      {/*ROW 7*/}
      <Divider sx={{ mb: 2, mt: 2 }} />
      <Typography marginBottom={'4px'}>
        Subcontract Information Visibility Prescribed
      </Typography>
      <FormControl fullWidth sx={{ mb: 2 }}>
        <Select
          value={getSelectElem(row7)}
          onChange={event => handleSelectChange(event, row7)}
          displayEmpty
          variant="outlined"
          sx={{
            borderRadius: '20px',
            border: isSelectModified(row7)
              ? '1px solid red'
              : '1px solid #E0E0E0',
            maxWidth: 600,
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderColor: isSelectModified(row7) ? 'red' : '#388E3C',
              borderWidth: '1px',
            },
            '&:hover .MuiOutlinedInput-notchedOutline': {
              borderColor: isSelectModified(row7) ? 'red' : '#388E3C',
            },
            '& .MuiSelect-icon': {
              color: '#6b7280',
              marginRight: '5px',
            },
          }}
          MenuProps={{
            PaperProps: {
              style: {
                borderRadius: '8px',
                boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
                marginTop: '5px',
              },
            },
          }}
        >
          {row7.map(checkbox => (
            <MenuItem
              key={checkbox.id}
              value={checkbox.name}
              disabled={isChildSelectDisabled(checkbox.name, row7, row1)}
            >
              {checkbox.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {/*ROW 8*/}
      <Divider sx={{ mb: 2 }} />
      <VisibilityRow>
        <TableBody>
          {row8.map(checkbox => (
            <TableRow
              key={checkbox.id}
              sx={{
                border: isCheckboxModified(checkbox.name)
                  ? '1px solid red'
                  : '',
              }}
            >
              <TableCell sx={{ width: '40px' }}>
                <Checkbox
                  checked={isChecked(checkbox.name, checkboxStates)}
                  disabled={isChildCheckboxDisabled(checkbox.name)}
                  onChange={() => handleCheckboxClick(checkbox.name)}
                />
              </TableCell>
              <TableCell>{checkbox.label}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </VisibilityRow>

      {/*ROW 9*/}
      <Divider sx={{ mb: 2, mt: 2 }} />
      <Typography marginBottom={'4px'}>
        Subcontractor Variation Made Information
      </Typography>
      <VisibilityRow>
        <TableBody>
          {row9.map(checkbox => (
            <TableRow
              key={checkbox.id}
              sx={{
                border: isCheckboxModified(checkbox.name)
                  ? '1px solid red'
                  : '',
              }}
            >
              <TableCell sx={{ width: '40px' }}>
                <Checkbox
                  checked={isChecked(checkbox.name, checkboxStates)}
                  disabled={isChildCheckboxDisabled(checkbox.name)}
                  onChange={() => handleCheckboxClick(checkbox.name)}
                />
              </TableCell>
              <TableCell>{checkbox.label}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </VisibilityRow>

      {/*ROW 10*/}
      <Divider sx={{ mb: 2 }} />
      <VisibilityRow>
        <TableBody>
          {row10.map(checkbox => (
            <TableRow
              key={checkbox.id}
              sx={{
                border: isCheckboxModified(checkbox.name)
                  ? '1px solid red'
                  : '',
              }}
            >
              <TableCell sx={{ width: '40px' }}>
                <Checkbox
                  checked={isChecked(checkbox.name, checkboxStates)}
                  disabled={isChildCheckboxDisabled(checkbox.name)}
                  onChange={() => handleCheckboxClick(checkbox.name)}
                />
              </TableCell>
              <TableCell>{checkbox.label}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </VisibilityRow>

      {/*ROW 11*/}
      <Divider sx={{ mb: 2, mt: 2 }} />
      <Typography marginBottom={'4px'}>
        Payment Defaults to Contractor -Alert info
      </Typography>
      <FormControl fullWidth sx={{ mb: 2 }}>
        <Select
          value={getSelectElem(row11)}
          onChange={event => handleSelectChange(event, row11)}
          displayEmpty
          variant="outlined"
          sx={{
            borderRadius: '20px',
            border: isSelectModified(row11)
              ? '1px solid red'
              : '1px solid #E0E0E0',
            maxWidth: 600,
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderColor: isSelectModified(row11) ? 'red' : '#388E3C',
              borderWidth: '1px',
            },
            '&:hover .MuiOutlinedInput-notchedOutline': {
              borderColor: isSelectModified(row11) ? 'red' : '#388E3C',
            },
            '& .MuiSelect-icon': {
              color: '#6b7280',
              marginRight: '5px',
            },
          }}
          MenuProps={{
            PaperProps: {
              style: {
                borderRadius: '8px',
                boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
                marginTop: '5px',
              },
            },
          }}
        >
          {row11.map(checkbox => (
            <MenuItem
              key={checkbox.id}
              value={checkbox.name}
              disabled={isChildSelectDisabled(checkbox.name, row11, row5)}
            >
              {checkbox.label}
              {checkbox.level}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {/*ROW 12*/}
      <Divider sx={{ mb: 2 }} />
      <VisibilityRow>
        <TableBody>
          {row12.map(checkbox => (
            <TableRow
              key={checkbox.id}
              sx={{
                border: isCheckboxModified(checkbox.name)
                  ? '1px solid red'
                  : '',
              }}
            >
              <TableCell sx={{ width: '40px' }}>
                <Checkbox
                  checked={isChecked(checkbox.name, checkboxStates)}
                  disabled={isChildCheckboxDisabled(checkbox.name)}
                  onChange={() => handleCheckboxClick(checkbox.name)}
                />
              </TableCell>
              <TableCell>{checkbox.label}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </VisibilityRow>

      <Divider sx={{ mb: 2, mt: 2 }} />
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Button
          onClick={handleSave}
          sx={{
            m: 2,
            borderColor: 'success.main',
            color: 'success.main',
            '&:hover': {
              borderColor: 'success.dark',
              color: 'success.dark',
            },
          }}
          variant="outlined"
        >
          Save
        </Button>
        <Button
          onClick={handleReset}
          sx={{
            m: 2,
            borderColor: 'error.main',
            color: 'error.main',
            '&:hover': {
              borderColor: 'error.dark',
              color: 'error.dark',
            },
          }}
          variant="outlined"
        >
          Reset
        </Button>
      </Box>
    </Box>
  );
}

export default VisibilitySettings;
