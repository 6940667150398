import React, { useCallback, useState } from 'react';

import {
  Button,
  Dialog,
  DialogContent,
  TextField,
  Typography,
} from '@mui/material';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useMutation, useQuery } from '@tanstack/react-query';
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from '../../common/hooks/useSnackbar';
import { Heading, InputActions } from '../../styles';
import {
  createVariationFile,
  createVariationMessage,
  getVariationOrNextId,
  updateVariation,
} from '../../common/api/variations';
import { FileUploadDropzone } from '../../components/file-upload/FileUploadDropzone';
import { ContractFile, VariationItem, VariationStatus } from '../../common/api';
import { LocalVariationFile } from './VariationFileDrawer';
import { getVariationDetails } from './variationsPage.utils';

const NAME_FIELD = 'comment';

interface FormData {
  [NAME_FIELD]: string;
}

interface Props {
  variationId: string;
  contractId: string;
  close: () => void;
  addFile?: (file: LocalVariationFile) => void;
  refetch: () => void;
}

function VariationRequestInfo({
  variationId,
  contractId,
  close,
  refetch,
}: Props) {
  const { showAlert, SnackbarComponent } = useSnackbar();
  const [files, setFiles] = useState<Array<File>>([]);
  const [variationItems, setVariationItems] = useState<VariationItem[]>([]);
  const [variationName, setVariationName] = useState<string>('');

  // eslint-disable-next-line
  const { data: variation, isLoading: isLoadingVariation } = useQuery(
    ['getVariation', variationId],
    () => getVariationOrNextId({ contractId, variationId }),
    {
      onSuccess: variation => {
        const details = getVariationDetails(variation);
        setVariationName(details.name);
        if ('variationItems' in variation) {
          setVariationItems(variation.variationItems);
        }
      },
      onError: () => {
        showAlert('Error fetching variation data.', 'error');
      },
    }
  );

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm<FormData>();

  const handleCloseDialog = useCallback(() => {
    close();
    reset();
  }, [close, reset]);

  const { mutate: mutateCreateMessage, isLoading: isLoadingSend } = useMutation(
    ({ variationId, message }: { variationId: string; message: string }) =>
      createVariationMessage(variationId, message),
    {
      onSuccess: res => {
        showAlert('Your message has been sent successfully.', 'success');
        refetch();
      },
      onError: () => {
        showAlert(
          'There was an error sending your message. Please try again.',
          'error'
        );
      },
    }
  );

  // eslint-disable-next-line
  const { mutate: mutateCreateFile, isLoading: isLoadingMutateCreateFile } =
    useMutation(
      (payload: {
        file: File | ContractFile;
        path: string;
        contentType: string;
      }) =>
        createVariationFile(variationId!, {
          fileName: payload.file.name,
          id: payload.path,
          contentType: payload.contentType,
        }),
      {
        onSuccess: res => {
          showAlert('Congratulations', 'success');
        },
      }
    );

  const onSubmit = useCallback<SubmitHandler<FormData>>(
    (data: FormData) => {
      if ((!data[NAME_FIELD] && files.length === 0) || !variationName) {
        showAlert('Please provide a comment and/or upload a file.', 'error');
        return;
      }

      if (!variationId) {
        return;
      }

      mutateCreateMessage({
        variationId: variationId,
        message: data[NAME_FIELD],
      });

      if (files.length > 0) {
        const file = files[0];
        mutateCreateFile({
          file: file,
          path: file.name,
          contentType: file.type,
        });
      }

      updateVariation(contractId!, variationId!, {
        name: variationName,
        variationItems,
        status: VariationStatus.FurtherInfoRequested,
      });

      close();
    },
    [
      contractId,
      mutateCreateMessage,
      variationId,
      files,
      variationName,
      mutateCreateFile,
      variationItems,
      close,
      showAlert,
    ]
  );

  return (
    <>
      <SnackbarComponent />
      <Dialog open onClose={handleCloseDialog}>
        <DialogContent>
          <form onSubmit={handleSubmit(onSubmit)} noValidate>
            <Heading>Request Further Info</Heading>
            <Controller
              name={NAME_FIELD}
              defaultValue=""
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Comment"
                  fullWidth
                  autoFocus
                  multiline
                  error={!!errors[NAME_FIELD]}
                  helperText={errors[NAME_FIELD]?.message}
                />
              )}
            />

            <Typography sx={{ marginTop: 2, marginBottom: 2 }}>
              You can submit a comment and/or upload a file.
            </Typography>

            <FileUploadDropzone
              onDropFiles={acceptedFiles => setFiles(acceptedFiles)}
            />
            {files.length > 0 && (
              <Typography marginTop={2}>{files[0].name}</Typography>
            )}

            <InputActions>
              <Button variant="outlined" onClick={handleCloseDialog}>
                Cancel
              </Button>
              <LoadingButton
                variant="contained"
                type="submit"
                loading={isLoadingSend}
              >
                Submit Request
              </LoadingButton>
            </InputActions>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
}

export { VariationRequestInfo };
