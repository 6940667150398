import { ReportItemStyle } from '../../common/api/report-types';
import basic from './images/basic.jpg';
import customTextBox from './images/custom-text-box.png';
import donut from './images/donut.jpg';
import headline from './images/headline.jpg';
import logo from './images/logo.jpg';
import map from './images/map.jpg';
import picture from './images/picture.jpg';
import pie from './images/pie.jpg';
import projectList from './images/project-list.jpg';

export function getReportTileStyles(
  projectId: string | undefined,
  isMainDashboard: boolean
) {
  return [
    {
      label: 'Basic Metric Tile',
      description: 'Call out a single number such as Budget in big text',
      style: ReportItemStyle.Basic,
      image: basic,
    },
    {
      label: 'Headline Text Box',
      description: 'Add the name of your project or dashboard',
      style: ReportItemStyle.HeadlineTextBox,
      image: headline,
    },
    {
      label: 'Logo Tile',
      description: 'Add your project’s client logo',
      style: ReportItemStyle.Logo,
      image: logo,
    },
    {
      label: 'Custom Text Box',
      description: 'A custom text box',
      style: ReportItemStyle.CustomTextBox,
      image: customTextBox,
    },
    {
      label: 'Donut Tile',
      description: 'A chart showing statistics',
      style: ReportItemStyle.Donut,
      image: donut,
    },
    {
      label: 'Pie Chart',
      description: 'A pie chart showing statistics',
      style: ReportItemStyle.Pie,
      image: pie,
    },
    !isMainDashboard
      ? {
          label: 'Project / Contract List',
          description: 'A list of your projects or contracts.',
          style: projectId
            ? ReportItemStyle.ContractList
            : ReportItemStyle.ProjectList,
          image: projectList,
        }
      : {},
    {
      label: 'Map Tile',
      description: 'A map showing your Project’s location',
      style: ReportItemStyle.Map,
      image: map,
    },
    {
      label: 'Picture Tile',
      description: 'Upload images of your project',
      style: ReportItemStyle.Picture,
      image: picture,
    },
  ].filter(f => f.style);
}

type TableCell<T> = T & { value: number | string };
type Table<T> = TableCell<T>[][];

export function sortReportRows<T>(
  table: Table<T>,
  columnIndex: number,
  property: keyof TableCell<T>,
  order: 'asc' | 'desc' = 'asc'
): Table<T> {
  // Helper function to determine if a value is a number
  function isNumber(value: any): value is number {
    return typeof value === 'number';
  }

  // Sort the rows based on the specified column and property
  return table.sort((a, b) => {
    const valueA = a[columnIndex][property];
    const valueB = b[columnIndex][property];

    // Compare numerical values
    if (isNumber(valueA) && isNumber(valueB)) {
      return order === 'asc' ? valueA - valueB : valueB - valueA;
    }

    // Compare string values
    if (typeof valueA === 'string' && typeof valueB === 'string') {
      return order === 'asc'
        ? valueA.localeCompare(valueB)
        : valueB.localeCompare(valueA);
    }

    // Handle mixed types (numbers and strings)
    if (isNumber(valueA) && typeof valueB === 'string') {
      return order === 'asc' ? -1 : 1;
    }
    if (typeof valueA === 'string' && isNumber(valueB)) {
      return order === 'asc' ? 1 : -1;
    }

    // Default to comparing as strings for other cases
    return order === 'asc'
      ? `${valueA}`.localeCompare(`${valueB}`)
      : `${valueB}`.localeCompare(`${valueA}`);
  });
}
