import { Chip } from '@mui/material';
import { styled } from '@mui/system';
import {
  brand100,
  brand800,
  grey100,
  grey900,
  orange100,
  orange900,
  red100,
  red900,
  yellow200,
  yellow600,
} from './colours';

type ChipProps = {
  chipSize?: 'small' | 'medium' | 'large';
};

const baseSmall = {
  borderRadius: '4px',
  height: '20px',
  fontSize: '12px',
};

const baseMedium = {
  borderRadius: '4px',
  height: '26px',
  fontSize: '14px',
};

const baseLarge = {
  borderRadius: '4px',
  height: '28px',
  fontSize: '16px',
  padding: '0 8px',
};

const getSizeStyles = ({ chipSize }: ChipProps) => {
  switch (chipSize) {
    case 'medium':
      return baseMedium;
    case 'large':
      return baseLarge;
    default:
      return baseSmall;
  }
};

export const ChipOk = styled(Chip, {
  shouldForwardProp: prop => prop !== 'chipSize',
})<ChipProps>(({ chipSize }) => ({
  ...getSizeStyles({ chipSize }),
  backgroundColor: brand100,
  color: brand800,
}));

export const ChipAlert = styled(Chip, {
  shouldForwardProp: prop => prop !== 'chipSize',
})<ChipProps>(({ chipSize }) => ({
  ...getSizeStyles({ chipSize }),
  backgroundColor: orange100,
  color: orange900,
}));

export const ChipReview = styled(Chip, {
  shouldForwardProp: prop => prop !== 'chipSize',
})<ChipProps>(({ chipSize }) => {
  const sizeStyles = getSizeStyles({ chipSize });
  return {
    ...sizeStyles,
    backgroundColor: grey100,
    color: grey900,
  };
});

export const ChipDanger = styled(Chip, {
  shouldForwardProp: prop => prop !== 'chipSize',
})<ChipProps>(({ chipSize }) => ({
  ...getSizeStyles({ chipSize }),
  backgroundColor: red100,
  color: red900,
}));

export const ChipYellow = styled(Chip, {
  shouldForwardProp: prop => prop !== 'chipSize',
})<ChipProps>(({ chipSize = 'small' }) => ({
  ...getSizeStyles({ chipSize }),
  backgroundColor: yellow200,
  color: yellow600,
}));
