import {
  Box,
  Button,
  IconButton,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useMutation, useQuery } from '@tanstack/react-query';
import React, { useCallback, useMemo, useRef, useState } from 'react';

import { useAuth } from '../../../../common/auth-provider/AuthProvider';
import { Bank, DeleteBankAccount, Role } from '../../../../common/api';
import { useSnackbar } from '../../../../common/hooks/useSnackbar';
import {
  deleteBankAccount,
  getBankAccountsByAccountId,
} from '../../../../common/api/bank';
import { StyledTable } from '../../../../styles';
import { BankCreateDialog } from '../../../../pages/bank/BankCreateDialog';
import { SkeletonTable } from '../../../../components/skeleton-table';

function MyBankingTab() {
  const { user } = useAuth();
  const accountId = user?.accountId;
  const editRef = useRef<Bank | null>(null);

  const canEdit = useMemo(
    () =>
      user?.roles.find(
        r =>
          r.role === Role.OrganisationAdmin || r.role === Role.FinancialManager
      ),
    [user]
  );

  const [isCreateDialogOpen, setIsCreateDialogOpen] = useState<boolean>(false);

  const { showAlert, SnackbarComponent } = useSnackbar();

  const { data, isLoading, refetch } = useQuery(['bank', accountId], () =>
    getBankAccountsByAccountId(accountId!)
  );

  const { mutateAsync: mutateDeleteBankAccount } = useMutation(
    (payload: DeleteBankAccount) => deleteBankAccount(accountId!, payload.id),
    {
      onError: () =>
        showAlert(
          'There was a problem deleting the account information.',
          'error'
        ),
    }
  );

  const handleEdit = useCallback(
    (bankAccount: Bank) => {
      editRef.current = bankAccount;
      setIsCreateDialogOpen(true);
    },
    [setIsCreateDialogOpen]
  );

  const handleDelete = useCallback(
    async (bankAccount: Bank) => {
      await mutateDeleteBankAccount({ id: bankAccount.id });
      refetch();
    },
    [mutateDeleteBankAccount, refetch]
  );

  const handleCloseDialog = useCallback(() => {
    editRef.current = null;
    setIsCreateDialogOpen(false);
  }, [setIsCreateDialogOpen]);

  return (
    <>
      <SnackbarComponent />
      <Box sx={{ mb: '1rem' }}>
        <Button
          type="button"
          variant="contained"
          disabled={!canEdit}
          onClick={() => setIsCreateDialogOpen(true)}
        >
          Add Bank Account
        </Button>
      </Box>

      {accountId && isCreateDialogOpen && (
        <BankCreateDialog
          isOpen={isCreateDialogOpen}
          accountId={accountId}
          bank={editRef.current}
          refetch={refetch}
          handleClose={handleCloseDialog}
          onError={() =>
            showAlert(
              'There was a problem saving the account information.',
              'error'
            )
          }
        />
      )}

      {!isLoading ? (
        <StyledTable>
          <TableHead>
            <TableRow>
              <TableCell>Account Name</TableCell>
              <TableCell>Account Nickname</TableCell>
              <TableCell>BSB</TableCell>
              <TableCell>Account Number</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.map(bankAccount => (
              <TableRow key={bankAccount.id}>
                <TableCell>{bankAccount.accountName}</TableCell>
                <TableCell>{bankAccount.accountNickname}</TableCell>
                <TableCell>{bankAccount.bsb}</TableCell>
                <TableCell>{bankAccount.accountNumber}</TableCell>
                <TableCell style={{ width: '150px' }}>
                  <IconButton
                    disabled={!canEdit}
                    onClick={() => handleEdit(bankAccount)}
                  >
                    <EditIcon />
                  </IconButton>
                  <IconButton
                    disabled={!canEdit}
                    onClick={() => handleDelete(bankAccount)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </StyledTable>
      ) : (
        <SkeletonTable />
      )}
    </>
  );
}

export { MyBankingTab };
