import { axios } from './axios';
import { ManualPayment, ManualPaymentStatus } from './types';

export type UpsertManualPayment = {
  manualPaymentId?: string;
  amount: number;
  paidAt: string;
  comments: string | null;
  status?: ManualPaymentStatus | null;
  files?: Array<{
    id?: string;
    path: string;
    name: string;
    mimetype: string;
    uploaderId: string;
  }>;
};

export async function upsertManualPayment(
  accountId: string,
  paymentId: string,
  payload: UpsertManualPayment
): Promise<ManualPayment> {
  const resp = await axios.post(
    `/accounts/${accountId}/payment/${paymentId}/manual-payment`,
    payload
  );
  return resp.data;
}
