import {
  Autocomplete,
  createFilterOptions,
  FilterOptionsState,
  TextField,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import React, { useCallback } from 'react';
import {
  ControllerRenderProps,
  FieldError,
  UseFormGetValues,
  UseFormResetField,
  UseFormSetValue,
} from 'react-hook-form';
import {
  BaseContract,
  listContracts,
  ResponseListBaseType,
} from '../../../common/api';
import { Form } from './AddSubtaskDialog';
import { ContractRenderOption } from './ContractRenderOption';

interface Props {
  field: ControllerRenderProps<Form, 'contract'>;
  error?: FieldError;
  contractorInputValue: string;
  setContractorInputValue: React.Dispatch<React.SetStateAction<string>>;
  setValue: UseFormSetValue<Form>;
  getValues: UseFormGetValues<Form>;
  resetField: UseFormResetField<Form>;
  projectId: string;
}

const filter = createFilterOptions<BaseContract>();

function ContractsAutocomplete(props: Props) {
  const {
    field,
    error,
    contractorInputValue,
    setContractorInputValue,
    setValue,
    projectId,
    resetField,
  } = props;

  const { data: contractsList } = useQuery<
    ResponseListBaseType<Array<BaseContract>>
  >(['contracts'], () => {
    return listContracts({ projectId, isAutocomplete: true });
  });

  const tagsFilterOptions = useCallback(
    (
      options: Array<BaseContract>,
      params: FilterOptionsState<BaseContract>
    ) => {
      return filter(options, params);
    },
    []
  );

  const handleChangeTagsInputValue = useCallback(
    (event: React.SyntheticEvent<Element, Event>, value: string) => {
      if (
        (event?.target as Element)?.localName !== 'li' &&
        event?.type !== 'keydown'
      ) {
        setContractorInputValue(value);
      }
    },
    [setContractorInputValue]
  );

  const handleChangeAutocomplete = useCallback(
    (
      event: React.SyntheticEvent<Element, Event>,
      value: string | BaseContract | null
    ) => {
      if (!value) {
        resetField('contract', undefined);
        setContractorInputValue('');
        return;
      }

      if (typeof value === 'string') {
        return;
      }

      setValue('contract', value);
      setContractorInputValue(value.description);
    },
    [resetField, setContractorInputValue, setValue]
  );

  const getOptionLabel = useCallback((option: BaseContract | string) => {
    if (typeof option === 'string') {
      return option;
    } else {
      return option.description;
    }
  }, []);

  return (
    <>
      <Autocomplete
        {...field}
        inputValue={contractorInputValue}
        value={field.value || null}
        options={contractsList?.data || []}
        onChange={handleChangeAutocomplete}
        filterOptions={tagsFilterOptions}
        getOptionLabel={getOptionLabel}
        selectOnFocus
        handleHomeEndKeys
        filterSelectedOptions
        freeSolo
        onInputChange={handleChangeTagsInputValue}
        isOptionEqualToValue={(option, value) =>
          option.description === value.description
        }
        renderOption={(props, option) => (
          <ContractRenderOption
            liProps={props}
            option={option}
            key={option.id}
          />
        )}
        sx={{ width: '100%', mt: '10px', mb: '10px' }}
        renderInput={params => (
          <TextField
            {...params}
            required
            label="Contract"
            error={!!error && !field.value}
            helperText={!!error && !field.value ? error?.message : ''}
          />
        )}
      />
    </>
  );
}

export { ContractsAutocomplete };
