import { useQuery } from '@tanstack/react-query';
import { Claim, getClaim } from '../api';
import { useParams } from 'react-router-dom';

type Params = {
  claimId: string;
};
function useClaim() {
  const { claimId } = useParams() as Params;

  const {
    data: claim,
    refetch: refetchClaim,
    isFetching: isLoadingClaim,
  } = useQuery<Claim>(['claims', claimId], () => getClaim(claimId), {
    staleTime: 30000,
  });

  return {
    claim,
    refetchClaim,
    isLoadingClaim,
  };
}

export { useClaim };
