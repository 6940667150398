import { PageContractApproveTabs } from '../../pages/contract-page/views/contract-approve-view/ContractApproveView';
import { PageContractClaimTabs } from '../../pages/contract-page/views/contract-claim-view/ContractClaimView';
import { RententionBasis, TimePeriod } from './contract-types';

export enum Role {
  BasicUser = 'BasicUser',
  ContractsUser = 'ContractsUser',
  ProjectManager = 'ProjectManager',
  FinancialManager = 'FinancialManager',
  OrganisationAdmin = 'OrganisationAdmin',
}

export enum UserStatus {
  Active = 'Active',
  Inactive = 'Inactive',
}

export interface User {
  id: string;
  email: string;
  status: UserStatus;
  accountId: string;
  firstName?: string;
  lastName?: string;
  phone?: string;
  avatarKey?: string;
  avatarUrl?: string | null;
  roles: Array<UserRole>;
}

export interface UserRole {
  id: string;
  role: Role;
  userId: string;
}

export interface AuthResponse {
  token: string;
  refreshToken: string;
  user: User & {
    roles: Array<UserRole>;
  };
}

export interface Project {
  id: string;
  name: string;
  accountId: string;
  systemDate: string;
  account: {
    name: string;
  };
}

export interface UpdateProjectPayload {
  id: string;
  systemDate: string | null;
}

export type ProjectData = ProjectStatistics & {
  notifications: Array<ProjectNotification>;
  accountId: string;
};

export interface CreateProjectPayload {
  name: string;
  accountId: string;
  addressLine1: string;
  addressLine2?: string;
  country: string;
  postcode: string;
  locality: string;
  city: string;
  placeId?: string;
  projectReportListItemId: string;
}

export interface Account {
  id: string;
  name: string;
  email: string;
  phone?: string | null;
  representative?: string;
  abn?: string | null;
  address?: string | null;
  addressLine2: string | null;
  users: User[];
  tradingName: string | null;
  postcode: string | null;
  state: string | null;
  country: string | null;
  locality: string | null;
  logoKey: string | null;
  logoUrl: string | null;
}

export enum NodeType {
  Contract = 'Contract',
  Task = 'Task',
}

export const PERCENT = 'percent';
export const DOLLAR = 'dollar';
export type ValueType = typeof PERCENT | typeof DOLLAR;

export interface ResponseListBaseType<T> {
  data: T;
  meta: {
    availableContingency?: number;
    total: number;
    totalApproved?: number;
    totalAnticipated?: number;
    totalClaimed?: number;
    totalValue?: number;
    complete?: number;
    totalValueClaimed?: number;
    variationTotalValue?: number;
    notificationCount?: number;
    valueType?: ValueType;
    nextId?: number;
  };
}

export interface ResponseBaseType<T, M = {}> {
  data: T;
  meta?: M;
}

export interface Path {
  url: string;
  label: string;
  contractorName: string;
  state?: {
    tab: PageContractApproveTabs | PageContractClaimTabs;
  };
}

export enum ContractStatus {
  Draft = 'Draft',
  DraftInviteSent = 'DraftInviteSent',
  AwaitingAcceptance = 'AwaitingAcceptance',
  Active = 'Active',
  Closed = 'Closed',
}

export interface BaseContract {
  id: string;
  customerAccountId: string;
  customerAccount: Account;
  contractorAccountId: string;
  contractorAccount: {
    id: string;
    name: string;
    representative: string;
    phone: string;
    email: string;
  };
  description: string;
  parentContractId?: string;
  parentRevisionContractId?: string;
  projectId: string;
  project: { id: string; name: string; accountId: string };
  value: string;
  originalValue: string;
  contingencyBudget: string;
  claimed: string;
  paid: string;
  approved: string;
  valueType: ValueType;
  path?: Array<Path>;
  email?: string;
  paymentTerm?: number;
  phoneNumber?: string;
  createdAt: string;
  updatedAt: string;
  approvedAt: string;
  status: ContractStatus;
  isReviewed: boolean;
  isSubmitted: boolean;
  isClosed: boolean;
  hasRevision: boolean;
  award: string;
  level: number;
  visibilitySettings?: Array<VisibilitySetting>;
  contractRoles?: Array<ContractRoles>;
  notificationSettings?: Array<NotificationSetting>;
  defectLiabilityNum: number | null;
  defectLiabilityTerm: TimePeriod | null;
  retentionBasis: RententionBasis | null;
  retentionMax: number | null;
  retentionInitial: number | null;
  variationRetentionBasis: RententionBasis | null;
  variationRetentionMax: number | null;
  variationRetentionInitial: number | null;
  minimalApproval: number | null;
  minimalReleaseAmount: number | null;
}

export interface ContractRoles {
  accountId: string;
  role: string;
}

export interface DeleteContractPayload {
  contractId: string;
}

export interface Contract extends BaseContract {
  tags: Array<Tags>;
  notifications: Array<Notification>;
  tasks: Array<Task>;
  subContracts: number;
  variationTotalValue: number;
  variationUnapprovedTotalValue: number;
  retention?: Retention;
}

export interface Retention {
  withheldWhenComplete: number;
  withheldPreviouslyApproved: number;
  minimalApproval: number;
  releasedApproved: number;
  releasedPreviouslyApproved: number;
}

export interface SubContract extends BaseContract {
  notifications?: Array<Notification>;
  variationTotalValue: number;
  variationUnapprovedTotalValue: number;
}

export interface Task {
  id: string;
  identifier: string;
  parentTaskIdentifier?: string;
  isNew: boolean;
  description: string;
  type: string;
  unitOfMeasure: string;
  quantity: number;
  rate: number;
  value: string;
  excludeFromRetention: boolean;
  originalValue: string;
  claimed: string;
  approved: string;
  paid: string;
  taskGroupId: string;
  valueType: ValueType;
  contractId: string;
  parentTaskId?: string;
  revisionTaskId: string;
  _count?: {
    subTasks: number;
  };
  hasRevision: boolean;
  contract?: Contract;
  claimItem: Array<ClaimItem>;
  todos: Array<Todo>;
  totalUnapprovedVariation: string;
  totalApprovedVariation: string;
  variationStatus: VariationStatus;
  level: number;
  children?: any;
  ref?: string;
  contractorName?: string;
}

export interface Tags {
  tag: Tag;
}

export interface Tag {
  id: string;
  label: string;
}

export interface CreateContractPayload {
  projectId: string;
  contractListReportItemId?: string;
  description: string;
  customerAccountId: string;
  contractorAccountId: string;
  identifier: string;
  paymentTerm: number;
  claimsDueOn: string;
  forWorkCompletedTo?: string;
  jurisdiction: string;
  defectLiabilityNum: number;
  defectLiabilityTerm: string;
  retentionBasis: RententionBasis;
  retentionMax?: number;
  retentionInitial?: number;
  variationRetentionBasis: RententionBasis;
  variationRetentionMax?: number;
  variationRetentionInitial?: number;
  minimalApproval?: number;
  minimalReleaseAmount?: number;
  award: string;
  parentContractId?: string;
  visibilitySettings?: Array<VisibilitySetting>;
}

export interface UpdateContractPayload {
  contractorAccountId?: string;
  description?: string;
  paymentTerm?: number;
  contingencyBudget?: number;
  status?: ContractStatus;
  isClosed?: boolean;
  isSubmitted?: boolean;
  hasRevision?: boolean;
  isRetentionUpdate?: boolean;
  defectLiabilityNum?: number;
  defectLiabilityTerm?: string;
  retentionBasis?: RententionBasis;
  retentionMax?: number;
  retentionInitial?: number;
  variationRetentionBasis?: RententionBasis;
  variationRetentionMax?: number;
  variationRetentionInitial?: number;
  minimalApproval?: number;
  minimalReleaseAmount?: number;
}

export interface ReviewTaskPayload {
  id: string;
  identifier: string;
  description: string;
  value: string;
  action: 'create' | 'update' | 'delete';
}

export interface ReviewContractPayload {
  description?: string;
  paymentTerm?: number;
  contingencyBudget?: string;
  isSubmitted?: boolean;
  isReviewed?: boolean;
  tasks?: Array<ReviewTaskPayload>;
  status?: ContractStatus;
}

export interface CreateTaskPayload {
  identifier: string;
  name: string;
  type?: string;
  unitOfMeasure?: string;
  quantity?: number;
  rate?: number;
  value: number | null;
  excludeFromRetention?: boolean;
  tagIds: Array<string>;
  taskGroupId?: string;
  parentTaskId?: string;
  contractId: string;
}

export interface UpdateTaskPayload {
  description: string;
  identifier: string;
  value: number | null;
  parentTaskId?: string | null;
  type?: string;
  unitOfMeasure?: string;
  quantity?: number;
  rate?: number;
  excludeFromRetention?: boolean;
}

export interface TaskMoveToFolderPayload {
  contractId: string;
  taskIds: Array<string>;
  groupId: string;
}

export interface DeleteTasksPayload {
  contractId: string;
  taskIds: Array<string>;
}

export interface SummaryTaskData {
  value: number;
  valuePaid: number;
  valueClaimed: number;
  valueApproved: number;
  valueToComplete: number;
  pendingApprovals: number;
  pendingClaims: number;
}

export interface CreateAccountPayload {
  name?: string;
  email: string;
  phone: string;
  representative: string;
  isProvider: boolean;
  isContract?: boolean;
}

export interface UpdateAccountPayload {
  id: string;
  representative: string;
  representativeEmail: string;
  representativePhone: string;
  abn?: string;
  businessName?: string;
  tradingName?: string;
  logoKey?: string;
  address?: string;
  addressLine2?: string;
  postcode?: string;
  country?: string;
  state?: string;
  locality?: string;
}

export interface UpdateAccountWithInvitationPayload {
  invitationId: string;
  abn: string;
  address: string;
  name: string;
}

export enum ClaimType {
  percent = 'Percentage',
  value = 'Value',
}

export enum ClaimStatus {
  Draft = 'Draft',
  Submitted = 'Submitted',
  Rejected = 'Rejected',
  Approved = 'Approved',
  Awaiting = 'Awaiting',
  Withdrawn = 'Withdrawn',
  Paid = 'Paid',
}

export interface ClaimItem {
  id: string;
  taskId: string;
  claimType: ClaimType;
  value: number;
  adjust?: Adjust;
  task?: Task;
  isError?: boolean;
}

export interface CreateClaimItemPayload {
  taskId: string;
  claimType: ClaimType;
  value: number;
  message?: string;
}

export interface AddAdjustClaimItemPayload {
  explanation: string | null;
  adjustValue: number | null;
}

export interface Adjust {
  id: string;
  value: number;
  explanation: string;
}

export interface ClaimFile {
  id: string;
  claimId: string;
  path: string;
  name: string;
  mimetype: string;
  uploaderId: string;
  uploader: {
    email: string;
  };
  createdAt: Date;
}

export interface PreviousClaimSnapshotData {
  [taskId: string]: {
    taskName: string;
    approved: number;
    paid: number;
    taskValue: number;
  };
}

export interface Claim {
  notifications?: Array<Notification>;
  id: string;
  periodStart: string;
  periodEnd: string;
  expiration: string;
  contractId: string;
  status: ClaimStatus;
  approvedAt: string | null;
  paidAt: string | null;
  paymentDueAt: string | null;
  previousClaimSnapshot: PreviousClaimSnapshotData;
  scheduledAt: string | null;
  submittedAt: string | null;
  rejectedAt: string | null;
  deletedAt: string | null;
  withdrawnAt: string | null;
  description: string;
  totalValue: number;
  totalVariance: number;
  totalOriginalValue: number;
  messages: Array<ClaimMessage>;
  claimItems: Array<ClaimItem>;
  claimFiles: Array<ClaimFile>;
  contract: Contract;
  valueType: ValueType;
  submitWhenDue?: boolean;
}

export interface SaveCurrentClaimPayload {
  value: number;
  taskId: string;
  claimType: ClaimType;
}

export interface SaveSubmitClaimPayload {
  periodStart: string;
  periodEnd: string;
  claimItems: Array<{
    id?: string;
    taskId: string;
    claimType: ClaimType;
    value: number;
  }>;
  status: ClaimStatus;
  claimName?: string;
  rejectedReason?: string;
  submitWhenDue?: boolean;
}

export interface ClaimMessage {
  id: string;
  claimId: string;
  message: string;
  createdAt: Date;
  userId: string;
  user: {
    email: string;
  };
  status: ClaimMessageStatus;
}

export enum ClaimMessageStatus {
  Unresolved = 'Unresolved',
  Resolved = 'Resolved',
}

export interface UserWithId extends User {
  id: string;
  roles: Array<UserRole>;
}

export interface Invitation {
  id: string;
  email: string;
  createdAt: string;
  deletedAt: string | null;
  accountId: string;
  userId: string;
}

export interface AccountUserData {
  invitationId: string;
  userId: string;
  email: string;
}

export interface ContractFile {
  id: string;
  contractId: string;
  path: string;
  name: string;
  mimetype: string;
  uploaderId: string;
  uploader: User;
  createdAt: Date;
  uploadedAt?: Date;
  uploadUrl: string;
  documentName?: string;
  documentType?: string;
  documentExpiry?: string;
}

export interface SaveBankAccount {
  id?: string;
  bsb: string;
  accountNumber: string;
  accountName: string;
  accountNickname?: string;
}

export interface DeleteBankAccount {
  id: string;
}

export interface Bank {
  id: string;
  bsb: string;
  accountNumber: string;
  accountId: string;
  accountName: string;
  accountNickname?: string;
}

export type Order = 'asc' | 'desc';

export enum NotificationStatus {
  Active = 'Active',
  Inactive = 'Inactive',
}

export enum NotificationType {
  AwaitingApproval = 'AwaitingApproval',
  Contract = 'Contract',
  Payment = 'Payment',
  Variation = 'Variation',
  ComplianceFile = 'ComplianceFile',
}

export enum NotificationColor {
  Red = 'Red',
  Yellow = 'Yellow',
  Green = 'Green',
}

export type Notification = {
  id: string;
  accountId?: string;
  status: NotificationStatus;
  message: string;
  isRead: boolean;
  type: NotificationType;
  createdAt: string;
  contract: Contract;
  dueAt?: string;
  contractId?: string;
  variationId?: string;
  claimId: string;
  color?: NotificationColor;
  project?: { systemDate?: string };
};

export type ProjectStatistics = {
  id: string;
  name: string;
  value: number;
  valueClaimed: number;
  valueApproved: number;
  valuePaid: number;
  activeContracts: number;
  variationTotalValue: number;
  variationUnapprovedTotalValue: number;
};

export type ProjectNotification = Notification;

export type ProjectSummary = {
  value: string;
  valueClaimed: string;
  valuePaid: string;
  valueToComplete: string;
  valueApproved: string;
  valueApprovals: string;
  totalApprovedVariations: string;
  totalUnapprovedVariations: string;
  isProvider: boolean | undefined;
  valueAwaitingApproval: string;
};

export type ContractStatistics = {
  id: string;
  name: string;
  totalValue: number;
  valueClaimed: number;
  valueApproved: number;
  valuePaid: number;
  activeContracts: number;
};

export type ContractNotification = Notification;

export type ContractHistoryData = {
  contractHistory?: Contract;
  tasksHistory: Array<Task>;
};

export type ContractSummary = {
  complete: number;
  subcontractsCounts: number;
  totalValue: number;
  pendingClaims: number;
};

export interface SubContractSummary {
  totalSubContracts: number;
  totalSubContractors: number;
  totalValue: number;
  totalValueApproved: number;
  percentageProgress: number;
}

export type TaskGroup = {
  id: string;
  name: string;
  order: string;
  createdAt: string;
  parentId: string | null;
  projectId: string;
  tasks?: Array<Task>;
  children: Array<TaskGroup>;
};

export type ManualPaymentFile = {
  id: string;
  manualPaymentId: string;
  path: string;
  name: string;
  mimetype: string;
  uploaderId: string;
  createdAt: Date;
  uploadedAt: Date | null;
  downloadUrl?: string;
};

export enum ManualPaymentStatus {
  Submitted = 'Submitted',
  Confirmed = 'Confirmed',
  Withdrawn = 'Withdrawn',
  Rejected = 'Rejected',
}

export type ManualPayment = {
  id: string;
  amount: number;
  createdAt: Date;
  paidAt: Date | null;
  submittedAt: Date | null;
  confirmedAt: Date | null;
  comment: string | null;
  status: ManualPaymentStatus;
  paymentId: string;
  manualPaymentFiles: Array<ManualPaymentFile>;
};

export enum PaymentStatus {
  Unpaid = 'Unpaid',
  Paid = 'Paid',
}

export type PaymentData = {
  id: string;
  amount: number;
  amountOutstanding: number;
  claim: {
    id: string;
    contractId: string;
    description: string | null;
    periodStart: string | null;
    periodEnd: Date | null;
  };
  dueAt: Date;
  paidAt: Date | null;
  payWhenDue: boolean;
  projectName: string;
  approvedAt: Date | null;
  payee: {
    name: string;
    bankSetupComplete: boolean;
  };
  payer: {
    name: string;
    bankSetupComplete: boolean;
  };
  manualPayments: Array<ManualPayment>;
};

// Todos

export type Todo = {
  id: string;
  createAt: string;
  description: string;
  state: TodoState;
  taskId: string;
};

export enum TodoState {
  Complete = 'Complete',
  Incomplete = 'Incomplete',
}

export type CreateTodoPayload = {
  description: string;
  taskId: string;
};

export type UpdateTodoPayload = {
  description?: string;
  todoId: string;
  state?: TodoState;
};

export type FileUploadResponse = {
  uploadUrl: string;
  contentType: string;
  path: string;
};

export type FileDownloadResponse = {
  url: string;
};
export interface AdjustmentFile {
  id: string;
  contractId: string;
  path: string;
  name: string;
  mimetype: string;
  uploaderId: string;
  uploader: User;
  createdAt: Date;

  uploadUrl: string;
}

export enum VariationStatus {
  Approved = 'Approved',
  Submitted = 'Submitted',
  Rejected = 'Rejected',
  Withdrawn = 'Withdrawn',
  RejectAndClosed = 'RejectedAndClosed',
  WithdrawnAndClosed = 'WithdrawnAndClosed',
  Draft = 'Draft',
  FurtherInfoRequested = 'FurtherInfoRequested',
}

export type VariationItem = {
  id: string;
  taskId: string;
  identifier: string;
  description: string;
  currentTaskValue: number;
  currentTaskApproved: number;
  variance: number;
  maxVarianceValue: number;
  newTaskValue: number;
};

export interface Variation {
  id: string;
  status: VariationStatus;
  name: string;
  isClosed: boolean;
  isFinished: boolean;
  isSubmitted: boolean;
  identifier: number;
  anticipatedId: number;
  totalValue: number;
  createdAt: Date;
  rejectedAt: Date | null;
  submittedAt: Date | null;
  approvedAt: Date | null;
  withdrawnAt: Date | null;
  closedAt: Date | null;
  revision: number | null;
  contractId: string;
  contract: Contract & {
    contractorAccount: {
      name: string | null;
    };
    project: Project;
  };
  parentVariationId: string | null;
  variationItems: Array<
    VariationItem & {
      isNewTask: boolean;
    }
  >;
  variationFiles: Array<VariationFile>;
  variationMessages: Array<VariationMessage>;
  valueType?: ValueType;
  revisedVariationId?: string;
}

export interface VariationHistory {
  id: string;
  description: string;
  identifier: number;
  anticipatedId: number;
  revision: number | null;
  hasRevision: boolean;
}

export type VariationFile = {
  id: string;
  variationId: string;
  path: string;
  name: string;
  mimetype: string;
  uploaderId: string;
  uploader: {
    email: string;
  };
  createdAt: Date;
};

export type VariationListResource = {
  id: string;
  identifier: number;
  anticipatedId: number;
  isClosed: boolean;
  isFinalised: boolean;
  isSubmitted: boolean;
  contract: {
    description: string;
    identifier: string;
    contractorAccount: {
      name: string;
    };
  };
  description: string;
  createdAt: Date | null;
  closedAt: Date | null;
  submittedAt: Date | null;
  dateResponded: Date | null;
  status: VariationStatus;
  revision: number | null;
  totalValue: number;
  maxVarianceValue: number;
  valueType: ValueType;
  notifications: Array<Notification>;
};

export type VariationNextIdResource = {
  nextAvId: number;
  nextSvId: number;
};

export type VariationInfoResource = {
  total: number;
  totalApproved?: number;
  totalAnticipated?: number;
  totalClaimed?: number;
};

export type VariationContractsResource = {
  id: string;
  description: string;
};

export enum VariationMessageStatus {
  Unresolved = 'Unresolved',
  Resolved = 'Resolved',
}

export type VariationMessage = {
  id: string;
  variationId: string;
  message: string;
  createdAt: Date;
  userId: string | null;
  user: {
    email: string;
  };
  status: VariationMessageStatus;
};

export type VariationRequestType =
  | 'submitted'
  | 'anticipated'
  | 'summary'
  | 'all';

export interface CreateReportPayload {
  name: string;
  order: string;
  projectId?: string;
}

export interface UpdateReportPayload {
  id: string;
  name: string;
  order: string;
  projectId?: string;
}

export enum VisibilityStatus {
  Unchanged = 'Unchanged',
  Modified = 'Modified',
}

export interface VisibilitySetting {
  id?: string;
  name: string;
  isOn: boolean;
  status?: VisibilityStatus;
  contractId?: string;
}

export interface SiteCoordinates {
  lat: number;
  lng: number;
}

export interface SiteAddress {
  address: string;
  placeId: string;
}

export interface NotificationSetting {
  id: string;
  accountId: string;
  name: string;
  isOn: boolean;
  notificationType: NotificationType;
  minimumValue?: number;
  timeDelay?: number;
  daysBeforeDeadline?: number;
  weeksBeforeExpire?: number;
  emailNotifications: boolean;
  alertColor: NotificationColor;
  createdAt: string;
  updatedAt: string;
  contractId: string;
}

export interface UpdateNotificationSetting {
  name: string;
  isOn: boolean;
  notificationType?: NotificationType;
  minimumValue?: number;
  timeDelay?: number;
  daysBeforeDeadline?: number;
  weeksBeforeExpire?: number;
  emailNotifications: boolean;
  alertColor: NotificationColor;
}
