import { SubContract, ValueType } from '../../../../../common/api';
import { getCostToComplete } from '../../contract-claim-view/components/CurrentClaimTab.utils';

type SubContractData = {
  id: string;
  name: string;
  status: string;
  description: string;
  value: number;
  claimed: number;
  approved: number;
  paid: number;
  progress: number;
  valueType: ValueType;
  notificationsLength: number;
  variationTotalValue: number;
  variationUnapprovedTotalValue: number;
};

export function getData(contracts: Array<SubContract>): SubContractData[] {
  return contracts.map(c => {
    const costToComplete = getCostToComplete(
      c.value,
      c.paid,
      c.approved,
      c.claimed
    );
    const costToCompletePercentsValue = Math.floor(
      (costToComplete / Number(c.value) || 0) * 100
    );

    return {
      id: c.id,
      name: c.contractorAccount.name,
      status: c.status,
      description: c.description,
      value: Number(c.value || 0),
      claimed: Number(c.claimed || 0),
      approved: Number(c.approved || 0),
      paid: Number(c.paid || 0),
      progress: costToCompletePercentsValue,
      valueType: c.valueType,
      notificationsLength: c.notifications?.length || 0,
      variationTotalValue: c.variationTotalValue,
      variationUnapprovedTotalValue: c.variationUnapprovedTotalValue,
    };
  });
}
