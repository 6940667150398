import { Variation } from '../../common/api';

export type VariationRowItem = {
  id: string;
  taskId: string;
  isNewTask: boolean;
  identifier: string;
  description: string;
  currentTaskValue: number;
  currentTaskApproved: number;
  variance: number;
  maxVarianceValue: number;
  newTaskValue: number;
  deleted?: boolean;
  type?: string;
  unitOfMeasure?: string;
  quantity?: number;
  rate?: number;
  value?: string;
  excludeFromRetention?: boolean;
};

export type VariationReceivedRowItem = {
  id: string;
  taskId: string;
  isNewTask: boolean;
  identifier: string;
  description: string;
  currentTaskValue: number;
  currentTaskApproved: number;
  variance: number;
  newTaskValue: number;
  parentTaskId?: string;
};

export function getVariationDetails(
  variation: Variation | { nextSvId: number; nextAvId: number }
) {
  const [svId, avId] = getIdentifiers(variation);
  const name = 'nextSvId' in variation ? '' : variation.name;
  return {
    identifier: svId,
    anticipatedId: avId,
    name,
  };
}

export function getIdentifiers(
  variation: Variation | { nextSvId: number; nextAvId: number }
): string[] {
  if ('nextSvId' in variation) {
    return [variation.nextSvId.toString(), variation.nextAvId.toString()];
  }

  return [
    variation.identifier?.toString(),
    variation.anticipatedId?.toString(),
  ];
}

export function canSubmit(variationItems: Array<VariationRowItem>) {
  return variationItems
    .filter(variation => !variation.isNewTask)
    .every(variation => {
      return variation.variance && variation.newTaskValue;
    });
}

export function getNextIdentifier(
  variations: Array<VariationRowItem>,
  variationIdentifier: string,
  variationRevision: number | null
) {
  if (variations.length === 0) {
    return variationRevision !== null
      ? `V${variationIdentifier}rev${variationRevision}.1`
      : `V${variationIdentifier}.1`;
  }

  let maxIdentifier = 0;
  const baseIdentifier =
    variationRevision !== null
      ? `V${variationIdentifier}rev${variationRevision}`
      : `V${variationIdentifier}`;

  variations.forEach((variation: any) => {
    const regex = new RegExp(`^${baseIdentifier.replace('.', '.')}.(\\d+)$`);
    const matches = variation.identifier.match(regex);
    if (matches) {
      const minor = parseInt(matches[1], 10);
      if (minor > maxIdentifier) {
        maxIdentifier = minor;
      }
    }
  });

  const nextMinor = maxIdentifier + 1;
  return `${baseIdentifier}.${nextMinor}`;
}
