export function validateNumericalField(value: string) {
  if (/^\d+$/.test(value)) {
    return true;
  }
  return 'Please only enter numbers';
}

export function validateBSBField(value: string) {
  const result = validateNumericalField(value);
  if (result === true && value.length < 6) {
    return 'BSB must contain 6 numbers';
  }
  return result;
}
