import React, { MouseEvent, useCallback, useMemo, useState } from 'react';
import Menu from '@mui/material/Menu';
import { Box, IconButton } from '@mui/material';
import FormatColorTextIcon from '@mui/icons-material/FormatColorText';

type Props = {
  onOptionSelect: (option: string) => void;
};

function RichTextColours({ onOptionSelect }: Props) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const options = useMemo(
    () => [
      '#000000',
      '#808080',
      '#C0C0C0',
      '#FF0000',
      '#DC143C',
      '#800000',
      '#FFFF00',
      '#808000',
      '#008000',
      '#00FF00',
      '#00FFFF',
      '#008080',
      '#0000FF',
      '#000080',
      '#EE82EE',
      '#800080',
      '#FFA500',
      '#A52A2A',
      '#FF7F50',
      '#FA8072',
      '#FFC0CB',
      '#FFD700',
      '#F0E68C',
      '#E6E6FA',
    ],
    []
  );

  const handleClick = useCallback(
    (event: MouseEvent<HTMLElement>) => {
      event.preventDefault();
      event.stopPropagation();
      setAnchorEl(event.currentTarget);
    },
    [setAnchorEl]
  );

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, [setAnchorEl]);

  const handleMenuItemClick = useCallback(
    (event: MouseEvent<HTMLElement>, index: number) => {
      event.preventDefault();
      event.stopPropagation();
      onOptionSelect(options[index]);
      handleClose();
    },
    [options, handleClose, onOptionSelect]
  );

  return (
    <>
      <IconButton aria-label="more" aria-haspopup="true" onClick={handleClick}>
        <FormatColorTextIcon />
      </IconButton>

      <Menu
        id="long-menu"
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <Box width={200} gap={'2px'} marginBottom={1}>
          {options.map((option, index) => (
            <IconButton
              key={option}
              sx={{
                margin: '2px',
                backgroundColor: option,
                width: '24px',
                height: '24px',
                ':hover': {
                  backgroundColor: option,
                },
              }}
              onClick={event => handleMenuItemClick(event, index)}
            ></IconButton>
          ))}
        </Box>
        <Box marginLeft={1} display="flex" alignItems="center" gap="4px">
          <span>Custom</span>
          <input type="color" onChange={e => onOptionSelect(e.target.value)} />
        </Box>
      </Menu>
    </>
  );
}

export default RichTextColours;
