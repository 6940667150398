import React, { useMemo } from 'react';
import {
  Box,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';

import { StyledTable } from '../../../styles';
import { ManualPayment, ManualPaymentStatus } from '../../../common/api';
import { grey300, grey500 } from '../../../styles/colours';
import PaymentIncomeManualRow from './PaymentIncomeManualRow';
import { PaymentRow } from './PaymentTableHeader';
import PaymentIncomeManualPreviousRow from './PaymentIncomeManualPreviousRow';

type Props = {
  accountId: string;
  paymentDetails: PaymentRow & {
    manualPayments: Array<ManualPayment>;
  };
  onPaymentUpdated: () => void;
};

const PaymentIncomeManualPayment = ({
  accountId,
  paymentDetails,
  onPaymentUpdated,
}: Props) => {
  const manualPayments = paymentDetails.manualPayments;

  const previous = useMemo(() => {
    return manualPayments.filter(
      mp => mp.status === ManualPaymentStatus.Confirmed
    );
  }, [manualPayments]);

  const submittedManualPayments = useMemo(() => {
    return manualPayments.filter(
      mp => mp.status === ManualPaymentStatus.Submitted
    );
  }, [manualPayments]);

  return (
    <Box>
      <Box marginBottom={6}>
        <Typography variant="body1" marginBottom={2}>
          <strong>Previous Payments</strong>
        </Typography>
        {previous.length === 0 ? (
          <EmptyState
            message="This is where confirmed payments for this
              claim will appear"
          />
        ) : (
          <StyledTable sx={{ mb: 6 }}>
            <TableHead>
              <TableRow>
                <TableCell>Amount Paid</TableCell>
                <TableCell>Date Paid</TableCell>
                <TableCell>Date Confirmed</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {previous.map(payment => (
                <PaymentIncomeManualPreviousRow
                  key={payment.id}
                  manualPayment={payment}
                />
              ))}
            </TableBody>
          </StyledTable>
        )}
      </Box>

      <Box>
        <Typography variant="body1" marginBottom={2}>
          <strong>Submitted Payments Awaiting Approval</strong>
        </Typography>
        {submittedManualPayments.length === 0 ? (
          <EmptyState
            message="This is where your submitted payments awaiting approval for this
              claim will appear"
          />
        ) : (
          <StyledTable sx={{ mb: 6 }}>
            <TableHead>
              <TableRow>
                <TableCell>Amount Paid</TableCell>
                <TableCell>Date Paid</TableCell>
                <TableCell>Date Submitted</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {submittedManualPayments.map(payment => (
                <PaymentIncomeManualRow
                  accountId={accountId}
                  paymentDetails={paymentDetails}
                  manualPayment={payment}
                  onPaymentUpdated={onPaymentUpdated}
                />
              ))}
            </TableBody>
          </StyledTable>
        )}
      </Box>
    </Box>
  );
};

export default PaymentIncomeManualPayment;

const EmptyState = ({ message }: { message: string }) => {
  return (
    <Box
      display="flex"
      justifyContent="center"
      sx={{
        border: `1px solid ${grey300}`,
        borderRadius: '4px',
        color: grey500,
        padding: '20px',
      }}
    >
      <Typography
        variant="body1"
        sx={{
          maxWidth: '400px',
          textAlign: 'center',
        }}
      >
        {message}
      </Typography>
    </Box>
  );
};
