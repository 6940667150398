import React, { useCallback } from 'react';
import dayjs from 'dayjs';
import { useMutation } from '@tanstack/react-query';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import AddTaskIcon from '@mui/icons-material/AddTask';
import { LoadingButton } from '@mui/lab';

import {
  upsertManualPayment,
  UpsertManualPayment,
} from '../../../common/api/manual-payment';
import { ManualPayment, ManualPaymentStatus } from '../../../common/api';
import { KebabMenu } from '../../../components/menu/KebabMenu';
import { formatCurrency, formatDate } from '../../../common/format';
import { useDialogState } from '../../../components/dialog/dialog.hooks';
import { PaymentRow } from './PaymentTableHeader';
import { red700 } from '../../../styles/colours';
import PaymentManualFilesCommentView from './PaymentManualFilesCommentView';

type Props = {
  accountId: string;
  manualPayment: ManualPayment;
  paymentDetails: PaymentRow;
  onPaymentUpdated: () => void;
};

const PaymentIncomeManualRow = ({
  accountId,
  manualPayment,
  paymentDetails,
  onPaymentUpdated,
}: Props) => {
  const {
    isVisible: isConfirmVisible,
    close: closeConfirm,
    open: openConfirm,
  } = useDialogState();
  const {
    isVisible: isRejectVisible,
    close: closeReject,
    open: openReject,
  } = useDialogState();
  const {
    isVisible: isViewVisible,
    close: closeView,
    open: openView,
  } = useDialogState();

  const { mutateAsync: mutateManualPayment, isLoading } = useMutation(
    (payload: UpsertManualPayment) =>
      upsertManualPayment(accountId, manualPayment.paymentId, payload),
    {
      onSuccess: () => {
        closeReject();
        closeConfirm();
        onPaymentUpdated();
      },
    }
  );

  const handleConfirmPayment = useCallback(() => {
    mutateManualPayment({
      amount: manualPayment.amount,
      comments: manualPayment.comment,
      paidAt: dayjs(manualPayment.paidAt).toISOString(),
      manualPaymentId: manualPayment.id,
      status: ManualPaymentStatus.Confirmed,
    });
  }, [manualPayment, mutateManualPayment]);

  const handleRejectPayment = useCallback(() => {
    mutateManualPayment({
      amount: manualPayment.amount,
      comments: manualPayment.comment,
      paidAt: dayjs(manualPayment.paidAt).toISOString(),
      manualPaymentId: manualPayment.id,
      status: ManualPaymentStatus.Rejected,
    });
  }, [manualPayment, mutateManualPayment]);

  return (
    <TableRow>
      {isViewVisible && (
        <PaymentManualFilesCommentView
          manualPayment={manualPayment}
          onClose={closeView}
        />
      )}
      {isConfirmVisible && (
        <Dialog open fullWidth maxWidth="xs" onClose={closeConfirm}>
          <DialogContent>
            <AddTaskIcon
              color="success"
              sx={{
                fontSize: '60px',
                marginBottom: '16px',
              }}
            />
            <Typography
              variant="body1"
              sx={{
                fontSize: '20px',
                marginBottom: 6,
              }}
            >
              <strong>
                You are about to confirm that you have received{' '}
                {formatCurrency(manualPayment.amount)} on{' '}
                {formatDate(manualPayment.paidAt)} from{' '}
                {paymentDetails.payerName} with regard to{' '}
                {paymentDetails.description} for the period{' '}
                {paymentDetails.claimPeriodStart} to{' '}
                {paymentDetails.claimPeriodEnd}.
              </strong>
            </Typography>
            <Box display="flex" gap="8px" justifyContent="flex-end">
              <Button type="button" variant="outlined" onClick={closeConfirm}>
                Cancel
              </Button>
              <LoadingButton
                type="button"
                variant="contained"
                loading={isLoading}
                disabled={isLoading}
                onClick={handleConfirmPayment}
              >
                Confirm
              </LoadingButton>
            </Box>
          </DialogContent>
        </Dialog>
      )}

      {isRejectVisible && (
        <Dialog open fullWidth maxWidth="xs" onClose={closeReject}>
          <DialogContent>
            <HighlightOffIcon
              color="error"
              sx={{
                fontSize: '60px',
                marginBottom: '16px',
              }}
            />
            <Typography
              variant="body1"
              sx={{
                fontSize: '20px',
                marginBottom: 6,
              }}
            >
              <strong>
                You are about to confirm that you have not received a payment of{' '}
                {formatCurrency(manualPayment.amount)} on{' '}
                {formatDate(manualPayment.paidAt)} from{' '}
                {paymentDetails.payerName} with regard to{' '}
                {paymentDetails.description} for the period{' '}
                {paymentDetails.claimPeriodStart} to{' '}
                {paymentDetails.claimPeriodEnd}.
              </strong>
            </Typography>
            <Box display="flex" gap="8px" justifyContent="flex-end">
              <Button type="button" variant="outlined" onClick={closeReject}>
                Cancel
              </Button>
              <LoadingButton
                type="button"
                variant="contained"
                color="error"
                loading={isLoading}
                disabled={isLoading}
                onClick={handleRejectPayment}
              >
                Reject
              </LoadingButton>
            </Box>
          </DialogContent>
        </Dialog>
      )}
      <TableCell>{formatCurrency(manualPayment.amount)}</TableCell>
      <TableCell>{formatDate(manualPayment.paidAt)}</TableCell>
      <TableCell>{formatDate(manualPayment.submittedAt)}</TableCell>
      <TableCell>
        <Box display="flex" alignItems="center" gap="8px">
          <Button type="button" variant="contained" onClick={openConfirm}>
            Approve
          </Button>
          <Button
            type="button"
            variant="outlined"
            color="error"
            sx={{
              borderColor: red700,
              color: red700,
            }}
            onClick={openReject}
          >
            Reject
          </Button>
          <KebabMenu
            size="small"
            orientation="vertical"
            options={['View Comments', 'View Files']}
            onOptionSelect={() => {
              openView();
            }}
          />
        </Box>
      </TableCell>
    </TableRow>
  );
};

export default PaymentIncomeManualRow;
