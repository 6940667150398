import React, { useDragLayer, XYCoord } from 'react-dnd';

function getItemStyles(
  initialOffset: XYCoord | null,
  currentOffset: XYCoord | null
) {
  if (!initialOffset || !currentOffset) {
    return {
      display: 'none',
    };
  }

  let { x, y } = currentOffset;
  const transform = `translate(${x}px, ${y + 16}px)`;
  return {
    transform,
    WebkitTransform: transform,
  };
}

export const CustomDragLayer = () => {
  const { isDragging, item, initialOffset, currentOffset } = useDragLayer(
    monitor => ({
      item: monitor.getItem(),
      initialOffset: monitor.getInitialSourceClientOffset(),
      currentOffset: monitor.getSourceClientOffset(),
      isDragging: monitor.isDragging(),
    })
  );

  if (!isDragging || !item.el) {
    return null;
  }

  return (
    <div
      style={{
        position: 'fixed',
        pointerEvents: 'none',
        left: 0,
        top: 0,
        height: '100%',
        width: '100%',
        opacity: '1',
        zIndex: 100,
      }}
    >
      <div
        style={{
          ...getItemStyles(initialOffset, currentOffset),
          width: item?.el.offsetWidth,
          height: item?.el.offsetHeight,
          opacity: '1',
        }}
        dangerouslySetInnerHTML={{ __html: item.el.innerHTML }}
      />
    </div>
  );
};
