import React from 'react';

import { useMutation } from '@tanstack/react-query';
import {
  upsertManualPayment,
  UpsertManualPayment,
} from '../../../common/api/manual-payment';
import { ManualPayment, ManualPaymentStatus } from '../../../common/api';
import { TableCell, TableRow } from '@mui/material';
import { KebabMenu } from '../../../components/menu/KebabMenu';
import { formatCurrency, formatDate } from '../../../common/format';
import { useConfirmDialog } from '../../../common/hooks/useConfirmDialog';
import dayjs from 'dayjs';
import PaymentManualFilesCommentView from './PaymentManualFilesCommentView';
import { useDialogState } from '../../../components/dialog/dialog.hooks';

type Props = {
  accountId: string;
  paymentId: string;
  manualPayment: ManualPayment;
  onPaymentUpdated: () => void;
  onEditPayment: (mp: ManualPayment) => void;
};
const PaymentManualRow = ({
  accountId,
  paymentId,
  manualPayment,
  onPaymentUpdated,
  onEditPayment,
}: Props) => {
  const { isVisible, open, close } = useDialogState();
  const { confirmAction, ConfirmDialog } = useConfirmDialog();

  const { mutateAsync: mutateManualPayment } = useMutation(
    (payload: UpsertManualPayment) =>
      upsertManualPayment(accountId, paymentId, payload),
    {
      onSuccess: () => onPaymentUpdated(),
    }
  );

  return (
    <TableRow>
      {isVisible && (
        <PaymentManualFilesCommentView
          manualPayment={manualPayment}
          onClose={close}
        />
      )}
      <ConfirmDialog />
      <TableCell>{formatCurrency(manualPayment.amount)}</TableCell>
      <TableCell>{formatDate(manualPayment.paidAt)}</TableCell>
      <TableCell>{manualPayment.status}</TableCell>
      <TableCell>{formatDate(manualPayment.submittedAt)}</TableCell>
      <TableCell>
        <KebabMenu
          size="small"
          orientation="vertical"
          options={
            manualPayment.status !== ManualPaymentStatus.Confirmed
              ? ['Edit', 'Withdraw']
              : ['View Files', 'View Comments']
          }
          onOptionSelect={async value => {
            switch (value) {
              case 'View Files':
                open();
                break;
              case 'View Comments':
                open();
                break;
              case 'Edit':
                onEditPayment(manualPayment);
                break;
              case 'Withdraw':
                await confirmAction(
                  'Are you sure you want to withdraw this payment?'
                );
                mutateManualPayment({
                  manualPaymentId: manualPayment.id,
                  amount: manualPayment.amount,
                  comments: manualPayment.comment,
                  paidAt: dayjs(manualPayment.paidAt).toISOString(),
                  status: ManualPaymentStatus.Withdrawn,
                });
                break;
            }
          }}
        />
      </TableCell>
    </TableRow>
  );
};

export default PaymentManualRow;
