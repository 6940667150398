import React, { MouseEventHandler, useCallback, useState } from 'react';
import { Checkbox, IconButton, TableCell, Typography } from '@mui/material';

import { Task, updateTask, VariationStatus } from '../../../../common/api';
import { TaskRowData } from '../../utils/taskTable.utils';
import { StyledTableRow } from '../../../../styles';
import { formatAmountByType } from '../../../../common/format';
import { TaskClaimItemRowModifiedValue } from '../../views/contract-claim-view/components/TaskClaimItemRowModifiedValue';
import { ProgressBar } from '../../../../components/progress-bar';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { Box } from '@mui/system';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { VariationAssignParent } from '../../../variations/VariationAssignParent';
import { useDialogState } from '../../../../components/dialog/dialog.hooks';

interface Props {
  task: TaskRowData;
  tasks?: Task[];
  reloadData: () => void;
  reloadContract?: () => void;
  onClick: MouseEventHandler;
  selected: boolean;
  isCanView?: boolean;
  isSubcontract?: boolean;
  isDisabledTaskDrawer?: boolean;
  onToggle?: () => void;
  isOpen?: boolean;
  hasButtonToggle?: boolean;
  offset?: string;
  isChild?: boolean;
  isMainCustomer?: boolean;
  contractId?: string;
  parentContractId?: string;
}

function TaskRow(props: Props) {
  const {
    task,
    tasks,
    onClick,
    selected,
    isSubcontract,
    isCanView,
    isDisabledTaskDrawer,
    onToggle,
    isOpen,
    hasButtonToggle,
    offset,
    isMainCustomer,
    contractId,
    parentContractId,
    reloadData,
    reloadContract,
  } = props;

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const isOpenActionButtons = Boolean(anchorEl);
  const [taskIdToAssignTo, setTaskIdToAssignTo] = useState<string>('');

  const isNewTask = Boolean(task.isNew === 'true');
  const isApprovedVariation = task.variationStatus === VariationStatus.Approved;
  const handleRowClick = (
    event: React.MouseEvent<HTMLTableRowElement, MouseEvent>
  ) => {
    if (isDisabledTaskDrawer) {
      return;
    }

    onClick(event);
  };

  const handleAssignToTask = useCallback(
    async (currentTask: Task, parentTaskID: string | null) => {
      if (!contractId) {
        return;
      }

      await updateTask(contractId, currentTask.id, {
        description: currentTask.description,
        identifier: currentTask.identifier,
        value: Number(currentTask.value),
        parentTaskId: parentTaskID,
        type: currentTask.type,
        unitOfMeasure: currentTask.unitOfMeasure,
        quantity: currentTask.quantity,
        rate: currentTask.rate,
        excludeFromRetention: currentTask.excludeFromRetention,
      });

      if (reloadContract) {
        reloadContract();
      }
      reloadData();
    },
    [contractId, reloadContract, reloadData]
  );

  const {
    isVisible: isSearchVisible,
    close: closeSearch,
    open: openSearch,
  } = useDialogState();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  const handleAssign = (
    event: React.MouseEvent<HTMLLIElement>,
    taskId: string
  ) => {
    event.stopPropagation();
    setAnchorEl(null);

    setTaskIdToAssignTo(taskId);
    openSearch();
  };

  const handleUnassign = (
    event: React.MouseEvent<HTMLLIElement>,
    taskId: string
  ) => {
    event.stopPropagation();
    setAnchorEl(null);

    const currentTask = tasks?.find(t => t.id === taskId);
    if (currentTask) {
      handleAssignToTask(currentTask, null);
    }
  };

  return (
    <>
      <StyledTableRow
        key={task.id}
        hover={true}
        onClick={handleRowClick}
        selected={selected}
      >
        <TableCell style={{ paddingLeft: offset ? `${offset}px` : '12px' }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Box sx={{ minWidth: '50px' }}>
              {task.identifier || ''}
              {hasButtonToggle && (
                <IconButton
                  onClick={event => {
                    event.stopPropagation();
                    onToggle && onToggle();
                  }}
                >
                  {isOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </IconButton>
              )}
            </Box>
            <Box
              sx={{
                display: 'flex',
                gap: '4px',
                color: 'gray',
                fontSize: '0.8rem',
              }}
            >
              <Box component="span">{task.contractorName}</Box>
              <Box component="span">{task.ref}</Box>
            </Box>
          </Box>
        </TableCell>
        <TableCell>
          <Typography variant={'body2'}>{task.description}</Typography>
        </TableCell>
        <TableCell>
          <Typography variant={'body2'}>{task.type}</Typography>
        </TableCell>
        <TableCell>
          <Typography variant={'body2'}>{task.unitOfMeasure || '-'}</Typography>
        </TableCell>
        <TableCell>
          <Typography variant={'body2'}>{task.quantity || '-'}</Typography>
        </TableCell>
        <TableCell>
          <Typography variant={'body2'}>{task.rate || '-'}</Typography>
        </TableCell>
        <TableCell>
          {task.type === 'scheduleOfRates' ? (
            <Box>
              <Typography variant={'body2'}>
                {task.quantity} {task.unitOfMeasure}
              </Typography>
              <Typography variant={'body2'}>
                ${task.rate} per {task.unitOfMeasure}
              </Typography>
            </Box>
          ) : (
            '-'
          )}
        </TableCell>
        {!isCanView && (
          <TableCell>
            {isNewTask && !isApprovedVariation ? (
              formatAmountByType(
                Number(task.unapprovedVariation),
                task.valueType
              )
            ) : Number(task.approvedVariation) > 0 ? (
              <TaskClaimItemRowModifiedValue
                taskValue={task.value}
                taskOriginalValue={task.originalValue}
              />
            ) : (
              formatAmountByType(Number(task.value), task.valueType)
            )}
          </TableCell>
        )}
        <TableCell>
          <Typography variant={'body2'}>
            <Checkbox
              checked={(task as unknown as Task).excludeFromRetention}
              disabled={true}
            ></Checkbox>
          </Typography>
        </TableCell>
        <TableCell>
          <ProgressBar
            isNewTask={isNewTask}
            isApprovedVariation={isApprovedVariation}
            total={Number(task.value)}
            claimed={Number(task.claimed)}
            approved={Number(task.approved)}
            paid={Number(task.paid)}
            valueType={task.valueType}
            approvedVariation={Number(task.approvedVariation)}
            unApprovedVariation={Number(task.unapprovedVariation)}
          />
        </TableCell>
        {!isCanView && (
          <TableCell>
            {formatAmountByType(Number(task.claimed), task.valueType)}
          </TableCell>
        )}
        {isSubcontract && isMainCustomer && (
          <TableCell sx={{ textAlign: 'center' }}>
            <IconButton onClick={handleClick}>
              <MoreVertIcon />
            </IconButton>

            <Menu
              anchorEl={anchorEl}
              open={isOpenActionButtons}
              onClose={handleClose}
              PaperProps={{
                style: {
                  width: '150px',
                },
              }}
            >
              <MenuItem
                key="assign"
                onClick={event => handleAssign(event, task.id)}
              >
                {task.parentTaskId ? 'Re-Assign' : 'Assign'}
              </MenuItem>
              {task.parentTaskId && (
                <MenuItem
                  key="unassign"
                  onClick={event => handleUnassign(event, task.id)}
                >
                  Un-Assign
                </MenuItem>
              )}
            </Menu>
          </TableCell>
        )}
      </StyledTableRow>
      {isSearchVisible && parentContractId && (
        <VariationAssignParent
          contractId={parentContractId}
          variationItems={tasks ? tasks : []}
          variationItemIdToAssignTo={taskIdToAssignTo}
          handleClose={closeSearch}
          assignToTask={taskId => {
            const currentTask = tasks?.find(t => t.id === taskIdToAssignTo);
            if (currentTask && taskId) {
              handleAssignToTask(currentTask, taskId);
            }
          }}
        />
      )}
    </>
  );
}

export { TaskRow };
