import {
  formatDaysTillDate,
  projectDate,
} from '../../../../common/format/formatDate';
import { Notification } from '../../../../common/api';
import { PriorityType } from './types';

interface Props {
  notification: Notification & {
    priority: typeof PriorityType[keyof typeof PriorityType];
  };
  systemDate?: string;
}

function getCaption({ notification, systemDate }: Props) {
  const daysTillDate = formatDaysTillDate(
    notification.createdAt,
    systemDate
      ? projectDate(systemDate)
      : projectDate(notification?.project?.systemDate)
  );

  return daysTillDate === 0
    ? 'today'
    : daysTillDate > 0
    ? `${daysTillDate} days ago`
    : `in ${Math.abs(daysTillDate)} days`;
}

export { getCaption };
