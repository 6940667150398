import React, { useCallback } from 'react';
import { Box, Checkbox, Link, Stack, Typography } from '@mui/material';
import {
  Notification,
  NotificationColor,
  NotificationType,
} from '../../../../common/api';
import { brand700 } from '../../../../styles/colours';
import { ChipPriority } from './ChipPriority';
import { PriorityType } from './types';
import CircleIcon from '@mui/icons-material/Circle';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import ListItemButton from '@mui/material/ListItemButton';
import { generatePath, useNavigate } from 'react-router-dom';
import { StyledTooltip } from '../../../../styles';
import { isContractor, isCustomer } from '../../../../common/access';
import { useAuth } from '../../../../common/auth-provider/AuthProvider';
import {
  CLAIM,
  CONTRACT,
  VARIATION_EXISTING,
  VARIATION_RECEIVED,
} from '../../../../common/router-util/routes';
import { useProject } from '../../../../common/hooks/useProject';
import { getCaption } from './NotificationItemUtils';

interface Props {
  notification: Notification & {
    priority: typeof PriorityType[keyof typeof PriorityType];
  };
  isItemSelected: (id: string) => boolean;
  handleCheckbox: (notification: Notification) => void;
  closeDrawer: () => void;
}

function NotificationItem(props: Props) {
  const { notification, isItemSelected, handleCheckbox, closeDrawer } = props;
  const { user } = useAuth();
  const { project } = useProject();
  const navigate = useNavigate();

  const handleClickListItem = useCallback(() => {
    if (!isItemSelected(notification.id)) {
      handleCheckbox(notification);
    }

    if (notification.type === NotificationType.Payment) {
      if (notification.color === NotificationColor.Red) {
        navigate(
          generatePath(CLAIM, {
            contractId: notification.contractId,
            claimId: notification.claimId,
          })
        );
      } else {
        navigate(`financials`);
      }
    } else if (
      notification.type === NotificationType.Variation &&
      user &&
      isContractor(user, notification.contract)
    ) {
      navigate(
        generatePath(VARIATION_EXISTING, {
          contractId: notification.contractId,
          variationId: notification?.variationId,
        })
      );
    } else if (
      notification.type === NotificationType.Variation &&
      user &&
      isCustomer(user, notification.contract)
    ) {
      navigate(
        generatePath(VARIATION_RECEIVED, {
          contractId:
            notification?.contract.parentContractId || notification.contractId,
          variationId: notification?.variationId,
        })
      );
    } else if (notification.type === NotificationType.ComplianceFile) {
      navigate(
        generatePath(CONTRACT, {
          contractId: notification.contractId,
        }),
        {
          state: {
            tab: 'compliance',
          },
        }
      );
    } else if (notification.type === NotificationType.Contract) {
      navigate(
        generatePath(CONTRACT, {
          contractId: notification.contractId,
        })
      );
    } else if (user && isContractor(user, notification.contract)) {
      navigate(
        generatePath(CLAIM, {
          contractId: notification.contractId,
          claimId: notification.claimId,
        })
      );
    } else {
      navigate(
        generatePath(CLAIM, {
          contractId:
            notification?.contract.parentContractId || notification.contractId,
          claimId: notification.claimId,
        })
      );
    }

    closeDrawer();
  }, [
    closeDrawer,
    handleCheckbox,
    isItemSelected,
    navigate,
    notification,
    user,
  ]);

  const caption = getCaption({ notification, systemDate: project?.systemDate });

  return (
    <ListItemButton
      key={notification.id}
      sx={styles.ListItem}
      divider
      component={Link}
      onClick={handleClickListItem}
    >
      <Box sx={styles.Box}>
        <ChipPriority type={notification.priority} />
      </Box>
      <Stack
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Typography variant="h6">{notification.message}</Typography>
        <StyledTooltip
          title={notification.isRead ? 'Mark Unread' : 'Mark Read'}
          placement="top"
        >
          <Checkbox
            checked={isItemSelected(notification.id)}
            onClick={event => {
              event.stopPropagation();
              handleCheckbox(notification);
            }}
            icon={<CircleIcon sx={styles.Icon} />}
            checkedIcon={<RadioButtonUncheckedIcon sx={styles.Icon} />}
          />
        </StyledTooltip>
      </Stack>
      <Typography variant="caption">{caption}</Typography>
    </ListItemButton>
  );
}

const styles = {
  ListItem: {
    p: 2,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
  },
  Box: {
    width: 'max-content',
  },
  Icon: {
    color: brand700,
  },
};

export { NotificationItem };
