import React from 'react';
import Typography from '@mui/material/Typography';
import { projectDate } from '../../common/format/formatDate';
import { useProject } from '../../common/hooks/useProject';

function Copyright() {
  const { project } = useProject();

  return (
    <Typography
      component="div"
      variant="body2"
      color="text.secondary"
      align="center"
    >
      {'Copyright © '}
      <Typography display="inline">Xpede</Typography>{' '}
      {projectDate(project?.systemDate).year()}.
    </Typography>
  );
}

export { Copyright };
