import { useMutation } from '@tanstack/react-query';
import dayjs from 'dayjs';
import {
  Claim,
  ClaimStatus,
  Contract,
  saveClaim,
} from '../../../../../common/api';
import { useSnackbar } from '../../../../../common/hooks/useSnackbar';

export const useUpdateClaim = ({
  claim,
  contract,
  onSuccess,
}: {
  claim: Claim;
  contract: Contract;
  onSuccess: () => void;
}) => {
  const { showAlert } = useSnackbar();

  const { mutate, isLoading } = useMutation(
    ({
      status,
      rejectedReason,
      submitWhenDue,
    }: {
      status: ClaimStatus;
      submitWhenDue?: boolean;
      rejectedReason?: string;
    }) => {
      const periodStart = dayjs(claim.periodStart).format('YYYY-MM-DD');
      const periodEnd = dayjs(claim.periodEnd).format('YYYY-MM-DD');

      return saveClaim(contract.id, claim.id, {
        ...claim,
        status,
        rejectedReason,
        submitWhenDue,
        periodStart,
        periodEnd,
        claimItems: claim.claimItems.map(claimItem => ({
          ...claimItem,
        })),
      });
    },
    {
      onSuccess,
      onError: () => {
        showAlert('There was an error saving the claim', 'error');
      },
    }
  );

  return {
    mutate,
    isLoading,
  };
};
