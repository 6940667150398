import {
  Avatar,
  Box,
  Dialog,
  DialogContent,
  IconButton,
  InputBase,
  Paper,
  Skeleton,
  Stack,
} from '@mui/material';
import SendRoundedIcon from '@mui/icons-material/SendRounded';
import React, { ChangeEvent, useCallback, useMemo, useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import {
  Claim,
  ClaimMessageStatus,
  createClaimMessage,
  deleteClaimMessage,
  updateClaimMessage,
} from '../../common/api';
import { useHotkeys } from 'react-hotkeys-hook';
import { Message } from './Message';
import { stringAvatar } from './stringAvatar';
import CloseIcon from '@mui/icons-material/Close';
import { useAuth } from '../../common/auth-provider/AuthProvider';

interface Props {
  claim: Claim;
  permissionToSend: boolean;
  reloadData: () => void;
  onClose: () => void;
}

function ClaimChat({ claim, permissionToSend, reloadData, onClose }: Props) {
  const { user } = useAuth();

  const [messageInput, setMessageInput] = useState<string>('');

  const messages = useMemo(() => claim.messages || [], [claim.messages]);

  const { mutate: mutateCreateMessage, isLoading: isLoadingSend } = useMutation(
    ({ claimId, message }: { claimId: string; message: string }) =>
      createClaimMessage(claimId, message),
    {
      onSuccess: () => reloadData(),
    }
  );

  const { mutate: mutateDeleteMessage, isLoading: isLoadingDelete } =
    useMutation(
      (messageId: string) => deleteClaimMessage(claim.id, messageId),
      {
        onSuccess: () => reloadData(),
      }
    );

  const { mutate: mutateResolveMessage } = useMutation(
    (messageId: string) =>
      updateClaimMessage(claim.id, messageId, {
        status: ClaimMessageStatus.Resolved,
      }),
    {
      onSuccess: () => reloadData(),
    }
  );

  const handleResolveMessage = useCallback(
    (messageId: string) => {
      mutateResolveMessage(messageId);
    },
    [mutateResolveMessage]
  );

  const handleChangeMessageInput = useCallback(
    ({ target }: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setMessageInput(target.value);
    },
    []
  );

  const handleSendMessage = useCallback(() => {
    if (messageInput.length === 0) {
      return;
    }
    mutateCreateMessage({
      claimId: claim.id,
      message: messageInput,
    });
    setMessageInput('');
  }, [messageInput, claim, mutateCreateMessage]);

  const handleDeleteMessage = useCallback(
    (messageId: string) => {
      mutateDeleteMessage(messageId);
    },
    [mutateDeleteMessage]
  );

  useHotkeys(
    'ctrl+enter',
    () => handleSendMessage(),
    {
      enableOnFormTags: true,
    },
    [messageInput]
  );

  const isLoading = isLoadingSend || isLoadingDelete;

  return (
    <Dialog open={true} onClose={onClose} onClick={e => e.stopPropagation()}>
      <DialogContent style={styles.DialogContent}>
        <br />
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <Box>
          {isLoading ? (
            <>
              <Skeleton variant="circular" width={'40px'} height={'40px'} />
              <br />
              <Skeleton variant="rectangular" width={'540px'} height={'30px'} />
              <br />
              <Skeleton variant="circular" width={'40px'} height={'40px'} />
              <br />
              <Skeleton variant="rectangular" width={'540px'} height={'30px'} />
              <br />
              <Skeleton variant="circular" width={'40px'} height={'40px'} />
              <br />
              <Skeleton variant="rectangular" width={'540px'} height={'30px'} />
              <br />
            </>
          ) : messages && messages.length > 0 ? (
            <Box style={styles.MessageList} id="message-list">
              <Box>
                {user &&
                  messages.map(message => (
                    <Message
                      key={message.id}
                      currentUser={user}
                      claimMessage={message}
                      onDeleteMessage={handleDeleteMessage}
                      onResolveMessage={handleResolveMessage}
                    />
                  ))}
              </Box>
            </Box>
          ) : (
            <div>No chat messages to display</div>
          )}
          <br />
          {permissionToSend && user && (
            <Stack direction="row" spacing={2}>
              <Avatar
                {...stringAvatar(user.email)}
                style={{ marginTop: '5px' }}
              />
              <Paper style={styles.Paper}>
                <InputBase
                  multiline
                  maxRows={3}
                  style={styles.InputBase}
                  value={messageInput}
                  onChange={handleChangeMessageInput}
                  placeholder="Reply"
                />
                <IconButton onClick={handleSendMessage}>
                  <SendRoundedIcon />
                </IconButton>
              </Paper>
            </Stack>
          )}
        </Box>
      </DialogContent>
    </Dialog>
  );
}

const styles: { [key: string]: React.CSSProperties } = {
  DialogContent: {
    width: '600px',
    height: '500px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
  },
  MessageTime: {
    color: 'grey',
    marginTop: '9px',
  },
  MessageAuthor: {
    marginTop: '9px',
    fontWeight: 'bold',
  },
  Message: {
    marginTop: '10px',
    marginLeft: '56px',
  },
  MessageList: {
    maxHeight: '350px',
    overflowY: 'auto',
    display: 'flex',
    flexDirection: 'column-reverse',
  },
  Paper: {
    borderColor: 'none',
    boxShadow: 'none',
    backgroundColor: '#E7E7E7',
    paddingRight: '10px',
  },
  InputBase: {
    width: '420px',
    margin: '10px',
  },
};

export { ClaimChat, stringAvatar };
