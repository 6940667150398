import { styled } from '@mui/system';
import { brand600, grey200 } from '../../styles/colours';

type Props = {
  isDragActive: boolean;
};
export const FileUploadZone = styled('div')<Props>`
  border: 1px solid ${props => (props.isDragActive ? brand600 : grey200)};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 60px 24px;
  text-align: center;
  margin-bottom: 20px;
`;
