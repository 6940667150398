import React, { useCallback } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useMutation } from '@tanstack/react-query';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Checkbox,
  Dialog,
  DialogContent,
  FormControlLabel,
  FormGroup,
  TextField,
} from '@mui/material';

import { useSnackbar } from '../../../../common/hooks/useSnackbar';
import { useAuth } from '../../../../common/auth-provider/AuthProvider';
import {
  createInvitation,
  Role,
  UserInvitationPayload,
} from '../../../../common/api';
import { EMAIL_REGEX } from '../../../../common/validate';
import { DialogClose, Heading, InputActions } from '../../../../styles';

const FIRST_NAME = 'firstName';
const LAST_NAME = 'lastName';
const PHONE = 'phone';
const EMAIL = 'email';
const BASIC_USER = 'basicUser';
const CONTRACTS_ADMIN = 'contractsAdmin';
const PROJECT_MANAGER = 'projectManager';
const FINANCIAL_MANAGER = 'financialManager';
const ORGANISATION_ADMIN = 'organisationAdmin';

type FormData = {
  [EMAIL]: string;
  [FIRST_NAME]: string;
  [LAST_NAME]: string;
  [PHONE]: string;
  [BASIC_USER]: boolean;
  [CONTRACTS_ADMIN]: boolean;
  [PROJECT_MANAGER]: boolean;
  [FINANCIAL_MANAGER]: boolean;
  [ORGANISATION_ADMIN]: boolean;
};

type Props = {
  close: (refetch: boolean) => void;
};

function AddUserForm({ close }: Props) {
  const { user } = useAuth();
  const { showAlert, SnackbarComponent } = useSnackbar();

  const { mutate: mutateCreateInvitation, isLoading } = useMutation(
    (payload: UserInvitationPayload) => createInvitation(payload),
    {
      onSuccess: () => {
        showAlert('Invitation successfully sent!', 'success');
        close(true);
      },
      onError: ({ response }) => {
        showAlert(response.data.message, 'error');
      },
    }
  );

  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm<FormData>({
    defaultValues: {
      [BASIC_USER]: true,
      [CONTRACTS_ADMIN]: false,
      [PROJECT_MANAGER]: false,
      [FINANCIAL_MANAGER]: false,
      [ORGANISATION_ADMIN]: false,
    },
  });

  const onSubmit = useCallback(
    (form: FormData) => {
      if (user?.id) {
        const roles = [Role.BasicUser];
        if (form[CONTRACTS_ADMIN]) roles.push(Role.ContractsUser);
        if (form[PROJECT_MANAGER]) roles.push(Role.ProjectManager);
        if (form[FINANCIAL_MANAGER]) roles.push(Role.FinancialManager);
        if (form[ORGANISATION_ADMIN]) roles.push(Role.OrganisationAdmin);

        mutateCreateInvitation({
          accountId: user.accountId,
          email: form[EMAIL],
          firstName: form[FIRST_NAME],
          lastName: form[LAST_NAME],
          phone: form[PHONE],
          roles,
        });
      }
    },
    [user?.accountId, user?.id, mutateCreateInvitation]
  );

  return (
    <Dialog open fullWidth maxWidth="sm" onClose={close}>
      <DialogContent>
        <Heading>Add User</Heading>
        <DialogClose onClose={() => close(false)} />

        <SnackbarComponent />
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <Box display="flex" gap={3} mb={3}>
            <Controller
              name={FIRST_NAME}
              defaultValue=""
              control={control}
              rules={{
                required: 'First name is required',
              }}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="First Name"
                  fullWidth
                  required
                  error={!!errors[FIRST_NAME]}
                  helperText={errors[FIRST_NAME]?.message}
                />
              )}
            />
            <Controller
              name={LAST_NAME}
              defaultValue=""
              control={control}
              rules={{
                required: 'Last name is required',
              }}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Last Name"
                  fullWidth
                  required
                  error={!!errors[LAST_NAME]}
                  helperText={errors[LAST_NAME]?.message}
                />
              )}
            />
          </Box>
          <Box display="flex" gap={3} mb={3}>
            <Controller
              name={EMAIL}
              defaultValue=""
              control={control}
              rules={{
                required: 'Email is required',
                pattern: {
                  value: EMAIL_REGEX,
                  message: 'Invalid email address',
                },
              }}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Email"
                  fullWidth
                  required
                  autoComplete="off"
                  error={!!errors[EMAIL]}
                  helperText={errors[EMAIL]?.message}
                />
              )}
            />
            <Controller
              name={PHONE}
              defaultValue=""
              control={control}
              rules={{
                required: 'Mobile is required',
              }}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Mobile"
                  fullWidth
                  required
                  error={!!errors[PHONE]}
                  helperText={errors[PHONE]?.message}
                />
              )}
            />
          </Box>
          <Box>
            <FormGroup>
              <Controller
                name={BASIC_USER}
                control={control}
                render={({ field }) => (
                  <FormControlLabel
                    {...field}
                    disabled
                    control={<Checkbox checked />}
                    label="Basic User"
                  />
                )}
              />
              <Controller
                name={CONTRACTS_ADMIN}
                control={control}
                render={({ field }) => (
                  <FormControlLabel
                    {...field}
                    control={<Checkbox />}
                    label="Contracts Admin"
                  />
                )}
              />
              <Controller
                name={PROJECT_MANAGER}
                control={control}
                render={({ field }) => (
                  <FormControlLabel
                    {...field}
                    control={<Checkbox />}
                    label="Project Manager"
                  />
                )}
              />
              <Controller
                name={FINANCIAL_MANAGER}
                control={control}
                render={({ field }) => (
                  <FormControlLabel
                    {...field}
                    control={<Checkbox />}
                    label="Financial Manager"
                  />
                )}
              />
              <Controller
                name={ORGANISATION_ADMIN}
                control={control}
                render={({ field }) => (
                  <FormControlLabel
                    {...field}
                    control={<Checkbox />}
                    label="Organisation Manager"
                  />
                )}
              />
            </FormGroup>
          </Box>
          <InputActions>
            <div />
            <LoadingButton
              variant="contained"
              type="submit"
              loading={isLoading}
            >
              Save and Send
            </LoadingButton>
          </InputActions>
        </form>
      </DialogContent>
    </Dialog>
  );
}

export { AddUserForm };
