import React, { useCallback, useEffect, useState } from 'react';
import { useOutletContext, useParams } from 'react-router-dom';
import {
  Claim,
  Contract,
  getContract,
  listTasks,
  ResponseListBaseType,
  Task,
} from '../../common/api';
import { useQuery } from '@tanstack/react-query';
import { isContractor, isCustomer, isProjectOwner } from '../../common/access';
import { useAuth } from '../../common/auth-provider/AuthProvider';
import { ClaimReadView } from './views/claim-read-view/ClaimReadView';
import { ClaimApproveView } from './views/claim-approve-view/ClaimApproveView';
import { HeaderBreadcrumbs } from '../../components/header-breadcrumbs';
import { DashboardOutletContext } from '../../layouts/dashboard-layout';
import { ClaimContractorView } from './views/claim-contractor-view/ClaimContractorView';
import { useClaim } from '../../common/hooks/useClaim';
import { useProject } from '../../common/hooks/useProject';
import { CircularProgress } from '@mui/material';

type Params = {
  contractId: string;
  subcontractId?: string;
  claimId: string;
};

type NestedPath = {
  url: string;
  label: string;
  contractorName: string;
};

function ClaimPage() {
  const { contractId, subcontractId } = useParams() as Params;
  const [selectedTaskGroup, setSelectedTaskGroup] = useState<string | null>(
    null
  );

  const { user } = useAuth();

  const { setTitle } = useOutletContext<DashboardOutletContext>();

  const { claim, refetchClaim, isLoadingClaim } = useClaim();

  const { data: contract, refetch: refetchContract } = useQuery<
    Contract | undefined
  >(
    ['contract', claim?.contractId],
    () => {
      return getContract(claim?.contractId || '');
    },
    {
      enabled: !!claim?.contractId,
    }
  );

  useProject(contract?.projectId);

  const { data: mainContract } = useQuery<Contract>(
    ['contract', contractId],
    () => {
      return getContract(contractId);
    }
  );

  const { data: tasks, refetch: refetchTasks } = useQuery<
    ResponseListBaseType<Array<Task>>
  >(
    ['tasks', claim?.contractId, 'tasks', selectedTaskGroup],
    () =>
      listTasks(claim?.contractId || '', {
        taskGroupId: selectedTaskGroup === '0' ? null : selectedTaskGroup,
      }),
    {
      enabled: !!claim?.contractId,
    }
  );

  const reloadData = useCallback(() => {
    (async () => {
      await refetchClaim();
      await refetchTasks();
    })();
  }, [refetchClaim, refetchTasks]);

  const reloadContract = useCallback(() => {
    (async () => {
      await refetchContract();
    })();
  }, [refetchContract]);

  const handleSelectedTaskGroup = useCallback((nodeId: string) => {
    setSelectedTaskGroup(nodeId);
  }, []);

  const buildBreadcrumb = useCallback(
    (contract: Contract, claim: Claim) => {
      const b = [];
      b.push({
        url: `/projects`,
        label: 'Projects',
        contractorName: '',
      });

      b.push({
        url: `/projects/${contract.projectId}`,
        label: contract.project.name,
        contractorName: '',
      });

      if (subcontractId && contract && contract.path) {
        const nestedPath = contract?.path.map((p: NestedPath) => {
          return {
            url: p.url,
            label: p.label,
            contractorName: p.contractorName,
          };
        });
        return [
          ...b,
          ...nestedPath,
          {
            url: `/contracts/${contractId}/subcontract/${subcontractId}`,
            label: contract.description,
            contractorName: contract.contractorAccount.name,
          },
          {
            url: `/contracts/${contractId}/subcontract/${subcontractId}/claims/${claim.id}`,
            label: claim.description,
            contractorName: contract.contractorAccount.name,
          },
        ];
      }

      if (
        user &&
        !isProjectOwner(user, contract) &&
        isCustomer(user, contract)
      ) {
        return [
          ...b,
          {
            url: `/contracts/${contractId}`,
            label: mainContract?.description || '',
            contractorName: mainContract?.contractorAccount.name || '',
          },
          {
            url: `/contracts/${contractId}/subcontract/${claim.contractId}`,
            label: contract.description,
            contractorName: contract.contractorAccount.name,
          },
          {
            url: `/contracts/${contractId}/subcontract/${subcontractId}/claims/${claim.id}`,
            label: claim.description,
            contractorName: contract.contractorAccount.name,
          },
        ];
      }

      return [
        ...b,
        {
          url: `/contracts/${mainContract?.id}`,
          label: mainContract?.description || '',
          contractorName: mainContract?.contractorAccount.name || '',
        },
        {
          url: `/contracts/${mainContract?.id}/claims/${claim.id}`,
          label: claim?.description || '',
          contractorName: contract?.contractorAccount.name || '',
        },
      ];
    },
    [
      contractId,
      mainContract?.contractorAccount.name,
      mainContract?.description,
      mainContract?.id,
      subcontractId,
      user,
    ]
  );

  useEffect(() => {
    if (contract && user && claim) {
      setTitle(<HeaderBreadcrumbs links={buildBreadcrumb(contract, claim)} />);
    }

    return () => {
      setTitle(<></>);
    };
  }, [user, contract, setTitle, claim, buildBreadcrumb]);

  const renderView = () => {
    if (claim && claim.claimItems && user && contract) {
      if (isCustomer(user, contract)) {
        return (
          <ClaimApproveView
            claim={claim}
            isLoadingClaim={isLoadingClaim}
            tasks={tasks?.data}
            contract={contract}
            reloadData={reloadData}
            reloadContract={reloadContract}
            selectedTaskGroup={selectedTaskGroup}
            handleSelectedTaskGroup={handleSelectedTaskGroup}
          />
        );
      }

      if (isContractor(user, contract)) {
        return (
          <ClaimContractorView
            claim={claim}
            isLoadingClaim={isLoadingClaim}
            tasks={tasks?.data}
            contract={contract}
            reloadData={reloadData}
            reloadContract={reloadContract}
            selectedTaskGroup={selectedTaskGroup}
            handleSelectedTaskGroup={handleSelectedTaskGroup}
            isContractor={isContractor(user, contract)}
          />
        );
      }

      return (
        <ClaimReadView
          claim={claim}
          isLoadingClaim={isLoadingClaim}
          tasks={tasks?.data}
          contract={contract}
          reloadData={reloadData}
          reloadContract={reloadContract}
          selectedTaskGroup={selectedTaskGroup}
          handleSelectedTaskGroup={handleSelectedTaskGroup}
        />
      );
    } else {
      return <CircularProgress />;
    }
  };

  return renderView();
}

export { ClaimPage };
