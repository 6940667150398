import React, { useCallback, useMemo } from 'react';
import {
  Drawer,
  Stack,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { StyledTable } from '../../../../../styles';
import { grey900 } from '../../../../../styles/colours';
import { FilesItem } from '../../../../contract-page/components/FilesItem';
import { useMutation } from '@tanstack/react-query';
import { Adjust, AdjustmentFile } from '../../../../../common/api';
import { getAdjustFile } from '../../../../../common/api/adjustFiles';
import { ClaimItemRow } from './ClaimTable.utils';
import { formatAmountByType } from '../../../../../common/format';

interface Props {
  open: boolean;
  onClose: () => void;
  claimItem: ClaimItemRow;
  adjust?: Adjust;
  adjustmentFiles?: Array<AdjustmentFile>;
}
function ClaimAdjustmentOnlyView(props: Props) {
  const { open, onClose, claimItem, adjust, adjustmentFiles } = props;

  const { mutate: mutateGetAdjustFile } = useMutation(
    (fileId: string) => getAdjustFile(adjust?.id!, fileId),
    {
      onSuccess: data => {
        const link = document.createElement('a');
        link.href = data.url;
        link.click();
        link.remove();
      },
    }
  );

  const handleDownloadAdjustmentFile = useCallback(
    async (fileId: string) => {
      mutateGetAdjustFile(fileId);
    },
    [mutateGetAdjustFile]
  );

  const variance = useMemo(
    () => (adjust?.value ? adjust.value - claimItem.value : 0),
    [adjust?.value, claimItem.value]
  );

  return (
    <Drawer
      anchor={'right'}
      PaperProps={{
        sx: { p: 3 },
      }}
      sx={{
        '& .MuiDrawer-paper': {
          marginTop: '0px',
          height: '100%',
        },
      }}
      open={open}
      onClose={onClose}
    >
      <Typography variant="h6" sx={{ fontSize: '20px', mb: 1 }}>
        Adjustment Reason
      </Typography>
      <Typography variant="body2" sx={{ fontSize: '12px', mb: 3 }}>
        {claimItem.identifier}
      </Typography>
      <StyledTable sx={{ borderTop: 'none', mb: 3 }}>
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell>
              <Typography sx={styles.TableCell}>Claimed Value</Typography>
            </TableCell>
            <TableCell>
              <Typography sx={styles.TableCell}>Adjusted Value</Typography>
            </TableCell>
            <TableCell>
              <Typography sx={styles.TableCell}>Variance</Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>
              <Typography sx={styles.TableCell}>
                {claimItem.description}
              </Typography>
            </TableCell>
            <TableCell align="right">
              <Typography variant="body1" sx={{ fontSize: '16px' }}>
                {formatAmountByType(claimItem.value, claimItem?.valueType)}
              </Typography>
            </TableCell>
            <TableCell>
              <Typography variant="body1" sx={{ fontSize: '16px' }}>
                {formatAmountByType(adjust?.value || 0, claimItem.valueType)}
              </Typography>
            </TableCell>
            <TableCell align="right">
              <Typography variant="body1" sx={{ fontSize: '16px' }}>
                {formatAmountByType(variance, claimItem.valueType)}
              </Typography>
            </TableCell>
          </TableRow>
        </TableBody>
      </StyledTable>

      <Stack sx={{ mb: 3 }}>
        <Typography>{adjust?.explanation}</Typography>
      </Stack>

      <Stack gap={2}>
        {adjustmentFiles?.map(adjustmentFile => (
          <FilesItem
            key={adjustmentFile.id}
            file={{
              id: adjustmentFile.id,
              name: adjustmentFile.name,
              createdAt: adjustmentFile.createdAt,
              uploaderEmail: adjustmentFile.uploader.email,
            }}
            editMode={false}
            onDownloadFile={handleDownloadAdjustmentFile}
          />
        ))}
      </Stack>
    </Drawer>
  );
}

const styles = {
  TableCell: {
    fontWeight: 500,
    fontSize: '16px',
    color: grey900,
  },
};

export { ClaimAdjustmentOnlyView };
