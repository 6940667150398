export function generateGreyShades(count: number): string[] {
  const shades: string[] = [];

  for (let i = 0; i < count; i++) {
    // Generate a random number between 0 and 255
    const greyValue = Math.floor(Math.random() * 256);

    // Convert the number to a hexadecimal string, ensuring it's always 2 digits
    const hexValue = greyValue.toString(16).padStart(2, '0');

    // A shade of grey in hex format is where R, G, and B values are the same
    const greyShade = `#${hexValue}${hexValue}${hexValue}`;

    shades.push(greyShade);
  }

  return shades;
}

export function generateRandomNumbers(count: number): number[] {
  const randomNumbers: number[] = [];

  for (let i = 0; i < count; i++) {
    // Generate a random number between 1 and 10
    const randomNumber = Math.floor(Math.random() * 10) + 1;
    randomNumbers.push(randomNumber);
  }

  return randomNumbers;
}
