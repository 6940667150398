import React, { MouseEventHandler, useMemo } from 'react';
import { Box, Checkbox, Typography } from '@mui/material';
import { grey200 } from '../../../styles/colours';
import { TextSmall } from '../../../styles';
import { formatDate } from '../../../common/format';
import { KebabMenu } from '../../../components/menu/KebabMenu';

interface Props {
  file: {
    id: string;
    name: string;
    uploaderEmail: string;
    createdAt: Date;
    documentName?: string;
    documentType?: string;
    documentExpiry?: string;
  };
  editMode: boolean;
  canEditFileInfo?: boolean;
  withCheckbox?: boolean;
  isChecked?: boolean;
  onDeleteFile?: (fileId: string) => void;
  onDownloadFile?: (fileId: string) => void;
  onEditFile?: (fileId: string) => void;
  handleCheckboxClick?: MouseEventHandler<HTMLButtonElement>;
}

function FilesItem(props: Props) {
  const {
    file,
    editMode,
    canEditFileInfo,
    onDeleteFile,
    onDownloadFile,
    onEditFile,
    withCheckbox,
    isChecked,
    handleCheckboxClick,
  } = props;

  const options = useMemo(() => {
    const menu = ['Download File'];
    if (editMode) {
      menu.push('Delete File');
    }
    if (canEditFileInfo) {
      menu.push('Edit File Info');
    }
    return menu;
  }, [editMode, canEditFileInfo]);

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      sx={{ border: `1px solid ${grey200}`, padding: '16px' }}
    >
      <Box sx={{ whiteSpace: 'nowrap', overflow: 'hidden' }}>
        <Typography>{file.name}</Typography>
        <TextSmall>Date addded: {formatDate(file.createdAt)}</TextSmall>
        <TextSmall>{file.uploaderEmail}</TextSmall>
        {file.documentName && <TextSmall>Name: {file.documentName}</TextSmall>}
        {file.documentType && <TextSmall>Type: {file.documentType}</TextSmall>}
        {file.documentExpiry && (
          <TextSmall>Expiry: {formatDate(file.documentExpiry)}</TextSmall>
        )}
      </Box>
      <div>
        {withCheckbox && (
          <Checkbox checked={isChecked} onClick={handleCheckboxClick} />
        )}
        {onDownloadFile && (
          <KebabMenu
            size="large"
            orientation="vertical"
            options={options}
            onOptionSelect={value => {
              switch (value) {
                case 'Download File':
                  onDownloadFile(file.id);
                  break;
                case 'Delete File':
                  onDeleteFile?.(file.id);
                  break;
                case 'Edit File Info':
                  onEditFile?.(file.id);
                  break;
              }
            }}
          />
        )}
      </div>
    </Box>
  );
}

export { FilesItem };
