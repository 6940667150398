import { axios } from './axios';
import { FileUploadResponse } from './types';

async function getUploadPath(payload: {
  id: string;
  contentType: string;
}): Promise<FileUploadResponse> {
  const resp = await axios.post('/file-upload', {
    id: payload.id,
    mimetype: payload.contentType,
  });

  return resp.data;
}

async function uploadToS3(
  url: string,
  file: File,
  contentType: string
): Promise<FileUploadResponse> {
  const resp = await axios.put(url, file, {
    headers: {
      'Content-Type': contentType,
    },
    withCredentials: false,
  });

  return resp.data;
}

export { getUploadPath, uploadToS3 };
