import React, { useMemo } from 'react';
import { TableBody, Box } from '@mui/material';
import { Claim, Notification } from '../../../common/api';
import { ClaimHistoryRow } from './ClaimHistoryRow';
import { SortedTableHead } from '../../../components/sorted-table-head';
import {
  HeadCell,
  useSortedTable,
} from '../../../components/sorted-table-head/SortedTable.hooks';
import { SkeletonTable } from '../../../components/skeleton-table';
import { StyledTable } from '../../../styles';
import { Pagination } from '../../../components/pagination';
import { stableSort } from '../../../common/sort/stableSort';
import { getComparator } from '../../../common/sort/getComparator';
import { createClaimHistoryRows } from './ClaimHistory.utils';
import { useAuth } from '../../../common/auth-provider/AuthProvider';

interface Props {
  claims: Array<Claim> | null;
  isContractor?: boolean;
  isCustomer?: boolean;
  reloadClaims?: () => void;
  reloadContract?: () => void;
  total: number;
  page: number;
  rowsPerPage: number;
  handleChangePage: (
    event: React.ChangeEvent<unknown>,
    newPage: number
  ) => void;
  handleClick?: (id: string) => void;
  notifications?: Array<Notification>;
  handleCheckbox?: (notification: Notification) => Promise<void>;
}

function ClaimHistory(props: Props) {
  const {
    claims,
    isContractor = false,
    isCustomer = false,
    reloadClaims,
    reloadContract,
    handleChangePage,
    page,
    rowsPerPage,
    total,
    handleClick,
    notifications,
    handleCheckbox,
  } = props;

  const headCells: Array<HeadCell> = [
    {
      id: 'description',
      numeric: false,
      disablePadding: false,
      label: 'Description',
    },
    {
      id: 'statusOrder',
      numeric: false,
      disablePadding: false,
      label: 'Status',
    },
    {
      id: 'claimPeriod',
      numeric: false,
      disablePadding: false,
      label: 'Claim Period',
    },
    {
      id: 'dateSubmitted',
      numeric: true,
      disablePadding: false,
      label: 'Date Submitted',
    },
    {
      id: 'dateExpired',
      numeric: true,
      disablePadding: false,
      label: 'SOPA Expiration',
    },
    {
      id: 'dateResponded',
      numeric: true,
      disablePadding: false,
      label: 'Date Responded',
    },
    {
      id: 'totalValue',
      numeric: true,
      disablePadding: false,
      label: 'Claim Value',
    },
  ];
  const { user } = useAuth();
  const { order, orderBy, handleRequestSort } = useSortedTable();

  const rows = useMemo(
    () =>
      claims
        ? claims.map(claim =>
            createClaimHistoryRows(claim, user?.accountId, notifications)
          )
        : [],
    [claims, user?.accountId, notifications]
  );

  if (!claims) {
    return <SkeletonTable />;
  }

  return (
    <Box>
      <StyledTable sx={{ marginBottom: '30px' }}>
        <SortedTableHead
          order={order}
          orderBy={orderBy}
          onRequestSort={handleRequestSort}
          headCells={headCells}
          data={claims}
        />
        <TableBody>
          {stableSort(rows, getComparator(order, orderBy)).map(row => {
            const claim = claims.find(claim => claim.id === row.id);
            const notification = notifications?.find(
              el => el.id === row?.notificationId
            );

            if (claim) {
              return (
                <ClaimHistoryRow
                  key={row.id}
                  row={row}
                  claim={claim}
                  isCustomer={isCustomer}
                  isContractor={isContractor}
                  reloadClaims={reloadClaims}
                  reloadContract={reloadContract}
                  notification={notification}
                  handleCheckbox={() => {
                    if (notification && handleCheckbox && row.notificationId) {
                      handleCheckbox(notification);
                    }
                  }}
                  handleClick={() => {
                    if (handleClick && row.notificationId) {
                      handleClick(row.notificationId);
                    }
                  }}
                />
              );
            }

            return <></>;
          })}
        </TableBody>
      </StyledTable>
      <Pagination
        count={Math.ceil(total / rowsPerPage)}
        total={total}
        handleChangePage={handleChangePage}
        page={page}
      />
    </Box>
  );
}

export { ClaimHistory };
