import { axios } from './axios';
import { ClaimItem, CreateClaimItemPayload } from './types';

async function createClaimItem(
  claimId: string,
  payload: CreateClaimItemPayload
): Promise<Array<ClaimItem>> {
  const resp = await axios.post(`claim/${claimId}/claim-item`, payload);
  return resp.data;
}

async function removeClaimItem(claimId: string, id: string): Promise<boolean> {
  const resp = await axios.delete(`/claim/${claimId}/claim-item/${id}`);
  return resp.data;
}

export { createClaimItem, removeClaimItem };
