import React from 'react';
import { Editor } from '@tiptap/react';
import { Box, Divider, IconButton, MenuItem, Select } from '@mui/material';
import FormatBoldIcon from '@mui/icons-material/FormatBold';
import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import StrikethroughSIcon from '@mui/icons-material/StrikethroughS';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import FormatUnderlinedIcon from '@mui/icons-material/FormatUnderlined';
import UndoIcon from '@mui/icons-material/Undo';
import RedoIcon from '@mui/icons-material/Redo';
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
import FormatAlignRightIcon from '@mui/icons-material/FormatAlignRight';
import FormatAlignCenterIcon from '@mui/icons-material/FormatAlignCenter';

import RichTextColours from './RichtextColours';

const RichtextMenu = ({ editor }: { editor: Editor }) => {
  const textType = editor.isActive('paragraph')
    ? 'paragraph'
    : editor.isActive('heading', { level: 1 })
    ? 'heading1'
    : editor.isActive('heading', { level: 2 })
    ? 'heading2'
    : 'paragraph';

  return (
    <Box display="flex" flexWrap="wrap" gap="16px" mb={2}>
      <Box>
        <IconButton
          onClick={() => editor.chain().focus().undo().run()}
          disabled={!editor.can().chain().focus().undo().run()}
        >
          <UndoIcon fontSize="small" />
        </IconButton>
        <IconButton
          onClick={() => editor.chain().focus().redo().run()}
          disabled={!editor.can().chain().focus().redo().run()}
        >
          <RedoIcon fontSize="small" />
        </IconButton>
      </Box>
      <Divider orientation="vertical" flexItem />
      <Box>
        <Select
          id="text-select"
          size="small"
          sx={{ width: '100px' }}
          value={textType}
          onChange={e => {
            const value = e.target.value;
            if (value === 'paragraph') {
              editor.chain().focus().setParagraph().run();
            } else if (value === 'heading1') {
              editor.chain().focus().toggleHeading({ level: 1 }).run();
            } else if (value === 'heading2') {
              editor.chain().focus().toggleHeading({ level: 2 }).run();
            }
          }}
        >
          <MenuItem value="heading1">Title 1</MenuItem>
          <MenuItem value="heading2">Title 2</MenuItem>
          <MenuItem value="paragraph">Normal</MenuItem>
        </Select>
      </Box>
      <Divider orientation="vertical" flexItem />
      <Box>
        <IconButton
          onClick={() => editor.chain().focus().toggleBold().run()}
          disabled={!editor.can().chain().focus().toggleBold().run()}
        >
          <FormatBoldIcon
            fontSize="small"
            sx={{ color: editor.isActive('bold') ? 'black' : '' }}
          />
        </IconButton>
        <IconButton
          onClick={() => editor.chain().focus().toggleItalic().run()}
          disabled={!editor.can().chain().focus().toggleItalic().run()}
        >
          <FormatItalicIcon
            fontSize="small"
            sx={{ color: editor.isActive('italic') ? 'black' : '' }}
          />
        </IconButton>
        <IconButton
          onClick={() => editor.chain().focus().toggleUnderline().run()}
          disabled={!editor.can().chain().focus().toggleUnderline().run()}
        >
          <FormatUnderlinedIcon
            fontSize="small"
            sx={{ color: editor.isActive('underline') ? 'black' : '' }}
          />
        </IconButton>
        <IconButton
          onClick={() => editor.chain().focus().toggleStrike().run()}
          disabled={!editor.can().chain().focus().toggleStrike().run()}
        >
          <StrikethroughSIcon
            fontSize="small"
            sx={{ color: editor.isActive('strike') ? 'black' : '' }}
          />
        </IconButton>
      </Box>
      <Divider orientation="vertical" flexItem />
      <Box>
        <IconButton
          onClick={() => editor.chain().focus().setTextAlign('left').run()}
        >
          <FormatAlignLeftIcon
            fontSize="small"
            sx={{
              color: editor.isActive({ textAlign: 'left' }) ? 'black' : '',
            }}
          />
        </IconButton>
        <IconButton
          onClick={() => editor.chain().focus().setTextAlign('center').run()}
        >
          <FormatAlignCenterIcon
            fontSize="small"
            sx={{
              color: editor.isActive({ textAlign: 'center' }) ? 'black' : '',
            }}
          />
        </IconButton>
        <IconButton
          onClick={() => editor.chain().focus().setTextAlign('right').run()}
        >
          <FormatAlignRightIcon
            fontSize="small"
            sx={{
              color: editor.isActive({ textAlign: 'right' }) ? 'black' : '',
            }}
          />
        </IconButton>
      </Box>
      <Divider orientation="vertical" flexItem />
      <Box>
        <IconButton
          onClick={() => editor.chain().focus().toggleBulletList().run()}
        >
          <FormatListBulletedIcon
            fontSize="small"
            sx={{ color: editor.isActive('bulletList') ? 'black' : '' }}
          />
        </IconButton>
        <IconButton
          onClick={() => editor.chain().focus().toggleOrderedList().run()}
        >
          <FormatListNumberedIcon
            fontSize="small"
            sx={{ color: editor.isActive('orderedList') ? 'black' : '' }}
          />
        </IconButton>
      </Box>
      <Divider orientation="vertical" flexItem />
      <Box>
        <RichTextColours
          onOptionSelect={colour => {
            editor.chain().focus().setColor(colour).run();
          }}
        />
      </Box>
    </Box>
  );
};

export default RichtextMenu;
