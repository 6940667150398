import React from 'react';
import { Button, TableCell, TableRow, Typography } from '@mui/material';
import {
  formatAmountPercentInValue,
  formatCurrency,
} from '../../../../../common/format';
import { ProgressBar } from '../../../../../components/progress-bar';
import { Adjust, ClaimStatus, ClaimType } from '../../../../../common/api';
import { ClaimItemRow } from './ClaimTable.utils';
import { useDialogState } from '../../../../../components/dialog/dialog.hooks';
import { ClaimAdjust } from './ClaimAdjust';
import { grey900 } from '../../../../../styles/colours';

interface Props {
  row: ClaimItemRow;
  reloadData: () => void;
  claimId: string;
  adjust?: Adjust;
  isContractor?: boolean;
  claimStatus: ClaimStatus;
}

function ClaimTableRow(props: Props) {
  const { row, reloadData, adjust, isContractor, claimStatus } = props;

  const {
    isVisible: adjustDrawer,
    open: openAdjustDrawer,
    close: closeAdjustDrawer,
  } = useDialogState();

  if (!row.taskId) {
    return null;
  }

  return (
    <>
      <TableRow key={row.id}>
        <TableCell>{row?.identifier || ''}</TableCell>
        <TableCell>{row?.description}</TableCell>
        <TableCell>{formatCurrency(Number(row.taskValue))}</TableCell>
        <TableCell>
          {formatAmountPercentInValue(
            row.previouslyApproved,
            ClaimType.value,
            row.taskValue
          )}
        </TableCell>
        <TableCell>
          <ProgressBar
            total={row.taskValue}
            claimed={
              row.claimType === ClaimType.value
                ? Number(adjust?.value || row.value)
                : (Number(adjust?.value || row.value) / 100) * row.taskValue
            }
            approved={row.approved}
            paid={row.paid}
            valueType={row.valueType}
          />
        </TableCell>
        <TableCell>
          {adjust?.value ? (
            <>
              <Typography>
                {formatAmountPercentInValue(
                  adjust.value,
                  row.claimType,
                  row.taskValue
                )}
              </Typography>
              <Typography
                sx={{ textDecoration: 'line-through', fontSize: '12px' }}
              >
                {formatAmountPercentInValue(
                  row.value,
                  row.claimType,
                  row.taskValue
                )}
              </Typography>
            </>
          ) : (
            <Typography>
              {formatAmountPercentInValue(
                row.value,
                row.claimType,
                row.taskValue
              )}
            </Typography>
          )}
        </TableCell>
        <TableCell>
          {(adjust || !isContractor) && (
            <Button
              variant="text"
              sx={{ textDecoration: 'underline', color: grey900 }}
              onClick={openAdjustDrawer}
            >
              {isContractor || claimStatus !== ClaimStatus.Submitted
                ? 'View Adjust'
                : 'Adjust'}
            </Button>
          )}
        </TableCell>
      </TableRow>
      <ClaimAdjust
        reloadData={reloadData}
        claimItem={row}
        edit={!isContractor && claimStatus === ClaimStatus.Submitted}
        open={adjustDrawer}
        adjust={adjust}
        onClose={closeAdjustDrawer}
      />
    </>
  );
}

export { ClaimTableRow };
