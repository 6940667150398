import React, { useCallback, useState } from 'react';
import Box from '@mui/material/Box';
import { Alert, TextField } from '@mui/material';
import { Copyright } from '../../components/copyright';
import { Controller, useForm } from 'react-hook-form';
import { selfRegister } from '../../common/api';
import { useMutation } from '@tanstack/react-query';
import { LoadingButton } from '@mui/lab';
import { useAuth } from '../../common/auth-provider/AuthProvider';
import { useLocation, useNavigate } from 'react-router-dom';
import { AxiosError } from 'axios';
import { LoginLayout } from '../../components/login-layout';
import { EMAIL_REGEX } from '../../common/validate';

const EMAIL_FIELD = 'email';
const PASSWORD_FIELD = 'password';
const CONFIRM_PASSWORD_FIELD = 'confirmPassword';

interface Form {
  [EMAIL_FIELD]: string;
  [PASSWORD_FIELD]: string;
  [CONFIRM_PASSWORD_FIELD]: string;
}

function RegisterPage() {
  const {
    control,
    handleSubmit,
    watch,
    formState: { errors, isValid },
  } = useForm<Form>();

  const password = watch('password');
  const { onLogin } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [pageError, setPageError] = useState<string | undefined>();

  const from = location.state?.from?.pathname || '/';

  const registerMutation = useMutation((form: Form) => {
    setPageError(undefined);

    return selfRegister(form.email, form.password);
  });

  const onSubmit = useCallback(
    async (form: Form) => {
      if (isValid) {
        try {
          const authResponse = await registerMutation.mutateAsync(form);
          onLogin(authResponse);
          navigate(from, { replace: true });
        } catch (err) {
          let message = `An unknown error occurred`;
          if (err instanceof AxiosError) {
            const status = err.response?.status;
            if (status === 400) {
              message = 'This email already used';
            } else {
              message = `An unknown error occurred (status ${status})`;
            }
          }
          setPageError(message);
        }
      }
    },
    [from, isValid, navigate, onLogin, registerMutation]
  );

  return (
    <LoginLayout>
      <Box
        component="form"
        noValidate
        sx={{ mt: 1 }}
        onSubmit={handleSubmit(onSubmit)}
      >
        {pageError && (
          <Alert sx={{ my: 3 }} severity="error">
            {pageError}
          </Alert>
        )}
        <Controller
          name={EMAIL_FIELD}
          control={control}
          rules={{
            required: 'Email is required',
            pattern: {
              value: EMAIL_REGEX,
              message: 'Invalid email address',
            },
          }}
          render={({ field }) => (
            <TextField
              {...field}
              margin="normal"
              required
              fullWidth
              label="Email Address"
              autoComplete="email"
              autoFocus
              error={!!errors.email}
              helperText={errors.email?.message?.toString()}
            />
          )}
        />
        <Controller
          name={PASSWORD_FIELD}
          control={control}
          rules={{ required: 'Password is required' }}
          render={({ field }) => (
            <TextField
              {...field}
              margin="normal"
              required
              fullWidth
              label="Password"
              type="password"
              autoComplete="current-password"
              error={!!errors.password}
              helperText={errors.password?.message?.toString()}
            />
          )}
        />
        <Controller
          name={CONFIRM_PASSWORD_FIELD}
          control={control}
          rules={{
            validate: value => value === password || 'Passwords do not match',
          }}
          render={({ field }) => (
            <TextField
              {...field}
              margin="normal"
              required
              fullWidth
              label="Confirm Password"
              type="password"
              error={!!errors.confirmPassword}
              helperText={errors.confirmPassword?.message}
            />
          )}
        />
        <LoadingButton
          loading={registerMutation.isLoading}
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 3 }}
        >
          Sign Up
        </LoadingButton>
        <Copyright />
      </Box>
    </LoginLayout>
  );
}

export { RegisterPage };
