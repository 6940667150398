import React, { useCallback } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useMutation } from '@tanstack/react-query';
import { Button, Dialog, DialogContent, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import { useSnackbar } from '../../common/hooks/useSnackbar';
import { Heading, InputActions, InputDescription } from '../../styles';
import { updateReport } from '../../common/api/reports';
import { UpdateReportPayload } from '../../common/api';
import { Report } from '../../common/api/report-types';

const NAME_FIELD = 'name';

type FormData = {
  [NAME_FIELD]: string;
};

type Props = {
  report: Report;
  reload: () => void;
  close: () => void;
};

function EditReportDialog({ report, reload, close }: Props) {
  const { showAlert, SnackbarComponent } = useSnackbar();

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm<FormData>({
    defaultValues: {
      [NAME_FIELD]: report.name,
    },
  });

  console.log(report);
  const handleCloseDialog = useCallback(() => {
    close();
    reset();
  }, [close, reset]);

  const { mutateAsync: mutate, isLoading } = useMutation(
    (payload: UpdateReportPayload) => updateReport(payload),
    {
      onSuccess: () => {
        reload();
        handleCloseDialog();
      },
      onError: () =>
        showAlert(
          'There was an error updating the Dashboard. Please try again later.',
          'error'
        ),
    }
  );

  const onSubmit = useCallback<SubmitHandler<FormData>>(
    (data: FormData) => {
      mutate({
        id: report.id,
        name: data[NAME_FIELD],
        order: report.order,
        projectId: report.projectId,
      });
    },
    [report, mutate]
  );

  return (
    <>
      <SnackbarComponent />
      <Dialog open onClose={handleCloseDialog}>
        <DialogContent>
          <form onSubmit={handleSubmit(onSubmit)} noValidate>
            <Heading>Edit Dashboard</Heading>
            <Controller
              name={NAME_FIELD}
              defaultValue=""
              control={control}
              rules={{
                required: 'Name is required',
              }}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Name"
                  required
                  fullWidth
                  autoFocus
                  error={!!errors[NAME_FIELD]}
                  helperText={errors[NAME_FIELD]?.message}
                />
              )}
            />
            <InputDescription>
              A name that helps everyone recognise the information
            </InputDescription>
            <InputActions>
              <Button variant="outlined" onClick={handleCloseDialog}>
                Cancel
              </Button>
              <LoadingButton
                variant="contained"
                type="submit"
                loading={isLoading}
              >
                Update
              </LoadingButton>
            </InputActions>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default EditReportDialog;
