import { useMutation, useQuery } from '@tanstack/react-query';
import {
  getProject,
  Project,
  updateProject,
  UpdateProjectPayload,
} from '../api';

function useProject(id?: string) {
  const {
    data,
    isLoading,
    refetch: refetchProject,
  } = useQuery<Project>(['currentProject', id], () => getProject(id!), {
    enabled: !!id,
    refetchOnWindowFocus: false,
    retry: false,
    staleTime: 30000,
  });

  const { mutateAsync: mutateUpdateProject, isLoading: isMutateLoading } =
    useMutation((payload: UpdateProjectPayload) => updateProject(payload), {
      onSuccess: () => refetchProject(),
    });

  return {
    project: data,
    isLoading: isLoading,
    refetch: refetchProject,
    mutateUpdateAccount: mutateUpdateProject,
    isMutateLoading,
  };
}

export { useProject };
