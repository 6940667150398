import React from 'react';
import {
  Checkbox,
  FormControl,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import { VariationContractsResource } from '../../../common/api';

// Styles
import { FilterInput, SmallInputLabel } from '../../../styles/inputs';

type Props = {
  data: VariationContractsResource[];
  label: string;
  id: string;
  value: string[];
  onChange: (event: SelectChangeEvent<string[]>) => void;
};

const FilterSelect = ({ data, label, id, value, onChange }: Props) => {
  return (
    <FormControl sx={{ ml: 1, width: 300 }}>
      <SmallInputLabel id={`${id}-label`}>{label}</SmallInputLabel>
      <Select
        labelId={`${id}-label`}
        id={id}
        value={value}
        label={label}
        multiple
        input={<FilterInput label={label} />}
        renderValue={selected =>
          selected.length === 1
            ? data.find(item => item.id === selected[0])?.description ||
              'Contract selected: 1'
            : `Contracts selected: ${selected.length}`
        }
        MenuProps={{
          disableScrollLock: true,
          PaperProps: {
            style: {
              width: 250,
              maxHeight: 36 * 4.5 + 8,
            },
          },
        }}
        onChange={onChange}
      >
        {data.length ? (
          data.map(contract => (
            <MenuItem key={contract.id} value={contract.id}>
              <Checkbox checked={value.indexOf(contract.id) > -1} />
              <ListItemText primary={contract.description} />
            </MenuItem>
          ))
        ) : (
          <MenuItem value="noValue"></MenuItem>
        )}
      </Select>
    </FormControl>
  );
};

export default FilterSelect;
