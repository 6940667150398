import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQuery } from '@tanstack/react-query';
import { Box } from '@mui/material';
import _ from 'lodash';

import {
  Contract,
  ResponseListBaseType,
  SummaryTaskData,
  Task,
  reviewContract,
  ReviewContractPayload,
  ContractStatus,
  getContractHistory,
  VisibilitySetting,
  VisibilityStatus,
} from '../../../../common/api';

// Components
import {
  BaseTaskGroupProps,
  TaskGroups,
} from '../../../../components/task-group/TaskGroups';
import { Tabs, TabsItem } from '../../../../components/tabs';
import { SkeletonTable } from '../../../../components/skeleton-table';

// Types
import { ActionListType } from '../../components/ActionButtons';
import { BasePaginationProps } from '../../../../common/types';
import { ContractDetailsEdit } from '../../components/ContractDetailsEdit';

import {
  EditableTaskType,
  TasksTableEdit,
} from '../../components/taskTables/TaskTableEdit';
import { useConfirmDialog } from '../../../../common/hooks/useConfirmDialog';

export enum PageContractDraftTabsIds {
  tasks = 'tasks',
}

type EditableDataType = {
  description: string;
  paymentTerm?: number;
  contingencyBudget: string;
  visibilitySettings: VisibilitySetting[];
};

type Props = BasePaginationProps &
  BaseTaskGroupProps & {
    isCustomer?: boolean;
    contract: Contract;
    tasks?: ResponseListBaseType<Array<Task>>;
    reloadContract: () => Promise<void>;
    summary?: SummaryTaskData;
  };

const draftTabs = [
  {
    label: 'Tasks',
    id: PageContractDraftTabsIds.tasks,
  },
];

function ContractRevisionView({
  contract,
  isCustomer = false,
  summary: initialSummary,
  tasks,
  handleSelectedTaskGroup,
  selectedTaskGroup,
  reloadContract,
}: Props) {
  const navigate = useNavigate();

  const { data: historyData } = useQuery(['getHistory', contract.id], () =>
    getContractHistory(contract.id)
  );

  const initState = useMemo(
    () => ({
      description: contract.description,
      paymentTerm: contract.paymentTerm,
      contingencyBudget: contract.contingencyBudget,
      visibilitySettings: contract.visibilitySettings || [],
    }),
    [
      contract.description,
      contract.paymentTerm,
      contract.contingencyBudget,
      contract.visibilitySettings,
    ]
  );

  const [editableContractData, setEditableContractData] =
    useState<EditableDataType>(initState);

  const initialTaskData: EditableTaskType[] = useMemo(() => {
    return tasks
      ? tasks.data.map(
          ({
            id,
            identifier,
            description,
            value,
            type,
            unitOfMeasure,
            quantity,
            rate,
            excludeFromRetention,
          }: Task) => ({
            id,
            identifier,
            description,
            value,
            type,
            unitOfMeasure,
            quantity,
            rate,
            excludeFromRetention,
            action: 'update',
          })
        )
      : [];
  }, [tasks]);

  const [taskList, setTaskList] = useState<EditableTaskType[]>(initialTaskData);
  const [isEmptyTaskList, setIsEmptyTaskList] = useState<boolean>(
    !taskList.length
  );

  const { confirmAction, ConfirmDialog } = useConfirmDialog();

  const [summary, setSummary] = useState<SummaryTaskData | undefined>(
    initialSummary
  );

  const contractIsChanged = !_.isEqual(initState, editableContractData);
  const tasksIsChanged = !_.isEqual(initialTaskData, taskList);

  const { mutateAsync: mutateContract, isLoading: isContractLoading } =
    useMutation(
      (payload: ReviewContractPayload) => reviewContract(contract.id, payload),
      {
        onSuccess: data => {
          if (data?.id) {
            navigate(`/contracts/${data.id}`);
          }
        },
      }
    );

  const handleReset = useCallback(async () => {
    await confirmAction('Are you sure you want to reset all changes ?');
    setEditableContractData(initState);
    setTaskList(initialTaskData);
    setSummary(initialSummary);
  }, [initState, initialTaskData, initialSummary, confirmAction]);

  const handleCancel = useCallback(async () => {
    if (contractIsChanged || tasksIsChanged) {
      await confirmAction('You have unsaved changes. Do you want to proceed ?');
    }
    navigate(`/contracts/${contract.id}`);
  }, [confirmAction, navigate, contract.id, contractIsChanged, tasksIsChanged]);

  const handleContractDataChange = (data: EditableDataType) => {
    setEditableContractData(data);
  };

  const handleSaveVisibilitySettings = (
    updatedSettings: VisibilitySetting[]
  ) => {
    editableContractData.visibilitySettings = updatedSettings;
  };

  const handleTaskUpdate = useCallback(
    (data: EditableTaskType & { action: 'create' | 'delete' | 'update' }) => {
      const { action } = data;
      let updatedList: EditableTaskType[] = [];
      if (action === 'update' || action === 'delete') {
        updatedList = [...taskList.filter(({ id }) => id !== data.id), data];
      }

      if (action === 'create') {
        updatedList = [...taskList, data];
      }

      const calcTotal = updatedList.reduce((acc, { action, value }) => {
        if (action === 'delete') {
          return acc;
        }
        return acc + Number(value);
      }, 0);

      if (summary) {
        setSummary({
          ...summary,
          value: calcTotal,
          valueToComplete: calcTotal,
        });
      }

      setTaskList(updatedList);
    },
    [taskList, summary]
  );

  const handleSaveContract = useCallback(
    ({ isSubmitted }: { isSubmitted: boolean }) =>
      async () => {
        const newStatus = isSubmitted
          ? ContractStatus.AwaitingAcceptance
          : contract.status;

        if (
          contract.visibilitySettings &&
          editableContractData.visibilitySettings.length ===
            contract.visibilitySettings.length &&
          editableContractData.visibilitySettings.every(
            (s, index) => s.isOn === contract.visibilitySettings![index].isOn
          )
        ) {
          editableContractData.visibilitySettings =
            editableContractData.visibilitySettings.map(s => ({
              ...s,
              status: VisibilityStatus.Unchanged,
            }));
        }

        await mutateContract({
          ...editableContractData,
          isSubmitted,
          isReviewed: !isCustomer,
          tasks: taskList,
          status: newStatus,
        });
      },
    [
      isCustomer,
      editableContractData,
      taskList,
      mutateContract,
      contract.status,
      contract.visibilitySettings,
    ]
  );

  const actionList: ActionListType[] = useMemo((): Array<ActionListType> => {
    return [
      {
        id: 'reset',
        isDisabled: !contractIsChanged && !tasksIsChanged,
        label: 'Reset',
        color: 'warning',
        variant: 'contained',
        action: handleReset,
      },
      {
        id: 'cancel',
        label: 'Cancel',
        variant: 'outlined',
        action: handleCancel,
      },
      {
        id: 'save',
        isLoading: isContractLoading,
        isDisabled: !contractIsChanged && !tasksIsChanged,
        label: 'Save',
        color: 'success',
        variant: 'outlined',
        action: handleSaveContract({ isSubmitted: false }),
      },
      {
        id: 'submit',
        isLoading: isContractLoading,
        label: 'Submit for Acceptance',
        isDisabled: isEmptyTaskList,
        color: 'success',
        variant: 'contained',
        action: handleSaveContract({ isSubmitted: true }),
      },
    ];
  }, [
    contractIsChanged,
    tasksIsChanged,
    handleCancel,
    handleReset,
    handleSaveContract,
    isContractLoading,
    isEmptyTaskList,
  ]);

  useEffect(() => {
    if (tasks?.data) {
      setTaskList(initialTaskData);
    }
  }, [tasks?.data, initialTaskData]);

  useEffect(() => {
    setIsEmptyTaskList(!taskList.length);
  }, [taskList]);

  return (
    <>
      <ContractDetailsEdit
        contract={contract}
        contractHistory={historyData?.contractHistory}
        editableData={editableContractData}
        actionList={actionList}
        summary={summary}
        onUpdate={handleContractDataChange}
        reloadContract={reloadContract}
        onEditSave={handleSaveVisibilitySettings}
      />
      <Box>
        <Tabs tabsData={draftTabs}>
          <TabsItem id={PageContractDraftTabsIds.tasks}>
            <TaskGroups
              canEdit={false}
              contractId={contract.id}
              queryId="contract-approve"
              selectedTaskGroup={selectedTaskGroup}
              handleSelectedTaskGroup={handleSelectedTaskGroup}
              render={() => (
                <>
                  {tasks ? (
                    <TasksTableEdit
                      tasks={taskList}
                      tasksHistory={historyData?.tasksHistory}
                      contract={contract}
                      selectedTaskGroup={selectedTaskGroup}
                      onUpdate={handleTaskUpdate}
                    />
                  ) : (
                    <SkeletonTable />
                  )}
                </>
              )}
            />
          </TabsItem>
        </Tabs>
      </Box>
      <ConfirmDialog />
    </>
  );
}

export { ContractRevisionView };
